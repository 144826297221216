import React, { useEffect, useState } from 'react'
import { Form, Modal, Row, Spin, Space, Button, Divider, Col, Card, Tag, notification, Image } from 'antd'
import axios from 'axios';
import dayjs from 'dayjs';
import { getImagess, resendeditcasere } from '../../../file_mid/all_api';

export default function Conclusion({ close, closeAll, changepage, page, noteCarRes, dataRe }) {
    const { confirm } = Modal
    const [form] = Form.useForm();
    const [api, contextHolder] = notification.useNotification();
    const [loading, setLoading] = useState(false)
    const [showImg, setShowImg] = useState([])
    const [noteCarRe] = useState(noteCarRes) // เก็บโน๊ต
    const username = localStorage.getItem('username');
    const token = localStorage.getItem('token');

    // console.log("dataRe?.cars", dataRe?.cars)
    // console.log("username", username)

    useEffect(() => {
        if (dataRe?.cars?.img?.length > 0) {
            setShowImg(dataRe?.cars?.img)
        }
    }, [])

    const handleSubmit = async () => {
        setLoading(true)
        if (dataRe?.cars.carLoanDetailsRe.reLoanId) {
            let sendData
            if (dataRe?.cars.carPriceStatus) {
                sendData = {
                    "reLoanId": dataRe?.cars.carLoanDetailsRe.reLoanId,
                    "approvalStatus": 5,
                    "ISSUNO": dataRe?.cars.ISSUNO,
                    "Username": username,
                    "ProductLoanType": dataRe?.cars?.carLoanDetailsRe?.productLoanType
                }
            } else {
                sendData = {
                    "reLoanId": dataRe?.cars.carLoanDetailsRe.reLoanId,
                    "approvalStatus": 17,
                    "ISSUNO": dataRe?.cars.ISSUNO,
                    "Username": username,
                    "ProductLoanType": dataRe?.cars?.carLoanDetailsRe?.productLoanType
                }
            }
            // console.log("sendData", sendData)
            await axios.put(resendeditcasere, sendData)
                .then(async (res) => {
                    // console.log("loadDataContno", res.data)
                    if (res.status === 200) {
                        sendSuccess('top')
                        setLoading(false)
                        setTimeout(() => {
                            closeAll();
                        }, 1000)
                    }
                }).catch((err) => {
                    console.log(err)
                    sendError('top')
                })
            setLoading(false)
        } else {
            alert("การโหลดข้อมูลมีปัญหา กรุณาปิดแล้วเปิดใหม่ครับ/ค่ะ")
        }
        setLoading(false)
    };

    const sendSuccess = (placement) => {
        api.success({
            message: <b>บันทึกสำเร็จ</b>,
            //   description:
            //     'สถานะถูกเปลี่ยนจาก รอธุการรับ เป็น รอพิจารณาแล้ว',
            placement,
        });
    }
    const sendError = (placement) => {
        api.error({
            message: <b>บันทึกไม่สำเร็จ</b>,
            //   description:
            //     'สถานะถูกเปลี่ยนจาก รอธุการรับ เป็น รอพิจารณาแล้ว',
            placement,
        });
    }

    const currencyFormatOne = (amount) => {
        if (amount) {
            return Number(amount)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, '$&,')
        } else {
            return "..."
        }
    }

    const showConfirmOK = () => {
        confirm({
            title: 'คุณต้องการส่งเคสอีกครั้ง...?',
            onOk() {
                handleSubmit()
            },
            onCancel() {
            },
        });
    };

    const onChangeBack = () => {
        changepage(page - 1)
    }

    const handleClose = () => {
        close()
    }

    return (
        <div>
            <Row justify={'center'}>
                <Card >
                    <Divider orientation={'center'} style={{ fontSize: '25px' }}><b><u>รายละเอียด</u></b></Divider>
                    <Spin tip="Loading..." spinning={loading} >
                        <Form
                            name="basic"
                            labelCol={{
                                span: 12,
                            }}
                            wrapperCol={{
                                span: 24,
                            }}
                            initialValues={{
                                remember: true,
                            }}
                            form={form}
                            onFinish={showConfirmOK}
                            autoComplete="off"
                        >
                            <Space
                                direction="vertical"
                                size="middle"
                                style={{
                                    display: 'flex',
                                }}>
                                <Row justify={'center'}>
                                    {/* <Col span={24}>
                                        <b>
                                            <div style={{ marginBottom: 0 }}>
                                                <span>เลขสัญญา : </span>{<b style={{ fontSize: '20px', lineHeight: '32px' }}>{dataRe?.cars?.ISSUNO}</b>}
                                            </div>
                                            <div style={{ marginBottom: 0 }}>
                                                <span>เกรด : </span>{<b style={{ fontSize: '20px', lineHeight: '32px' }}>{dataRe?.cars?.carLoanDetailsRe?.GRDCOD}</b>}
                                            </div>
                                        </b>
                                    </Col> */}
                                    <Col span={24} style={{ textAlign: 'right' }}>
                                        <div style={{ margin: 0 }}>
                                            สถานะ : <Tag color="geekblue">{dataRe?.cars?.carLoanDetailsRe?.approvalStatusTable?.name}</Tag>
                                        </div>
                                        <div style={{ margin: 0 }}>
                                            ประเภทขอกู้ : <Tag color="green">{dataRe?.cars?.carLoanDetailsRe?.productLoanType}</Tag>
                                        </div>
                                    </Col>
                                </Row>
                                <Row gutter={32} justify={'center'}>
                                    <Row className='main' justify={'center'}>
                                        <Col span={22}>
                                            <Divider orientation={'left'}><b><u>รายละเอียด</u></b></Divider>
                                            <aside style={{ width: '100%' }}>
                                                <b>
                                                    <div style={{ margin: 0, }}>
                                                        <span>ตัดสด ณ วันที่ : </span>
                                                        <b style={{ fontSize: '18px' }}>
                                                            <u>
                                                                {dayjs(dataRe?.cars?.carLoanDetailsRe?.ftime).format("DD-MM-YYYY")}
                                                            </u>
                                                        </b>
                                                    </div>
                                                </b>
                                                <div label='เลขที่สัญญา' name='ISSUNO' style={{ marginBottom: 3 }}>
                                                    <span>เลขที่สัญญา : </span> <b>{dataRe?.cars?.ISSUNO}</b>
                                                </div>
                                                <div label='ชื่อ-สกุล' name='snam' style={{ marginBottom: 3 }}>
                                                    <span>ชื่อ-สกุล : </span> <b>{dataRe?.firstname + ' ' + dataRe?.lastname}</b>
                                                </div>
                                                {
                                                    dataRe?.phones?.length > 0 ?
                                                        <div label='เบอร์' name='telp' style={{ marginBottom: 3 }}>
                                                            <span>เบอร์ : </span> <b>{dataRe?.phones[0]?.telp}</b>
                                                        </div>
                                                        : null
                                                }
                                                <div label='เกรด' name='GRDCOD' style={{ marginBottom: 3 }}>
                                                    <span>เกรด : </span> <b> {dataRe?.cars?.carLoanDetailsRe?.GRDCOD}</b>
                                                </div>
                                            </aside>
                                            <Divider orientation={'left'}><b><u>รายละเอียดสินค้า</u></b></Divider>
                                            <aside style={{ width: '100%' }}>
                                                <div label='ชนิดรถ' name='productTypeCar' style={{ marginBottom: 3 }}>
                                                    <span>ชนิดรถ : </span><b>{
                                                        dataRe?.cars?.productTypeCar === 1 && ("รถยนต์")
                                                    }
                                                        {
                                                            dataRe?.cars?.productTypeCar === 2 && ("รถเครื่องจักการเกษตร")
                                                        }
                                                        {
                                                            dataRe?.cars?.productTypeCar === 3 && ("รถบรรทุก")
                                                        }</b>
                                                </div>
                                                <div label='ทะเบียนรถ' name='carPlateNumber' style={{ marginBottom: 3 }}>
                                                    <span>ทะเบียนรถ : </span><b>{dataRe?.cars?.carPlateNumber}</b>
                                                </div>
                                                <div label='ยี่ห้อ' name='carBrand' style={{ marginBottom: 3 }}>
                                                    <span>ยี่ห้อ : </span><b>{dataRe?.cars?.carBrand}</b>
                                                </div>
                                                <div label='รุ่น' name='carModel' style={{ marginBottom: 3 }}>
                                                    <span>รุ่น : </span><b>{dataRe?.cars?.carModel}</b>
                                                </div>
                                                <div label='แบบ' name='carBaab' style={{ marginBottom: 3 }}>
                                                    <span>แบบ : </span><b>{dataRe?.cars?.carBaab}</b>
                                                </div>
                                                <div label='ปี' name='carYear' style={{ marginBottom: 3 }}>
                                                    <span>ปี : </span><b>{dataRe?.cars?.carYear}</b>
                                                </div>
                                                <div label='เจ้าหน้าที่ทำเคส' name='proposalBy' style={{ marginBottom: 3 }}>
                                                    <span>เจ้าหน้าที่ทำเคส : </span><b>{dataRe?.cars?.carLoanDetailsRe?.proposalBy}</b>
                                                </div>
                                                <b>
                                                    <div style={{ margin: 0, }}>
                                                        <span>เรทรถ : </span>
                                                        <b style={{ color: 'red', fontSize: '18px' }}><u>{currencyFormatOne(dataRe?.cars?.carPrice)}</u></b>
                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                            บาท
                                                        </span>
                                                    </div>
                                                </b>
                                            </aside>
                                            {/* <Divider orientation={'left'} ><b><u>รายละเอียดยอดกู้</u></b></Divider>
                                            <aside style={{ width: '100%' }}>
                                                <div label='ค่างวด' name='DAMT' style={{ marginBottom: 3 }}>
                                                    <span>ค่างวด : </span><b>{dataRe?.cars?.carLoanDetailsRe?.DAMT ? currencyFormatOne(dataRe?.cars?.carLoanDetailsRe?.DAMT) + " บาท" : 0}</b>
                                                </div>
                                                <div label='ยอดจัดครั้งที่แล้ว' name='tcshprc' style={{ marginBottom: 3 }}>
                                                    <span>ยอดจัดครั้งที่แล้ว : </span><b>{dataRe?.cars?.carLoanDetailsRe?.tcshprc ? currencyFormatOne(dataRe?.cars?.carLoanDetailsRe?.tcshprc) + " บาท" : 0}</b>
                                                </div>
                                                <div label='ราคาเช่าซื้อครั้งที่แล้ว' name='balanc' style={{ marginBottom: 3 }}>
                                                    <span>ราคาเช่าซื้อครั้งที่แล้ว : </span><b>{dataRe?.cars?.carLoanDetailsRe?.balanc ? currencyFormatOne(dataRe?.cars?.carLoanDetailsRe?.balanc) + " บาท" : 0}</b>
                                                </div>
                                                <div label='ยอดที่ชำระมาแล้วทั้งหมด' name='totalpayment' style={{ marginBottom: 3 }}>
                                                    <span>ยอดที่ชำระมาแล้วทั้งหมด : </span><b>{dataRe?.cars?.carLoanDetailsRe?.totalpayment ? currencyFormatOne(dataRe?.cars?.carLoanDetailsRe?.totalpayment) + " บาท" : 0}</b>
                                                </div>
                                                <div label='ล/น คงเหลือรวม' name='neekong' style={{ marginBottom: 3 }}>
                                                    <span>ล/น คงเหลือรวม : </span><b>{dataRe?.cars?.carLoanDetailsRe?.neekong ? currencyFormatOne(dataRe?.cars?.carLoanDetailsRe?.neekong) + " บาท" : 0}</b>
                                                </div>
                                                <div label='เบี้ยปรับ' name='beeypup' style={{ marginBottom: 3 }}>
                                                    <span>เบี้ยปรับ : </span><b>{dataRe?.cars?.carLoanDetailsRe?.beeypup ? currencyFormatOne(dataRe?.cars?.carLoanDetailsRe?.beeypup) + " บาท" : 0}</b>
                                                </div>
                                                <div label='ลูกหนี้อื่นๆ' name='OTHR' style={{ marginBottom: 3 }}>
                                                    <span>ลูกหนี้อื่นๆ : </span><b>{dataRe?.cars?.carLoanDetailsRe?.OTHR ? currencyFormatOne(dataRe?.cars?.carLoanDetailsRe?.OTHR) + " บาท" : 0}</b>
                                                </div>
                                                <div label='จำนวนงวดที่ผ่อนมาแล้ว' name='NOPAY' style={{ marginBottom: 3 }}>
                                                    <span>จำนวนงวดที่ผ่อนมาแล้ว : </span><b>{dataRe?.cars?.carLoanDetailsRe?.NOPAY ? currencyFormatOne(dataRe?.cars?.carLoanDetailsRe?.NOPAY) + " งวด" : 0}</b>
                                                </div>
                                                <div label='จำนวนงวดทั้งหมด' name='nopay' style={{ marginBottom: 3 }}>
                                                    <span>จำนวนงวดทั้งหมด : </span><b>{dataRe?.cars?.carLoanDetailsRe?.nopays ? currencyFormatOne(dataRe?.cars?.carLoanDetailsRe?.nopays) + " งวด" : 0}</b>
                                                </div>
                                                <div label='ตัดสด งวดที่' name='reqNo' style={{ marginBottom: 3 }}>
                                                    <span>ตัดสด งวดที่ : </span><b>{dataRe?.cars?.carLoanDetailsRe?.reqNo ? currencyFormatOne(dataRe?.cars?.carLoanDetailsRe?.reqNo) : 0}</b>
                                                </div>
                                            </aside>
                                            <Divider orientation={'left'}><b><u>รายละเอียดจัดใหม่</u></b></Divider>
                                            <aside style={{ width: '100%' }}>

                                                <b>
                                                    <div style={{ margin: 0, }}>
                                                        <span>ยอดรีไฟแนนซ์เพื่อจัดใหม่ : </span>
                                                        <b style={{ color: 'red', fontSize: '18px' }}><u>{currencyFormatOne(dataRe?.cars?.carLoanDetailsRe?.re1)}</u></b>
                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                            บาท
                                                        </span>
                                                    </div>
                                                </b>
                                                <b>
                                                    <div style={{ margin: 0, }}>
                                                        <span>ยอดจัดใหม่ : </span>
                                                        <b style={{ color: 'red', fontSize: '18px' }}><u>{currencyFormatOne(dataRe?.cars?.carLoanDetailsRe?.approvedLoanAmount)}</u></b>
                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                            บาท
                                                        </span>
                                                    </div>
                                                </b>
                                                <b>
                                                    <div style={{ margin: 0, }}>
                                                        <span>จำนวนงวด : </span>
                                                        <b style={{ color: 'red', fontSize: '18px' }}><u>{currencyFormatOne(dataRe?.cars?.carLoanDetailsRe?.approvedLoanTerm)}</u></b>
                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                            งวด
                                                        </span>
                                                    </div>
                                                </b>
                                            </aside>
                                            <Divider orientation={'left'} ></Divider>
                                            <aside style={{ width: '100%' }}>
                                                <b>
                                                    <div style={{ margin: 0, }}>
                                                        <span>ใบนี้หมดอายุ ณ วันที่ : </span>
                                                        <b style={{ color: 'red', fontSize: '20px' }}><u>{dayjs(dataRe?.cars?.carLoanDetailsRe?.otime).format("DD-MM-YYYY")}</u></b>
                                                    </div>
                                                </b>
                                            </aside> */}
                                        </Col>
                                    </Row>
                                    <Divider />
                                    {
                                        noteCarRe?.length > 0 ?
                                            <>
                                                <Card>
                                                    <Divider orientation={'left'} ><b><u>หมายเหตุคำร้อง</u></b></Divider>
                                                    <aside style={{ width: '100%' }}>
                                                        {noteCarRe?.map((e, index) => {
                                                            return (
                                                                <>
                                                                    <div label='สาเหตุ' name='cause' style={{ marginBottom: 3 }}>
                                                                        <span>สาเหตุ : </span><b style={{ color: 'red', fontSize: '16px' }}>{e?.cause}</b>
                                                                    </div>
                                                                    <div label='หมายเหตุ' name='note' style={{ marginBottom: 3 }}>
                                                                        <span>หมายเหตุ : </span><b style={{ color: 'red', fontSize: '16px' }}>{e?.note}</b>
                                                                    </div>
                                                                    <div label='สถานะที่ทำหมายเหตุ' name='status' style={{ marginBottom: 3 }}>
                                                                        <span>สถานะที่ทำหมายเหตุ : </span><b style={{ color: 'red', fontSize: '16px' }}>{e?.status}</b>
                                                                    </div>
                                                                    <Divider style={{ margin: 5 }} />
                                                                </>
                                                            )
                                                        })}
                                                    </aside>
                                                </Card>
                                                <Divider style={{ margin: 5 }} />
                                            </>
                                            : null
                                    }
                                    {
                                        showImg?.length > 0 ?
                                            <>
                                                <Card>
                                                    <aside style={{ width: '100%' }}>
                                                        <div className='text-left'>
                                                            <h4><u>รูปภาพ</u></h4>
                                                        </div><p />
                                                        <n />
                                                        {showImg?.map((e, index) => {
                                                            return (
                                                                <>
                                                                    {
                                                                        e.type === 14 ?
                                                                            <Image width={'100px'} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                            : null
                                                                    }
                                                                </>
                                                            )
                                                        })}
                                                    </aside>
                                                </Card>
                                                <Divider style={{ margin: 5 }} />
                                            </>
                                            : null
                                    }
                                    <Divider />
                                    <Row justify={'center'}>
                                        <Space>
                                            <Button type="text" onClick={onChangeBack} style={{ background: "#bfbfbf", color: 'white' }} >
                                                ย้อนกลับ
                                            </Button>
                                            <Button type="primary" style={{ background: "red" }} onClick={handleClose}>
                                                ยกเลิก
                                            </Button>
                                            {
                                                loading === false ?
                                                    <>
                                                        <Button type="text" htmlType="submit" style={{ background: '#389e0d', color: '#ffffff' }} >
                                                            ส่งเคสอีกครั้ง
                                                        </Button>
                                                    </> :
                                                    <>
                                                        <Button type='primary' htmlType="submit" disabled
                                                            style={{ color: 'success' }}
                                                        >
                                                            ส่งเคสอีกครั้ง
                                                        </Button>
                                                    </>
                                            }
                                        </Space>
                                    </Row>
                                </Row>
                            </Space>
                        </Form>
                    </Spin>
                </Card>
                {contextHolder}
            </Row>
        </div >
    )
}
