import React, { useState, useEffect } from 'react'
import { Form, Modal, Row, Spin, Space, Button, Divider, Col, Card, Tag, message, Image, Checkbox, notification } from 'antd'
import axios from 'axios';
import dayjs from "dayjs";
import { getAllFolderGuaImg, loadDataRe, confirmre, getImagess } from '../../file_mid/all_api';
import TextArea from 'antd/es/input/TextArea';

function Detail({ page, changepage, close, data, allData, closeAll }) {
  const token = localStorage.getItem('token');
  const branch = localStorage.getItem('branch');
  const { confirm } = Modal
  const [form] = Form.useForm();
  const addCars = localStorage.getItem('addCar')
  const addOccupation = localStorage.getItem('addOccupation')
  const addAddress = localStorage.getItem('addAddress')
  const addCarLoanDetailsRes = localStorage.getItem('addCarLoanDetailsRe')
  const addCustomers = localStorage.getItem('addCustomer')
  const addCustomer2 = localStorage.getItem('addCustomer2')
  const addGuarantors = localStorage.getItem('addGuarantor')
  const addLoans = localStorage.getItem('addLoan')
  const addPhones = localStorage.getItem('addPhone')
  const addPhones2 = localStorage.getItem('addPhone2')
  const addCheckGuas = localStorage.getItem('addCheckGua')
  const dataAddCars = JSON.parse(addCars)
  const dataAddCarLoanDetailsRes = JSON.parse(addCarLoanDetailsRes)
  const dataAddAddress = JSON.parse(addAddress)
  const dataAddCustomers = JSON.parse(addCustomers)
  const dataAddCustomers2 = JSON.parse(addCustomer2)
  const dataOccupation = JSON.parse(addOccupation)
  const dataAddGuarantor = JSON.parse(addGuarantors)
  const dataAddLoans = JSON.parse(addLoans)
  const dataAddPhones = JSON.parse(addPhones)
  const dataAddPhones2 = JSON.parse(addPhones2)
  const dataAddCheckGuas = JSON.parse(addCheckGuas)
  const [getimages, setGetImages] = useState([]);
  const [loading, setLoading] = useState(false)
  const [showImg, setShowImg] = useState([]) // โชว์รูป
  const [DATAGUA, setDATAGUA] = useState([])
  const [DATAPHONE, setDATAPHONE] = useState([])
  const [DATAADDRESS, setDATAADDRESS] = useState([])
  const [DATACUSTOMER, setDATACUSTOMER] = useState()
  const [DATAvehicleModel, setDATAvehicleModel] = useState(null)
  const [guarantors, setGuarantors] = useState([]);
  const [dataPost, setDataPost] = useState({
    offercaseNote: ""
  })
  const [DATAAun, setDATAAun] = useState()
  const [moonnee, setMoonnee] = useState()
  const [hug, setHug] = useState()
  const [kong, setKong] = useState()
  const [jaimoney, setJaimoney] = useState()
  const [yodjut, setYodjut] = useState()
  const [dateceo, setDateceo] = useState()
  const [exp, setExp] = useState()
  const [ngod, setNgod] = useState()
  const [pon, setPon] = useState()

  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    loadDataContno()
    if (dataAddCars.vehicleModel === "" || dataAddCars.vehicleModel === null) {
      setDATAvehicleModel(null)
    } else {
      setDATAvehicleModel(dataAddCars?.vehicleModel)
    }
    if (dataAddGuarantor?.length > 0) {
      getImg()
      setGuarantors(dataAddGuarantor)
      setDATAGUA(dataAddGuarantor)
    } else {
      setDATAGUA([])
    }
    if (dataAddPhones2?.length > 0) {
      setDATAPHONE(dataAddPhones2)
    } else {
      setDATAPHONE(dataAddPhones)
    }
    if (dataAddCustomers2) {
      setDATACUSTOMER(dataAddCustomers2)
    } else {
      setDATACUSTOMER(dataAddCustomers)
    }
    if (dataAddAddress?.length > 0) {
      setDATAADDRESS(dataAddAddress)
    } else {
      setDATAADDRESS([])
    }
  }, [])

  const errorSubmit = (placement) => {
    api.error({
      message: `ไม่สามารถส่งเคสได้`,
      description:
        'กรุณาตรวจสอบ ยอดจัดใหม่/จำนวนงวด',
      placement,
    });
  };

  const errorSubmitSend = (placement) => {
    api.error({
      message: `บันทึกไม่สำเร็จ`,
      placement,
    });
  };

  const getImg = async () => {
    const path = `one_images/car/${data.carId}/5`
    setLoading(true)
    await axios.get(`${getAllFolderGuaImg}/${path}?token=${token}`)
      .then(res => {
        if (res.data) {
          setGetImages(res.data)
          setLoading(false)
        }
      })
      .catch((err) => {
        console.log("err", err)
        setLoading(false)
      })
    setLoading(false)
  }

  const loadDataContno = async () => {
    if (data?.reLoanId && data?.customerId && data?.carId) {
      var mainData = { "reLoanId": data?.reLoanId, "customerId": data?.customerId, "carId": data?.carId }
      setLoading(true)
      await axios.post(loadDataRe, mainData)
        .then(async (res) => {
          // console.log("load", res.data)
          setShowImg(res.data?.cars?.img)
          // setDATAAun(res.data?.cars?.carsApproveCeo)
          calYellowCard(res.data?.cars?.carsApproveCeo)
          setLoading(false)
        }).catch((err) => {
          console.log(err)
          alert("ไม่พบข้อมูล !!!")
        })
      setLoading(false)
    } else {
      // alert("การโหลดข้อมูลมีปัญหา กรุณาปิดแล้วเปิดใหม่ครับ/ค่ะ")
    }
  }

  const calYellowCard = (value) => {
    // console.log("calYellowCard", value)
    // มูลหนี้ balanc
    // หักส่วนลด (balanc-(approvedLoanAmount+(newDamt+followPay)))
    // คงเหลือ มูลหนี้ - หักส่วนลด
    // จ่ายเงิน (newDamt+followPay)
    // ยอดจัด approvedLoanAmount
    // วันที่ approvalDate
    // ใบนี้หมดอายุ ณ วันที่ newOfferDate
    // จำนวนงวด pgLoanTerm
    // ผ่อน installment x pgLoanTerm งวด (รวมภาษีมูลค่าเพิ่ม) 
    // วันที่ dayjs(dataCarAun?.approvalDate).format("DD-MM-YYYY")

    setMoonnee(currencyFormatOne(~~value?.balanc))
    setHug(currencyFormatOne(~~value?.balanc - (~~value?.approvedLoanAmount + (~~value?.newDamt + ~~value?.followPay))))
    setKong(currencyFormatOne(~~value?.balanc - (~~value?.balanc - (~~value?.approvedLoanAmount + (~~value?.newDamt + ~~value?.followPay)))))
    setJaimoney(currencyFormatOne(~~value?.newDamt + ~~value?.followPay))
    setYodjut(currencyFormatOne(~~value?.approvedLoanAmount))
    setDateceo(dayjs(value?.approvalDate).format("DD-MM-YYYY"))
    setExp(dayjs(value?.newOfferDate).format("DD-MM-YYYY"))
    setNgod(~~value?.pgLoanTerm)
    setPon(currencyFormatOne(~~value?.installment) + " x " + ~~value?.pgLoanTerm)
  }

  const handleSubmit = async () => {
    setLoading(true)
    if (dataAddLoans.interestRate > 0 && dataAddLoans.interestt > 0 && dataAddLoans.loanAmountt > 0 && dataAddLoans.monthlyPayment > 0) {
      var data = {
        ...DATACUSTOMER,
        ...dataPost,
        carLoanDetailsRe: {
          reLoanId: dataAddCarLoanDetailsRes.reLoanId,
          carId: dataAddCars.carId,
          customerId: dataAddCarLoanDetailsRes.customerId
        },
        phones: DATAPHONE,
        address: DATAADDRESS,
        carLoanDetails: [{
          ...dataAddLoans,
          branch: branch,
          proposalBy: dataAddCarLoanDetailsRes.proposalBy,
          productLoanType: dataAddCarLoanDetailsRes.productLoanType,
          checkGua: dataAddCheckGuas,
          approvalStatus: 16,
          cars: [{
            carId: dataAddCars.carId,
            customerId: dataAddCars.customerId,
            loanId: dataAddCars.loanId,
            ISSUNO: dataAddCars.ISSUNO,
            carInput: dataAddCars.carInput,
            vehicleModel: DATAvehicleModel
          }]
        }],
        ...dataOccupation,
        guarantors: DATAGUA
      }
      delete data.cars
    } else {
      errorSubmit("top")
    }
    // console.log("data", data)
    await axios.post(confirmre, data)
      .then(res => {
        if (res.data) {
          message.success('ส่งยอดจัดใหม่เรียบร้อย')
          closeAll();
          setLoading(false)
        }
      }).catch((err) => {
        console.log("err", err)
        errorSubmitSend("top")
        setLoading(false)
      })
  };

  const currencyFormatOne = (amount) => {
    if (amount) {
      return Number(amount)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, '$&,')
    } else {
      return "..."
    }
  }
  const showConfirmOKRe = () => {
    confirm({
      title: 'ผู้กู้ยอมรับยอดที่อนุมัติ...?',
      onOk() {
        let checkOK = true
        let app = "ลูกค้าคอนเฟิร์ม"
        handleSubmit(checkOK, app)

      },
      onCancel() {
      },
    });
  };
  const genGuarantors = ({ item, index }) => {

    var itemSplit = []
    if (item?.pathImg) {
      itemSplit = item.pathImg.split("/")
    }

    return (
      <Form
        name="basic"
        labelCol={{
          span: 12,
        }}
        // wrapperCol={{
        //     span: 24,
        // }}
        style={{
          maxWidth: '100%',
        }}
        initialValues={{
          remember: true,
        }}
        autoComplete="off"
      >
        <Card style={{ width: '100%' }}                >
          <Row justify={'center'}><b><u>คนค้ำที่ {index + 1}</u></b></Row>
          <span>เลขบัตรประชาชน : </span>
          <b>{item?.identificationId}</b>
          <div style={{ textAlign: 'left' }}>
            <span>ชื่อ : </span>
            <b>{item?.firstname}</b>
          </div>
          <div style={{ textAlign: 'left' }}>
            <span>นามสกุล : </span>
            <b>{item?.lastname}</b>
          </div>
          <div style={{ textAlign: 'left' }}>
            <span>ชื่อเล่น : </span>
            <b>{item?.nickname}</b>
          </div>
          <div style={{ textAlign: 'left' }}>
            {item?.phones?.length >= 1 ?
              <>
                <span>เบอร์โทร : </span>
                <b>{item?.phones[0].telp}</b>
              </>
              :
              <>
                <span>เบอร์โทร : </span>
                <b>{item?.phones}</b>
              </>
            }
          </div>
          <div style={{ textAlign: 'left' }}>
            <span>เพศ : </span>
            <b>{item?.gender}</b>
          </div>
          <Divider style={{ margin: 5 }} />
          <Row gutter={32} justify={'center'}>
            <span>รูปภาพ : </span>
            <Col span={24} style={{ textAlign: 'center' }}>
              {
                itemSplit.length > 0 ? (
                  <>
                    {getimages?.map((items, index) => {
                      const segments = items.pathImage.split("/")
                      if (itemSplit[4] === segments[4]) {
                        return (
                          // <img width={'300px'} key={index} style={{ margin: 5 }} src={createImageUrl(items.image64)} alt={items.filename} />
                          <Image width={'50px'} key={index} src={`data:${items?.Attr};base64,${items?.data?.toString('base64')}`} alt={`Image ${index + 1}`} />
                        )
                      }

                    })}
                  </>
                ) : null
              }
            </Col>
          </Row>
          <Divider style={{ margin: 5 }} />
        </Card>
      </Form>
    )
  }
  const handleBack = () => {
    changepage(page - 1)
  }
  const handleClose = () => {
    close()
  }

  return (
    <Row justify={'center'}>
      <Card style={{ width: '100%' }}>
        <Divider orientation={'center'} style={{ fontSize: '25px' }}><b><u>รายละเอียด</u></b></Divider>
        <Spin tip="Loading..." spinning={loading} >
          <Form
            name="basic"
            labelCol={{
              span: 12,
            }}
            wrapperCol={{
              span: 24,
            }}

            initialValues={{
              remember: true,
            }}
            form={form}
            onFinish={showConfirmOKRe}
            autoComplete="off"
          >
            <Space
              direction="vertical"
              size="middle"
              style={{
                display: 'flex',
              }}>
              {/* <aside> */}
              <Row>
                <Col span={12} style={{ textAlign: 'left' }}>
                  {
                    allData?.cars?.carLoanDetailsRe?.productLoanType === "Pre-Aaprove-เปลี่ยนสัญญา" ||
                      allData?.cars?.carLoanDetailsRe?.productLoanType === "Pre-Aaprove-รี+เปลี่ยนสัญญา" ||
                      allData?.cars?.carLoanDetailsRe?.productLoanType === "Pre-Aaprove-ปรับโครงสร้าง" ||
                      allData?.cars?.carLoanDetailsRe?.productLoanType === "Pre-Aaprove-รี" ||
                      allData?.cars?.carLoanDetailsRe?.productLoanType === "รี+เปลี่ยนสัญญา" ||
                      allData?.cars?.carLoanDetailsRe?.productLoanType === "เปลี่ยนสัญญา" ||
                      allData?.cars?.carLoanDetailsRe?.productLoanType === "ปรับโครงสร้าง" ||
                      allData?.cars?.carLoanDetailsRe?.productLoanType === "รี" ?
                      <>
                        <div style={{ margin: 0 }}>
                          เลขสัญญา : <Tag color="geekblue">{allData?.cars?.ISSUNO}</Tag>
                        </div>
                        <div style={{ margin: 0 }}>
                          เกรด : <Tag color="green">{allData?.cars?.carLoanDetailsRe?.GRDCOD}</Tag>
                        </div>
                      </>
                      :
                      null
                  }
                </Col>
                <Col span={12} style={{ textAlign: 'right' }}>
                  <div style={{ margin: 0 }}>
                    สถานะ : <Tag color="geekblue">{allData?.cars?.carLoanDetailsRe?.approvalStatusTable?.name}</Tag>
                  </div>
                  <div style={{ margin: 0 }}>
                    ประเภทขอกู้ : <Tag color="green">{allData?.cars?.carLoanDetailsRe?.productLoanType}</Tag>
                  </div>
                </Col>
              </Row>
              <Row className='main' justify={'center'}>
                <Col span={24}>
                  <Divider orientation={'left'}><b><u>รายละเอียด</u></b></Divider>
                  <aside style={{ width: '100%' }}>
                    <b>
                      <div style={{ margin: 0, }}>
                        <span>ตัดสด ณ วันที่ : </span>
                        <b style={{ fontSize: '18px' }}>
                          <u>
                            {dayjs(dataAddCarLoanDetailsRes?.ftime).format("DD-MM-YYYY")}
                          </u>
                        </b>
                      </div>
                    </b>
                    <div label='เลขที่สัญญา' name='ISSUNO' style={{ marginBottom: 3 }}>
                      <span>เลขที่สัญญา : </span> <b>{allData?.cars?.ISSUNO}</b>
                    </div>
                    <div label='ชื่อ-สกุล' name='snam' style={{ marginBottom: 3 }}>
                      <span>ชื่อ-สกุล : </span> <b>{DATACUSTOMER?.firstname + ' ' + DATACUSTOMER?.lastname}</b>
                    </div>
                    {
                      DATAPHONE?.length > 0 ?
                        <div label='เบอร์' name='telp' style={{ marginBottom: 3 }}>
                          <span>เบอร์ : </span> <b>{DATAPHONE[0]?.telp}</b>
                        </div>
                        : null
                    }
                    <div label='เกรด' name='GRDCOD' style={{ marginBottom: 3 }}>
                      <span>เกรด : </span> <b> {dataAddCarLoanDetailsRes?.GRDCOD}</b>
                    </div>
                  </aside>
                  <Divider orientation={'left'}><b><u>อาชึพ</u></b></Divider>
                  <aside style={{ width: '100%' }}>

                    <div style={{ margin: 0, }}>
                      <span>กลุ่มอาชีพ : </span>
                      <b>{dataOccupation?.ojs?.occupationalGroupTH}</b>
                    </div>
                    <div style={{ margin: 0, }}>
                      <span>อาชีพ : </span>
                      <b>{dataOccupation?.ojs?.governmentPrivateTH}</b>
                    </div>
                    <div style={{ margin: 0, }}>
                      <span>สาขาอาชีพ : </span>
                      <b>{dataOccupation?.ojs?.jobPositionTH}</b>
                    </div>
                    <div style={{ margin: 0, }}>
                      <span>ชื่อบริษัท(สถานที่ทำงาน) : </span>
                      <b>{dataOccupation?.ojs?.companyName}</b>
                    </div>
                    <div style={{ margin: 0, }}>
                      <span>รายได้ / เดือน : </span>
                      <b>{dataOccupation?.income?.incomeMonth}</b>
                      <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                        บาท
                      </span>
                    </div>
                    <div style={{ margin: 0, }}>
                      <span>รายจ่าย / เดือน : </span>
                      <b>{dataOccupation?.expenses?.expensesMonth}</b>
                      <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                        บาท
                      </span>
                    </div>
                  </aside>
                  {
                    allData?.cars?.carLoanDetailsRe?.productLoanType === "Pre-Aaprove-เปลี่ยนสัญญา" ||
                      allData?.cars?.carLoanDetailsRe?.productLoanType === "Pre-Aaprove-รี+เปลี่ยนสัญญา" ||
                      allData?.cars?.carLoanDetailsRe?.productLoanType === "รี+เปลี่ยนสัญญา" ||
                      allData?.cars?.carLoanDetailsRe?.productLoanType === "เปลี่ยนสัญญา"
                      ?
                      <>
                        {allData?.cars?.carLoanDetailsRe?.priceChange ?
                          <>
                            <Divider style={{ margin: 3 }} />
                            <Divider orientation={'left'}><b><u>ค่าใช้จ่ายที่ลูกค้าต้องจ่าย</u></b></Divider>

                            <aside style={{ width: '100%', background: '#061178', padding: '8px', color: 'white' }} className='center'>
                              <div name='priceChange' style={{ marginBottom: 3 }}>
                                <span>ค่าเปลี่ยนสัญญา : </span> <b> {currencyFormatOne(allData?.cars?.carLoanDetailsRe?.priceChange)}</b>
                              </div>
                              <div name='overdue' style={{ marginBottom: 3 }}>
                                <span>ยอดค้าง + เบี้ยปรับ : </span> <b> {currencyFormatOne(allData?.cars?.carLoanDetailsRe?.overdue)}</b>
                              </div>
                              <div name='preChange' style={{ marginBottom: 3 }}>
                                <span>ค่างวดล่วงหน้า : </span> <b> {currencyFormatOne(allData?.cars?.carLoanDetailsRe?.preChange)}</b>
                              </div><br />
                              <div>
                                <span>ยอดรวม : </span> <b> {currencyFormatOne(~~allData?.cars?.carLoanDetailsRe?.preChange + ~~allData?.cars?.carLoanDetailsRe?.overdue + ~~allData?.cars?.carLoanDetailsRe?.priceChange
                                )}</b>
                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                  บาท
                                </span>
                              </div>
                            </aside>
                            <Divider style={{ margin: 3 }} />
                          </>
                          : null
                        }

                      </>
                      :
                      null
                  }
                  <Divider orientation={'left'}><b><u>รายละเอียดสินค้า</u></b></Divider>
                  <aside style={{ width: '100%' }}>
                    <div label='ชนิดรถ' name='productTypeCar' style={{ marginBottom: 3 }}>
                      <span>ชนิดรถ : </span><b>
                        {
                          dataAddCars?.productTypeCar === 1 && ("รถยนต์")
                        }
                        {
                          dataAddCars?.productTypeCar === 2 && ("รถเครื่องจักการเกษตร")
                        }
                        {
                          dataAddCars?.productTypeCar === 3 && ("รถบรรทุก")
                        }</b>
                    </div>
                    <div label='ทะเบียนรถ' name='carPlateNumber' style={{ marginBottom: 3 }}>
                      <span>ทะเบียนรถ : </span><b>{dataAddCars?.carPlateNumber} {dataAddCars?.carProvince}</b>
                    </div>
                    <div label='ยี่ห้อ' name='carBrand' style={{ marginBottom: 3 }}>
                      <span>ยี่ห้อ : </span><b>{dataAddCars?.carBrand}</b>
                    </div>
                    <div label='รุ่น' name='carModel' style={{ marginBottom: 3 }}>
                      <span>รุ่น : </span><b>{dataAddCars?.carModel}</b>
                    </div>
                    <div label='แบบ' name='carBaab' style={{ marginBottom: 3 }}>
                      <span>แบบ : </span><b>{dataAddCars?.carBaab}</b>
                    </div>
                    <div label='ปี' name='carYear' style={{ marginBottom: 3 }}>
                      <span>ปี : </span><b>{dataAddCars?.carYear}</b>
                    </div>
                    <div label='เจ้าหน้าที่ทำเคส' name='proposalBy' style={{ marginBottom: 3 }}>
                      <span>เจ้าหน้าที่ทำเคส : </span><b>{dataAddCars?.carLoanDetailsRe?.proposalBy}</b>
                    </div>
                    <b>
                      <div style={{ margin: 0, }}>
                        <span>เรทรถ : </span>
                        <b style={{ color: 'red', fontSize: '18px' }}><u>{currencyFormatOne(dataAddCars?.carPrice)}</u></b>
                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                          บาท
                        </span>
                      </div>
                    </b>
                  </aside>
                  <Divider orientation={'left'}><b><u>รายละเอียดยอดกู้</u></b></Divider>
                  <aside style={{ width: '100%' }}>
                    <div label='ค่างวด' name='DAMT' style={{ marginBottom: 3 }}>
                      <span>ค่างวด : </span><b>
                        {dataAddCarLoanDetailsRes?.DAMT ? currencyFormatOne(dataAddCarLoanDetailsRes?.DAMT) + " บาท" : "0"}
                      </b>
                    </div>
                    <div label='ยอดจัดครั้งที่แล้ว' name='tcshprc' style={{ marginBottom: 3 }}>
                      <span>ยอดจัดครั้งที่แล้ว : </span><b>{dataAddCarLoanDetailsRes?.tcshprc ? currencyFormatOne(dataAddCarLoanDetailsRes?.tcshprc) + " บาท" : 0}</b>
                    </div>
                    <div label='ราคาเช่าซื้อครั้งที่แล้ว' name='balanc' style={{ marginBottom: 3 }}>
                      <span>ราคาเช่าซื้อครั้งที่แล้ว : </span><b>{dataAddCarLoanDetailsRes?.balanc ? currencyFormatOne(dataAddCarLoanDetailsRes?.balanc) + " บาท" : 0}</b>
                    </div>
                    <div label='ยอดที่ชำระมาแล้วทั้งหมด' name='totalpayment' style={{ marginBottom: 3 }}>
                      <span>ยอดที่ชำระมาแล้วทั้งหมด : </span><b>{dataAddCarLoanDetailsRes?.totalpayment ? currencyFormatOne(dataAddCarLoanDetailsRes?.totalpayment) + " บาท" : 0}</b>
                    </div>
                    <div label='ล/น คงเหลือรวม' name='neekong' style={{ marginBottom: 3 }}>
                      <span>ล/น คงเหลือรวม : </span><b>{dataAddCarLoanDetailsRes?.neekong ? currencyFormatOne(dataAddCarLoanDetailsRes?.neekong) + " บาท" : 0}</b>
                    </div>
                    <div label='เบี้ยปรับ' name='beeypup' style={{ marginBottom: 3 }}>
                      <span>เบี้ยปรับ : </span><b>{dataAddCarLoanDetailsRes?.beeypup ? currencyFormatOne(dataAddCarLoanDetailsRes?.beeypup) + " บาท" : 0}</b>
                    </div>
                    <div label='ลูกหนี้อื่นๆ' name='OTHR' style={{ marginBottom: 3 }}>
                      <span>ลูกหนี้อื่นๆ : </span><b>{dataAddCarLoanDetailsRes?.OTHR ? currencyFormatOne(dataAddCarLoanDetailsRes?.OTHR) + " บาท" : 0}</b>
                    </div>
                    <div label='จำนวนงวดที่ผ่อนมาแล้ว' name='NOPAY' style={{ marginBottom: 3 }}>
                      <span>จำนวนงวดที่ผ่อนมาแล้ว : </span><b>{dataAddCarLoanDetailsRes?.NOPAY ? currencyFormatOne(dataAddCarLoanDetailsRes?.NOPAY) + " งวด" : 0}</b>
                    </div>
                    <div label='จำนวนงวดทั้งหมด' name='nopay' style={{ marginBottom: 3 }}>
                      <span>จำนวนงวดทั้งหมด : </span><b>{dataAddCarLoanDetailsRes?.nopays ? currencyFormatOne(dataAddCarLoanDetailsRes?.nopays) + " งวด" : 0}</b>
                    </div>
                    <div label='ตัดสด งวดที่' name='reqNo' style={{ marginBottom: 3 }}>
                      <span>ตัดสด งวดที่ : </span><b>{dataAddCarLoanDetailsRes?.reqNo ? currencyFormatOne(dataAddCarLoanDetailsRes?.reqNo) : 0}</b>
                    </div>
                  </aside>
                  <Divider orientation={'left'}><b><u>รายละเอียดจัดใหม่</u></b></Divider>
                  {
                    DATAAun?.id > 0 ?
                      <>
                        <Card style={{ backgroundColor: "lightyellow" }}>
                          <Row justify={'left'}><b><u style={{ backgroundColor: '#f39c12' }}>เงื่อนไขการปรับโครงสร้าง</u></b></Row>
                          <Col span={24} style={{ textAlign: 'center' }}>
                            <Image
                              width={100}
                              src={`${process.env.PUBLIC_URL}/logo.jpg`}
                              alt="My Image"
                            />
                          </Col>
                          <br></br>
                          <aside style={{ width: '100%' }}>
                            <b>
                              <div style={{ margin: 0, }}>
                                <span>วันที่ : </span>
                                <b><u>{dateceo}</u></b>
                              </div>
                            </b>
                            <b>
                              <div style={{ margin: 0, }}>
                                <span>มูลหนี้เช่าซื้อคงเหลือรวม : </span>
                                <b><u>{moonnee}</u></b>
                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                  บาท
                                </span>
                              </div>
                            </b>
                            <b>
                              <div style={{ margin: 0, }}>
                                <span>หักส่วนลด : </span>
                                <b><u>{hug}</u></b>
                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                  บาท
                                </span>
                              </div>
                            </b>
                            <b>
                              <div style={{ margin: 0, }}>
                                <span>คงเหลือ : </span>
                                <b><u>{kong}</u></b>
                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                  บาท
                                </span>
                              </div>
                            </b>
                            <b>
                              <div style={{ margin: 0, }}>
                                <span>จ่ายเงิน : </span>
                                <b><u>{jaimoney}</u></b>
                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                  บาท
                                </span>
                              </div>
                            </b>
                            <b>
                              <div style={{ margin: 0, }}>
                                <span>จำนวนงวด : </span>
                                <b><u>{(DATAAun?.pgLoanTerm)}</u></b>
                              </div>
                            </b>
                            <b>
                              <div style={{ margin: 0, }}>
                                <span>ผ่อน : </span>
                                <b>{ngod}</b>
                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                  งวด
                                  (รวมภาษีมูลค่าเพิ่ม)
                                </span>
                              </div>
                            </b>
                            <b>
                              <div style={{ margin: 0, }}>
                                <span>ยอดจัด : </span>
                                <b style={{ color: 'red', fontSize: '18px' }}><u>{yodjut}</u></b>
                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                  บาท
                                </span>
                              </div>
                            </b>
                          </aside>
                        </Card>
                      </>
                      :
                      <>
                        <aside style={{ width: '100%' }}>
                          <b>
                            <div style={{ margin: 0, }}>
                              <span>ยอดรีไฟแนนซ์เพื่อจัดใหม่ : </span>
                              <b style={{ color: 'red', fontSize: '18px' }}><u>{currencyFormatOne(dataAddCarLoanDetailsRes?.re1)}</u></b>
                              <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                บาท
                              </span>
                            </div>
                          </b>
                          <b>
                            <div style={{ margin: 0, }}>
                              <span>ยอดจัดใหม่ : </span>
                              <b style={{ color: 'red', fontSize: '18px' }}><u>{currencyFormatOne(dataAddLoans?.loanAmountt)}</u></b>
                              <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                บาท
                              </span>
                            </div>
                          </b>
                          <b>
                            <div style={{ margin: 0, }}>
                              <span>จำนวนงวด : </span>
                              <b style={{ color: 'red', fontSize: '18px' }}><u>{(dataAddLoans?.installmentAmountt)}</u></b>
                              <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                งวด
                              </span>
                            </div>
                          </b>
                        </aside>
                      </>
                  }
                  <Divider orientation={'left'}><b><u>การค้ำโอน</u></b></Divider>
                  <Row justify={'center'}>
                    <Checkbox
                      style={{ fontSize: '20px' }}
                      checked={dataAddCheckGuas === "ค้ำ-ไม่โอน" ? true : false}
                    >ค้ำ-ไม่โอน
                    </Checkbox>
                    <Checkbox
                      style={{ fontSize: '20px' }}
                      checked={dataAddCheckGuas === "ค้ำ-โอน" ? true : false}
                    >ค้ำ-โอน
                    </Checkbox>
                    <Checkbox
                      style={{ fontSize: '20px' }}
                      checked={dataAddCheckGuas === "ไม่ค้ำ-ไม่โอน" ? true : false}
                    >ไม่ค้ำ-ไม่โอน
                    </Checkbox>
                    <Checkbox
                      style={{ fontSize: '20px' }}
                      checked={dataAddCheckGuas === "ไม่ค้ำ-โอน" ? true : false}
                    >ไม่ค้ำ-โอน
                    </Checkbox>
                  </Row>
                  <Divider orientation={'left'}></Divider>
                  <aside style={{ width: '100%' }}>
                    <b>
                      <div style={{ margin: 0, }}>
                        <span>ใบนี้หมดอายุ ณ วันที่ : </span>
                        <b style={{ color: 'red', fontSize: '20px' }}><u>{dayjs(dataAddCarLoanDetailsRes?.newOfferDate).format("DD-MM-YYYY")}</u></b>
                      </div>
                    </b>
                  </aside>
                </Col>
              </Row>
              <Divider orientation={'left'}></Divider>
              <Row justify={'center'}>
                <Col span={22}>
                  <Form.Item name="offercaseNote" label="">
                    <span><b style={{ fontSize: '25' }}><u>หมายเหตุ</u></b></span> <p />
                    <span><u style={{ fontSize: '20', color: 'red' }}>กรณีที่เอกสารส่วนไหนไม่ครบหรือไม่มี ให้พิมพ์หมายเหตุตรงนี้</u></span>
                    <TextArea
                      name='offercaseNote'
                      style={{ color: 'blue' }}
                      rows={8}
                      onChange={(e) => setDataPost({ offercaseNote: e.target.value })}
                    >
                    </TextArea>
                  </Form.Item>
                </Col>
              </Row>
              {
                guarantors?.length > 0 ?
                  <>
                    <Divider orientation={'left'}></Divider>
                    {guarantors?.map((item, index) => {
                      return genGuarantors({ item, index, key: `{item.identificationId} - ${index}` });
                    })}
                  </>
                  :
                  null
              }
              <Divider />
              <Row justify={'left'}><b><u>รูปประกอบการขออนุมัติ</u></b></Row>
              <Row gutter={32} justify={'center'}>
                {/* <Space direction="vertical" > */}
                {showImg?.map((imageData, index) => {
                  if (imageData?.type === 14) {
                    return (
                      <>
                        <Row gutter={32} justify={'center'}>
                          <Col span={24} style={{ textAlign: 'center' }}>
                            <Image width={'100px'} key={index} src={`${getImagess}/${imageData.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                          </Col>
                        </Row>
                      </>
                    );
                  }
                  return null;
                })}
                {/* </Space> */}
              </Row>
              <Divider />
              <Row justify={'left'}><b><u>รูปบัตรประชาชนผู้ทำสัญญา</u></b></Row>
              <Row gutter={32} justify={'center'}>
                {/* <Space direction="vertical" > */}
                {showImg?.map((imageData, index) => {
                  if (imageData?.type === 4) {
                    return (
                      <>
                        <Row gutter={32} justify={'center'}>
                          <Col span={24} style={{ textAlign: 'center' }}>
                            <Image width={'100px'} key={index} src={`${getImagess}/${imageData.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                          </Col>
                        </Row>
                      </>
                    );
                  }
                  return null;
                })}
                {/* </Space> */}
              </Row>
              <Divider />
              <Row justify={'left'}><b><u>หน้าคู่มือ</u></b></Row>
              <Row gutter={32} justify={'center'}>
                {/* <Space direction="vertical" > */}
                {showImg?.map((imageData, index) => {
                  if (imageData?.type === 2) {
                    return (
                      <>
                        <Row gutter={32} justify={'center'}>
                          <Col span={24} style={{ textAlign: 'center' }}>
                            <Image width={'100px'} key={index} src={`${getImagess}/${imageData.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                          </Col>
                        </Row>
                      </>
                    );
                  }
                  return null;
                })}
                {/* </Space> */}
              </Row>
              <Divider />
              <Row justify={'left'}><b><u>ใบประเมิน / รูปรถ / สัญญาไฟแนนซ์เดิม / ใบเสร็จไฟแนนซ์เดิม</u></b></Row>
              <Row gutter={32} justify={'center'}>
                {/* <Space direction="vertical" > */}
                {showImg?.map((imageData, index) => {
                  if (imageData?.type === 1) {
                    return (
                      <>
                        <Row gutter={32} justify={'center'}>
                          <Col span={24} style={{ textAlign: 'center' }}>
                            <Image width={'100px'} key={index} src={`${getImagess}/${imageData.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                          </Col>
                        </Row>
                      </>
                    );
                  }
                  return null;
                })}
                {/* </Space> */}
              </Row>
              <Divider />
              <Row justify={'left'}><b><u>ประวัติผู้กู้ / ทะเบียนบ้าน / อาชีพ</u></b></Row>
              <Row gutter={32} justify={'center'}>
                {/* <Space direction="vertical" > */}
                {showImg?.map((imageData, index) => {
                  if (imageData?.type === 6) {
                    return (
                      <>
                        <Row gutter={32} justify={'center'}>
                          <Col span={24} style={{ textAlign: 'center' }}>
                            <Image width={'100px'} key={index} src={`${getImagess}/${imageData.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                          </Col>
                        </Row>
                      </>
                    );
                  }
                  return null;
                })}
                {/* </Space> */}
              </Row>
              <Divider />
              <Row justify={'left'}><b><u>รูปสเตทเมนต์ผู้กู้</u></b></Row>
              <Row gutter={32} justify={'center'}>
                {/* <Space direction="vertical" > */}
                {showImg?.map((imageData, index) => {
                  if (imageData?.type === 8) {
                    return (
                      <>
                        <Row gutter={32} justify={'center'}>
                          <Col span={24} style={{ textAlign: 'center' }}>
                            <Image width={'100px'} key={index} src={`${getImagess}/${imageData.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                          </Col>
                        </Row>
                      </>
                    );
                  }
                  return null;
                })}
                {/* </Space> */}
              </Row>
              <Divider />
              <Row justify={'left'}><b><u>รูปสเตทเมนต์ผู้กู้ PDF</u></b></Row>
              <Row gutter={32} justify={'center'}>
                {/* <Space direction="vertical" > */}
                {showImg?.map((imageData, index) => {
                  if (imageData.type === 7) {
                    return (
                      <Row gutter={32} justify={'center'}>
                        <Col span={24} style={{ textAlign: 'center' }}>
                          {/* <Space direction='vertical'> */}
                          <object data={`${getImagess}/${imageData.pathImage}?token=${token}`} type="application/pdf" width="350px" height="450px">
                            <p>Alternative text - include a link <a href={`${getImagess}/${imageData.pathImage}?token=${token}`}>to the PDF!</a></p>
                          </object>
                          <p></p>
                          {/* </Space> */}
                        </Col>
                      </Row>
                    )
                  }
                  return null;
                })}
                {/* </Space> */}
              </Row>
              <Divider />
              <Row justify={'left'}><b><u>เอกสารเพิ่มเติม</u></b></Row>
              <Row gutter={32} justify={'center'}>
                {/* <Space direction="vertical" > */}
                {showImg?.map((imageData, index) => {
                  if (imageData?.type === 12) {
                    return (
                      <>
                        <Row gutter={32} justify={'center'}>
                          <Col span={24} style={{ textAlign: 'center' }}>
                            <Image width={'100px'} key={index} src={`${getImagess}/${imageData.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                          </Col>
                        </Row>
                      </>
                    );
                  }
                  return null;
                })}
                {/* </Space> */}
              </Row>
              <Divider />
              <Row justify={'center'}>
                <Space>
                  <Button type='primary' onClick={handleBack} >ย้อนกลับ</Button>
                  <Button type='primary' danger onClick={handleClose} >ยกเลิก</Button>
                  <Button type='primary' htmlType='submit' disabled={loading} style={{ backgroundColor: "green" }} >คอนเฟิร์มยอดจัดใหม่</Button>
                </Space>
              </Row>
            </Space>
          </Form>
        </Spin>
      </Card>
      {contextHolder}
    </Row>
  )
}

export default Detail