import { Modal, Form, Button, Row, Spin, Input, Divider, Col, Space, Upload, Card, message, notification } from 'antd'
import React, { useState, useEffect } from 'react'
import Compressor from 'compressorjs';
import { PlusOutlined } from '@ant-design/icons';
import axios from 'axios';
import dayjs from 'dayjs';
import { addcusforgua, checkCCAP, checkNewCCAP, testupload, uploadImgGuaTable } from '../../../file_mid/all_api';

function AddGua({ open, close, indexedit, sb, getData, pregarno, oldGua }) {

    const branch = localStorage.getItem('branch');
    var countGu = 0
    // const token = localStorage.getItem('token');
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm()
    const [form1] = Form.useForm()

    const { confirm } = Modal
    const [api, contextHolder] = notification.useNotification();
    const [guarantors, setGuarantors] = useState();
    const [images, setImage] = useState([]);
    const [images1, setImage1] = useState();
    const [dataPostGuarantor, setDataPostGuarantor] = useState([]) // customerGuarantor

    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState('');

    const [fileListGuarantor, setFileListGuarantor] = useState([]);
    const [fileListGuarantor1, setFileListGuarantor1] = useState([]);
    const [fileListGuarantors, setFileListGuarantors] = useState([]);
    const [fileListGuarantors1, setFileListGuarantors1] = useState([]);
    const [CheckIdCard, setCheckIdCard] = useState(false)
    const [indexedit1, setIndexedit1] = useState(indexedit);

    // console.log("indexedit1", indexedit1)
    // console.log("getData555", getData)
    // console.log("guarantors", guarantors)


    useEffect(() => {
        if (guarantors?.length === 0) {
            setIndexedit1(0)

        } else if (guarantors?.length >= 1) {
            const count = guarantors?.length
            setIndexedit1(count + 1)
        }
        if (images1?.length === 0) {
            form.setFieldsValue({
                ...images1[0], ...images1[0]?.phones[0]
            })
        } else if (images1?.length >= 1) {
            form.setFieldsValue({
                ...images1[0], ...images1[0]?.phones
            })
        }

    }, [images1])


    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);
    };
    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };
    const handleCancel1 = () => {
        setPreviewVisible(false);
    }

    const handleCancel = () => {
        close(false);
    };
    //////////// โชว์รูปภาพ ////////////////
    function createImageUrl(imageData) {
        return imageData;
    }
    const CheckGuER = (placement) => {
        api.error({
            message: <b>ไม่สามารถเพิ่มข้อมูลได้</b>,
            description: 'พบข้อมูลซ้ำกันระหว่างผู้กู้และผู้ค้ำ กรุณาเปลี่ยนผู้ค้ำ',
            placement,
        });
    }
    const CheckGuERS = (placement) => {
        api.error({
            message: <b>ไม่สามารถเพิ่มข้อมูลได้</b>,
            description: 'พบข้อมูลซ้ำกันระหว่างผู้ค้ำและผู้ค้ำ กรุณาเปลี่ยนผู้ค้ำ',
            placement,
        });
    }

    /////////// ดึงข้อมูลจากรูปบัตรประชาชนคนค้ำ /////////////////////////////////////////////////////////////////  
    const handleScanIdCardGuarantor = async (value) => {
        console.log("value", value)
        if (guarantors?.length > 0) {
            var dataListGuarantor = [...guarantors]
        } else {
            var dataListGuarantor = []
        }
        for (let i = 0; i < value.length; i++) {
            var imgId = { image: value[i].image64 }
            let path = `one_images/land/${getData.lands.landId}/5/${indexedit + 1}`
            console.log("path", path)
            // setLoading(true)
            const headers = { 'Content-Type': 'application/json' }
            await axios.post(testupload, imgId, { headers: headers })
                .then(async (res) => {
                    delete res.data[0].address
                    if (res.data) {
                        // oldGua
                        if (oldGua !== undefined) {
                            oldGua?.map((e) => {
                                if (e.identificationId === res.data[0]?.identificationId) {
                                    countGu = 1
                                    setFileListGuarantor()
                                    setFileListGuarantor1()
                                    console.log("บัตรคนค้ำซ้ำกัน")
                                    CheckGuERS('top')
                                }
                            })
                        }
                        // console.log(res.data[0])
                        const b = await loadDataOneGuarantor(res.data[0])
                        dataListGuarantor.push({
                            ...b[0], imgs: value[i],
                            garno: pregarno + 1,
                            loanId: getData?.lands?.landLoanDetails?.landLoanId,
                            carLandId: getData?.lands?.landId,
                            typeLoan: "land",
                            locat: branch,
                            pathImg: path
                        })
                        // console.log("BB", dataListGuarantor)
                        // setLoading(false)
                    }
                    else {
                        message.info('ไม่สามารถอ่านได้')
                        // setLoading(false)
                    }
                })
                .catch((err) => console.log(err))
            // setLoading(false)
        }
        // console.log("bb=", dataListGuarantor)
        if (countGu === 0) {
            setGuarantors({ ...guarantors, dataListGuarantor })
            showConfirm({ dataListGuarantor })
        }
    }
    ////////////// ข้อมูลยูสในเดต้าเบสคนค้ำ ////////////////////////////////////////////////////////////////
    const loadDataOneGuarantor = async (data) => {
        //setLoading(true)
        var dataListGuarantor = []
        if (!data?.identificationId) {
            setCheckIdCard(true)
            var unDataGua = {}
            // countGu = 1
            setFileListGuarantor()
            setFileListGuarantor1()
            console.log("ไม่มีเลขบัตรคนค้ำ")
            alert('ไม่สามารถอ่านบัตรคนค้ำประกันได้')
            unDataGua.identificationId = ""
            unDataGua.customerId = 0
            unDataGua.firstname = data?.firstname
            unDataGua.lastname = data?.lastname
            unDataGua.snam = data?.snam
            unDataGua.nickname = ""
            unDataGua.phones = []
            unDataGua.gender = data?.gender
            unDataGua.birthdate = data?.birthdate
            unDataGua.expdt = data?.expdt
            unDataGua.issuby = data?.issuby
            unDataGua.checkDup = false
            dataListGuarantor.push(unDataGua)
            setDataPostGuarantor({ ...dataPostGuarantor, ...unDataGua })
            form.setFieldsValue({ nickname: "", telp: "" })
        } else if (getData?.identificationId === data?.identificationId) {
            countGu = 1
            setFileListGuarantor()
            setFileListGuarantor1()
            CheckGuER('top')
        } else {
            var one = { identificationId: data.identificationId }
            //setLoading(true)
            await axios.post(checkCCAP, one)
                .then((res) => {
                    if (res.data) {
                        console.log("55555")
                        //alert('มีข้อมูลในฐานข้อมูล')
                        message.info('คนค้ำมีข้อมูลในฐานข้อมูล')
                        delete res.data.address
                        delete res.data.carLoanDetails
                        delete res.data.emails
                        delete res.data.ojs
                        delete res.data.socials
                        if (res.data.phones?.length > 0) {
                            dataListGuarantor.push({ ...res.data, telp: res.data.phones[0].telp, checkDup: true })
                        } else {
                            dataListGuarantor.push({ ...res.data, checkDup: true })
                        }
                        setDataPostGuarantor({ ...dataPostGuarantor, ...data, customerId: 0 })
                        setCheckIdCard(false)
                    }
                    else {
                        // alert('คนค้ำไม่พบข้อมูลในฐานข้อมูล')
                        message.info('ไม่พบข้อมูลในฐานข้อมูล')
                        console.log("RES-DATA NO =", data)
                        dataListGuarantor.push({ ...data, checkDup: false, phones: [] })
                        setDataPostGuarantor({ ...dataPostGuarantor, ...data, customerId: 0 })
                        setCheckIdCard(true)
                    }
                })
                .catch((err) => console.log(err))
        }
        // console.log("dataListGuarantor", dataListGuarantor)
        return dataListGuarantor
    }

    const showConfirm = ({ dataListGuarantor }) => {
        var note = dataListGuarantor?.length;
        var lastIndex = note - 1;
        var imageGuarantors1 = [...dataListGuarantor];
        console.log("imageGuarantors1BeforeMAP", imageGuarantors1)
        setImage1(imageGuarantors1)
        guarantors?.map((img, index) => {
            imageGuarantors1.push({ ...img.imgs })
            setImage(imageGuarantors1)
        })

        setLoading(true)
        confirm({
            title: 'เพิ่มคนค้ำ?',
            content: dataListGuarantor[lastIndex]?.snam + ' ' + dataListGuarantor[lastIndex]?.firstname + ' ' + dataListGuarantor[lastIndex]?.lastname,
            onOk() {
                setGuarantors(dataListGuarantor)
                setLoading(false)
                setTimeout(() => {
                    setFileListGuarantor([])
                },)
            },
            onCancel() {
                message.error('ยกเลิก')
                setGuarantors(guarantors)
                setFileListGuarantor1({})
                setTimeout(() => {
                    setFileListGuarantor([])
                },)
                setLoading(false)
            },
        });
    }

    //////////////////// อ่านบัตรคนค้ำ /////////////////////////
    const handleChangeGuarantor1 = async ({ fileList }) => {
        setLoading(true)
        console.log("fileList", fileList)
        setFileListGuarantor(fileList);
        var imageListGuarantor = []
        var i = 0
        for (const file of fileList) {
            const compressedFile = await new Promise((resolve) => {
                new Compressor(file.originFileObj, {
                    quality: 0.1, // เช่น, 60%
                    success: (result) => {
                        resolve(result);
                    },
                });
            });
            var image = {}
            var filename = file.name
            var image64 = await getBase64(compressedFile)
            // var image64 = file.originFileObj(compressedFile)
            image.filename = filename
            image.image64 = image64
            image.type = `5.${indexedit1 + 1}`
            imageListGuarantor.push(image)
            i++
        }
        await handleScanIdCardGuarantor(imageListGuarantor)
        setFileListGuarantor1({ ...fileListGuarantor1, imageListGuarantor });
        setLoading(false)
    };



    //////////////////// รูปเอกสาร ///////////////////////
    const handleChangeGuarantors1 = async ({ fileList }) => {
        console.log("fileList", fileList)
        setLoading(true)
        setFileListGuarantors(fileList);
        var imageListGuarantors = []
        var i = 0
        for (const file of fileList) {
            const compressedFile = await new Promise((resolve) => {
                new Compressor(file.originFileObj, {
                    quality: 0.1, // เช่น, 60%
                    success: (result) => {
                        resolve(result);
                    },
                });
            });
            var image = {}
            var filename = file.name
            var image64 = await getBase64(compressedFile)
            //var image64 = file.originFileObj
            image.filename = filename
            image.image64 = image64
            image.type = `5.${indexedit1 + 1}`
            imageListGuarantors.push(image)
            i++
        }
        setFileListGuarantors1({ ...fileListGuarantors1, imageListGuarantors });
        setLoading(false)
    };
    const success = () => {
        Modal.success({
            content: 'บันทึกสำเร็จ',
            // path: navigate('/checkInfoCar')
        })
    }

    const axi = async (InputImage) => {
        console.log("InputImage", InputImage)
        setLoading(true)
        await axios.post(uploadImgGuaTable, InputImage)
            .then(response => {
                if (response) {
                    console.log("response", response)
                    sb(false)
                    setLoading(false)
                    setTimeout(() => {
                        close(false);
                    }, 1500)
                }
            }).catch((err) => {
                console.log("err", err)
                setLoading(false)
            })
    }

    // const DataImagesGu = ({ images1, imageListGuarantorPush }) => {
    const DataImagesGu = async ({ data }) => {
        console.log("data", data)
        setIndexedit1(indexedit1 + 1)

        let AddData2
        if (guarantors?.length === 0) {
            let path = `one_images/land/${getData.lands.landId}/5/${indexedit + 1}`
            console.log("path1", path)
            AddData2 = [{ ...data[0], pathImg: path }];
            //รอเซตเข้าฐานข้อมูล path

        } else if (guarantors?.length >= 1) {
            let path = `one_images/land/${getData.lands.landId}/5/${indexedit + 1}`
            console.log("path2", path)
            AddData2 = [{ ...data[0], pathImg: path }];//เก็บค่า state เดิมไว้ พร้อมส่ง type ไปด้วย เพื่อบอกค่าที่ส่ง
            // console.log("AddData2", AddData2)
            //รอเซตเข้าฐานข้อมูล path
        }
        const InputImage = {
            mainImg: { carId: (getData.lands.landId).toString(), carInput: dayjs().format("YYYY-MM-DDTHH:mm:ssZ"), productTypeId: 2, img: data[0].imgs }
        }

        // เรียกใช้ function axi
        await axi(InputImage)
        // getAddData({ AddData2, indexedit1 })
        setLoading(false)
        close(false)

    }

    // ส่งค่าไปหน้าหลักของคนค้ำ
    const CheckImgGua = () => {
        const imageListGuarantorPush = []
        // imageListGuarantorPush.push(...fileListGuarantor1?.imageListGuarantor)
        if (fileListGuarantor1?.imageListGuarantor) {
            imageListGuarantorPush.push(...fileListGuarantor1?.imageListGuarantor)
        }
        if (fileListGuarantors1?.imageListGuarantors) {
            imageListGuarantorPush.push(...fileListGuarantors1?.imageListGuarantors)
        }
        console.log("imageListGuarantorPush", imageListGuarantorPush)

        var data = []
        if (guarantors?.length === 0) {
            data.push({ ...data[0] })
            console.log("กรณียังไม่มีคนค้ำ")
        } else {
            data.push({ ...data[0], ...images1[0], imgs: imageListGuarantorPush }) //ต่อ array
            console.log("กรณีมีคนค้ำแล้ว",)
        }
        DataImagesGu({ data })
    }


    const handleCheckData = async () => {
        // console.log("guarantors",guarantors)
        setLoading(true)
        if (guarantors !== undefined) {
            let checkidentificationId = 0
            let checkidentificationId13 = 0
            let checkfirstname = 0
            let checklastname = 0
            let checkphones = 0
            let checknickname = 0
            guarantors.map((e, index) => {
                if (e.identificationId === undefined || e.identificationId === "") {
                    checkidentificationId = 1
                    const CheckIdentificationIdGua = (placement) => {
                        api.error({
                            message: <b>กรุณาเพิ่มเลขบัตรประชาชนของผู้ค้ำ</b>,
                            placement,
                        });
                    }
                    CheckIdentificationIdGua("top")
                } else if (e.identificationId.length !== 13) {
                    checkidentificationId13 = 1
                    const CheckIdentificationId13Gua = (placement) => {
                        api.error({
                            message: <b>กรุณากรอกเลขประจำตัวประชาชนให้ครบ 13 หลัก</b>,
                            placement,
                        });
                    }
                    CheckIdentificationId13Gua("top")
                }
                if (e.firstname === undefined || e.firstname === "") {
                    checkfirstname = 1
                    const CheckFirstnameGua = (placement) => {
                        api.error({
                            message: <b>กรุณาเพิ่มชื่อของผู้ค้ำ</b>,
                            placement,
                        });
                    }
                    CheckFirstnameGua("top")
                }
                if (e.lastname === undefined || e.lastname === "") {
                    checklastname = 1
                    const CheckLastNameGua = (placement) => {
                        api.error({
                            message: <b>กรุณาเพิ่มนามสกุลของผู้ค้ำ</b>,
                            placement,
                        });
                    }
                    CheckLastNameGua("top")
                }
                if (e.nickname === undefined || e.nickname === "") {
                    checknickname = 1
                    const CheckNickNameGua = (placement) => {
                        api.error({
                            message: <b>กรุณาเพิ่มชื่อเล่นของผู้ค้ำ</b>,
                            // description: `กรุณาเพิ่มที่อยู่ผู้ค้ำ ลำดับที่ ${index + 1}`,
                            placement,
                        });
                    }
                    CheckNickNameGua("top")
                }
                if (e.phones !== undefined) {
                    if (e.phones[0].telp === undefined || e.phones[0].telp === "") {
                        checkphones = 1
                        const CheckPhnGua = (placement) => {
                            api.error({
                                message: <b>กรุณาเพิ่มเบอร์โทรศัพท์ของผู้ค้ำ</b>,
                                placement,
                            });
                        }
                        CheckPhnGua("top")
                    }
                }
                // else {
                //     checknickname = 1
                //     const CheckPhnGua = (placement) => {
                //         api.error({
                //             message: <b>กรุณาเพิ่มเบอร์โทรศัพท์ของผู้ค้ำ</b>,
                //             placement,
                //         });
                //     }
                //     CheckPhnGua("top")
                // }

            })
            if (checkfirstname === 0 && checklastname === 0 && checkphones === 0 && checknickname === 0 && checkidentificationId === 0 && checkidentificationId13 === 0) {
                // console.log('OK', guarantors)
                await axios.post(addcusforgua, guarantors)
                    .then(res => {
                        if (res.status === 200) {
                            CheckImgGua()
                            // sendback(dayjs())
                            // SuccGua("top")
                            setLoading(false)
                            // setCheckSave(false)
                        }
                    })
                    .catch((err) => {
                        console.log("err", err)
                        setLoading(false)
                    })
            }
        }
        setLoading(false)
    }

    // console.log('guarantors.phones', guarantors?.phones)
    const handleIdCard = async (value) => {
        // console.log("value", value)
        const sumOne = { identificationId: value }
        setLoading(true)
        if (oldGua !== undefined) {
            oldGua?.map((e) => {
                if (e.identificationId === sumOne?.identificationId) {
                    countGu = 1
                    console.log("บัตรคนค้ำซ้ำกัน")
                    setGuarantors([{ ...guarantors[0], identificationId: "", phones: [] }])
                    form.setFieldsValue({ identificationId: "", telp: "" })
                    CheckGuERS('top')
                }
            })
        }
        if (countGu === 0) {
            console.log("ตอนกรอกเองไม่ซ้ำ")
            if (sumOne?.identificationId === getData.identificationId) {
                CheckGuER('top')
                setGuarantors([{ ...guarantors[0], identificationId: "", phones: [] }])
                form.setFieldsValue({ identificationId: "", telp: "" })
            } else {
                await axios.post(checkNewCCAP, sumOne)
                    .then((res) => {
                        console.log("res", res.data)
                        if (res.data) {
                            // console.log("res.data",res.data)
                            delete res.data.address
                            delete res.data.emails
                            delete res.data.ojs
                            delete res.data.socials
                            message.info('มีข้อมูลในฐานข้อมูล')
                            setCheckIdCard(false)
                            setDataPostGuarantor({ ...dataPostGuarantor, ...res.data })
                            // setGuarantors([res.data])
                            setImage1([{
                                ...images1[0], ...res.data,
                            }])
                            setGuarantors([{
                                ...guarantors[0], ...res.data, checkDup: true
                            }])
                        }
                        else {
                            message.info('ไม่พบข้อมูลในฐานข้อมูล1')
                            setCheckIdCard(true)
                            // setDataPostGuarantor({ ...dataPostGuarantor })
                            setGuarantors([{ ...guarantors[0], identificationId: value, customerId: 0, phones: [], checkDup: false }])
                            form.setFieldsValue({ telp: "" })
                        }
                    })
                    .catch((err) => console.log(err))
            }
        }
        setLoading(false)
    }
    const handleIdentificationId = async (e) => {
        if (e.target.value.length === 13) {
            await handleIdCard(e.target.value)
        } else {
            setGuarantors([{ ...guarantors[0], identificationId: e.target.value }])
        }
    }

    const handlePhoneChange = (e) => {
        const updatedGuarantors = [...guarantors];
        // if (guarantors[0]?.phones !== undefined) {
        if (guarantors[0]?.phones?.length > 0) {
            updatedGuarantors[0].phones[0].telp = e.target.value;
            setGuarantors(updatedGuarantors);
        }
        else {
            setGuarantors([{ ...guarantors[0], phones: [{ telp: e.target.value, phoneType: "เบอร์ติดต่อ", sendSms: "1" }] }])
        }
    };

    const genGuarantors = ({ item, index }) => {
        return (
            <Form
                name="basic"
                labelCol={{
                    span: 12,
                }}
                wrapperCol={{
                    span: 24,
                }}
                style={{
                    maxWidth: '100%',
                }}
                initialValues={{
                    remember: true,
                }}
                form={form}
                autoComplete="off"
            >
                <Card style={{ width: '100%' }}
                >
                    <Row justify={'center'}><b><u>คนค้ำที่ {index + 1}</u></b></Row>
                    <div style={{ textAlign: 'center' }}>
                        <Form.Item style={{ margin: 3 }} label='เลขบัตรประชาชน' name='identificationId'>
                            <Input
                                maxLength={13}
                                disabled={CheckIdCard ? false : true}
                                style={{ color: 'black' }}
                                type='text'
                                name='identificationId'
                                onChange={(e) => { handleIdentificationId(e) }}
                            >
                            </Input>
                        </Form.Item>
                    </div>
                    <Row justify={'center'} className='main2'>
                        <aside style={{ width: '100%', }} >
                            <div style={{ textAlign: 'left' }}>
                                <Form.Item style={{ margin: 3 }} label='ชื่อ' name='firstname'>
                                    <Input
                                        style={{ color: 'black' }}
                                        type='text'
                                        name='firstname'
                                        onChange={(e) => setGuarantors([{ ...guarantors[0], firstname: e.target.value }])}
                                    >
                                    </Input>
                                </Form.Item>
                            </div>
                            <div style={{ textAlign: 'left' }}>
                                <Form.Item style={{ margin: 3 }} label='นามสกุล' name='lastname'>
                                    <Input
                                        // disabled
                                        style={{ color: 'black' }}
                                        type='text'
                                        name='lastname'
                                        onChange={(e) => setGuarantors([{ ...guarantors[0], lastname: e.target.value }])}
                                    >
                                    </Input>
                                </Form.Item>
                            </div>
                            <div style={{ textAlign: 'left' }}>
                                <Form.Item style={{ margin: 3 }} label='ชื่อเล่น' name='nickname'>
                                    <Input
                                        name='nickname'
                                        style={{ color: 'black' }}
                                        onChange={(e) => setGuarantors([{ ...guarantors[0], nickname: e.target.value }])}
                                    >
                                    </Input>
                                </Form.Item>
                            </div>
                            <div style={{ textAlign: 'left' }}>
                                <Form.Item
                                    label='เบอร์โทร'
                                    style={{ margin: 3 }}
                                    name='telp'>
                                    <Input
                                        name='telp'
                                        style={{ color: 'black' }}
                                        onChange={handlePhoneChange}
                                    // onChange={(e) => setGuarantors([{ ...guarantors[0], {...guarantors[0].phones[0],telp: e.target.value}}])}
                                    >
                                    </Input>
                                </Form.Item>
                            </div>
                        </aside>
                    </Row>
                </Card>
            </Form >
        )
    }

    return (
        <>
            <Modal open={open} onCancel={handleCancel} width={1000} footer={[null]}>
                <Spin spinning={loading} size='large' tip=" Loading... ">
                    <Form
                        name="basic"
                        labelCol={{
                            span: 12,
                        }}
                        wrapperCol={{
                            span: 24,
                        }}
                        style={{
                            width: '100%',
                        }}
                        initialValues={{
                            remember: true,
                        }}
                        form={form1}
                        autoComplete="off"
                    >
                        <Row justify={'center'}><b><u>เพิ่มข้อมูลคนค้ำ</u></b></Row>
                        <Divider style={{ margin: 5 }} />
                        <Row justify={'center'} className='main2'>
                            <Col span={22}>
                                <aside>
                                    <b>
                                        <div>
                                            <Row justify={'center'}>
                                                {guarantors?.length > 0 ?
                                                    <>
                                                        {guarantors?.map((item, index) => {
                                                            return genGuarantors({ item, index, key: `{item.identificationId} - ${index}` });
                                                        })}
                                                    </>
                                                    :
                                                    null
                                                }
                                            </Row>
                                            <Divider />
                                            {
                                                guarantors?.length >= 1 ?
                                                    <>
                                                        {fileListGuarantor1?.imageListGuarantor?.map((item, index) => {
                                                            // console.log("item", item)
                                                            return (
                                                                <>
                                                                    <Row justify={'left'} > <span><b>รูปบัตรประชาชนผู้ขอกู้ :</b></span></Row>
                                                                    <Row justify={'center'}>
                                                                        <Col span={24} style={{ textAlign: 'center' }}>
                                                                            <img width={'300px'} key={index} style={{ margin: 5 }} src={createImageUrl(item.image64)} alt={item.filename} />
                                                                        </Col>
                                                                    </Row>
                                                                </>
                                                            )
                                                        })}

                                                    </>

                                                    :
                                                    <Form.Item
                                                        label={<u>รูปบัตรประชาชนคนค้ำ ( 1 รูป)</u>}
                                                        name='imgGuarantor'
                                                    >
                                                        <Upload
                                                            style={{ textAlign: 'center' }}
                                                            listType="picture-card"
                                                            fileList={fileListGuarantor}
                                                            onPreview={handlePreview}
                                                            onChange={handleChangeGuarantor1}
                                                            beforeUpload={() => false}
                                                        >
                                                            {fileListGuarantor?.length >= 1 ? null : (
                                                                <div>
                                                                    <PlusOutlined />
                                                                    <div className="ant-upload-text">upload</div>
                                                                </div>
                                                            )}
                                                        </Upload>
                                                        <Modal open={previewVisible} onCancel={handleCancel1}>
                                                            <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                        </Modal>
                                                    </Form.Item>
                                            }
                                        </div>
                                        <Divider />
                                        <div>
                                            <Row justify={'left'} > <span><b>รูปเอกสารผู้ค้ำ :</b></span></Row>
                                            <Row justify={'center'}>
                                                <Form.Item
                                                    label=''
                                                    name='imgGuarantor'
                                                    style={{ textAlign: 'center' }}
                                                >
                                                    <Upload
                                                        multiple={true}
                                                        listType="picture-card"
                                                        fileList={fileListGuarantors}
                                                        onPreview={handlePreview}
                                                        onChange={handleChangeGuarantors1}
                                                        beforeUpload={() => false}
                                                    >
                                                        {fileListGuarantors?.length >= 10 ? null : (
                                                            <div>
                                                                <PlusOutlined />
                                                                <div className="ant-upload-text">upload</div>
                                                            </div>
                                                        )}
                                                    </Upload>
                                                    <Modal open={previewVisible} onCancel={handleCancel1}>
                                                        <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                    </Modal>
                                                </Form.Item>
                                            </Row>
                                        </div>
                                    </b>
                                </aside>
                            </Col>
                        </Row>
                        <Divider />
                        <Row>
                            <Col span={24} style={{ textAlign: 'center' }}>
                                <Space>
                                    <Button type="primary" onClick={handleCancel} style={{ background: "red" }}>ยกเลิก</Button>
                                    <Button type="primary" onClick={handleCheckData} style={{ background: "green" }} >บันทึก</Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
                {contextHolder}
            </Modal>
        </>

    )

}

export default AddGua