import React, { useState, useEffect } from "react";
import {
  Col,
  Input,
  Row,
  Table,
  Spin,
  Space,
  Card,
  Divider,
  Select,
  message,
  Progress,
  Tag,
} from "antd";
import { InfoCircleFilled, InfoCircleOutlined } from "@ant-design/icons";
import axios from "axios";
import "../css/MobileActive.css";
import "../css/buttonNickie.css";
import { acceptceo, loadMainBoss } from "../file_mid/all_api";
import Approval from "./modal/Approval";
import moment from "moment";

const Main_Boss = () => {
  const user = localStorage.getItem("username");
  const [arrayTable, setArrayTable] = useState();
  const [totalPage, setTotalPage] = useState();
  const [isModalApproval, setIsModalApproval] = useState(false);
  const [dataArr, setDataArr] = useState([]);
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");
  const [countStatus, setCountStatus] = useState(2);
  const [dataSearch, setDataSearch] = useState([]);
  const [dataSend, setDataSend] = useState({});
  const [selectedValue, setSelectedValue] = useState(0);
  const [dataContno, setDataContno] = useState();
  const [statusApproval, setStatusApproval] = useState({
    waitAccept: 0,
    approval: 0,
    approved: 0,
    percentWaitAccept: 0,
    percentApproval: 0,
    percentApproved: 0,
  });

  useEffect(() => {
    if (selectedValue === 0) {
      loadData();
      // console.log("Main_Boss1");
    }
  }, [selectedValue]);

  const search = (event) => {
    // console.log("Main_Boss2");
    onSearch(event.target.value);
  };

  const onSearch = (value) => {
    if (selectedValue === 0) {
      let result = dataArr.filter((item) => item.CONTNO.includes(value));
      // console.log("result", result);
      setArrayTable(result);
    } else {
      let result = dataSearch.filter((item) => item.CONTNO.includes(value));
      // console.log("result", result);
      setArrayTable(result);
    }
  };

  const handleSelectedStatus = (value) => {
    if (value === 3) {
      let statusAll = dataArr.filter((item) => item.approvalStatus === 3 || item.approvalStatus === 4);
      statusAll.sort((a, b) => new Date(b.approvalDate) - new Date(a.approvalDate));
      setArrayTable(statusAll);
      setDataSearch(statusAll);
    } else if (value === 28) {
      let statusAll = dataArr.filter((item) => item.approvalStatus === 28 );
      statusAll.sort((a, b) => new Date(a.offerDate) - new Date(b.offerDate));
      setArrayTable(statusAll);
      setDataSearch(statusAll);
    } else {
      let statusAll = dataArr.filter((item) => item.approvalStatus === value);
      setArrayTable(statusAll);
      setDataSearch(statusAll);
    }
  };

  const onSelect = (value) => {
    // console.log("v", value)
    setSelectedValue(value);
    if (value !== 0) {
      handleSelectedStatus(value);
    } else {
      loadData();
      // console.log("Main_Boss3");
    }
  };

  const handleChangeStatus = (data, status) => {
    // console.error("TEST", data);
    if (status !== 0) {
      const result = dataArr.map((item) => {
        if (item.CONTNO === data.CONTNO) {
          return { ...data };
        } else {
          return { ...item };
        }
      });
      setDataArr(result);
      handleStatusApproval(result);
      const arr = result.filter((item) => item.approvalStatus === status);
      setArrayTable(arr);
    } else {
      loadData();
      console.log("handleChangeStatus loadData");
    }
  };

  const loadData = async (data) => {
    setLoading(true);
    const tk = JSON.parse(token);
    const url = loadMainBoss;
    const statusParams = { approvalStatus: countStatus };
    const headers = {
      Authorization: `Bearer ${tk}`,
      Menu: JSON.stringify("8"),
    };

    await axios
      .post(url, { statusParams }, { headers: headers })
      .then(async (res) => {
        // console.log('res.data', res.data)
        if (res.data) {
          // console.log(res.data.data);
          let result = res.data.data.filter(
            (item) =>
              item.approvalStatus === 3 ||
              item.approvalStatus === 2 ||
              item.approvalStatus === 28 ||
              item.approvalStatus === 4
          );
          // console.log("result", result);
          setArrayTable(result);
          defaultStatusApproval(result);
          setDataArr(result);
          setTotalPage(result.length);
        }
      })
      .catch((err) => console.log(err));

    setLoading(false);
  };

  const sendStatus = async (data, contno) => {
    setLoading(true);
    const tk = JSON.parse(token);
    const url = acceptceo;
    const headers = {
      Authorization: `Bearer ${tk}`,
      Menu: JSON.stringify("8"),
    };

    await axios
      .post(
        url,
        {
          //แก้กลับไป
          id: data.id,
          acceptBy: user,
          CONTNO: data.CONTNO,
          offerBy: data.offerBy,
          proposalBy: data.proposalBy,
        },
        { headers: headers }
      )
      .then(async (res) => {
        handleChangeStatus({ ...data, approvalStatus: 2 }, selectedValue);
        message.success(`รับงานสำเร็จ  เลขที่สัญญาที่: ${contno}  `);
        setTimeout(() => { }, 2000);
      })
      .catch((err) => console.log(err));

    setLoading(false);
  };

  const defaultStatusApproval = (data) => {
    if (data) {
      let countWaitAccept = 0;
      let countApproval = 0;
      let countApproved = 0;

      data.map((item) => {
        if (item.approvalStatus === 28) {
          countWaitAccept++;
        } else if (item.approvalStatus === 2) {
          countApproval++;
        } else if (item.approvalStatus === 3) {
          countApproved++;
        } else if (item.approvalStatus === 4) {
          countApproved++;
        }
      });

      let waitAcceptItems = data.filter((item) => item.approvalStatus === 28);
      let totalItemswaitAccept = data.length;
      let resultWaitAccept =
        (waitAcceptItems.length / totalItemswaitAccept) * 100;

      if (resultWaitAccept === 0) {
        resultWaitAccept = 100;
      } else if (resultWaitAccept === 100) {
        resultWaitAccept = 0;
      }
      let approvalItems = data.filter((item) => item.approvalStatus === 2);
      let totalItemsapproval = data.length;
      let resultApproval = (approvalItems.length / totalItemsapproval) * 100;
      if (resultApproval === 0) {
        resultApproval = 100;
      } else if (resultApproval === 100) {
        resultApproval = 0;
      }

      let approvedItems = data.filter(
        (item) => item.approvalStatus === 3 || item.approvalStatus === 4
      );
      // console.log(approvedItems);
      let totalItemsapproved = data.length;
      let resultApproved = (approvedItems.length / totalItemsapproved) * 100;
      if (resultApproved === 0) {
        resultApproved = 100;
      } else if (resultApproved === 100) {
        resultApproved = 0;
      }

      setStatusApproval({
        waitAccept: countWaitAccept,
        approval: countApproval,
        approved: countApproved,
        percentWaitAccept: resultWaitAccept.toFixed(2),
        percentApproval: resultApproval.toFixed(2),
        percentApproved: resultApproved.toFixed(2),
      });
    }
  };

  const handleStatusApproval = (data) => {
    if (data) {
      let countWaitAccept = 0;
      let countApproval = 0;
      let countApproved = 0;
      data.map((item) => {
        if (item.approvalStatus === 28) {
          countWaitAccept++;
        } else if (item.approvalStatus === 2) {
          countApproval++;
        } else if (item.approvalStatus === 3) {
          countApproved++;
        } else if (item.approvalStatus === 4) {
          countApproved++;
        }
      });

      let waitAcceptItems = data.filter((item) => item.approvalStatus === 28);
      let totalItemswaitAccept = data.length;
      let resultWaitAccept =
        (waitAcceptItems.length / totalItemswaitAccept) * 100;
      if (resultWaitAccept === 0) {
        resultWaitAccept = 100;
      } else if (resultWaitAccept === 100) {
        resultWaitAccept = 0;
      }

      let approvalItems = data.filter((item) => item.approvalStatus === 2);
      let totalItemsapproval = data.length;
      let resultApproval = (approvalItems.length / totalItemsapproval) * 100;
      if (resultApproval === 0) {
        resultApproval = 100;
      } else if (resultApproval === 100) {
        resultApproval = 0;
      }

      let approvedItems = data.filter((item) => item.approvalStatus === 3 || item.approvalStatus === 4);
      console.log(approvedItems);
      let totalItemsapproved = data.length;
      let resultApproved = (approvedItems.length / totalItemsapproved) * 100;
      if (resultApproved === 0) {
        resultApproved = 100;
      } else if (resultApproved === 100) {
        resultApproved = 0;
      }

      setStatusApproval({
        ...statusApproval,
        waitAccept: countWaitAccept,
        approval: countApproval,
        approved: countApproved,
        percentWaitAccept: resultWaitAccept.toFixed(2),
        percentApproval: resultApproval.toFixed(2),
        percentApproved: resultApproved.toFixed(2),
      });
      console.log("setStatusApproval2", statusApproval);
    }
  };

  const columns = [
    {
      title: "ลำดับ",
      dataIndex: "id",
      key: "id",
      align: "center",
      width: "5%",
      render: (text, record, index) => index + 1,
      sorter: (a, b) => a.id - b.id,
    },

    {
      title: "เลขที่สัญญา",
      dataIndex: "CONTNO",
      key: "CONTNO",
      align: "center",
      width: "15%",
      render: (text, record) => {
        return <>{record.CONTNO ? record.CONTNO : "-"}</>;
      },
    },
    {
      title: "วันที่เสนอเคส",
      dataIndex: "offerDate",
      key: "offerDate",
      align: "center",
      width: "10%",
      render: (text, record) => {
        return (
          <>
            <>
              วันที่{" "}
              {moment(record.offerDate).add(543, "year").format("DD/MM/YYYY ")}
            </>
            <br />
            <>เวลา {moment(record.offerDate).format("HH:mm:ss ")}</>
          </>
        );
      },
      sorter: (a, b) => moment(a.offerDate).unix() - moment(b.offerDate).unix(),
    },
    {
      title: "ผู้รับผิดชอบ",
      dataIndex: "offerBy",
      key: "offerBy",
      align: "center",
      width: "30%",
      render: (text, record) => (
        <>
          <b>ผู้เสนอเคส :</b> {record.offerBy ? record.offerBy : "-"}
          <br />
          <b>การตลาด :</b> {record.proposalBy ? record.proposalBy : "-"}
          <br />
          <b>ธุรการ :</b> {record.reviewedBy ? record.reviewedBy : "-"}
          <br />
          <b>ผู้อนุมัติ :</b> {record.approvedBy ? record.approvedBy : "-"}
        </>
      ),
    },
    {
      title: "วันที่อนุมัติ",
      dataIndex: "approvalDate",
      key: "approvalDate",
      align: "center",
      width: "10%",
      render: (text, record) => (
        <div>
          วันที่{" "}
          {record.approvalDate
            ? moment(record.approvalDate).add(543, "year").format("DD/MM/YYYY ")
            : "-"}
          <br />
          เวลา{" "}
          {record.approvalDate
            ? moment(record.approvalDate).format("HH:mm:ss")
            : "-"}
        </div>
      ),
      sorter: (a, b) =>
        moment(a.approvalDate).unix() - moment(b.approvalDate).unix(),
    },

    {
      title: "สถานะสัญญา/การจัดการ",
      dataIndex: "approvalStatus",
      key: "approvalStatus",
      align: "center",
      width: "30%",
      render: (text, record) => (
        <>
          {record.approvalStatus === 3 || record.approvalStatus === 4 ? (
            <button
              className="myButtonGreen "
              onClick={() => {
                setDataSend(record);
                setDataContno(record.CONTNO);
                setIsModalApproval(true);
                console.log("อนุมัติแล้ว", record);
              }}
            >
              อนุมัติแล้ว
            </button>
          ) : record.approvalStatus === 2 ? (
            <button
              className="myButtonYellow "
              onClick={() => {
                setDataSend(record);
                setDataContno(record.CONTNO);
                setIsModalApproval(true);
                console.log("รออนุมัติ", record);
              }}
            >
              รออนุมัติ
            </button>
          ) : record.approvalStatus === 28 ? (
            <button
              className="myButtonOriginal "
              onClick={() => {
                sendStatus(record, record.CONTNO);
                console.log("รับงาน", record);
              }}
            >
              รอรับงาน
            </button>
          ) : null}
        </>
      ),
    },
  ];

  return (
    <>
      <Card>
        <Row gutter={32} style={{ textAlign: "center" }}>
          <Col>
            <Spin spinning={loading} size="large" tip=" Loading... ">
              <Row gutter={32} justify={'center'}>
                <Col>
                  <h2>ข้อมูลการขออนุมัติ</h2>
                </Col>
              </Row>
              <Row gutter={24} justify={'center'} className="mains" style={{ margin: 10 }}>
                <Col span={20}>
                  <aside>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column', // จัดวาง div แต่ละอันในแนวตั้ง
                        alignItems: 'center', // จัดให้อยู่ตรงกลางในแนวนอน
                        justifyContent: 'center', // จัดให้อยู่ตรงกลางในแนวตั้ง

                      }}
                    >
                      <Tag
                        style={{
                          backgroundColor: "#f5f5f5",
                          margin: "5px",
                          width: '160px',
                          height: '30px',
                          display: 'flex', // ใช้ Flexbox
                          justifyContent: 'center', // จัดกึ่งกลางในแนวนอน
                          alignItems: 'center', // จัดกึ่งกลางในแนวตั้ง
                          textAlign: 'center', // จัดการข้อความให้อยู่ตรงกลาง
                          fontSize: '15px'
                        }}
                        // style={{ backgroundColor: "#f5f5f5", margin: "5px" }}
                        onClick={() => {
                          handleSelectedStatus(28);
                          setSelectedValue(28);
                        }}
                      >
                        <b>{"รอรับงาน "}
                          {statusApproval.waitAccept ? statusApproval.waitAccept : "0"}
                          {" "}
                          เคส</b>
                      </Tag>
                      <Progress
                        title="รอรับงาน"
                        percent={statusApproval.percentWaitAccept}
                        size="small"
                      />
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column', // จัดวาง div แต่ละอันในแนวตั้ง
                        alignItems: 'center', // จัดให้อยู่ตรงกลางในแนวนอน
                        justifyContent: 'center', // จัดให้อยู่ตรงกลางในแนวตั้ง

                      }}
                    >
                      <Tag
                        style={{
                          backgroundColor: "yellow",
                          margin: "5px",
                          width: '160px',
                          height: '30px',
                          display: 'flex', // ใช้ Flexbox
                          justifyContent: 'center', // จัดกึ่งกลางในแนวนอน
                          alignItems: 'center', // จัดกึ่งกลางในแนวตั้ง
                          textAlign: 'center', // จัดการข้อความให้อยู่ตรงกลาง
                          fontSize: '15px'
                        }}
                        // style={{ backgroundColor: "yellow", marginTop: "5px" }}
                        onClick={() => {
                          handleSelectedStatus(2);
                          setSelectedValue(2);
                        }}
                      >
                        <b>{"รออนุมัติ "}{" "}
                          {statusApproval.approval ? statusApproval.approval : "0"}{" "}
                          เคส</b>
                      </Tag>
                      <Progress
                        title="รออนุมัติ"
                        percent={statusApproval.percentApproval}
                        size="small"
                      />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column', // จัดวาง div แต่ละอันในแนวตั้ง
                        alignItems: 'center', // จัดให้อยู่ตรงกลางในแนวนอน
                        justifyContent: 'center', // จัดให้อยู่ตรงกลางในแนวตั้ง

                      }}
                    >
                      <Tag
                        style={{
                          backgroundColor: "#72f70d",
                          margin: "5px",
                          width: '160px',
                          height: '30px',
                          display: 'flex', // ใช้ Flexbox
                          justifyContent: 'center', // จัดกึ่งกลางในแนวนอน
                          alignItems: 'center', // จัดกึ่งกลางในแนวตั้ง
                          textAlign: 'center', // จัดการข้อความให้อยู่ตรงกลาง
                          fontSize: '15px'
                        }}
                        onClick={() => {
                          handleSelectedStatus(3);
                          setSelectedValue(3);
                        }}
                      >
                        <b>{"อนุมัติแล้ว"}{" "}
                          {statusApproval.approved ? statusApproval.approved : "0"}{" "}
                          เคส</b>
                      </Tag>
                      <Progress
                        title="อนุมัติแล้ว"
                        percent={statusApproval.percentApproved}
                        size="small"
                      />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column', // จัดวาง div แต่ละอันในแนวตั้ง
                        alignItems: 'center', // จัดให้อยู่ตรงกลางในแนวนอน
                        justifyContent: 'center', // จัดให้อยู่ตรงกลางในแนวตั้ง

                      }}
                    >
                      <Col>
                        <Tag
                          style={{
                            backgroundColor: "#18ffff",
                            margin: "5px",
                            width: '160px',
                            height: '30px',
                            display: 'flex', // ใช้ Flexbox
                            justifyContent: 'center', // จัดกึ่งกลางในแนวนอน
                            alignItems: 'center', // จัดกึ่งกลางในแนวตั้ง
                            textAlign: 'center', // จัดการข้อความให้อยู่ตรงกลาง
                            fontSize: '15px'
                          }}
                          onClick={() => {
                            loadData();
                            setSelectedValue(0);
                            console.log("Main_Boss4");
                          }}
                        >
                          <b>

                            {`เคสทั้งหมด ${totalPage} เคส`}
                          </b>
                        </Tag>
                        {/* <Progress
                          title="อนุมัติแล้ว"
                          percent={100}
                          size="small"
                        /> */}
                      </Col>
                    </div>

                  </aside>
                </Col>

              </Row>
              <Row justify={'center'} className="main4" style={{ margin: 15 }}>
                <aside>
                  {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}> */}
                  <div style={{ textAlign: "right", margin: 5 }}>
                    <Select
                      defaultValue="ทั้งหมด"
                      style={{
                        width: 200,
                        height: '40px',
                        fontSize: '18px'
                      }}
                      value={selectedValue}
                      onChange={onSelect}
                      options={[
                        {
                          value: 0,
                          label: "ทั้งหมด",
                        },
                        {
                          value: 3,
                          label: "อนุมัติแล้ว",
                        },
                        {
                          value: 2,
                          label: "รออนุมัติ",
                        },
                        {
                          value: 28,
                          label: "รอรับงาน",
                        },
                      ]}
                    />
                  </div>
                  <div style={{ textAlign: "left", margin: 5 }}>
                    <Input.Search
                      style={{ width: "200px" }}
                      placeholder="ค้นหา...."
                      onChange={search}
                      name="search"
                      id="search"
                    />
                    {/* </div> */}
                  </div>
                </aside>
              </Row>
              <Row gutter={32}>
                <Col span={24}>
                  <Table
                    columns={columns}
                    Key={(record) => record.uid}
                    scroll={{
                      x: 1500,
                      y: 400,
                    }}
                    dataSource={arrayTable}
                  ></Table>
                  <Divider />
                  <div style={{ textAlign: "center" }}>
                    <p>
                      <Space style={{ fontSize: '16px' }}>
                        <InfoCircleOutlined
                          title="สีบอกไม่ผ่านการอนุมัติ"
                          style={{ marginRight: "3px" }}
                        />
                        รอรับงาน
                        <InfoCircleFilled
                          title="สีบอกรออนุมัติ"
                          style={{
                            color: "yellow",
                            marginRight: "3px",
                            marginLeft: "3px",
                          }}
                        />
                        รออนุมัติ
                        <InfoCircleFilled
                          title="อนุมัติ"
                          style={{
                            color: "#64FF00",
                            marginRight: "3px",
                            marginLeft: "3px",
                          }}
                        />
                        อนุมัติแล้ว
                      </Space>
                    </p>
                  </div>
                  <b>จำนวนทั้งหมด {totalPage + " เคส"}</b>
                  <Row justify={"center"}></Row>
                  <Divider />
                </Col>
              </Row>
            </Spin>
          </Col>
        </Row>
      </Card >
      {
        isModalApproval ? (
          <Approval
            open={isModalApproval}
            close={setIsModalApproval}
            data={dataSend}
            contno={dataContno}
            onChangeStatus={handleChangeStatus}
            status={selectedValue}
          />
        ) : null
      }
    </>
  );
};

export default Main_Boss;
