import { Button, Modal, Row, Col, Form, Divider, Card, Space, Input, Radio, notification } from 'antd'
import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { checklandformix } from '../../file_mid/all_api';


function ModalAddLandRe({ open, close, dataFromTable, funcAdd, dataEdit, checkEdit, setCheckEdit, funcEdit, dataEditIndex, dataForCheckDup }) {
    const [form] = Form.useForm()
    const [foundInfo, setFoundInfo] = useState(false)
    const [showOldData, setShowOldData] = useState()
    const [pickLand, setPickLand] = useState();
    const [selectedRadioIndex, setSelectedRadioIndex] = useState();
    const [api, contextHolder] = notification.useNotification();

    const [dataForCheck, setDataForCheck] = useState({
        provinces: dataFromTable?.province,
        district: dataFromTable?.district,
        proposalBy: dataFromTable?.proposalBy
    })

    useEffect(() => {
        if (checkEdit) {
            setFoundInfo(true)
            setShowOldData([dataEdit])
        }
        form.setFieldsValue({
            province: dataFromTable?.province,
            district: dataFromTable?.district,
        })
    }, [])

    const handleCancel = () => {
        setCheckEdit(false)
        close(false);
    };

    const errorDup = (placement) => {
        api.warning({
            message: "ข้อมูลซ้ำกับทำมีอยู่แล้ว",
            description:
                'กรุณาเลือกที่ดินอื่น',
            placement,
        });
    };

    const loadDataDup = async () => {
        setSelectedRadioIndex(null)
        await axios.post(checklandformix, dataForCheck)
            .then(res => {
                if (res.status === 200) {
                    if (res.data.length > 0) {
                        setFoundInfo(true)
                        setShowOldData(res.data)
                    } else {
                        setShowOldData([])
                    }
                }
            }).catch((err) => {
                console.log("err", err)
            })
    }

    const currencyFormatOne = (amount) => {
        return Number(amount)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, '$&,')
    }

    const handleSelect = (v, i) => {
        setSelectedRadioIndex(i)
        setPickLand(v)
    };

    const handleSubmit = () => {
        if (pickLand !== undefined) {
            console.log("pickLand", pickLand)
            var check = 0
            dataForCheckDup.map((e, index) => {
                if (e.landId === pickLand.landId) {
                    check = 1
                }
            })
            if (check === 0) {
                if (checkEdit) {
                    funcEdit(pickLand, dataEditIndex)
                    handleCancel()
                } else {
                    funcAdd(pickLand)
                    handleCancel()
                }
            } else {
                errorDup("top")
                console.log("no")
            }

        }
    };

    const renderLandData = ({ item, index }) => {

        return (
            <>
                {/* <Row gutter={32} justify={'center'}> */}
                <Col className='gutter-row' span={24}>
                    <>
                        {/* <Card key={index} style={{ width: '100%', textAlign: 'left' }}> */}
                        <Col style={{ marginBottom: 0, textAlign: 'right' }}>
                        </Col>
                        <Row ><b><u>รายละเอียดที่ดิน {<b>{item?.numberLand}</b>}</u></b></Row>
                        <Row gutter={32} justify={'center'}>
                            <aside style={{ width: '90%' }}>
                                <div style={{ marginBottom: 0 }}>
                                    <span>กลุ่มสินค้า : </span>{<b>{item?.landLoanDetails.productType}</b>}
                                </div>
                                <div style={{ marginBottom: 0 }}>
                                    <span>ประเภทเอกสาร : </span>{<b>{item?.productTypeLand === 1 ? "นส.3" : "นส.4"}</b>}
                                </div>
                                <div style={{ marginBottom: 0 }}>
                                    <span>เลขโฉนดที่ดิน : </span>{<b>{item?.numberLand}</b>}
                                </div>
                                <div style={{ marginBottom: 0 }}>
                                    <span>เลขที่ดินหรือระวาง : </span>{<b>{item?.numberLandlawang}</b>}
                                </div>
                                <div style={{ marginBottom: 0 }}>
                                    <span>จังหวัด : </span>{<b>{item?.provinces}</b>}
                                </div>
                                <div style={{ marginBottom: 0 }}>
                                    <span>อำเภอ : </span>{<b>{item?.district}</b>}
                                </div>
                                <div style={{ marginBottom: 0 }}>
                                    <span>พื้นที่ไร่ : </span>{<b>{item?.rai}</b>}
                                    <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                        ไร่
                                    </span>
                                </div>
                                <div style={{ marginBottom: 0 }}>
                                    <span>พื้นที่งาน : </span>{<b>{item?.workArea}</b>}
                                    <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                        งาน
                                    </span>
                                </div>
                                <div style={{ marginBottom: 0 }}>
                                    <span>พื้นที่ตารางวา : </span>{<b>{currencyFormatOne(item?.squareWaArea)}</b>}
                                    <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                        บาท
                                    </span>
                                </div>
                                <div style={{ marginBottom: 0 }}>
                                    <span>ราคาประเมินที่ดิน : </span> {<b>{item?.landPrice}</b>}
                                    <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                        บาท / ตารางวา
                                    </span>
                                </div>
                                <div style={{ marginBottom: 0 }}>
                                    <span>ราคารวม : </span> {<b>{item?.resultLandPrice}</b>}
                                    <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                        บาท
                                    </span>
                                </div>
                            </aside>
                            <Space>
                                <Radio
                                    checked={selectedRadioIndex === index}
                                    onChange={() => handleSelect(item, index)}
                                >
                                    เลือก
                                </Radio>
                            </Space>
                        </Row>
                        <Divider />
                        {/* </Card> */}

                    </>
                </Col>
                {/* </Row> */}
            </>
        )

    }

    return (
        <>
            <Modal open={open} onCancel={handleCancel} width={'90%'}
                footer={[null]} >

                <Row justify={'center'}>
                    <Col className='gutter-row' span={24} style={{ fontSize: '25px', textAlign: 'center' }}>
                        <b>ผูกโฉนด</b>
                    </Col>
                </Row>
                <Row justify={'center'}>
                    <Divider style={{ margin: 5 }} />
                    <Form
                        labelCol={{
                            span: 12,
                        }}
                        wrapperCol={{
                            span: 24,
                        }}
                        style={{
                            width: '90%',
                        }}
                        form={form}
                        name='fucnAdd'
                        onFinish={handleSubmit}
                        autoComplete="off"
                        initialValues={{ remember: true }}
                    >
                        <div className='main'>
                            <Card style={{ width: '100%' }}>
                                <Divider />
                                <Row justify={'center'}>

                                    <Space>
                                        <Form.Item label='จังหวัด' name='province' >
                                            <Input disabled name='province'></Input>
                                        </Form.Item>
                                        <Form.Item label='อำเภอ' name='district'>
                                            <Input disabled name='district' ></Input>
                                        </Form.Item>
                                        <Form.Item label='เลขโฉนดที่ดิน' name='numberLand'>
                                            <Input name='numberLand' onChange={(e) => setDataForCheck({ ...dataForCheck, numberLand: e.target.value })}></Input>
                                        </Form.Item>
                                        <Form.Item label='' name=''>
                                            <Button onClick={loadDataDup}>ค้นหา</Button>
                                        </Form.Item>
                                    </Space>
                                    <Divider />
                                </Row>
                                {
                                    foundInfo === true ?
                                        <>
                                            <Row justify={'left'}><b><u> พบ {showOldData?.length} ที่ดิน</u></b></Row>
                                            <Divider />
                                            <Row gutter={32} justify={'center'}>

                                                {
                                                    showOldData?.map((item, index) => {
                                                        return renderLandData({ item, index })
                                                    })
                                                }
                                            </Row>
                                            {/* <Divider /> */}
                                        </>
                                        :
                                        null
                                }

                                {/* <Button onClick={test}>TEST</Button> */}
                            </Card>
                            <Divider />
                            <Row justify={'center'}>
                                <Space>
                                    <Button key="back" type='primary' danger onClick={handleCancel}>
                                        ปิด
                                    </Button>
                                    <Button key="back" type='primary' htmlType='submit' style={{ backgroundColor: "green" }} >
                                        ยืนยัน
                                    </Button>
                                </Space>
                            </Row>
                        </div>
                    </Form>
                </Row>
            </Modal >
            {contextHolder}
        </>
    )
}

export default ModalAddLandRe