import React, { useState, useEffect }  from 'react';
import myImage from "./picture/unnamed.png"; 
import { Row, Col} from "antd";
import './css/page1.css';
import DotaThaiMonths from "./dotaThaiMonths";
import THBText from 'thai-baht-text';

const Page1 = (props) => {
  //const { dataRecord } = props;
  const { dataRecord, marketingData } = props;
  // ใช้ dataRecord และ marketingData ในคอมโพเนนต์นี้ได้เลย
  //console.log("dataMK11",marketingData)
  //let inputDateTextYear = dataRecord.inputDateText.substring(0, 4);
  let inputDateTextYear = parseInt(dataRecord.inputDateText.substring(0, 4))+543;
  let inputDateTextMonth = dataRecord.inputDateText.substring(5, 7);
  let thaiMonth = DotaThaiMonths[parseInt(inputDateTextMonth) - 1]; // แปลงเลขเดือนเป็นชื่อเดือนภาษาไทย
  let inputDateTextDay = dataRecord.inputDateText.substring(8, 10);
  let moneyText = THBText(dataRecord.approvedLoanAmount);
  //เลขบัตร
  //let strNoIDMK = "1234567890123";
  let strNoIDMK = "";
  //console.log("strNoIDMK2",strNoIDMK2);
  if(marketingData.noIDMK === undefined){
    //console.log("undefined",typeof marketingData.noIDMK);
  }else{
    strNoIDMK = marketingData.noIDMK;
    //console.log("111",typeof marketingData.noIDMK);
  }
  // ฟังก์ชันเพื่อคำนวณอายุจากวันเกิด
const calculateAge = (birthDate) => {
  const today = new Date();
  const birthDateObj = new Date(birthDate);
  let age = today.getFullYear() - birthDateObj.getFullYear();
  const monthDifference = today.getMonth() - birthDateObj.getMonth();
  const dayDifference = today.getDate() - birthDateObj.getDate();
  // ตรวจสอบว่าผู้ใช้เกิดปีนี้หรือไม่
  if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
    age--;
  }
  return age;
};

let checkInputCase = "";
if(dataRecord.inputCase === "รับจำนองที่ดิน"){
  checkInputCase = "รับจำนองที่ดินพร้อมสิ่งปลูกสร้างที่มีอยู่ หรือที่จะเกิดขึ้นในอนาคต";
}else{
  checkInputCase = dataRecord.inputCase;
}

//ตรวจสอบ ตำบล
const [checkTumB, setcheckTumB] = useState(dataRecord.subdistrict);
//ระวาง
const [lawang, setLawang] = useState(dataRecord.numberLandLawang);
useEffect(() => {
  if (!dataRecord.subdistrict) {
    setcheckTumB(dataRecord.inputDateText6);
  }
  if (!dataRecord.numberLandLawang) {
    setLawang(dataRecord.inputDateText5);
  }
}, [dataRecord]);

const age = calculateAge(marketingData.bDayMK)

  //console.log(dataRecord)
  // ทำสิ่งที่ต้องการกับ dataRecord ได้ต่อไปจากนี้
  // 1 ย่อหน้า 0.6 น. = 58 px     1123/794
  return (
    // <div style={{ height: "1123PX",width: "678PX",textAlign: "center"}}>
    <div style={{ padding: '40px'}}>
      <p style={{ textAlign: "right" }}>(ท.ด.๒๑)</p>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: "100px",
        }}
      >
        <img
          src={myImage}
          alt=""
          style={{ width: "100px", height: "100px" }}
        />
      </div>
      <Row>
        <Col span={8} className="ColUnderline18">ที่ดิน</Col>
        <Col span={8} className="ColUnderline24">หนังสือมอบอำนาจ</Col>
        <Col span={8} className="ColUnderline24"></Col>

        <Col span={1} className="Col">ระวาง</Col>
        <Col span={11} className="ColUnderline">{lawang}</Col>             
        <Col span={1} className="Col">ตำบล</Col>
        <Col span={11} className="ColUnderline">{checkTumB}</Col>
        
        <Col span={2} className="Col">เลขที่ดิน</Col>
        <Col span={6} className="ColUnderline">{dataRecord.inputDateText3}</Col>
        <Col span={3} className="Col" style={{ textAlign: "center"}}>หน้าสำรวจ</Col>
        <Col span={5} className="ColUnderline">{dataRecord.inputDateText4}</Col>
        <Col span={1} className="Col">อำเภอ</Col>
        <Col span={7} className="ColUnderline">{dataRecord.district}</Col>
        
        <Col span={3} className="Col">โฉนดที่ดินเลขที่</Col>
        <Col span={9} className="ColUnderline">{dataRecord.numberLand}</Col>
        <Col span={1} className="Col">จังหวัด</Col>
        <Col span={11} className="ColUnderline">{dataRecord.province}</Col>
        
        <Col span={1} className="Colbold18">เรื่อง</Col>
        <Col span={23} className="ColUnderline">{dataRecord.inputCase}</Col>
        
        <Col span={15} className="ColRight">เขียนที่</Col>
        <Col span={9} className="ColUnderline">1/24 ถ.มิตรภาพ ต.ในเมือง อ.เมือง จ.ขอนแก่น</Col>

        <Col span={15} className="ColRight">วันที่</Col>
        <Col span={2} className="ColUnderline">{inputDateTextDay}</Col>
        <Col span={1} className="Col">เดือน</Col>
        <Col span={2} className="ColUnderline">{thaiMonth}</Col>
        <Col span={2} className="Col">พุทธศักราช</Col>
        <Col span={2} className="ColUnderline">{inputDateTextYear}</Col>

        <Col span={6} className="ColRight">โดยหนังสือฉบับนี้ข้าพเจ้า</Col>
        <Col span={18} className="ColUnderline">บริษัท วัน มันนี่ จำกัด (โดยนายทัชชล ลีศิริกุล กรรมการแทน)</Col>

        <Col span={4} className="Col">เลขประจำตัวประชาชน</Col>
        <Col span={12}>
        <span  className="span1"></span>
        <span  className="span1"></span>
        <span  className="span1"></span>
        <span  className="span1"></span>
        <span  className="span1"></span>
        <span  className="span1"></span>
        <span  className="span1"></span>
        <span  className="span1"></span>
        <span  className="span1"></span>
        <span  className="span1"></span>
        <span  className="span1"></span>
        <span  className="span1"></span>
        <span  className="span1"></span>
        </Col>
        <Col span={1} className="Col">อายุ</Col>
        <Col span={2} className="ColUnderline"></Col>
        <Col span={1} className="Col">ปี</Col>
        <Col span={2} className="Col">เชื้อชาติ</Col>
        <Col span={2} className="ColUnderline">ไทย</Col>

        <Col span={2} className="Col">สัญชาติ</Col>
        <Col span={2} className="ColUnderline">ไทย</Col>
        <Col span={1} className="Col">บิดา</Col>
        <Col span={9} className="ColUnderline"></Col>
        <Col span={1} className="Col">มารดา</Col>
        <Col span={9} className="ColUnderline"></Col>

        <Col span={2} className="Col">บ้านเลขที่</Col>
        <Col span={2} className="ColUnderline">1/24</Col>
        <Col span={1} className="Col">หมู่ที่</Col>
        <Col span={5} className="ColUnderline"></Col>
        <Col span={2} className="Col">ตรอก/ซอย</Col>
        <Col span={4} className="ColUnderline"></Col>
        <Col span={1} className="Col">ถนน</Col>
        <Col span={7} className="ColUnderline">มิตรภาพ</Col>

        <Col span={1} className="Col">ตำบล</Col>
        <Col span={3} className="ColUnderline">ในเมือง</Col>
        <Col span={1} className="Col">อำเภอ</Col>
        <Col span={5} className="ColUnderline">เมือง</Col>
        <Col span={1} className="Col">จังหวัด</Col>
        <Col span={5} className="ColUnderline">ขอนแก่น</Col>
        <Col span={2} className="ColCenter">โทรศัพท์</Col>
        <Col span={6} className="ColUnderline">043-239-074</Col>

        <Col span={2} className="Colbold18">ได้มอบให้</Col>
        <Col span={22} className="ColUnderline">{marketingData.nameMK}{marketingData.nameMK1} {marketingData.nameMK2}</Col>

        <Col span={4} className="Col">เลขประจำตัวประชาชน</Col>
        <Col span={12}>
        <span  className="span1">{strNoIDMK[0]}</span>
        <span  className="span1">{strNoIDMK[1]}</span>
        <span  className="span1">{strNoIDMK[2]}</span>
        <span  className="span1">{strNoIDMK[3]}</span>
        <span  className="span1">{strNoIDMK[4]}</span>
        <span  className="span1">{strNoIDMK[5]}</span>
        <span  className="span1">{strNoIDMK[6]}</span>
        <span  className="span1">{strNoIDMK[7]}</span>
        <span  className="span1">{strNoIDMK[8]}</span>
        <span  className="span1">{strNoIDMK[9]}</span>
        <span  className="span1">{strNoIDMK[10]}</span>
        <span  className="span1">{strNoIDMK[11]}</span>
        <span  className="span1">{strNoIDMK[12]}</span>
        </Col>
        <Col span={1} className="Col">อายุ</Col>
        <Col span={2} className="ColUnderline">{age}</Col>
        <Col span={1} className="Col">ปี</Col>
        <Col span={2} className="Col">เชื้อชาติ</Col>
        <Col span={2} className="ColUnderline">{marketingData.ethnicity}</Col>

        <Col span={2} className="Col">สัญชาติ</Col>
        <Col span={2} className="ColUnderline">{marketingData.nationality}</Col>
        <Col span={1} className="Col">บิดา</Col>
        <Col span={9} className="ColUnderline">{marketingData.nameMKF}{marketingData.nameMKF1} {marketingData.nameMKF2}</Col>
        <Col span={1} className="Col">มารดา</Col>
        <Col span={9} className="ColUnderline">{marketingData.nameMKM}{marketingData.nameMKM1} {marketingData.nameMKM2}</Col>

        <Col span={2} className="Col">บ้านเลขที่</Col>
        <Col span={2} className="ColUnderline">{marketingData.addr}</Col>
        <Col span={1} className="Col">หมู่ที่</Col>
        <Col span={5} className="ColUnderline">{marketingData.village}</Col>
        <Col span={2} className="Col">ตรอก/ซอย</Col>
        <Col span={4} className="ColUnderline">{marketingData.soi}</Col>
        <Col span={1} className="Col">ถนน</Col>
        <Col span={7} className="ColUnderline">{marketingData.road}</Col>

        <Col span={1} className="Col">ตำบล</Col>
        <Col span={3} className="ColUnderline">{marketingData.tumB}</Col>
        <Col span={1} className="Col">อำเภอ</Col>
        <Col span={5} className="ColUnderline">{marketingData.district}</Col>
        <Col span={1} className="Col">จังหวัด</Col>
        <Col span={5} className="ColUnderline">{marketingData.province}</Col>
        <Col span={2} className="ColCenter">โทรศัพท์</Col>
        <Col span={6} className="ColUnderline">043-239-074</Col>

        <Col span={4} className="Colbold18">เป็นผู้มีอำนาจจัดการ</Col>
        <Col span={20} className="ColUnderlineLeft">{checkInputCase} แปลงเครื่องหมายข้างบนนี้ในวงเงิน {parseFloat(dataRecord.approvedLoanAmount).toLocaleString()} บาท ({moneyText}) </Col>
        
        <Col span={24} className="ColUnderlineLeft"> อัตราดอกเบี้ยร้อยละ 15 บาทต่อปีนำส่งเดือนละครั้งเป็นการประกันหนี้เงินกู้ {dataRecord.snam}{dataRecord.firstname} {dataRecord.lastname} ตลอดจนให้คำต่างๆ ต่อพนักงานเจ้าหน้าที่ และยินยอมให้ผู้รับมอบอำนาจ</Col>
         
        <Col span={19} className="ColUnderlineLeft">เข้าทำการในนามตนเอง/หรือเป็นตัวแทนของอีกฝ่ายหนึ่งด้วยและให้ถือสัญญาจำนองเป็นหลักฐานการกู้ยืมเงิน</Col>
        <Col span={5} className="Col">แทนข้าพเจ้าจนเสร็จการ</Col>
        <Col span={24} className="ColCenter">เพื่อเป็นหลักฐานข้าพเจ้าได้ ลงลายมือ/พิมพ์ลายนิ้วมือ ไว้เป็นสำคัญต่อหน้าพยานแล้ว</Col>

        <Col span={14} className="Col"></Col>
        <Col span={6} className="ColUnderline"></Col>
        <Col span={4} className="Colbold18">ผู้มอบอำนาจ</Col>
        <Col span={14}></Col>
        <Col span={6} className="ColCenter">(   นายทัชชล ลีศิริกุล   )</Col>
        <Col span={4}></Col>

        <Col span={24} className="ColCenter">ข้าพเจ้าขอรับรองว่าเป็นลายมือชื่อ / พิมพ์ลายนิ้วมือ อันแท้จริงของผู้มอบอำนาจและผู้มอบอำนาจได้ลายมือชื่อ / พิมพ์ลายนิ้วมือ ต่อหน้าข้าพเจ้า</Col>

        <Col span={4} className="Col"></Col>
        <Col span={6} className="ColUnderline"></Col>
        <Col span={4} className="Colbold18">พยาน</Col>
        <Col span={6} className="ColUnderline"></Col>
        <Col span={4} className="Colbold18">พยาน</Col>

        <Col span={4} className="Col"></Col>
        <Col span={6} className="ColCenter">(   นางสาวสายใจ หินราชา  )</Col>
        <Col span={4}></Col>
        <Col span={6} className="ColCenter">(   นางสาวอารยา เส่งตี๋  )</Col>
        <Col span={4}></Col>

        <Col span={2} className="ColUnderline18">หมายเหตุ</Col>
        <Col span={0.5} className="Col"></Col>
        <Col span={21} className="ColUnderline18">กรณีที่ผู้มอบอำนาจเป็นคู่สัญญา หรือเป็นตัวแทนของคู่สัญญาให้ระบุข้อความดังต่อไปนี้ด้วย "และยินยอมให้ผู้มอบอำนาจเข้าทำการในนาม" ตนเองและ/หรือเป็นตัวแทนของอีกฝ่ายหนึ่งด้วย</Col>
        
        <Col span={2} className="ColUnderline18">หมายเหตุ</Col>
        <Col span={0.5} className="Col"></Col>
        <Col span={21} className="ColUnderline18"> การลงลายมือชื่อหรือพิมพ์ลายนิ้วมือ ถ้าใช้อย่างใด ให้ขีดฆ่าข้อความที่ไม่ใช้ออกในกรณีพิมพ์ลายนิ้วมือให้ใช้พิมพ์ลายนิ้วแม่มือซ้าย</Col>

        <Col span={19} className="Col"></Col>
        <Col span={5} className="ColUnderline18"> โปรดอ่านคำเตือนด้านหลัง</Col>
      </Row>
      {/* <br/><br/><br/><br/><br/> */}
    </div> 
  );
}
export default Page1;