import React, { useState, useEffect } from 'react';
import { Row, Col, Radio, Space, notification, Card, Spin } from 'antd'
// import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import dayjs from 'dayjs';
import Customer from "./model/Customer";
import Product from "./model/Product";
import PDF from "./model/PDF";
import CustomerNew from './model/CustomerNew';
import ProductNew from './model/ProductNew';
import PDFNew from './model/PDFNew';
import Product2 from './model/Product2';
import Product2New from './model/Product2New';
import History from './model/History';
import HistoryNew from './model/HistoryNew';
import Guarantor from './model/Guarantor';
import OtherNew from './model/OtherNew';
import Other from './model/Other';
import { checkgu, findlandpn, loadimage } from '../../file_mid/all_api';

export default function View_image() {
    const view = localStorage.getItem('viewAD')
    const getData = JSON.parse(view)
    const [count, setCount] = useState(1)
    const [api, contextHolder] = notification.useNotification();
    const [loading, setLoading] = useState(false)
    const [imageBlobzz, setImageBlobzz] = useState([]);
    const [guarantor1, setGuarantor1] = useState([])
    const [resetPage, setResetPage] = useState(dayjs())
    const [checkSendNEW, setCheckSendNEW] = useState(false)
    const [newDataLand, setNewDataLand] = useState([]);

    useEffect(() => {
        imageBlobzz?.map((e, index) => {
            var PreAaproveSplit = []
            PreAaproveSplit = e.pathImage.split("/")
            if (PreAaproveSplit[3] === "13") {
                setCheckSendNEW(true)
            }
        })
    }, [imageBlobzz])

    useEffect(() => {
        if (getData) {
            loadImage()
            loadGuarantors()
            loadDataNew()
        }
    }, [count, resetPage])

    const loadDataNew = async () => {
        await axios.post(findlandpn, { landLoanId: getData.loanId })
            .then(res => {
                if (res.status === 200) {
                    setNewDataLand(res.data)
                }
            }).catch((err) => {
                console.log("err", err)
            })
    }

    const loadGuarantors = async () => { //คนค้ำ
        setLoading(true)
        // console.log("{ typeLoanloanId: getData.loanId }", { typeLoan: "car", loanId: getData.loanId })
        if (getData?.car === "car") {
            await axios.post(checkgu, { typeLoan: "car", loanId: getData.loanId })
                .then(res => {
                    if (res.status === 200) {
                        // console.log("loadGuarantors car", res.data)
                        setGuarantor1(res.data)
                        setLoading(false)
                    } else if (res.status === 204) {
                        // console.log("ไม่พบข้อมูล")
                        setLoading(false)
                    }
                }).catch((err) => {
                    console.log("err", err)
                })
        } else {
            await axios.post(checkgu, { typeLoan: "land", loanId: getData.loanId })
                .then(res => {
                    if (res.status === 200) {
                        // console.log("loadGuarantors land", res.data)
                        setGuarantor1(res.data)
                        setLoading(false)
                    } else if (res.status === 204) {
                        // console.log("ไม่พบข้อมูล")
                        setLoading(false)
                    }
                }).catch((err) => {
                    console.log("err", err)
                })
        }
    }

    const loadImage = async () => {
        setLoading(true)
        if (getData.car === "car") {
            await axios.post(loadimage, { carId: getData.carId, typeLoan: getData.car })
                .then(res => {
                    if (res.status === 200) {
                        setImageBlobzz(res.data)
                        setLoading(false)
                        console.log("blob car =", res.data)
                    } else if (res.status === 204) {
                        setLoading(false)
                    }
                }).catch(err => {
                    console.log(err)
                })
        } else {
            await axios.post(loadimage, { landId: getData.carId, typeLoan: getData.car })
                .then(res => {
                    if (res.status === 200) {
                        setImageBlobzz(res.data)
                        console.log("blob land =", res.data)
                        setLoading(false)
                    } else if (res.status === 204) {
                        setLoading(false)
                    }
                }).catch(err => {
                    console.log(err)
                })
        }
        setLoading(false)
    }
    const Onclose = () => {
        localStorage.removeItem('viewAD')
        window.close();
    };

    const NotiDelete = (placement) => {
        api.success({
            message: <b>ลบข้อมูลสำเร็จ</b>,
            //   description:
            //     'สถานะถูกเปลี่ยนจาก รอธุการรับ เป็น รอพิจารณาแล้ว',
            placement,
        });
    }
    const DeleteError = (placement) => {
        api.error({
            message: <b>ลบข้อมูลไม่สำเร็จ</b>,
            placement,
        });
    }

    return (
        <>
            <Card
                bordered={false}
                style={{
                    width: 'auto',
                }}
            >
                <Row style={{ textAlign: 'center' }}>
                    <Col span={24}>
                        <Spin spinning={loading} size='large' tip=" Loading... ">
                            <Space
                                direction="vertical"
                                size="middle"
                                style={{
                                    display: 'flex',
                                }}>
                                {
                                    checkSendNEW ?
                                        <>
                                            <Row>
                                                <Col span={24} style={{ textAlign: 'center' }}>
                                                    <Radio.Group value={count} onChange={(e) => setCount(e.target.value)}>
                                                        <Radio.Button value={1}>รูปบัตรประชาชน</Radio.Button>
                                                        <Radio.Button value={11}>รูปบัตรประชาชน (ใหม่)</Radio.Button>
                                                        <Radio.Button value={2}>หน้าคู่มือ/โฉนด</Radio.Button>
                                                        <Radio.Button value={12}>หน้าคู่มือ/โฉนด (ใหม่)</Radio.Button>
                                                        <Radio.Button value={3}>ใบประเมิน / รูปรถ / สัญญาไฟแนนซ์เดิม / ใบเสร็จไฟแนนซ์เดิม</Radio.Button>
                                                        <Radio.Button value={13}>ใบประเมิน / รูปรถ / สัญญาไฟแนนซ์เดิม / ใบเสร็จไฟแนนซ์เดิม (ใหม่)</Radio.Button>
                                                        <Radio.Button value={4}>ประวัติผู้กู้ / ทะเบียนบ้าน / อาชีพ</Radio.Button>
                                                        <Radio.Button value={14}>ประวัติผู้กู้ / ทะเบียนบ้าน / อาชีพ (ใหม่)</Radio.Button>
                                                        <Radio.Button value={5}>รูปสเตทเมนต์ผู้กู้</Radio.Button>
                                                        <Radio.Button value={15}>รูปสเตทเมนต์ผู้กู้ (ใหม่)</Radio.Button>
                                                        <Radio.Button value={6}>ประวัติคนค้ำ / ทะเบียนบ้าน / อาชีพ</Radio.Button>
                                                        <Radio.Button value={7}>เอกสารเพิ่มเติม</Radio.Button>
                                                        <Radio.Button value={17}>เอกสารเพิ่มเติม (ใหม่)</Radio.Button>
                                                    </Radio.Group>
                                                </Col>
                                            </Row>
                                        </>
                                        :
                                        <>
                                            <Row>
                                                <Col span={24} style={{ textAlign: 'center' }}>
                                                    <Radio.Group value={count} onChange={(e) => setCount(e.target.value)}>
                                                        <Radio.Button value={1}>รูปบัตรประชาชน</Radio.Button>
                                                        <Radio.Button value={2}>หน้าคู่มือ/โฉนด</Radio.Button>
                                                        <Radio.Button value={3}>ใบประเมิน / รูปรถ / สัญญาไฟแนนซ์เดิม / ใบเสร็จไฟแนนซ์เดิม</Radio.Button>
                                                        <Radio.Button value={4}>ประวัติผู้กู้ / ทะเบียนบ้าน / อาชีพ</Radio.Button>
                                                        <Radio.Button value={5}>รูปสเตทเมนต์ผู้กู้</Radio.Button>
                                                        <Radio.Button value={6}>ประวัติคนค้ำ / ทะเบียนบ้าน / อาชีพ</Radio.Button>
                                                        <Radio.Button value={7}>เอกสารเพิ่มเติม</Radio.Button>
                                                    </Radio.Group>
                                                </Col>
                                            </Row>
                                        </>
                                }

                                {count === 1 && (<Customer getData={imageBlobzz} checkType={getData} close={Onclose} page={count} changepage={setCount} deleteData={NotiDelete} DeleteError={DeleteError} checkSendNEW={checkSendNEW} sendB={setResetPage} />)}
                                {count === 2 && (<Product getData={imageBlobzz} newDataLand={newDataLand} checkType={getData} close={Onclose} page={count} changepage={setCount} deleteData={NotiDelete} DeleteError={DeleteError} checkSendNEW={checkSendNEW} sendB={setResetPage} />)}
                                {count === 3 && (<Product2 getData={imageBlobzz} newDataLand={newDataLand} checkType={getData} close={Onclose} page={count} changepage={setCount} deleteData={NotiDelete} DeleteError={DeleteError} checkSendNEW={checkSendNEW} sendB={setResetPage} />)}
                                {count === 4 && (<History getData={imageBlobzz} checkType={getData} close={Onclose} page={count} changepage={setCount} deleteData={NotiDelete} DeleteError={DeleteError} checkSendNEW={checkSendNEW} sendB={setResetPage} />)}
                                {count === 5 && (<PDF getData={imageBlobzz} checkType={getData} close={Onclose} page={count} changepage={setCount} deleteData={NotiDelete} DeleteError={DeleteError} checkSendNEW={checkSendNEW} sendB={setResetPage} />)}
                                {count === 6 && (<Guarantor guarantor1={guarantor1} getData={imageBlobzz} checkType={getData} close={Onclose} page={count} changepage={setCount} deleteData={NotiDelete} DeleteError={DeleteError} checkSendNEW={checkSendNEW} sendB={setResetPage} />)}
                                {count === 7 && (<Other getData={imageBlobzz} checkType={getData} close={Onclose} page={count} changepage={setCount} deleteData={NotiDelete} DeleteError={DeleteError} checkSendNEW={checkSendNEW} sendB={setResetPage} />)}

                                {count === 11 && (<CustomerNew getData={imageBlobzz} checkType={getData} close={Onclose} page={count} changepage={setCount} deleteData={NotiDelete} DeleteError={DeleteError} checkSendNEW={checkSendNEW} sendB={setResetPage} />)}
                                {count === 12 && (<ProductNew getData={imageBlobzz} newDataLand={newDataLand} checkType={getData} close={Onclose} page={count} changepage={setCount} deleteData={NotiDelete} DeleteError={DeleteError} checkSendNEW={checkSendNEW} sendB={setResetPage} />)}
                                {count === 13 && (<Product2New getData={imageBlobzz} newDataLand={newDataLand} checkType={getData} close={Onclose} page={count} changepage={setCount} deleteData={NotiDelete} DeleteError={DeleteError} checkSendNEW={checkSendNEW} sendB={setResetPage} />)}
                                {count === 14 && (<HistoryNew getData={imageBlobzz} checkType={getData} close={Onclose} page={count} changepage={setCount} deleteData={NotiDelete} DeleteError={DeleteError} checkSendNEW={checkSendNEW} sendB={setResetPage} />)}
                                {count === 15 && (<PDFNew getData={imageBlobzz} checkType={getData} close={Onclose} page={count} changepage={setCount} deleteData={NotiDelete} DeleteError={DeleteError} checkSendNEW={checkSendNEW} sendB={setResetPage} />)}
                                {count === 17 && (<OtherNew getData={imageBlobzz} checkType={getData} close={Onclose} page={count} changepage={setCount} deleteData={NotiDelete} DeleteError={DeleteError} checkSendNEW={checkSendNEW} sendB={setResetPage} />)}
                            </Space>
                            {contextHolder}
                        </Spin>
                    </Col>
                </Row>
            </Card>
        </>
    )
}
