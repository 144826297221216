import React, { useState } from 'react'
import { Modal, Col, Row, Space, notification, Steps } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { addCustomer } from '../../../../../redux/Customer';
import { addCar } from '../../../../../redux/Car';
import { addAddress } from '../../../../../redux/Address';
import { addPhone } from '../../../../../redux/Phone';

import GAddress from './GAddress';
import GPhone from './GPhone';
import GCustomer from './GCustomer';
import GDetail from './GDetail';

export default function ModalAddGuarantor({ open, close, fucnAdd }) {
    const customers = useSelector(state => state.customers)
    const cars = useSelector(state => state.cars)


    const [saveCusIdMain] = useState(customers.data);
    const [saveCarId] = useState(cars.data);
    const [api, contextHolder] = notification.useNotification();
    const [count, setCount] = useState(1)
    const dispatch = useDispatch()
    const [current, setCurrent] = useState(0);
    const { confirm } = Modal

    const next = () => {
        setCurrent(current + 1);
    };
    const prev = () => {
        setCurrent(current - 1);
    };
    const steps = [
        {
            title: 'ข้อมูลส่วนตัว',
        },
        {
            title: 'รายละเอียดที่อยู่',
        },
        {
            title: 'ช่องทางการติดต่อ',
        },
        {
            title: 'สรุปรายละเอียด/บันทึกข้อมูล',
        },
    ];

    const items = steps.map((item) => ({
        key: item.title,
        title: item.title,
    }));

    const Onclose = () => {
        close(false);
        dispatch(addCustomer(saveCusIdMain))
        dispatch(addCar(saveCarId))
        dispatch(addAddress())
        dispatch(addPhone())
    };

    // const InsertSuccess = (placement) => {
    //     api.success({
    //         message: <b>เพิ่มข้อมูลสำเร็จ</b>,
    //         placement,
    //     });
    // }
    // const EditSuccess = (placement) => {
    //     api.success({
    //         message: <b>แก้ไขข้อมูลสำเร็จ</b>,
    //         placement,
    //     });
    // }
    // const DeleteSuccess = (placement) => {
    //     api.success({
    //         message: <b>ลบข้อมูลสำเร็จ</b>,
    //         placement,
    //     });
    // }
    // const InsertError = (placement) => {
    //     api.error({
    //         message: <b>เพิ่มข้อมูลไม่สำเร็จ</b>,
    //         placement,
    //     });
    // }
    // const EditError = (placement) => {
    //     api.error({
    //         message: <b>แก้ไขข้อมูลไม่สำเร็จ</b>,
    //         placement,
    //     });
    // }
    // const DeleteError = (placement) => {
    //     api.error({
    //         message: <b>ลบข้อมูลไม่สำเร็จ</b>,
    //         placement,
    //     });
    // }
    const CheckAddressER = (placement) => {
        api.error({
            message: <b>เพิ่มข้อมูลไม่สำเร็จ</b>,
            description: 'กรุณาเพิ่มที่อยู่ อย่างน้อย 1 ที่',
            placement,
        });
    }
    const CheckPhoneER = (placement) => {
        api.error({
            message: <b>เพิ่มข้อมูลไม่สำเร็จ</b>,
            description: 'กรุณาเพิ่มเบอร์โทรศัพท์ อย่างน้อย 1 เบอร์',
            placement,
        });
    }
    const CheckGuER = (placement) => {
        api.error({
            message: <b>เพิ่มข้อมูลไม่สำเร็จ</b>,
            description: 'พบข้อมูลซ้ำกันระหว่างผู้กู้และผู้ค้ำ กรุณาเปลี่ยนผู้ค้ำ',
            placement,
        });
    }

    const showConfirm = () => {
        confirm({
            title: 'คุณต้องการที่ปิดหน้าต่างข้อมูลผู้กู้หรือไม่...?',
            content: 'หากกดปิดหน้าต่างผู้กู้ข้อมูลจะไม่ถูกบันทึก กด OK เพื่อยืนยัน',
            onOk() {
                Onclose();
            },
            onCancel() {
            },
        });
    };

    return (
        <>
            <Modal title="เพิ่มข้อมูลผู้ค้ำ" open={open} onCancel={showConfirm} width={1200} footer={null} >
                <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                    <Row>
                        <Col span={24} style={{ textAlign: 'center' }}>
                            <Steps current={current} items={items} />
                        </Col>
                    </Row>
                    {count === 1 && (<GCustomer close={showConfirm} page={count} setPage={setCount} next={next} prev={prev} chg={CheckGuER} cusId={saveCusIdMain} />)}
                    {count === 2 && (<GAddress close={showConfirm} page={count} setPage={setCount} cha={CheckAddressER} next={next} prev={prev} cusId={saveCusIdMain} />)}
                    {count === 3 && (<GPhone close={showConfirm} page={count} setPage={setCount} chp={CheckPhoneER} next={next} prev={prev} cusId={saveCusIdMain} />)}
                    {count === 4 && (<GDetail close={showConfirm} page={count} setPage={setCount} saveclose={Onclose} next={next} prev={prev} fucnAdd={fucnAdd} cusId={saveCusIdMain} />)}
                </Space>
                {contextHolder}
            </Modal>
        </>
    )
};
