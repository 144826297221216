import { Button, Card, Col, Divider, Form, Modal, notification, Select, Space, Spin } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import axios from 'axios';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react'
import { changeproductLoanType, changestatus } from '../all_api';
import { optionsCarPLT, optionsChange, optionsLandPLT, optionsPLTOld } from '../all_options';

function NoteChangeStatus({ open, close, closeAll, dataFromTable, typeOpenNote, typeLoans, resetpage }) {

    const user = localStorage.getItem('username');
    const { confirm } = Modal
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [api, contextHolder] = notification.useNotification();
    const [dataChangeStatus, setDataChangeStatus] = useState({
        typeLoan: typeLoans,
        loanId: dataFromTable?.loanId,
        notesCarLand: {
            loanId: dataFromTable?.loanId,
            // cause: "เปลี่ยนสถานะ",
            // note: "123456",
            noteBy: user,
            status: dataFromTable?.approvalStatusName,
            rejectStatus: false,
            // noteDate: "2024-07-09T13:26:54+07:00"
        }
    })

    useEffect(() => {
        if (typeOpenNote === "STA") {
            setDataChangeStatus({ ...dataChangeStatus, notesCarLand: { ...dataChangeStatus.notesCarLand, cause: "เปลี่ยนสถานะ" } })
            form.setFieldsValue({
                cause: "เปลี่ยนสถานะ"
            })
        } else if (typeOpenNote === "PLT") {
            setDataChangeStatus({ ...dataChangeStatus, productLoanTypeOld: dataFromTable?.productLoanType, notesCarLand: { ...dataChangeStatus.notesCarLand, cause: "เปลี่ยนประเภทขอกู้" } })
            form.setFieldsValue({
                cause: "เปลี่ยนประเภทขอกู้",
                productLoanTypeOld: dataFromTable?.productLoanType
            })
        }
    }, [])

    const handleClose = () => {
        close(false)
    }
    const handleSubmit = async () => {
        setLoading(true)
        let sendData = {
            ...dataChangeStatus,
            notesCarLand: {
                ...dataChangeStatus.notesCarLand,
                noteDate: dayjs()
            }
        }
        if (typeOpenNote === "STA") {
            // console.log("STA", sendData)
            await axios.post(changestatus, sendData)
                .then((res) => {
                    if (res.status === 200) {
                        EditSuccess('top')
                        // console.log("OK")
                        // resetpage(dayjs())
                        setTimeout(() => {
                            closeAll(false)
                            setLoading(false)
                        }, 200)

                    } else if (res.status === 201) {
                        EditError('top')
                    }
                }).catch((err) => {
                    console.log("err", err)
                })
        } else if (typeOpenNote === "PLT") {
            // console.log("PLT", sendData)
            await axios.post(changeproductLoanType, sendData)
                .then((res) => {
                    if (res.status === 200) {
                        EditSuccess('top')
                        // console.log("OK")
                        // resetpage(dayjs())
                        setTimeout(() => {
                            closeAll(false)
                            setLoading(false)
                        }, 200)
                    } else if (res.status === 201) {
                        EditError('top')
                    }
                }).catch((err) => {
                    console.log("err", err)
                })
        }
    }
    const showConfirmSubmit = () => {
        confirm({
            title: 'คุณต้องการที่จะแก้ไขข้อมูลหรือไม่...?',
            content: 'กด ยืนยัน เพื่อแก้ไขข้อมูล',
            okText: 'ยืนยัน',
            cancelText: 'ยกเลิก',
            onOk() {
                handleSubmit()
            },
            onCancel() {

            },
        });
    };
    const handleChangeapprovalStatus = (value) => {
        setDataChangeStatus({ ...dataChangeStatus, approvalStatus: value })
    }
    const handleChangeProductLoanType = (value) => {
        setDataChangeStatus({ ...dataChangeStatus, productLoanType: value })
    }
    const EditSuccess = (placement) => {
        api.success({
            message: <b>แก้ไขข้อมูลสำเร็จ</b>,
            placement,
        });
    }
    const EditError = (placement) => {
        api.error({
            message: <b>แก้ไขข้อมูลไม่สำเร็จ</b>,
            placement,
        });
    }

    return (
        <Modal title="แก้ไขสถานะ" open={open} onCancel={handleClose} footer={[null]}>
            <Spin spinning={loading} size='large' tip=" Loading... ">
                <Form
                    labelCol={{
                        span: 7,
                    }}
                    wrapperCol={{
                        span: 15,
                    }}
                    form={form}
                    onFinish={showConfirmSubmit}
                >
                    <Card>
                        {
                            typeOpenNote === "STA" ?
                                <>
                                    <Form.Item label="เปลี่ยนสถานะ" name="approvalStatus"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'กรุณากรอกสาเหตุ'
                                            },]}>
                                        <Select
                                            // mode="multiple"
                                            style={{
                                                width: '100%',
                                            }}
                                            size="large"
                                            placeholder="สถานะ"
                                            onChange={handleChangeapprovalStatus}
                                            options={optionsChange}
                                        />
                                    </Form.Item>
                                    <Form.Item label="สาเหตุ" name="cause"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'กรุณากรอกสาเหตุ'
                                            },]}>
                                        <Select
                                            disabled
                                            mode="multiple"
                                            style={{
                                                width: '100%',
                                            }}
                                            size="large"
                                            placeholder="สาเหตุ"
                                        // onChange={handleChangeCause}
                                        // options={options}
                                        />
                                    </Form.Item>
                                </>
                                :
                                <>
                                    <Form.Item label="ประเภทขอกู้ปัจจุบัน" name="productLoanTypeOld"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'กรุณากรอกสาเหตุ'
                                            },]}>
                                        <Select
                                            disabled
                                            // mode="multiple"
                                            style={{
                                                width: '100%',
                                            }}
                                            size="large"
                                            placeholder="สถานะ"
                                        // onChange={handleChangeProductLoanType}
                                        // options={optionsLandPLT}
                                        />
                                    </Form.Item>
                                    <Form.Item label="ประเภทขอกู้" name="productLoanType"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'กรุณากรอกสาเหตุ'
                                            },]}>
                                        {
                                            dataFromTable?.productLoanType === "ย้ายไฟแนนซ์" ||
                                                dataFromTable?.productLoanType === "Pre-Aaprove-ย้ายไฟแนนซ์" ?
                                                <>
                                                    <Select
                                                        // mode="multiple"
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        size="large"
                                                        placeholder="สถานะ"
                                                        onChange={handleChangeProductLoanType}
                                                        options={optionsPLTOld}
                                                    />
                                                </>
                                                : typeLoans === "car" ?
                                                    <>
                                                        <Select
                                                            // mode="multiple"
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                            size="large"
                                                            placeholder="สถานะ"
                                                            onChange={handleChangeProductLoanType}
                                                            options={optionsCarPLT}
                                                        />
                                                    </>
                                                    :  // typeLoans === "land" ?
                                                    <>
                                                        <Select
                                                            // mode="multiple"
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                            size="large"
                                                            placeholder="สถานะ"
                                                            onChange={handleChangeProductLoanType}
                                                            options={optionsLandPLT}
                                                        />
                                                    </>
                                        }
                                    </Form.Item>
                                    <Form.Item label="สาเหตุ" name="cause"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'กรุณากรอกสาเหตุ'
                                            },]}>
                                        <Select
                                            disabled
                                            mode="multiple"
                                            style={{
                                                width: '100%',
                                            }}
                                            size="large"
                                            placeholder="สาเหตุ"
                                        // onChange={handleChangeCause}
                                        // options={options}
                                        />
                                    </Form.Item>
                                </>
                        }
                        <Form.Item label="รายละเอียด" name="note">
                            <TextArea rows={5} onChange={(e) => setDataChangeStatus({ ...dataChangeStatus, notesCarLand: { ...dataChangeStatus.notesCarLand, note: e.target.value } })} />
                        </Form.Item>
                    </Card>
                    <Divider />
                    <Col span={24} style={{ textAlign: 'center' }}>
                        <Space>
                            <Button type='primary' danger onClick={handleClose}>ปิด</Button>
                            <Button type='primary' htmlType='submit' style={{ backgroundColor: "green" }}>บันทึก</Button>
                        </Space>
                    </Col>
                </Form>
            </Spin>
            {contextHolder}
        </Modal>
    )
}

export default NoteChangeStatus