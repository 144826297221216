import { Button, Card, Col, Divider, Form, Input, Modal, Row, Select, Space, notification, Spin, Checkbox, DatePicker } from 'antd'
import React, { useEffect, useState } from 'react'
import axios from 'axios';
import {
    addcausename, showcausename, acceptpgre, approveRE, approveREcon,
    docnotpasspgre, approveRELand, approveREconLand, docnotpasspgreland,
    showcausenamere
} from '../../file_mid/all_api';
import TextArea from 'antd/es/input/TextArea';
import dayjs from 'dayjs';
import { loanPLorCH, loanPLorCHBig, loanTermMid } from '../../file_mid/all_options';
import { irr } from 'node-irr';
import Currency from 'currency.js';
import { FIFTEENPERCENT, TWENTYFOURPERCENT } from '../../offer_case/ploan/InstallmentTable';
import { Installment } from '../../offer_case/ploan/Installment';
import { HirePurchase } from '../../offer_case/hire_purchase/HirePurchase';


function NoteRe({ open, close, loanId, CloseAll, typeNote, loanRe, typeData, checkGuas, dataCarLand, contno, dataField, guarantorData, dataFromTable, allData }) {

    const [money] = useState({}) //loan
    const [monthlyPayment, setMonthlyPayment] = useState()
    const [interest, setInterest] = useState(0.0)
    const [installmentWithInterest, setInstallmentWithInterest] = useState()
    const user = localStorage.getItem('username');
    const [api, contextHolder] = notification.useNotification();
    const [options, setOptions] = useState([]);
    const [checkAddCause, setCheckAddCause] = useState(false);
    const [isModalTableP, setIsModalTableP] = useState(false);
    const [isModalTable, setIsModalTable] = useState(false);
    const [loading, setLoading] = useState(false);
    const [resetPage, setResetPage] = useState();
    const [form] = Form.useForm()
    const [formAddCauseName] = Form.useForm()
    const [interestRate, setInterestRate] = useState();
    const [dataPost, setDataPost] = useState(loanRe);
    const [checkDis, setCheckDis] = useState(true)
    const [valueApCon, setValueApCon] = useState(guarantorData);
    const [addCauseName, setAddCauseName] = useState({ name: '' });

    // console.log("loanRe", loanRe)
    // console.log("typeData", typeData)
    // console.log("typeNote", typeNote)
    // console.log("dataFromTable", dataFromTable)
    // console.log("dataField", dataField)


    useEffect(() => {
        // console.log("loanRe", loanRe)
        // console.log("typeData", typeData)
        // console.log("allData", allData)
        // console.log("1", dataCarLand)
        if (typeNote === "รับคำร้อง") {
            loadCauseNameRe()
        } else {
            loadCauseName()
        }
        if (loanRe) {
            form.setFieldsValue({
                ...loanRe,
                interestRate: dataField?.interestRate,
                monthlyPayment: dataField?.monthlyPayment1,
                interest: dataField?.interest1,
                installmentWithInterest: dataField?.installmentWithInterestt,
                newOfferDate: dayjs(loanRe?.newOfferDate)
            })
        } else {
            form.setFieldsValue({
                newOfferDate: dayjs(allData?.cars?.carLoanDetailsRe?.newOfferDate)
            })
        }
    }, [resetPage])

    useEffect(() => {
        handleCheckForCal(dataPost)
    }, [interestRate, dataPost?.approvedLoanTerm, dataPost?.approvedLoanAmount])

    const loadCauseName = async () => {
        setLoading(true)
        await axios.get(showcausename)
            .then(res => {
                if (res.status === 200) {
                    setOptions(res.data)
                    setLoading(false)
                }
            }).catch((err) => {
                console.log(err)
                setLoading(false)
            })
    };

    const loadCauseNameRe = async () => {
        setLoading(true)
        await axios.get(showcausenamere)
            .then(res => {
                if (res.status === 200) {
                    setOptions(res.data)
                    setLoading(false)
                }
            }).catch((err) => {
                console.log(err)
                setLoading(false)
            })
    };

    // console.log("dataCarLand", dataCarLand)

    const handleCheckForCal = async (value) => {

        // console.log("handleCheckForCal", value)

        if (dataFromTable.typeLoan === "car") {
            if (value?.productType === "p-loan") {
                console.log("1 = p-loan")
                setInterestRate("1.78")
                await handleCalculate(value)
            } else if (value?.productType === "เช่าซื้อ") {
                if (dataCarLand?.productTypeCar === 1) {
                    console.log("2 = รถเล็ก เช่าซื้อ")
                    await handleCalculateHIRE(value)
                    setInterestRate("1.25")
                } else {
                    console.log("3 = รถใหญ่ เช่าซื้อ")
                    setCheckDis(false)
                    await car_Calculate(value)
                }
            } else if (value?.productType === "เช่าซื้อ1") {
                console.log("4 = รถเล็ก เช่าซื้อ1")
                await car_Calculate(value)
            }
        } else {
            setInterestRate("1.1")
            land_Calculate(value)
        }
        // console.log("value", value)
    }

    // คำนวน
    const car_Calculate = async (value) => {

        var LA = value?.approvedLoanAmount
        var LT = value?.approvedLoanTerm
        var IR = interestRate

        var interestRateCar = parseFloat(IR) / 100 // อัตราดอก / 100
        var rateCar = ((parseFloat(LA) * Currency(interestRateCar, { precision: 5 }).value) * LT) // (ยอดจัด * อัตราดอกที่หาร100แล้ว ,precision: 3 คือทศนิยม 3 )*งวด
        var loanTermCar = (rateCar + parseFloat(LA)) / parseInt(LT) // (ดอกเบี้ยทั้งหมด + ยอดจัด)/ งวด
        var newLoanTermCar = Math.ceil(Currency((loanTermCar * 0.07) + loanTermCar), { precision: 5 })// งวดละใหม่ = ( งวดละ * 0.07 ) + งวดละ
        var resultRateCar = Math.ceil(Currency(newLoanTermCar, { precision: 5 }) * parseInt(LT)) - parseFloat(LA) // งวดละใหม่ * งวด - ยอดจัด
        var newResultPriceCar = Math.ceil(Currency(newLoanTermCar) * parseInt(LT), { precision: 5 }) // งวดละใหม่ * งวด

        setMonthlyPayment(newLoanTermCar)
        setInterest(resultRateCar)
        setInstallmentWithInterest(newResultPriceCar)

        // console.log("newLoanTermCar", newLoanTermCar) // งวดละ
        // console.log("resultRateCar", resultRateCar) // ดอกเบี้ย
        // console.log("newResultPriceCar", newResultPriceCar) // ราคารวม
        form.setFieldsValue({
            interestRate: interestRate ? interestRate : "",
            monthlyPayment: newLoanTermCar ? newLoanTermCar : "",
            interest: resultRateCar ? resultRateCar : "",
            installmentWithInterest: newResultPriceCar ? newResultPriceCar : ""
        })
    }
    const handleCalculate = async (value) => {
        var LA = parseInt(value?.approvedLoanAmount)
        var LT = parseInt(value?.approvedLoanTerm)

        if (LA && LT) {

            let loanAmount = 0.0
            let interest = 0.0

            TWENTYFOURPERCENT.map((value) => {
                if (value.installment === ~~LT) {
                    loanAmount = value.interest * 0.7
                }
            })

            FIFTEENPERCENT.map((value) => {
                if (value.installment === ~~LT) {
                    interest = value.interest * 0.3
                }
            })

            let installmentAmount = Math.round((interest + loanAmount) * LA)

            // IRR Calculate
            var array = []
            array.push(-LA)
            for (var i = 0; i < LT; i++) {
                array.push(installmentAmount)
            }

            let IRR = irr(array) * 100

            if (IRR) {
                var result = LA

                result = installmentAmount * LT
                var interestNEW = result - LA
                // setResultIrr(Currency(IRR, { precision: 5 }).value)

                setMonthlyPayment(installmentAmount)
                setInterest(interestNEW)
                setInstallmentWithInterest(result)

                // console.log("installmentAmount", installmentAmount) // งวดละ
                // console.log("interestNEW", interestNEW) // ดอกเบี้ย
                // console.log("result", result) // ราคารวม
                form.setFieldsValue({
                    interestRate: interestRate,
                    monthlyPayment: installmentAmount,
                    interest: interestNEW,
                    installmentWithInterest: result
                })
            }
        }
    }
    const handleCalculateHIRE = async (value) => {
        var LA = value?.approvedLoanAmount
        var LT = value?.approvedLoanTerm
        if (LA && LT) {
            const amountWithVat = LA * 1.07
            let installment = 0.0

            FIFTEENPERCENT.map((value) => {
                if (value.installment === ~~LT) {
                    installment = value.interest * amountWithVat
                }
            })

            let installmentAmount = Math.round(installment)
            // IRR Calculate
            var array = []
            array.push(-amountWithVat)
            for (var i = 0; i < LT; i++) {
                array.push(installmentAmount)
            }

            let IRR = irr(array) * 100

            if (IRR) {
                var result = amountWithVat

                //result += totalInterest
                result = installmentAmount * LT
                var interestNEW = result - LA
                // setResultIrr(Currency(IRR, { precision: 5 }).value)

                setMonthlyPayment(installmentAmount)
                setInterest(interestNEW)
                setInstallmentWithInterest(result)

                // console.log("installmentAmount", installmentAmount) // งวดละ
                // console.log("interestNEW", interestNEW) // ดอกเบี้ย
                // console.log("result", result) // ราคารวม
                form.setFieldsValue({
                    interestRate: interestRate,
                    monthlyPayment: installmentAmount,
                    interest: interestNEW,
                    installmentWithInterest: result
                })
            }
        }
    }
    const land_Calculate = (value) => {

        var LA = value?.approvedLoanAmount
        var LT = value?.approvedLoanTerm
        var IR = interestRate

        console.log("LA", LA)
        console.log("LT", LT)
        console.log("IR", IR)

        var interestRateLand = parseFloat(IR) / 100 // อัตราดอก / 100
        var rate = (parseFloat(LA) * Currency(interestRateLand, { precision: 3 }).value) * parseInt(LT) // (ยอดจัด * อัตราดอกที่หาร100แล้ว ,precision: 3 คือทศนิยม 3 )*งวด
        var loanTerm = Math.ceil(Currency((rate + parseFloat(LA)) / parseInt(LT), { precision: 2 }).value) // (ดอกเบี้ยทั้งหมด + ยอดจัด)/ งวด
        var resultRate = (loanTerm * parseInt(LT)) - parseFloat(LA)
        var resultPrice = loanTerm * parseInt(LT)

        form.setFieldsValue(
            {
                monthlyPayment: loanTerm,
                interest: resultRate,
                installmentWithInterest: resultPrice
            }
        )

        setMonthlyPayment(loanTerm)
        setInterest(resultRate)
        setInstallmentWithInterest(resultPrice)
    }
    // คำนวน

    // console.log("dataPost", dataPost)

    const errorSendCause = (placement) => {
        api.error({
            message: `กรุณากรอกสาเหตุที่ต้องการเพิ่ม`,
            // description:
            //     'เนื่องจากเคสนี้ถูกรับงานไปแล้ว',
            placement,
        });
    };

    const successSendCause = (placement) => {
        api.success({
            message: `เพิ่มสาเหตุแล้ว`,
            description:
                'เลือกสาเหตุที่เพิ่มได้ในช่องเลือกสาเหตุ',
            placement,
        });
    };

    const successSendNote = (placement) => {
        api.success({
            message: `บันทึกสำเร็จ`,
            description:
                'ตีกลับเคสแล้ว',
            placement,
        });
    };

    const successSendApp = (placement) => {
        api.success({
            message: `บันทึกสำเร็จ`,
            // description:
            //     'ตีกลับเคสแล้ว',
            placement,
        });
    };

    const errorSendNote = (placement) => {
        api.error({
            message: `บันทึกไม่สำเร็จ`,
            // description:
            //     'ตีกลับเคสแล้ว สถานะถูกเปลี่ยนเป็น `คำร้องไม่ถูกต้อง`',
            placement,
        });
    };

    const handleClose = () => {
        close(false)
    }
    const handleOpenTableP = () => {
        setIsModalTableP(true)
    }
    const handleOpenTable = () => {
        setIsModalTable(true)
    }

    const handleChangeProductType = (value) => {
        if (typeData === 1) {
            // console.log("รถเล็ก")
            if (value === "p-loan") {
                // console.log("p-loan")
                // setCheckType(value)
                setCheckDis(true)
                setDataPost({
                    ...dataPost,
                    productType: value,
                })
            } else if (value === "เช่าซื้อ") {
                // console.log("เช่าซื้อ")
                setCheckDis(true)
                setDataPost({
                    ...dataPost,
                    productType: value,
                })
                // setCheckType(value)
            } else if (value === "เช่าซื้อ1") {
                // console.log("เช่าซื้อ1")
                setCheckDis(false)
                setDataPost({
                    ...dataPost,
                    productType: value,
                })
                // setCheckType(value)
            }
            form.setFieldsValue(
                {
                    // approvedLoanAmount: "",
                    // approvedLoanTerm: "",
                    interestRate: "",
                    interest: "",
                    monthlyPayment: "",
                    installmentWithInterest: "",
                }
            )
            // setCheckCar23(false)
        } else {
            // console.log("รถใหญ่")
            if (value === "p-loan") {
                // setCheckType(value)
                setCheckDis(true)
                setDataPost({
                    ...dataPost,
                    productType: value,
                })
                // setCheckCar23(false)
                form.setFieldsValue(
                    {
                        interestRate: interestRate
                    }
                )
            } else if (value === "เช่าซื้อ") {
                setCheckDis(false)
                setDataPost({
                    ...dataPost,
                    productType: value,
                })
                // setCheckType(value)
                // setCheckCar23(true)
                form.setFieldsValue(
                    {
                        interestRate: interestRate
                    }
                )
            }
            form.setFieldsValue(
                {
                    approvedLoanAmount: "",
                    approvedLoanTerm: "",
                    interest: "",
                    monthlyPayment: "",
                    installmentWithInterest: "",
                }
            )
        }
    }

    const successAccept = (placement) => {
        api.success({
            message: `บันทึกสำเร็จ`,
            // description:
            //     'สถานะเปลี่ยนเป็น ปฏิเสธคำร้อง',
            placement,
        });
    };
    const errorAccept = (placement) => {
        api.error({
            message: `บันทึกไม่สำเร็จ`,
            // description:
            //     'เนื่องจากเคสนี้ถูกรับงานไปแล้ว',
            placement,
        });
    };

    // console.log("dataFromTable", dataFromTable)
    // console.log("dataPost", dataPost)

    const handleSubmit = async () => {
        let sendData
        setLoading(true);
        if (typeNote === "ปฏิเสธคำร้อง") {
            let newProductLoanType
            if (dataFromTable.typeLoan === "car") {
                newProductLoanType = allData?.cars?.carLoanDetailsRe?.productLoanType
            } else {
                newProductLoanType = allData?.land?.landLoanDetailsRe?.productLoanLandType
            }
            let sendData = {
                reLoanId: dataFromTable.reLoanId,
                acceptByPg: user,
                acceptDatePg: dayjs(),
                approvalStatus: 8,
                cause: dataPost.cause,
                note: dataPost.note,
                noteDate: dayjs(),
                typeLoan: dataFromTable.typeLoan,
                newOfferDate: dataPost.newOfferDate,
                ISSUNO: dataFromTable.ISSUNO,
                username: dataFromTable.proposalBy,
                productLoanType: newProductLoanType
            }

            // console.log("sendData", sendData)
            await axios.put(acceptpgre, { ...dataFromTable, ...sendData })
                .then(res => {
                    // console.log(res.status)
                    if (res.status === 200) {
                        successAccept("top")
                        setTimeout(() => {
                            CloseAll();
                        }, 2000)
                    } else {
                        errorAccept("top")
                    }
                }).catch((err) => {
                    errorAccept("top")
                    console.log(err)
                })
        } else {
            if (dataFromTable.typeLoan === "car") {
                if (typeNote === "อนุมัติ") {
                    sendData = {
                        cars: {
                            ISSUNO: dataFromTable.ISSUNO,
                            carId: dataCarLand.carId,
                            customerId: dataCarLand.customerId,
                            // LoanId
                            carInput: dataCarLand.carInput,
                            carProvince: dataCarLand.carProvince,
                            productTypeCar: dataCarLand.productTypeCar,
                            // Gcode
                            ISSUNO: contno,
                            carBrand: dataCarLand.carBrand,
                            carModel: dataCarLand.carModel,
                            carBaab: dataCarLand.carBaab,
                            carYear: dataCarLand.carYear,
                            carColor: dataCarLand.carColor,
                            carPlateNumber: dataCarLand.carPlateNumber,
                            carChassisNumber: dataCarLand.carChassisNumber,
                            carEngineNumber: dataCarLand.carEngineNumber,
                            carPrice: dataCarLand.carPrice,
                            // carInputContno: dataCarLand.carInputContno,
                            carDateStatus: dataCarLand.carDateStatus,
                            // carContno: dataCarLand.carContno,
                            carStatus: dataCarLand.carStatus,
                            carPriceStatus: dataCarLand.carPriceStatus
                            // VehicleModel
                        },
                        carLoanDetailsRe: {
                            reLoanId: dataPost.reLoanId,
                            carId: dataPost.carId,
                            customerId: dataPost.customerId,
                            productType: dataPost.productType,
                            productLoanType: dataPost.productLoanType,
                            ftime: dataPost.ftime,
                            otime: dataPost.otime,
                            DAMT: dataPost.DAMT,
                            tcshprc: dataPost.tcshprc,
                            balanc: dataPost.balanc,
                            totalpayment: dataPost.totalpayment,
                            neekong: dataPost.neekong,
                            beeypup: dataPost.beeypup,
                            OTHR: dataPost.OTHR,
                            NOPAY: dataPost.NOPAY,
                            nopays: dataPost.nopays,
                            reqNo: dataPost.reqNo,
                            re1: dataPost.re1,
                            GRDCOD: dataPost.GRDCOD,
                            proposalBy: dataPost.proposalBy,
                            reviewedBy: dataPost.reviewedBy,
                            approvalStatus: 3,
                            approvedLoanAmount: dataPost.approvedLoanAmount,
                            approvedLoanTerm: dataPost.approvedLoanTerm,
                            approvedBy: dataPost.approvedBy,
                            approvalDate: dayjs(),
                            acceptDatePg: dataPost.acceptDatePg,
                            acceptByPg: dataPost.acceptByPg,
                            // IsAccepted: dataPost,
                            // CustomerApprovedLoan: dataPost,
                            Branch: dataPost.branch,
                            // CusIsAccepted: dataPost,
                            // CusIsAcceptedDate: dataPost,
                            checkGua: checkGuas,
                            approvalConditions: false
                        },
                        carLoanDetails: {
                            monthlyPayment: monthlyPayment,
                            interest: interest,
                            installmentWithInterest: installmentWithInterest,
                            interestRate
                        },
                        noteCarRe: {
                            reLoanId: loanId,
                            cause: dataPost.cause,
                            note: dataPost.note,
                            noteBy: user,
                            status: typeNote,
                            rejectStatus: false,
                            noteDate: dayjs(),
                        }
                    }
                    await axios.put(approveRE, sendData)
                        .then(res => {
                            if (res.status === 200) {
                                console.log("OK")
                                successSendApp("top")
                                setTimeout(() => {
                                    CloseAll()
                                }, 2000)
                            }
                        }).catch(error => {
                            console.error(error);
                            errorSendNote("top")
                        });
                } else if (typeNote === "อนุมัติแบบมีเงื่อนไข") {
                    sendData = {
                        cars: {
                            ISSUNO: dataFromTable.ISSUNO,
                            carId: dataCarLand.carId,
                            customerId: dataCarLand.customerId,
                            // LoanId
                            carInput: dataCarLand.carInput,
                            carProvince: dataCarLand.carProvince,
                            productTypeCar: dataCarLand.productTypeCar,
                            // Gcode
                            // ISSUNO: dataCarLand.ISSUNO,
                            carBrand: dataCarLand.carBrand,
                            carModel: dataCarLand.carModel,
                            carBaab: dataCarLand.carBaab,
                            carYear: dataCarLand.carYear,
                            carColor: dataCarLand.carColor,
                            carPlateNumber: dataCarLand.carPlateNumber,
                            carChassisNumber: dataCarLand.carChassisNumber,
                            carEngineNumber: dataCarLand.carEngineNumber,
                            carPrice: dataCarLand.carPrice,
                            carInputContno: dataCarLand.carInputContno,
                            carDateStatus: dataCarLand.carDateStatus,
                            carContno: dataCarLand.carContno,
                            carStatus: dataCarLand.carStatus,
                            carPriceStatus: dataCarLand.carPriceStatus
                            // VehicleModel
                        },
                        carLoanDetailsRe: {
                            reLoanId: dataPost.reLoanId,
                            carId: dataPost.carId,
                            customerId: dataPost.customerId,
                            productType: dataPost.productType,
                            productLoanType: dataPost.productLoanType,
                            ftime: dataPost.ftime,
                            otime: dataPost.otime,
                            DAMT: dataPost.DAMT,
                            tcshprc: dataPost.tcshprc,
                            balanc: dataPost.balanc,
                            totalpayment: dataPost.totalpayment,
                            neekong: dataPost.neekong,
                            beeypup: dataPost.beeypup,
                            OTHR: dataPost.OTHR,
                            NOPAY: dataPost.NOPAY,
                            nopays: dataPost.nopays,
                            reqNo: dataPost.reqNo,
                            re1: dataPost.re1,
                            GRDCOD: dataPost.GRDCOD,
                            proposalBy: dataPost.proposalBy,
                            reviewedBy: dataPost.reviewedBy,
                            approvalStatus: 21,
                            approvedLoanAmount: dataPost.approvedLoanAmount,
                            approvedLoanTerm: dataPost.approvedLoanTerm,
                            approvedBy: dataPost.approvedBy,
                            approvalDate: dayjs(),
                            acceptDatePg: dataPost.acceptDatePg,
                            acceptByPg: dataPost.acceptByPg,
                            // IsAccepted: dataPost,
                            // CustomerApprovedLoan: dataPost,
                            Branch: dataPost.branch,
                            // CusIsAccepted: dataPost,
                            // CusIsAcceptedDate: dataPost,
                            checkGua: checkGuas,
                            approvalConditions: true
                        },
                        carLoanDetails: {
                            monthlyPayment: monthlyPayment,
                            interest: interest,
                            installmentWithInterest: installmentWithInterest,
                            interestRate
                        },
                        noteCarRe: {
                            reLoanId: loanId,
                            cause: dataPost.cause,
                            note: dataPost.note,
                            noteBy: user,
                            status: typeNote,
                            rejectStatus: false,
                            noteDate: dayjs(),
                        },
                        guarantors: valueApCon
                    }
                    console.log("sendData", sendData)
                    // approveREcon
                    await axios.put(approveREcon, sendData)
                        .then(res => {
                            if (res.status === 200) {
                                console.log("OK")
                                successSendApp("top")
                                setTimeout(() => {
                                    CloseAll()
                                }, 2000)
                            }
                        }).catch(error => {
                            console.error(error);
                            errorSendNote("top")
                        });
                } else if (typeNote === "ไม่อนุมัติ") {
                    sendData = {
                        cars: {
                            ISSUNO: dataFromTable.ISSUNO,
                        },
                        carLoanDetailsRe: {
                            reLoanId: dataPost.reLoanId,
                            carId: dataPost.carId,
                            customerId: dataPost.customerId,
                            // productType: dataPost.productType,
                            productLoanType: dataPost.productLoanType,
                            // ftime: dataPost.ftime,
                            // otime: dataPost.otime,
                            // DAMT: dataPost.DAMT,
                            // tcshprc: dataPost.tcshprc,
                            // balanc: dataPost.balanc,
                            // totalpayment: dataPost.totalpayment,
                            // neekong: dataPost.neekong,
                            // beeypup: dataPost.beeypup,
                            // OTHR: dataPost.OTHR,
                            // NOPAY: dataPost.NOPAY,
                            // nopays: dataPost.nopays,
                            // reqNo: dataPost.reqNo,
                            // re1: dataPost.re1,
                            // GRDCOD: dataPost.GRDCOD,
                            proposalBy: dataPost.proposalBy,
                            // reviewedBy: dataPost.reviewedBy,
                            approvalStatus: 22,
                            // approvedLoanAmount: dataPost.approvedLoanAmount,
                            // approvedLoanTerm: dataPost.approvedLoanTerm,
                            // approvedBy: dataPost.approvedBy,
                            approvalDate: dayjs(),
                            // acceptDatePg: dataPost.acceptDatePg,
                            // acceptByPg: dataPost.acceptByPg,
                            // IsAccepted: dataPost,
                            // CustomerApprovedLoan: dataPost,
                            // Branch: dataPost.branch,
                            // CusIsAccepted: dataPost,
                            // CusIsAcceptedDate: dataPost,
                            // checkGua: checkGuas,
                            newOfferDate: dataPost?.newOfferDate
                        },
                        carLoanDetails: {
                        },
                        noteCarRe: {
                            reLoanId: loanId,
                            cause: dataPost.cause,
                            note: dataPost.note,
                            noteBy: user,
                            status: typeNote,
                            rejectStatus: false,
                            noteDate: dayjs(),
                        }
                    }
                    await axios.put(approveRE, sendData)
                        .then(res => {
                            if (res.status === 200) {
                                console.log("OK")
                                successSendApp("top")
                                setTimeout(() => {
                                    CloseAll()
                                }, 2000)
                            }
                        }).catch(error => {
                            console.error(error);
                            errorSendNote("top")
                        });
                } else if (typeNote === "ตีกลับ") {
                    sendData = {
                        ISSUNO: dataFromTable.ISSUNO,
                        productLoanType: dataPost.productLoanType,
                        proposalBy: dataPost.proposalBy,
                        username: dataPost.proposalBy,
                        reLoanId: loanId,
                        cause: dataPost.cause,
                        note: dataPost.note,
                        noteBy: user,
                        status: "คำร้องไม่ถูกต้อง",
                        rejectStatus: false,
                        noteDate: dayjs()
                    }
                    await axios.put(docnotpasspgre, sendData)
                        .then(res => {
                            if (res.status === 200) {
                                successSendNote("top")
                                setTimeout(() => {
                                    CloseAll()
                                }, 2000)
                            }
                        }).catch((err) => {
                            console.log(err)
                            errorSendNote("top")
                            setLoading(false);
                        })
                } else if (typeNote === "ลูกค้ายกเลิก") {
                    sendData = {
                        ISSUNO: dataFromTable.ISSUNO,
                        productLoanType: dataPost.productLoanType,
                        proposalBy: dataPost.proposalBy,
                        username: dataPost.proposalBy,
                        reLoanId: loanId,
                        cause: dataPost.cause,
                        note: dataPost.note,
                        noteBy: user,
                        status: "ลูกค้ายกเลิก",
                        rejectStatus: false,
                        noteDate: dayjs()
                    }
                    await axios.put(docnotpasspgre, sendData)
                        .then(res => {
                            if (res.status === 200) {
                                successSendNote("top")
                                setTimeout(() => {
                                    CloseAll()
                                }, 2000)
                            }
                        }).catch((err) => {
                            console.log(err)
                            errorSendNote("top")
                            setLoading(false);
                        })
                } else if (typeNote === "รับคำร้อง") {
                    let newProductLoanType
                    newProductLoanType = allData?.cars?.carLoanDetailsRe?.productLoanType
                    //     newProductLoanType = allData?.land?.landLoanDetailsRe?.productLoanLandType
                    let sendData = {
                        reLoanId: dataFromTable.reLoanId,
                        acceptByPg: user,
                        acceptDatePg: dayjs(),
                        approvalStatus: 7,
                        cause: dataPost.cause,
                        note: dataPost.note,
                        noteDate: dayjs(),
                        typeLoan: dataFromTable.typeLoan,
                        // newOfferDate: dataPost.newOfferDate,
                        ISSUNO: dataFromTable.ISSUNO,
                        username: dataFromTable.proposalBy,
                        productLoanType: newProductLoanType
                    }
                    console.log("sendData", sendData)
                    await axios.put(acceptpgre, { ...dataFromTable, ...sendData })
                        .then(res => {
                            if (res.status === 200) {
                                successAccept("top")
                                setTimeout(() => {
                                    CloseAll()
                                }, 2000)
                            }
                        }).catch((err) => {
                            errorAccept("top")
                            console.log(err)
                        })
                }
            } else {
                if (typeNote === "อนุมัติ") {
                    sendData = {
                        land: {
                            landId: dataCarLand.landId,
                            ISSUNO: dataFromTable.ISSUNO
                        },
                        landLoanDetailsRe: {
                            reLoanId: dataPost.reLoanId,
                            landId: dataPost.landId,
                            customerId: dataPost.customerId,
                            productType: dataPost.productType,
                            productLoanLandType: dataPost.productLoanLandType,
                            ftime: dataPost.ftime,
                            otime: dataPost.otime,
                            DAMT: dataPost.DAMT,
                            tcshprc: dataPost.tcshprc,
                            balanc: dataPost.balanc,
                            totalpayment: dataPost.totalpayment,
                            neekong: dataPost.neekong,
                            beeypup: dataPost.beeypup,
                            OTHR: dataPost.OTHR,
                            NOPAY: dataPost.NOPAY,
                            nopays: dataPost.nopays,
                            reqNo: dataPost.reqNo,
                            re1: dataPost.re1,
                            GRDCOD: dataPost.GRDCOD,
                            proposalBy: dataPost.proposalBy,
                            reviewedBy: dataPost.reviewedBy,
                            approvalStatus: 3,
                            approvedLoanAmount: dataPost.approvedLoanAmount,
                            approvedLoanTerm: dataPost.approvedLoanTerm,
                            approvedBy: dataPost.approvedBy,
                            approvalDate: dayjs(),
                            acceptDatePg: dataPost.acceptDatePg,
                            acceptByPg: dataPost.acceptByPg,
                            // IsAccepted: dataPost,
                            // CustomerApprovedLoan: dataPost,
                            branch: dataPost.branch,
                            // CusIsAccepted: dataPost,
                            // CusIsAcceptedDate: dataPost,
                            checkGua: checkGuas,
                            approvalConditions: false
                        },
                        landLoanDetails: {
                            monthlyPaymentLand: monthlyPayment,
                            interestLand: interest,
                            installmentWithInterest: installmentWithInterest,
                            InterestRateLand: interestRate
                        },
                        noteLandRe: {
                            reLoanId: loanId,
                            cause: dataPost.cause,
                            note: dataPost.note,
                            noteBy: user,
                            status: typeNote,
                            rejectStatus: false,
                            noteDate: dayjs(),
                        }
                    }
                    await axios.put(approveRELand, sendData)
                        .then(res => {
                            if (res.status === 200) {
                                console.log("OK")
                                successSendApp("top")
                                setTimeout(() => {
                                    CloseAll()
                                }, 2000)
                            }
                        }).catch(error => {
                            console.error(error);
                            errorSendNote("top")
                        });
                } else if (typeNote === "อนุมัติแบบมีเงื่อนไข") {
                    sendData = {
                        land: {
                            landId: dataCarLand.landId,
                            ISSUNO: dataFromTable.ISSUNO,
                        },
                        landLoanDetailsRe: {
                            reLoanId: dataPost.reLoanId,
                            landId: dataPost.landId,
                            customerId: dataPost.customerId,
                            productType: dataPost.productType,
                            productLoanLandType: dataPost.productLoanLandType,
                            ftime: dataPost.ftime,
                            otime: dataPost.otime,
                            DAMT: dataPost.DAMT,
                            tcshprc: dataPost.tcshprc,
                            balanc: dataPost.balanc,
                            totalpayment: dataPost.totalpayment,
                            neekong: dataPost.neekong,
                            beeypup: dataPost.beeypup,
                            OTHR: dataPost.OTHR,
                            NOPAY: dataPost.NOPAY,
                            nopays: dataPost.nopays,
                            reqNo: dataPost.reqNo,
                            re1: dataPost.re1,
                            GRDCOD: dataPost.GRDCOD,
                            proposalBy: dataPost.proposalBy,
                            reviewedBy: dataPost.reviewedBy,
                            approvalStatus: 21,
                            approvedLoanAmount: dataPost.approvedLoanAmount,
                            approvedLoanTerm: dataPost.approvedLoanTerm,
                            approvedBy: dataPost.approvedBy,
                            approvalDate: dayjs(),
                            acceptDatePg: dataPost.acceptDatePg,
                            acceptByPg: dataPost.acceptByPg,
                            // IsAccepted: dataPost,
                            // CustomerApprovedLoan: dataPost,
                            branch: dataPost.branch,
                            // CusIsAccepted: dataPost,
                            // CusIsAcceptedDate: dataPost,
                            checkGua: checkGuas,
                            approvalConditions: false
                        },
                        landLoanDetails: {
                            monthlyPaymentLand: monthlyPayment,
                            interestLand: interest,
                            installmentWithInterest: installmentWithInterest,
                            InterestRateLand: interestRate
                        },
                        noteLandRe: {
                            reLoanId: loanId,
                            cause: dataPost.cause,
                            note: dataPost.note,
                            noteBy: user,
                            status: typeNote,
                            rejectStatus: false,
                            noteDate: dayjs(),
                        },
                        guarantors: valueApCon
                    }
                    // approveREcon
                    await axios.put(approveREconLand, sendData)
                        .then(res => {
                            if (res.status === 200) {
                                console.log("OK")
                                successSendApp("top")
                                setTimeout(() => {
                                    CloseAll()
                                }, 2000)
                            }
                        }).catch(error => {
                            console.error(error);
                            errorSendNote("top")
                        });
                } else if (typeNote === "ไม่อนุมัติ") {
                    sendData = {
                        land: {
                            // landId: dataCarLand.landId,
                            ISSUNO: dataFromTable.ISSUNO,
                        },
                        landLoanDetailsRe: {
                            reLoanId: dataPost.reLoanId,
                            landId: dataPost.landId,
                            customerId: dataPost.customerId,
                            // productType: dataPost.productType,
                            productLoanLandType: dataPost.productLoanLandType,
                            // ftime: dataPost.ftime,
                            // otime: dataPost.otime,
                            // DAMT: dataPost.DAMT,
                            // tcshprc: dataPost.tcshprc,
                            // balanc: dataPost.balanc,
                            // totalpayment: dataPost.totalpayment,
                            // neekong: dataPost.neekong,
                            // beeypup: dataPost.beeypup,
                            // OTHR: dataPost.OTHR,
                            // NOPAY: dataPost.NOPAY,
                            // nopays: dataPost.nopays,
                            // reqNo: dataPost.reqNo,
                            // re1: dataPost.re1,
                            // GRDCOD: dataPost.GRDCOD,
                            proposalBy: dataPost.proposalBy,
                            // reviewedBy: dataPost.reviewedBy,
                            approvalStatus: 22,
                            // approvedLoanAmount: dataPost.approvedLoanAmount,
                            // approvedLoanTerm: dataPost.approvedLoanTerm,
                            // approvedBy: dataPost.approvedBy,
                            approvalDate: dayjs(),
                            // acceptDatePg: dataPost.acceptDatePg,
                            // acceptByPg: dataPost.acceptByPg,
                            // IsAccepted: dataPost,
                            // CustomerApprovedLoan: dataPost,
                            // branch: dataPost.branch,
                            // CusIsAccepted: dataPost,
                            // CusIsAcceptedDate: dataPost,
                            // checkGua: checkGuas,
                            // approvalConditions: false
                            newOfferDate: dataPost?.newOfferDate
                        },
                        landLoanDetails: {
                            // monthlyPayment: monthlyPayment,
                            // interest: interest,
                            // installmentWithInterest: installmentWithInterest,
                            // interestRate
                        },
                        noteLandRe: {
                            reLoanId: loanId,
                            cause: dataPost.cause,
                            note: dataPost.note,
                            noteBy: user,
                            status: typeNote,
                            rejectStatus: false,
                            noteDate: dayjs(),
                        }
                    }
                    await axios.put(approveRELand, sendData)
                        .then(res => {
                            if (res.status === 200) {
                                console.log("OK")
                                successSendApp("top")
                                setTimeout(() => {
                                    CloseAll()
                                }, 2000)
                            }
                        }).catch(error => {
                            console.error(error);
                            errorSendNote("top")
                        });
                } else if (typeNote === "ตีกลับ") {
                } else if (typeNote === "ลูกค้ายกเลิก") {
                    sendData = {
                        ISSUNO: dataFromTable.ISSUNO,
                        productLoanType: dataPost.productLoanLandType,
                        proposalBy: dataPost.proposalBy,
                        username: dataPost.proposalBy,
                        reLoanId: loanId,
                        cause: dataPost.cause,
                        note: dataPost.note,
                        noteBy: user,
                        status: "ลูกค้ายกเลิก",
                        rejectStatus: false,
                        noteDate: dayjs(),
                    }

                    await axios.put(docnotpasspgreland, sendData)
                        .then(res => {
                            if (res.status === 200) {
                                successSendNote("top")
                                setTimeout(() => {
                                    CloseAll()
                                }, 2000)
                            }
                        }).catch((err) => {
                            console.log(err)
                            errorSendNote("top")
                            setLoading(false);
                        })
                } else if (typeNote === "รับคำร้อง") {
                    let newProductLoanType
                    // newProductLoanType = allData?.cars?.carLoanDetailsRe?.productLoanType
                    newProductLoanType = allData?.land?.landLoanDetailsRe?.productLoanLandType
                    let sendData = {
                        reLoanId: dataFromTable.reLoanId,
                        acceptByPg: user,
                        acceptDatePg: dayjs(),
                        approvalStatus: 7,
                        cause: dataPost.cause,
                        note: dataPost.note,
                        noteDate: dayjs(),
                        typeLoan: dataFromTable.typeLoan,
                        // newOfferDate: dataPost.newOfferDate,
                        ISSUNO: dataFromTable.ISSUNO,
                        username: dataFromTable.proposalBy,
                        productLoanType: newProductLoanType
                    }
                    console.log("sendData", sendData)
                    await axios.put(acceptpgre, { ...dataFromTable, ...sendData })
                        .then(res => {
                            if (res.status === 200) {
                                successAccept("top")
                                setTimeout(() => {
                                    CloseAll()
                                }, 2000)
                            }
                        }).catch((err) => {
                            errorAccept("top")
                            console.log(err)
                        })
                }
            }
        }




        // console.log("sendData", sendData)
        setLoading(false);
    }

    const handleChangeCause = (value) => {
        setDataPost({ ...dataPost, cause: `${value}` })
        // setSendData({ ...sendData, cause: `${value}` })
    }

    const handleLoanTerm = (value) => {
        setDataPost({ ...dataPost, approvedLoanTerm: value })
    }

    const handleAddCause = async () => {
        if (addCauseName?.name !== '') {
            await axios.post(addcausename, addCauseName)
                .then(res => {
                    if (res.status === 200) {
                        formAddCauseName.resetFields();
                        setResetPage(dayjs())
                        setCheckAddCause(false);
                        successSendCause("top")
                    }
                }).catch((err) => console.log(err))
        } else {
            errorSendCause("top")
        }
    }

    const handleCheckBtnAddCause = () => {
        if (checkAddCause === false) {
            setCheckAddCause(true);
        } else {
            setCheckAddCause(false);
        }
    };

    const currencyFormatOne = (amount) => {
        if (amount) {
            return Number(amount)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, '$&,')
        }
    }

    const handleMoney = (values) => {
        //console.log("values",values)
        setDataPost({
            ...dataPost,
            approvedLoanAmount: values?.loanAmountt,
            approvedLoanTerm: values?.installmentAmountt,
            interestRate: values?.interestRate,
            interest: values?.interestt,
            monthlyPayment: values?.monthlyPayment,
            installmentWithInterest: values?.installmentWithInterestt
        })
        setMonthlyPayment(values?.monthlyPayment)
        setInterest(values?.interestt)
        setInstallmentWithInterest(values?.installmentWithInterestt)
        form.setFieldsValue(
            {
                approvedLoanAmount: values?.loanAmountt,
                approvedLoanTerm: values?.installmentAmountt,
                interestRate: values?.interestRate,
                interest: currencyFormatOne(values?.interestt),
                monthlyPayment: currencyFormatOne(values?.monthlyPayment),
                installmentWithInterest: currencyFormatOne(values?.installmentWithInterestt),
            }
        )
    }

    const handleMoneyHP = (values) => {
        setDataPost({
            ...dataPost,
            approvedLoanAmount: values?.loanAmountt,
            approvedLoanTerm: values?.installmentAmountt,
            interestRate: values?.interestRate,
            interest: values?.interestt,
            monthlyPayment: values?.monthlyPayment,
            installmentWithInterest: values?.installmentWithInterestt
        })
        setMonthlyPayment(values?.monthlyPayment)
        setInterest(values?.interestt)
        setInstallmentWithInterest(values?.installmentWithInterestt)
        form.setFieldsValue(
            {
                approvedLoanAmount: values?.loanAmountt,
                approvedLoanTerm: values?.installmentAmountt,
                interestRate: values?.interestRate,
                interest: currencyFormatOne(values?.interestt),
                monthlyPayment: currencyFormatOne(values?.monthlyPayment),
                installmentWithInterest: currencyFormatOne(values?.installmentWithInterestt),
            }
        )
    }

    const onChange = (v, i) => {
        const updatedGuarantorData = valueApCon?.map((item, index) => {
            if (index === i) {
                return { ...item, checkApCon: v.target.checked };
            } else {
                return { ...item };
            }
        });
        setValueApCon(updatedGuarantorData);
    };

    const renderGuarantorNewImg = ({ item, index }) => { //item คือตัวแปรที่เอาไว้ดึงค่าของข้อมูลออกมาแสดง โดยใช้ item.ชื่อฟิวล์
        return (
            <>
                <Card
                    title={"ลำดับที่ " + (item.garno)}
                    style={{ textAlign: "center" }}
                    key={index}
                >
                    <Row style={{ margin: '3px' }} justify={'center'} >
                        <Col span={24} style={{ textAlign: "left" }}>
                            <div>
                                <span>ชื่อ-สกุล :</span>  <b>{item?.firstname} {item.lastname}</b>
                            </div>
                            <div>
                                <span>เบอร์โทรติดต่อ :</span> <b>{item?.phones[0]?.telp}</b>
                            </div>
                            <div>
                                <span>ความสัมพันธ์ :</span> <b>{item?.description}</b>
                            </div>
                            <Divider />
                            <div style={{ textAlign: 'center' }}>
                                <Checkbox onChange={(value) => onChange(value, index)} style={{ color: "red" }}>ไม่ผ่าน(ลบ)</Checkbox>
                            </div>
                        </Col>
                    </Row>
                </Card >

            </>
        )
    }

    const onChangeNewOfferDate = (date, dateString) => {
        console.log(date, dateString)
        setDataPost({ ...dataPost, newOfferDate: date })
    }


    // console.log("dataPost", dataPost)

    return (
        <Modal open={open} onCancel={handleClose} footer={[null]} title={`หมายเหตุ ${typeNote}`} width={700}>
            <Spin spinning={loading} size='large' tip=" Loading... ">
                <Form
                    labelCol={{
                        span: 7,
                    }}
                    wrapperCol={{
                        span: 15,
                    }}
                    form={form}
                    onFinish={handleSubmit}
                >
                    <Card>
                        <Row>
                            {/* <Col span={22}> */}
                            {
                                typeNote === "อนุมัติแบบมีเงื่อนไข" ?
                                    <>
                                        <Row gutter={32}  >
                                            {
                                                guarantorData?.map((item, index) => {
                                                    return renderGuarantorNewImg({ item, index, key: `{item.identificationId} - ${index}` })
                                                })
                                            }
                                        </Row>
                                    </>
                                    : null
                            }
                            {/* </Col> */}
                            <Divider />
                            <Col span={22}>
                                <Form.Item
                                    label="สาเหตุ"
                                    name="cause"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'กรุณากรอกสาเหตุ'
                                        },]}>
                                    {
                                        typeNote === "รับคำร้อง" ?
                                            <Select
                                                mode="multiple"
                                                style={{
                                                    width: '100%',
                                                }}
                                                size="large"
                                                placeholder="สาเหตุ"
                                                onChange={handleChangeCause}
                                                options={options}
                                            />
                                            :
                                            <Select
                                                mode="multiple"
                                                style={{
                                                    width: '100%',
                                                }}
                                                size="large"
                                                placeholder="สาเหตุ"
                                                onChange={handleChangeCause}
                                                options={options}
                                            />
                                    }
                                </Form.Item>
                                {
                                    checkAddCause === false ?
                                        null
                                        :
                                        <>
                                            <Form
                                                form={formAddCauseName}
                                                labelCol={{
                                                    span: 7,
                                                }}
                                                wrapperCol={{
                                                    span: 15,
                                                }}>
                                                <Form.Item label='เพิ่มสาเหตุ' name='name'>
                                                    <Input type='text' size="small" placeholder='กรอกสาเหตุที่ต้องการเพิ่ม' style={{ color: 'black' }}
                                                        onChange={(e) => setAddCauseName({ ...addCauseName, name: e.target.value })}
                                                    />
                                                </Form.Item>
                                            </Form>
                                        </>

                                }
                                <Form.Item label="รายละเอียด" name="note">
                                    <TextArea rows={5} onChange={(e) => setDataPost({ ...dataPost, note: e.target.value })} />
                                </Form.Item>
                                {
                                    typeNote === "ไม่อนุมัติ" || typeNote === "ปฏิเสธคำร้อง" ?
                                        <Form.Item label="วันที่นำเคสกลับมารีใหม่ได้" name="newOfferDate"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'กรุณาเลือกวันที่นำเคสกลับมารีใหม่ได้'
                                                },]}
                                        >
                                            <DatePicker style={{ width: '100%', height: '40px' }} onChange={onChangeNewOfferDate} />
                                        </Form.Item>
                                        :
                                        null
                                }
                            </Col>
                            <Col span={2}>
                                {
                                    typeNote === "รับคำร้อง" ?
                                        null
                                        :
                                        <>
                                            {
                                                checkAddCause === false ?
                                                    <Form.Item>
                                                        <Button type="primary" onClick={handleCheckBtnAddCause}>
                                                            +
                                                        </Button>
                                                    </Form.Item>
                                                    :
                                                    <Form.Item>
                                                        <Button type="primary" onClick={handleCheckBtnAddCause}>
                                                            -
                                                        </Button >
                                                    </Form.Item>
                                            }
                                        </>
                                }
                            </Col>
                            <Col span={22}>
                                {
                                    typeNote === "อนุมัติ" || typeNote === "อนุมัติแบบมีเงื่อนไข" ?
                                        <>
                                            {
                                                dataFromTable.typeLoan === "car" ?
                                                    <>
                                                        <Form.Item label="ประเภทค่างวด" name="productType">
                                                            {
                                                                typeData === 1 ?
                                                                    <Select
                                                                        placeholder={'เลือก'}
                                                                        style={{ height: '40px' }}
                                                                        onChange={(value) => { handleChangeProductType(value) }}
                                                                        options={loanPLorCH}
                                                                    />
                                                                    :
                                                                    <Select
                                                                        placeholder={'เลือก'}
                                                                        style={{ height: '40px' }}
                                                                        onChange={(value) => { handleChangeProductType(value) }}
                                                                        options={loanPLorCHBig}
                                                                    />
                                                            }
                                                        </Form.Item>
                                                        {
                                                            dataPost?.productType === "p-loan" ?
                                                                <>
                                                                    <Form.Item label='ตารางค่างวด'>
                                                                        <Button type="primary" onClick={handleOpenTableP}>
                                                                            ตารางค่างวด P-LOAN
                                                                        </Button>
                                                                    </Form.Item>
                                                                </>
                                                                : dataPost?.productType === "เช่าซื้อ" ?
                                                                    <>
                                                                        {
                                                                            dataCarLand?.productTypeCar === 1 ?
                                                                                <>
                                                                                    <Form.Item label='ตารางค่างวด'>
                                                                                        <Button type="primary" onClick={handleOpenTable}>
                                                                                            ตารางค่างวด เช่าซื้อ
                                                                                        </Button>
                                                                                    </Form.Item>
                                                                                </>
                                                                                : null
                                                                        }
                                                                    </> : null
                                                        }
                                                        <Form.Item name="approvedLoanAmount" label="ยอดจัดใหม่"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please input !'
                                                                },]}>
                                                            <Input
                                                                type='number'
                                                                disabled={checkDis}
                                                                style={{ height: "40px" }}
                                                                onChange={(e) => setDataPost({ ...dataPost, approvedLoanAmount: e.target.value })}
                                                            />
                                                        </Form.Item>
                                                        <Form.Item name="approvedLoanTerm" label="จำนวนงวด"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please input !'
                                                                },]}>
                                                            <Select
                                                                disabled={checkDis}
                                                                style={{ height: "40px" }}
                                                                placeholder="เลือกจำนวนงวด"
                                                                onChange={handleLoanTerm}
                                                                options={loanTermMid}
                                                            />
                                                        </Form.Item>
                                                        {
                                                            dataPost?.productType === "เช่าซื้อ" &&
                                                                (typeData === 2 || typeData === 3) ?
                                                                <>
                                                                    <Form.Item label='อัตราดอกเบี้ย' name='interestRate'
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: 'Please input !'
                                                                            },]}>
                                                                        <Input type='number'
                                                                            suffix="% +VAT"
                                                                            name="interestRate"
                                                                            style={{ height: "50px" }}
                                                                            onChange={(e) => setInterestRate(parseFloat(e.target.value))}
                                                                        />

                                                                    </Form.Item>
                                                                </>
                                                                : dataPost?.productType === "เช่าซื้อ1" ?
                                                                    <>
                                                                        <Form.Item label='อัตราดอกเบี้ย' name='interestRate'
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: 'Please input !'
                                                                                },]}>
                                                                            <Select
                                                                                style={{ height: "40px" }}
                                                                                placeholder={'เลือก'}
                                                                                onChange={(value) => setInterestRate(value)}
                                                                                label="จำนวนงวด"
                                                                                options={[
                                                                                    { label: 0.75, value: 0.75 }, { label: 0.84, value: 0.84 },
                                                                                ]}
                                                                            ></Select>
                                                                        </Form.Item>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <Form.Item label='อัตราดอกเบี้ย' name='interestRate'
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: 'Please input !'
                                                                                },]}>
                                                                            <Input name='interestRate' disabled />
                                                                            {/* <b>{interestRate}</b> */}
                                                                        </Form.Item>
                                                                    </>
                                                        }
                                                        <Form.Item label='งวดละ' name='monthlyPayment'
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please input !'
                                                                },]}>
                                                            <Input disabled style={{ color: 'black' }} suffix="บาท"
                                                                onChange={(e) => setDataPost({ ...dataPost, monthlyPayment: e.target.value })}
                                                            />
                                                        </Form.Item>
                                                        <Form.Item label='ดอกเบี้ย' name='interest'
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please input !'
                                                                },]}>
                                                            <Input disabled style={{ color: 'black' }} suffix="บาท"
                                                                onChange={(e) => setDataPost({ ...dataPost, interest: e.target.value })}
                                                            />
                                                        </Form.Item>
                                                        <Form.Item label='รวมราคา' name='installmentWithInterest'
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please input !'
                                                                },]}>
                                                            <Input disabled style={{ color: 'black' }} suffix="บาท"
                                                                onChange={(e) => setDataPost({ ...dataPost, installmentWithInterest: e.target.value })}
                                                            />
                                                        </Form.Item>
                                                    </>
                                                    :
                                                    <>
                                                        <Form.Item name="approvedLoanAmount" label="ยอดจัดใหม่"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please input !'
                                                                },]}>
                                                            <Input
                                                                type='number'
                                                                // disabled={checkDis}
                                                                style={{ height: "40px" }}
                                                                onChange={(e) => setDataPost({ ...dataPost, approvedLoanAmount: e.target.value })}
                                                            />
                                                        </Form.Item>
                                                        <Form.Item name="approvedLoanTerm" label="จำนวนงวด"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please input !'
                                                                },]}>
                                                            <Select
                                                                // disabled={checkDis}
                                                                style={{ height: "40px" }}
                                                                placeholder="เลือกจำนวนงวด"
                                                                onChange={handleLoanTerm}
                                                                options={loanTermMid}
                                                            />
                                                        </Form.Item>
                                                        <Form.Item label='งวดละ' name='monthlyPayment'
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please input !'
                                                                },]}>
                                                            <Input disabled style={{ color: 'black' }} suffix="บาท"
                                                                onChange={(e) => setDataPost({ ...dataPost, monthlyPayment: e.target.value })}
                                                            />
                                                        </Form.Item>
                                                        <Form.Item label='ดอกเบี้ย' name='interest'
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please input !'
                                                                },]}>
                                                            <Input disabled style={{ color: 'black' }} suffix="บาท"
                                                                onChange={(e) => setDataPost({ ...dataPost, interest: e.target.value })}
                                                            />
                                                        </Form.Item>
                                                        <Form.Item label='รวมราคา' name='installmentWithInterest'
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please input !'
                                                                },]}>
                                                            <Input disabled style={{ color: 'black' }} suffix="บาท"
                                                                onChange={(e) => setDataPost({ ...dataPost, installmentWithInterest: e.target.value })}
                                                            />
                                                        </Form.Item>
                                                    </>
                                            }
                                        </>
                                        : null
                                }

                            </Col>

                        </Row>
                    </Card>
                    <Divider />
                    <Row>
                        <Col span={24} style={{ textAlign: 'center' }}>
                            <Space>
                                {
                                    checkAddCause === true ?
                                        <Button type='primary' onClick={handleAddCause}>เพิ่มหมายเหตุ</Button>
                                        : null
                                }
                                <Button type="primary" onClick={handleClose} danger>
                                    ปิด
                                </Button>
                                <Button type="primary" disabled={checkAddCause} htmlType="submit" style={{ backgroundColor: "green" }}>
                                    บันทึก
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
                {
                    isModalTableP ?
                        <Installment open={isModalTableP} close={setIsModalTableP} money={handleMoney} type={money} cp={~~dataPost.approvedLoanAmount} cp2={~~dataPost.approvedLoanTerm} checkGARFE={2} checkPG={1} />
                        : null
                }
                {
                    isModalTable ?
                        <HirePurchase open={isModalTable} close={setIsModalTable} money={handleMoneyHP} type={money} cp={~~dataPost.approvedLoanAmount} cp2={~~dataPost.approvedLoanTerm} checkGARFE={2} checkPG={1} />
                        : null
                }
                {

                }
            </Spin>
            {contextHolder}

        </Modal >

    )
}

export default NoteRe