import React, { useState, useEffect } from "react";
import { PlusCircleFilled, HomeFilled, EditOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, Input, Row, Table, Spin, Space, Card, Radio, DatePicker, Statistic, Divider, Checkbox, Select, Tag } from 'antd';
import axios from "axios";
import dayjs from "dayjs";
import ModalInfoLandRe from './modals/ModalInfoLandRe';
import PrintLand_V2 from './print/PrintLand_V2';
import ModalAcceptRe from './modals/ModalAcceptRe';
import ModalDetailRe from './modals/ModalDetailRe';
import EditPrice from './modals_price/EditPrice';
import { colorApprovalStatus, colorProductLoanType } from "../file_mid/status_color";
import { useDispatch } from "react-redux";
import { addCustomer } from '../../redux/Customer';
import { addCar } from '../../redux/Car';
import { addAddress } from '../../redux/Address';
import { addPhone } from '../../redux/Phone';
import { addLoan } from '../../redux/Loan';
import { addOldLoan } from '../../redux/OldLoan';
import { addImage } from '../../redux/Image';
import { addLand } from '../../redux/Land';
import { addLoanLand } from '../../redux/LoanLand';
import { addOldLoanLand } from '../../redux/OldLoanLand';
import ExcelJS from 'exceljs';
import { buttonApprovedLandPn } from '../file_mid/condition';
import { getlandrepn } from "../file_mid/all_api";
import '../css/Media.css'
const { Countdown } = Statistic;

export default function MainLandPnRe() {

  const currentDate = new Date(); // สร้างอ็อบเจ็กต์ Date สำหรับวันที่ปัจจุบัน
  const year = currentDate.getFullYear(); // ดึงปีปัจจุบัน
  const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // ดึงเดือนและจัดรูปแบบให้มี 2 หลัก
  const day = String(currentDate.getDate()).padStart(2, '0'); // ดึงวันและจัดรูปแบบให้มี 2 หลัก
  const formattedDate = `${year}-${month}-${day}`;
  const currentDateTime = dayjs()
  const lastMonth = dayjs().subtract(1, 'month').format("YYYY-MM-DD")
  const lastMonthShow = dayjs().subtract(1, 'month')

  const dispatch = useDispatch()
  const [editData, setEditData] = useState();
  const [modalEditPrice, setModalEditPrice] = useState(false);
  const [modalDetail, setModalDetail] = useState(false);
  const [isModalInfoLand, setIsModalInfoLand] = useState(false);
  const [modalAccept, setModalAccept] = useState(false); // Approve
  const [printLandV2, setPrintLandV2] = useState(false);
  const [axiosData, setAxiosData] = useState([]);
  const [arrayTable, setArrayTable] = useState([]);
  const [dataPost, setDataPost] = useState({ sec: 0, days1: lastMonth, days2: formattedDate, approvalStatus: "ทั้งหมด" })
  const [loading, setLoading] = useState(false)
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [New, setNew] = useState()



  useEffect(() => {
    dispatch(addCustomer())
    dispatch(addAddress())
    dispatch(addPhone())
    dispatch(addCar())
    dispatch(addLoan())
    dispatch(addOldLoan())
    dispatch(addImage())
    dispatch(addLand())
    dispatch(addLoanLand())
    dispatch(addOldLoanLand())
  }, [])

  useEffect(() => {
    loadData()
    // loadDataOlds()
    //loadCountData()
  }, [modalAccept, isModalInfoLand, modalEditPrice, dataPost])


  const loadData = async () => {
    if (dataPost?.days1 !== "" && dataPost?.days2 !== "") {
      setLoading(true)
      await axios.post(getlandrepn, { days1: dataPost?.days1, days2: dataPost?.days2, approvalStatus: dataPost?.approvalStatus })
        .then((res) => {
          // console.log("loaddata", res.data)
          res.data.forEach(item => {
            if (item.approvalStatus === 11) {
              item.approvalStatus = 12;
            }
          });
          setArrayTable(res.data)
          setAxiosData(res.data)
          setLoading(false)
        }).catch((err) => alert("ไม่พบข้อมูล"))
      setLoading(false)
    } else {
      setArrayTable([])
      setAxiosData([])
      //setUser([])
      setSelectedRecords([])
    }
  }

  const search = (data) => {
    const greaterThanTen = axiosData.filter(
      (item) =>
        item.firstname.toLowerCase().includes(data.target.value) ||
        item.numberLand.toLowerCase().includes(data.target.value)
    );
    setArrayTable(greaterThanTen)
  };

  const test = async (allData) => {
    arrayTable?.map((e) => {
      console.log("e", e.reviewedBy)
      var data = {}
      data.LandDateStatus = dayjs(e.carInput).format('YYYY-MM-DD HH:mm:ss');
      data.proposalBy = e.proposalBy
      data.productTypeLand = e.productTypeLand === 2 ? 'นส.4 จ.' : 'นส.3 ก.'
      data.numberLand = e.numberLand
      data.minPrice = e.minPrice
      data.maxPrice = e.maxPrice
      data.approvalStatus = e.approvalStatus
      allData.push(data)
      return null
    })
    return allData
  }

  const exportToExcel = async () => {
    var allData = []
    await test(allData)
    const workbook = new ExcelJS.Workbook();
    //  const worksheet = workbook.addWorksheet('Data');

    // สร้างชีทและเพิ่มข้อมูลลงในแต่ละชีท
    const addSheet = (sheetName, dataSource, headers_colums_name, headers_thai) => {

      const worksheet = workbook.addWorksheet(sheetName);
      // กำหนดความกว้างของแต่ละคอลัมน์
      worksheet.columns = [
        { key: "วันที่เสนอ", width: 20, },
        { key: "การตลาด", width: 10 },
        { key: "เอกสาร", width: 10 },
        { key: "เลขโฉนดที่ดิน", width: 30 },
        { key: "ยอดขั้นต่ำ", width: 20 },
        { key: "ยอดสูงสุด", width: 20 },
        { key: "สถานะ", width: 20 },
      ]
      worksheet.addRow(headers_thai);
      dataSource.forEach((row) => {
        const rowData = headers_colums_name.map((column) => row[column]);
        worksheet.addRow(rowData);
      });
    };

    // หัวข้อใน Excel

    const headers_thai = ['วันที่เสนอ', 'การตลาด', 'เอกสาร', 'เลขโฉนดที่ดิน', 'ยอดขั้นต่ำ', 'ยอดสูงสุด', 'สถานะ'];
    const headers_colums_name = ['LandDateStatus', 'proposalBy', 'productTypeLand', 'numberLand', 'minPrice', 'maxPrice', 'approvalStatus'];
    // // นำหัวข้อลงในแถวแรกของ Excel
    // // เพิ่มข้อมูลลงในแต่ละชีท 
    addSheet('รายงานพี่หนุ่ม', allData, headers_colums_name, headers_thai);
    // console.log("addSheet")

    // บันทึกไฟล์ Excel
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);

      // สร้างลิงก์สำหรับดาวน์โหลดไฟล์ Excel
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'dataPN.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });

  }

  const isAllSelected = (record) => {
    return selectedRecords?.length === arrayTable?.length
  }
  const handleSelectAll = () => {
    if (selectedRecords?.length === arrayTable?.length) {
      setSelectedRecords([]); // ถอดเลือกทั้งหมด
    } else {
      setSelectedRecords(arrayTable?.map((record) => record)); // เลือกทั้งหมด
    }
  }

  // สร้างฟังก์ชันเพื่อจัดการการเลือก record แต่ละอัน
  const handleSelect = (record) => {
    if (record) {
      const selectedIndex = selectedRecords.indexOf(record);
      if (selectedIndex === -1) {
        setSelectedRecords([...selectedRecords, record]); // เพิ่ม record ที่ถูกเลือก
      } else {
        const updatedSelectedRecords = selectedRecords.filter((r) => r !== record); // ถอดเลือก record
        setSelectedRecords(updatedSelectedRecords);
      }
    } else {
      setSelectedRecords([])
    }
  }

  // สร้างฟังก์ชันเพื่อตรวจสอบว่า record นี้ถูกเลือกหรือไม่
  const isSelected = (record) => {
    //console.log("isrecord", record)
    return selectedRecords.includes(record);
  }

  const columns = [
    {
      fixed: true,
      title: (
        <Checkbox
          checked={isAllSelected()}
          onChange={handleSelectAll}

        />
      ),
      dataIndex: 'selectAll',
      key: 'selectAll',
      width: '5%',
      align: 'center',
      render: (text, record) => (
        <Checkbox
          checked={isSelected(record)}
          onChange={() => handleSelect(record)}
        />
      ),
    },
    {
      fixed: true,
      title: "ลำดับ",
      dataIndex: "index",
      key: 'index',
      align: 'center',
      width: '5%',
      render: (text, object, index) => index + 1
    },
    {
      title: "เลขสัญญา",
      dataIndex: "ISSUNO",
      key: 'ISSUNO',
      align: 'center',
      width: '10%',
      // render: (text, object, index) => index + 1
    },
    {
      key: 'numberLand',
      title: "รายละเอียด",
      dataIndex: "numberLand",
      width: '20%',
      align: 'center',
      render: (text, record) => {
        // const renderArea = () => {
        //   return ((parseFloat(record.rai) * 400)) + ((parseFloat(record.workArea) * 100)) + parseFloat(record.squareWaArea)
        // }
        return (
          <>
            <div>{record.numberLand}</div>
            {/* <div>{record.numberLandLawang}</div> */}
            <div>{record.province} {record.district} </div>
            {/* <div>{renderArea()} {record.squareWaArea && "ตารางวา"}</div> */}
            {/* <div>{currencyFormatOne(record.landPrice)} {"บาทต่อตารางวา"} </div> */}
            {/* <div>เสนอโดย {record.proposalBy}</div> */}
          </>
        );
      }
    },
    {
      title: "วันที่เสนอ",
      dataIndex: "landInput",
      width: '10%',
      align: 'center',
      sorter: {
        compare: (a, b) => dayjs(a.landInput) - dayjs(b.landInput),
        multiple: 1,
      },
      render: (text, record) => (
        <Space size="middle">
          {
            record.landInput !== "0001-01-01T00:00:00Z" ?
              <Space>
                <>{dayjs(record.landInput).format("DD-MM-YYYY HH:mm")}</>
              </Space>
              : null
          }
        </Space>
      ),
      defaultSortOrder: 'descend',
    },
    // {
    //   title: "วันที่รับ",
    //   dataIndex: "approvalDate",
    //   width: 150,
    //   align: 'center',
    //   sorter: {
    //     compare: (a, b) => dayjs(a.approvalDate) - dayjs(b.approvalDate),
    //     multiple: 2,
    //   },
    //   render: (text, record) => (
    //     <Space size="middle">
    //       {
    //         record.approvalDate !== "0001-01-01T00:00:00Z" && record.approvalDate !== null ?
    //           <Space>
    //             <>{dayjs(record.approvalDate).format("DD-MM-YYYY HH:mm")}</>
    //           </Space>
    //           : null
    //       }
    //     </Space>
    //   ),
    // },
    {
      title: "ระยะเวลาที่คาดว่าจะเสร็จ",
      dataIndex: "",
      width: '10%',
      align: 'center',
      render: (text, record) => (
        <Space size="middle">
          {
            record.approvalDate !== "0001-01-01T00:00:00Z" && record.approvalDate !== null && record.approvalStatus !== 22 && record.approvalStatus !== 3 && record.reviewedBy === null ?
              <Space>
                <Countdown value={dayjs(record.approvalDate).add(3, 'hour')} />
              </Space>
              : null
          }
        </Space>
      ),
    },
    {
      title: "ยอดที่อนุมัติ",
      dataIndex: "minPrice",
      align: 'center',
      width: '20%',
      render: (text, record) => (
        <Space size="middle">
          {record.minPrice !== "0" ? (
            <Space>
              {currencyFormatOne(record.minPrice)} - {currencyFormatOne(record.maxPrice)}
            </Space>
          ) : (
            <Space>
              {"-"}
            </Space>
          )}
        </Space>
      ),
    },
    {
      title: "สถานะ",
      dataIndex: "approvalStatus",
      align: 'center',
      width: '10%',
      render: (text, record) => {
        let color
        let color2
        color = colorApprovalStatus(record?.productLoanLandType, record?.approvalStatus)
        color2 = colorProductLoanType(record?.productLoanLandType)
        return (
          <>

            <Tag color={color}>
              {record.approvalStatusName}
            </Tag>
          </>
        )
      },
    },
    {
      title: "Action",
      key: 'Action',
      align: 'center',
      width: '10%',
      render: (_, record) => (
        // console.log("record", record)
        <Space size="middle">
          {record.approvalStatus === 12 && record.priceStatus === false ?
            <Space>
              <Button
                onClick={() => {
                  setIsModalInfoLand(true);
                  setEditData(record);
                  // setStatusRe()
                }}
              >
                <EditOutlined />
              </Button>
              <Button
                onClick={() => {
                  setModalDetail(true);
                  setEditData(record);
                }}
              >
                <SearchOutlined />
              </Button>
            </Space>
            : buttonApprovedLandPn(record.approvalStatus, record.priceStatus) ?
              <Space>
                <Button
                  type="primary"
                  style={{ backgroundColor: '#757575' }}
                  onClick={() => {
                    setModalEditPrice(true);
                    setEditData(record);
                  }}
                >
                  แก้ยอด
                </Button>
                <Button
                  onClick={() => {
                    setModalDetail(true);
                    setEditData(record);
                  }}
                >
                  <SearchOutlined />
                </Button>
              </Space>
              :
              <Button
                onClick={() => {
                  setModalDetail(true);
                  setEditData(record);
                }}
              >
                <SearchOutlined />
              </Button>
          }
        </Space>
      ),
    },
  ];

  const currencyFormatOne = (amount) => {
    if (amount) {
      return Number(amount)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, '$&,')
    } else {
      return null
    }
  }

  const handleLandPn = () => {
    const newData = selectedRecords.map(item => {
      if (item.images) {
        const parsedImages = JSON.parse(item.images);
        return {
          ...item,
          images: parsedImages,
        };
      }
      return item;
    });
    //console.log(newData)
    setNew(newData)
    setPrintLandV2(true)
  }

  return (
    <>
      <Card>
        <Row gutter={32} justify={'center'}>
          <Col span={24}>
            <Spin spinning={loading} size='large' tip=" Loading... ">
              <div className='text-center'>
                <h2>ตรวจสอบข้อมูลเคสรี(พี่หนุ่ม)</h2>
              </div>
              <Divider />

              <Row gutter={24} justify={'center'} style={{ margin: 10 }}>
                <Col className='gutter-row' span={24} style={{ textAlign: 'center' }}>
                  <Space>
                    <>{"เลือกวันที่ต้องการแสดงเคส :"}</>
                    <DatePicker format={'YYYY-MM-DD'}
                      defaultValue={lastMonthShow}
                      style={{ height: '40px' }}
                      onChange={(e) => {
                        if (e) {
                          setDataPost({ ...dataPost, days1: e.format('YYYY-MM-DD') })
                        }
                      }} />
                    <>{"ถึง"}</>
                    <DatePicker format={'YYYY-MM-DD'}
                      defaultValue={currentDateTime}
                      style={{ height: '40px' }}
                      onChange={(e) => {
                        if (e) {
                          setDataPost({ ...dataPost, days2: e.format('YYYY-MM-DD') })
                        }
                      }} />
                    <Divider style={{ margin: 5 }} />
                  </Space>
                </Col>
              </Row>
              <Row gutter={32} justify={'center'}>
                <Space>
                  <Radio.Group size="large">
                    <Radio.Button
                      disabled
                      style={{
                        width: '140px',
                        border: '2px solid',
                        backgroundColor: '#e6f4ff',
                        color: '#1677ff'
                      }}>{"จำนวนเคส : "} {arrayTable?.length}</Radio.Button>
                  </Radio.Group>
                  <Button onClick={exportToExcel}>Export to Excel</Button>
                </Space>
              </Row>

              <Row gutter={32} style={{ margin: 10 }}>
                <Col className='gutter-row' span={24} style={{ textAlign: 'right' }}>
                  <Input.Search
                    style={{ width: '250px' }}
                    placeholder="ค้นหา...."
                    onChange={search}
                    name="search"
                    id="search"

                  />
                </Col>
              </Row>
              <Row gutter={32}>
                <Col span={24}>

                  <Table
                    rowKey={(record) => record.uid}
                    scroll={{
                      x: 1700,
                      y: 400,
                    }}
                    dataSource={arrayTable}
                    columns={columns}
                  >
                  </Table>

                </Col>
              </Row>


              <Col span={24} style={{ textAlign: 'center' }}>
                <Space>
                  {/* <Button href="/offercase" type="primary" ><PlusCircleFilled />เพิ่ม</Button> */}
                  <Button href="/" type="primary"><HomeFilled />หน้าหลัก</Button>
                  {/* <Button type="primary" onClick={() => setPrintLand(true)}><PlusCircleFilled />print</Button> */}
                  <Button type="primary" onClick={() => handleLandPn()}><PlusCircleFilled />print.V2</Button>
                  {/* <Button type="primary" onClick={() => setPrintLandV2(true)}><PlusCircleFilled />print.V2</Button> */}

                </Space>
              </Col>
            </Spin>
          </Col>
        </Row>
      </Card>
      {/*เคาะยอด*/}
      {
        isModalInfoLand ?
          <ModalInfoLandRe open={isModalInfoLand} close={setIsModalInfoLand} dataFromTable={editData} />
          : null
      }
      {/*รับงาน*/}
      {
        modalAccept ?
          <ModalAcceptRe open={modalAccept} close={setModalAccept} dataFromTable={editData} />
          : null
      }
      {/*ปริ้นซ์*/}
      {
        printLandV2 ?
          <PrintLand_V2 open={printLandV2} close={setPrintLandV2} userData={New} />
          : null
      }
      {/*ดูรายละเอียดเฉยๆ*/}
      {
        modalDetail ?
          <ModalDetailRe open={modalDetail} close={setModalDetail} dataFromTable={editData} />
          : null
      }
      {/*แก้ยอด*/}
      {
        modalEditPrice ?
          <EditPrice open={modalEditPrice} close={setModalEditPrice} dataFromTable={editData} />
          : null
      }
    </>
  )
};
