import React, { useState, useEffect } from "react";
import { HomeFilled, SearchOutlined, EditOutlined, SendOutlined } from '@ant-design/icons';
import { Button, Col, Input, Row, Table, Spin, Tag, Space, Card, Divider, Radio, Pagination } from 'antd';
import '../css/Media.css'
import axios from "axios";
import dayjs from "dayjs";
import { useDispatch } from 'react-redux';
import { addCustomer } from '../../redux/Customer';
import { addCar } from '../../redux/Car';
import { addAddress } from '../../redux/Address';
import { addPhone } from '../../redux/Phone';
import { addLoan } from '../../redux/Loan';
import { addOldLoan } from '../../redux/OldLoan';
import { addImage } from '../../redux/Image';
import { addLand } from '../../redux/Land';
import { addLoanLand } from '../../redux/LoanLand';
import { addOldLoanLand } from '../../redux/OldLoanLand';
import { addGuarantor } from '../../redux/Guarantor';
import { addCareerIncome } from '../../redux/CareerIncome';
import { addOccupation } from '../../redux/Occupation';
import { addEmail } from '../../redux/Email';
import { addSocial } from '../../redux/Social';
import { backc, textc, colorApprovalStatus, colorProductLoanType } from "../file_mid/status_color";
import { countStatusRe } from "../file_mid/all_options";
import ModalEditerReLand from "./Modal/ModalEditerReLand";
import ModalEditLand from "./Modal/ModalEditLand";
import { showOffercaseReLand } from '../file_mid/all_api';
import ModalInfoMK from "../file_mid/info/ModalInfoMK";
import SendtoPage from "./Modal/SendtoPages";

export default function MainLand_Re() {
  const dispatch = useDispatch()
  // const nicknameSalcod = localStorage.getItem('nicknameSalcod');
  const user = localStorage.getItem('username');
  // const nickname = localStorage.getItem('nickname');
  const token = localStorage.getItem('token');
  const branch = localStorage.getItem('branch');
  const [loading, setLoading] = useState(false)
  const [axiosData, setAxiosData] = useState([]);
  const [arrayTable, setArrayTable] = useState([]);
  // const showDay1 = dayjs("2015-01-01")
  const [keyWord, setKeyWord] = useState("ทั้งหมด");
  // const [dataPost, setDataPost] = useState({ day1: "2023-01-01", day2: "" })
  const [cData, setCData] = useState([]);
  const [dataCont, setDataCont] = useState()
  const [modalCont, setModalCont] = useState(false)
  const [modalEdit, setModalEdit] = useState(false)
  const [isModalInfoMK, setIsModalInfoMK] = useState(false);
  const [sendtopage, setSendtoPage] = useState(false);
  const [testPage, setTestPage] = useState();
  const [ssPage, setSsPage] = useState({ numberPage: 1, pageSize: 10 });
  const [currentPage, setCurrentPage] = useState(1)
  const [query, setQuery] = useState("");

  useEffect(() => {
    localStorage.removeItem('addCustomer');
    localStorage.removeItem('addAddress');
    localStorage.removeItem('addPhone');
    localStorage.removeItem('addCar');
    localStorage.removeItem('addGuarantor');
    localStorage.removeItem('addCareerIncome');
    localStorage.removeItem('addLoan');
    localStorage.removeItem('addOldLoan');
    localStorage.removeItem('addLand');
    localStorage.removeItem('addLoanLand');
    localStorage.removeItem('addOldLoanLand');
    localStorage.removeItem('addOccupation');
    localStorage.removeItem('addEmail');
    localStorage.removeItem('addSocial');
    localStorage.removeItem('addImage');
    localStorage.removeItem('addBroker');
    dispatch(addCustomer())
    dispatch(addAddress())
    dispatch(addPhone())
    dispatch(addGuarantor())
    dispatch(addCareerIncome())
    dispatch(addCar())
    dispatch(addLoan())
    dispatch(addOldLoan())
    dispatch(addImage())
    dispatch(addLand())
    dispatch(addLoanLand())
    dispatch(addOldLoanLand())
    dispatch(addOccupation())
    dispatch(addEmail())
    dispatch(addSocial())
  }, [])
  useEffect(() => {
    if (branch === "MIT" || branch === "UD" || branch === "LEX" || branch === "S4") {
      loadData()
    } else {
      alert("คุณไม่มีสิทธิ์ใช้งานเมนูนี้ !")
    }
  }, [keyWord, modalCont, modalEdit, ssPage])

  useEffect(() => {
    if (branch === "MIT" || branch === "UD" || branch === "LEX" || branch === "S4") {
      const delayDebounceFn = setTimeout(() => {
        console.log(query)
        searchLoadData(query)
      }, 1500)
      return () => clearTimeout(delayDebounceFn)
    }
  }, [query])

  const searchLoadData = async (data) => {
    console.log("searchLoadData data", data)
    if (data !== "") {
      console.log("OK searchLoadData data")
      await loadData(data)
    }
  }

  const loadCountData = async (data) => {
    if (!data) {
      setCData([])
    } else {
      const ez = data.filter(
        (item) =>
          item.approvalStatus === keyWord
      );
      setTestPage(...ez)
      setCData(data)
    }
  }
  const loadData = async (data) => {
    setLoading(true)
    const tk = JSON.parse(token)
    const headers = {
      "Authorization": `Bearer ${tk}`,
      "Menu": JSON.stringify("3")
    }
    if (query !== "" && data !== "stop") {
      var mainData = { proposalBy: user, approvalStatus: keyWord, numberPage: ssPage?.numberPage, pageSize: ssPage?.pageSize, keyword: query }
    } else {
      if (data === "stop") {
        var mainData = { proposalBy: user, approvalStatus: "ทั้งหมด", numberPage: ssPage?.numberPage, pageSize: ssPage?.pageSize }
      } else {
        var mainData = { proposalBy: user, approvalStatus: keyWord, numberPage: ssPage?.numberPage, pageSize: ssPage?.pageSize }
      }
    }
    await axios.post(showOffercaseReLand, mainData, { headers: headers })
      .then(async (res) => {
        // console.log("res.data Main_Re_offer", res.data)
        if (res.data.CountStatus) {
          await loadCountData(res.data.CountStatus)
        }
        if (res.data.ResultOffercaseReLand) {
          setArrayTable(res.data.ResultOffercaseReLand)
          setAxiosData(res.data.ResultOffercaseReLand)
        } else {
          setArrayTable([])
          setAxiosData([])
        }
        setLoading(false)
      }).catch((err) => {
        console.log(err)
        setLoading(false)
        alert("ไม่พบข้อมูล !!!")
      })
  }

  const onChangePagination = (e, pageSize) => {
    // console.log(e, pageSize)
    setCurrentPage(e)
    setSsPage({ numberPage: e, pageSize: pageSize })
  }
  const search = (data) => {
    // const greaterThanTen = axiosData.filter(
    //   (item) =>
    //     item.ISSUNO.toLowerCase().includes(data.target.value)
    // );
    // setArrayTable(greaterThanTen)
    if (data.target.value !== "") {
      setQuery(data.target.value)
    } else {
      setSsPage({ numberPage: 1, pageSize: 10 })
      setCurrentPage(1)
      setKeyWord("ทั้งหมด")
      setQuery("")
      // setTimeout(async () => {
      //   await loadData("stop")
      // }, 1500)
    }
  };

  const onChangeRadio = (value) => {
    // console.log("value", value)
    setKeyWord(value)
  }
  const columns = [
    {
      title: "ลำดับ",
      dataIndex: "index",
      key: 'index',
      align: 'center',
      //   fixed: true,
      width: "10%",
      render: (text, object, index) => index + 1
    },
    {
      title: "วันที่เสนอ",
      dataIndex: "landInput",
      width: "18%",
      align: 'center',
      //   sorter: {
      //     compare: (a, b) => dayjs(a.SDATE) - dayjs(b.SDATE),
      //     multiple: 1,
      //   },
      render: (text, record) => (
        <Space size="middle">
          {
            record.landInput !== "" ?
              <Space>
                <>{dayjs(record.landInput).format("DD-MM-YYYY")}</>
              </Space>
              : null
          }
        </Space>
      ),
      defaultSortOrder: 'descend',
    },
    {
      title: "เลขที่สัญญา(เก่า)",
      dataIndex: "ISSUNO",
      key: 'ISSUNO',
      align: 'center',
      width: "18%",
      //   sorter: {
      //     compare: (a, b) => a.CONTNO.length - b.CONTNO.length,
      //     multiple: 1,
      //   },
      render: (text, record) => (
        <>{record.ISSUNO}</>
      ),
    },
    {
      title: "เลขที่สัญญา(ใหม่)",
      dataIndex: "landContno",
      key: 'landContno',
      align: 'center',
      width: "15%",
      //   sorter: {
      //     compare: (a, b) => a.CONTNO.length - b.CONTNO.length,
      //     multiple: 1,
      //   },
      render: (text, record) => (
        <>{record.landContno}</>
      ),
    },
    {
      title: "ชื่อ-สกุล",
      dataIndex: "firstName",
      key: 'firstName',
      align: 'left',
      // fixed: true,
      width: "18%",
      // sorter: {
      //     compare: (a, b) => a.firstname.length - b.firstname.length,
      //     multiple: 1,
      // },
      render: (text, record) => (
        <>{record.snam}{record.firstname} {record.lastname}</>
      ),
    },
    {
      title: "สถานะ",
      dataIndex: "approvalStatus",
      align: 'center',
      width: "18%",
      render: (text, record) => {
        let color
        let color2
        color = colorApprovalStatus(record?.productLoanType, record?.approvalStatus)
        color2 = colorProductLoanType(record?.productLoanLandType)
        return (
          <>
            <Space>
              <Tag color={color}>
                {record.approvalStatusName}
              </Tag>
              <Tag color={color2}>
                {record.productLoanLandType}
              </Tag>
            </Space>
          </>
        );
      },
    },
    {
      title: "Action",
      key: 'Action',
      align: 'center',
      width: "18%",
      render: (text, record) => {
        const newOfferDate = dayjs(record.newOfferDate).startOf('day'); // วันที่ปลดรี แบบนับแต่วันที่
        const currentDate = dayjs().startOf('day'); // วันที่ปัจจุบัน แบบนับแต่วันที่
        return (
          <>
            {
              record.approvalStatus === 9 ?
                <>
                  <Space size="middle">
                    <Tag color="geekblue">กรุณาติดต่อเร่งรัดภาคสนาม</Tag>
                  </Space>
                </>
                : (newOfferDate < currentDate) && record.approvalStatus !== 10 ?
                  <>
                    <Space size="middle">
                      <Tag color="red">ใบหมดอายุ {dayjs(record.newOfferDate).format("DD/MM/YYYY")}</Tag>
                    </Space>
                  </>
                  : record.approvalStatus === 7 ?
                    <>
                      <Space size="middle">
                        <Button onClick={() => {
                          setModalCont(true)
                          setDataCont(record)
                        }}><EditOutlined /></Button>
                        <Button onClick={() => {
                          setIsModalInfoMK(true)
                          setDataCont(record)
                        }}><SearchOutlined /></Button>
                      </Space>
                    </>
                    : record.approvalStatus === 6 ?
                      <>
                        <Space size="middle">
                          <Button onClick={() => {
                            setModalEdit(true)
                            setDataCont(record)
                          }}>แก้ไข</Button>
                          <Button onClick={() => {
                            setIsModalInfoMK(true)
                            setDataCont(record)
                          }}><SearchOutlined /></Button>
                        </Space>
                      </>
                      : record.approvalStatus === 10 ?
                        <>
                          <Space size="middle">
                            <Button onClick={() => {
                              setSendtoPage(true)
                              setDataCont(record)
                            }}><SendOutlined />เสนอต่อ</Button>
                          </Space>
                        </>
                        :
                        <>
                          <Space size="middle">
                            <Button onClick={() => {
                              setIsModalInfoMK(true)
                              setDataCont(record)
                            }}><SearchOutlined /></Button>
                          </Space>
                        </>
            }
          </>
        );
      },
    },
  ];

  return (
    <>
      <Card>
        <Row gutter={32} style={{ textAlign: 'center' }}>
          <Col>
            <Spin spinning={loading} size='large' tip=" Loading... ">
              <div className='text-center'>
                <h2>ตรวจสอบข้อมูลสัญญาที่ส่งเสนอที่ดิน(การตลาด)</h2>
              </div>
              <Divider></Divider>
              <Row gutter={32} justify={'center'} style={{ margin: 10 }}>
                <Radio.Group value={keyWord} onChange={(e) => onChangeRadio(e.target.value)} >
                  {
                    countStatusRe.map((item) => (
                      <Radio.Button style={{
                        width: '160px',
                        border: '2px solid',
                        backgroundColor: backc[item.approvalStatus],
                        color: textc[item.approvalStatus]
                      }}
                        value={item.approvalStatusId}>{item.approvalStatus} </Radio.Button>
                    ))}
                </Radio.Group>
              </Row>
              <Row gutter={32} style={{ margin: 10 }}>
                <Col className='gutter-row' span={24} style={{ textAlign: 'right' }}>
                  <Input.Search
                    style={{ width: '250px' }}
                    placeholder="ค้นหา...."
                    onChange={search}
                    name="search"
                    id="search"

                  />
                </Col>
              </Row>
              <Row gutter={32}>
                <Col span={24}>
                  <Table
                    pagination={false}
                    rowKey={(record) => record.uid}
                    scroll={{
                      x: 1500,
                      y: 400,
                    }}
                    dataSource={arrayTable}
                    columns={columns}
                  >
                  </Table>
                  <Divider />
                  <Pagination
                    current={currentPage}
                    onChange={onChangePagination}
                    onShowSizeChange={onChangePagination}
                    defaultPageSize={10}
                    defaultCurrent={1}
                    total={testPage?.totalCount}
                  />
                  <Divider />
                </Col>
              </Row>

              <Col span={24} style={{ textAlign: 'center' }}>
                <Space>
                  {/* <Button href="/offercase" type="primary" ><PlusCircleFilled />เพิ่ม</Button> */}
                  <Button href="/" type="primary"><HomeFilled />หน้าหลัก</Button>
                </Space>
              </Col>
            </Spin>
          </Col>
        </Row>
      </Card >
      {
        modalCont ?
          <ModalEditerReLand open={modalCont} close={setModalCont} data={dataCont} /> // ปุ่มปากกา
          : null
      }
      {
        modalEdit ?
          <ModalEditLand open={modalEdit} close={setModalEdit} data={dataCont} /> // ปุ่ม แก้ไข
          : null
      }
      {
        isModalInfoMK ?
          <ModalInfoMK open={isModalInfoMK} close={setIsModalInfoMK} dataFromTable={dataCont} typeLoan={"landre"} />
          : null
      }
      {
        sendtopage ?
          <SendtoPage open={sendtopage} close={setSendtoPage} data={dataCont} /> // ปุ่ม โยกหน้า
          : null
      }
    </>
  )
}
