import { PhoneOutlined } from '@ant-design/icons'
import { Button, Card, Col, Divider, Form, Input, Row, Space, Tag } from 'antd'
import React, { useEffect, useState } from 'react'
import axios from "axios";
import { alldataland1, getcontnoJojonoi } from '../../file_mid/all_api';
const { TextArea } = Input;

function Tel({ allData, data, onCancel }) {

    const [telData, setTelData] = useState()
    const [cusData, setCusData] = useState()
    const [realTel] = useState([])
    const [loading, setLoading] = useState(false)

    const [newData, setNewData] = useState()

    // TestCutTel = TestCutTel.replace(/\.\s*|\(.*?\)|,.*|-|\s+.*$/g, '');
    // TestCutTel = TestCutTel.replace(/\.\s*|\(.*?\)|,.*|-|\s+.*$|^[^0-9]*\//g, '');
    // TestCutTel = TestCutTel.replace(/\.\s*|\(.*?\)|,.*|-|\s+.*$|^[^0-9]*\/|\*/g, '');
    // TestCutTel = TestCutTel.replace(/\.\s*|\(.*?\)|,.*|-|\s+.*$|^[^0-9]*\/|\*|^[^\d]*$/g, '');


    useEffect(() => {
        if (data.PRODUCTS === "car") {
            loadDataContno()
            // setTelData("083-678-4303/333-333-3333".replace(/[^\d]/g, ''))
            setTelData(allData?.custmast[0].TELP.replace(/[^\d]/g, ''))
            setCusData(allData?.custmast[0])
            if (realTel?.length === 0) {
                cutPhoneNumbers(allData?.custmast[0].TELP.replace(/[^\d]/g, ''))
            }
        } else {
            loadDataContnoLand()
            if (allData) {
                // setTelData("083-678-4303/333-333-3333".replace(/[^\d]/g, ''))
                setTelData(allData[0]?.TELP.replace(/[^\d]/g, ''))
                setCusData(allData[0])
                if (realTel?.length === 0) {
                    cutPhoneNumbers(allData[0]?.TELP.replace(/[^\d]/g, ''))
                }
            }
        }
    }, [allData  ])

    console.log("newData", newData)

    const loadDataContno = async () => {
        setLoading(true)
        await axios.get(`${getcontnoJojonoi}/${data?.CONTNO}`)
            .then(async res => {
                if (res.data) {
                    console.log("Contno42", res.data)
                    await setNewData(res.data)
                    setLoading(false)
                }
            }).catch((err) => {
                console.log("err", err)
            })
        setLoading(false)
    }

    const loadDataContnoLand = async () => {
        setLoading(true)
        await axios.post(alldataland1, { CONTNO: data?.CONTNO })
            .then(async res => {
                if (res.data) {
                    console.log("loadDataContnoLand", res.data)
                    await setNewData(res.data)
                    setLoading(false)
                }
            }).catch((err) => {
                console.log("err", err)
            })
        setLoading(false)
    }


    const handleCall = (item) => {
        window.location.href = `tel:+66${item}`;
    };


    const cutPhoneNumbers = (input) => {
        // console.log("input", input)
        // ลูปเพื่อประมวลผล input ทีละ 20 ตัวอักษร
        for (let i = 0; i < input?.length; i += 20) {
            // ตัดสตริง input ตั้งแต่ตำแหน่ง i ถึง i + 20 (รวม 20 ตัวอักษร)
            let segment = input?.substring(i, i + 20);

            // ตัดสตริง segment ตั้งแต่ตำแหน่ง 0 ถึง 10 (เอา 10 ตัวอักษรแรก)
            let firstTen = segment?.substring(0, 10);

            // ตัดสตริง segment ตั้งแต่ตำแหน่ง 10 ถึง 20 (เอา 10 ตัวอักษรถัดไป)
            // let lastTen = segment.substring(10, 20);

            let lastTen = segment?.length > 10 ? segment?.substring(10, 20) : segment?.substring(10);
            realTel.push(firstTen);
            if (lastTen?.length > 0) {
                realTel.push(lastTen);
            }
        }
    };

    const handleClose = () => {
        onCancel(false);
    }
    const handleSubmit = () => {
        const NewData = {
            ...newData?.custmast[0],
            note: "test NOTE"
        }
        console.log("NewData", NewData)

    }

    const renderTel = ({ item, index }) => { //item คือตัวแปรที่เอาไว้ดึงค่าของข้อมูลออกมาแสดง โดยใช้ item.ชื่อฟิวล์
        // console.log("item", item)
        return (
            <>
                <div style={{ marginBottom: 3 }}>
                    <Form.Item>
                        <Space>
                            <span>เบอร์ที่ {index + 1} : </span> <b>{item}</b>
                            <Button
                                type='primary'
                                style={{ backgroundColor: "green" }}
                                icon={<PhoneOutlined />}
                                onClick={() => handleCall(item)}
                            >โทรออก</Button>
                        </Space>
                    </Form.Item>
                </div>

            </>
        )
    }


    const Headered = (item, index) => {
        // console.log("item", item)
        const items = item?.item
        return (
            <>
                <Row justify={'center'} className='main'>
                    <Divider orientation={'center'}><b><u>การตลาด</u></b></Divider>
                    <aside style={{ width: '100%' }}>
                        <div style={{ marginBottom: 3 }}>
                            <span>ผู้เสนอ :  <Tag color="geekblue"><b>{items?.SALCOD}</b></Tag></span>
                        </div>
                        <div style={{ marginBottom: 3 }}>
                            <span>ผู้ตรวจสอบ :  <Tag color="geekblue"><b>{items?.SALCOD}</b></Tag></span>
                        </div>

                    </aside>
                </Row>

            </>
        )
    }
    const HeaderedLand = (item, index) => {
        // console.log("item", item)
        const items = item?.item
        return (
            <>
                <Row justify={'center'} className='main'>
                    <Divider orientation={'center'}><b><u>การตลาด</u></b></Divider>
                    <aside style={{ width: '100%' }}>
                        <div style={{ marginBottom: 3 }}>
                            <span>ผู้เสนอ :  <Tag color="geekblue"><b>{items?.SALCOD}</b></Tag></span>
                        </div>
                        <div style={{ marginBottom: 3 }}>
                            <span>ผู้ตรวจสอบ :  <Tag color="geekblue"><b>{items?.SALCOD}</b></Tag></span>
                        </div>

                    </aside>
                </Row>

            </>
        )
    }
    const Description = (item, index) => {
        // console.log("item", item)
        const items = item?.item
        return (
            <>
                <Row justify={'center'} className='main'>
                    <Divider orientation={'center'}><b><u>รายละเอียด</u></b></Divider>
                    <aside style={{ width: '100%' }}>
                        <div style={{ marginBottom: 3 }}>
                            <span>เลขบัตรประชาชน : </span> <b>{items?.CUSCOD}</b>
                        </div>
                        <div style={{ marginBottom: 3 }}>
                            <span>ชื่อ-สกุล : </span> <b>{items?.SNAM}{items?.NAME1} {items?.NAME2}</b>
                        </div>
                        <div style={{ marginBottom: 3 }}>
                            <span>ชื่อเล่น : </span> <b>{items?.NIKNAME}</b>
                        </div>
                        <div style={{ marginBottom: 3 }}>
                            <span>เบอร์ : </span> <b>{items?.TELP}</b>
                        </div>

                    </aside>
                </Row>

            </>
        )
    }
    const DescriptionLand = (item, index) => {
        console.log("item", item)
        const items = item?.item
        return (
            <>
                <Row justify={'center'} className='main'>
                    <Divider orientation={'center'}><b><u>รายละเอียด</u></b></Divider>
                    <aside style={{ width: '100%' }}>
                        <div style={{ marginBottom: 3 }}>
                            <span>เลขบัตรประชาชน : </span> <b>{items?.CUSCOD}</b>
                        </div>
                        <div style={{ marginBottom: 3 }}>
                            <span>ชื่อ-สกุล : </span> <b>{items?.SNAM}{items?.NAME1} {items?.NAME2}</b>
                        </div>
                        <div style={{ marginBottom: 3 }}>
                            <span>ชื่อเล่น : </span> <b>{items?.NIKNAME}</b>
                        </div>
                        <div style={{ marginBottom: 3 }}>
                            <span>เบอร์ : </span> <b>{items?.TELP}</b>
                        </div>

                    </aside>
                </Row>

            </>
        )
    }
    const DescriptionProduct = (item, index) => {
        // console.log("item", item)
        const items = item?.item
        return (
            <>
                <Row justify={'center'} className='main'>
                    <Divider orientation={'center'}><b><u>รายละเอียดสินค้า</u></b></Divider>
                    <aside style={{ width: '100%' }}>
                        <div style={{ marginBottom: 3 }}>
                            <span>ยี่ห้อ : </span> <b>{items?.TYPE}</b>
                        </div>
                        <div style={{ marginBottom: 3 }}>
                            <span>รุ่น : </span> <b>{items?.MODEL}</b>
                        </div>
                        <div style={{ marginBottom: 3 }}>
                            <span>แบบ : </span> <b>{items?.BAAB}</b>
                        </div>
                        <div style={{ marginBottom: 3 }}>
                            <span>เลขคัสซี : </span> <b>{items?.CUSCOD}</b>
                        </div>
                        <div style={{ marginBottom: 3 }}>
                            <span>เลขเครื่อง : </span> <b>{items?.ENGNO}</b>
                        </div>
                        <div style={{ marginBottom: 3 }}>
                            <span>ปี : </span> <b>{items?.MANUYR}</b>
                        </div>
                        <div style={{ marginBottom: 3 }}>
                            <span>ทะเบียน : </span> <b>{items?.REGNO}</b>
                        </div>
                        <div style={{ marginBottom: 3 }}>
                            <span>สี : </span> <b>{items?.COLOR}</b>
                        </div>

                    </aside>
                </Row>

            </>
        )
    }
    const DescriptionProductLand = (item, index) => {
        console.log("item", item)
        const items = item?.item
        return (
            <>
                <Row justify={'center'} className='main'>
                    <Divider orientation={'center'}><b><u>รายละเอียดสินค้า</u></b></Divider>
                    <aside style={{ width: '100%' }}>
                        <div style={{ marginBottom: 3 }}>
                            <span>ประเภทเอกสาร : </span> <b>{items?.productTypeLand === 'โฉนด' ? 'นส4.' : 'นส3.'}</b>
                        </div>
                        <div style={{ marginBottom: 3 }}>
                            <span>เลขโฉนดที่ดิน : </span> <b>{items?.numberLand}</b>
                        </div>
                        <div style={{ marginBottom: 3 }}>
                            <span>เลขที่ดินหรือระวาง : </span> <b>{items?.numberLandlawang}</b>
                        </div>
                        <div style={{ marginBottom: 3 }}>
                            <span>จังหวัด : </span> <b>{items?.provinces}</b>
                        </div>
                        <div style={{ marginBottom: 3 }}>
                            <span>อำเภอ : </span> <b>{items?.district}</b>
                        </div>
                    </aside>
                </Row>

            </>
        )
    }

    return (
        <Card style={{ width: "800px" }}>
            <Row justify={'center'} className='main'>
                <Col span={12} style={{ textAlign: 'left' }}>
                    <b>
                        <div style={{ marginBottom: 0 }}>
                            <span>เลขสัญญา : </span>{<b style={{ fontSize: '20px', lineHeight: '32px' }}>{data?.CONTNO}</b>}
                        </div>
                    </b>

                </Col>
                <Col span={12} style={{ textAlign: 'center' }}>

                    <b>
                        <div style={{ marginBottom: 0 }}>
                            <span>เกรด : </span>{<b style={{ fontSize: '20px', lineHeight: '32px' }}>{data?.GRDCOD}</b>}
                        </div>
                    </b>
                </Col>
            </Row>



            {data?.PRODUCTS === "car" ?
                <>
                    <Col orientation={'center'}>
                        {
                            newData?.custmast?.map((item, index) => {
                                return Headered({ item, index })

                            })
                        }
                    </Col>
                    <Col orientation={'center'}>
                        {
                            newData?.custmast?.map((item, index) => {
                                return Description({ item, index })

                            })
                        }
                    </Col>
                    <Col orientation={'center'}>
                        {
                            newData?.custmast?.map((item, index) => {
                                return DescriptionProduct({ item, index })

                            })
                        }
                    </Col>
                </>
                :
                <>
                    <Col orientation={'center'}>
                        {
                            newData?.Data1?.map((item, index) => {
                                return HeaderedLand({ item, index })

                            })
                        }
                    </Col>
                    <Col orientation={'center'}>
                        {
                            newData?.Data1?.map((item, index) => {
                                return DescriptionLand({ item, index })

                            })
                        }
                    </Col>
                    <Col orientation={'center'}>
                        {
                            newData?.Data1?.map((item, index) => {
                                return DescriptionProductLand({ item, index })

                            })
                        }
                    </Col>
                </>
            }
            <Col justify={'center'}>
                <Divider orientation={'center'}><b><u>ช่องทางการติดต่อ</u></b></Divider>
                <aside style={{ width: '90%' }}>
                    {
                        realTel.map((item, index) => {
                            return renderTel({ item, index })
                        })
                    }
                    {/* <div style={{ marginBottom: 3 }}>
                        <span>หมายเหตุ  : </span>
                        <TextArea rows={4} placeholder="กรอกหมายเหตุ เพื่อแจ้งธุรการ" maxLength={500} />
                    </div> */}
                </aside>
            </Col>
            <Divider />

            <Row justify={'center'}>
                <Space>
                    <Button type='primary' danger onClick={handleClose}>ปิด</Button>

                    {/* <Button
                        type='primary'
                        green
                        onClick={handleSubmit}
                    >บันทึก
                    </Button> */}
                </Space>
            </Row>
        </Card >
    )
}

export default Tel