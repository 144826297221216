import React from 'react';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Divider, Row, Col, Card, Modal, Space } from 'antd';

import { useDispatch, useSelector } from 'react-redux';
import { addAddress } from '../../../../../redux/Address';
import AddAddress from './Modals/Address/AddAddress';
import EditAddress from './Modals/Address/EditAddress';
import { deleteaddress } from '../../../../file_mid/all_api';

function GAddress({ close, page, setPage, cha, next, prev }) {

    const dispatch = useDispatch()
    const Addresss = useSelector(state => state.Addresss)

    // const [dataPost, setDataPost] = useState(customers.data); //มาจากรีดัค
    const [preAddress, setPreAddress] = useState(Addresss.data); //มาจากรีดัค
    // const [form] = Form.useForm();
    const [dataedit, setDataEdit] = useState([]); //เซตสำหรับแก้ไขข้อมูล 
    const [index1, setIndex] = useState(); //set index send to page edit
    const [dataAddress, setDataAddress] = useState([]); // เก็บ address
    const { confirm } = Modal

    //checkOpen Button
    const [modaleditdata, setModalEditData] = useState(false);
    const [modaladddata, setModalAddData] = useState(false);

    // console.log("preAddress", preAddress)

    useEffect(() => {
        if (preAddress !== undefined) {
            setDataAddress(Addresss.data)
        }
    }, [])

    const deleteData = async (value) => {
        const test2 = value
        await axios.delete(deleteaddress, { data: test2 })
            .then((res) => {
                if (res.status === 200) {
                }
                else {
                    // error()
                }
            }).catch((err) => console.log(err))
    }

    const showConfirm = (value, index) => {
        confirm({
            title: 'คุณต้องการที่จะลบข้อมูลหรือไม่...?',
            content: 'กด OK เพื่อยืนยันการลบ',
            onOk() {
                const deletearray = [...dataAddress]
                deletearray.splice(index, 1)
                setDataAddress(deletearray)
                deleteData(value)
            },
            onCancel() {
                // message.error('ยกเลิกการลบข้อมูล')
            },
        });
    };

    const onChangeBack = () => {
        if (dataAddress.length === 0 || dataAddress.length === undefined) {
            setPage(page - 1)
            prev()
        } else {
            if (dataAddress !== undefined) {
                dispatch(addAddress(dataAddress))
            }
            setPage(page - 1)
            prev()
        }
    }

    const onChangeGo = () => {
        setPage(page + 1)
        next()
    }

    const handleSubmit = () => {

        if (dataAddress.length === 0 || dataAddress.length === undefined) {
            cha("top")
        } else {
            if (dataAddress !== undefined) {
                dispatch(addAddress(dataAddress))
            }
            onChangeGo()
        }
    }

    const handleCancel = () => {
        close(false)
    }

    //ดึงข้อมูลมากจาก Modal addData
    const fucnAdd = (value) => {
        if (dataAddress.length === 0) {
            setDataAddress(pre => [...pre, { ...value.address }]);
        } else {
            setDataAddress(pre => [...pre, { ...value.address }]);
        }
    }
    // แก้ไขข้อมูลจากหน้า AddressEditData
    const fucnEdit = ({ getData, getIndex }) => {
        let Theedit = [...dataAddress]
        Theedit[getIndex] = getData
        //ข้อมูลใหม่แทนที่ข้อมูลเดิมในช่อง Input
        setDataAddress(Theedit);
    }

    const renderItem = ({ item, index }) => { //item คือตัวแปรที่เอาไว้ดึงค่าของข้อมูลออกมาแสดง โดยใช้ item.ชื่อฟิวล์

        // index ตอนนี้เป็นข้อมูลจาก Object
        const handleEdit = (dataedit, index1) => {
            setIndex(index1) //เซตค่า index ให้กับชุด ข้อมูล
            setDataEdit({ dataedit, index1 }) //ส่งข้อมูลด index ไปหน้าแก้ไข
            setModalEditData(true);
        }

        return (
            // <Form>
            <Row style={{ margin: '3px' }} justify={'center'} >
                <Card
                    key={index} // index เป็นตัวกำหนดคีย์
                    title={
                        (item.addressStatus === true || item.AddressStatus === 1 || item.AddressStatus === true) ? <u>ใช้งานที่อยู่</u> : <u>ไม่ได้ใช้งาน</u>
                    }
                    style={{ width: '300px' }} >
                    <Col span={24}>
                        {/* <Col span={24} style={{ textAlign: 'left' }}> */}
                        <Form.Item style={{ marginBottom: 0 }} label='เลขทะเบียนบ้าน' >
                            <b>{item.houseRegistrationNumber}</b>
                        </Form.Item>
                        <Form.Item style={{ marginBottom: 0 }} label='บ้านเลขที่'>
                            <b>{item.houseNumber}</b>
                        </Form.Item>
                        <Form.Item style={{ marginBottom: 0 }} label='ซอย'>
                            <b>{item.soi}</b>
                        </Form.Item>
                        <Form.Item style={{ marginBottom: 0 }} label='ถนน'>
                            <b>{item.road}</b>
                        </Form.Item>
                        <Form.Item style={{ marginBottom: 0 }} label='หมู่บ้าน'>
                            <b>{item.village}</b>
                        </Form.Item>
                        <Form.Item style={{ marginBottom: 0 }} label='ตำบล'>
                            <b>{item.subdistrict}</b>
                        </Form.Item>
                        <Form.Item style={{ marginBottom: 0 }} label='อำเภอ'>
                            <b>{item.district}</b>
                        </Form.Item>
                        <Form.Item style={{ marginBottom: 0 }} label='จังหวัด'>
                            <b>{item.province}</b>
                        </Form.Item>
                        <Form.Item style={{ marginBottom: 0 }} label='รหัสไปรษณีย์'>
                            <b>{item.zipCode}</b>
                        </Form.Item>
                        <Divider></Divider>
                        <Form.Item style={{ marginBottom: 0 }} label='เป็นที่อยู่'>
                            <b>
                                {(item.addressOnIdcard === true || item.AddressOnIdcard === 1 || item.AddressOnIdcard === true) && "ตามบัตรประชาชน "}
                                {(item.houseRegistration === true || item.HouseRegistration === 1 || item.HouseRegistration === true) && "ตามทะเบียนบ้าน "}
                                {(item.workAddress === true || item.WorkAddress === 1 || item.WorkAddress === true) && "ตามสถานที่ทำงาน "}
                                {(item.otherAddress === true || item.OtherAddress === 1 || item.OtherAddress === true) && "อื่นๆ "}
                                {(item.currentAddress === true || item.CurrentAddress === 1 || item.CurrentAddress === true) && "ที่อยู่ปัจจุบัน "}
                                {(item.sendDocuments === true || item.SendDocuments === 1 || item.SendDocuments === true) && "ที่อยู่ส่งเอกสาร "}
                            </b>
                        </Form.Item>
                        <Divider></Divider>
                        <Row justify={'center'} gutter={{ sx: 8, sm: 16, md: 24, lg: 32 }}>
                            <Form.Item>
                                {/* ปุ่มลบข้อมูล */}
                                <Button
                                    style={{ margin: '10px', background: '#f5222d', color: 'white', marginBottom: 0 }}
                                    icon={<DeleteOutlined />}
                                    // onClick={() => deleteData(index)}
                                    onClick={() => showConfirm(dataAddress[index], index)}
                                >ลบ</Button>
                            </Form.Item>
                            <Form.Item>
                                {/* ปุ่มแก้ไขข้อมูล */}
                                <Button
                                    style={{ margin: '10px', background: '#bfbfbf', color: '#ffffff', marginBottom: 0 }}
                                    type='submit'
                                    onClick={() => { handleEdit(dataAddress[index], index); }}
                                >แก้ไข</Button>
                            </Form.Item>
                        </Row>
                    </Col>
                </Card>
            </Row>
            // </Form>
        )
    }
    return (
        <>
            <Card style={{ color: '#4096ff' }}>
                {/* <Form> */}
                <Row>
                    <Col className='gutter-row' span={24}>
                        <Row>
                            <Col span={24} style={{ fontSize: '30px', textAlign: 'center' }} >รายละเอียดที่อยู่</Col>
                        </Row>
                        <Divider />
                        <Row gutter={32}>
                            <Col span={24} style={{ textAlign: 'right' }}>
                                {/* ปุ่มเพิ่มข้อมูล */}
                                <Button type='primary' onClick={() => { setModalAddData(true); }}> <PlusOutlined />เพิ่มข้อมูลที่อยู่</Button>
                            </Col>
                        </Row>
                        <Row justify={'center'}>
                            {
                                dataAddress.map((item, index) => {
                                    return renderItem({ item, index, key: `{item.identificationId} - ${index}` })
                                })
                            }
                        </Row>
                    </Col>
                </Row>
                <Divider />
                <div style={{ textAlign: "center" }} >
                    <Space>
                        <Button type="primary" style={{ background: '#bfbfbf', color: '#000000', }} onClick={onChangeBack}>ย้อนกลับ</Button>
                        <Button type="primary" onClick={handleCancel} style={{ background: "red" }}>ยกเลิก</Button>
                        <Button type="primary" htmlType="submit" onClick={handleSubmit}>ต่อไป</Button>
                    </Space>
                </div>
                {/* </Form> */}
            </Card>
            {
                //เพื่มข้อมูลในการ์ด
                modaladddata ?
                    <AddAddress open={modaladddata} close={setModalAddData} fucnAdd={fucnAdd} />
                    : null
            }
            {
                //แก้ไขข้อมูลในการ์ด
                modaleditdata ?
                    <EditAddress
                        open={modaleditdata}
                        close={setModalEditData}
                        shootdata={dataedit}
                        fucnEdit={fucnEdit}
                        dataindex={index1}
                    />
                    : null
            }
        </>
    )
};
export default GAddress;