import { Col, Form, Modal, Row, Button, Card, Checkbox, Space } from 'antd'
import React, { useRef, useState } from 'react'
import { useReactToPrint } from "react-to-print";
import { PrinterOutlined } from '@ant-design/icons'
import dayjs from 'dayjs';
// import { useSelector } from 'react-redux'
//css
// import './../add_customer/css/modaloffer.css'
import '../check_info_ad/css.css'
import logo from './../add_customer/image/logo.jpg'
import { showNoteModalPreemAD, showNoteModalPreemPG } from '../file_mid/condition';

function ModalPreemptionCertificateNEW({ open, close, data, notes, typeLoan, approval, guarantor, dataCarLand, datare, dataLoan, dataOldloan, dataAP, monthlyPaymentCAL, installmentWithInterestCAL, mixBroker }) {

    // console.log("data", data)
    // console.log("notes", notes)
    // console.log("typeLoan", typeLoan)
    // console.log("approval", approval)
    // console.log("guarantor", guarantor)
    // console.log("dataCarLand", dataCarLand)
    // console.log("dataLoan", dataLoan)
    // console.log("dataOldloan", dataOldloan)
    // console.log("dataAP", dataAP)
    // console.log("monthlyPaymentCAL", monthlyPaymentCAL)
    // console.log("InstallmentWithInterestCAL", installmentWithInterestCAL)
    // console.log("mixBroker", mixBroker)
    // console.log("datare", datare)


    const [form] = Form.useForm()
    const conponentPDF = useRef();
    const [dates] = useState(dayjs().add(543, 'year').format("DD-MM-YYYY"))

    // var note = notes?.length;
    // var lastIndex = note - 1;

    const generatePDF = useReactToPrint({
        content: () => conponentPDF.current,
        documentTitle: "ใบจอง",
    });

    const handleCancel = () => {
        close(false);
    };

    const currencyFormatOne = (amount) => {
        return Number(amount)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, '$&,')
    }

    const renderGuarantor = ({ item, index }) => { //item คือตัวแปรที่เอาไว้ดึงค่าของข้อมูลออกมาแสดง โดยใช้ item.ชื่อฟิวล์
        return (
            <>
                <Row>
                    <Col span={15} className='gutter-row' style={{ textAlign: 'left' }}>
                        <Form.Item style={{ margin: 0 }}>
                            <span>ชื่อผู้ค้ำ {index + 1 + "."} </span>
                            <b style={{ marginLeft: '25px' }}>{item.snam + ' ' + item.firstname + ' ' + item.lastname}</b>
                        </Form.Item>
                    </Col>
                    <Col span={9} className='gutter-row' style={{ textAlign: 'left' }}>
                        <Form.Item style={{ margin: 0 }}>
                            <span>เลขที่บัตร </span>
                            <b style={{ marginLeft: '10px' }}>{item.identificationId}</b>
                        </Form.Item>
                    </Col>
                </Row>
            </>
        )
    }

    return (
        <>
            <Modal
                open={open}
                onCancel={handleCancel}
                width={"1000px"}
                footer={[
                    <Button
                        style={{ margin: '5px', background: '#cf1322', color: '#ffffff' }}
                        icon={<PrinterOutlined />}
                        onClick={handleCancel}>
                        ยกเลิก
                    </Button>,
                    <Button
                        style={{ margin: '5px', background: '#597ef7', color: '#ffffff' }}
                        icon={<PrinterOutlined />}
                        onClick={generatePDF}>
                        พิมพ์
                    </Button>,
                ]}
            >
                <Row ref={conponentPDF} gutter={32} justify={'center'} style={{ marginTop: 13 }}>
                    <Form
                        name="basic"
                        labelCol={{
                            span: 12,
                        }}
                        wrapperCol={{
                            span: 24,
                        }}
                        style={{
                            width: '90%',
                        }}
                        initialValues={{
                            remember: true,
                        }}
                        form={form}
                        autoComplete="off"
                    >
                        {/* ส่วน Header */}
                        <Row justify={'center'} gutter={32}>
                            <Col className='gutter-row' span={4}>เล่มที่</Col>
                            <Col className='gutter-row' span={5} style={{ textAlign: 'right' }}>
                                <img src={logo} alt="logo" style={{ width: '50px' }} />
                            </Col>
                            <Col className='gutter-row' span={9} style={{ textAlign: 'center' }}>
                                <h2 style={{ margin: 0, textAlign: 'left' }}><b>บริษัทวัน ลิสซิ่ง จำกัด</b></h2>
                                <p style={{ margin: 0, textAlign: 'left', color: '#061178' }}> <b>ONE LEASING CO.,LTD.</b></p>
                            </Col>
                            <Col className='gutter-row' span={4}>เลขที่</Col>
                        </Row>
                        {/* ส่วน Header */}

                        <Row gutter={32} justify={'center'} >
                            <Card style={{ width: '100%', margin: 0 }}>
                                <Col span={24} className='gutter-row' >

                                    {/* ส่วนบน */}
                                    <Row gutter={32} justify={'center'} style={{ margin: 0 }}>
                                        <Col span={16} className='gutter-row' style={{ textAlign: 'left' }}>
                                            <Form.Item style={{ margin: 3 }}>
                                                เลขที่สัญญา
                                            </Form.Item>
                                        </Col>
                                        <Col span={8} className='gutter-row' style={{ textAlign: 'left' }}>
                                            <Form.Item style={{ margin: 3 }}>
                                                <span>วันที่  </span>
                                                <b> {dates}</b>
                                            </Form.Item>
                                        </Col>
                                        <Col span={15} className='gutter-row' style={{ textAlign: 'left' }}>
                                            <Form.Item style={{ margin: 3 }}>
                                                <span>
                                                    {data?.nationalId === 'นิติบุคคล' ? 'ชื่อบริษัท ' : 'ชื่อ-สกุลผู้เช่าซื้อ'}
                                                </span>
                                                <b> {data?.snam + ' ' + data?.firstname + ' ' + data?.lastname}</b>
                                            </Form.Item>
                                        </Col>
                                        <Col span={9} className='gutter-row' style={{ textAlign: 'left' }}>
                                            <Form.Item style={{ margin: 3 }}>
                                                <span>
                                                    {data?.nationalId === 'นิติบุคคล' ? 'เลขประจำตัวผู้เสียภาษี' : 'เลขที่บัตร'}
                                                </span>
                                                <b style={{ marginLeft: '10px' }}>{data?.identificationId}</b>
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            {
                                                guarantor.map((item, index) => {
                                                    return renderGuarantor({ item, index })
                                                })
                                            }
                                        </Col>
                                    </Row>
                                    {/* ส่วนบน */}

                                    {/* ส่วนรายละเอียด รถ/ที่ดิน */}
                                    <Row gutter={32} style={{ margin: 0 }}>
                                        {
                                            typeLoan === "car" ?
                                                <>
                                                    <div className='center' style={{ width: '100%' }}>
                                                        <Row gutter={32} justify={'center'}>
                                                            <Col span={8} className='gutter-row'>
                                                                <Form.Item style={{ margin: 3 }}>
                                                                    <span>ยี่ห้อ :</span>
                                                                    <b style={{ marginLeft: '10px' }}>{dataCarLand.carBrand}</b>
                                                                </Form.Item>
                                                                <Form.Item style={{ margin: 3 }}>
                                                                    <span>สี :</span>
                                                                    <b style={{ marginLeft: '10px' }}>{dataCarLand.carColor}</b>
                                                                </Form.Item>
                                                                <Form.Item style={{ margin: 3 }}>
                                                                    <span>เลขคัสซี :</span>
                                                                    <b style={{ marginLeft: '10px' }}>{dataCarLand.carChassisNumber}</b>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={8} className='gutter-row'>
                                                                <Form.Item style={{ margin: 3 }}>
                                                                    <span>รุ่น :</span>
                                                                    <b style={{ marginLeft: '10px' }}>{dataCarLand.carModel}</b>
                                                                </Form.Item>
                                                                <Form.Item style={{ margin: 3 }}>
                                                                    <span>เลขทะเบียน :</span>
                                                                    <b style={{ marginLeft: '10px' }}>{dataCarLand.carPlateNumber}</b>
                                                                </Form.Item>
                                                                <Form.Item style={{ margin: 3 }}>
                                                                    <span>เลขเครื่อง :</span>
                                                                    <b style={{ marginLeft: '10px' }}>{dataCarLand.carEngineNumber}</b>
                                                                </Form.Item>

                                                            </Col>
                                                            <Col span={8} className='gutter-row'>
                                                                <Form.Item style={{ margin: 3 }}>
                                                                    <span>ประเภท :</span>
                                                                    <b style={{ marginLeft: '10px' }}>{dataCarLand.carBaab}</b>
                                                                </Form.Item>
                                                                <Form.Item style={{ margin: 3 }}>
                                                                    <span>จังหวัด :</span>
                                                                    <b style={{ marginLeft: '10px' }}>{dataCarLand.carProvince}</b>
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <div className='center' style={{ width: '100%' }}>
                                                        <Row gutter={32} justify={'center'}>
                                                            <Col span={7} className='gutter-row'>
                                                                <Form.Item style={{ margin: 3 }}>
                                                                    <span>เลขโฉนดที่ดิน :</span>
                                                                    <b style={{ marginLeft: '10px' }}>{dataCarLand.numberLand}</b>
                                                                </Form.Item>
                                                                <Form.Item style={{ margin: 3 }}>
                                                                    <span>อำเภอ :</span>
                                                                    <b style={{ marginLeft: '10px' }}>{dataCarLand.district}</b>
                                                                </Form.Item>
                                                                <Form.Item style={{ margin: 3 }}>
                                                                    <span>พื้นที่ตารางวา :</span>
                                                                    <b style={{ marginLeft: '10px' }}>{dataCarLand.squareWaArea}</b>
                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                        บาท
                                                                    </span>
                                                                </Form.Item>

                                                            </Col>
                                                            <Col span={10} className='gutter-row'>
                                                                <Form.Item style={{ margin: 3 }}>
                                                                    <span>เลขที่ดินหรือระวาง :</span>
                                                                    <b style={{ marginLeft: '10px' }}>{dataCarLand.numberLandlawang}</b>
                                                                </Form.Item>
                                                                <Form.Item style={{ margin: 3 }}>
                                                                    <span>พื้นที่ไร่ :</span>
                                                                    <b style={{ marginLeft: '10px' }}>{dataCarLand.rai}</b>
                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                        ไร่
                                                                    </span>
                                                                </Form.Item>
                                                                <Form.Item style={{ margin: 3 }}>
                                                                    <span>ราคาประเมินที่ดิน :</span>
                                                                    <b style={{ marginLeft: '10px' }}>{dataCarLand.landPrice}</b>
                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                        บาท / ตารางวา
                                                                    </span>
                                                                </Form.Item>

                                                            </Col>
                                                            <Col span={7} className='gutter-row'>
                                                                <Form.Item style={{ margin: 3 }}>
                                                                    <span>จังหวัด :</span>
                                                                    <b style={{ marginLeft: '10px' }}>{dataCarLand.provinces}</b>
                                                                </Form.Item>
                                                                <Form.Item style={{ margin: 3 }}>
                                                                    <span>พื้นที่งาน :</span>
                                                                    <b style={{ marginLeft: '10px' }}>{dataCarLand.workArea}</b>
                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                        งาน
                                                                    </span>
                                                                </Form.Item>
                                                                <Form.Item style={{ margin: 3 }}>
                                                                    <span>ราคารวม :</span>
                                                                    <b style={{ marginLeft: '10px' }}>{currencyFormatOne(dataCarLand.resultLandPrice)}</b>
                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                        บาท
                                                                    </span>
                                                                </Form.Item>

                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </>
                                        }
                                    </Row>
                                    {/* ส่วนรายละเอียด รถ/ที่ดิน */}

                                    {/* ช่องเช็คบล็อก */}
                                    <Row gutter={32} justify={'center'} style={{ marginTop: 15, marginBottom: 15, width: '900px' }}>
                                        {
                                            typeLoan === "car" ?
                                                <>
                                                    <Col style={{ textAlign: 'center' }}>
                                                        <Checkbox
                                                            checked={dataLoan?.productLoanType === "ย้ายไฟแนนซ์" || dataLoan?.productLoanType === "Pre-Aaprove-ย้ายไฟแนนซ์" ? true : false}
                                                        >ย้ายไฟแนนซ์
                                                        </Checkbox>
                                                        <Checkbox
                                                            checked={dataLoan?.productLoanType === "ถือเล่มมา" || dataLoan?.productLoanType === "Pre-Aaprove-ถือเล่มมา" ? true : false}
                                                        >ถือเล่มมา
                                                        </Checkbox>
                                                        <Checkbox
                                                            checked={dataLoan?.productLoanType === "ปรับโครงสร้าง" ? true : false}
                                                        >ปรับโครงสร้าง
                                                        </Checkbox>
                                                        <Checkbox
                                                            checked={dataLoan?.productLoanType === "ซื้อ-ขาย" || dataLoan?.productLoanType === "Pre-Aaprove-ซื้อ-ขาย" ? true : false}
                                                        >ซื้อ-ขาย
                                                        </Checkbox>
                                                        <Checkbox
                                                            checked={dataLoan?.checkGua === "ค้ำ-ไม่โอน" ? true : false}
                                                        >ค้ำ-ไม่โอน
                                                        </Checkbox>
                                                        <Checkbox
                                                            checked={dataLoan?.checkGua === "ค้ำ-โอน" ? true : false}
                                                        >ค้ำ-โอน
                                                        </Checkbox>
                                                        <Checkbox
                                                            checked={dataLoan?.checkGua === "ไม่ค้ำ-ไม่โอน" ? true : false}
                                                        >ไม่ค้ำ-ไม่โอน
                                                        </Checkbox>
                                                        <Checkbox
                                                            checked={dataLoan?.checkGua === "ไม่ค้ำ-โอน" ? true : false}
                                                        >ไม่ค้ำ-โอน
                                                        </Checkbox>
                                                    </Col>
                                                </>
                                                :
                                                <>
                                                    <Col style={{ textAlign: 'center' }}>
                                                        <Checkbox
                                                            checked={dataLoan?.productLoanLandType === "ย้ายไฟแนนซ์" || dataLoan?.productLoanLandType === "Pre-Aaprove-ย้ายไฟแนนซ์" ? true : false}
                                                        >ย้ายไฟแนนซ์
                                                        </Checkbox>
                                                        <Checkbox
                                                            checked={dataLoan?.productLoanLandType === "ถือโฉนด(ที่ดิน)" || dataLoan?.productLoanLandType === "Pre-Aaprove ถือโฉนดที่ดิน" ? true : false}
                                                        >ถือโฉนด
                                                        </Checkbox>
                                                        <Checkbox
                                                            checked={dataLoan?.productLoanLandType === "ปรับโครงสร้าง" ? true : false}
                                                        >ปรับโครงสร้าง
                                                        </Checkbox>
                                                        <Checkbox
                                                            checked={dataLoan?.productLoanLandType === "ซื้อ-ขาย" || dataLoan?.productLoanLandType === "Pre-Aaprove-ซื้อ-ขาย" ? true : false}
                                                        >ซื้อ-ขาย
                                                        </Checkbox>
                                                        <Checkbox
                                                            checked={dataLoan?.checkGua === "ค้ำ-ไม่โอน" ? true : false}
                                                        >ค้ำ-ไม่โอน
                                                        </Checkbox>
                                                        <Checkbox
                                                            checked={dataLoan?.checkGua === "ค้ำ-โอน" ? true : false}
                                                        >ค้ำ-โอน
                                                        </Checkbox>
                                                        <Checkbox
                                                            checked={dataLoan?.checkGua === "ไม่ค้ำ-ไม่โอน" ? true : false}
                                                        >ไม่ค้ำ-ไม่โอน
                                                        </Checkbox>
                                                        <Checkbox
                                                            checked={dataLoan?.checkGua === "ไม่ค้ำ-โอน" ? true : false}
                                                        >ไม่ค้ำ-โอน
                                                        </Checkbox>
                                                    </Col>
                                                </>
                                        }
                                    </Row>
                                    {/* ช่องเช็คบล็อก */}

                                    <Row gutter={32} style={{ marginTop: 5, marginLeft: 5 }}>
                                        <Col span={24} className='gutter-row'>
                                            <b style={{ fontSize: '15px' }}><u>ค่าใช้จ่ายและเงื่อนไขการผ่อนชำระ
                                                {
                                                    typeLoan === "car" ?
                                                        <>
                                                            {dataLoan?.productType === "เช่าซื้อ1" ?
                                                                <>
                                                                    (เช่าซื้อ)
                                                                </>
                                                                :
                                                                <>
                                                                    {dataAP?.loanId === 0 ?
                                                                        <>
                                                                            ({dataLoan?.productType})
                                                                        </>
                                                                        :
                                                                        <>
                                                                            ({dataAP?.apProductType})
                                                                        </>

                                                                    }
                                                                </>
                                                            }
                                                        </>
                                                        :
                                                        <>
                                                            ({dataCarLand?.approval?.note})
                                                        </>
                                                }
                                            </u></b>
                                        </Col>
                                    </Row>
                                    <Row gutter={32} style={{ margin: 3, marginTop: 10, marginBottom: 15, }}>
                                        {
                                            typeLoan === "car" ?
                                                <>
                                                    {/* Car */}
                                                    <Col span={12} className='gutter-row'>
                                                        {
                                                            dataLoan?.productLoanType === 'ย้ายไฟแนนซ์' ||
                                                                dataLoan?.productLoanType === 'Pre-Aaprove-ย้ายไฟแนนซ์' ?
                                                                <>
                                                                    {
                                                                        data?.carLoanDetailsRe?.productLoanType === "Pre-Aaprove-เปลี่ยนสัญญา" ||
                                                                            data?.carLoanDetailsRe?.productLoanType === "Pre-Aaprove-รี+เปลี่ยนสัญญา" ||
                                                                            data?.carLoanDetailsRe?.productLoanType === "รี+เปลี่ยนสัญญา" ||
                                                                            data?.carLoanDetailsRe?.productLoanType === "เปลี่ยนสัญญา" ||
                                                                            data?.carLoanDetailsRe?.productLoanType === "Pre-Aaprove-ปรับโครงสร้าง" ||
                                                                            data?.carLoanDetailsRe?.productLoanType === "ปรับโครงสร้าง" ||
                                                                            data?.carLoanDetailsRe?.productLoanType === "Pre-Aaprove-รี" ||
                                                                            data?.carLoanDetailsRe?.productLoanType === "รี" ||
                                                                            data?.carLoanDetailsRe?.productLoanType === "Pre-Aaprove-รีโอน" ||
                                                                            data?.carLoanDetailsRe?.productLoanType === "รีโอน" ||
                                                                            data?.cars?.carLoanDetailsRe?.productLoanType === "Pre-Aaprove-เปลี่ยนสัญญา" ||
                                                                            data?.cars?.carLoanDetailsRe?.productLoanType === "Pre-Aaprove-รี+เปลี่ยนสัญญา" ||
                                                                            data?.cars?.carLoanDetailsRe?.productLoanType === "รี+เปลี่ยนสัญญา" ||
                                                                            data?.cars?.carLoanDetailsRe?.productLoanType === "เปลี่ยนสัญญา" ||
                                                                            data?.cars?.carLoanDetailsRe?.productLoanType === "Pre-Aaprove-ปรับโครงสร้าง" ||
                                                                            data?.cars?.carLoanDetailsRe?.productLoanType === "ปรับโครงสร้าง" ||
                                                                            data?.cars?.carLoanDetailsRe?.productLoanType === "Pre-Aaprove-รี" ||
                                                                            data?.cars?.carLoanDetailsRe?.productLoanType === "รี" ||
                                                                            data?.cars?.carLoanDetailsRe?.productLoanType === "Pre-Aaprove-รีโอน" ||
                                                                            data?.cars?.carLoanDetailsRe?.productLoanType === "รีโอน"
                                                                            ?
                                                                            <>
                                                                                <Form.Item style={{ margin: 3 }}>
                                                                                    ปิดไฟแนนซ์จาก
                                                                                    <p className='dotted wi2' style={{ display: 'inline-block' }}>
                                                                                        {data?.cars?.carLoanDetailsRe?.productLoanType ?
                                                                                            <>
                                                                                                <b style={{ marginLeft: '20px', }}>{data?.cars?.ISSUNO}</b>
                                                                                            </>
                                                                                            :
                                                                                            <>
                                                                                                <b style={{ marginLeft: '20px', }}>{data?.carLoanDetails?.cars?.ISSUNO}</b>
                                                                                            </>
                                                                                        }

                                                                                    </p>
                                                                                </Form.Item>
                                                                                <Form.Item style={{ margin: 3 }}>
                                                                                    ยอดปิด
                                                                                    <p className='dotted wi2' style={{ display: 'inline-block' }}>
                                                                                        {data?.cars?.carLoanDetailsRe?.productLoanType ?
                                                                                            <>
                                                                                                <b>{currencyFormatOne(data?.cars?.carLoanDetailsRe?.re1)}</b>
                                                                                            </>
                                                                                            :
                                                                                            <>
                                                                                                <b>{currencyFormatOne(data?.carLoanDetailsRe?.re1)}</b>
                                                                                            </>
                                                                                        }


                                                                                    </p>
                                                                                    <span>บาท</span>
                                                                                </Form.Item>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <Form.Item style={{ margin: 3 }}>
                                                                                    ปิดไฟแนนซ์จาก
                                                                                    <p className='dotted wi2' style={{ display: 'inline-block' }}>
                                                                                        <b style={{ marginLeft: '20px', }}>{dataOldloan?.issuno}</b>
                                                                                    </p>
                                                                                </Form.Item>
                                                                                <Form.Item style={{ margin: 3 }}>
                                                                                    ยอดปิด
                                                                                    <p className='dotted wi2' style={{ display: 'inline-block' }}>
                                                                                        <b>
                                                                                            {/* {currencyFormatOne(dataOldloan?.oldLoanAmount)} */}
                                                                                            {dataOldloan?.carId !== 0 ?
                                                                                                <>
                                                                                                    {currencyFormatOne(dataOldloan?.oldLoanAmount)}
                                                                                                </>
                                                                                                :
                                                                                                null
                                                                                            }
                                                                                        </b>
                                                                                    </p>
                                                                                    <span>บาท</span>
                                                                                </Form.Item>
                                                                            </>
                                                                    }
                                                                    <Form.Item style={{ margin: 3 }}>
                                                                        ค่าจัด-โอน............................................................................บาท
                                                                    </Form.Item>
                                                                    {
                                                                        mixBroker?.checkBroker ?
                                                                            <Form.Item style={{ margin: 3 }}>
                                                                                ค่าอนุมัติ/บริการ
                                                                                <p className='dotted wi4' style={{ display: 'inline-block' }}>
                                                                                    <b>{currencyFormatOne(mixBroker?.data627)}</b>
                                                                                </p>
                                                                                <span> บาท</span>
                                                                            </Form.Item>
                                                                            :
                                                                            <Form.Item style={{ margin: 3 }}>
                                                                                ค่าอนุมัติ/บริการ............................................................บาท
                                                                            </Form.Item>
                                                                    }
                                                                    <Form.Item style={{ margin: 3 }}>
                                                                        ค่าน้ำมัน...............................................................................บาท
                                                                    </Form.Item>
                                                                    <Form.Item style={{ margin: 3 }}>
                                                                        ค่าทางด่วนที่ดิน....................................................................บาท
                                                                    </Form.Item>
                                                                    <Form.Item style={{ margin: 3 }}>
                                                                        ค่าเลี่ยงประกัน......................................................................บาท
                                                                    </Form.Item>
                                                                    <Form.Item style={{ margin: 3 }}>
                                                                        ค่าแจ้งย้าย............................................................................บาท
                                                                    </Form.Item>
                                                                    <Form.Item style={{ margin: 3 }}>
                                                                        ค่าฝากตรวจ..........................................................................บาท
                                                                    </Form.Item>
                                                                    <Form.Item style={{ margin: 3 }}>
                                                                        ค่าภาษี+พรบ........................................................................บาท
                                                                    </Form.Item>
                                                                    <Form.Item style={{ margin: 3 }}>
                                                                        ยอดคงเหลือ.........................................................................บาท
                                                                    </Form.Item>

                                                                </>
                                                                :
                                                                <>
                                                                    {/* <Row gutter={32} style={{ margin: 3, marginTop: 10, marginBottom: 15, }}> */}
                                                                    {
                                                                        data?.carLoanDetailsRe?.productLoanType === "Pre-Aaprove-เปลี่ยนสัญญา" ||
                                                                            data?.carLoanDetailsRe?.productLoanType === "Pre-Aaprove-รี+เปลี่ยนสัญญา" ||
                                                                            data?.carLoanDetailsRe?.productLoanType === "รี+เปลี่ยนสัญญา" ||
                                                                            data?.carLoanDetailsRe?.productLoanType === "เปลี่ยนสัญญา" ||
                                                                            data?.carLoanDetailsRe?.productLoanType === "Pre-Aaprove-ปรับโครงสร้าง" ||
                                                                            data?.carLoanDetailsRe?.productLoanType === "ปรับโครงสร้าง" ||
                                                                            data?.carLoanDetailsRe?.productLoanType === "Pre-Aaprove-รี" ||
                                                                            data?.carLoanDetailsRe?.productLoanType === "รี" ||
                                                                            data?.carLoanDetailsRe?.productLoanType === "Pre-Aaprove-รีโอน" ||
                                                                            data?.carLoanDetailsRe?.productLoanType === "รีโอน" ||
                                                                            data?.cars?.carLoanDetailsRe?.productLoanType === "Pre-Aaprove-เปลี่ยนสัญญา" ||
                                                                            data?.cars?.carLoanDetailsRe?.productLoanType === "Pre-Aaprove-รี+เปลี่ยนสัญญา" ||
                                                                            data?.cars?.carLoanDetailsRe?.productLoanType === "รี+เปลี่ยนสัญญา" ||
                                                                            data?.cars?.carLoanDetailsRe?.productLoanType === "เปลี่ยนสัญญา" ||
                                                                            data?.cars?.carLoanDetailsRe?.productLoanType === "Pre-Aaprove-ปรับโครงสร้าง" ||
                                                                            data?.cars?.carLoanDetailsRe?.productLoanType === "ปรับโครงสร้าง" ||
                                                                            data?.cars?.carLoanDetailsRe?.productLoanType === "Pre-Aaprove-รี" ||
                                                                            data?.cars?.carLoanDetailsRe?.productLoanType === "รี" ||
                                                                            data?.cars?.carLoanDetailsRe?.productLoanType === "Pre-Aaprove-รีโอน" ||
                                                                            data?.cars?.carLoanDetailsRe?.productLoanType === "รีโอน"
                                                                            ?
                                                                            <>
                                                                                <Form.Item style={{ margin: 3 }}>
                                                                                    ปิดไฟแนนซ์จาก
                                                                                    <p className='dotted wi2' style={{ display: 'inline-block' }}>
                                                                                        {data?.cars?.carLoanDetailsRe?.productLoanType ?
                                                                                            <>
                                                                                                <b style={{ marginLeft: '20px', }}>{data?.cars?.ISSUNO}</b>
                                                                                            </>
                                                                                            :
                                                                                            <>
                                                                                                <b style={{ marginLeft: '20px', }}>{data?.carLoanDetails?.cars?.ISSUNO}</b>
                                                                                            </>
                                                                                        }

                                                                                    </p>
                                                                                </Form.Item>
                                                                                <Form.Item style={{ margin: 3 }}>
                                                                                    ยอดปิด
                                                                                    <p className='dotted wi2' style={{ display: 'inline-block' }}>
                                                                                        {data?.cars?.carLoanDetailsRe?.productLoanType ?
                                                                                            <>
                                                                                                <b>{currencyFormatOne(data?.cars?.carLoanDetailsRe?.re1)}</b>
                                                                                            </>
                                                                                            :
                                                                                            <>
                                                                                                <b>{currencyFormatOne(data?.carLoanDetailsRe?.re1)}</b>
                                                                                            </>
                                                                                        }


                                                                                    </p>
                                                                                    <span>บาท</span>
                                                                                </Form.Item>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <Form.Item style={{ margin: 3 }}>
                                                                                    ปิดไฟแนนซ์จาก
                                                                                    <p className='dotted wi2' style={{ display: 'inline-block' }}>
                                                                                        <b style={{ marginLeft: '20px', }}>{dataOldloan?.issuno}</b>
                                                                                    </p>
                                                                                </Form.Item>
                                                                                <Form.Item style={{ margin: 3 }}>
                                                                                    ยอดปิด
                                                                                    <p className='dotted wi2' style={{ display: 'inline-block' }}>
                                                                                        <b>
                                                                                            {/* {currencyFormatOne(dataOldloan?.oldLoanAmount)} */}
                                                                                            {dataOldloan?.carId !== 0 ?
                                                                                                <>
                                                                                                    {currencyFormatOne(dataOldloan?.oldLoanAmount)}
                                                                                                </>
                                                                                                :
                                                                                                null
                                                                                            }
                                                                                        </b>
                                                                                    </p>
                                                                                    <span>บาท</span>
                                                                                </Form.Item>
                                                                            </>
                                                                    }
                                                                    <Form.Item style={{ margin: 3 }}>
                                                                        ค่าจัด-โอน...................................................................บาท
                                                                    </Form.Item>
                                                                    {
                                                                        mixBroker?.checkBroker ?
                                                                            <Form.Item style={{ margin: 3 }}>
                                                                                ค่าอนุมัติ/บริการ
                                                                                <p className='dotted wi4' style={{ display: 'inline-block' }}>
                                                                                    <b>{currencyFormatOne(mixBroker?.data627)}</b>
                                                                                </p>
                                                                                <span> บาท</span>
                                                                            </Form.Item>
                                                                            :
                                                                            <Form.Item style={{ margin: 3 }}>
                                                                                ค่าอนุมัติ/บริการ............................................................บาท
                                                                            </Form.Item>
                                                                    }
                                                                    <Form.Item style={{ margin: 3 }}>
                                                                        ค่าน้ำมัน.......................................................................บาท
                                                                    </Form.Item>
                                                                    <Form.Item style={{ margin: 3 }}>
                                                                        ค่าทางด่วนที่ดิน............................................................บาท
                                                                    </Form.Item>
                                                                    <Form.Item style={{ margin: 3 }}>
                                                                        ค่าเลี่ยงประกัน..............................................................บาท
                                                                    </Form.Item>
                                                                    <Form.Item style={{ margin: 3 }}>
                                                                        ค่าแจ้งย้าย....................................................................บาท
                                                                    </Form.Item>
                                                                    <Form.Item style={{ margin: 3 }}>
                                                                        ค่าฝากตรวจ..................................................................บาท
                                                                    </Form.Item>
                                                                    <Form.Item style={{ margin: 3 }}>
                                                                        ค่าภาษี+พรบ................................................................บาท
                                                                    </Form.Item>
                                                                    <Form.Item style={{ margin: 3 }}>
                                                                        ยอดคงเหลือ.................................................................บาท
                                                                    </Form.Item>

                                                                    {/* </Row> */}
                                                                </>
                                                        }
                                                    </Col>
                                                    <Col span={12} className='gutter-row'>
                                                        {
                                                            approval === 27 ||
                                                                approval === 3 ||
                                                                approval === 26 ||
                                                                approval === 25 ||
                                                                approval === 21 ||
                                                                approval === 24 ?
                                                                <>
                                                                    <Form.Item style={{ margin: 3, fontSize: '20px' }}>
                                                                        <span style={{ display: 'inline-block', fontSize: '20px' }}>ยอดจัด</span>
                                                                        <p className='dotted wi' style={{ fontSize: '20px', display: 'inline-block' }}>
                                                                            {
                                                                                dataAP.loanId !== 0 ?
                                                                                    <b>{currencyFormatOne(dataAP.apLoanAmount)}</b>
                                                                                    :
                                                                                    <b>{currencyFormatOne(dataLoan?.approvedLoanAmount)}</b>
                                                                            }
                                                                        </p>
                                                                        <span style={{ display: 'inline-block', fontSize: '20px' }}>บาท</span>
                                                                    </Form.Item>
                                                                    <Form.Item style={{ margin: 3 }}>
                                                                        อัตราดอกเบี้ย
                                                                        <p className='dotted wi1' style={{ display: 'inline-block' }}>

                                                                            {dataLoan?.productType === 'p-loan' ?
                                                                                null
                                                                                :
                                                                                <>
                                                                                    {
                                                                                        dataCarLand?.productTypeCar === 2 ||
                                                                                            dataCarLand?.productTypeCar === 3 ?
                                                                                            <>
                                                                                                {/* รถใหญ่เช่าซื้อ */}
                                                                                                {
                                                                                                    dataAP.loanId !== 0 ?
                                                                                                        <>
                                                                                                            <b>{currencyFormatOne(dataAP.apInterestRate)} %</b>
                                                                                                        </>
                                                                                                        :
                                                                                                        <>
                                                                                                            <b>{currencyFormatOne(dataLoan?.interestRate)} %</b>
                                                                                                        </>
                                                                                                }
                                                                                            </>
                                                                                            :
                                                                                            <>
                                                                                                {/* รถเล็กเช่าซื้อ1 0.84, 0.75 */}
                                                                                                {dataCarLand?.productTypeCar === 1 ?
                                                                                                    <>
                                                                                                        {
                                                                                                            dataAP.loanId !== 0 ?
                                                                                                                <>
                                                                                                                    {/* <b>{currencyFormatOne(dataAP.apInterestRate)} %</b> */}
                                                                                                                </>
                                                                                                                :
                                                                                                                <>
                                                                                                                    <b>{currencyFormatOne(dataLoan?.interestRate)} %</b>
                                                                                                                </>
                                                                                                        }
                                                                                                    </>
                                                                                                    :
                                                                                                    null
                                                                                                }
                                                                                            </>
                                                                                    }
                                                                                </>
                                                                            }

                                                                        </p>
                                                                        <span> +VAT  </span>
                                                                        <span style={{ marginLeft: '10px' }}>จำนวน  </span>
                                                                        <span >
                                                                            <p className='dotted wi1' style={{ display: 'inline-block' }}>
                                                                                {
                                                                                    dataAP.loanId !== 0 ?
                                                                                        <b>{dataAP?.apLoanTerm}</b>
                                                                                        :
                                                                                        <b>{dataLoan?.loanTerm}</b>
                                                                                }
                                                                            </p>
                                                                        </span>
                                                                        <span> งวด  </span>
                                                                    </Form.Item>
                                                                    <Form.Item style={{ margin: 3 }}>
                                                                        งวดละ
                                                                        <p className='dotted wi2' style={{ display: 'inline-block' }}>
                                                                            {
                                                                                dataAP.loanId !== 0 ?
                                                                                    <b>{currencyFormatOne(dataAP?.apMonthlyPayment)}</b>
                                                                                    :
                                                                                    <b>{currencyFormatOne(monthlyPaymentCAL)}</b>
                                                                            }
                                                                        </p>
                                                                        <span> บาท  </span>
                                                                    </Form.Item>
                                                                    <Form.Item style={{ margin: 3 }} >
                                                                        รวมราคา
                                                                        <p className='dotted wi3' style={{ display: 'inline-block' }}>
                                                                            {
                                                                                dataAP.loanId !== 0 ?
                                                                                    <b>{currencyFormatOne(dataAP.apInstallmentWithInterest)}</b>
                                                                                    :
                                                                                    <b>{currencyFormatOne(installmentWithInterestCAL)}</b>
                                                                            }
                                                                        </p>
                                                                        <span> บาท  </span>
                                                                    </Form.Item>
                                                                </>
                                                                :
                                                                <>
                                                                    <Form.Item style={{ margin: 3, fontSize: '20px' }}>
                                                                        <span style={{ display: 'inline-block', fontSize: '20px' }}>ยอดจัด</span>
                                                                        <p className='dotted wi' style={{ fontSize: '20px', display: 'inline-block' }}>
                                                                            <b>{currencyFormatOne(dataLoan?.loanAmount)}</b>
                                                                        </p>
                                                                        <span style={{ display: 'inline-block', fontSize: '20px' }}>บาท</span>
                                                                    </Form.Item>
                                                                    <Form.Item style={{ margin: 3 }}>
                                                                        อัตราดอกเบี้ย
                                                                        <p className='dotted wi1' style={{ display: 'inline-block' }}>
                                                                            {/* <b>{currencyFormatOne(dataLoan?.interestRate)} % </b> */}
                                                                            {dataLoan?.productType === 'p-loan' ?
                                                                                null
                                                                                :
                                                                                <>
                                                                                    {
                                                                                        dataCarLand?.productTypeCar === 2 ||
                                                                                            dataCarLand?.productTypeCar === 3 ?
                                                                                            <>
                                                                                                {/* รถใหญ่เช่าซื้อ */}
                                                                                                {
                                                                                                    dataAP.loanId !== 0 ?
                                                                                                        <>
                                                                                                            <b>{currencyFormatOne(dataAP.apInterestRate)} %</b>
                                                                                                        </>
                                                                                                        :
                                                                                                        <>
                                                                                                            <b>{currencyFormatOne(dataLoan?.interestRate)} %</b>
                                                                                                        </>
                                                                                                }
                                                                                            </>
                                                                                            :
                                                                                            <>
                                                                                                {/* รถเล็กเช่าซื้อ1 0.84, 0.75*/}
                                                                                                {dataCarLand?.productTypeCar === 1 ?
                                                                                                    null
                                                                                                    :
                                                                                                    <>
                                                                                                        {
                                                                                                            dataAP.loanId !== 0 ?
                                                                                                                <>
                                                                                                                    {/* <b>{currencyFormatOne(dataAP.apInterestRate)} %</b> */}
                                                                                                                </>
                                                                                                                :
                                                                                                                <>
                                                                                                                    <b>{currencyFormatOne(dataLoan?.interestRate)} %</b>
                                                                                                                </>
                                                                                                        }
                                                                                                    </>

                                                                                                }
                                                                                            </>
                                                                                    }
                                                                                </>
                                                                            }

                                                                        </p>
                                                                        <span> +VAT  </span>
                                                                        <span style={{ marginLeft: '10px' }}>จำนวน  </span>
                                                                        <span >
                                                                            <p className='dotted wi1' style={{ display: 'inline-block' }}>
                                                                                <b>{dataLoan?.loanTerm}</b>
                                                                            </p>
                                                                        </span>
                                                                        <span> งวด  </span>
                                                                    </Form.Item>
                                                                    <Form.Item style={{ margin: 3 }}>
                                                                        งวดละ
                                                                        <p className='dotted wi2' style={{ display: 'inline-block' }}>
                                                                            <b>{currencyFormatOne(dataLoan?.monthlyPayment)}</b>
                                                                        </p>
                                                                        <span> บาท  </span>
                                                                    </Form.Item>
                                                                    <Form.Item style={{ margin: 3 }} >
                                                                        รวมราคา
                                                                        <p className='dotted wi3' style={{ display: 'inline-block' }}>
                                                                            <b>{currencyFormatOne(~~dataLoan?.monthlyPayment * ~~dataLoan?.loanTerm)}</b>
                                                                        </p>
                                                                        <span> บาท  </span>
                                                                    </Form.Item>
                                                                </>

                                                        }

                                                        <Form.Item style={{ margin: 3 }} >
                                                            <span> ส่งงวดทุกวันที่....................................................................</span>
                                                        </Form.Item>
                                                        <Form.Item style={{ margin: 3 }}>
                                                            <span> ส่งงวดแรกวันที่....................................................................</span>
                                                        </Form.Item>
                                                        <Form.Item style={{ margin: 3, fontSize: '20px' }}>
                                                            <b style={{ fontSize: '18px' }}><u>ค่าแนะนำ</u></b>
                                                        </Form.Item>
                                                        <Form.Item style={{ margin: 3 }}>
                                                            <span> รหัสลูกค้า...........................................................................</span>
                                                        </Form.Item>
                                                        {
                                                            mixBroker?.checkBroker ?
                                                                <>
                                                                    <Form.Item style={{ margin: 3 }}>
                                                                        ชื่อ-สกุลลูกค้า
                                                                        <p className='dotted wi3' style={{ display: 'inline-block' }}>
                                                                            <b>{mixBroker?.brokerName}</b>
                                                                        </p>
                                                                    </Form.Item>
                                                                    <Form.Item style={{ margin: 3 }}>
                                                                        จำนวนเงิน
                                                                        <p className='dotted wi3' style={{ display: 'inline-block' }}>
                                                                            <b>{currencyFormatOne(mixBroker?.data630)}</b>
                                                                        </p>
                                                                        <span> บาท  </span>
                                                                    </Form.Item>
                                                                </>
                                                                :
                                                                <>
                                                                    <Form.Item style={{ margin: 3 }}>
                                                                        <span> ชื่อ-สกุลลูกค้า.....................................................................</span>
                                                                    </Form.Item>
                                                                    <Form.Item style={{ margin: 3 }}>
                                                                        <span>จำนวนเงิน..........................................................................บาท</span>
                                                                    </Form.Item>
                                                                </>
                                                        }
                                                    </Col>
                                                </>
                                                :
                                                <>
                                                    {/* Land */}
                                                    <Col span={12} className='gutter-row'>
                                                        {
                                                            dataLoan?.productLoanLandType === 'ย้ายไฟแนนซ์' ||
                                                                dataLoan?.productLoanLandType === 'Pre-Aaprove-ย้ายไฟแนนซ์' ?
                                                                <>
                                                                    {
                                                                        data?.lands?.landOldLoanDetails?.productLoanLandType === "Pre-Aaprove-เปลี่ยนสัญญา" ||
                                                                            data?.lands?.landOldLoanDetails?.productLoanLandType === "Pre-Aaprove-รี+เปลี่ยนสัญญา" ||
                                                                            data?.lands?.landOldLoanDetails?.productLoanLandType === "รี+เปลี่ยนสัญญา" ||
                                                                            data?.lands?.landOldLoanDetails?.productLoanLandType === "เปลี่ยนสัญญา" ||
                                                                            data?.lands?.landOldLoanDetails?.productLoanLandType === "Pre-Aaprove-ปรับโครงสร้าง" ||
                                                                            data?.lands?.landOldLoanDetails?.productLoanLandType === "ปรับโครงสร้าง" ||
                                                                            data?.lands?.landOldLoanDetails?.productLoanLandType === "Pre-Aaprove-รี" ||
                                                                            data?.lands?.landOldLoanDetails?.productLoanLandType === "รี" ||
                                                                            data?.lands?.landOldLoanDetails?.productLoanLandType === "Pre-Aaprove-รีโอน" ||
                                                                            data?.lands?.landOldLoanDetails?.productLoanLandType === "รีโอน"
                                                                            ?
                                                                            <>
                                                                                <Form.Item style={{ margin: 3 }}>
                                                                                    ปิดไฟแนนซ์จาก
                                                                                    <p className='dotted wi2' style={{ display: 'inline-block' }}>
                                                                                        <b style={{ marginLeft: '20px', }}>{data?.landLoanDetails?.land?.ISSUNO}</b>
                                                                                    </p>
                                                                                </Form.Item>
                                                                                <Form.Item style={{ margin: 3 }}>
                                                                                    ยอดปิด
                                                                                    <p className='dotted wi2' style={{ display: 'inline-block' }}>
                                                                                        <b>{currencyFormatOne(data?.landLoanDetailsRe?.re1)}</b>

                                                                                    </p>
                                                                                    <span>บาท</span>
                                                                                </Form.Item>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <Form.Item style={{ margin: 3 }}>
                                                                                    ปิดไฟแนนซ์จาก
                                                                                    <p className='dotted wi2' style={{ display: 'inline-block' }}>
                                                                                        <b style={{ marginLeft: '20px', }}>{dataOldloan?.issuno}</b>
                                                                                    </p>
                                                                                </Form.Item>
                                                                                <Form.Item style={{ margin: 3 }}>
                                                                                    ยอดปิด
                                                                                    <p className='dotted wi2' style={{ display: 'inline-block' }}>
                                                                                        <b>
                                                                                            {dataOldloan?.landId !== 0 ?
                                                                                                <>
                                                                                                    {currencyFormatOne(dataOldloan?.oldLandLoanAmount)}
                                                                                                </>
                                                                                                :
                                                                                                null
                                                                                            }
                                                                                        </b>
                                                                                    </p>
                                                                                    <span>บาท</span>
                                                                                </Form.Item>
                                                                            </>
                                                                    }
                                                                    <Form.Item style={{ margin: 3 }}>
                                                                        ค่าจัด-โอน...........................................................................บาท
                                                                    </Form.Item>
                                                                    <Form.Item style={{ margin: 3 }}>
                                                                        ค่าอนุมัติ/บริการ....................................................................บาท
                                                                    </Form.Item>
                                                                    <Form.Item style={{ margin: 3 }}>
                                                                        ค่าน้ำมัน...............................................................................บาท
                                                                    </Form.Item>
                                                                    <Form.Item style={{ margin: 3 }}>
                                                                        ค่าทางด่วนที่ดิน....................................................................บาท
                                                                    </Form.Item>
                                                                    <Form.Item style={{ margin: 3 }}>
                                                                        ค่าเลี่ยงประกัน......................................................................บาท
                                                                    </Form.Item>
                                                                    <Form.Item style={{ margin: 3 }}>
                                                                        ค่าแจ้งย้าย............................................................................บาท
                                                                    </Form.Item>
                                                                    <Form.Item style={{ margin: 3 }}>
                                                                        ค่าฝากตรวจ..........................................................................บาท
                                                                    </Form.Item>
                                                                    <Form.Item style={{ margin: 3 }}>
                                                                        ค่าภาษี+พรบ........................................................................บาท
                                                                    </Form.Item>
                                                                    <Form.Item style={{ margin: 3 }}>
                                                                        ยอดคงเหลือ.........................................................................บาท
                                                                    </Form.Item>
                                                                </>
                                                                :
                                                                <>
                                                                    {
                                                                        data?.lands?.landLoanDetailsRe?.productLoanLandType === "Pre-Aaprove-เปลี่ยนสัญญา" ||
                                                                            data?.lands?.landLoanDetailsRe?.productLoanLandType === "Pre-Aaprove-รี+เปลี่ยนสัญญา" ||
                                                                            data?.lands?.landLoanDetailsRe?.productLoanLandType === "รี+เปลี่ยนสัญญา" ||
                                                                            data?.lands?.landLoanDetailsRe?.productLoanLandType === "เปลี่ยนสัญญา" ||
                                                                            data?.lands?.landLoanDetailsRe?.productLoanLandType === "Pre-Aaprove-ปรับโครงสร้าง" ||
                                                                            data?.lands?.landLoanDetailsRe?.productLoanLandType === "ปรับโครงสร้าง" ||
                                                                            data?.lands?.landLoanDetailsRe?.productLoanLandType === "Pre-Aaprove-รี" ||
                                                                            data?.lands?.landLoanDetailsRe?.productLoanLandType === "รี" ||
                                                                            data?.lands?.landLoanDetailsRe?.productLoanLandType === "Pre-Aaprove-รีโอน" ||
                                                                            data?.lands?.landLoanDetailsRe?.productLoanLandType === "รีโอน"
                                                                            ?
                                                                            <>
                                                                                <Form.Item style={{ margin: 3 }}>
                                                                                    ปิดไฟแนนซ์จาก
                                                                                    <p className='dotted wi2' style={{ display: 'inline-block' }}>
                                                                                        <b style={{ marginLeft: '20px', }}>{data?.lands?.ISSUNO}</b>
                                                                                    </p>
                                                                                </Form.Item>
                                                                                <Form.Item style={{ margin: 3 }}>
                                                                                    ยอดปิด
                                                                                    <p className='dotted wi2' style={{ display: 'inline-block' }}>
                                                                                        <b>{currencyFormatOne(data?.lands?.landLoanDetailsRe?.re1)}</b>

                                                                                    </p>
                                                                                    <span>บาท</span>
                                                                                </Form.Item>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <Form.Item style={{ margin: 3 }}>
                                                                                    ปิดไฟแนนซ์จาก
                                                                                    <p className='dotted wi2' style={{ display: 'inline-block' }}>
                                                                                        <b style={{ marginLeft: '20px', }}>{dataOldloan?.issuno}</b>
                                                                                    </p>
                                                                                </Form.Item>
                                                                                <Form.Item style={{ margin: 3 }}>
                                                                                    ยอดปิด
                                                                                    <p className='dotted wi2' style={{ display: 'inline-block' }}>
                                                                                        <b>
                                                                                            {dataOldloan?.landId !== 0 ?
                                                                                                <>
                                                                                                    {currencyFormatOne(dataOldloan?.oldLandLoanAmount)}
                                                                                                </>
                                                                                                :
                                                                                                null
                                                                                            }
                                                                                        </b>
                                                                                    </p>
                                                                                    <span>บาท</span>
                                                                                </Form.Item>
                                                                            </>
                                                                    }
                                                                    <Form.Item style={{ margin: 3 }}>
                                                                        ค่าจัด-โอน...................................................................บาท
                                                                    </Form.Item>
                                                                    <Form.Item style={{ margin: 3 }}>
                                                                        ค่าอนุมัติ/บริการ............................................................บาท
                                                                    </Form.Item>
                                                                    <Form.Item style={{ margin: 3 }}>
                                                                        ค่าน้ำมัน.......................................................................บาท
                                                                    </Form.Item>
                                                                    <Form.Item style={{ margin: 3 }}>
                                                                        ค่าทางด่วนที่ดิน............................................................บาท
                                                                    </Form.Item>
                                                                    <Form.Item style={{ margin: 3 }}>
                                                                        ค่าเลี่ยงประกัน..............................................................บาท
                                                                    </Form.Item>
                                                                    <Form.Item style={{ margin: 3 }}>
                                                                        ค่าแจ้งย้าย....................................................................บาท
                                                                    </Form.Item>
                                                                    <Form.Item style={{ margin: 3 }}>
                                                                        ค่าฝากตรวจ..................................................................บาท
                                                                    </Form.Item>
                                                                    <Form.Item style={{ margin: 3 }}>
                                                                        ค่าภาษี+พรบ................................................................บาท
                                                                    </Form.Item>
                                                                    <Form.Item style={{ margin: 3 }}>
                                                                        ยอดคงเหลือ.................................................................บาท
                                                                    </Form.Item>


                                                                </>
                                                        }
                                                    </Col>
                                                    <Col span={12} className='gutter-row'>
                                                        {
                                                            approval === 27 ||
                                                                approval === 3 ||
                                                                approval === 26 ||
                                                                approval === 25 ||
                                                                approval === 21 ||
                                                                approval === 24 ?
                                                                <>
                                                                    <Form.Item style={{ margin: 3, fontSize: '20px' }}>
                                                                        <span style={{ display: 'inline-block', fontSize: '20px' }}>ยอดจัด</span>
                                                                        <p className='dotted wi' style={{ fontSize: '20px', display: 'inline-block' }}>
                                                                            {
                                                                                dataAP.loanId !== 0 ?
                                                                                    <b>{currencyFormatOne(dataAP.apLoanAmount)}</b>
                                                                                    :
                                                                                    <b>{currencyFormatOne(dataLoan?.approvedLoanAmount)}</b>
                                                                            }
                                                                        </p>
                                                                        <span style={{ display: 'inline-block', fontSize: '20px' }}>บาท</span>
                                                                    </Form.Item>
                                                                    <Form.Item style={{ margin: 3 }}>
                                                                        อัตราดอกเบี้ย
                                                                        <p className='dotted wi1' style={{ display: 'inline-block' }}>
                                                                            {
                                                                                dataAP.loanId !== 0 ?
                                                                                    <b>{currencyFormatOne(dataAP.apInterestRate)} %</b>
                                                                                    :
                                                                                    <b>{currencyFormatOne(dataLoan?.interestRateLand)} %</b>
                                                                            }
                                                                        </p>
                                                                        <span> +VAT  </span>
                                                                        <span style={{ marginLeft: '10px' }}>จำนวน  </span>
                                                                        <span >
                                                                            <p className='dotted wi1' style={{ display: 'inline-block' }}>
                                                                                {
                                                                                    dataAP.loanId !== 0 ?
                                                                                        <b>{dataAP.apLoanTerm}</b>
                                                                                        :
                                                                                        <b>{dataLoan?.loanLandTerm}</b>
                                                                                }
                                                                            </p>
                                                                        </span>
                                                                        <span> งวด  </span>
                                                                    </Form.Item>
                                                                    <Form.Item style={{ margin: 3 }}>
                                                                        งวดละ
                                                                        <p className='dotted wi2' style={{ display: 'inline-block' }}>
                                                                            {
                                                                                dataAP.loanId !== 0 ?
                                                                                    <b>{currencyFormatOne(dataAP.apMonthlyPayment)}</b>
                                                                                    :
                                                                                    <b>{currencyFormatOne(monthlyPaymentCAL)}</b>
                                                                            }
                                                                        </p>
                                                                        <span> บาท  </span>
                                                                    </Form.Item>
                                                                    <Form.Item style={{ margin: 3 }} >
                                                                        รวมราคา
                                                                        <p className='dotted wi3' style={{ display: 'inline-block' }}>
                                                                            {
                                                                                dataAP.loanId !== 0 ?
                                                                                    <b style={{ marginLeft: '20px' }}>{currencyFormatOne(dataAP.apInstallmentWithInterest)}</b>
                                                                                    :
                                                                                    <b style={{ marginLeft: '20px' }}>{currencyFormatOne(installmentWithInterestCAL)}</b>
                                                                            }
                                                                        </p>
                                                                        <span> บาท  </span>
                                                                    </Form.Item>
                                                                </>
                                                                :
                                                                <>
                                                                    <Form.Item style={{ margin: 3, fontSize: '20px' }}>
                                                                        <span style={{ display: 'inline-block', fontSize: '20px' }}>ยอดจัด</span>
                                                                        <p className='dotted wi' style={{ fontSize: '20px', display: 'inline-block' }}>
                                                                            <b>{currencyFormatOne(dataLoan?.loanAmountLand)}</b>
                                                                        </p>
                                                                        <span style={{ display: 'inline-block', fontSize: '20px' }}>บาท</span>
                                                                    </Form.Item>
                                                                    <Form.Item style={{ margin: 3 }}>
                                                                        อัตราดอกเบี้ย
                                                                        <p className='dotted wi1' style={{ display: 'inline-block' }}>
                                                                            {
                                                                                dataAP.loanId !== 0 ?
                                                                                    <b>{currencyFormatOne(dataAP.apInterestRate)} %</b>
                                                                                    :
                                                                                    <b>{currencyFormatOne(dataLoan?.interestRateLand)} %</b>
                                                                            }
                                                                        </p>
                                                                        <span> +VAT  </span>
                                                                        <span style={{ marginLeft: '10px' }}>จำนวน  </span>
                                                                        <span >
                                                                            <p className='dotted wi1' style={{ display: 'inline-block' }}>
                                                                                <b>{dataLoan?.loanLandTerm}</b>
                                                                            </p>
                                                                        </span>
                                                                        <span> งวด  </span>
                                                                    </Form.Item>
                                                                    <Form.Item style={{ margin: 3 }}>
                                                                        งวดละ
                                                                        <p className='dotted wi2' style={{ display: 'inline-block' }}>
                                                                            <b>{currencyFormatOne(dataLoan?.monthlyPaymentLand)}</b>
                                                                        </p>
                                                                        <span> บาท  </span>
                                                                    </Form.Item>
                                                                    <Form.Item style={{ margin: 3 }} >
                                                                        รวมราคา
                                                                        <p className='dotted wi3' style={{ display: 'inline-block' }}>
                                                                            <b style={{ marginLeft: '20px' }}>{currencyFormatOne(~~dataLoan?.loanLandTerm * ~~dataLoan?.monthlyPaymentLand)}
                                                                            </b>
                                                                        </p>
                                                                        <span> บาท  </span>
                                                                    </Form.Item>
                                                                </>
                                                        }
                                                        <Form.Item style={{ margin: 3 }} >
                                                            <span> ส่งงวดทุกวันที่....................................................................</span>
                                                        </Form.Item>
                                                        <Form.Item style={{ margin: 3 }}>
                                                            <span> ส่งงวดแรกวันที่....................................................................</span>
                                                        </Form.Item>
                                                        <Form.Item style={{ margin: 3, fontSize: '20px' }}>
                                                            <b style={{ fontSize: '18px' }}><u>ค่าแนะนำ</u></b>
                                                        </Form.Item>
                                                        <Form.Item style={{ margin: 3 }}>
                                                            <span> รหัสลูกค้า...........................................................................</span>
                                                        </Form.Item>
                                                        <Form.Item style={{ margin: 3 }}>
                                                            <span> ชื่อ-สกุลลูกค้า.....................................................................</span>
                                                        </Form.Item>
                                                        <Form.Item style={{ margin: 3 }}>
                                                            <span>จำนวนเงิน..........................................................................บาท</span>
                                                        </Form.Item>
                                                    </Col>
                                                </>
                                        }
                                    </Row>

                                    <Row gutter={32} >
                                        <Col span={24} >
                                            <b>
                                                {
                                                    typeLoan === "car" ?
                                                        <>
                                                            <Row style={{ margin: 0 }}>
                                                                <Col span={3} style={{ textAlign: 'center', margin: 0 }}>
                                                                    <u>หมายเหตุ</u>
                                                                </Col>
                                                                <Col span={21} style={{ textAlign: 'center', margin: 0 }}>
                                                                    <Checkbox
                                                                        style={{ fontSize: '15px' }}
                                                                        checked={dataLoan?.checkbox?.cbPreaaprove === true ? true : false}
                                                                    >Pre-Aaprove
                                                                    </Checkbox>
                                                                    <Checkbox
                                                                        style={{ fontSize: '15px' }}
                                                                        checked={dataLoan?.checkbox?.cbCar === true ? true : false}
                                                                    >รถ
                                                                    </Checkbox>
                                                                    <Checkbox
                                                                        style={{ fontSize: '15px' }}
                                                                        checked={dataLoan?.checkbox?.cbHome === true ? true : false}
                                                                    >บ้าน
                                                                    </Checkbox>
                                                                    <Checkbox
                                                                        style={{ fontSize: '15px' }}
                                                                        checked={dataLoan?.checkbox?.cbHomeRent === true ? true : false}
                                                                    >บ้านเช่า
                                                                    </Checkbox>
                                                                    <Checkbox
                                                                        style={{ fontSize: '15px' }}
                                                                        checked={dataLoan?.checkbox?.cbHomeGov === true ? true : false}
                                                                    >บ้านพักราการ
                                                                    </Checkbox>
                                                                    <Checkbox
                                                                        style={{ fontSize: '15px' }}
                                                                        checked={dataLoan?.checkbox?.cbPark === true ? true : false}
                                                                    >ลานจอด
                                                                    </Checkbox>
                                                                    <Checkbox
                                                                        style={{ fontSize: '15px' }}
                                                                        checked={dataLoan?.checkbox?.cbVat === true ? true : false}
                                                                    >ภาษี
                                                                    </Checkbox>
                                                                    <Checkbox
                                                                        style={{ fontSize: '15px' }}
                                                                        checked={dataLoan?.checkbox?.cbVdo === true ? true : false}
                                                                    >VDO
                                                                    </Checkbox>
                                                                    <Checkbox
                                                                        style={{ fontSize: '15px' }}
                                                                        checked={dataLoan?.checkbox?.cbGps === true ? true : false}
                                                                    >GPS
                                                                    </Checkbox>
                                                                </Col>
                                                            </Row>
                                                        </>
                                                        :
                                                        <>
                                                            <Row style={{ margin: 0 }}>
                                                                <Col span={3} style={{ textAlign: 'center', margin: 0 }}>
                                                                    <u>หมายเหตุ</u>
                                                                </Col>
                                                                <Col span={21} style={{ textAlign: 'center', margin: 0 }}>
                                                                    <Checkbox
                                                                        style={{ fontSize: '15px' }}
                                                                        checked={dataLoan?.checkbox?.cbPreaaprove === true ? true : false}
                                                                    >Pre-Aaprove
                                                                    </Checkbox>
                                                                    <Checkbox
                                                                        style={{ fontSize: '15px' }}
                                                                        checked={dataLoan?.checkbox?.cbLand === true ? true : false}
                                                                    >ที่ดิน
                                                                    </Checkbox>
                                                                    <Checkbox
                                                                        style={{ fontSize: '15px' }}
                                                                        checked={dataLoan?.checkbox?.cbHome === true ? true : false}
                                                                    >บ้าน
                                                                    </Checkbox>
                                                                    <Checkbox
                                                                        style={{ fontSize: '15px' }}
                                                                        checked={dataLoan?.checkbox?.cbHomeRent === true ? true : false}
                                                                    >บ้านเช่า
                                                                    </Checkbox>
                                                                    <Checkbox
                                                                        style={{ fontSize: '15px' }}
                                                                        checked={dataLoan?.checkbox?.cbHomeGov === true ? true : false}
                                                                    >บ้านพักราการ
                                                                    </Checkbox>
                                                                    <Checkbox
                                                                        style={{ fontSize: '15px' }}
                                                                        checked={dataLoan?.checkbox?.cbPark === true ? true : false}
                                                                    >ลานจอด
                                                                    </Checkbox>
                                                                    <Checkbox
                                                                        style={{ fontSize: '15px' }}
                                                                        checked={dataLoan?.checkbox?.cbVat === true ? true : false}
                                                                    >ภาษี
                                                                    </Checkbox>
                                                                    <Checkbox
                                                                        style={{ fontSize: '15px' }}
                                                                        checked={dataLoan?.checkbox?.cbVdo === true ? true : false}
                                                                    >VDO
                                                                    </Checkbox>
                                                                    <Checkbox
                                                                        style={{ fontSize: '15px' }}
                                                                        checked={dataLoan?.checkbox?.cbGps === true ? true : false}
                                                                    >GPS
                                                                    </Checkbox>
                                                                </Col>
                                                            </Row>
                                                        </>
                                                }
                                            </b>
                                        </Col>
                                    </Row>

                                    <Row gutter={32} >
                                        <Col span={24} className='center' style={{ marginTop: '5px', height: '150px' }}>
                                            {/* <b> */}
                                            {/* <Row style={{ margin: 0 }}>
                                                    <Col span={4} style={{ textAlign: 'center', margin: 0 }}>
                                                        <u>หมายเหตุธุรการ</u>
                                                    </Col>
                                                </Row> */}
                                            <b>
                                                {
                                                    notes?.length > 0 ?
                                                        <Row style={{ margin: 0 }} gutter={32}>
                                                            <Col span={24}>
                                                                <u>หมายเหตุธุรการ : </u>
                                                                {
                                                                    notes?.map((item, index) => {
                                                                        if (showNoteModalPreemAD(item.status)) {
                                                                            return (
                                                                                <div key={index} style={{ display: 'inline-block' }}>
                                                                                    {/* <b style={{ color: 'red' }}>{notes[lastIndex]?.cause}, </b>
                                                                                    <b style={{ color: 'red' }}> {notes[lastIndex]?.note} </b> */}
                                                                                    <b style={{ color: 'red', marginRight: '5px' }}>{item.cause !== "" ? <>{item.cause},</> : <></>}</b>
                                                                                    <b style={{ color: 'red' }}>{item.note !== "" ? <>{item.note},</> : <></>}</b>
                                                                                </div>
                                                                            )
                                                                        } else {
                                                                            return null;
                                                                        }
                                                                    })
                                                                }
                                                                <p className='pp'>
                                                                    ...............................................................................................................................................................................................................................
                                                                </p>
                                                                {/* <p className='pp'>
                                                                    ...............................................................................................................................................................................................................................
                                                                </p> */}
                                                            </Col>
                                                        </Row>
                                                        : null
                                                }
                                            </b>
                                            {/* </b> */}
                                        </Col>
                                    </Row>
                                    <Space>
                                        <Row gutter={32} >
                                            <Col span={24} className='center' style={{ marginTop: '10px' }}>
                                                {/* <b> */}
                                                {/* <Row style={{ margin: 0 }}>
                                                    <Col span={4} style={{ textAlign: 'center', margin: 0 }}>
                                                        <u>หมายเหตุผู้อนุมัติ</u>
                                                    </Col>
                                                </Row> */}
                                                <b>
                                                    {
                                                        notes?.length > 0 ?
                                                            <Row style={{ margin: 0 }} gutter={32}>
                                                                <Col span={24}>
                                                                    <u>หมายเหตุผู้อนุมัติ : </u>
                                                                    {
                                                                        notes?.map((item, index) => {
                                                                            if (showNoteModalPreemPG(item.status)) {
                                                                                return (
                                                                                    <div key={index} style={{ display: 'inline-block' }}>
                                                                                        <b style={{ color: 'red', marginRight: '5px' }}>{item.cause !== "" ? <>{item.cause},</> : <></>}</b>
                                                                                        <b style={{ color: 'red' }}>{item.note !== "" ? <>{item.note},</> : <></>}</b>
                                                                                    </div>
                                                                                )
                                                                            } else {
                                                                                return null;
                                                                            }
                                                                        })
                                                                    }
                                                                    <p className='pp'>
                                                                        ...............................................................................................................................................................................................................................
                                                                    </p>
                                                                    {/* <p className='pp'>
                                                                    ...............................................................................................................................................................................................................................
                                                                </p> */}
                                                                </Col>
                                                            </Row>
                                                            : null
                                                    }
                                                </b>
                                                {/* </b> */}
                                            </Col>
                                        </Row>
                                    </Space>
                                    <Row gutter={32} style={{ marginTop: 20, marginBottom: 0 }}>
                                        <Col className='gutter-row' span={6}>
                                            <span>
                                                <Form.Item label='การตลาด' style={{ margin: 0 }}>
                                                    <b>{dataLoan?.proposalBy}</b>
                                                </Form.Item>
                                                {
                                                    typeLoan === "car" ?
                                                        <>
                                                            <Form.Item label='วันที่เสนอ' style={{ margin: 0 }}>
                                                                <b>{data?.cars?.carInput ? dayjs(data?.cars?.carInput).format('DD-MM-YYYY') : ""}</b>
                                                            </Form.Item>
                                                        </>
                                                        :
                                                        <>
                                                            <Form.Item label='วันที่เสนอ' style={{ margin: 0 }}>
                                                                <b>{data?.lands?.landInput ? dayjs(data?.lands?.landInput).format('DD-MM-YYYY') : ""}</b>
                                                            </Form.Item>
                                                        </>
                                                }
                                            </span>
                                        </Col>
                                        <Col className='gutter-row' span={6}>
                                            <span>
                                                <Form.Item label='ผู้เสนอ' style={{ margin: 0 }}>
                                                    <b> {dataLoan?.reviewedBy}</b>
                                                </Form.Item>
                                                <Form.Item label='วันที่เสนอ' style={{ margin: 0 }}>
                                                    <b>{dataLoan?.acceptPassDate ? dayjs(dataLoan?.acceptPassDate).format('DD-MM-YYYY') : ""}</b>
                                                </Form.Item>
                                            </span>
                                        </Col>

                                        <Col className='gutter-row' span={6}>
                                            <span>
                                                <Form.Item label='ผู้อนุมัติ' style={{ margin: 0 }}>
                                                    <b> {dataLoan?.approvedBy}</b>
                                                </Form.Item>
                                                <Form.Item label='วันที่อนุมัติ' style={{ margin: 0 }}>
                                                    <b>{dataLoan?.approvalDate ? dayjs(dataLoan?.approvalDate).format('DD-MM-YYYY') : ""}</b>
                                                </Form.Item>
                                            </span>
                                        </Col>
                                        <Col className='gutter-row' span={6}>
                                            {/* <span> */}
                                            <Form.Item label='ผู้ตรวจ' style={{ marginTop: 0 }}>
                                                <b>................</b>
                                            </Form.Item>
                                            {/* <Form.Item label='วันที่ตรวจ' style={{ margin: 0 }}>
                                                    <b>{dataLoan?.approvalDate ? dayjs(dataLoan?.approvalDate).format('DD-MM-YYYY') : ""}</b>
                                                </Form.Item> */}
                                            {/* </span> */}
                                        </Col>
                                    </Row>
                                </Col>
                            </Card>
                        </Row>
                    </Form>
                </Row >
            </Modal >
        </>
    )
};

export default ModalPreemptionCertificateNEW
