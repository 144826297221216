// -ค้นหาสาขา+วันที่ถึงวันที่  -ตารางยอดรวมเลือกข้อมูลได้ -ปุ่มค้นหา -PDF --นับและแสดงออกตารางได้ เหลือนับเคสซ้ำ --นับแสดงทุกเคสแล้ว -ได้ Excel ได้หมดแล้ว
import React, { useState, useRef } from "react";
import { Button, Row, Col, Table, Form, DatePicker } from "antd";
import { useReactToPrint } from "react-to-print";
import axios from "axios";
import dotaColums from "./dotaColums";
import ExportExcel from "./exportExcel";
import InputSelect from "./inputSelect";
import { getlandpn1 } from "../../../file_mid/all_api";

const { RangePicker } = DatePicker;

function TabelLoanLocat() {
  const [axiosData, setData] = useState([]);
  const [dd1, setDd1] = useState([]);
  //รับค่าจาก InputSelect
  const [locatA, setLocatA] = useState([]);
  const handleSelectChange = (values) => {
    setLocatA(values);
  };
  const onChangeDate = (e1, e2) => {
    console.log("e1: ", e1);
    console.log("e2: ", e2);
    setDd1(e2);
  };

  console.log("dd1:", dd1);

  //F-form-1
  const onFinish = (values) => {
    console.log("Success:", values);
  };
  //F-form-2
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  //เรียกข้อมูล API
  const postData = async () => {
    const today = new Date();
    const formattedDate = today.toISOString().split("T")[0];
    if (locatA.length === 0) {
      alert("เลือกสาขาก่อน");
    } else if (dd1.length === 0) {
      alert("เลือกวันที่ถึงวันที่ก่อน");
    } else if (dd1[0] > formattedDate || dd1[2] > formattedDate) {
      alert(
        "ยังไม่มีข้อมูลวันที่" +
        JSON.stringify(dd1[0]) +
        "-" +
        JSON.stringify(dd1[1])
      );
    } else {
      try {
        const response = await axios.post(
          getlandpn1,
          {
            days1: dd1[0],
            days2: dd1[1],
            approvalStatus: "ทั้งหมด",
          }
        );
        //console.error("response.data:",response.data);
        //checkCaseDoubel(response.data);
        formatDataForTable(response.data);
      } catch (error) {
        // Handle errors
        console.error("Error:", error);
      }
    }
  };
  //เคสทั้งหมด
  const countBranchesCaseAll = (data, keyword) => {
    return data.filter((item) => item.branch.includes(keyword)).length;
  };
  //เคสได้ยอด caseSuccess
  const countCaseSuccess = (data, keyword) => {
    //return data.filter((item) => item.branch.includes(keyword)).length && data.filter((item) => item.approvalStatus.includes(keyword2)).length
    const data1 = data.filter(
      (item) =>
        item.branch.includes(keyword) && item.approvalStatus === 14
    ).length;
    const data2 = data.filter(
      (item) =>
        item.branch.includes(keyword) && item.approvalStatus === 16
    ).length;
    const data3 = data.filter(
      (item) =>
        item.branch.includes(keyword) && item.approvalStatus === 18
    ).length;
    const data4 = data.filter(
      (item) =>
        item.branch.includes(keyword) &&
        item.approvalStatus === 19
    ).length;
    const data5 = data.filter(
      (item) =>
        item.branch.includes(keyword) &&
        item.approvalStatus === 20
    ).length;
    const data6 = data.filter(
      (item) =>
        item.branch.includes(keyword) && item.approvalStatus === 2
    ).length;
    const data7 = data.filter(
      (item) =>
        item.branch.includes(keyword) && item.approvalStatus === 3
    ).length;
    const data8 = data.filter(
      (item) =>
        item.branch.includes(keyword) && item.approvalStatus === 22
    ).length;
    const data9 = data.filter(
      (item) =>
        item.branch.includes(keyword) &&
        item.approvalStatus === 24
    ).length;
    const data10 = data.filter(
      (item) =>
        item.branch.includes(keyword) && item.approvalStatus === 25
    ).length;
    const data11 = data.filter(
      (item) =>
        item.branch.includes(keyword) &&
        item.approvalStatus === 26
    ).length;
    const data12 = data.filter(
      (item) =>
        item.branch.includes(keyword) && item.approvalStatus === 27
    ).length;
    const data13 = data.filter(
      (item) =>
        item.branch.includes(keyword) && item.approvalStatus === 23
    ).length;
    return (
      data1 +
      data2 +
      data3 +
      data4 +
      data5 +
      data6 +
      data7 +
      data8 +
      data9 +
      data10 +
      data11 +
      data12 +
      data13
    );
  };
  //รอพี่หนุ่มรับ รอเคาะราคา ขอเอกสารเพิ่ม
  const countCaseWait = (data, keyword) => {
    //return data.filter((item) => item.branch.includes(keyword)).length && data.filter((item) => item.approvalStatus.includes(keyword2)).length
    const data1 = data.filter(
      (item) =>
        item.branch.includes(keyword) && item.approvalStatus === 11
    ).length;
    const data2 = data.filter(
      (item) =>
        item.branch.includes(keyword) && item.approvalStatus === 12
    ).length;
    const data3 = data.filter(
      (item) =>
        item.branch.includes(keyword) && item.approvalStatus === 13
    ).length;
    return data1 + data2 + data3;
  };
  //ปัดตก
  const countCaseFail = (data, keyword) => {
    return data.filter(
      (item) => item.branch.includes(keyword) && item.approvalStatus === 15
    ).length;
  };
  //เคสซ้ำ numberLand/เลขโฉนด ,district/อำเภอ
  // countCaseDoubel
  const checkCaseDoubel = (data) => {
    const newResult = {};
    const seen = {};

    data.forEach((item) => {
      const key = item.numberLand + item.district;
      if (seen[key]) {
        newResult[item.branch] = (newResult[item.branch] || 0) + 1;
      }
      seen[key] = true;
    });

    // Set default count to 0 for branches not found
    Object.keys(newResult).forEach((branch) => {
      if (!newResult[branch]) {
        newResult[branch] = 0;
      }
    });
    //console.log("XXXX", newResult);
    return newResult;
  };

  //console.log(countBranches);
  const formatDataForTable = async (axiosData) => {
    const arrCountCaseAll = [];
    arrCountCaseAll[0] = await countBranchesCaseAll(axiosData, "MIT");
    arrCountCaseAll[1] = await countBranchesCaseAll(axiosData, "UD");
    arrCountCaseAll[2] = await countBranchesCaseAll(axiosData, "LEX");
    arrCountCaseAll[3] = await countBranchesCaseAll(axiosData, "S4");
    arrCountCaseAll[4] = await countBranchesCaseAll(axiosData, "K100");
    const sumCaseAll = arrCountCaseAll.reduce((sum, number) => {
      return sum + number;
    }, 0);

    const arrCaseSuccess = [];
    arrCaseSuccess[0] = await countCaseSuccess(axiosData, "MIT");
    arrCaseSuccess[1] = await countCaseSuccess(axiosData, "UD");
    arrCaseSuccess[2] = await countCaseSuccess(axiosData, "LEX");
    arrCaseSuccess[3] = await countCaseSuccess(axiosData, "S4");
    arrCaseSuccess[4] = await countCaseSuccess(axiosData, "K100");
    const sumCaseSuccess = arrCaseSuccess.reduce((sum, number) => {
      return sum + number;
    }, 0);

    const arrCountCaseWait = [];
    arrCountCaseWait[0] = await countCaseWait(axiosData, "MIT");
    arrCountCaseWait[1] = await countCaseWait(axiosData, "UD");
    arrCountCaseWait[2] = await countCaseWait(axiosData, "LEX");
    arrCountCaseWait[3] = await countCaseWait(axiosData, "S4");
    arrCountCaseWait[4] = await countCaseWait(axiosData, "K100");
    const sumCaseWait = arrCountCaseWait.reduce((sum, number) => {
      return sum + number;
    }, 0);

    const arrCountCaseFail = [];
    arrCountCaseFail[0] = await countCaseFail(axiosData, "MIT");
    arrCountCaseFail[1] = await countCaseFail(axiosData, "UD");
    arrCountCaseFail[2] = await countCaseFail(axiosData, "LEX");
    arrCountCaseFail[3] = await countCaseFail(axiosData, "S4");
    arrCountCaseFail[4] = await countCaseFail(axiosData, "K100");
    const sumCaseFail = arrCountCaseFail.reduce((sum, number) => {
      return sum + number;
    }, 0);

    const resultDoubel = checkCaseDoubel(axiosData);
    //console.log("QQQQ",Object.keys(resultDoubel));
    const arrCountCaseDoubel = [];
    if (Object.keys(resultDoubel).includes("MIT")) {
      arrCountCaseDoubel[0] = resultDoubel["MIT"];
    } else {
      arrCountCaseDoubel[0] = 0;
    }
    if (Object.keys(resultDoubel).includes("UD")) {
      arrCountCaseDoubel[1] = resultDoubel["UD"];
    } else {
      arrCountCaseDoubel[1] = 0;
    }
    if (Object.keys(resultDoubel).includes("LEX")) {
      arrCountCaseDoubel[2] = resultDoubel["LEX"];
    } else {
      arrCountCaseDoubel[2] = 0;
    }
    if (Object.keys(resultDoubel).includes("S4")) {
      arrCountCaseDoubel[3] = resultDoubel["S4"];
    } else {
      arrCountCaseDoubel[3] = 0;
    }
    if (Object.keys(resultDoubel).includes("K1001")) {
      arrCountCaseDoubel[4] = resultDoubel["K1001"];
    }
    if (Object.keys(resultDoubel).includes("K1002")) {
      arrCountCaseDoubel[5] = resultDoubel["K1002"];
    }
    if (Object.keys(resultDoubel).includes("K1003")) {
      arrCountCaseDoubel[6] = resultDoubel["K1003"];
    }
    if (Object.keys(resultDoubel).includes("K1004")) {
      arrCountCaseDoubel[7] = resultDoubel["K1004"];
    }
    if (Object.keys(resultDoubel).includes("K1005")) {
      arrCountCaseDoubel[8] = resultDoubel["K1005"];
    }
    if (Object.keys(resultDoubel).includes("K1006")) {
      arrCountCaseDoubel[9] = resultDoubel["K1006"];
    }
    if (Object.keys(resultDoubel).includes("K1007")) {
      arrCountCaseDoubel[10] = resultDoubel["K1007"];
    }
    if (Object.keys(resultDoubel).includes("K1008")) {
      arrCountCaseDoubel[11] = resultDoubel["K1008"];
    }
    if (Object.keys(resultDoubel).includes("K1009")) {
      arrCountCaseDoubel[12] = resultDoubel["K1009"];
    }
    const sumExceptFirst = arrCountCaseDoubel.reduce(
      (accumulator, currentValue, index) => {
        if (index >= 4) {
          return accumulator + currentValue;
        } else {
          return accumulator;
        }
      },
      0
    );

    const sumCaseDoubel = arrCountCaseDoubel.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );

    if (locatA === "ทั้งหมด") {
      const sumSum = [
        {
          locat: "MIT",
          caseAll: arrCountCaseAll[0],
          caseSuccess: arrCaseSuccess[0],
          caseWait: arrCountCaseWait[0],
          caseFail: arrCountCaseFail[0],
          caseDoubel: arrCountCaseDoubel[0],
        },
        {
          locat: "UD",
          caseAll: arrCountCaseAll[1],
          caseSuccess: arrCaseSuccess[1],
          caseWait: arrCountCaseWait[1],
          caseFail: arrCountCaseFail[1],
          caseDoubel: arrCountCaseDoubel[1],
        },
        {
          locat: "LEX",
          caseAll: arrCountCaseAll[2],
          caseSuccess: arrCaseSuccess[2],
          caseWait: arrCountCaseWait[2],
          caseFail: arrCountCaseFail[2],
          caseDoubel: arrCountCaseDoubel[2],
        },
        {
          locat: "S4",
          caseAll: arrCountCaseAll[3],
          caseSuccess: arrCaseSuccess[3],
          caseWait: arrCountCaseWait[3],
          caseFail: arrCountCaseFail[3],
          caseDoubel: arrCountCaseDoubel[3],
        },
        {
          locat: "KSM",
          caseAll: arrCountCaseAll[4],
          caseSuccess: arrCaseSuccess[4],
          caseWait: arrCountCaseWait[4],
          caseFail: arrCountCaseFail[4],
          caseDoubel: sumExceptFirst,
        },
        {
          locat: "รวม",
          caseAll: sumCaseAll,
          caseSuccess: sumCaseSuccess,
          caseWait: sumCaseWait,
          caseFail: sumCaseFail,
          caseDoubel: sumCaseDoubel,
        },
      ];
      setData(sumSum);
    } else if (locatA === "MIT") {
      const sumSum = [
        {
          locat: "MIT",
          caseAll: arrCountCaseAll[0],
          caseSuccess: arrCaseSuccess[0],
          caseWait: arrCountCaseWait[0],
          caseFail: arrCountCaseFail[0],
          caseDoubel: arrCountCaseDoubel[0],
        },
      ];
      setData(sumSum);
    } else if (locatA === "UD") {
      const sumSum = [
        {
          locat: "UD",
          caseAll: arrCountCaseAll[1],
          caseSuccess: arrCaseSuccess[1],
          caseWait: arrCountCaseWait[1],
          caseFail: arrCountCaseFail[1],
          caseDoubel: arrCountCaseDoubel[1],
        },
      ];
      setData(sumSum);
    } else if (locatA === "LEX") {
      const sumSum = [
        {
          locat: "LEX",
          caseAll: arrCountCaseAll[2],
          caseSuccess: arrCaseSuccess[2],
          caseWait: arrCountCaseWait[2],
          caseFail: arrCountCaseFail[2],
          caseDoubel: arrCountCaseDoubel[2],
        },
      ];
      setData(sumSum);
    } else if (locatA === "S4") {
      const sumSum = [
        {
          locat: "S4",
          caseAll: arrCountCaseAll[3],
          caseSuccess: arrCaseSuccess[3],
          caseWait: arrCountCaseWait[3],
          caseFail: arrCountCaseFail[3],
          caseDoubel: arrCountCaseDoubel[3],
        },
      ];
      setData(sumSum);
    } else if (locatA === "KSM") {
      const sumSum = [
        {
          locat: "KSM",
          caseAll: arrCountCaseAll[4],
          caseSuccess: arrCaseSuccess[4],
          caseWait: arrCountCaseWait[4],
          caseFail: arrCountCaseFail[4],
          caseDoubel: sumExceptFirst,
        },
      ];
      setData(sumSum);
    }
    //console.log("sumSum", sumSum);
  };

  //ExportPDF
  const conponentPDF = useRef();
  const generatePDF = useReactToPrint({
    content: () => conponentPDF.current,
    documentTitle: "รายงานสรุปเคสที่ดินตามสาขา",
    onAfterPrint: () => alert("Data saved in PDF"),
  });

  return (
    <>
      <center>
        <h1>รายงานสรุปเคสที่ดินตามสาขา</h1>
        <label>สาขา :</label>
        <label style={{ color: "#0D36EE" }}>{locatA}</label>
        <label> วันที่ :</label>
        <label style={{ color: "#0D36EE" }}>{dd1[0]}</label>
        <label> ถึงวันที่ :</label>
        <label style={{ color: "#0D36EE" }}>{dd1[1]}</label>
        <br />
        <br />
      </center>
      <Row justify={"center"}>
        <Form
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Row>
            <Col xs={14} sm={16} md={18} lg={20} xl={24}>
              <InputSelect onChange={handleSelectChange} />
              <RangePicker
                placeholder={["เลือกวันที่", "ถึงวันที่"]}
                style={{ margin: "0 4px 4px" }}
                format="YYYY-MM-DD"
                onChange={(e1, e2) => {
                  onChangeDate(e1, e2);
                }}
              />
              <Button
                type="primary"
                onClick={postData}
                style={{ margin: "0 2px 4px" }}
              >
                ค้นหา
              </Button>
              <Button
                type="primary"
                danger
                onClick={generatePDF}
                style={{ margin: "0 2px 4px" }}
              >
                PDF
              </Button>
              <ExportExcel columns={dotaColums} dataSource={axiosData} />
            </Col>
          </Row>

          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          ></Form.Item>
        </Form>
      </Row>
      <Row ref={conponentPDF} style={{ width: "100%" }} justify={"center"}>
        <style>
          {`@media print {.ant-pagination {display: none !important;}.report-name {display: block;}}           
      @media screen {.report-name {display: none;}}`}
        </style>
        <label className="report-name">รายงานสรุปเคสที่ดินตามสาขา  </label>
        <label className="report-name">สาขา :</label>
        <label className="report-name" style={{ color: "#0D36EE" }}>{locatA}</label>
        <label className="report-name"> วันที่ :</label>
        <label className="report-name" style={{ color: "#0D36EE" }}>{dd1[0]}</label>
        <label className="report-name"> ถึงวันที่ :</label>
        <label className="report-name" style={{ color: "#0D36EE" }}>{dd1[1]}</label>
        <Table
          columns={dotaColums}
          dataSource={axiosData.map((item, index) => ({ ...item, key: index }))}
          style={{ width: "1500px" }}
        />
      </Row>
    </>
  );
}
export default TabelLoanLocat;
