import React, { useState, useEffect } from 'react'
import { Form, Modal, Row, Spin, Space, Button, Divider, Col, Card, Tag, notification, Image } from 'antd'
import axios from 'axios';
import dayjs from 'dayjs';
import { getImagess, resendeditcasereland } from '../../../file_mid/all_api';

export default function Conclusion({ close, closeAll, changepage, page, noteLandRes, dataRe }) {
    const { confirm } = Modal
    const [form] = Form.useForm();
    const [api, contextHolder] = notification.useNotification();
    const token = localStorage.getItem('token');
    const [loading, setLoading] = useState(false)
    const [showImg, setShowImg] = useState([])
    const [noteLandRe] = useState(noteLandRes) // เก็บโน๊ต
    const username = localStorage.getItem('username');

    useEffect(() => {
        if (dataRe?.land?.img?.length > 0) {
            setShowImg(dataRe?.land?.img)
        }
    }, [])

    const showConfirmOK = () => {
        confirm({
            title: 'คุณต้องการส่งเคสอีกครั้ง...?',
            onOk() {
                handleSubmit()
            },
            onCancel() {
            },
        });
    };

    const handleSubmit = async () => {
        setLoading(true)
        if (dataRe?.land?.landLoanDetailsRe?.reLoanId) {
            let sendData = {
                "reLoanId": dataRe?.land?.landLoanDetailsRe?.reLoanId,
                "approvalStatus": 11,
                "ISSUNO": dataRe?.land.ISSUNO,
                "username": username,
                "ProductLoanType": dataRe?.land?.landLoanDetailsRe?.productLoanLandType
            }

            // console.log("sendData", sendData)
            await axios.put(resendeditcasereland, sendData)
                .then(async (res) => {
                    if (res.status === 200) {
                        sendSuccess('top')
                        setLoading(false)
                        setTimeout(() => {
                            closeAll();
                        }, 1000)
                    }
                }).catch((err) => {
                    console.log(err)
                    sendError('top')
                })
            setLoading(false)
        } else {
            alert("การโหลดข้อมูลมีปัญหา กรุณาปิดแล้วเปิดใหม่ครับ/ค่ะ")
        }
    };

    const sendSuccess = (placement) => {
        api.success({
            message: <b>บันทึกสำเร็จ</b>,
            //   description:
            //     'สถานะถูกเปลี่ยนจาก รอธุการรับ เป็น รอพิจารณาแล้ว',
            placement,
        });
    }
    const sendError = (placement) => {
        api.error({
            message: <b>บันทึกไม่สำเร็จ</b>,
            //   description:
            //     'สถานะถูกเปลี่ยนจาก รอธุการรับ เป็น รอพิจารณาแล้ว',
            placement,
        });
    }

    const onChangeBack = () => {
        changepage(page - 1)
    }

    const handleClose = () => {
        close()
    }

    return (
        <div>
            <Row justify={'center'}>
                <Card >
                    <Col style={{ textAlign: 'center' }} >
                        <b><h2>รายละเอียดสัญญา</h2></b>
                    </Col>
                    <Divider style={{ margin: 10 }} />
                    <Spin tip="Loading..." spinning={loading} >
                        <Form
                            name="basic"
                            labelCol={{
                                span: 12,
                            }}
                            wrapperCol={{
                                span: 24,
                            }}

                            initialValues={{
                                remember: true,
                            }}
                            form={form}
                            onFinish={showConfirmOK}
                            autoComplete="off"
                        >
                            <Space
                                direction="vertical"
                                size="middle"
                                style={{
                                    display: 'flex',
                                }}>
                                <Row justify={'center'}>
                                    <Col span={24} style={{ textAlign: 'right' }}>
                                        <div style={{ margin: 0 }}>
                                            สถานะ : <Tag color="geekblue">{dataRe?.land?.landLoanDetailsRe?.approvalStatusTable?.name}</Tag>
                                        </div>
                                        <div style={{ margin: 0 }}>
                                            ประเภทขอกู้ : <Tag color="green">{dataRe?.land?.landLoanDetailsRe?.productLoanLandType}</Tag>
                                        </div>
                                    </Col>
                                </Row>
                                <Row gutter={32} justify={'center'}>
                                    <Divider />
                                    <Row className='main' justify={'center'}>
                                        <Col span={24}>
                                            <aside style={{ width: '100%' }}>
                                                <div className='text-left' style={{ margin: 0 }}>
                                                    <h4><u>รายละเอียด</u></h4>
                                                </div><p />
                                                <n />
                                                <b>
                                                    <div style={{ margin: 0, }}>
                                                        <span>ตัดสด ณ วันที่ : </span>
                                                        <b style={{ fontSize: '18px' }}>
                                                            <u>
                                                                {dayjs(dataRe?.land?.landLoanDetailsRe?.ftime).format("DD-MM-YYYY")}
                                                            </u>
                                                        </b>
                                                    </div>
                                                </b>
                                                <div label='เลขที่สัญญา' name='ISSUNO' style={{ marginBottom: 3 }}>
                                                    <span>เลขที่สัญญา : </span> <b>{dataRe?.land?.ISSUNO}</b>
                                                </div>
                                                <div label='ชื่อ-สกุล' name='snam' style={{ marginBottom: 3 }}>
                                                    <span>ชื่อ-สกุล : </span> <b>{dataRe?.firstname + ' ' + dataRe?.lastname}</b>
                                                </div>
                                                {
                                                    dataRe?.phones?.length > 0 ?
                                                        <div label='เบอร์' name='telp' style={{ marginBottom: 3 }}>
                                                            <span>เบอร์ : </span> <b>{dataRe?.phones[0]?.telp}</b>
                                                        </div>
                                                        : null
                                                }
                                                <div label='เกรด' name='GRDCOD' style={{ marginBottom: 3 }}>
                                                    <span>เกรด : </span> <b> {dataRe?.land?.landLoanDetailsRe?.GRDCOD}</b>
                                                </div>
                                            </aside>
                                            <Divider style={{ margin: 5 }} />
                                            <aside style={{ width: '100%' }}>
                                                <div className='text-left'>
                                                    <h4><u>สินค้า</u></h4>
                                                </div><p />
                                                <n />
                                                <div label='ประเภทเอกสาร' name='' style={{ marginBottom: 3 }}>
                                                    <span>ประเภทเอกสาร : </span>
                                                    <b>
                                                        {
                                                            dataRe?.land?.productTypeLand === 1 && ("นส.3 ก.")
                                                        }
                                                        {
                                                            dataRe?.land?.productTypeLand === 2 && ("นส.4 จ.")
                                                        }
                                                    </b>
                                                </div>
                                                {
                                                    dataRe?.land?.productTypeLand === 1 ?
                                                        <>
                                                            <div label='จังหวัด' name='' style={{ marginBottom: 3 }}>
                                                                <span>จังหวัด : </span><b>{dataRe?.land?.province}</b>
                                                            </div>
                                                            <div label='อำเภอ' name='' style={{ marginBottom: 3 }}>
                                                                <span>อำเภอ : </span><b>{dataRe?.land?.district}</b>
                                                            </div>
                                                            <div label='เลขที่โฉนด/เลขที่' name='' style={{ marginBottom: 3 }}>
                                                                <span>เลขที่โฉนด/เลขที่ : </span><b>{dataRe?.land?.numberLand}</b>
                                                            </div>
                                                            <div label='พื้นที่ไร่' name='' style={{ marginBottom: 3 }}>
                                                                <span>พื้นที่ไร่ : </span><b>{dataRe?.land?.rai}</b>
                                                            </div>
                                                            <div label='พื้นที่งาน' name='' style={{ marginBottom: 3 }}>
                                                                <span>พื้นที่งาน : </span><b>{dataRe?.land?.workArea}</b>
                                                            </div>
                                                            <div label='พื้นที่ตารางวา' name='' style={{ marginBottom: 3 }}>
                                                                <span>พื้นที่ตารางวา : </span><b>{dataRe?.land?.squareWaArea}</b>
                                                            </div>
                                                        </>
                                                        :
                                                        <>
                                                            <div label='จังหวัด' name='' style={{ marginBottom: 3 }}>
                                                                <span>จังหวัด : </span><b>{dataRe?.land?.province}</b>
                                                            </div>
                                                            <div label='อำเภอ' name='' style={{ marginBottom: 3 }}>
                                                                <span>อำเภอ : </span><b>{dataRe?.land?.district}</b>
                                                            </div>
                                                            <div label='เลขที่โฉนด/เลขที่' name='' style={{ marginBottom: 3 }}>
                                                                <span>เลขที่โฉนด/เลขที่ : </span><b>{dataRe?.land?.numberLand}</b>
                                                            </div>
                                                            <div label='ระวาง' name='' style={{ marginBottom: 3 }}>
                                                                <span>ระวาง : </span><b>{dataRe?.land?.numberLandLawang}</b>
                                                            </div>
                                                            <div label='พื้นที่ไร่' name='' style={{ marginBottom: 3 }}>
                                                                <span>พื้นที่ไร่ : </span><b>{dataRe?.land?.rai}</b>
                                                            </div>
                                                            <div label='พื้นที่งาน' name='' style={{ marginBottom: 3 }}>
                                                                <span>พื้นที่งาน : </span><b>{dataRe?.land?.workArea}</b>
                                                            </div>
                                                            <div label='พื้นที่ตารางวา' name='' style={{ marginBottom: 3 }}>
                                                                <span>พื้นที่ตารางวา : </span><b>{dataRe?.land?.squareWaArea}</b>
                                                            </div>
                                                            <div label='ราคาประเมินที่ดิน' name='' style={{ marginBottom: 3 }}>
                                                                <span>ราคาประเมินที่ดิน : </span><b>{dataRe?.land?.landPrice}</b>
                                                            </div>
                                                            <div label='ราคารวม' name='' style={{ marginBottom: 3 }}>
                                                                <span>ราคารวม : </span><b>{dataRe?.land?.resultLandPrice}</b>
                                                            </div>
                                                        </>
                                                }
                                            </aside>
                                            <Divider style={{ margin: 5 }} />
                                            <aside style={{ width: '100%' }}>
                                                <b>
                                                    <div style={{ margin: 0, }}>
                                                        <span>ใบนี้หมดอายุ ณ วันที่ : </span>
                                                        <b style={{ color: 'red', fontSize: '20px' }}><u>{dataRe?.land?.landLoanDetailsRe?.newOfferDate ? dayjs(dataRe?.land?.landLoanDetailsRe?.newOfferDate).format("DD-MM-YYYY") : "-"}</u></b>
                                                    </div>
                                                </b>
                                            </aside>
                                        </Col>
                                    </Row>
                                    <Divider style={{ margin: 5 }} />
                                    {
                                        noteLandRe?.length > 0 ?
                                            <>
                                                <Card>
                                                    <aside style={{ width: '100%' }}>
                                                        <div className='text-left'>
                                                            <h4><u>หมายเหตุคำร้อง</u></h4>
                                                        </div><p />
                                                        <n />
                                                        {noteLandRe?.map((e, index) => {
                                                            return (
                                                                <>
                                                                    <div label='สาเหตุ' name='cause' style={{ marginBottom: 3 }}>
                                                                        <span>สาเหตุ : </span><b style={{ color: 'red', fontSize: '16px' }}>{e?.cause}</b>
                                                                    </div>
                                                                    <div label='หมายเหตุ' name='note' style={{ marginBottom: 3 }}>
                                                                        <span>หมายเหตุ : </span><b style={{ color: 'red', fontSize: '16px' }}>{e?.note}</b>
                                                                    </div>
                                                                    <div label='สถานะที่ทำหมายเหตุ' name='status' style={{ marginBottom: 3 }}>
                                                                        <span>สถานะที่ทำหมายเหตุ : </span><b style={{ color: 'red', fontSize: '16px' }}>{e?.status}</b>
                                                                    </div>
                                                                    <Divider style={{ margin: 5 }} />
                                                                </>
                                                            )
                                                        })}
                                                    </aside>
                                                </Card>
                                                <Divider style={{ margin: 5 }} />
                                            </>
                                            : null
                                    }
                                    {
                                        showImg?.length > 0 ?
                                            <>
                                                <Card>
                                                    <aside style={{ width: '100%' }}>
                                                        <div className='text-left'>
                                                            <h4><u>รูปภาพ</u></h4>
                                                        </div><p />
                                                        <n />
                                                        {showImg?.map((e, index) => {
                                                            return (
                                                                <>
                                                                    {
                                                                        e.type === 14 ?
                                                                            <Image width={'100px'} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                            : null
                                                                    }
                                                                </>
                                                            )
                                                        })}
                                                    </aside>
                                                </Card>
                                                <Divider style={{ margin: 5 }} />
                                            </>
                                            : null
                                    }
                                    <Divider />
                                    <Row justify={'center'}>
                                        <Space>
                                            <Button type="text" onClick={onChangeBack} style={{ background: "#bfbfbf", color: 'white' }} >
                                                ย้อนกลับ
                                            </Button>
                                            <Button type="primary" style={{ background: "red" }} onClick={handleClose}>
                                                ยกเลิก
                                            </Button>
                                            {
                                                loading === false ?
                                                    <>
                                                        <Button type="text" htmlType="submit" style={{ background: '#389e0d', color: '#ffffff' }} >
                                                            ส่งเคสอีกครั้ง
                                                        </Button>
                                                    </> :
                                                    <>
                                                        <Button type='primary' htmlType="submit" disabled
                                                            style={{ color: 'success' }}
                                                        >
                                                            ส่งเคสอีกครั้ง
                                                        </Button>
                                                    </>
                                            }
                                        </Space>
                                    </Row>
                                </Row>
                            </Space>
                        </Form>
                    </Spin>
                </Card>
                {contextHolder}
            </Row>
        </div >
    )
}
