import React, { useState, useEffect } from 'react'
import { Form, Input, Row, Col, Button, Select, Divider, Space, Modal, Spin, Card, Upload, InputNumber, message, notification } from 'antd'
import axios from 'axios'
import dayjs from 'dayjs';
import { PlusOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import Currency from 'currency.js';
import TextArea from 'antd/es/input/TextArea';
import Compressor from 'compressorjs';
import { productLoanTypeLand } from '../../file_mid/all_options';
import { AllData, GetAmphur1, GetProvince, PostProvince1, editlandloan, mutips4, mutips4pre } from '../../file_mid/all_api';

export default function EditLLD({ getData, close, sendback, notPassData, saveResendNote, setSaveResendNote, checkPreNotPass, newDataLand, setCount }) {

    const [api, contextHolder] = notification.useNotification();
    const [notPassData2, setNotPassData2] = useState(notPassData)
    // const dispatch = useDispatch()
    const { Option } = Select;
    const [form] = Form.useForm()
    // const token = localStorage.getItem('token');
    // const user = localStorage.getItem('username');
    const [loading, setLoading] = useState(false)

    const [dataCusId, setDataCusId] = useState(getData.customerId)
    const [dataLand, setDataLand] = useState(getData?.lands)
    const [dataLoan, setDataLoan] = useState(getData?.lands?.landLoanDetails)
    const [dataOldLoan, setDataOldLoan] = useState(getData?.lands?.landOldLoanDetails)

    const [pvcodeS, setPvcodeS] = useState({});
    const [amcodeS, setAmcodeS] = useState({});

    const [provinces, setProvinces] = useState([]);
    const [district, setDistricts] = useState([]);

    ///////////////// อัปโหลดรูป //////////////////////////////////////
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState('');

    const [checkImage, setCheckImage] = useState();

    const [fileListDeed, setFileListDeed] = useState([]);
    const [fileListLeafDivider, setFileListLeafDivider] = useState([]);
    const [fileListLand, setFileListLand] = useState([]);
    const [fileListBurden, setFileListBurden] = useState([]);

    // const [fileListGuarantorCard, setFileListGuarantorCard] = useState([]);
    /*เก็บรูปทั้งหมด*/
    const [allImg, setAllImg] = useState([]);
    const newFormData = new FormData();
    const [resultImage, setResultImage] = useState([]);

    const [resendNote, setResendNote] = useState();

    useEffect(() => {
        getProvinces()
        GetAmphur(getData?.lands?.district)
        PostProvince(getData?.lands?.provinces)
        form.setFieldsValue(
            {
                ...getData,
                ...getData?.lands,
                ...getData?.lands?.landLoanDetails,
                ...getData?.lands?.landOldLoanDetails,
                ...notPassData,
            }
        )
        if (saveResendNote.customerNote) {
            setResendNote(saveResendNote)
            form.setFieldsValue({
                ...saveResendNote
            })
        }
    }, [])


    useEffect(() => {
        land_Calculate()
    }, [dataLoan.loanLandTerm, dataLoan.loanAmountLand])



    const handleSubmit = async () => {
        const MixData = { ...dataOldLoan, ...dataLoan, ...dataLand, customerId: dataCusId, TypeLoan: "land" }
        // console.log("MixData", MixData)
        // console.log("dataOldLoan", dataOldLoan)
        // console.log("dataLoan", dataLoan)
        // console.log("dataLand", dataLand)
        await axios.post(editlandloan, MixData)
            .then(async res => {
                if (res.status === 200) {
                    if (allImg?.length > 0) {
                        await imgSend(allImg)
                    } else {
                        sendback(dayjs())
                        setLoading(false)
                    }
                    handleSendNote()
                    SuccSend("top")
                } else {
                }
            }).catch((err) => console.log(err))
    }

    const handleSendNote = async () => {
        if (resendNote !== undefined) {
            // console.log("!= undefined")
            if (resendNote.productNote !== "") {
                // console.log("if")
                setSaveResendNote({ ...saveResendNote, ...resendNote })
            } else {
                // console.log("else")
                setSaveResendNote({ ...saveResendNote, productNote: null })
            }
        } else {
            // console.log("= undefined")
        }
    };


    const imgSend = (resultImgs) => {
        return new Promise(async (resolve, reject) => {
            try {
                if (checkPreNotPass) {
                    var status;
                    // newFormData.append('idcus', getData?.lands?.landId);
                    //newFormData.append('productTypeId', "1");
                    newFormData.append('tm', dayjs().format("YYYY-MM-DDTHH:mm:ssZ"));

                    for (const file of resultImgs) {
                        var image = {}
                        newFormData.append('file', file.imageCompressedFile);
                        newFormData.append("type", file.type);
                        newFormData.append('idcus', file?.imgid);
                        image.type = file.type
                        resultImage.push(image)
                    }
                    const response = await axios.post(mutips4pre, newFormData);
                    if (response.data) {
                        console.log("TY Pre", response.data);
                        setFileListDeed([])
                        setFileListLeafDivider([])
                        setFileListLand([])
                        setFileListBurden([])
                        setAllImg([])
                        status = response.status;
                        resolve(status);
                    }
                } else {
                    var status;
                    // newFormData.append('idcus', getData?.lands?.landId);
                    //newFormData.append('productTypeId', "1");
                    newFormData.append('tm', dayjs().format("YYYY-MM-DDTHH:mm:ssZ"));

                    for (const file of resultImgs) {
                        var image = {}
                        newFormData.append('file', file.imageCompressedFile);
                        newFormData.append("type", file.type);
                        newFormData.append('idcus', file.imgid)
                        image.type = file.type
                        resultImage.push(image)
                    }
                    const response = await axios.post(mutips4, newFormData);
                    if (response.data) {
                        console.log("TY", response.data);
                        setFileListDeed([])
                        setFileListLeafDivider([])
                        setFileListLand([])
                        setFileListBurden([])
                        setAllImg([])
                        status = response.status;
                        resolve(status);
                    }
                }
            } catch (error) {
                error(error);
                reject(error);
            } finally {
                //setLoading(false);

                console.log("OK")
                SuccImg("top")
                sendback(dayjs())
                setCount(6)
            }
        });
    };

    const SuccImg = (placement) => {
        api.success({
            message: <b>เพิ่มรูปสำเร็จ</b>,
            // description: `กรุณาเพิ่มที่อยู่ผู้ค้ำ ลำดับที่ ${index + 1}`,
            placement,
        });
    }
    const SuccSend = (placement) => {
        api.success({
            message: <b>แก้ไขข้อมูลสำเร็จ</b>,
            // description: `กรุณาเพิ่มที่อยู่ผู้ค้ำ ลำดับที่ ${index + 1}`,
            placement,
        });
    }

    /////////////// ที่อยู่ ของ ที่ดิน //////////////////
    const getProvinces = async () => {
        setLoading(true)
        await axios.get(GetProvince)
            .then((res) => {
                //console.log("Province =", res.data)
                setProvinces(res.data)
                setLoading(false)
            }).catch((err) => {
                console.error(err);
            })
        setLoading(false)
    }

    const PostProvince = async (value) => {
        const pr = { pvcode: value }
        setLoading(true)
        await axios.post(PostProvince1, pr)
            .then((res) => {
                setPvcodeS(res.data[0].pvcode)
                setLoading(false)
            }).catch((err) => {
                console.error(err);
            })
        setLoading(false)
    }

    const GetAmphur = async (value) => {
        const am = { pvcode: value }
        setLoading(true)
        await axios.post(GetAmphur1, am)
            .then((res) => {
                setDistricts(res.data)
                setAmcodeS(res.data[0].amcode)
                setLoading(false)
            }).catch((err) => {
                console.error(err);
            })
        setLoading(false)
    }

    const handleProvinceChange = async (value, key) => {
        setPvcodeS(key["key"])
        setDataLand({ ...dataLand, provinces: value });
        form.setFieldsValue(
            {
                numberLand: "",
                numberLandLawang: "",
                rai: "",
                workArea: "",
                squareWaArea: "",
                landPrice: "",
                resultLandPrice: "",
                district: "",
            }
        )
        setLoading(true)
        const test = { pvcode: key["key"] }
        await axios.post(GetAmphur1, test)
            .then((res) => {
                setDistricts(res.data)
                setLoading(false)
            }).catch((err) => {
                console.error(err);
            })
        setLoading(false)
    };

    const handleDistrictChange = (value, key) => {
        setAmcodeS(key["key"])
        setDataLand({ ...dataLand, district: value });
        form.setFieldsValue(
            {
                numberLand: "",
                numberLandLawang: "",
                rai: "",
                workArea: "",
                squareWaArea: "",
                landPrice: "",
                resultLandPrice: "",
            }
        )

    };

    ///////////////// กรณี นส.3 ///////////////////////////

    const handleProvinceChange1 = async (value, key) => {
        setPvcodeS(key["key"])
        setDataLand({ ...dataLand, provinces: value });
        form.setFieldsValue(
            {
                numberLandLawang: "",
                rai: "",
                workArea: "",
                squareWaArea: "",
                district: "",
            }
        )
        setLoading(true)
        const test = { pvcode: key["key"] }
        await axios.post(GetAmphur1, test)
            .then((res) => {
                setDistricts(res.data)
                setLoading(false)
            }).catch((err) => {
                console.error(err);
            })
        setLoading(false)
    };

    const handleDistrictChange2 = (value, key) => {
        setAmcodeS(key["key"])
        setDataLand({ ...dataLand, district: value });
        form.setFieldsValue(
            {
                numberLandLawang: "",
                rai: "",
                workArea: "",
                squareWaArea: "",
            }
        )

    };

    /////////////////// เลือก 1 = นส.3 กับ 2 = นส.4 //////////////////////
    const handleProductLand = (value) => {
        console.log("landType =", value)
        if (value === 1) {

            setDataLand({
                ...dataLand,
                productTypeLand: value,
                numberLand: "-",
                rai: 0,
                workArea: 0,
                squareWaArea: 0,
                landPrice: 0,
                resultLandPrice: 0,
                landPriceStatus: false,
                subdistrict: null,
            })
            // setFileListResult2([]);
            // setFileListResult1([]);
            form.setFieldsValue(
                {
                    numberLand: "",
                    numberLandLawang: "",
                    rai: "",
                    workArea: "",
                    squareWaArea: "",
                    landPrice: "",
                    resultLandPrice: "",
                    provinces: "",
                    district: "",
                }
            )
        } else {
            setDataLand({
                ...dataLand,
                productTypeLand: value,
                numberLand: "-",
                rai: 0,
                workArea: 0,
                squareWaArea: 0,
                landPrice: 0,
                resultLandPrice: 0,
                landPriceStatus: false,
            })
            // setFileListResult2([]);
            // setFileListResult1([]);
            form.setFieldsValue(
                {
                    numberLand: "",
                    numberLandLawang: "",
                    rai: "",
                    workArea: "",
                    squareWaArea: "",
                    landPrice: "",
                    resultLandPrice: "",
                    provinces: "",
                    district: "",
                }
            )
        }
    }

    ////////////// ที่ดิน /////////////////////
    const handleChangeLandType = (value) => {
        if (value === 'ย้ายไฟแนนซ์' || value === 'Pre-Aaprove-ย้ายไฟแนนซ์') {
            setDataLoan({ ...dataLoan, productLoanLandType: value })
            setNotPassData2({ ...notPassData2, npOldLoan: false })
            // setDataLoan({ ...dataLoan, productLoanLandType: value, productType: "ที่ดิน" })
        }
        else {
            setDataLoan({ ...dataLoan, productLoanLandType: value })
            setNotPassData2({ ...notPassData2, npOldLoan: true })
            // setDataLoan({ ...dataLoan, productLoanLandType: value, productType: "ที่ดิน" })
            //setMoneyOldLand("")
            form.setFieldsValue(
                {
                    loanAmountLand: '',
                    loanLandTerm: '',
                    // interestRateLand: '1.1',
                    interesttLand: '',
                    monthlyPaymentLand: '',
                    installmentWithInterestLand: '',
                    oldLandLoanAmount: '',
                    oldLandMonthlyPayment: '',
                    oldLandLoanTerm: '',
                    oldLandKangPayment: '',
                    oldLandKangPaymentTerm: '',
                }
            )
        }
    }

    ///////////////// อัปโหลดรูปภาพ //////////////////////////////
    const handleLand = async ({ fileList }) => {

        for (const removedFile of fileListLand.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 1)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 1));
                setAllImg(updatedAllImg);
            }
        }
        setFileListLand(fileList);
        setLoading(false)
    };

    const handleBurden = async ({ fileList }) => {

        for (const removedFile of fileListBurden.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 11)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 11));
                setAllImg(updatedAllImg);
            }
        }
        setFileListBurden(fileList)
        setLoading(false)
    };


    const handleCancel1 = () => {
        close();
    }
    const handleCancel = () => {
        setPreviewVisible(false);
    }
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);
    };


    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };

    const errorLand = () => {
        setDataLand({
            ...dataLand,
            numberLandLawang: null,
            rai: null,
            workArea: null,
            squareWaArea: null,
            landPrice: 0,
            resultLandPrice: 0,
            landPriceStatus: false
        });
        form.setFieldsValue(
            {
                numberLandLawang: "",
                rai: "",
                workArea: "",
                squareWaArea: "",
                landPrice: 0,
                resultLandPrice: 0,
            }
        )
        Modal.error({
            title: 'ไม่พบข้อมูล',
            content: 'กรุณาตรวจสอบข้อมูลที่กรอกอีกครั้ง',
        })
    }

    const handleCheckLand = async () => {
        // console.log("pvcodeS =", pvcodeS)
        // console.log("amcodeS =", amcodeS)
        //const tokens = "eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6IjEiLCJyZWdpc3Rlcl90eXBlX3NlcSI6IjgiLCJ1c2VyX2dyb3VwX2RhdGFfbGlzdCI6IjEsMSwwLDAsMCwwLDAsMSIsInBlcnNvbmFsX2lkIjoiIiwianRpIjoiOTllYmE2OTAtMGFmOC00M2NhLTg2OTktYWUwZDI4N2ZjY2EzIiwiaWF0IjoiMDkvMjcvMjAyMyAwMzoyMDoxNSIsIm5iZiI6MTY5NTc4NDgxNSwiZXhwIjoxNjk1ODcxMjE1LCJpc3MiOiJodHRwczovL2xvY2FsaG9zdDozMDAwMS8iLCJhdWQiOiJMYW5kc01hcHMifQ.Lw3h4tjSbzjscWqkuA9FutwzXPY8UEb49D4ZdsdqKRfR0fwFunY7i4pg5Rdc70Vrw8jDR1qhtQLn5SEqxPguoQ"
        var provids = pvcodeS
        var amphurs = amcodeS
        var landNos = dataLand.numberLand
        var result = { pvcode: provids, amcode: amphurs, landNo: landNos }
        //console.log("result =", result)
        const headers = {
            "Content-Type": "application/json",
            // "Authorization": `Bearer ${tokens}`,
        }
        setLoading(true)
        await axios.post(AllData, result, { headers: headers })
            .then((response) => {
                if (response.data) {
                    // console.log("DATA =", response.data.result)
                    // console.log("ไร่ =", parseInt(response.data.result[0].rai))
                    // console.log("งาน =", parseInt(response.data.result[0].ngan))
                    // console.log("ตารางวา =", parseInt(response.data.result[0].wa + "." + response.data.result[0].subwa))
                    // console.log("จังหวัด =", response.data.result[0].provname)
                    // console.log("อำเภอ =", response.data.result[0].amphurname)
                    // console.log("ละติจูด =", response.data.result[0].parcellat)
                    // console.log("ลองติจูด =", response.data.result[0].parcellon)
                    // console.log("เลขระวาง =", response.data.result[0].utm)
                    // console.log("ราคาต่อตารางวา =", parseInt(response.data.result[0].landprice.replace(/,/g, "")))
                    const squareWaArea = parseFloat(response.data.result[0].wa + "." + response.data.result[0].subwa)
                    const landPricePerWa = parseInt(response.data.result[0].landprice.replace(/,/g, ""))
                    const totalWa = (
                        ((parseInt(response.data?.result[0].rai) * 400)) +
                        ((parseInt(response.data?.result[0].ngan) * 100)) +
                        parseFloat(response.data?.result[0].wa + "." + response.data?.result[0].subwa)
                    )
                    const totalPrice = Currency(totalWa * landPricePerWa).value
                    //console.log("รวมตารางวาทั้งหมด =", totalPrice.toFixed(1));
                    if (landPricePerWa > 0) {
                        setDataLand({
                            ...dataLand,
                            subdistrict: response.data?.result[0].tumbolname,
                            numberLandLawang: response.data?.result[0].utm,
                            rai: parseInt(response.data?.result[0].rai),
                            workArea: parseInt(response.data?.result[0].ngan),
                            squareWaArea: parseFloat(squareWaArea),
                            landPrice: parseInt(response.data.result[0].landprice.replace(/,/g, "")) || 0,
                            resultLandPrice: totalPrice || 0,
                            landPriceStatus: true
                        });
                    } else {
                        setDataLand({
                            ...dataLand,
                            subdistrict: response.data?.result[0].tumbolname,
                            numberLandLawang: response.data?.result[0].utm,
                            rai: parseInt(response.data?.result[0].rai),
                            workArea: parseInt(response.data?.result[0].ngan),
                            squareWaArea: parseFloat(squareWaArea),
                            landPrice: parseInt(response.data.result[0].landprice.replace(/,/g, "")) || 0,
                            resultLandPrice: totalPrice || 0,
                            landPriceStatus: false
                        });
                    }
                    form.setFieldsValue(
                        {
                            numberLandLawang: response.data?.result[0].utm,
                            rai: parseInt(response.data?.result[0].rai),
                            workArea: parseInt(response.data?.result[0].ngan),
                            squareWaArea: squareWaArea,
                            landPrice: currencyFormatOne(parseInt(response.data.result[0].landprice.replace(/,/g, ""))) || 0,
                            resultLandPrice: currencyFormatOne(totalPrice) || 0,
                        }
                    )
                    setLoading(false)
                } else {
                    console.log("DATA =", response.data)
                }
            })
            .catch((err) => errorLand())
            .finally(() => {
                setLoading(false);
            })
        setLoading(false)
    }

    const land_Calculate = () => {
        // console.log("AA")
        // console.log("dataLoan.loanAmounttLand", dataLoan.loanAmountLand)
        // console.log("dataLoan.interestRateLand", dataLoan.interestRateLand)
        // console.log("dataLoan.loanLandTerm", dataLoan.loanLandTerm)


        if (dataLoan?.loanAmountLand && dataLoan?.interestRateLand && dataLoan?.loanLandTerm) {
            // console.log("BB")
            var interestRateLands = dataLoan?.interestRateLand / 100 // อัตราดอก / 100
            var rate = ((parseFloat(dataLoan?.loanAmountLand) * interestRateLands) * dataLoan?.loanLandTerm) // (ยอดจัด * อัตราดอกที่หาร100แล้ว ,precision: 3 คือทศนิยม 3 )*งวด
            var loanTerm = Math.ceil(Currency((rate + parseFloat(dataLoan?.loanAmountLand)) / dataLoan?.loanLandTerm, { precision: 2 }).value) // (ดอกเบี้ยทั้งหมด + ยอดจัด)/ งวด
            var resultRate = (loanTerm * dataLoan?.loanLandTerm) - parseFloat(dataLoan?.loanAmountLand)
            var resultPrice = loanTerm * dataLoan?.loanLandTerm

            // console.log("interestRateLand", interestRateLands)
            // console.log("rate", rate)
            // console.log("loanTerm", loanTerm)
            // console.log("resultRate", resultRate)
            // console.log("resultPrice", resultPrice)
            setDataLoan({
                ...dataLoan,
                //loanLandTerm: parseInt(event.target.value), //งวด
                monthlyPaymentLand: loanTerm,
                interestLand: resultRate,
                installmentWithInterestLand: resultPrice
            })
            if (dataLoan.loanAmountLand >= 50000 && dataLoan.loanAmountLand <= 99999) {
                var feeLoan7 = dataLoan.loanAmountLand * 0.07 // 7%
                //console.log("7%")
                if (feeLoan7 <= 5000) {
                    form.setFieldsValue(
                        {
                            fee: Currency(feeLoan7, { precision: 2 }).value,
                            monthlyPaymentLand: loanTerm,
                            interestLand: resultRate,
                            installmentWithInterestLand: resultPrice
                        }
                    )
                } else {
                    form.setFieldsValue(
                        {
                            fee: 5000,
                            monthlyPaymentLand: loanTerm,
                            interestLand: resultRate,
                            installmentWithInterestLand: resultPrice
                        }
                    )
                }
            }
            if (dataLoan.loanAmountLand >= 100000 && dataLoan.loanAmountLand <= 999999) {
                var feeLoan5 = dataLoan.loanAmountLand * 0.05 // 5%
                //console.log("5%")
                form.setFieldsValue(
                    {
                        fee: Currency(feeLoan5, { precision: 2 }).value,
                        monthlyPaymentLand: loanTerm,
                        interestLand: resultRate,
                        installmentWithInterestLand: resultPrice
                    }
                )
            }
            if (dataLoan.loanAmountLand >= 1000000) {
                //console.log("3%")
                var feeLoan3 = dataLoan.loanAmountLand * 0.03 // 3%
                form.setFieldsValue(
                    {
                        fee: Currency(feeLoan3, { precision: 2 }).value,
                        monthlyPaymentLand: loanTerm,
                        interestLand: resultRate,
                        installmentWithInterestLand: resultPrice
                    }
                )
            }
            // form.setFieldsValue(
            //   {
            //     monthlyPaymentLand: loanTerm,
            //     interesttLand: resultRate,
            //     installmentWithInterestLand: resultPrice
            //   }
            // )
        }
        else {
            form.setFieldsValue(
                {
                    fee: 0,
                    monthlyPaymentLand: 0,
                    interesttLand: 0,
                    installmentWithInterestLand: 0
                }
            )
        }
    }

    const currencyFormatOne = (amount) => {
        return Number(amount)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, '$&,')
    }

    const handleDeed = async ({ fileList }) => {
        for (const removedFile of fileListDeed.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 3)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 3));
                setAllImg(updatedAllImg);
            }
        }
        setFileListDeed(fileList);
        setLoading(false)
    };

    const handleLeafDivider = async ({ fileList }) => {
        for (const removedFile of fileListLeafDivider.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 10)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 10));
                setAllImg(updatedAllImg);
            }
        }
        setFileListLeafDivider(fileList);
        setLoading(false)
    };

    const handleCancelPreview = () => {
        setPreviewVisible(false);
    }

    const beforeUpts = async (file, ty, landId) => {
        //console.log("file", file)
        try {
            setLoading(true)
            const compressedFile = await compressImage(file, ty, landId);
            return Promise.resolve(compressedFile);
        } catch (error) {
            console.error('Image compression error:', error);
            message.error('Image compression failed.');
            return Promise.reject(); // เปลี่ยนให้ return Promise.reject() เมื่อไม่สามารถบีบอัดได้
        }
    };


    const compressImage = async (ez, types, landId) => {
        var image = {};
        return new Promise(async (resolve) => {
            if (types !== 7) {
                if (ez.size > 4000000) {
                    console.log("4m ไม่ให้ส่ง");
                    resolve(true);  // บอกให้ระบบทราบว่ากระบวนการเสร็จสิ้น
                } else {
                    let quality;
                    if (ez.size >= 2500000 && ez.size <= 4000000) {
                        quality = 0.1;
                    } else if (ez.size >= 2000000 && ez.size <= 2499999) {
                        quality = 0.15;
                    } else if (ez.size >= 1500000 && ez.size <= 1999999) {
                        quality = 0.2;
                    } else if (ez.size >= 1000000 && ez.size <= 1499999) {
                        quality = 0.3;
                    } else {
                        quality = 0.5;
                    }

                    try {
                        // ใช้ Promise เพื่อบีบอัดรูปภาพ
                        const compressedFile = await new Promise((resolveCompress) => {
                            new Compressor(ez, {
                                quality: quality,
                                success: (result) => {
                                    resolveCompress(result);
                                },
                            });
                        });

                        const fileOptions = {
                            type: compressedFile.type,
                            lastModified: Date.now(),
                        };
                        const compressedFileAsFile = new File([compressedFile], ez.name, fileOptions);
                        image.imageCompressedFile = compressedFileAsFile;
                        image.type = types
                        image.imgid = landId
                        allImg.push(image);
                        //setAllImg((prevAllImg) => [...prevAllImg, image]);

                        // ตรงนี้คือการ resolve ไฟล์ที่ถูกบีบอัด
                        resolve(image);
                    } catch (error) {
                        console.error('Image compression error:', error);
                        resolve(true);  // บอกให้ระบบทราบว่ากระบวนการเสร็จสิ้น
                    }
                }
            } else {
                // ... (โลจิกสำหรับ type 7)
                image.imageCompressedFile = ez;
                image.type = types
                image.imgid = landId
                allImg.push(image);
                //setAllImg((prevAllImg) => [...prevAllImg, image]);
                resolve(true);  // บอกให้ระบบทราบว่ากระบวนการเสร็จสิ้น
            }
        });
    };

    const renderLandBuildings = ({ item, index }) => { //render Land Array
        return (
            <>
                <Row justify={'center'}><b><u>ใบประเมิน/รูปที่ดิน/สิ่งปลูกสร้าง-ที่ดินปัจจุบัน ({item.numberLand})</u> (ไม่เกิน 4 MB ต่อรูป)</b></Row>
                <Row gutter={32} justify={'center'}>
                    <Space direction="vertical">
                        <div className='gutter-row' span={12}>
                            <b> <Form.Item name='imageIDGuCar'
                            >
                                <Upload
                                    name='imageIDGuCar'
                                    multiple={true}
                                    listType="picture-card"
                                    // fileList={fileListLand}
                                    onPreview={handlePreview}
                                    onChange={handleLand}
                                    beforeUpload={(file) => beforeUpts(file, 1, item.landId).then(() => false).catch(() => true)}
                                >
                                    {/* {fileListLand?.length >= 15 ? null : ( */}
                                    <div>
                                        <PlusOutlined />
                                        <div className="ant-upload-text">upload</div>
                                    </div>
                                    {/* )} */}
                                </Upload>
                                <Modal open={previewVisible} onCancel={handleCancelPreview}>
                                    <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                </Modal>
                            </Form.Item>
                            </b>
                        </div>
                    </Space>
                </Row>
                <Divider />
            </>
        )
    }
    const renderTitleDeed = ({ item, index }) => { //render Land Array
        return (
            <>
                <Row justify={'center'}><b><u>รูปโฉนด/หน้า-หลัง ({item.numberLand})</u> (ไม่เกิน 4 MB ต่อรูป)</b></Row>
                <Row gutter={32} justify={'center'}>
                    <Space direction="vertical">
                        <div className='gutter-row' span={12}>
                            <b> <Form.Item name='imageIDGuCar'>
                                <Upload
                                    name='imageIDGuCar'
                                    multiple={true}
                                    listType="picture-card"
                                    // fileList={fileListDeed}
                                    onPreview={handlePreview}
                                    onChange={handleDeed}
                                    beforeUpload={(file) => beforeUpts(file, 3, item.landId).then(() => false).catch(() => true)}
                                >
                                    {/* {fileListDeed?.length >= 5 ? null : ( */}
                                    <div>
                                        <PlusOutlined />
                                        <div className="ant-upload-text">upload</div>
                                    </div>
                                    {/* )} */}
                                </Upload>
                                <Modal open={previewVisible} onCancel={handleCancelPreview}>
                                    <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                </Modal>
                            </Form.Item>
                            </b>
                        </div>
                    </Space>
                </Row>
                <Divider />
            </>
        )
    }
    const renderDivision = ({ item, index }) => { //render Land Array
        return (
            <>
                <Row justify={'center'}><b><u>ใบแบ่งใบต่อ ({item.numberLand})</u> (ไม่เกิน 4 MB ต่อรูป)</b></Row>
                <Row gutter={32} justify={'center'}>
                    <Space direction="vertical">
                        <div className='gutter-row' span={12}>
                            <b> <Form.Item name='imageIDGuCar'>
                                <Upload
                                    name='imageIDGuCar'
                                    multiple={true}
                                    listType="picture-card"
                                    // fileList={fileListLeafDivider}
                                    onPreview={handlePreview}
                                    onChange={handleLeafDivider}
                                    beforeUpload={(file) => beforeUpts(file, 10, item.landId).then(() => false).catch(() => true)}
                                >
                                    {/* {fileListLeafDivider?.length >= 5 ? null : ( */}
                                    <div>
                                        <PlusOutlined />
                                        <div className="ant-upload-text">upload</div>
                                    </div>
                                    {/* )} */}
                                </Upload>
                                <Modal open={previewVisible} onCancel={handleCancelPreview}>
                                    <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                </Modal>
                            </Form.Item>
                            </b>
                        </div>
                    </Space>
                </Row>
                <Divider />
            </>
        )
    }
    const renderLawang = ({ item, index }) => { //render Land Array
        return (
            <>
                <Row justify={'center'}><b><u>ระวาง ({item.numberLand})</u> (ไม่เกิน 4 MB ต่อรูป)</b></Row>
                <Row gutter={32} justify={'center'}>
                    <Space direction="vertical">
                        <div className='gutter-row' span={12}>
                            <b> <Form.Item name='imageIDGuCar'>
                                <Upload
                                    name='imageIDGuCar'
                                    multiple={true}
                                    listType="picture-card"
                                    // fileList={fileListBurden}
                                    onPreview={handlePreview}
                                    onChange={handleBurden}
                                    beforeUpload={(file) => beforeUpts(file, 11, item.landId).then(() => false).catch(() => true)}
                                >
                                    {/* {fileListBurden?.length >= 5 ? null : ( */}
                                    <div>
                                        <PlusOutlined />
                                        <div className="ant-upload-text">upload</div>
                                    </div>
                                    {/* )} */}
                                </Upload>
                                <Modal open={previewVisible} onCancel={handleCancelPreview}>
                                    <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                </Modal>
                            </Form.Item>
                            </b>
                        </div>
                    </Space>
                </Row>
                <Divider />
            </>
        )
    }


    return (
        <Row justify={'center'}>
            <Row justify={'center'} style={{ margin: '5px', fontSize: '18px' }} >รายละเอียดสินค้า</Row>
            <Card style={{ width: '100%' }}>
                <Spin spinning={loading} size='large' tip=" Loading... ">
                    <Form
                        labelCol={{
                            span: 10,
                        }}
                        wrapperCol={{
                            span: 24,
                        }}
                        style={{
                            width: '100%',
                        }}
                        form={form}
                        onFinish={handleSubmit}
                        autoComplete="off"
                        initialValues={{ remember: true }}>
                        <Row className='inputaside' gutter={18} justify={'center'}>
                            <div style={{ margin: 5 }}>
                                {
                                    notPassData2?.npProduct === false ?
                                        <Form.Item label="หมายเหตุ(ธุรการ) รถ" name="npProductNote">
                                            <TextArea rows={5} name='npProductNote' disabled />
                                        </Form.Item>
                                        : null
                                }
                                {
                                    notPassData2?.npLoan === false ?
                                        <Form.Item label="หมายเหตุ(ธุรการ) ยอดกู้" name="npLoanNote">
                                            <TextArea rows={5} name='npLoanNote' disabled />
                                        </Form.Item>
                                        : null
                                }
                                {
                                    notPassData2?.npOldLoan === false ?
                                        <Form.Item label="หมายเหตุ(ธุรการ) ย้ายไฟแแนนซ์" name="npOldLoanNote">
                                            <TextArea rows={5} name='npOldLoanNote' disabled />
                                        </Form.Item>
                                        : null
                                }
                                <Form.Item label="หมายเหตุ(การตลาด)" name="productNote">
                                    <TextArea rows={5} name='productNote' placeholder='หมายเหตุถึงธุรการ' onChange={(e) => setResendNote({ productNote: e.target.value })} />
                                </Form.Item>
                            </div>
                        </Row>
                        <Divider />
                        {
                            !checkPreNotPass ?
                                <>
                                    <Row className='inputaside' gutter={18} justify={'center'}>
                                        <div style={{ margin: 5 }}>
                                            <Form.Item label='ประเภทเอกสาร' name='productTypeLand'
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input !'
                                                    },]}>
                                                <Select
                                                    disabled
                                                    placeholder={'เลือก'}
                                                    style={{ height: '40px', width: '100%' }}
                                                    onChange={(value) => handleProductLand(value)}
                                                >
                                                    <Option value={1}>นส.3 ก.</Option>
                                                    <Option value={2}>นส.4 จ.</Option>
                                                </Select>
                                            </Form.Item>
                                            {dataLand?.productTypeLand === 1 && (
                                                <>
                                                    <Form.Item name='provinces' label='จังหวัด'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <Select
                                                            disabled
                                                            style={{ height: '40px' }}
                                                            showSearch
                                                            loading={loading}
                                                            name='provinces'
                                                            placeholder="จังหวัด"
                                                            onChange={handleProvinceChange1}
                                                        >
                                                            {provinces?.result?.map((pro, index) => (
                                                                <Option key={pro.pvcode} value={pro.pvnamethai}>
                                                                    {pro.pvnamethai}
                                                                </Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                    <Form.Item name='district' label='อำเภอ'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <Select
                                                            disabled
                                                            style={{ height: '40px' }}
                                                            showSearch
                                                            loading={loading}
                                                            name='district'
                                                            placeholder="อำเภอ"
                                                            onChange={handleDistrictChange2}
                                                        >
                                                            {district?.map((dis, index) => (
                                                                <Option key={dis.amcode} value={dis.amnamethai}>
                                                                    {dis.amnamethai}
                                                                </Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                    <Form.Item label='เลขที่ดินหรือระวาง' name='numberLandlawang'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <Input disabled type='text' style={{ height: '40px' }}
                                                            onChange={(e) => setDataLand({ ...dataLand, numberLandlawang: e.target.value })}></Input>
                                                    </Form.Item>
                                                    <Form.Item label='พื้นที่ไร่' name='rai'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <Input disabled type='number' suffix="ไร่"
                                                            onChange={(e) => setDataLand({ ...dataLand, rai: parseInt(e.target.value) })}></Input>
                                                    </Form.Item>
                                                    <Form.Item label='พื้นที่งาน' name='workArea'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <Input disabled type='number' suffix="งาน"
                                                            onChange={(e) => setDataLand({ ...dataLand, workArea: parseInt(e.target.value) })}></Input>
                                                    </Form.Item>
                                                    <Form.Item label='พื้นที่ตารางวา' name='squareWaArea'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <Input disabled type='number' suffix="ตารางวา"
                                                            onChange={(e) => setDataLand({ ...dataLand, squareWaArea: parseInt(e.target.value) })}></Input>
                                                    </Form.Item>
                                                </>
                                            )}
                                            {dataLand?.productTypeLand === 2 && (
                                                <>
                                                    <Form.Item name='provinces' label='จังหวัด'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <Select
                                                            disabled={notPassData2?.npProduct}
                                                            style={{ height: '40px' }}
                                                            showSearch
                                                            loading={loading}
                                                            name='provinces'
                                                            placeholder="จังหวัด"
                                                            onChange={handleProvinceChange}
                                                        >
                                                            {provinces?.result?.map((pro, index) => (
                                                                <Option key={pro.pvcode} value={pro.pvnamethai}>
                                                                    {pro.pvnamethai}
                                                                </Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>

                                                    <Form.Item name='district' label='อำเภอ'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <Select
                                                            disabled={notPassData2?.npProduct}
                                                            style={{ height: '40px' }}
                                                            showSearch
                                                            loading={loading}
                                                            name='district'
                                                            placeholder="อำเภอ"
                                                            onChange={handleDistrictChange}
                                                        >
                                                            {district?.map((dis, index) => (
                                                                <Option key={dis.amcode} value={dis.amnamethai}>
                                                                    {dis.amnamethai}
                                                                </Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>

                                                    <Form.Item label='เลขโฉนดที่ดิน' name='numberLand'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <Input disabled={notPassData2?.npProduct} type='text'
                                                            onChange={(e) => setDataLand({ ...dataLand, numberLand: e.target.value })}></Input>
                                                    </Form.Item>

                                                    <Form.Item label='ตรวจสอบ'>
                                                        <Button disabled={notPassData2?.npProduct} type="primary" onClick={handleCheckLand}>ตรวจสอบ</Button>
                                                    </Form.Item>

                                                    <Form.Item label='เลขที่ดินหรือระวาง' name='numberLandlawang'>
                                                        <Input type='text' disabled style={{ color: 'black' }}
                                                            onChange={(e) => setDataLand({ ...dataLand, numberLandlawang: e.target.value })}></Input>
                                                    </Form.Item>

                                                    <Form.Item label='พื้นที่ไร่' name='rai'>
                                                        <Input type='text' suffix="ไร่" disabled style={{ color: 'black' }}
                                                            onChange={(e) => setDataLand({ ...dataLand, rai: parseInt(e.target.value) })}></Input>
                                                    </Form.Item>

                                                    <Form.Item label='พื้นที่งาน' name='workArea'>
                                                        <Input type='text' suffix="งาน" disabled style={{ color: 'black' }}
                                                            onChange={(e) => setDataLand({ ...dataLand, workArea: parseInt(e.target.value) })}></Input>
                                                    </Form.Item>

                                                    <Form.Item label='พื้นที่ตารางวา' name='squareWaArea'>
                                                        <Input type='text' suffix="ตารางวา" disabled style={{ color: 'black' }}
                                                            onChange={(e) => setDataLand({ ...dataLand, squareWaArea: parseInt(e.target.value) })}></Input>
                                                    </Form.Item>

                                                    <Form.Item label='ราคาประเมินที่ดิน' name='landPrice'>
                                                        <Input type='text' suffix="บาทต่อตารางวา" disabled style={{ color: 'black' }}
                                                            onChange={(e) => setDataLand({ ...dataLand, landPrice: parseInt(e.target.value) })}></Input>
                                                    </Form.Item>

                                                    <Form.Item label='ราคารวม' name='resultLandPrice'>
                                                        <Input type='text' suffix="บาท" disabled style={{ color: 'black' }}
                                                            onChange={(e) => setDataLand({ ...dataLand, resultLandPrice: parseInt(e.target.value) })}></Input>
                                                    </Form.Item>
                                                </>
                                            )}
                                        </div>
                                        <div style={{ margin: 5 }}>
                                            <Form.Item label='ประเภทขอกู้ที่ดิน' name='productLoanLandType'
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input !',
                                                    },]}>
                                                <Select
                                                    style={{ height: '40px' }}
                                                    disabled={notPassData2?.npLoan}
                                                    placeholder={'เลือก'}
                                                    onChange={(value) => { handleChangeLandType(value) }}
                                                    options={productLoanTypeLand}
                                                />
                                            </Form.Item>
                                            <Form.Item label='ยอดจัด' name='loanAmountLand'
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input !'
                                                    },]}>
                                                <InputNumber
                                                    disabled={notPassData2?.npLoan}
                                                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                    size="large"
                                                    style={{ width: '100%', color: 'black', height: '40px' }}
                                                    onChange={(value) => setDataLoan({ ...dataLoan, loanAmountLand: parseInt(value) })}
                                                />
                                            </Form.Item>
                                            <Form.Item label='อัตราดอกเบี้ย' name='interestRateLand'
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input !'
                                                    },]}>
                                                <Input type='number' suffix="% +VAT" disabled
                                                    onChange={(e) => setDataLoan({ ...dataLoan, interestRateLand: parseFloat(e.target.value) })} />
                                            </Form.Item>
                                            <Form.Item label='ค่าธรรมเนียม' name='fee'>
                                                <Input type='number' disabled suffix="บาท" style={{ color: 'black' }}
                                                    onChange={(e) => setDataLoan({ ...dataLoan, fee: parseFloat(e.target.value) })} />
                                            </Form.Item>
                                            <Form.Item label='จำนวนงวด' name='loanLandTerm'
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input !'
                                                    },]}>
                                                <Input disabled={notPassData2?.npLoan} type='number'
                                                    onChange={(e) => setDataLoan({ ...dataLoan, loanLandTerm: parseInt(e.target.value) })} />
                                            </Form.Item>
                                            <Form.Item label='งวดละ' name='monthlyPaymentLand'
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input !'
                                                    },]}>
                                                <Input type='number' suffix="บาท" disabled style={{ color: 'black' }}
                                                    onChange={(e) => setDataLoan({ ...dataLoan, monthlyPaymentLand: parseInt(e.target.value) })} />
                                            </Form.Item>
                                            <Form.Item label='ดอกเบี้ย' name='interestLand'
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input !'
                                                    },]}>
                                                <Input type='number' suffix="บาท" disabled style={{ color: 'black' }}
                                                    onChange={(e) => setDataLoan({ ...dataLoan, interestLand: parseInt(e.target.value) })} />
                                            </Form.Item>
                                            <Form.Item label='รวมราคา' name='installmentWithInterestLand'>
                                                <Input disabled style={{ color: 'black' }} suffix="บาท" />
                                            </Form.Item>
                                            {(dataLoan && dataLoan.productLoanLandType === 'ย้ายไฟแนนซ์') || (dataLoan && dataLoan.productLoanLandType === 'Pre-Aaprove-ย้ายไฟแนนซ์') ? (
                                                <>
                                                    <Form.Item label='ย้ายไฟแนนซ์จาก' name='issuno'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <Input type='text'
                                                            style={{ color: 'black' }}
                                                            onChange={(e) => setDataOldLoan({ ...dataOldLoan, issuno: e.target.value })} />
                                                    </Form.Item>
                                                    <Form.Item label='ยอดปิดไฟแนนซ์' name='oldLandLoanAmount'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <InputNumber
                                                            disabled={notPassData2?.npOldLoan}
                                                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                            size="large"
                                                            style={{ width: '100%', color: 'black' }}
                                                            onChange={(value) => setDataOldLoan({ ...dataOldLoan, oldLandLoanAmount: parseInt(value) })}
                                                        />
                                                    </Form.Item>

                                                    <Form.Item label='ค่างวด/เดือน' name='oldLandMonthlyPayment'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <InputNumber
                                                            disabled={notPassData2?.npOldLoan}
                                                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                            size="large"
                                                            style={{ width: '100%', color: 'black' }}
                                                            onChange={(value) => setDataOldLoan({ ...dataOldLoan, oldLandMonthlyPayment: parseInt(value) })}
                                                        />
                                                    </Form.Item>

                                                    <Form.Item label='จำนวนงวด' name='oldLandLoanTerm'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <InputNumber
                                                            disabled={notPassData2?.npOldLoan}
                                                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                            size="large"
                                                            style={{ width: '100%', color: 'black' }}
                                                            onChange={(value) => setDataOldLoan({ ...dataOldLoan, oldLandLoanTerm: parseInt(value) })}
                                                        />
                                                    </Form.Item>
                                                </>
                                            ) : null}
                                            <Form.Item label='ผู้เสนอเคส' name='proposalBy' style={{ textAlign: 'center' }} >
                                                <Input disabled style={{ color: 'black', width: '95%' }} />
                                            </Form.Item>
                                        </div>
                                    </Row>
                                    <Row justify={'center'}>
                                        <Col span={22}>
                                            <span><b style={{ fontSize: '25' }}>สตอรี่รายละเอียดที่ดิน</b></span> <p />
                                            <Form.Item name="storyLand" label="">
                                                <TextArea
                                                    name='storyLand'
                                                    style={{ color: 'blue' }}
                                                    rows={8}
                                                    disabled
                                                >
                                                </TextArea>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Divider />
                                </>
                                : null
                        }
                        {/* อัพรูป */}
                        <Row justify={'center'}>
                            <Col span={24}>
                                {/* <Row justify={'center'} ><b><u>เพิ่มรูปภาพโฉนดที่ดิน (ไม่เกิน 4 MB ต่อรูป)</u></b></Row>
                                <Row justify={'center'} >
                                    <b>
                                        <Form.Item name='imgDeed'>
                                            <Upload
                                                accept="image/*"
                                                multiple={true}
                                                style={{ textAlign: 'center' }}
                                                listType="picture-card"
                                                fileList={fileListDeed}
                                                onPreview={handlePreview}
                                                onChange={handleDeed}
                                                beforeUpload={(file) => beforeUpts(file, 3, getData.lands.landId).then(() => false).catch(() => true)}
                                            >
                                                {fileListDeed?.length >= 10 ? null : (
                                                    <div>
                                                        <PlusOutlined />
                                                        <div className="ant-upload-text">upload</div>
                                                    </div>
                                                )}
                                            </Upload>
                                            <Modal open={previewVisible} onCancel={handleCancel}>
                                                <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                            </Modal>
                                        </Form.Item>
                                    </b>
                                </Row>
                                <Divider />
                                <Row justify={'center'} style={{ margin: '5px' }} ><b><u>ใบแบ่งใบต่อ (ไม่เกิน 4 MB ต่อรูป)</u></b></Row>
                                <Row justify={'center'} >
                                    <b>
                                        <Form.Item name='imgLeafDivider'>
                                            <Upload
                                                accept="image/*"
                                                multiple={true}
                                                style={{ textAlign: 'center' }}
                                                listType="picture-card"
                                                fileList={fileListLeafDivider}
                                                onPreview={handlePreview}
                                                onChange={handleLeafDivider}
                                                beforeUpload={(file) => beforeUpts(file, 10, getData.lands.landId).then(() => false).catch(() => true)}
                                            >
                                                {fileListLeafDivider?.length >= 10 ? null : (
                                                    <div>
                                                        <PlusOutlined />
                                                        <div className="ant-upload-text">upload</div>
                                                    </div>
                                                )}
                                            </Upload>
                                            <Modal open={previewVisible} onCancel={handleCancel}>
                                                <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                            </Modal>
                                        </Form.Item>
                                    </b>
                                </Row>
                                <Divider />
                                <Row justify={'center'} style={{ margin: '5px' }} ><b><u>เพิ่มรูปภาพระวาง (ไม่เกิน 4 MB ต่อรูป)</u></b></Row>
                                <Row justify={'center'} >
                                    <b>
                                        <Form.Item name='imgBurden'>
                                            <Upload
                                                accept="image/*"
                                                multiple={true}
                                                style={{ textAlign: 'center' }}
                                                listType="picture-card"
                                                fileList={fileListBurden}
                                                onPreview={handlePreview}
                                                onChange={handleBurden}
                                                beforeUpload={(file) => beforeUpts(file, 11, getData.lands.landId).then(() => false).catch(() => true)}
                                            >
                                                {fileListBurden?.length >= 10 ? null : (
                                                    <div>
                                                        <PlusOutlined />
                                                        <div className="ant-upload-text">upload</div>
                                                    </div>
                                                )}
                                            </Upload>
                                            <Modal open={previewVisible} onCancel={handleCancel}>
                                                <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                            </Modal>
                                        </Form.Item>
                                    </b>
                                </Row>
                                <Divider />
                                <Row justify={'center'} style={{ margin: '5px' }} ><b><u>ใบประเมิน/รูปที่ดิน/สิ่งปลูกสร้าง-ที่ดินปัจจุบัน(อัพโหลดไฟล์ไม่เกิน 4MB)</u></b></Row>
                                <Row justify={'center'} >
                                    <b>
                                        <Form.Item name='imgGuarantorcard'>
                                            <Upload
                                                accept="image/*"
                                                multiple={true}
                                                style={{ textAlign: 'center' }}
                                                listType="picture-card"
                                                fileList={fileListLand}
                                                onPreview={handlePreview}
                                                onChange={handleLand}
                                                beforeUpload={(file) => beforeUpts(file, 1, getData.lands.landId).then(() => false).catch(() => true)}
                                            >
                                                {fileListLand?.length >= 10 ? null : (
                                                    <div>
                                                        <PlusOutlined />
                                                        <div className="ant-upload-text">upload</div>
                                                    </div>
                                                )}
                                            </Upload>
                                            <Modal open={previewVisible} onCancel={handleCancel}>
                                                <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                            </Modal>
                                        </Form.Item>
                                    </b>
                                </Row> */}
                                {
                                    newDataLand?.map((item, index) => {
                                        return renderTitleDeed({ item, index })
                                    })
                                }
                                {
                                    newDataLand?.map((item, index) => {
                                        return renderDivision({ item, index })
                                    })
                                }
                                {
                                    newDataLand?.map((item, index) => {
                                        return renderLawang({ item, index })
                                    })
                                }
                                {
                                    newDataLand?.map((item, index) => {
                                        return renderLandBuildings({ item, index })
                                    })
                                }

                            </Col>
                        </Row>
                        <Divider />
                        <Row justify={'center'}>
                            <Space>
                                <Button type="primary" htmlType="submit" style={{ background: "green" }}>บันทึก</Button>
                                <Button type="primary" danger onClick={handleCancel1}>ปิด</Button>
                            </Space>
                        </Row>
                    </Form>
                </Spin>
            </Card>
            {contextHolder}
        </Row>
    )
}
