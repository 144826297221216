import { Button, Form, Card, Col, Row, Space, Upload, Modal, Divider, message, Image } from 'antd';
import React, { useState, useEffect } from 'react'
import { PlusOutlined } from '@ant-design/icons';
import './../../css/Media.css'
import Compressor from 'compressorjs';


function ModalUpImg({ close, page, setPage, setDataSendBack, dataEdit }) {

    const [loading, setLoading] = useState(false)
    // const [form] = Form.useForm()

    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [fileListCustomerLand, setFileListCustomerLand] = useState([]);
    const [fileListCustomerDeed, setFileListCustomerDeed] = useState([]);
    const [fileListCustomerLeafDivider, setFileListCustomerLeafDivider] = useState([]);
    const [fileListCustomerBurden, setFileListCustomerBurden] = useState([]);

    /*เก็บรูปทั้งหมด*/
    const [allImg, setAllImg] = useState([]);

    // const newFormData = new FormData();

    const handleClose = () => {
        close(false);
    }

    const handleSubmit = async () => {
        setDataSendBack({ img: allImg })
        setPage(page + 1)
    }

    // const compressImage = async (ez, types) => {
    //     var image = {};
    //     return new Promise(async (resolve) => {
    //         if (types !== 7) {
    //             if (ez.size > 4000000) {
    //                 console.log("4m ไม่ให้ส่ง");
    //                 resolve(true);  // บอกให้ระบบทราบว่ากระบวนการเสร็จสิ้น
    //             } else {
    //                 let quality;
    //                 if (ez.size >= 2500000 && ez.size <= 4000000) {
    //                     quality = 0.1;
    //                 } else if (ez.size >= 2000000 && ez.size <= 2499999) {
    //                     quality = 0.15;
    //                 } else if (ez.size >= 1500000 && ez.size <= 1999999) {
    //                     quality = 0.2;
    //                 } else if (ez.size >= 1000000 && ez.size <= 1499999) {
    //                     quality = 0.3;
    //                 } else {
    //                     quality = 0.5;
    //                 }

    //                 try {
    //                     // ใช้ Promise เพื่อบีบอัดรูปภาพ
    //                     const compressedFile = await new Promise((resolveCompress) => {
    //                         new Compressor(ez, {
    //                             quality: quality,
    //                             success: (result) => {
    //                                 resolveCompress(result);
    //                             },
    //                         });
    //                     });

    //                     const fileOptions = {
    //                         type: compressedFile.type,
    //                         lastModified: Date.now(),
    //                     };
    //                     const compressedFileAsFile = new File([compressedFile], ez.name, fileOptions);
    //                     image.imageCompressedFile = compressedFileAsFile;
    //                     image.type = types
    //                     allImg.push(image);
    //                     //setAllImg((prevAllImg) => [...prevAllImg, image]);

    //                     // ตรงนี้คือการ resolve ไฟล์ที่ถูกบีบอัด
    //                     resolve(image);
    //                 } catch (error) {
    //                     console.error('Image compression error:', error);
    //                     resolve(true);  // บอกให้ระบบทราบว่ากระบวนการเสร็จสิ้น
    //                 }
    //             }
    //         } else {
    //             // ... (โลจิกสำหรับ type 7)
    //             image.imageCompressedFile = ez;
    //             image.type = types
    //             allImg.push(image);
    //             //setAllImg((prevAllImg) => [...prevAllImg, image]);
    //             resolve(true);  // บอกให้ระบบทราบว่ากระบวนการเสร็จสิ้น
    //         }
    //     });
    // };

    // const beforeUpts = async (file, ty) => {
    //     //console.log("file", file)
    //     try {
    //         setLoading(true)
    //         const compressedFile = await compressImage(file, ty);
    //         //fileListCustomerCar.push({originFileObj:file})
    //         //เปลี่ยนไปใช้ไฟล์ที่ถูกบีบอัดแล้ว
    //         //allImg.push(compressedFile)
    //         //    image1.push(compressedFile)
    //         //console.log('Image=',file)
    //         //setLoading(false);
    //         return Promise.resolve(compressedFile);
    //     } catch (error) {
    //         console.error('Image compression error:', error);
    //         message.error('Image compression failed.');
    //         return Promise.reject(); // เปลี่ยนให้ return Promise.reject() เมื่อไม่สามารถบีบอัดได้
    //     }
    // };

    const beforeUpts = async (file, ty) => {
        try {
            // setLoading(true)
            const compressedFile = await compressImage(file, ty);
            return Promise.resolve(compressedFile);
        } catch (error) {
            console.error('Image compression error:', error);
            message.error('Image compression failed.');
            return Promise.reject(); // เปลี่ยนให้ return Promise.reject() เมื่อไม่สามารถบีบอัดได้
        }
    };

    const compressImage = async (ez, types) => {
        var image = {};
        return new Promise(async (resolve) => {
            if (types !== 7) {
                if (ez.size > 4000000) {
                    console.log("4m ไม่ให้ส่ง");
                    resolve(true);  // บอกให้ระบบทราบว่ากระบวนการเสร็จสิ้น
                } else {
                    let quality;
                    if (ez.size >= 2500000 && ez.size <= 4000000) {
                        quality = 0.1;
                    } else if (ez.size >= 2000000 && ez.size <= 2499999) {
                        quality = 0.15;
                    } else if (ez.size >= 1500000 && ez.size <= 1999999) {
                        quality = 0.2;
                    } else if (ez.size >= 1000000 && ez.size <= 1499999) {
                        quality = 0.3;
                    } else {
                        quality = 0.5;
                    }

                    try {
                        // ใช้ Promise เพื่อบีบอัดรูปภาพ
                        const compressedFile = await new Promise((resolveCompress) => {
                            new Compressor(ez, {
                                quality: quality,
                                success: (result) => {
                                    resolveCompress(result);
                                },
                            });
                        });

                        // แบบเก่า
                        // const fileOptions = {
                        //     type: compressedFile.type,
                        //     lastModified: Date.now(),
                        // };
                        // const compressedFileAsFile = new File([compressedFile], ez.name, fileOptions);
                        // image.imageCompressedFile = compressedFileAsFile;
                        // image.type = types
                        // allImg.push(image);

                        // แบบ base64
                        var filename = ez.name
                        var image64 = await getBase64(compressedFile)
                        image.filename = filename
                        image.image64 = image64
                        image.type = types
                        allImg.push(image)

                        // ตรงนี้คือการ resolve ไฟล์ที่ถูกบีบอัด
                        resolve(image);
                    } catch (error) {
                        console.error('Image compression error:', error);
                        resolve(true);  // บอกให้ระบบทราบว่ากระบวนการเสร็จสิ้น
                    }
                }
            } else {
                // ... (โลจิกสำหรับ type 7)
                image.imageCompressedFile = ez;
                image.type = types
                allImg.push(image);
                resolve(true);  // บอกให้ระบบทราบว่ากระบวนการเสร็จสิ้น
            }
        });
    };

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);
    };

    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };

    const handleCancel = () => {
        setPreviewVisible(false);
    }

    const handleChangeCustomerLand = async ({ fileList }) => { // 1
        for (const removedFile of fileListCustomerLand.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.filename === removedFile.name && img.type === 1)) {
                const updatedAllImg = allImg.filter(img => !(img.filename === removedFile.name && img.type === 1));
                setAllImg(updatedAllImg);
            }
        }
        setFileListCustomerLand(fileList);
        setLoading(false)
    };

    const handleChangeCustomerDeed = async ({ fileList }) => { // 3
        for (const removedFile of fileListCustomerDeed.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.filename === removedFile.name && img.type === 3)) {
                const updatedAllImg = allImg.filter(img => !(img.filename === removedFile.name && img.type === 3));
                setAllImg(updatedAllImg);
            }
        }
        setFileListCustomerDeed(fileList);
        setLoading(false)
    };

    const handleChangeCustomerLeafDivider = async ({ fileList }) => { // 10
        for (const removedFile of fileListCustomerLeafDivider.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.filename === removedFile.name && img.type === 10)) {
                const updatedAllImg = allImg.filter(img => !(img.filename === removedFile.name && img.type === 10));
                setAllImg(updatedAllImg);
            }
        }
        setFileListCustomerLeafDivider(fileList);
        setLoading(false)
    };

    const handleChangeCustomerBurden = async ({ fileList }) => { // 11
        for (const removedFile of fileListCustomerBurden.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.filename === removedFile.name && img.type === 11)) {
                const updatedAllImg = allImg.filter(img => !(img.filename.name === removedFile.name && img.type === 11));
                setAllImg(updatedAllImg);
            }
        }
        setFileListCustomerBurden(fileList);
        setLoading(false)
    };

    return (
        <Form
            name="basic"
            labelCol={{
                span: 8,
            }}
            wrapperCol={{
                span: 16,
            }}
            onFinish={handleSubmit}
            autoComplete="off"
        >
            <Row justify={'center'} style={{ margin: 5 }}><b><h4 style={{ color: 'red' }}><u>อัพโหลดรูปขนาดไม่เกิน 3MB / รูป</u></h4></b></Row>
            <Card>
                <Row justify={'center'}><b><h3><u>รูปโฉนด/หน้า-หลัง</u></h3></b></Row>
                <Row justify={'center'}>
                    <aside gutter={24} justify={'center'}>
                        <div className='gutter-row' span={24}>
                            <b>
                                    {dataEdit?.img?.map((e, index) => {
                                        if (e.type === 3) {
                                            return (
                                                <div style={{ margin: 3 }}>
                                                    <Space>
                                                        <Image width={'100px'} key={index} style={{ margin: 5 }} src={e.image64} alt={e.filename} />
                                                        <Button>delete</Button>
                                                    </Space>
                                                </div>
                                            );
                                        }
                                        return null;
                                    })}
                                {/* </Row> */}
                                <Upload
                                    accept="image/*"
                                    multiple={true}
                                    style={{ textAlign: 'center' }}
                                    listType="picture-card"
                                    fileList={fileListCustomerDeed}
                                    onPreview={handlePreview}
                                    onChange={handleChangeCustomerDeed}
                                    beforeUpload={(file) => beforeUpts(file, 3).then(() => false).catch(() => true)}
                                >
                                    {fileListCustomerDeed?.length >= 20 ? null : (
                                        <div>
                                            <PlusOutlined />
                                            <div className="ant-upload-text">upload</div>
                                        </div>
                                    )}
                                </Upload>
                                <Modal open={previewVisible} onCancel={handleCancel}>
                                    <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                </Modal>
                            </b>
                        </div>
                    </aside>
                </Row>
                <Divider style={{ margin: 5 }} />
                <Row justify={'center'}><b><h3><u>ใบแบ่งใบต่อ</u></h3></b></Row>
                <Row justify={'center'}>
                    <aside gutter={24} justify={'center'}>
                        <div className='gutter-row' span={12}>
                            <b>
                                <Upload
                                    accept="image/*"
                                    multiple={true}
                                    style={{ textAlign: 'center' }}
                                    listType="picture-card"
                                    fileList={fileListCustomerLeafDivider}
                                    onPreview={handlePreview}
                                    onChange={handleChangeCustomerLeafDivider}
                                    beforeUpload={(file) => beforeUpts(file, 10).then(() => false).catch(() => true)}
                                >
                                    {fileListCustomerLeafDivider?.length >= 20 ? null : (
                                        <div>
                                            <PlusOutlined />
                                            <div className="ant-upload-text">upload</div>
                                        </div>
                                    )}
                                </Upload>
                                <Modal open={previewVisible} onCancel={handleCancel}>
                                    <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                </Modal>
                            </b>
                        </div>
                    </aside>
                </Row>
                <Divider style={{ margin: 5 }} />
                <Row justify={'center'}><b><h3><u>ระวาง</u></h3></b></Row>
                <Row justify={'center'}>
                    <aside gutter={24} justify={'center'}>
                        <div className='gutter-row' span={12}>
                            <b>
                                <Upload
                                    accept="image/*"
                                    multiple={true}
                                    style={{ textAlign: 'center' }}
                                    listType="picture-card"
                                    fileList={fileListCustomerBurden}
                                    onPreview={handlePreview}
                                    onChange={handleChangeCustomerBurden}
                                    beforeUpload={(file) => beforeUpts(file, 11).then(() => false).catch(() => true)}
                                >
                                    {fileListCustomerBurden?.length >= 20 ? null : (
                                        <div>
                                            <PlusOutlined />
                                            <div className="ant-upload-text">upload</div>
                                        </div>
                                    )}
                                </Upload>
                                <Modal open={previewVisible} onCancel={handleCancel}>
                                    <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                </Modal>
                            </b>
                        </div>
                    </aside>
                </Row>
                <Divider style={{ margin: 5 }} />
                <Row justify={'center'}><b><h3><u>รูปที่ดิน/สิ่งปลูกสร้าง-ที่ดินปัจจุบัน</u></h3></b></Row>
                <Row justify={'center'}>
                    <aside gutter={24} justify={'center'}>
                        <div className='gutter-row' span={12}>
                            <b>
                                <Upload
                                    accept="image/*"
                                    multiple={true}
                                    style={{ textAlign: 'center' }}
                                    listType="picture-card"
                                    fileList={fileListCustomerLand}
                                    onPreview={handlePreview}
                                    onChange={handleChangeCustomerLand}
                                    beforeUpload={(file) => beforeUpts(file, 1).then(() => false).catch(() => true)}
                                >
                                    {fileListCustomerLand?.length >= 20 ? null : (
                                        <div>
                                            <PlusOutlined />
                                            <div className="ant-upload-text">upload</div>
                                        </div>
                                    )}
                                </Upload>
                                <Modal open={previewVisible} onCancel={handleCancel}>
                                    <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                </Modal>
                            </b>
                        </div>
                    </aside>
                </Row>
                <Divider style={{ margin: 5 }} />
                <Row>
                    <Col span={24} style={{ textAlign: "right" }}>
                        <Space>
                            <Button type='primary' danger onClick={handleClose}>ยกเลิก</Button>
                            <Button type='primary' style={{ backgroundColor: "green" }} onClick={handleSubmit}>ถัดไป</Button>
                        </Space>
                    </Col>
                </Row>
            </Card>
        </Form>
    )
}
export default ModalUpImg