import React from 'react'
import { Form, Modal, Row, Col, Image, Space, Button, Divider } from 'antd'
import '../../css/Media.css'
import { getImagess } from '../../file_mid/all_api';

function ModalShowImgL({ open, close, imageBlobzz, newDataLand }) {
    const token = localStorage.getItem('token');
    const [form] = Form.useForm()

    const handleCancel = () => {
        close(false)
    }

    const renderTitleDeed = ({ item, index }) => { //render Land Array
        return (
            <>
                <Row justify={'left'}><b><u>รูปโฉนด/หน้า-หลัง ({item.numberLand})</u></b></Row>
                <Row gutter={32} justify={'center'}>
                    <Space>
                        <Row justify={'center'}>
                            {item?.img?.map((e, index) => {
                                var PreAaproveSplit = []
                                PreAaproveSplit = e.pathImage.split("/")
                                if (PreAaproveSplit[3] !== "13") {
                                    if (e.type === 3) {
                                        return (
                                            <Row gutter={32} justify={'center'}>
                                                <Col span={24} style={{ textAlign: 'center' }}>
                                                    <Image style={{ margin: 3 }} width={'100px'} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                </Col>
                                            </Row>
                                        );
                                    }
                                }
                                return null;
                            })}
                        </Row>
                    </Space>
                </Row>
                <Divider />
            </>
        )
    }
    const renderDivision = ({ item, index }) => { //render Land Array
        return (
            <>
                <Row justify={'left'}><b><u>ใบแบ่งใบต่อ ({item.numberLand})</u></b></Row>
                <Row gutter={32} justify={'center'}>
                    <Space>
                        <Row justify={'center'}>
                            {item?.img?.map((e, index) => {
                                var PreAaproveSplit = []
                                PreAaproveSplit = e.pathImage.split("/")
                                if (PreAaproveSplit[3] !== "13") {
                                    if (e.type === 10) {
                                        return (
                                            <>
                                                <Row gutter={32} justify={'center'}>
                                                    <Col span={24} style={{ textAlign: 'center' }}>
                                                        <Image style={{ margin: 3 }} width={'100px'} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                    </Col>
                                                </Row>
                                            </>
                                        );
                                    }
                                }
                                return null;
                            })}
                        </Row>
                    </Space>
                </Row>
                <Divider />
            </>
        )
    }
    const renderLawang = ({ item, index }) => { //render Land Array
        return (
            <>
                <Row justify={'left'}><b><u>ระวาง ({item.numberLand})</u></b></Row>
                <Row gutter={32} justify={'center'}>
                    <Space>
                        <Row justify={'center'}>
                            {item?.img?.map((e, index) => {
                                var PreAaproveSplit = []
                                PreAaproveSplit = e.pathImage.split("/")
                                if (PreAaproveSplit[3] !== "13") {
                                    if (e.type === 11) {
                                        return (
                                            <>
                                                <Row gutter={32} justify={'center'}>
                                                    <Col span={24} style={{ textAlign: 'center' }}>
                                                        <Image style={{ margin: 3 }} width={'100px'} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                    </Col>
                                                </Row>
                                            </>
                                        );
                                    }
                                }
                                return null;
                            })}
                        </Row>
                    </Space>
                </Row>
                <Divider />
            </>
        )
    }
    const renderLandBuildings = ({ item, index }) => { //render Land Array
        return (
            <>
                <Row justify={'left'}><b><u>รูปที่ดิน/สิ่งปลูกสร้าง-ที่ดินปัจจุบัน ({item.numberLand})</u></b></Row>
                <Row gutter={32} justify={'center'}>
                    <Space>
                        <Row justify={'center'}>
                            {item?.img?.map((e, index) => {
                                var PreAaproveSplit = []
                                PreAaproveSplit = e.pathImage.split("/")
                                if (PreAaproveSplit[3] !== "13") {
                                    if (e.type === 1) {
                                        return (
                                            <div style={{ margin: 3 }}>
                                                <Image width={"100px"} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                            </div>
                                        );
                                    }
                                }
                                return null;
                            })}
                        </Row>
                    </Space>
                </Row>
                <Divider />
            </>
        )
    }

    return (
        <Modal title="แสดงรูปภาพ" open={open} onCancel={handleCancel} width={'1200px'}
            footer={[
                <Row justify={'center'}>
                    <Space>
                        <Button key="back" type='primary' danger onClick={handleCancel}>
                            ปิด
                        </Button>
                    </Space>
                </Row >
            ]} >
            <Row justify={'center'}>
                <Form
                    labelCol={{
                        span: 12,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    style={{
                        width: '90%',
                    }}
                    form={form}
                    autoComplete="off"
                    initialValues={{ remember: true }}
                >
                    <Row justify={'left'}><b><u>รูปบัตรประชาชนผู้ขอกู้</u></b></Row>
                    <Row gutter={32} justify={'center'}>
                        <Space direction="vertical" >
                            <Row justify={'center'}>
                                {imageBlobzz?.map((e, index) => {
                                    var PreAaproveSplit = []
                                    PreAaproveSplit = e.pathImage.split("/")
                                    if (PreAaproveSplit[3] !== "13") {
                                        if (e.type === 4) {
                                            return (
                                                <Row gutter={32} justify={'center'}>
                                                    <Col span={24} style={{ textAlign: 'center' }}>
                                                        <Image width={'200px'} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                    </Col>
                                                </Row>
                                            );
                                        }
                                    }
                                    return null;
                                })}
                            </Row>
                        </Space>
                    </Row>
                    <Divider />
                    {
                        newDataLand?.map((item, index) => {
                            return renderLandBuildings({ item, index })
                        })
                    }
                    {
                        newDataLand?.map((item, index) => {
                            return renderTitleDeed({ item, index })
                        })
                    }
                    {
                        newDataLand?.map((item, index) => {
                            return renderDivision({ item, index })
                        })
                    }
                    {
                        newDataLand?.map((item, index) => {
                            return renderLawang({ item, index })
                        })
                    }
                    <Row justify={'left'}><b><u>ประวัติคนกู้ / ทะเบียนบ้าน / อาชีพ</u></b></Row>
                    <Row gutter={32} justify={'center'}>
                        <Space direction="vertical" >
                            <Row justify={'center'}>
                                {imageBlobzz?.map((e, index) => {
                                    var PreAaproveSplit = []
                                    PreAaproveSplit = e.pathImage.split("/")
                                    if (PreAaproveSplit[3] !== "13") {
                                        if (e.type === 6) {
                                            return (
                                                <>
                                                    <Row gutter={32} justify={'center'}>
                                                        <Col span={24} style={{ textAlign: 'center' }}>
                                                            <Image width={'200px'} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                        </Col>
                                                    </Row>
                                                </>
                                            );
                                        }
                                    }
                                    return null;
                                })}
                            </Row>
                        </Space>
                    </Row>
                    <Divider />
                    <Row justify={'left'}><b><u>รูปสเตทเมนต์ผู้กู้</u></b></Row>
                    <Row gutter={32} justify={'center'}>
                        <Space direction="vertical" >
                            <Row justify={'center'}>
                                {imageBlobzz?.map((e, index) => {
                                    var PreAaproveSplit = []
                                    PreAaproveSplit = e.pathImage.split("/")
                                    if (PreAaproveSplit[3] !== "13") {
                                        if (e.type === 8) {
                                            return (
                                                <>
                                                    <Row gutter={32} justify={'center'}>
                                                        <Col span={24} style={{ textAlign: 'center' }}>
                                                            <Image width={'200px'} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                        </Col>

                                                    </Row>
                                                </>

                                            );
                                        }
                                    }
                                    return null;
                                })}
                            </Row>
                        </Space>
                    </Row>
                    <Divider />
                    <Row justify={'left'}><b><u>รูปสเตทเมนต์ผู้กู้ PDF</u></b></Row>
                    <Row gutter={32} justify={'center'}>
                        <Space direction="vertical" >
                            <Row justify={'center'}>
                                {imageBlobzz?.map((e, index) => {
                                    var PreAaproveSplit = []
                                    PreAaproveSplit = e.pathImage.split("/")
                                    if (PreAaproveSplit[3] !== "13") {
                                        if (e.type === 7) {
                                            return (
                                                <>
                                                    <Row gutter={32} justify={'center'}>
                                                        <Col span={24} style={{ textAlign: 'center' }}>
                                                            <Space direction='vertical'>
                                                                <object data={`${getImagess}/${e.pathImage}?token=${token}`} type="application/pdf" width="350px" height="450px">
                                                                    <p>Alternative text - include a link <a href={`${getImagess}/${e.pathImage}?token=${token}`}>to the PDF!</a></p>
                                                                </object>
                                                                <p></p>
                                                            </Space>
                                                        </Col>
                                                    </Row>
                                                </>
                                            )
                                        }
                                    }
                                    return null;
                                })}
                            </Row>
                        </Space>
                    </Row>
                    <Divider />
                    <Row justify={'left'}><b><u>เอกสารเพิ่มเติม</u></b></Row>
                    <Row gutter={32} justify={'center'}>
                        <Space direction="vertical" >
                            <Row justify={'center'}>
                                {imageBlobzz?.map((e, index) => {
                                    var PreAaproveSplit = []
                                    PreAaproveSplit = e.pathImage.split("/")
                                    if (PreAaproveSplit[3] !== "13") {
                                        if (e.type === 12) {
                                            return (
                                                <>
                                                    <Row gutter={32} justify={'center'}>
                                                        <Col span={24} style={{ textAlign: 'center' }}>
                                                            <Image width={'200px'} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                        </Col>

                                                    </Row>
                                                </>

                                            );
                                        }
                                    }
                                    return null;
                                })}
                            </Row>
                        </Space>
                    </Row>
                    <Divider />
                </Form>
            </Row>
        </Modal >
    )
};

export default ModalShowImgL