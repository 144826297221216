import React, { useState, useEffect } from 'react'
import { Form, Modal, Input, Row, Col, Button, Card, Divider, Space, Image, DatePicker, Select, notification } from 'antd'
import axios from 'axios';
import dayjs from 'dayjs';
import { editcarrate, findcarall, getImagess } from '../../file_mid/all_api';

function ModalEditCarRate({ open, dataFromTable, close, checkstatus }) {

    const token = localStorage.getItem('token');
    const { Option } = Select;
    // const [dataCar, setDataCar] = useState({});
    const [dataCar, setDataCar] = useState({});
    const [api, contextHolder] = notification.useNotification();
    const [form] = Form.useForm()
    const [imageBlobzz, setImageBlobzz] = useState([]);

    useEffect(() => {
        loadDataCar()
    }, [dataFromTable])

    console.log("dataCar", dataCar)

    const loadDataCar = async () => {
        await axios.post(findcarall, dataFromTable)
            .then(res => {
                if (res.status === 200) {
                    console.log("loadDataCar", res.data)
                    setDataCar({ ...res.data.carLoanDetails.cars, carPriceStatus: true, approvalStatus: 16 })
                    // setDataCar(res.data)
                    setImageBlobzz(res.data.carLoanDetails.cars.img)
                    form.setFieldsValue(
                        {
                            ...res.data.carLoanDetails.cars,
                            carYear: dayjs(res.data.carLoanDetails.cars.carYear)
                        }
                    )

                }
            }).catch((err) => {
                console.log("err", err)
            })
    }

    const ErrorRate = (placement) => {
        api.error({
            message: `เรทรถไม่ถูกต้อง`,
            description:
                'เรทรถไม่ถูกต้อง กรุณาแก้ไขเรทรถ!',
            placement,
        });
    };
    const SuccessRate = (placement) => {
        api.success({
            message: `เพิ่มเรทรถสำเร็จ`,
            description:
                'เพิ่มเรทรถแล้ว สถานะถูกเปลี่ยนเป็น รอธุรการรับ',
            placement,
        });
    };

    const onFinish = async () => {
        delete dataCar.img
        delete dataCar.oldLoanDetails
        if (dataCar.carPrice === null || dataCar.carPrice === undefined || dataCar.carPrice <= 0 || isNaN(dataCar.carPrice)) {
            ErrorRate('top')
        } else {
            await axios.put(editcarrate, dataCar)
                .then(res => {
                    if (res.status === 200) {
                        SuccessRate('top')
                        setTimeout(() => {
                            close(false);
                        }, 1000);
                    }
                }).catch((err) => {
                    console.log("err", err)
                })
        }
    };

    const handleCancel = () => {
        close(false);
    };

    return (
        <div>
            <Modal title="แก้ไขข้อมูลเรทรถ" open={open} onCancel={handleCancel} footer={[null]} width={800}>
                <Form
                    name="basic"
                    labelCol={{
                        span: 5,
                    }}
                    wrapperCol={{
                        span: 16,
                    }}
                    onFinish={onFinish}
                    form={form}
                >
                    <Card>
                        <Row>
                            <Divider orientation="left"><b>รายละเอียดรถ</b></Divider>
                            <Col span={12}>
                                <Form.Item label="ชนิดรถ" name="productTypeCar">
                                    <Select
                                        style={{ color: 'black', height: '40px' }}
                                        onChange={(e) => setDataCar({ ...dataCar, productTypeCar: e })}>
                                        <Option value={1}>รถยนต์</Option>
                                        <Option value={2}>รถเครื่องจักการเกษตร</Option>
                                        <Option value={3}>รถบรรทุก</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item label="รุ่นรถ" name="carModel"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'กรุณากรอกข้อมูล !'
                                        },
                                    ]}>
                                    <Input onChange={(e) => setDataCar({ ...dataCar, carModel: e.target.value })} />
                                </Form.Item>
                                <Form.Item label="ปีรถ" name="carYear"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'กรุณากรอกข้อมูล !'
                                        },
                                    ]}>
                                    <DatePicker style={{ width: "100%", height: '40px' }} onChange={(date, dateString) => setDataCar({ ...dataCar, carYear: dateString })} picker="year" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="ยี่ห้อ" name="carBrand"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'กรุณากรอกข้อมูล !'
                                        },
                                    ]}>
                                    <Input onChange={(e) => setDataCar({ ...dataCar, carBrand: e.target.value })} />
                                </Form.Item>
                                <Form.Item label="แบบรถ" name="carBaab"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'กรุณากรอกข้อมูล !'
                                        },
                                    ]}>
                                    <Input onChange={(e) => setDataCar({ ...dataCar, carBaab: e.target.value })} />
                                </Form.Item>
                                <Form.Item label="เรทรถ" name="carPrice"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'กรุณากรอกข้อมูล !'
                                        },
                                    ]}>
                                    <Input onChange={(e) => setDataCar({ ...dataCar, carPrice: parseInt(e.target.value) })} />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Divider orientation="left"><b>รูปภาพ</b></Divider>
                                <Form.Item label="หน้าคู่มือ">
                                    <Space size={[10, 10]} wrap>
                                        {imageBlobzz?.map((e, index) => {
                                            if (e.type === 2) {
                                                return (
                                                    <>
                                                        <Image width={200} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                    </>
                                                );
                                            }
                                            return null;
                                        })}
                                    </Space>
                                </Form.Item>
                                <Divider />
                                <Form.Item label="ใบประเมิน / รูปรถ">
                                    <Space size={[10, 10]} wrap>
                                        {imageBlobzz?.map((e, index) => {
                                            if (e.type === 1) {
                                                return (
                                                    <>
                                                        <Image width={200} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                    </>
                                                );
                                            }
                                            return null;
                                        })}
                                    </Space>
                                </Form.Item>
                            </Col>

                        </Row>
                    </Card>
                    <div style={{ textAlign: 'center', marginTop: 10 }}>
                        <Space >
                            <Button type='primary' onClick={handleCancel} danger>ปิด</Button>
                            <Button type='primary' htmlType='submit' style={{ backgroundColor: "green" }}>บันทึก</Button>
                        </Space>
                    </div>
                </Form>
            </Modal >
            {contextHolder}
        </div >
    )
}

export default ModalEditCarRate