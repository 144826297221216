import React from 'react';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Divider, Row, Col, Card, Modal, Space, notification, Spin } from 'antd';

import AddCareer from './Modals/Career/AddCareer';
import EditCareer from './Modals/Career/EditCareer';
import { loadOJS } from '../../../../file_mid/all_api';

export default function GCareer({ close, next, prev, dataCareer, setDataCareer, dataCustomer }) {
    const [form] = Form.useForm();
    // const customers = useSelector(state => state.customers)
    const [loading, setLoading] = useState(false)
    const [dataedit, setDataEdit] = useState([]); //เซตสำหรับแก้ไขข้อมูล 
    const [index1, setIndex] = useState(); //set index send to page edit
    const [career, setCareer] = useState([]);
    const [api, contextHolder] = notification.useNotification();
    const { confirm } = Modal

    //checkOpen Button
    const [modaleditdata, setModalEditData] = useState(false);
    const [modaladddata, setModalAddData] = useState(false);

    
    useEffect(() => {
        // console.log("dataCareer")
        // console.log("dataCustomer", dataCustomer)
        if (dataCareer) {
            setCareer(dataCareer)
        }
        if (dataCustomer[0]?.customerId > 0) {
            loadCareer()
        }
    }, [])

    const loadCareer = async () => {
        const id = { customerId: dataCustomer[0]?.customerId }
        setLoading(true)
        await axios.post(loadOJS, id)
            .then((res) => {
                console.log("res", res.data)
                setCareer(res.data)
                setLoading(false)
            })
            .catch((err) => console.log(err))
        setLoading(false)
    }

    const DeleteSuccess = (placement) => {
        api.success({
            message: <b>ลบข้อมูลสำเร็จ</b>,
            placement,
        });
    }
    const AddSuccess = (placement) => {
        api.success({
            message: <b>เพิ่มอาชีพสำเร็จ</b>,
            placement,
        });
    }
    const EditSuccess = (placement) => {
        api.success({
            message: <b>แก้ไขอาชีพสำเร็จ</b>,
            placement,
        });
    }
    const NotPass = (placement) => {
        api.warning({
            message: <b>กรุณาเพิ่มอาชีพอย่างน้อย 1 อาชีพ</b>,
            placement,
        });
    }

    const handleCancel = () => {
        close(false)
    }

    const onChangeBack = () => {
        if (career.length === 0 || career === undefined) {
            prev()
        } else {
            setDataCareer(career)
            prev()
        }
    }

    const showConfirm = (value, index) => {
        // console.log("showConfirm", value)
        confirm({
            title: 'คุณต้องการที่จะลบข้อมูลหรือไม่...?',
            content: 'กด OK เพื่อยืนยันการลบ',
            onOk() {
                const deletearray = [...career]
                deletearray.splice(index, 1)
                setCareer(deletearray)
                // if (value.addressId !== 0) {
                //     deleteData(value)
                // } else {
                DeleteSuccess("top")
                //}

            },
            onCancel() {
                // message.error('ยกเลิกการลบข้อมูล')
            },
        });
    };

    const handleSubmit = () => {
        if (career.length === 0 || career.length === undefined) {
            NotPass("top")
        } else {
            console.log("career", career)
            setDataCareer(career)
            next()
        }
    }

    //ดึงข้อมูลมากจาก Modal addData
    const fucnAdd = (value) => {
        if (career.length === 0) {
            setCareer(pre => [...pre, { ...value.career }]);
        } else {
            setCareer(pre => [...pre, { ...value.career }]);
        }
        AddSuccess("top")
    }

    const fucnEdit = ({ getData, getIndex }) => {
        let Theedit = [...career]
        Theedit[getIndex] = getData
        setCareer(Theedit);
        EditSuccess("top")
    }

    const renderItem = ({ item, index }) => { //item คือตัวแปรที่เอาไว้ดึงค่าของข้อมูลออกมาแสดง โดยใช้ item.ชื่อฟิวล์
        // index ตอนนี้เป็นข้อมูลจาก Object
        const handleEdit = (dataedit, index1) => {
            setIndex(index1) //เซตค่า index ให้กับชุด ข้อมูล
            setDataEdit({ dataedit, index1 }) //ส่งข้อมูลด index ไปหน้าแก้ไข
            setModalEditData(true);
        }

        return (
            <Form form={form}>
                <Row style={{ margin: '3px' }} justify={'center'} >
                    <Card
                        key={index}
                        title={
                            <u>อาชีพที่ {index + 1}</u>
                        }
                        style={{ width: '300px' }} >
                        <Col span={24}>
                            <Form.Item style={{ marginBottom: 0 }} label='กลุ่มอาชีพ' >
                                <b>{item.desc}</b>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} label='อาชีพ'>
                                <b>{item.descSub}</b>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} label='สาขาอาชีพ'>
                                <b>{item.descSection}</b>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} label='ชื่อบริษัท(สถานที่ทำงาน)'>
                                <b>{item.companyName}</b>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} label='หมายเหตุ(อาชีพ)'>
                                <b>{item?.occupationDescription ? item.occupationDescription : ""}</b>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} label='รายได้ / เดือน'>
                                <b>{item.incomeMonth}</b>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} label='หมายเหตุ(รายได้)'>
                                <b>{item?.incomeMainDescription ? item.incomeMainDescription : ""}</b>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} label='รายจ่าย / เดือน'>
                                <b>{item.expensesMonth}</b>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} label='หมายเหตุ(รายจ่าย)'>
                                <b>{item?.expensesMonthDescription ? item.expensesMonthDescription : ""}</b>
                            </Form.Item>
                            <Divider></Divider>
                            <Row justify={'center'} gutter={{ sx: 8, sm: 16, md: 24, lg: 32 }}>
                                <Form.Item>
                                    <Button
                                        style={{ margin: '10px', background: '#f5222d', color: 'white', marginBottom: 0 }}
                                        icon={<DeleteOutlined />}
                                        onClick={() => showConfirm(career[index], index)}
                                    >ลบ</Button>
                                </Form.Item>
                                <Form.Item>
                                    <Button
                                        style={{ margin: '10px', background: '#bfbfbf', color: '#ffffff', marginBottom: 0 }}
                                        type='submit'
                                        onClick={() => { handleEdit(career[index], index); }}
                                    >แก้ไข</Button>
                                </Form.Item>
                            </Row>
                        </Col>
                    </Card>
                </Row>
            </Form>
        )
    }
    return (
        <>
            <Card style={{ color: '#4096ff' }}>
                <Spin spinning={loading} size='large' tip=" Loading... ">
                    <Form>
                        <Row>
                            <Col className='gutter-row' span={24}>
                                <Row>
                                    <Col span={24} style={{ fontSize: '30px', textAlign: 'center' }} >รายละเอียดอาชีพ - รายได้</Col>
                                </Row>
                                <Divider />
                                <Row gutter={32}>
                                    <Col span={24} style={{ textAlign: 'right' }}>
                                        <Button type='primary' onClick={() => { setModalAddData(true); }}> <PlusOutlined />เพิ่มข้อมูลอาชีพ - รายได้</Button>
                                    </Col>
                                </Row>
                                <Row justify={'center'}>
                                    {
                                        career?.map((item, index) => {
                                            return renderItem({ item, index, key: `{item.occupationJobPositionId} - ${index}` })
                                        })
                                    }
                                </Row>
                            </Col>
                        </Row>
                        <Divider />
                        <div style={{ textAlign: "center" }} >
                            <Space>
                                <Button type="primary" style={{ background: '#bfbfbf', color: '#000000', }} onClick={onChangeBack}>ย้อนกลับ</Button>
                                <Button type="primary" onClick={handleCancel} style={{ background: "red" }}>ยกเลิก</Button>
                                <Button type="primary" htmlType="submit" onClick={handleSubmit}>ต่อไป</Button>
                            </Space>
                        </div>
                    </Form>
                    {contextHolder}
                </Spin>
            </Card>
            {
                //เพื่มข้อมูลในการ์ด
                modaladddata ?
                    <AddCareer open={modaladddata} close={setModalAddData} fucnAdd={fucnAdd} dataCustomer={dataCustomer} />
                    : null
            }
            {
                //แก้ไขข้อมูลในการ์ด
                modaleditdata ?
                    <EditCareer open={modaleditdata} close={setModalEditData} shootdata={dataedit} fucnEdit={fucnEdit} dataindex={index1} />
                    : null
            }
        </>
    )
}
