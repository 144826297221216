import React from 'react'
import { Modal, Row, Card, Button, Divider, Col, Form } from 'antd'
import { currencyFormatOne } from "../file_mid/allFormat";
import dayjs from 'dayjs';
import { uid } from 'chart.js/helpers';


export default function Anothr({ open, close, dataAROTHR, dataHD }) {
    console.log("dataAROTHR",dataAROTHR)
    const [form] = Form.useForm()
    const handleOk = async () => {
        close(false);
    };
    const handleCancel = () => {
        close(false);
    };
    const renderItem = ({ item, index }) => {
        if (item.PAYAMT > 0) {
            return (
                <>
                    <aside style={{ width: '90%' }}>
                        <div><b><u>ลำดับที่ {index + 1}</u></b></div>
                        <div
                            style={{ marginBottom: 0 }}
                        >
                            <span>วันที่ : </span> <b>{dayjs(item.INPDT).format("DD-MM-YYYY")}</b>
                        </div>
                        <div
                            style={{ marginBottom: 0 }}
                        >
                            <span>ARCONT : </span> <b>{item.ARCONT}</b>
                        </div>
                        <div
                            style={{ marginBottom: 0 }}
                        >
                            <span>จำนวนเงิน : </span> <b>{currencyFormatOne(item.PAYAMT)} บาท</b>
                        </div>
                        <div
                            style={{ marginBottom: 0 }}
                        >
                            <span>หมายเหตุ : </span> <b>{item.FORDESC}</b>
                        </div>
                    </aside>
                    <Divider />
                </>
            )
        } else {
            return (
                <>
                    <aside style={{ width: '90%' }}>
                        <div
                            style={{ marginBottom: 0 }}
                        >
                            <span> <b>*** ไม่พบข้อมูล ***</b></span>
                        </div>
                    </aside>
                </>
            )
        }
    }
    return (
        <div>
            <Modal title="รายละเอียดลูกหนี้อื่นๆ"
                open={open}
                onOk={handleOk}
                onCancel={handleCancel}
                width={'900px'}
                footer={[
                    <Row justify={'center'}>
                        <Button key="back" type='primary' danger onClick={handleCancel}>
                            ปิด
                        </Button>
                    </Row>
                ]} >
                {/* <Spin spinning={loading} size='large' tip="Loading..."> */}
                <Row justify={'center'}>
                    <Col className='gutter-row' span={24} style={{ fontSize: '25px', textAlign: 'center' }}>
                        {/* <b>รายละเอียดลูกหนี้อื่นๆ</b> */}
                    </Col>
                </Row>
                <Row justify={'center'}>
                    <Form
                        labelCol={{
                            span: 12,
                        }}
                        wrapperCol={{
                            span: 24,
                        }}
                        style={{
                            width: '90%',
                        }}
                        form={form}
                        autoComplete="off"
                        initialValues={{ remember: true }}
                    >
                        <div className='main'>
                            <Card style={{ width: '100%' }}>
                                <Row justify={'left'}><b><u>รายละเอียดลูกหนี้อื่นๆ</u></b> </Row>
                                <Row gutter={32} justify={'center'}>
                                    {
                                        dataAROTHR.length > 0 ?
                                            dataAROTHR?.map((item, index) => {
                                                return renderItem({ item, index, key: uid })
                                            })
                                            :
                                            <>
                                                <aside style={{ width: '90%' }}>
                                                    <div
                                                        style={{ marginBottom: 10 }}
                                                    >
                                                        <span> <b>*** ไม่พบข้อมูล ***</b></span>
                                                    </div>
                                                </aside>
                                            </>
                                    }
                                </Row>
                                <Divider />
                            </Card>
                        </div>
                    </Form>
                </Row>
                {/* </Spin> */}
            </Modal >
        </div>
    )
}
