import React, { useState, useEffect } from 'react'
import { Form, Modal, Row, Col, Spin, Card, Button, Tag, Divider, notification, Table, Space } from 'antd'
import dayjs from 'dayjs';
import axios from "axios";
import { currencyFormatOne, msSale, msError, msDue, msNil, msLimit, msCreateDue, msDueNil } from "../file_mid/allFormat";
import { calPay } from "../loan_history/cal/Cal";
import { columns, columnsDetail } from '../loan_history/table/cm';
import Anothr from "./Anothr";

export default function DetailContno({ open, close, dataFromTable }) {
    const [form] = Form.useForm()
    const currentDateTime = dayjs()
    const [isModal, setIsModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const [dataAll, setDataAll] = useState(null);
    const [dataTable, setDataTable] = useState([]);
    const [dataHD, setDataHD] = useState([]);
    const [dataAROTHR, setDataAROTHR] = useState([]);
    const [dataTableDetail, setDataTableDetail] = useState([]);
    const [calData, setCalData] = useState({
        ALLPAYAMT: 0,
        ALLPAYFOLLOW: 0,
        ALLPAYINTEFF: 0,
        ALLPAYTON: 0,
        ALLCAL: 0,
        text: "",
    });
    const [isClicked, setIsClicked] = useState(true);
    const [checkButton, setCheckButton] = useState(true)
    const [checkButtonPay, setCheckButtonPay] = useState(true)
    useEffect(() => {
        console.log("dataFromTable =>",dataFromTable)
        handlePay()
    }, [])

    const handlePay = async () => {
        if (dataFromTable?.CONTNO !== "" && dataFromTable?.money > 0) {
            let mixData
            mixData = { CONTNO: dataFromTable.CONTNO, money: dataFromTable?.money, sale: 0, date: currentDateTime, payType: dataFromTable?.payType }
            console.log("mixData", mixData)
            setLoading(true)
            await axios.post("https://api-search-contno-db2-i67f6gaaqa-as.a.run.app/api-db2/data-payamt", mixData)
            // await axios.post("http://localhost:8070/api-db2/data-payamt", mixData)
                .then(async (res) => {
                    console.log("res.data payamt", res.data)
                    if (res.status === 200) {
                        if (res.data?.AllDataPay) {
                            setDataAll(res.data.AllDataPay)
                            // await calPay(res.data, "")
                            const result = await calPay(res.data.AllDataPay, "");
                            setCalData(result)
                            setDataTable(res.data.AllDataPay.result)
                            if (res.data.AllDataPay?.detailPay) {
                                setDataTableDetail(res.data.AllDataPay.detailPay)
                                setCheckButtonPay(false)
                            } else {
                                setDataTableDetail([])
                            }
                        }
                    } else if (res.status === 202) {
                        // setDataAll(res.data)
                        console.log("ไม่ได้จ่ายล่วงหน้า คิดดอกเป็นรายวัน", res.data)
                        // await calPay(res.data, "ไม่ได้จ่ายล่วงหน้า คิดดอกเป็นรายวัน")
                        const result = await calPay(res.data, "ไม่ได้จ่ายล่วงหน้า คิดดอกเป็นรายวัน");
                        setCalData(result)
                        setDataTable([])
                        setDataTableDetail([])
                        // setDataPay([])
                    } else if (res.status === 203) {
                        console.log("จำนวนเงินที่จ่ายเข้ามา มากกว่าเงินต้นคงเหลือ", res.data)
                        // setCalData({ ALLPAYAMT: 0, ALLPAYFOLLOW: 0, ALLPAYINTEFF: 0, ALLPAYTON: 0, ALLCAL: res.data.ccb, text: "จำนวนเงินที่จ่ายเข้ามา มากกว่าเงินต้นคงเหลือ" })
                        setCalData({ ALLPAYAMT: 0, ALLPAYFOLLOW: 0, ALLPAYINTEFF: 0, ALLPAYTON: 0, ALLCAL: 0, text: "จำนวนเงินที่จ่ายเข้ามา มากกว่าเงินต้นคงเหลือ" })
                        setDataTable([])
                        setDataTableDetail([])
                        // setDataPay([])
                        msLimit(api, "top")
                    } else if (res.status === 204) {
                        console.log("กรอกยอดเงินไม่ถูกต้อง")
                        setCalData({ ALLPAYAMT: 0, ALLPAYFOLLOW: 0, ALLPAYINTEFF: 0, ALLPAYTON: 0, ALLCAL: 0, text: "กรอกยอดเงินไม่ถูกต้อง" })
                        setDataTable([])
                        setDataTableDetail([])
                        // setDataPay([])
                        msNil(api, "top")
                    } else if (res.status === 205) {
                        console.log("เงินน้อยกว่าดอกเบี้ยสร้างดิว")
                        setDataTable([])
                        setDataTableDetail([])
                        msCreateDue(api, "top")
                    } else if (res.status === 208) {
                        console.log("ไม่พบดิวล่าสุด")
                        setDataTable([])
                        setDataTableDetail([])
                        msDueNil(api, "top")
                    } else {
                        setCalData({ ALLPAYAMT: 0, ALLPAYFOLLOW: 0, ALLPAYINTEFF: 0, ALLPAYTON: 0, ALLCAL: 0, text: "" })
                        setDataTable([])
                        setDataTableDetail([])
                        // setDataPay([])
                        msNil(api, "top")
                    }
                    if (res.data?.ResCheckHDPAYMENT) {
                        setDataHD(res.data.ResCheckHDPAYMENT)
                    } else {
                        setDataHD([])
                    }
                    if (res.data?.ResCheckAROTHR) {
                        setDataAROTHR(res.data.ResCheckAROTHR)
                    } else {
                        setDataAROTHR([])
                    }
                    setLoading(false)
                })
                .catch((err) => {
                    console.log("err", err)
                    setCalData({ ALLPAYAMT: 0, ALLPAYFOLLOW: 0, ALLPAYINTEFF: 0, ALLPAYTON: 0, ALLCAL: 0, text: "" })
                    setDataTable([])
                    setDataTableDetail([])
                    setDataHD([])
                    setDataAROTHR([])
                    // setDataPay([])
                    msError(api, "top")
                    setLoading(false)
                })
        } else {
            msNil(api, "top")
        }
    }
    const buttonAmt = async () => {
        setCheckButton(true)
        setIsClicked(true)
    }
    const buttonPay = async () => {
        setCheckButton(false)
        setIsClicked(false)
    }
    const handleOk = async () => {
        close(false);
    };
    const handleCancel = () => {
        close(false);
    };
    const checkart = () => {
        setIsModal(true)
    }
    return (
        <div>
            <Modal title="รายละเอียดสัญญา"
                open={open}
                onOk={handleOk}
                onCancel={handleCancel}
                width={'1100px'}
                footer={[
                    <Row justify={'center'}>
                        <Button key="back" type='primary' danger onClick={handleCancel}>
                            ปิด
                        </Button>
                    </Row>
                ]} >
                <Spin spinning={loading} size='large' tip="Loading...">
                    <Row justify={'center'}>
                        <Col className='gutter-row' span={24} style={{ fontSize: '25px', textAlign: 'center' }}>
                            <b>รายละเอียดสัญญา</b>
                        </Col>
                    </Row>
                    <Row justify={'center'}>
                        <Form
                            labelCol={{
                                span: 12,
                            }}
                            wrapperCol={{
                                span: 24,
                            }}
                            style={{
                                width: '90%',
                            }}
                            form={form}
                            autoComplete="off"
                            initialValues={{ remember: true }}
                        >
                            <div className='main'>
                                <Card style={{ width: '100%' }}>
                                    <Row justify={'center'}>
                                        <Col span={24} style={{ textAlign: 'left' }}>
                                            {
                                                dataFromTable?.CONTNO !== "" ?
                                                    <>
                                                        <div style={{ margin: 0 }}>
                                                            วันที่ : <Tag color={'green'}>{dayjs(dataFromTable?.PAYMENTDATE).format("DD-MM-YYYY")}</Tag>
                                                        </div>
                                                        <div style={{ margin: 0 }}>
                                                            เลขสัญญา : <Tag color={'green'}>{dataFromTable?.CONTNO}</Tag>
                                                        </div>
                                                    </>
                                                    :
                                                    null
                                            }
                                        </Col>
                                    </Row>
                                    <Divider />
                                    <Row justify={'left'}><b><u>รายละเอียดสัญญา {dataFromTable?.CONTNO}</u></b></Row>
                                    <Row gutter={32} justify={'center'}>
                                        <aside style={{ width: '90%' }}>
                                            {/* <b>
                                                <div style={{ margin: 0, }}>
                                                    <span>รับเงิน : </span>
                                                    <b style={{ fontSize: '20px' }}><u>{currencyFormatOne(dataFromTable?.money)}</u></b>
                                                    <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                                        บาท
                                                    </span>
                                                </div>
                                            </b> */}
                                            <div style={{ marginBottom: 0, }}>
                                                <span>ยอดเงิน : </span>
                                                <b>{currencyFormatOne(dataFromTable?.money)}</b>
                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                    บาท
                                                </span>
                                            </div>
                                            <div style={{ marginBottom: 0, }}>
                                                <span>ยอดลูกหนี้อื่นๆ : </span>
                                                <b>{currencyFormatOne(dataFromTable?.AROTHR)}</b>
                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                    บาท
                                                </span>
                                            </div>
                                            <div style={{ marginBottom: 0, }}>
                                                <span>ยอดรับฝาก : </span>
                                                <b>{currencyFormatOne(dataFromTable?.TOTAMT)}</b>
                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                    บาท
                                                </span>
                                            </div>
                                        </aside>
                                    </Row>
                                    <Row style={{ textAlign: 'right' }}>
                                        <Col span={24} style={{ textAlign: 'right' }}>
                                            <Button type='primary' onClick={checkart} >รายละเอียดลูกหนี้อื่นๆ</Button>
                                        </Col>
                                    </Row>
                                    <Divider />
                                    {/* <Row justify={'left'}><b><u>รายละเอียดอื่นๆ</u></b> </Row>
                                    <Row gutter={32} justify={'center'}>
                                        {
                                            dataAROTHR?.map((item, index) => {
                                                return renderItem({ item, index, key: uid })
                                            })
                                        }
                                    </Row>
                                    <Divider /> */}
                                    <Row justify={'left'}><b><u>รายละเอียดการจ่าย</u></b> </Row>
                                    <aside style={{ width: '90%' }}>
                                        {
                                            calData.text === "" ?
                                                <>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>ชำระค่างวด : </span>
                                                        <b>{currencyFormatOne(calData.ALLPAYAMT)}</b>
                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                            บาท
                                                        </span>
                                                    </div>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>ชำระดอกเบี้ย : </span>
                                                        <b>{currencyFormatOne(calData.ALLPAYINTEFF)}</b>
                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                            บาท
                                                        </span>
                                                    </div>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>ชำระเงินต้น : </span>
                                                        <b>{currencyFormatOne(calData.ALLPAYTON)}</b>
                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                            บาท
                                                        </span>
                                                    </div>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>ชำระค่าทวงถาม : </span>
                                                        <b>{currencyFormatOne(calData.ALLPAYFOLLOW)}</b>
                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                            บาท
                                                        </span>
                                                    </div>
                                                    {
                                                        calData?.Dok > 0 ?
                                                            <div style={{ marginBottom: 0, }}>
                                                                <span>ดอกเบี้ยรายวัน : </span>
                                                                <b>{currencyFormatOne(calData.Dok)}</b>
                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                    บาท
                                                                </span>
                                                            </div>
                                                            : null
                                                    }
                                                    {
                                                        calData?.PaySm > 0 ?
                                                            <div style={{ marginBottom: 0, }}>
                                                                <span>ดอกเบี้ยรายวัน : </span>
                                                                <b>{currencyFormatOne(calData.PaySm)}</b>
                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                    บาท
                                                                </span>
                                                            </div>
                                                            : null
                                                    }
                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>รวมยอดชำระ : </span>
                                                        <b>{currencyFormatOne(calData.ALLCAL)}</b>
                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                            บาท
                                                        </span>
                                                    </div>
                                                </>
                                                : calData.text === "เงินที่จ่ายเข้ามาเกินเงินต้นคงเหลือ" || calData.text === "จำนวนเงินที่จ่ายเข้ามา มากกว่าเงินต้นคงเหลือ" ?
                                                    <>
                                                        <div style={{ marginBottom: 0, }}>
                                                            <span>Note : </span>
                                                            <b><Tag color="geekblue">{calData.text}</Tag></b>
                                                        </div>
                                                        <div style={{ marginBottom: 0, }}>
                                                            <span>ยอดที่รับได้ไม่เกิน : </span>
                                                            <b>{currencyFormatOne(dataAll?.ccb)}</b>
                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                บาท
                                                            </span>
                                                        </div>
                                                    </>
                                                    : calData.text === "ไม่ตัดเข้าตารางดิว" ?
                                                        <>
                                                            <div style={{ marginBottom: 0, }}>
                                                                <span>Note : </span>
                                                                <b><Tag color="geekblue">{calData.text}</Tag></b>
                                                            </div>
                                                            <div style={{ marginBottom: 0, }}>
                                                                <span>ชำระค่างวด : </span>
                                                                <b>{currencyFormatOne(calData.ALLPAYAMT)}</b>
                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                    บาท
                                                                </span>
                                                            </div>
                                                            <div style={{ marginBottom: 0, }}>
                                                                <span>ชำระดอกเบี้ย : </span>
                                                                <b>{currencyFormatOne(calData.ALLPAYINTEFF)}</b>
                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                    บาท
                                                                </span>
                                                            </div>
                                                            <div style={{ marginBottom: 0, }}>
                                                                <span>ชำระเงินต้น : </span>
                                                                <b>{currencyFormatOne(calData.ALLPAYTON)}</b>
                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                    บาท
                                                                </span>
                                                            </div>
                                                            <div style={{ marginBottom: 0, }}>
                                                                <span>ชำระค่าทวงถาม : </span>
                                                                <b>{currencyFormatOne(calData.ALLPAYFOLLOW)}</b>
                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                    บาท
                                                                </span>
                                                            </div>
                                                            <div style={{ marginBottom: 0, }}>
                                                                <span>รวมยอดชำระ : </span>
                                                                <b>{currencyFormatOne(calData.ALLCAL)}</b>
                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                    บาท
                                                                </span>
                                                            </div>
                                                        </>
                                                        : <div style={{ marginBottom: 0, }}>
                                                            <span>Note : </span>
                                                            <b><Tag color="geekblue">{calData.text}</Tag></b>
                                                        </div>
                                        }
                                    </aside>
                                    <Divider />
                                    <Row>
                                        <Col span={24}>
                                            <Space>
                                                <Button onClick={buttonAmt} style={{ background: isClicked ? '#00FF00' : 'white' }}>ตารางค่างวด</Button>
                                                <Button disabled={checkButtonPay} onClick={buttonPay} style={{ background: !isClicked ? '#00FF00' : 'white' }}>ตารางรายละเอียดการจ่าย</Button>
                                            </Space>
                                        </Col>
                                    </Row>
                                    {
                                        checkButton ?
                                            <Table
                                                rowKey={(record) => record.uid}
                                                dataSource={dataTable}
                                                columns={columns}
                                                scroll={{
                                                    x: 1500,
                                                    y: 400,
                                                }}
                                                style={{ padding: 20 }}
                                            >
                                            </Table>
                                            :
                                            <Table
                                                rowKey={(record) => record.uid}
                                                dataSource={dataTableDetail}
                                                columns={columnsDetail}
                                                scroll={{
                                                    x: 1500,
                                                    y: 400,
                                                }}
                                                style={{ padding: 20 }}
                                            >
                                            </Table>
                                    }
                                </Card>
                            </div>
                        </Form>
                    </Row>
                    {contextHolder}
                </Spin>
            </Modal >
            {
                isModal ?
                    <Anothr
                        open={isModal}
                        close={setIsModal}
                        dataAROTHR={dataAROTHR}
                        dataHD={dataHD}
                    />
                    : null
            }
        </div>
    )
}
