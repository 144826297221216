// firebase.js
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import axios from 'axios';


// Firebase configuration ของคุณ
const firebaseConfig = {
    // apiKey: process.env.REACT_APP_APIKEY,
    // authDomain: process.env.REACT_APP_AUTHDOMAIN,
    // projectId: process.env.REACT_APP_PROJECTID,
    // storageBucket: process.env.REACT_APP_STORAGEBUCKET,
    // messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
    // appId: process.env.REACT_APP_APPID,
    // measurementId: process.env.REACT_APP_MEASUREMENTID
    apiKey: 'AIzaSyAoarvAEbm_3ONAepm1TSJL-bLlNQ4X1sk',
    authDomain: 'test-project-s-6a4cf.firebaseapp.com',
    projectId: 'test-project-s-6a4cf',
    storageBucket: 'test-project-s-6a4cf.appspot.com',
    messagingSenderId: '475120379296',
    appId: '1:475120379296:web:6398b833069585d17b30c5',
    measurementId: 'G-CG6X32YH9F'
};

// Initialize Firebase app
const app = initializeApp(firebaseConfig);

// ใช้ `app` นี้ในการตั้งค่าอื่น ๆ ของ Firebase
const messaging = getMessaging(app);

// รับ token เพื่อใช้ในการส่ง notification
export const requestForToken = async () => {
    return getToken(messaging, { vapidkey: 'BDhNrawZnWHRp409rSoJgg_q8WrXDjGmqFDig7CmOnNkzBWwBje4AV6zFmkySPugqn0Rh5RfTHfKWL0Rwjv-r7E', forceRefresh: true })
        .then(async (currentToken) => {
            if (currentToken) {
                console.log("FCM Token:", currentToken);
                localStorage.setItem('currentToken', currentToken);
                // await testSend(currentToken)
                // ส่ง token ไปยัง backend เพื่อใช้ในการส่งข้อความ
            } else {
                console.log("No registration token available.");
            }
        })
        .catch((err) => {
            console.log("An error occurred while retrieving token. ", err);
        });
};

// จัดการข้อความที่มาขณะหน้าเว็บเปิดอยู่
export const onMessageListener = async () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            console.log("Message received. ", payload);
            if (payload) {
                new Notification(payload.notification.title, {
                    body: payload.notification.body,
                });
            }
            resolve(payload);
        });
    });


// const testSend = async (currentToken) => {
//     try {
//         const response = await axios.post('https://api-search-contno-db2-i67f6gaaqa-as.a.run.app/api-db2/TestSendFCMNotification', {
//             fcmToken: currentToken,
//         });
//         console.log("Notification sent successfully:", response.data);
//     } catch (error) {
//         console.error("Error sending notification:", error);
//     }
// };