import React, { useState, useEffect } from "react";
import { PlusOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Col, Row, Table, Divider, Space, Card, Radio, Tag, Select, DatePicker, Pagination, Spin } from 'antd';

// import { useDispatch, useSelector } from 'react-redux';
// import { addCustomer } from '../../redux/Customer';
// import { addCar } from '../../redux/Car';
// import { addGuarantor } from '../../redux/Guarantor';

import dayjs from "dayjs";
import axios from "axios";
import ModalAddMemo from "./modal/ModalAddMemo";
import ModalEditMemo from "./modal/ModalEditMemo";
import { pgmaincountmemo, pgmaindatamemo } from "../file_mid/all_api";

export default function Main() {

  const currentDateTime = dayjs()
  const currentDateDay2 = dayjs()
  const showDay1 = dayjs("2023-01-01")
  const [dataPost, setDataPost] = useState({ sec: 0, days1: dayjs("2023-01-01"), days2: currentDateDay2, approvalStatus: "ทั้งหมด" })
  const [dataRecord, setDataRecord] = useState();
  const user = localStorage.getItem('username');
  // const branch = localStorage.getItem('branch');
  const [cData, setCData] = useState([]);
  // const [axiosData, setAxiosData] = useState([]); // Table
  const [arrayTable, setArrayTable] = useState(); // Array Table
  const [openAddMemo, setOpenAddMemo] = useState(false);
  const [openEditMemo, setOpenEditMemo] = useState(false);
  const token = localStorage.getItem('token')
  const [loading, setLoading] = useState(false)
  const [testPage, setTestPage] = useState();
  const [ssPage, setSsPage] = useState({ numberPage: 1, pageSize: 10 });
  const [currentPage, setCurrentPage] = useState(1)
  const [query, setQuery] = useState("");

  useEffect(() => {
    loadData();
    // loadCountData();
  }, [dataPost.days1, dataPost.days2, dataPost.approvalStatus, openAddMemo, openEditMemo, ssPage])

  // useEffect(() => {
  //   const delayDebounceFn = setTimeout(() => {
  //     console.log(query)
  //     searchLoadData(query)
  //   }, 1500)
  //   return () => clearTimeout(delayDebounceFn)
  // }, [query])

  // const searchLoadData = async (data) => {
  //   console.log("searchLoadData data", data)
  //   if (data !== "") {
  //     console.log("OK searchLoadData data")
  //     setCurrentPage(1)
  //     await loadData(data)
  //   }
  // }
  // const search = async (data) => {
  //   if (data.target.value !== "") {
  //     setQuery(data.target.value)
  //   } else {
  //     setSsPage({ numberPage: 1, pageSize: 10 })
  //     setCurrentPage(1)
  //     // setKeyWord("รอธุรการรับ")
  //     setDataPost({ sec: 0, days1: dayjs("2023-01-01"), days2: currentDateDay2, approvalStatus: 16 })
  //     setQuery("")
  //     // setTimeout(async () => {
  //     //   await loadData("stop")
  //     // }, 1500)
  //   }
  // };

  // console.log("branch", branch)
  // const loadCountData = async () => {
  //   const tk = JSON.parse(token)
  //   const headers = {
  //     "Authorization": `Bearer ${tk}`,
  //     "Menu": JSON.stringify("20")
  //   }
  //   var mainData = { reviewedBy: user, approvalStatus: dataPost.approvalStatus, day1: dataPost.days1, day2: dataPost.days2 }
  //   await axios.post(pgmaincountmemo, mainData, { headers: headers })
  //     .then((res) => {
  //       if (res.data === null) {
  //         setCData([])
  //       } else {
  //         setCData(res.data)
  //       }
  //     }).catch((err) => console.log(err))
  // }
  const loadCountData = async (data) => {
    const ez = data.filter(
      (item) =>
        item.approvalStatus === dataPost?.approvalStatus
    );
    console.log("ez", ez)
    console.log("data", data)
    setTestPage(...ez)
    setCData(data)
  }

  const loadData = async (data) => {
    let mainData = {}
    setLoading(true)
    const tk = JSON.parse(token)
    const headers = {
      "Authorization": `Bearer ${tk}`,
      "Menu": JSON.stringify("20")
    }
    if (query !== "" && data !== "stop") {
      mainData = { reviewedBy: user, approvalStatus: dataPost.approvalStatus, numberPage: ssPage?.numberPage, pageSize: ssPage?.pageSize, keyword: query, day1: dataPost.days1, day2: dataPost.days2 }
    } else {
      if (data === "stop") {
        mainData = { reviewedBy: user, approvalStatus: "ทั้งหมด", numberPage: ssPage?.numberPage, pageSize: ssPage?.pageSize, day1: dataPost.days1, day2: dataPost.days2 }
      } else {
        mainData = { reviewedBy: user, approvalStatus: dataPost.approvalStatus, numberPage: ssPage?.numberPage, pageSize: ssPage?.pageSize, day1: dataPost.days1, day2: dataPost.days2 }
      }
    }
    await axios.post(pgmaindatamemo, mainData, { headers: headers })
      .then((res) => {
        console.log(res.data)
        if (res.data.ShowTablePG) {
          setArrayTable(res.data.ShowTablePG)
          // setAxiosData(res.data)
          // await loadCountData();
        } else {
          setArrayTable([])
          // setAxiosData([])
        }
        if (res.data.Countstatus) {
          loadCountData(res.data.Countstatus);
        } else {
          setArrayTable([])
        }
        setLoading(false)
      }).catch((err) => console.log(err))
    setLoading(false)
  }

  const onChangePagination = (e, pageSize) => {
    // console.log(e, pageSize)
    setCurrentPage(e)
    setSsPage({ numberPage: e, pageSize: pageSize })
  }
  // const search = (data) => {
  //   // อนาคต ควรหาใน database โดยตรง
  //   const greaterThanTen = axiosData.filter(
  //     (item) =>
  //       item.firstName.toLowerCase().includes(data.target.value) ||
  //       item.lastName.toLowerCase().includes(data.target.value) ||
  //       item.carPlateNumber.toLowerCase().includes(data.target.value)
  //   );
  //   setArrayTable(greaterThanTen)
  // };

  const backc = {
    "ทั้งหมด": '#e6f4ff',
    "รอธุรการรับ": '#fffbe6',
    "ลูกค้าคอนเฟิร์ม": '#fffbe6',
    "รออนุมัติราคารถ": '#fff7e6',
    "รอวิเคราะห์": '#e6fffb',
    "ผ่านการวิเคราะห์": '#f0f5ff',
    "รออนุมัติ": '#fcffe6',
    "รอตรวจสอบเอกสาร": '#fcffe6',
    "อนุมัติ": '#f6ffed',
    "อนุมัติแบบมีเงื่อนไข": '#f6ffed',
    "โทรติด": '#fff0f6',
    "โทรไม่ติด(ติดต่อไม่ได้)": '#fff2e8',
    "ไม่อนุมัติ": '#fff1f0',
    "ไม่ผ่านการวิเคราะห์": '#fff1f0',
    "รออนุมัติราคาที่ดิน": '#fff1f0',
    "รอทำสัญญา": '#f6ffed',
    "ลูกค้าปฏิเสธ": '#fff1f0',
    "Reject": '#fff1f0',
  }
  const textc = {
    "ทั้งหมด": '#1677ff',
    "รอธุรการรับ": '#e39f23',
    "ลูกค้าคอนเฟิร์ม": '#e39f23',
    "รออนุมัติราคารถ": '#d86d38',
    "รอวิเคราะห์": '#3fbccf',
    "ผ่านการวิเคราะห์": '#384ecb',
    "รออนุมัติ": '#afc222',
    "รอตรวจสอบเอกสาร": '#afc222',
    "อนุมัติ": '#47ad55',
    "โทรติด": '#c73799',
    "โทรไม่ติด(ติดต่อไม่ได้)": '#e36729',
    "ไม่อนุมัติ": '#ff4d4f',
    "ไม่ผ่านการวิเคราะห์": '#ff4d4f',
    "รออนุมัติราคาที่ดิน": '#ff4d4f',
    "รอทำสัญญา": '#47ad55',
    "อนุมัติแบบมีเงื่อนไข": '#47ad55',
    "ลูกค้าปฏิเสธ": '#ff4d4f',
    "Reject": '#ff4d4f',
  }

  const columns = [
    {
      fixed: true,
      title: "ลำดับ",
      dataIndex: "index",
      key: 'index',
      width: "5%",
      align: 'center',
      render: (text, object, index) => index + 1
    },
    {
      key: 'carInfo',
      title: "รายละเอียด",
      dataIndex: "carPlateNumber",
      width: "25%",
      align: 'center',
      render: (text, record) => (
        <>
          <div>{record.snam}{record.firstName} {record.lastName}</div>
          <div>
            {
              record.car === "car" ?
                <div>
                  <div>{record.carBrand} {record.carModel} ปีรถ {parseInt(record.carYear) + 543} เลขทะเบียน {record.carPlateNumber}</div>
                  <div>เสนอโดย <Space style={{ color: "red" }}><b>{record.proposalBy}</b></Space></div>
                  {
                    record.reviewedBy !== null ?
                      <>
                        <div>ธุรการที่ได้เคส <Space style={{ color: "red" }}><b>{record.reviewedBy}</b></Space></div>
                      </>
                      : null
                  }
                </div>
                :
                <div>
                  <div>{record.carBrand} {record.carModel} ตารางวา {record.carYear} เลขโฉนด {record.carPlateNumber}</div>
                  <div>เสนอโดย <Space style={{ color: "red" }}><b>{record.proposalBy}</b></Space></div>
                  {
                    record.reviewedBy !== null ?
                      <div>ผ่านการวิเคราะห์โดย <Space style={{ color: "red" }}><b>{record.reviewedBy}</b></Space></div>
                      : null
                  }
                </div>
            }
          </div >
        </>
      )
    },
    {
      key: 'productLoanType',
      title: "ประเภทขอกู้",
      dataIndex: "productLoanType",
      width: "20%",
      align: 'center',
      render: (text, record) => (
        <>
          <div>
            {
              record.car === "car" ?
                <Tag color="purple">รถ</Tag>
                :
                <Tag color="green">ที่ดิน</Tag>
            }
            {
              <Tag color="geekblue">{record.productLoanType}</Tag>
            }
          </div>
        </>
      ),
    },
    {
      key: 'loanAmount',
      title: "ยอดขอกู้",
      dataIndex: "loanAmount",
      width: "10%",
      align: 'center',
      sorter: {
        compare: (a, b) => a.loanAmount - b.loanAmount,
        multiple: 2,
      },
      render: (text, record) => (
        <Space size="middle">
          {
            <Space>
              <>{currencyFormatOne(record.loanAmount)}</>
            </Space>
          }
        </Space>
      ),
    },
    {
      title: "เรท",
      dataIndex: "carPrice",
      align: 'center',
      width: "10%",
      sorter: {
        compare: (a, b) => a.carPrice - b.carPrice,
        multiple: 2,
      },
      render: (text, record) => (
        <Space size="middle">
          {
            <Space>
              <>{currencyFormatOne(record.carPrice)}</>
            </Space>
          }
        </Space>
      ),
    },
    {
      title: "วันที่เสนอ",
      dataIndex: "carInput",
      align: 'center',
      width: "10%",
      defaultSortOrder: 'descend',
      sorter: {
        compare: (a, b) => dayjs(a.carInput) - dayjs(b.carInput),
        multiple: 1,
      },
      render: (text, record) => (
        <>{dayjs(record.carInput).format("DD-MM-YYYY HH:mm")}</>
      ),

    },
    {
      key: 'approvalStatus',
      title: "สถานะ",
      dataIndex: "approvalStatus",
      width: "10%",
      align: 'center',
      render: (text, record) => {
        let color
        let color2
        if (record.approvalStatus === 16) {
          color = "gold"
        }
        if (record.approvalStatus === 24) {
          color = "gold"
        }
        if (record.approvalStatus === 27) {
          color = "gold"
        }
        if (record.approvalStatus === 17) {
          color = "orange"
        }
        if (record.approvalStatus === 18) {
          color = "cyan"
        }
        if (record.approvalStatus === 19) {
          color = "geekblue"
        }
        if (record.approvalStatus === 2) {
          color = "lime"
        }
        if (record.approvalStatus === 26) {
          color = "lime"
        }
        if (record.approvalStatus === 3) {
          color = "green"
        }
        if (record.approvalStatus === 22) {
          color = "red"
        }
        if (record.approvalStatus === 23) {
          color = "red"
        }
        if (record.approvalStatus === 20) {
          color = "red"
        }
        if (record.approvalStatus === 25) {
          color = "red"
        }
        if (record.productLoanType === "ย้ายไฟแนนซ์") {
          color2 = "green"
        }
        if (record.productLoanType === "ถือเล่มมา") {
          color2 = "geekblue"
        }
        if (record.productLoanType === "ซื้อ-ขาย") {
          color2 = "geekblue"
        }
        if (record.productLoanType === "ปรับโครงสร้าง") {
          color2 = "geekblue"
        }
        if (record.productLoanType === "Pre-Aaprove") {
          color2 = "magenta"
        }
        if (record.productLoanType === "Pre-Aaprove-ถือโฉนด(ที่ดิน)") {
          color2 = "magenta"
        }
        if (record.productLoanType === "Pre-Aaprove-ถือเล่มมา") {
          color2 = "magenta"
        }
        if (record.productLoanType === "Pre-Aaprove-ซื้อ-ขาย") {
          color2 = "magenta"
        }
        if (record.productLoanType === "Pre-Aaprove-ย้ายไฟแนนซ์") {
          color2 = "magenta"
        }
        if (record.productLoanType === "Pre-Aaprove-รีโอน") {
          color2 = "magenta"
        }
        if (record.productLoanType === "เปลี่ยนสัญญา") {
          color2 = "magenta"
        }
        if (record.productLoanType === "Pre-Aaprove-เปลี่ยนสัญญา") {
          color2 = "magenta"
        }
        if (record.productLoanType === "รี+เปลี่ยนสัญญา") {
          color2 = "magenta"
        }
        if (record.productLoanType === "ปรับ+เปลี่ยนสัญญา") {
          color2 = "magenta"
        }
        if (record.productLoanType === "Pre-Aaprove-รี-Ploan") {
          color2 = "magenta"
        }
        if (record.productLoanType === "รี-3") {
          color2 = "magenta"
        }
        if (record.productLoanType === "Pre-Aaprove-ปรับโครงสร้าง") {
          color2 = "magenta"
        }
        if (record.productLoanType === "Pre-Aaprove-รี") {
          color2 = "magenta"
        }
        if (record.productLoanType === "Pre-Aaprove-รี") {
          color2 = "magenta"
        }
        if (record.productLoanType === "Pre-Aaprove-รี+เปลี่ยนสัญญา") {
          color2 = "magenta"
        }
        if (record.productLoanType === "Pre-Aaprove-Ploan") {
          color2 = "magenta"
        }
        if (record.productLoanType === "ที่ดิน+บ้าน") {
          color2 = "geekblue"
        }
        if (record.productLoanType === "ฝากโฉนด") {
          color2 = "geekblue"
        }
        if (record.productLoanType === "Pre-Aaprove-ฝากโฉนด") {
          color2 = "geekblue"
        }
        return (
          <>
            <Tag color={color}>
              {record.approvalStatusName}
            </Tag>
          </>
        );
      },
    },
    {
      title: "Action",
      align: 'center',
      width: "12.5%",
      render: (record) => (
        <Space size="middle">
          {
            record.carMemo === '' || record.carMemo === null ?
              <>
                <Button type="primary" style={{ backgroundColor: "green" }} onClick={() => {
                  setOpenAddMemo(true)
                  setDataRecord(record)
                }}><PlusOutlined /> AddMemo</Button>
              </>
              :
              <>
                <Button type="primary" onClick={() => {
                  setOpenEditMemo(true)
                  setDataRecord(record)
                }}><EditOutlined /> EditMemo</Button>
              </>
          }
        </Space>
      ),
    },
  ];

  const currencyFormatOne = (amount) => {
    return Number(amount)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, '$&,')
  }

  return (
    <>
      <Card
        bordered={false}
        style={{
          width: 'auto',
        }}
      >
        <Row style={{ textAlign: 'center' }}>
          <Col span={24}>
            <Spin spinning={loading} size='large' tip=" Loading... ">
              <div className='text-center'>
                <h2>Memo Menu</h2>
              </div>
              <Row gutter={32} justify={'center'}>
                <Row gutter={24} justify={'center'} style={{ margin: 10 }}>
                  <Col className='gutter-row' span={24} style={{ textAlign: 'center' }}>
                    <Space>
                      <>{"เลือกวันที่ต้องการแสดงเคส :"}</>
                      <DatePicker format={'YYYY-MM-DD'}
                        defaultValue={showDay1}
                        style={{ height: '40px' }}
                        onChange={(e) => {
                          if (e) {
                            setDataPost({ ...dataPost, days1: e.format('YYYY-MM-DD') })
                          }
                        }} />
                      <>{"ถึง"}</>
                      <DatePicker format={'YYYY-MM-DD'}
                        defaultValue={currentDateTime}
                        style={{ height: '40px' }}
                        onChange={(e) => {
                          if (e) {
                            setDataPost({ ...dataPost, days2: e.format('YYYY-MM-DD') })
                          }
                        }} />
                    </Space>
                  </Col>
                </Row>

                <Row gutter={24} justify={'center'} style={{ margin: 10 }}>
                  <Col className='gutter-row' span={24} style={{ textAlign: 'center' }}>
                    <Space>
                      <>{"เลือกสถานะ :"}</>
                      <Select
                        defaultValue={"ทั้งหมด"}
                        style={{ width: 200 }}
                        onChange={(value) => setDataPost({ ...dataPost, approvalStatus: value })}
                        options={[
                          {
                            label: 'ทั้งหมด', value: 'ทั้งหมด',
                          },
                          {
                            label: 'รออนุมัติราคารถ', value: '17',
                          },
                          {
                            label: 'รอธุรการรับ', value: '16',
                          },
                          {
                            label: 'รอวิเคราะห์', value: '18',
                          },
                          {
                            label: 'ผ่านการวิเคราะห์', value: '19',
                          },
                          {
                            label: 'ไม่ผ่านการวิเคราะห์', value: '20',
                          },
                          {
                            label: 'รออนุมัติ', value: '2',
                          },
                          {
                            label: 'อนุมัติ', value: '3',
                          },
                          {
                            label: 'ไม่อนุมัติ', value: '22',
                          },
                          {
                            label: 'ลูกค้าคอนเฟิร์ม', value: '24',
                          },
                          {
                            label: 'รอทำสัญญา', value: '27',
                          },
                          {
                            label: 'ลูกค้าปฏิเสธ', value: '25',
                          },
                          {
                            label: 'รอตรวจสอบเอกสาร', value: '26',
                          },
                          {
                            label: 'Reject', value: '23',
                          },
                        ]}
                      />
                    </Space>
                  </Col>
                </Row>
                <Divider style={{ margin: 5 }} />

                <Radio.Group disabled  >
                  {
                    cData
                      .sort((a, b) => {
                        const order = [
                          "รอธุรการรับ",
                          "รออนุมัติราคารถ",
                          "รอวิเคราะห์",
                          "ผ่านการวิเคราะห์",
                          "ไม่ผ่านการวิเคราะห์",
                          "รออนุมัติ",
                          "อนุมัติ",
                          "อนุมัติแบบมีเงื่อนไข",
                          "ไม่อนุมัติ",
                          "รอตรวจสอบเอกสาร",
                          "รอทำสัญญา",
                          "ลูกค้าปฏิเสธ",
                          "Reject",
                          "ทั้งหมด",
                        ];

                        return order.indexOf(a.approvalStatus) - order.indexOf(b.approvalStatus);
                      })
                      .map((item) => (
                        <Radio.Button
                          style={{
                            backgroundColor: backc[item.name],
                            color: textc[item.name]
                          }}
                          value={item.approvalStatus}
                        >
                          {item.name} <b>{item.totalCount}</b>
                        </Radio.Button>
                      ))}
                </Radio.Group>
              </Row>
              <Row gutter={32} style={{ margin: 10 }}>
                {/* <Col className='gutter-row' span={24} style={{ textAlign: 'right' }}>
                <Input.Search
                  style={{ width: '250px' }}
                  placeholder="ค้นหา...."
                  onChange={search}
                  name="search"
                  id="search"
                />
              </Col> */}
                {/* <Col className='gutter-row' span={24} style={{ textAlign: 'right' }}>
                  <Input.Search
                    style={{ width: '250px' }}
                    placeholder="ค้นหา...."
                    onChange={search}
                    name="search"
                    id="search"
                    value={query}
                  />
                </Col> */}
              </Row>
              <Row gutter={32}>
                <Col span={24}>
                  <Table
                    pagination={false}
                    rowKey={(record) => record.uid}
                    dataSource={arrayTable}
                    columns={columns}
                    scroll={{
                      x: 1500,
                      y: 400,
                    }}
                    style={{ padding: 20 }}
                  >
                  </Table>
                  <Divider />
                  <Pagination
                    current={currentPage}
                    onChange={onChangePagination}
                    onShowSizeChange={onChangePagination}
                    defaultPageSize={10}
                    defaultCurrent={1}
                    total={testPage?.totalCount}
                  />
                  <Divider />
                </Col>
              </Row>
            </Spin>
          </Col>
        </Row>
      </Card>
      {
        openAddMemo ?
          <ModalAddMemo
            open={openAddMemo}
            close={setOpenAddMemo}
            dataFromTable={dataRecord}
          />
          : null
      }
      {
        openEditMemo ?
          <ModalEditMemo
            open={openEditMemo}
            close={setOpenEditMemo}
            dataFromTable={dataRecord}
          />
          : null
      }

    </>
  )
}
