import React, { useState, useEffect } from 'react'
import { Form, Modal, Row, Col, Image, Space, Card, Button, Tag, Divider, notification, Input, Upload, message, Spin } from 'antd'
import { SendOutlined, PlusOutlined } from '@ant-design/icons'
import dayjs from 'dayjs';
import axios from "axios";
import '../../css/Media.css'
import { useSelector, useDispatch } from 'react-redux';
import { addImage } from '../../../redux/Image';
import Compressor from 'compressorjs';
import Currency from 'currency.js';
import { checkguarantors, customerconfirmland, loadOJS, makenoteresend, mutips3, mutips4pre, resendlandmk1, getImagess, getImagessPre, getImagessGua } from '../../file_mid/all_api';
import { ApprovalStatusColor } from '../../file_mid/status_color';

function DescriptionAllLand({ getData, close, saveResendNote, newDataLand, checkPreNotPass }) {
    const { TextArea } = Input;
    const guarantor = useSelector(state => state.guarantor)
    const dispatch = useDispatch()
    const token = localStorage.getItem('token');
    const [api, contextHolder] = notification.useNotification();
    const [dataLand] = useState(getData);
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()
    const { confirm } = Modal;
    const [guarantor1, setGuarantor1] = useState([])
    const [notes] = useState(getData?.lands?.landLoanDetails?.notes)
    const [address] = useState(getData?.address)
    const [dataPhoneCus] = useState(getData?.phones);
    const [dataEmail, setDataEmail] = useState([]);
    const [dataSocials, setDataSocials] = useState([]);
    const [career, setCareer] = useState([]) // เก็บข้อมูลอาชีพ
    const [imageBlobzz, setImageBlobzz] = useState(getData?.lands?.img);

    //เพิ่มรูป
    const [fileListCustomer, setFileListCustomer] = useState([]);
    const [fileListCustomerImageCareer, setFileListCustomerImageCareer] = useState([]);
    const [fileListStatement, setFileListStateMent] = useState([]);
    const [fileListStatementPDF, setFileListStateMentPDF] = useState([]);
    const [fileListOtherMent, setFileListOtherMent] = useState([]);
    const [fileListLand, setFileListLand] = useState([]);
    const [fileListDeep, setFileListDeep] = useState([]);
    const [fileListCustomerLeafDivider, setFileListCustomerLeafDivider] = useState([]);
    const [fileListCustomerBurden, setFileListCustomerBurden] = useState([]);
    const [fileListGuarantor, setFileListGuarantor] = useState([]);
    const [allImg, setAllImg] = useState([]);
    const newFormData = new FormData();
    const [resultImage, setResultImage] = useState([]);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [checkImage, setCheckImage] = useState();
    const [loanTermDATA, setloanTermDATA] = useState({})
    const [resultRateDATA, setresultRateDATA] = useState({})
    const [resultPriceDATA, setresultPriceDATA] = useState({})
    const [checkSendNEW, setCheckSendNEW] = useState(false);
    var note = notes?.length;
    var lastIndex = note - 1;

    useEffect(() => {
        loadCareer()
        loadGuarantors()
        if (getData?.lands?.landLoanDetails?.noteResend?.offercaseNote) {
            form.setFieldsValue({
                offercaseNote: getData?.lands?.landLoanDetails?.noteResend?.offercaseNote
            })
        }
        land_Calculate(getData?.lands?.landLoanDetails)
    }, [])

    useEffect(() => {
        imageBlobzz?.map((e, index) => {
            var PreAaproveSplit = []
            PreAaproveSplit = e.pathImage.split("/")
            if (PreAaproveSplit[3] === "13") {
                setCheckSendNEW(true)
            }
        })
    }, [imageBlobzz])

    const land_Calculate = async (value) => {
        var interestRateLand = parseFloat(value?.interestRateLand) / 100 // อัตราดอก / 100
        var rate = (parseFloat(value?.approvedLoanAmount) * Currency(interestRateLand, { precision: 3 }).value) * parseInt(value?.approvedLoanTerm) // (ยอดจัด * อัตราดอกที่หาร100แล้ว ,precision: 3 คือทศนิยม 3 )*งวด
        var loanTerm = Math.ceil(Currency((rate + parseFloat(value?.approvedLoanAmount)) / parseInt(value?.approvedLoanTerm), { precision: 2 }).value) // (ดอกเบี้ยทั้งหมด + ยอดจัด)/ งวด
        var resultRate = (loanTerm * parseInt(value?.approvedLoanTerm)) - parseFloat(value?.approvedLoanAmount)
        var resultPrice = loanTerm * parseInt(value?.approvedLoanTerm)

        setloanTermDATA(loanTerm)
        setresultRateDATA(resultRate)
        setresultPriceDATA(resultPrice)
    }

    const loadCareer = async () => {
        const id = { customerId: getData?.customerId }
        setLoading(true)
        await axios.post(loadOJS, id)
            .then((res) => {
                setCareer(res.data)
                setLoading(false)
            })
            .catch((err) => console.log(err))
        setLoading(false)
    }

    const SuccSend = (placement) => {
        api.success({
            message: <b>ส่งเคสสำเร็จ</b>,
            // description: `กรุณาเพิ่มที่อยู่ผู้ค้ำ ลำดับที่ ${index + 1}`,
            placement,
        });
    }

    const loadGuarantors = async () => { //คนค้ำ
        var dataloadGua = { typeLoan: "land", loanId: getData?.lands?.landLoanDetails?.landLoanId }
        await axios.post(checkguarantors, dataloadGua)
            .then(res => {
                if (res.status === 200) {
                    setGuarantor1(res.data)
                } else if (res.status === 204) {
                    // console.log("ไม่พบข้อมูล")
                }
            }).catch((err) => {
                console.log("err", err)
            })
    }

    const showConfirm = async () => {
        var mix = { ...getData.lands.landLoanDetails, guarantors: guarantor?.data, land: { ...getData.lands, img: resultImage, landInput: dayjs().format("YYYY-MM-DD") }, approvalStatus: 16 }
        console.log("mix", mix)
        confirm({
            title: 'คุณต้องการที่จะส่งเคสนี้อีกครั้ง...?',
            onOk() {
                axios.put(resendlandmk1, mix)
                    .then(res => {
                        if (res.status === 200) {
                            SuccSend("top")
                            handleSendNote()
                        }
                    }).catch((err) => console.log(err))
            },
            onCancel() {
                dispatch(addImage())
            },
        });
    };

    const handleSendNote = async () => {
        axios.post(makenoteresend, saveResendNote)
            .then(res => {
                if (res.status === 200) {
                    setTimeout(() => {
                        close(false)
                    }, 1500);
                }
            }).catch((err) => console.log(err))
    };

    const showConfirmOK = () => {
        confirm({
            title: 'ผู้กู้ยอมรับยอดที่อนุมัติ...?',
            onOk() {
                var checkOK = true
                customerConfirm(checkOK)
                // setTimeout(() => {

                // }, 1500)
            },
            onCancel() {
            },
        });
    };

    const showConfirmCancel = () => {
        confirm({
            title: 'ผู้กู้ปฏิเสธยอดที่อนุมัติ...?',
            onOk() {
                var checkOK = false
                customerConfirm(checkOK)

            },
            onCancel() {
            },
        });
    };

    const customerConfirm = async (checkOK) => {
        setLoading(true)
        if (checkOK === true) {
            var data = { landLoanId: getData?.lands?.landLoanDetails?.landLoanId, approvalStatus: 24, cusIsAccepted: true, cusIsAcceptedDate: dayjs() }
        } else {
            var data = { landLoanId: getData?.lands?.landLoanDetails?.landLoanId, approvalStatus: 25, cusIsAccepted: false, cusIsAcceptedDate: dayjs() }
        }
        delete data.rejectDate
        await axios.put(customerconfirmland, data)
            .then(async res => {
                if (res.status === 200) {
                    setLoading(false)
                    if (data.approvalStatus === 24) {
                        message.success('ผู้กู้ยอมรับยอดที่อนุมัติ')
                    } else {
                        message.error('ผู้กู้ปฏิเสธยอดที่อนุมัติ')
                    }
                    await handleUpload()
                    setTimeout(() => {
                        handleCancel();
                    }, 2000)
                }
            }).catch((err) => {
                console.log("err", err)
            })
    };


    const handleUpload = async () => {
        console.log("allImg", allImg)
        if (allImg?.length > 0) {
            await imgSend(allImg)
        }
    };

    const imgSend = (resultImgs) => {
        setLoading(true);
        return new Promise(async (resolve, reject) => {
            try {
                if (checkPreNotPass) {
                    var status;
                    // newFormData.append('idcus', getData?.lands?.landId);
                    //newFormData.append('productTypeId', "1");
                    newFormData.append('tm', dayjs().format("YYYY-MM-DDTHH:mm:ssZ"));

                    for (const file of resultImgs) {
                        var image = {}
                        newFormData.append('file', file.imageCompressedFile);
                        newFormData.append("type", file.type);
                        newFormData.append('idcus', file?.imgid);
                        image.type = file.type
                        resultImage.push(image)
                    }
                    const response = await axios.post(mutips4pre, newFormData);
                    if (response.data) {
                        //success(randomTenDigitNumber);
                        console.log("TY Pre", response.data);
                        //dispatch(addSocial(response.data))
                        status = response.status;
                        resolve(status);
                    }
                } else {
                    var status;
                    newFormData.append('idcus', getData?.lands?.landId);
                    //newFormData.append('productTypeId', "1");
                    newFormData.append('tm', dayjs().format("YYYY-MM-DDTHH:mm:ssZ"));

                    for (const file of resultImgs) {
                        var image = {}
                        newFormData.append('file', file.imageCompressedFile);
                        newFormData.append("type", file.type);
                        image.type = file.type
                        resultImage.push(image)
                    }
                    //const response = await axios.post('http://localhost:8060/Apimg/mutips-3', newFormData);
                    const response = await axios.post(mutips3, newFormData);
                    if (response.data) {
                        //success(randomTenDigitNumber);
                        console.log("TY", response.data);
                        //dispatch(addSocial(response.data))
                        status = response.status;
                        resolve(status);
                    }
                }

            } catch (error) {
                error(error);
                reject(error);
            } finally {
                setLoading(false);
                console.log("OK")
            }
        });
    };

    const handleCancel = () => {
        close()
    }

    const currencyFormatOne = (amount) => {
        return Number(amount)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, '$&,')
    }

    const renderGuarantorNewImg = ({ item, index }) => {
        var itemSplit = []
        if (item?.pathImg) {
            itemSplit = item.pathImg.split("/")
        }
        return (
            <>
                <div><b><u>ลำดับที่ {index + 1}</u></b></div>
                <aside style={{ width: '90%' }}>
                    <div style={{ marginBottom: 0 }}>
                        <span>ชื่อ-นามสกุล : </span><b>{item?.snam + ' ' + item?.firstname + ' ' + item?.lastname}</b>
                    </div>
                    {item?.phones?.length > 0 ?
                        <>
                            <div style={{ marginBottom: 0 }}>
                                <span>เบอร์โทรติดต่อ : </span> <b>{item?.phones[0]?.telp}</b>
                            </div>
                        </>
                        : <div style={{ marginBottom: 0 }}>
                            <span>เบอร์โทรติดต่อ : </span> <b>ไม่มี</b>
                        </div>
                    }
                    {item?.address?.length > 0 ?
                        <>
                            <div style={{ marginBottom: 0 }}>
                                <span > ที่อยู่ :  </span>
                                <b>
                                    {
                                        item.address[0]?.houseNumber + ' ' +
                                        "หมู่ " + item.address[0]?.soi + ' ' +
                                        "ถ." + item.address[0]?.road + ' ' +
                                        "ต." + item.address[0]?.subdistrict + ' ' +
                                        "อ." + item.address[0]?.district + ' ' +
                                        "จ." + item.address[0]?.province + ' '
                                        + item.address[0]?.zipCode
                                    }
                                </b>
                            </div>
                        </>
                        : null
                    }
                    {itemSplit.length > 0 ? (
                        <>
                            <Row justify={"center"}>
                                {
                                    imageBlobzz?.map((items, index) => {
                                        if (items.type === 5) {
                                            const segments = items.pathImage.split("/")
                                            if (itemSplit[4] === segments[4]) {
                                                return (
                                                    // <Image width={'50px'} key={index} src={`data:${items?.Attr};base64,${items?.data?.toString('base64')}`} alt={`Image ${index + 1}`} />
                                                    <Image width={"50px"} key={index} src={`${getImagessGua}/${items.pathImage}?token=${token}`} alt={`รูปภาพ ${index + 1}`} />
                                                )
                                            }
                                            // return null;
                                        }
                                        return null;
                                    })
                                }
                            </Row>
                        </>
                    ) : null
                    }

                </aside>
                <Divider style={{ margin: 5 }} />
            </>
        )
    }

    const renderItemAddress = ({ item, index }) => { //item คือตัวแปรที่เอาไว้ดึงค่าของข้อมูลออกมาแสดง โดยใช้ item.ชื่อฟิวล์
        return (
            <>
                <div><b><u>ลำดับที่ {index + 1}</u></b></div>
                <aside style={{ width: '90%' }}>
                    <div style={{ marginBottom: 0 }}>
                        <span>เลขทะเบียนบ้าน : </span> <b>{item.houseRegistrationNumber}</b>
                    </div>
                    <div
                        style={{ marginBottom: 0 }}>
                        <span>ถนน : </span><b>{item.road}</b>
                    </div>
                    <div style={{ marginBottom: 0 }}>
                        <span>ตำบล : </span> <b>{item.subdistrict}</b>
                    </div>
                    <div style={{ marginBottom: 0 }}>
                        <span>บ้านเลขที่ : </span> <b>{item.houseNumber}</b>
                    </div>
                    <div style={{ marginBottom: 0 }}>
                        <span>หมู่บ้าน : </span> <b>{item.village}</b>
                    </div>
                    <div style={{ marginBottom: 0 }}>
                        <span>อำเภอ : </span> <b>{item.district}</b>
                    </div>
                    <div style={{ marginBottom: 0 }}>
                        <span>หมู่ / ซอย : </span> <b>{item.soi}</b>
                    </div>
                    <div
                        style={{ marginBottom: 0 }}>
                        <span>จังหวัด : </span> <b>{item.province}</b>
                    </div>
                    <div style={{ marginBottom: 0 }}>
                        <span>รหัสไปรษณีย์ : </span> <b>{item.zipCode}</b>
                    </div>

                </aside>
                {address.length >= 1 ? <Divider /> : null}
            </>
        )
    }
    const renderItemPhones = ({ item, index }) => { //item คือตัวแปรที่เอาไว้ดึงค่าของข้อมูลออกมาแสดง โดยใช้ item.ชื่อฟิวล์
        return (
            <>
                <div
                    style={{ marginBottom: 0 }}
                >
                    <span>{item.phoneType ? <>{item.phoneType} : </> : null}</span>
                    <b> {item.telp}</b>
                </div>
            </>
        )
    }

    const handleCustomerImage = async ({ fileList }) => {

        for (const removedFile of fileListCustomer.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 4)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 4));
                setAllImg(updatedAllImg);
            }
        }
        setFileListCustomer(fileList);
        setCheckImage(fileList);
        //setAllImg({ ...allImg, ts4: fileList });
        setLoading(false)
    };
    const handleCustomerImageCareer = async ({ fileList }) => {

        for (const removedFile of fileListCustomerImageCareer.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 6)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 6));
                setAllImg(updatedAllImg);
            }
        }
        setFileListCustomerImageCareer(fileList);
        setCheckImage(fileList);
        //setAllImg({ ...allImg, ts6: fileList });
        setLoading(false)
    };
    const handleLand = async ({ fileList }) => {

        for (const removedFile of fileListLand.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 1)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 1));
                setAllImg(updatedAllImg);
            }
        }
        setFileListLand(fileList);
        setCheckImage(fileList);
        //setAllImg({ ...allImg, ts1: fileList });
        setLoading(false)
    };
    const handleDeep = async ({ fileList }) => {

        for (const removedFile of fileListDeep.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 3)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 3));
                setAllImg(updatedAllImg);
            }
        }
        setFileListDeep(fileList);
        setCheckImage(fileList);
        //setAllImg({ ...allImg, ts3: fileList });
        setLoading(false)
    };
    const handleChangeCustomerLeafDivider = async ({ fileList }) => {

        for (const removedFile of fileListCustomerLeafDivider.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 10)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 10));
                setAllImg(updatedAllImg);
            }
        }
        setFileListCustomerLeafDivider(fileList);
        setCheckImage(fileList);
        //setAllImg({ ...allImg, ts10: fileList });
        setLoading(false)
    };
    const handleChangeCustomerBurden = async ({ fileList }) => {

        for (const removedFile of fileListCustomerBurden.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 11)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 11));
                setAllImg(updatedAllImg);
            }
        }
        setFileListCustomerBurden(fileList)
        setCheckImage(fileList);
        //setAllImg({ ...allImg, ts11: fileList });
        setLoading(false)
    };

    const handleStatement = async ({ fileList }) => {

        for (const removedFile of fileListStatement.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 8)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 8));
                setAllImg(updatedAllImg);
            }
        }
        setFileListStateMent(fileList);
        setCheckImage(fileList);
        //setAllImg({ ...allImg, ts8: fileList });
        setLoading(false)
    };
    const handleStatementPDF = async ({ fileList }) => {

        for (const removedFile of fileListStatementPDF.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 7)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 7));
                setAllImg(updatedAllImg);
            }
        }
        setFileListStateMentPDF(fileList);
        setCheckImage(fileList);
        //setAllImg({ ...allImg, ts7: fileList });
        setLoading(false)
    };

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);
    };
    const compressImage = async (ez, types, landId) => {
        var image = {};
        return new Promise(async (resolve) => {
            if (types !== 7) {
                if (ez.size > 4000000) {
                    console.log("4m ไม่ให้ส่ง");
                    resolve(true);  // บอกให้ระบบทราบว่ากระบวนการเสร็จสิ้น
                } else {
                    let quality;
                    if (ez.size >= 2500000 && ez.size <= 4000000) {
                        quality = 0.1;
                    } else if (ez.size >= 2000000 && ez.size <= 2499999) {
                        quality = 0.15;
                    } else if (ez.size >= 1500000 && ez.size <= 1999999) {
                        quality = 0.2;
                    } else if (ez.size >= 1000000 && ez.size <= 1499999) {
                        quality = 0.3;
                    } else {
                        quality = 0.5;
                    }

                    try {
                        // ใช้ Promise เพื่อบีบอัดรูปภาพ
                        const compressedFile = await new Promise((resolveCompress) => {
                            new Compressor(ez, {
                                quality: quality,
                                success: (result) => {
                                    resolveCompress(result);
                                },
                            });
                        });

                        const fileOptions = {
                            type: compressedFile.type,
                            lastModified: Date.now(),
                        };
                        const compressedFileAsFile = new File([compressedFile], ez.name, fileOptions);
                        image.imageCompressedFile = compressedFileAsFile;
                        image.type = types
                        image.imgid = landId
                        allImg.push(image);
                        //setAllImg((prevAllImg) => [...prevAllImg, image]);

                        // ตรงนี้คือการ resolve ไฟล์ที่ถูกบีบอัด
                        resolve(image);
                    } catch (error) {
                        console.error('Image compression error:', error);
                        resolve(true);  // บอกให้ระบบทราบว่ากระบวนการเสร็จสิ้น
                    }
                }
            } else {
                // ... (โลจิกสำหรับ type 7)
                image.imageCompressedFile = ez;
                image.type = types
                image.imgid = landId
                allImg.push(image);
                //setAllImg((prevAllImg) => [...prevAllImg, image]);
                resolve(true);  // บอกให้ระบบทราบว่ากระบวนการเสร็จสิ้น
            }
        });
    };
    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };
    const beforeUpts = async (file, ty, landId) => {
        try {
            setLoading(true)
            const compressedFile = await compressImage(file, ty, landId);
            return Promise.resolve(compressedFile);
        } catch (error) {
            console.error('Image compression error:', error);
            message.error('Image compression failed.');
            return Promise.reject(); // เปลี่ยนให้ return Promise.reject() เมื่อไม่สามารถบีบอัดได้
        }
    };
    const handleCancelPreview = () => {
        setPreviewVisible(false);
    }

    const renderLandBuildings = ({ item, index }) => { //render Land Array
        return (
            <>
                <Row justify={'left'}><b><u>ใบประเมิน/รูปที่ดิน/สิ่งปลูกสร้าง-ที่ดินปัจจุบัน ({item.numberLand})</u></b></Row>
                <Row gutter={32} justify={'center'}>
                    <Space direction="vertical">
                        <Row justify={'center'}>
                            {item?.img?.map((e, index) => {
                                var PreAaproveSplit = []
                                PreAaproveSplit = e.pathImage.split("/")
                                if (PreAaproveSplit[3] !== "13") {
                                    if (e.type === 1) {
                                        return (
                                            <div style={{ margin: 3 }}>
                                                <Image width={"100px"} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                            </div>
                                        );
                                    }
                                }
                                return null;
                            })}
                        </Row>
                        {
                            getData?.lands?.landLoanDetails?.approvalStatus === 21 ?
                                <>
                                    <div className='gutter-row' span={12}>
                                        <b>
                                            <Form.Item label='เพิ่มรูป' name='imageIDGuCar'>
                                                <Upload
                                                    name='imageIDGuCar'
                                                    multiple={true}
                                                    listType="picture-card"
                                                    fileList={fileListLand}
                                                    onPreview={handlePreview}
                                                    onChange={handleLand}
                                                    //beforeUpload={() => false}
                                                    beforeUpload={(file) => beforeUpts(file, 1, item.landId).then(() => false).catch(() => true)}
                                                >
                                                    {fileListLand?.length >= 15 ? null : (
                                                        <div>
                                                            <PlusOutlined />
                                                            <div className="ant-upload-text">upload</div>
                                                        </div>
                                                    )}
                                                </Upload>
                                                <Modal open={previewVisible} onCancel={handleCancelPreview}>
                                                    <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                </Modal>
                                            </Form.Item>
                                        </b>
                                    </div>
                                </>
                                : null
                        }
                    </Space>
                </Row>
                <Divider />
                {
                    checkSendNEW ?
                        <>
                            <Row justify={'left'}><b><u>ใบประเมิน/รูปที่ดิน/สิ่งปลูกสร้าง-ที่ดินปัจจุบัน ({item.numberLand})</u> (ใหม่)</b></Row>
                            <Row gutter={32} justify={'center'}>
                                <Space direction="vertical">
                                    <Row gutter={32} justify={'center'}>
                                        {item?.img?.map((e, index) => {
                                            var PreAaproveSplit = []
                                            PreAaproveSplit = e.pathImage.split("/")
                                            if (PreAaproveSplit[3] === "13") {
                                                if (e.type === 1) {
                                                    return (
                                                        <>
                                                            <Image width={"100px"} key={index} src={`${getImagessPre}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                        </>
                                                    );
                                                }
                                            }
                                            return null;
                                        })}
                                    </Row>
                                </Space>
                            </Row>
                            <Divider />
                        </>
                        : null
                }
            </>
        )
    }
    const renderTitleDeed = ({ item, index }) => { //render Land Array
        return (
            <>
                <Row justify={'left'}><b><u>รูปโฉนด/หน้า-หลัง ({item.numberLand})</u></b></Row>
                <Row gutter={32} justify={'center'}>
                    <Space direction="vertical">
                        <Row justify={'center'}>
                            {item?.img?.map((e, index) => {
                                var PreAaproveSplit = []
                                PreAaproveSplit = e.pathImage.split("/")
                                if (PreAaproveSplit[3] !== "13") {
                                    if (e.type === 3) {
                                        return (
                                            <Row gutter={32} justify={'center'}>
                                                <Col span={24} style={{ textAlign: 'center' }}>
                                                    <Image style={{ margin: 3 }} width={'100px'} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                </Col>
                                            </Row>
                                        );
                                    }
                                }
                                return null;
                            })}
                        </Row>
                        {
                            getData?.lands?.landLoanDetails?.approvalStatus === 21 ?
                                <>
                                    <div className='gutter-row' span={12}>
                                        <b>
                                            <Form.Item label='เพิ่มรูป' name='imageIDGuCar'>
                                                <Upload
                                                    name='imageIDGuCar'
                                                    multiple={true}
                                                    listType="picture-card"
                                                    fileList={fileListDeep}
                                                    onPreview={handlePreview}
                                                    onChange={handleDeep}
                                                    beforeUpload={(file) => beforeUpts(file, 3, item.landId).then(() => false).catch(() => true)}
                                                >
                                                    <div>
                                                        <PlusOutlined />
                                                        <div className="ant-upload-text">upload</div>
                                                    </div>
                                                </Upload>
                                                <Modal open={previewVisible} onCancel={handleCancelPreview}>
                                                    <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                </Modal>
                                            </Form.Item>
                                        </b>
                                    </div>
                                </>
                                : null
                        }
                    </Space>
                </Row>
                <Divider />
                {
                    checkSendNEW ?
                        <>
                            <Row justify={'left'}><b><u>รูปโฉนด/หน้า-หลัง ({item.numberLand})</u> (ใหม่)</b></Row>
                            <Row gutter={32} justify={'center'}>
                                <Space direction="vertical">
                                    <Row gutter={32} justify={'center'}>
                                        {item?.img?.map((e, index) => {
                                            var PreAaproveSplit = []
                                            PreAaproveSplit = e.pathImage.split("/")
                                            if (PreAaproveSplit[3] === "13") {
                                                if (e.type === 3) {
                                                    return (
                                                        <>
                                                            <Image width={"100px"} key={index} src={`${getImagessPre}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                        </>
                                                    );
                                                }
                                            }
                                            return null;
                                        })}
                                    </Row>
                                </Space>
                            </Row>
                            <Divider />
                        </>
                        : null
                }
            </>
        )
    }
    const renderDivision = ({ item, index }) => { //render Land Array
        return (
            <>
                <Row justify={'left'}><b><u>ใบแบ่งใบต่อ ({item.numberLand})</u></b></Row>
                <Row gutter={32} justify={'center'}>
                    <Space direction="vertical">
                        <Row justify={'center'}>
                            {item?.img?.map((e, index) => {
                                var PreAaproveSplit = []
                                PreAaproveSplit = e.pathImage.split("/")
                                if (PreAaproveSplit[3] !== "13") {
                                    if (e.type === 10) {
                                        return (
                                            <>
                                                <Row gutter={32} justify={'center'}>
                                                    <Col span={24} style={{ textAlign: 'center' }}>
                                                        <Image style={{ margin: 3 }} width={'100px'} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                    </Col>
                                                </Row>
                                            </>
                                        );
                                    }
                                }
                                return null;
                            })}
                        </Row>
                        {
                            getData?.lands?.landLoanDetails?.approvalStatus === 21 ?
                                <>
                                    <div className='gutter-row' span={12}>
                                        <b>
                                            <Form.Item label='เพิ่มรูป' name='imageIDGuCar'>
                                                <Upload
                                                    name='imageIDGuCar'
                                                    multiple={true}
                                                    listType="picture-card"
                                                    fileList={fileListCustomerLeafDivider}
                                                    onPreview={handlePreview}
                                                    onChange={handleChangeCustomerLeafDivider}
                                                    beforeUpload={(file) => beforeUpts(file, 10, item.landId).then(() => false).catch(() => true)}
                                                >
                                                    <div>
                                                        <PlusOutlined />
                                                        <div className="ant-upload-text">upload</div>
                                                    </div>
                                                </Upload>
                                                <Modal open={previewVisible} onCancel={handleCancelPreview}>
                                                    <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                </Modal>
                                            </Form.Item>
                                        </b>
                                    </div>
                                </>
                                : null
                        }
                    </Space>
                </Row>
                <Divider />
                {
                    checkSendNEW ?
                        <>
                            <Row justify={'left'}><b><u>ใบแบ่งใบต่อ ({item.numberLand})</u> (ใหม่)</b></Row>
                            <Row gutter={32} justify={'center'}>
                                <Space direction="vertical">
                                    <Row gutter={32} justify={'center'}>
                                        {item?.img?.map((e, index) => {
                                            var PreAaproveSplit = []
                                            PreAaproveSplit = e.pathImage.split("/")
                                            if (PreAaproveSplit[3] === "13") {
                                                if (e.type === 10) {
                                                    return (
                                                        <>
                                                            <Image width={"100px"} key={index} src={`${getImagessPre}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                        </>
                                                    );
                                                }
                                            }
                                            return null;
                                        })}
                                    </Row>
                                </Space>
                            </Row>
                            <Divider />
                        </>
                        : null
                }
            </>
        )
    }
    const renderLawang = ({ item, index }) => { //render Land Array
        return (
            <>
                <Row justify={'left'}><b><u>ระวาง ({item.numberLand})</u></b></Row>
                <Row gutter={32} justify={'center'}>
                    <Space direction="vertical">
                        <Row justify={'center'}>
                            {item?.img?.map((e, index) => {
                                var PreAaproveSplit = []
                                PreAaproveSplit = e.pathImage.split("/")
                                if (PreAaproveSplit[3] !== "13") {
                                    if (e.type === 11) {
                                        return (
                                            <>
                                                <Row gutter={32} justify={'center'}>
                                                    <Col span={24} style={{ textAlign: 'center' }}>
                                                        <Image style={{ margin: 3 }} width={'100px'} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                    </Col>
                                                </Row>
                                            </>
                                        );
                                    }
                                }
                                return null;
                            })}
                        </Row>
                        {
                            getData?.lands?.landLoanDetails?.approvalStatus === 21 ?
                                <>
                                    <div className='gutter-row' span={12}>
                                        <b>
                                            <Form.Item label='เพิ่มรูป' name='imageIDGuCar'>
                                                <Upload
                                                    name='imageIDGuCar'
                                                    multiple={true}
                                                    listType="picture-card"
                                                    fileList={fileListCustomerBurden}
                                                    onPreview={handlePreview}
                                                    onChange={handleChangeCustomerBurden}
                                                    beforeUpload={(file) => beforeUpts(file, 11, item.landId).then(() => false).catch(() => true)}
                                                >
                                                    <div>
                                                        <PlusOutlined />
                                                        <div className="ant-upload-text">upload</div>
                                                    </div>
                                                </Upload>
                                                <Modal open={previewVisible} onCancel={handleCancelPreview}>
                                                    <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                </Modal>
                                            </Form.Item>
                                        </b>
                                    </div>
                                </>
                                : null
                        }
                    </Space>
                </Row>
                <Divider />
                {
                    checkSendNEW ?
                        <>
                            <Row justify={'left'}><b><u>ระวาง ({item.numberLand})</u> (ใหม่)</b></Row>
                            <Row gutter={32} justify={'center'}>
                                <Space direction="vertical">
                                    <Row gutter={32} justify={'center'}>
                                        {item?.img?.map((e, index) => {
                                            var PreAaproveSplit = []
                                            PreAaproveSplit = e.pathImage.split("/")
                                            if (PreAaproveSplit[3] === "13") {
                                                if (e.type === 11) {
                                                    return (
                                                        <>
                                                            <Image width={"100px"} key={index} src={`${getImagessPre}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                        </>
                                                    );
                                                }
                                            }
                                            return null;
                                        })}
                                    </Row>
                                </Space>
                            </Row>
                            <Divider />
                        </>
                        : null
                }
            </>
        )
    }

    return (
        <>
            <Row justify={'center'}>
                <Spin spinning={loading} size='large' tip=" Loading... ">
                    <Row justify={'center'} style={{ fontSize: '18px' }} >รายละเอียดการขอกู้</Row>
                    <Form
                        labelCol={{
                            span: 12,
                        }}
                        wrapperCol={{
                            span: 24,
                        }}
                        style={{
                            width: '100%',
                        }}
                        form={form}
                        // onFinish={handleSubmit}
                        autoComplete="off"
                        initialValues={{ remember: true }}
                    >
                        <div className='main'>
                            <Card style={{ width: '100%' }}>
                                <Row justify={'center'}>
                                    <Col span={24} style={{ textAlign: 'right' }}>

                                        <div style={{ margin: 0 }}>
                                            สถานะ : <Tag color={ApprovalStatusColor(dataLand?.lands?.landLoanDetails?.approvalStatus)}>{dataLand?.lands?.landLoanDetails?.approvalStatusTable?.name}</Tag>
                                        </div>
                                        <div style={{ margin: 0 }}>
                                            วันที่ถูกเปลี่ยนสถานะ : {dayjs(dataLand?.lands?.landLoanDetails?.LandDateStatus).format("DD-MM-YYYY")}
                                        </div>

                                    </Col>
                                </Row>
                                <Divider />
                                {dataLand?.nationalId === "นิติบุคคล" ?
                                    <>
                                        <Row justify={'left'}><b><u>รายละเอียดผู้ขอกู้</u></b></Row>
                                        <Row gutter={32} justify={'center'}>
                                            <aside style={{ width: '90%' }}>
                                                <div label='ประเภท' name='identificationId' style={{ marginBottom: 3 }}>
                                                    <span>ประเภท : </span> <b>{dataLand?.nationalId}</b>
                                                </div>
                                                <div label='เลขประจำตัวผู้เสียภาษี' name='identificationId' style={{ marginBottom: 3 }}>
                                                    <span>เลขประจำตัวผู้เสียภาษี : </span> <b>{dataLand.identificationId}</b>
                                                </div>
                                                <div label='ชื่อบริษัท' name='snam' style={{ marginBottom: 3 }}>
                                                    <span>
                                                        {dataLand?.snam} : </span> <b>{dataLand?.firstname + ' ' + dataLand?.lastname}</b>
                                                </div>
                                                <div label='วันจดทะเบียน' name='nickname' style={{ marginBottom: 3 }}>
                                                    <span>วันจดทะเบียน : </span> <b> {dataLand?.birthdate}</b>
                                                </div>
                                                <div style={{ marginBottom: 0, }}>
                                                    <b>

                                                        {
                                                            dataPhoneCus?.map((item, index) => {
                                                                return renderItemPhones({ item, index, key: `{item.identification} - ${index}` })
                                                            })
                                                        }
                                                    </b>
                                                </div>
                                            </aside>
                                        </Row>
                                    </>
                                    :
                                    <>
                                        <Row justify={'left'}><b><u>รายละเอียดผู้ขอกู้</u></b></Row>
                                        <Row gutter={32} justify={'center'}>
                                            <>
                                                <aside style={{ width: '90%' }}>
                                                    <div
                                                        style={{ marginBottom: 0, }}
                                                    >
                                                        <span>ชื่อ - นามสกุล : </span>
                                                        <b>{dataLand?.snam + '   ' + dataLand?.firstname + '   ' + dataLand?.lastname}</b>
                                                    </div>
                                                    <div
                                                        style={{ marginBottom: 0, }}
                                                    >
                                                        <span>ชื่อเล่น : </span>
                                                        <b>{dataLand?.nickname}</b>
                                                    </div>

                                                    <div
                                                        style={{ marginBottom: 0, }}
                                                    >
                                                        <span>เลขบัตรประชาชน : </span>
                                                        <b>{dataLand?.identificationId}</b>
                                                    </div>
                                                    <div
                                                        style={{ marginBottom: 0, }}
                                                    >
                                                        <b>
                                                            {
                                                                dataPhoneCus?.map((item, index) => {
                                                                    return renderItemPhones({ item, index, key: `{item.identification} - ${index}` })
                                                                })
                                                            }
                                                        </b>
                                                    </div>
                                                    <div
                                                        style={{ margin: 0, }}
                                                    >
                                                        <span>เพศ : </span>
                                                        <b>{dataLand?.gender}</b>
                                                    </div>
                                                    <div
                                                        style={{ marginBottom: 0, }}
                                                    >
                                                        <span>{dataEmail === "" ? <> </> : <>{dataEmail?.emailData}</>}</span>
                                                        <b>{dataEmail?.emailData}</b>
                                                    </div>
                                                    <div
                                                        style={{ marginBottom: 0, }}
                                                    >
                                                        <span>{dataSocials?.socialType}  </span>
                                                        <b>{dataSocials?.socialData}</b>
                                                    </div>
                                                </aside>
                                            </>
                                        </Row>
                                    </>
                                }
                                <Divider />
                                {address?.length >= 1 ?
                                    <>
                                        <Row justify={'left'}>
                                            <b><u>รายละเอียดที่อยู่</u></b>
                                        </Row>
                                        <Row gutter={32} justify={'center'}>
                                            {
                                                address?.map((item, index) => {
                                                    return renderItemAddress({ item, index, key: `{item.identification} - ${index}` })
                                                })
                                            }

                                        </Row>
                                    </>
                                    : null
                                }
                                <Row justify={'left'} >
                                    <b><u>รายละเอียดที่ดิน</u></b>
                                </Row>
                                <Row justify={'center'}>
                                    <aside style={{ width: '95%' }}>
                                        <div label='กลุ่มสินค้า' name='productTypeId'>
                                            <span>กลุ่มสินค้า : </span>
                                            <b>
                                                {
                                                    dataLand?.lands?.landLoanDetails?.productType === 'ที่ดิน' ? <>ที่ดิน</> : <>รถ</>
                                                }
                                            </b>
                                        </div>
                                        <div label='ประเภทเอกสาร' name='landTypeId'>
                                            <span>ประเภทเอกสาร : </span>
                                            <b>
                                                {
                                                    dataLand?.lands?.landLoanDetails?.productTypeLand === 1 ? <>นส.3</> : <>นส.4</>
                                                }
                                            </b>
                                        </div>
                                        <div label='เลขโฉนดที่ดิน' name='numberLand'>
                                            <span>เลขโฉนดที่ดิน : </span><b>{dataLand?.lands?.numberLand}</b>
                                        </div>
                                        <div label='เลขที่ดินหรือระวาง' name='numberLandlawang' >
                                            <span>เลขที่ดินหรือระวาง : </span><b>{dataLand?.lands?.numberLandlawang}</b>
                                        </div>
                                        <div label='จังหวัด' name='provinces' >
                                            <span>จังหวัด : </span><b>{dataLand?.lands?.provinces}</b>
                                        </div>
                                        <div label='อำเภอ' name='district'>
                                            <span>อำเภอ : </span><b>{dataLand?.lands?.district}</b>
                                        </div>
                                        <div label='พื้นที่ไร่' name='rai'>
                                            <span>พื้นที่ไร่ : </span><b>{isNaN(dataLand?.lands?.rai) ? "-" : currencyFormatOne(dataLand?.lands?.rai) + " ไร่"}</b>
                                        </div>
                                        <div label='พื้นที่งาน' name='workArea'>
                                            <span>พื้นที่งาน : </span><b>{isNaN(dataLand?.lands?.workArea) ? "-" : currencyFormatOne(dataLand?.lands?.workArea) + " งาน"}</b>
                                        </div>
                                        <div label='พื้นที่ตารางวา' name='squareWaArea'>
                                            <span>พื้นที่ตารางวา : </span><b>{isNaN(dataLand?.lands?.squareWaArea) ? "-" : currencyFormatOne(dataLand?.lands?.squareWaArea) + " ตารางวา"}</b>
                                        </div>
                                        {/* <div label='ราคาประเมินที่ดิน' name='landPrice'>
                                        <span>ราคาประเมินที่ดิน : </span><b>{isNaN(dataLand?.lands?.landPrice) ? "-" : currencyFormatOne(dataLand?.lands?.landPrice) + " บาท / ตารางวา"}</b>
                                    </div> */}
                                        {/* <div label='ราคารวม' name='resultLandPrice'>
                                        <span>ราคารวม : </span><b>{isNaN(dataLand?.lands?.resultLandPrice) ? "-" : currencyFormatOne(dataLand?.lands?.resultLandPrice) + " บาท"}</b>
                                    </div> */}
                                    </aside>
                                </Row>
                                {/* ราคาประเมินที่ดิน */}
                                <Divider style={{ margin: 5 }} />
                                {dataLand?.lands?.landLoanDetails?.productLoanLandType === "ย้ายไฟแนนซ์" ?
                                    <>
                                        <Row justify={'center'} gutter={32}>
                                            <aside style={{ width: '95%' }}>
                                                <Row className='text-left' style={{ marginTop: '20px' }}>
                                                    <b><u>รายละเอียดขอกู้ย้ายไฟแนนซ์</u></b>
                                                </Row>
                                                <p />
                                                <div label='ประเภทขอกู้' name='productLoanLandType'>
                                                    <span>ประเภทขอกู้ : </span><b>{dataLand?.lands?.landLoanDetails?.productLoanLandType}</b>
                                                </div>
                                                <div label='ยอดจัด' name='loanAmountLand'>
                                                    <span>ยอดจัด : </span><b>{isNaN(dataLand?.lands?.landLoanDetails?.loanAmountLand) ? "-" : currencyFormatOne(dataLand?.lands?.landLoanDetails?.loanAmountLand) + " บาท"}</b>
                                                </div>
                                                <div label='อัตราดอกเบี้ย' name='interestRateLand'>
                                                    <span>อัตราดอกเบี้ย : </span><b>{isNaN(dataLand?.lands?.landLoanDetails?.interestRateLand) ? "-" : currencyFormatOne(dataLand?.lands?.landLoanDetails?.interestRateLand) + " % +VAT"}</b>
                                                </div>
                                                <div label='จำนวนงวด' name='loanLandTerm'>
                                                    <span>จำนวนงวด : </span><b>{isNaN(dataLand?.lands?.landLoanDetails?.loanLandTerm) ? "-" : currencyFormatOne(dataLand?.lands?.landLoanDetails?.loanLandTerm) + " งวด"}</b>
                                                </div>
                                                <div label='ดอกเบี้ย' name='interestLand'>
                                                    <span>ดอกเบี้ย : </span><b>{isNaN(dataLand?.lands?.landLoanDetails?.interestLand) ? "-" : currencyFormatOne(dataLand?.lands?.landLoanDetails?.interestLand) + " บาท"}</b>
                                                </div>
                                                <div label='งวดละ' name='monthlyPaymentLand'>
                                                    <span>งวดละ : </span><b>{isNaN(dataLand?.lands?.landLoanDetails?.monthlyPaymentLand) ? "-" : currencyFormatOne(dataLand?.lands?.landLoanDetails?.monthlyPaymentLand) + " บาท"}</b>
                                                </div>
                                                <div label='ราคารวม' name='installmentWithInterestLand'>
                                                    <span>ราคารวม : </span><b>{currencyFormatOne(parseInt(dataLand?.lands?.landLoanDetails?.monthlyPaymentLand) * parseInt(dataLand?.lands?.landLoanDetails?.loanLandTerm)) + " บาท"}</b>
                                                </div>
                                            </aside>
                                        </Row>
                                        <Divider style={{ margin: 5 }} />
                                        <Row justify={'center'} gutter={32}>
                                            <aside style={{ width: '95%' }}>
                                                <Row className='text-left' style={{ marginTop: '20px' }}>
                                                    <b><u>รายละเอียดย้ายไฟแนนซ์</u></b>
                                                </Row><p />
                                                {/* <n /> */}
                                                <div label='ปิดไฟแนนซ์จาก' name='issuno' style={{ marginBottom: 3 }}>
                                                    <span>ปิดไฟแนนซ์จาก : </span><b>{isNaN(dataLand?.lands?.landOldLoanDetails?.issuno) ? "-" : (dataLand?.lands?.landOldLoanDetails?.issuno)}</b>
                                                </div>

                                                <div label='ยอดปิดไฟแนนซ์' name='oldLandLoanAmount' style={{ marginBottom: 3 }}>
                                                    <span>ยอดปิดไฟแนนซ์ : </span><b>{isNaN(dataLand?.lands?.landOldLoanDetails?.oldLandLoanAmount) ? "-" : currencyFormatOne(dataLand?.lands?.landOldLoanDetails?.oldLandLoanAmount) + " บาท"}</b>
                                                </div>

                                                <div label='ค่างวด/เดือน' name='oldLandMonthlyPayment' style={{ marginBottom: 3 }}>
                                                    <span>ค่างวด/เดือน : </span><b>{isNaN(dataLand?.lands?.landOldLoanDetails?.oldLandMonthlyPayment) ? "-" : currencyFormatOne(dataLand?.lands?.landOldLoanDetails?.oldLandMonthlyPayment) + " บาท"}</b>
                                                </div>

                                                <div label='จำนวนผ่อนกี่งวด' name='oldLandLoanTerm' style={{ marginBottom: 3 }}>
                                                    <span>จำนวนผ่อนกี่งวด : </span><b>{isNaN(dataLand?.lands?.landOldLoanDetails?.oldLandLoanTerm) ? "-" : dataLand?.lands?.landOldLoanDetails?.oldLandLoanTerm + " งวด"}</b>
                                                </div>
                                            </aside>
                                        </Row>
                                        <Divider />
                                    </>
                                    :
                                    <>
                                        <Row justify={'left'} style={{ marginTop: '15px' }}>
                                            <b><u>รายละเอียดยอดกู้</u></b>
                                        </Row>
                                        <Row justify={'center'}>
                                            <aside style={{ width: '95%' }}>
                                                <div label='ประเภทขอกู้' name='productLoanLandType'>
                                                    <span>ประเภทขอกู้ : </span><b>{dataLand?.lands?.landLoanDetails?.productLoanLandType}</b>
                                                </div>
                                                <div label='ยอดจัด' name='loanAmountLand'>
                                                    <span>ยอดจัด : </span><b>{isNaN(dataLand?.lands?.landLoanDetails?.loanAmountLand) ? "-" : currencyFormatOne(dataLand?.lands?.landLoanDetails?.loanAmountLand) + " บาท"}</b>
                                                </div>
                                                <div label='อัตราดอกเบี้ย' name='interestRateLand'>
                                                    <span>อัตราดอกเบี้ย : </span><b>{isNaN(dataLand?.lands?.landLoanDetails?.interestRateLand) ? "-" : currencyFormatOne(dataLand?.lands?.landLoanDetails?.interestRateLand) + " % +VAT"}</b>
                                                </div>
                                                <div label='จำนวนงวด' name='loanLandTerm'>
                                                    <span>จำนวนงวด : </span><b>{isNaN(dataLand?.lands?.landLoanDetails?.loanLandTerm) ? "-" : currencyFormatOne(dataLand?.lands?.landLoanDetails?.loanLandTerm) + " งวด"}</b>
                                                </div>
                                                <div label='ดอกเบี้ย' name='interestLand'>
                                                    <span>ดอกเบี้ย : </span><b>{isNaN(dataLand?.lands?.landLoanDetails?.interestLand) ? "-" : currencyFormatOne(dataLand?.lands?.landLoanDetails?.interestLand) + " บาท"}</b>
                                                </div>
                                                <div label='งวดละ' name='monthlyPaymentLand'>
                                                    <span>งวดละ : </span><b>{isNaN(dataLand?.lands?.landLoanDetails?.monthlyPaymentLand) ? "-" : currencyFormatOne(dataLand?.lands?.landLoanDetails?.monthlyPaymentLand) + " บาท"}</b>
                                                </div>
                                                <div label='ราคารวม' name='installmentWithInterestLand'>
                                                    <span>ราคารวม : </span><b>{parseInt(dataLand?.lands?.landLoanDetails?.loanLandTerm) * parseInt(dataLand?.lands?.landLoanDetails?.monthlyPaymentLand) + " บาท"}</b>
                                                </div>
                                            </aside>
                                        </Row>
                                        <Divider />
                                    </>
                                }
                                {
                                    getData.lands.landLoanDetails.approvalStatus === 3 ||
                                        getData.lands.landLoanDetails.approvalStatus === 21 ||
                                        getData.lands.landLoanDetails.approvalStatus === 27 ||
                                        getData.lands.landLoanDetails.approvalStatus === 25 ||
                                        getData.lands.landLoanDetails.approvalStatus === 26 ||
                                        getData.lands.landLoanDetails.approvalStatus === 24 ?
                                        <>
                                            <Row justify={'left'}><b><u>ยอดที่อนุมัติ</u></b></Row>
                                            <Row gutter={32} justify={'center'} style={{ backgroundColor: "lightgreen" }}>
                                                <aside style={{ width: '90%' }}>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>ยอดจัด : </span>
                                                        {
                                                            getData?.lands?.apLandLoanDetails?.apLoanAmount !== "0" ?
                                                                <b>{currencyFormatOne(getData?.lands?.apLandLoanDetails?.apLoanAmount)}</b>
                                                                :
                                                                <b>{currencyFormatOne(getData.lands.landLoanDetails?.approvedLoanAmount)}</b>
                                                        }
                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                            บาท
                                                        </span>
                                                    </div>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>จำนวนงวด : </span>
                                                        {
                                                            getData?.lands?.apLandLoanDetails?.apLoanTerm !== "0" ?
                                                                <b>{getData?.lands?.apLandLoanDetails?.apLoanTerm}</b>
                                                                :
                                                                <b>{getData.lands.landLoanDetails?.approvedLoanTerm}</b>
                                                        }
                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                            งวด
                                                        </span>
                                                    </div>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>งวดละ : </span>
                                                        {
                                                            getData?.lands?.apLandLoanDetails?.apMonthlyPayment !== "0" ?
                                                                <b>{currencyFormatOne(getData?.lands?.apLandLoanDetails?.apMonthlyPayment)}</b>
                                                                :
                                                                <b>{currencyFormatOne(loanTermDATA)}</b>
                                                        }
                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                            บาท
                                                        </span>
                                                    </div>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>ดอกเบี้ย : </span>
                                                        {
                                                            getData?.lands?.apLandLoanDetails?.apInterest !== "0" ?
                                                                <b>{currencyFormatOne(getData?.lands?.apLandLoanDetails?.apInterest)}</b>
                                                                :
                                                                <b>{currencyFormatOne(resultRateDATA)}</b>
                                                        }
                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                            บาท
                                                        </span>
                                                    </div>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>ราคารวม : </span>
                                                        {
                                                            getData?.lands?.apLandLoanDetails?.apInstallmentWithInterest !== "0" ?
                                                                <b>{currencyFormatOne(getData?.lands?.apLandLoanDetails?.apInstallmentWithInterest)}</b>
                                                                :
                                                                <b>{currencyFormatOne(resultPriceDATA)}</b>
                                                        }
                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                            บาท
                                                        </span>
                                                    </div>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>ผู้อนุมัติเคส : </span>
                                                        <b>{getData.lands.landLoanDetails?.approvedBy}</b>
                                                    </div>
                                                </aside>
                                            </Row>
                                            <Divider />
                                        </>
                                        : null
                                }
                                <Row justify={'left'} style={{ marginTop: '15px' }}>
                                    <b><u>อาชีพ - รายได้</u></b>
                                </Row>
                                {career ?
                                    <>
                                        {career?.map((e, index) => {
                                            return (
                                                <Row gutter={32} justify={'center'}>
                                                    <aside style={{ width: '90%' }}>
                                                        <div style={{ marginBottom: 0, }}>
                                                            <span>กลุ่มอาชีพ : </span>
                                                            <b>{e?.desc}</b>
                                                        </div>
                                                        <div style={{ marginBottom: 0, }}>
                                                            <span>ชื่อบริษัท(สถานที่ทำงาน) : </span>
                                                            <b>{e?.companyName}</b>
                                                        </div>
                                                        <div style={{ marginBottom: 0, }}>
                                                            <span>อาชีพ  : </span>
                                                            <b>{e?.descSub}</b>
                                                        </div>
                                                        <div style={{ marginBottom: 0, }}>
                                                            <span>รายได้ / เดือน : </span>
                                                            <b>{currencyFormatOne(e?.incomeMonth)}</b>
                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                บาท
                                                            </span>
                                                        </div>
                                                        <div style={{ marginBottom: 0, }}>
                                                            <span>สาขาอาชีพ : </span>
                                                            <b>{e?.descSection}</b>
                                                        </div>

                                                        <div style={{ marginBottom: 0, }}>
                                                            <span>
                                                                รายจ่าย / เดือน : </span>
                                                            <b>{currencyFormatOne(e?.expensesMonth)}</b>
                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                บาท
                                                            </span>
                                                        </div>
                                                    </aside>
                                                </Row>
                                            )
                                        })}
                                    </>
                                    : null
                                }
                                <Divider />
                                {
                                    guarantor1?.length >= 1 ?
                                        <>
                                            <Row justify={'left'}><b><u>รายละเอียดคนค้ำ</u></b></Row>
                                            <Row gutter={32} justify={'center'}>
                                                {
                                                    guarantor1?.map((item, index) => {
                                                        return renderGuarantorNewImg({ item, index })
                                                    })
                                                }
                                            </Row>
                                        </>
                                        : null
                                }
                                <Row justify={'left'}><Col span={24}><b><u>หมายเหตุ</u></b></Col></Row>
                                <Row gutter={32} justify={'center'}>
                                    {notes ?
                                        < Card
                                            key={lastIndex}
                                            style={{ width: '270px', textAlign: 'left' }}>
                                            <div style={{ marginBottom: 0 }}>
                                                <span>สาเหตุ : </span>
                                                <b>{notes[lastIndex]?.cause}</b>
                                            </div>
                                            <div style={{ marginBottom: 0 }}>
                                                <span>หมายเหตุ : </span>
                                                <b>{notes[lastIndex]?.note}</b>
                                            </div>
                                            {/* <div style={{ marginBottom: 0 }}>
                                                <span>ผู้สร้างหมายเหตุ : </span>
                                                <b>{notes[lastIndex].noteBy}</b>
                                            </div> */}
                                            <div style={{ marginBottom: 0 }}>
                                                <span>สถานะที่ทำการหมายเหตุ : </span>
                                                <b>{notes[lastIndex].status}</b>
                                            </div>
                                        </Card>
                                        : <>* ยังไม่เพิ่มหมายเหตุ!</>
                                    }
                                </Row>
                                {
                                    getData?.lands?.landLoanDetails?.noteResend?.offercaseNote !== null ?
                                        <>
                                            <Divider />
                                            <Row justify={'left'}><Col span={24}><b><u>หมายเหตุตอนเสนอเคส</u></b></Col></Row>
                                            <Row gutter={32} justify={'center'}>
                                                <Form.Item name="offercaseNote" label="">
                                                    <TextArea
                                                        name='offercaseNote'
                                                        style={{ color: 'blue' }}
                                                        rows={8}
                                                        disabled
                                                    //onChange={(e) => setDataPost({ offercaseNote: e.target.value })}
                                                    >
                                                    </TextArea>
                                                </Form.Item>
                                            </Row>
                                        </>
                                        : null
                                }
                                <Divider />
                                <Row justify={'left'}><b><u>รูปบัตรประชาชนผู้ขอกู้</u></b></Row>
                                <Row gutter={32} justify={'center'}>
                                    <Space direction="vertical" >
                                        <Row gutter={32} justify={'center'}>
                                            {imageBlobzz?.map((e, index) => {
                                                var PreAaproveSplit = []
                                                PreAaproveSplit = e.pathImage.split("/")
                                                if (PreAaproveSplit[3] !== "13") {
                                                    if (e.type === 4) {
                                                        return (
                                                            <>
                                                                <Image width={"100px"} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                            </>
                                                        );
                                                    }
                                                }
                                                return null;
                                            })}
                                        </Row>
                                        {
                                            getData?.lands?.landLoanDetails?.approvalStatus === 21 ?
                                                <>
                                                    <div className='gutter-row' span={12}>
                                                        <b> <Form.Item label='เพิ่มรูป' name='imageIDGuCar'
                                                        >
                                                            <Upload
                                                                name='imageIDGuCar'
                                                                multiple={true}
                                                                listType="picture-card"
                                                                fileList={fileListCustomer}
                                                                onPreview={handlePreview}
                                                                onChange={handleCustomerImage}
                                                                //beforeUpload={() => false}
                                                                beforeUpload={(file) => beforeUpts(file, 4).then(() => false).catch(() => true)}
                                                            >
                                                                {fileListCustomer?.length >= 5 ? null : (
                                                                    <div>
                                                                        <PlusOutlined />
                                                                        <div className="ant-upload-text">upload</div>
                                                                    </div>
                                                                )}
                                                            </Upload>
                                                            <Modal open={previewVisible} onCancel={handleCancelPreview}>
                                                                <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                            </Modal>
                                                        </Form.Item>
                                                        </b>
                                                    </div>
                                                </>
                                                :
                                                null
                                        }
                                    </Space>
                                </Row>
                                <Divider />
                                {
                                    checkSendNEW ?
                                        <>
                                            <Row justify={'left'}><b><u>รูปบัตรประชาชนผู้ขอกู้</u> (ใหม่)</b></Row>
                                            <Row gutter={32} justify={'center'}>
                                                <Space direction="vertical" >
                                                    <Row gutter={32} justify={'center'}>
                                                        {imageBlobzz?.map((e, index) => {
                                                            var PreAaproveSplit = []
                                                            PreAaproveSplit = e.pathImage.split("/")
                                                            if (PreAaproveSplit[3] === "13") {
                                                                if (e.type === 4) {
                                                                    return (
                                                                        <>
                                                                            <Image width={"100px"} key={index} src={`${getImagessPre}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                        </>
                                                                    );
                                                                }
                                                            }
                                                            return null;
                                                        })}
                                                    </Row>
                                                </Space>
                                            </Row>
                                            <Divider />
                                        </>
                                        : null
                                }
                                {
                                    newDataLand?.map((item, index) => {
                                        return renderLandBuildings({ item, index })
                                    })
                                }
                                {
                                    newDataLand?.map((item, index) => {
                                        return renderTitleDeed({ item, index })
                                    })
                                }
                                {
                                    newDataLand?.map((item, index) => {
                                        return renderDivision({ item, index })
                                    })
                                }
                                {
                                    newDataLand?.map((item, index) => {
                                        return renderLawang({ item, index })
                                    })
                                }
                                <Row justify={'left'}><b><u>ประวัติผู้กู้ / ทะเบียนบ้าน / อาชีพ</u></b></Row>
                                <Row gutter={32} justify={'center'}>
                                    <Space direction="vertical" >
                                        <Row gutter={32} justify={'center'}>
                                            {imageBlobzz?.map((e, index) => {
                                                var PreAaproveSplit = []
                                                PreAaproveSplit = e.pathImage.split("/")
                                                if (PreAaproveSplit[3] !== "13") {
                                                    if (e.type === 6) {
                                                        return (
                                                            <>
                                                                <Image width={"100px"} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                            </>
                                                        );
                                                    }
                                                }
                                                return null;
                                            })}
                                        </Row>
                                        {
                                            getData?.lands?.landLoanDetails?.approvalStatus === 21 ?
                                                <>
                                                    <div className='gutter-row' span={12}>
                                                        <b> <Form.Item label='เพิ่มรูป' name='imageIDGuCar'
                                                        >

                                                            <Upload
                                                                name='imageIDGuCar'
                                                                multiple={true}
                                                                listType="picture-card"
                                                                fileList={fileListCustomerImageCareer}
                                                                onPreview={handlePreview}
                                                                onChange={handleCustomerImageCareer}
                                                                //beforeUpload={() => false}
                                                                beforeUpload={(file) => beforeUpts(file, 6).then(() => false).catch(() => true)}
                                                            >
                                                                {fileListCustomerImageCareer?.length >= 20 ? null : (
                                                                    <div>
                                                                        <PlusOutlined />
                                                                        <div className="ant-upload-text">upload</div>
                                                                    </div>
                                                                )}
                                                            </Upload>
                                                            <Modal open={previewVisible} onCancel={handleCancelPreview}>
                                                                <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                            </Modal>
                                                        </Form.Item>
                                                        </b>
                                                    </div>
                                                </>
                                                : null
                                        }
                                    </Space>
                                </Row>
                                <Divider />
                                {
                                    checkSendNEW ?
                                        <>
                                            <Row justify={'left'}><b><u>ประวัติผู้กู้ / ทะเบียนบ้าน / อาชีพ</u> (ใหม่)</b></Row>
                                            <Row gutter={32} justify={'center'}>
                                                <Space direction="vertical" >
                                                    <Row gutter={32} justify={'center'}>
                                                        {imageBlobzz?.map((e, index) => {
                                                            var PreAaproveSplit = []
                                                            PreAaproveSplit = e.pathImage.split("/")
                                                            if (PreAaproveSplit[3] === "13") {
                                                                if (e.type === 6) {
                                                                    return (
                                                                        <>
                                                                            <Image width={"100px"} key={index} src={`${getImagessPre}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                        </>
                                                                    );
                                                                }
                                                            }
                                                            return null;
                                                        })}
                                                    </Row>
                                                </Space>
                                            </Row>
                                            <Divider />
                                        </>
                                        : null
                                }
                                <Row justify={'left'}><b><u>รูปสเตทเมนต์ผู้กู้</u></b></Row>
                                <Row gutter={32} justify={'center'}>
                                    <Space direction="vertical">
                                        <Row gutter={32} justify={'center'}>
                                            {imageBlobzz?.map((e, index) => {
                                                var PreAaproveSplit = []
                                                PreAaproveSplit = e.pathImage.split("/")
                                                if (PreAaproveSplit[3] !== "13") {
                                                    if (e.type === 8) {
                                                        return (
                                                            <>
                                                                <Image width={"100px"} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                            </>
                                                        );
                                                    }
                                                }
                                                return null;
                                            })}
                                        </Row>
                                        {
                                            getData?.lands?.landLoanDetails?.approvalStatus === 21 ?
                                                <>
                                                    <div className='gutter-row' span={12}>
                                                        <b> <Form.Item label='เพิ่มรูป' name='imageIDGuCar'
                                                        >
                                                            <Upload
                                                                name='imageIDGuCar'
                                                                multiple={true}
                                                                listType="picture-card"
                                                                fileList={fileListStatement}
                                                                onPreview={handlePreview}
                                                                onChange={handleStatement}
                                                                //beforeUpload={() => false}
                                                                beforeUpload={(file) => beforeUpts(file, 8).then(() => false).catch(() => true)}
                                                            >
                                                                {fileListStatement?.length >= 15 ? null : (
                                                                    <div>
                                                                        <PlusOutlined />
                                                                        <div className="ant-upload-text">upload</div>
                                                                    </div>
                                                                )}
                                                            </Upload>
                                                            <Modal open={previewVisible} onCancel={handleCancelPreview}>
                                                                <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                            </Modal>
                                                        </Form.Item>
                                                        </b>
                                                    </div>
                                                </>
                                                : null
                                        }
                                    </Space>
                                </Row>
                                <Divider />
                                {
                                    checkSendNEW ?
                                        <>
                                            <Row justify={'left'}><b><u>รูปสเตทเมนต์ผู้กู้</u> (ใหม่)</b></Row>
                                            <Row gutter={32} justify={'center'}>
                                                <Space direction="vertical">
                                                    <Row gutter={32} justify={'center'}>
                                                        {imageBlobzz?.map((e, index) => {
                                                            var PreAaproveSplit = []
                                                            PreAaproveSplit = e.pathImage.split("/")
                                                            if (PreAaproveSplit[3] === "13") {
                                                                if (e.type === 8) {
                                                                    return (
                                                                        <>
                                                                            <Image width={"100px"} key={index} src={`${getImagessPre}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                        </>
                                                                    );
                                                                }
                                                            }
                                                            return null;
                                                        })}
                                                    </Row>
                                                </Space>
                                            </Row>
                                            <Divider />
                                        </>
                                        : null
                                }
                                <Row justify={'left'}><b><u>รูปสเตทเมนต์ผู้กู้ PDF</u></b></Row>
                                <Row gutter={32} justify={'center'}>
                                    <Space direction="vertical">
                                        <Row gutter={32} justify={'center'}>
                                            {imageBlobzz?.map((e, index) => {
                                                var PreAaproveSplit = []
                                                PreAaproveSplit = e.pathImage.split("/")
                                                if (PreAaproveSplit[3] !== "13") {
                                                    if (e.type === 7) {
                                                        return (
                                                            <>
                                                                <object data={`${getImagess}/${e.pathImage}?token=${token}`} type="application/pdf" width="350px" height="450px">
                                                                    <p>Alternative text - include a link <a href={`${getImagess}/${e.pathImage}?token=${token}`}>to the PDF!</a></p>
                                                                </object>
                                                            </>
                                                        )
                                                    }
                                                }
                                                return null;
                                            })}
                                        </Row >
                                        {
                                            getData?.lands?.landLoanDetails?.approvalStatus === 21 ?
                                                <>
                                                    <div className='gutter-row' span={12}>
                                                        <b> <Form.Item label='เพิ่มรูป' name='imageIDGuCar'
                                                        >
                                                            <Upload
                                                                name='imageIDGuCar'
                                                                multiple={true}
                                                                listType="picture-card"
                                                                fileList={fileListStatementPDF}
                                                                onPreview={handlePreview}
                                                                onChange={handleStatementPDF}
                                                                //beforeUpload={() => false}
                                                                beforeUpload={(file) => beforeUpts(file, 7).then(() => false).catch(() => true)}
                                                            >
                                                                {fileListStatementPDF?.length >= 7 ? null : (
                                                                    <div>
                                                                        <PlusOutlined />
                                                                        <div className="ant-upload-text">upload</div>
                                                                    </div>
                                                                )}
                                                            </Upload>
                                                            <Modal open={previewVisible} onCancel={handleCancelPreview}>
                                                                <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                            </Modal>
                                                        </Form.Item>
                                                        </b>
                                                    </div>
                                                </>
                                                : null
                                        }
                                    </Space>
                                </Row>
                                <Divider />
                                {
                                    checkSendNEW ?
                                        <>
                                            <Row justify={'left'}><b><u>รูปสเตทเมนต์ผู้กู้ PDF</u> (ใหม่)</b></Row>
                                            <Row gutter={32} justify={'center'}>
                                                <Space direction="vertical">
                                                    <Row gutter={32} justify={'center'}>
                                                        {imageBlobzz?.map((e, index) => {
                                                            var PreAaproveSplit = []
                                                            PreAaproveSplit = e.pathImage.split("/")
                                                            if (PreAaproveSplit[3] === "13") {
                                                                if (e.type === 7) {
                                                                    return (
                                                                        <>
                                                                            <object data={`${getImagessPre}/${e.pathImage}?token=${token}`} type="application/pdf" width="350px" height="450px">
                                                                                <p>Alternative text - include a link <a href={`${getImagessPre}/${e.pathImage}?token=${token}`}>to the PDF!</a></p>
                                                                            </object>
                                                                        </>
                                                                    )
                                                                }
                                                            }
                                                            return null;
                                                        })}
                                                    </Row >
                                                </Space>
                                            </Row>
                                            <Divider />
                                        </>
                                        : null
                                }
                                <Row justify={'left'}><b><u>เอกสารเพิ่มเติม</u></b></Row>
                                <Row gutter={32} justify={'center'}>
                                    <Space direction="vertical">
                                        <Row gutter={32} justify={'center'}>
                                            {imageBlobzz?.map((e, index) => {
                                                var PreAaproveSplit = []
                                                PreAaproveSplit = e.pathImage.split("/")
                                                if (PreAaproveSplit[3] !== "13") {
                                                    if (e.type === 12) {
                                                        return (
                                                            <>
                                                                <Image width={"100px"} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                            </>
                                                        );
                                                    }
                                                }
                                                return null;
                                            })}
                                        </Row>
                                    </Space>
                                </Row>
                                <Divider />
                                {
                                    checkSendNEW ?
                                        <>
                                            <Row justify={'left'}><b><u>เอกสารเพิ่มเติม</u> (ใหม่)</b></Row>
                                            <Row gutter={32} justify={'center'}>
                                                <Space direction="vertical">
                                                    <Row gutter={32} justify={'center'}>
                                                        {imageBlobzz?.map((e, index) => {
                                                            var PreAaproveSplit = []
                                                            PreAaproveSplit = e.pathImage.split("/")
                                                            if (PreAaproveSplit[3] === "13") {
                                                                if (e.type === 12) {
                                                                    return (
                                                                        <>
                                                                            <Image width={"100px"} key={index} src={`${getImagessPre}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                        </>
                                                                    );
                                                                }
                                                            }
                                                            return null;
                                                        })}
                                                    </Row>
                                                </Space>
                                            </Row>
                                            <Divider />
                                        </>
                                        : null
                                }
                                <Col className='gutter-row' span={24} style={{ textAlign: 'right' }}>
                                    <Space>
                                        <Button type='primary' danger onClick={handleCancel}>
                                            ปิด
                                        </Button>
                                        {
                                            getData?.lands?.landLoanDetails?.approvalStatus === 21 ?
                                                <>
                                                    <Button type='primary' danger onClick={showConfirmCancel}>
                                                        ผู้กู้ไม่ยอมรับ
                                                    </Button>
                                                    <Button type='primary' style={{ backgroundColor: 'green' }} onClick={showConfirmOK}>
                                                        ผู้กู้ยอมรับ
                                                    </Button>
                                                </>
                                                :
                                                <>
                                                    <Button type='primary' icon={<SendOutlined />} onClick={showConfirm}>
                                                        ส่งเคสอีกครั้ง
                                                    </Button>
                                                </>
                                        }
                                    </Space>
                                </Col>
                            </Card>
                        </div>
                    </Form>
                    {contextHolder}
                </Spin>
            </Row>
        </>
    )
}

export default DescriptionAllLand