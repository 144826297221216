import React, { useState, useEffect } from "react";
import { Button, Col, message, Row, Table, Space, Card, Radio, Tag, notification, Spin, DatePicker, Select, Divider } from 'antd';
import { SearchOutlined } from '@ant-design/icons'
import dayjs from "dayjs";
import axios from "axios";

import { useDispatch } from 'react-redux';
import { addGuarantor } from '../../redux/Guarantor';
import { op } from "../file_mid/all_options";
import { backc, textc, colorApprovalStatus } from "../file_mid/status_color";
import { tablemanager } from "../file_mid/all_api";
import ModalInfoADPGDC from "../file_mid/info/ModalInfoADPGDC";

export default function Main_Manager() {
  const token = localStorage.getItem('token')
  const menu = localStorage.getItem('menu')
  const branch = localStorage.getItem('branch')
  const currentDate = new Date(); // สร้างอ็อบเจ็กต์ Date สำหรับวันที่ปัจจุบัน
  const year = currentDate.getFullYear(); // ดึงปีปัจจุบัน
  const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // ดึงเดือนและจัดรูปแบบให้มี 2 หลัก
  const day = String(currentDate.getDate()).padStart(2, '0'); // ดึงวันและจัดรูปแบบให้มี 2 หลัก
  const formattedDate = `${year}-${month}-${day}`;

  const currentDateTime = dayjs()
  const [dataPost, setDataPost] = useState({ sec: 0, days1: formattedDate, days2: formattedDate, approvalStatus: "ทั้งหมด" })

  const dispatch = useDispatch()
  // const user = localStorage.getItem('username');
  const [editData, setEditData] = useState();
  const [modalInfo, setModalInfo] = useState(false); // Info 
  const [isModalAccept, setIsModalAccept] = useState(false); // Accept
  const [isModalPreApprove, setIsModalPreApprove] = useState(false); // PreApprove

  const [cData, setCData] = useState([]);
  const [axiosData, setAxiosData] = useState([]);
  const [arrayTable, setArrayTable] = useState();
  const [keyWord, setKeyWord] = useState("ทั้งหมด");
  const [api, contextHolder] = notification.useNotification();
  const [valueName, setValueName] = useState("ทั้งหมด");
  const [namead, setNamead] = useState([]);
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    loadData();
  }, [keyWord, isModalAccept, isModalPreApprove, editData, dataPost])

  const loadData = async () => {
    const tk = JSON.parse(token)
    const headers = {
      "Authorization": `Bearer ${tk}`,
      "Menu": JSON.stringify("7")
    }
    if (dataPost?.days1 != "" && dataPost?.days2 != "") {
      setLoading(true)
      await axios.post(tablemanager, { sec: dataPost.sec, days1: dataPost.days1, days2: dataPost.days2, approvalStatus: dataPost.approvalStatus, branch: branch }, { headers: headers })
        .then((res) => {
          console.log('resdata',res.data)
          if (res.data) {
            console.log("res", res.data)
            var cutData = res.data
            cutData.map((e, index) => {
              // console.log(e)
              if (e.approvalStatus ===11 || e.approvalStatus ===12) {
                var num = index
              }
              delete cutData[num]
            })
            // console.log("cutData", cutData)
            const resultArray = [{ label: 'ทั้งหมด', value: 'ทั้งหมด' }];
            cutData.forEach(item => {
              if (item.reviewedBy && item.reviewedBy !== "1234" && item.reviewedBy !== "pn") {
                // console.log("items",item.reviewedBy)
                if (!resultArray.some(resultItem => resultItem.label === item.proposalBy)) {
                  resultArray.push({ label: item.proposalBy, value: item.proposalBy });
                }
              }
            });
            // console.log("resultArray", resultArray)
            setNamead(resultArray)
            setArrayTable(cutData)
            setAxiosData(cutData)
            setValueName("ทั้งหมด")
            setLoading(false)
          } else {
            setValueName("ทั้งหมด")
            setNamead([])
            setArrayTable([])
            setAxiosData([])
            message.info('ไม่พบข้อมูล')
          }
        }).catch((err) => { console.log(err) })
      setLoading(false)
    } else {
      setValueName("ทั้งหมด")
      setNamead([])
      setArrayTable([])
      setAxiosData([])
      setLoading(false)
      message.info('ไม่พบข้อมูล')
    }
  }

  // const handleYG = () => {
  //   // console.log("dataPost", dataPost)
  //   // setArrayTable([])
  //   // setAxiosData([])
  //   // setSelectedRecords([])
  //   loadData()
  // };

  const handleChangeNamead = (value) => { // รหัสพนักงาน
    // console.log("value", value)
    setValueName(value)
    let filteredData;
    if (value === 'ทั้งหมด') {
      filteredData = axiosData
    } else {
      filteredData = axiosData.filter(
        (item) =>
          // console.log("item",item)
          item.proposalBy === value,
      );
    }
    // console.log("filteredData", filteredData)
    setArrayTable(filteredData)
  };

  const columns = [
    {
      key: 'index',
      title: "ลำดับ",
      dataIndex: "index",
      width: 50,
      align: 'center',
      render: (text, object, index) => index + 1
    },
    {
      key: 'carInput',
      title: "วันที่เสนอและการตลาด",
      dataIndex: "carInput",
      width: 120,
      align: 'center',
      defaultSortOrder: 'descend',
      render: (text, record) => (
        <>
          <div>{dayjs(record.carInput).format("DD-MM-YYYY HH:mm")}</div>
          {/* <div>{record.proposalBy}</div> */}
          <div>เสนอโดย <Space style={{ color: "red" }}><b>{record.proposalBy}</b></Space></div>
        </>
      ),
    },
    {
      key: 'carInfo',
      title: "รายละเอียดและธุรการที่รับเคส",
      dataIndex: "carPlateNumber",
      width: 280,
      align: 'center',
      render: (text, record) => (
        <>

          <div>{record.snam}{record.firstName} {record.lastName}</div>
          <div>{record.carPlateNumber} {record.carBrand} {record.carBrand} {record.carModel} </div>
          <div>{record.carProvince} {" "}
            {Number.isInteger(parseFloat(record.carYear)) ? (
              <>{parseInt(record.carYear) + 543}</>
            ) : (
              <>{currencyFormatOne(record.carYear)}</>
            )}
          </div>
          <div>ธุรการที่ได้เคส <Space style={{ color: "red" }}><b>{record.reviewedBy}</b></Space></div>
        </>
      )
    },
    {
      key: 'productLoanType',
      title: "ประเภทขอกู้",
      dataIndex: "productLoanType",
      width: 150,
      align: 'center',
      render: (text, record) => (
        <>{record.productLoanType}</>
      ),
    },
    {
      key: 'loanAmount',
      title: "ขอมา",
      dataIndex: "loanAmount",
      width: 90,
      align: 'center',
      render: (text, record) => (
        <Space size="middle">
          {
            <>{record.loanAmount === null ? " " : currencyFormatOne(record.loanAmount)}</>
          }
        </Space>
      ),
    },
    {
      key: 'approvedLoanAmount',
      title: "อนุมัติ",
      dataIndex: "approvedLoanAmount",
      width: 90,
      align: 'center',
      render: (text, record) => (
        <Space size="middle">
          {
            <>{record.approvedLoanAmount === null ? " " : currencyFormatOne(record.approvedLoanAmount)}</>
          }
        </Space>
      ),
    },
    {
      key: 'carPrice',
      title: "เรต",
      dataIndex: "carPrice",
      width: 90,
      align: 'center',
      render: (text, record) => (
        <Space size="middle">
          {
            <>{currencyFormatOne(record.carPrice)}</>
          }
        </Space>
      ),
    },
    {
      key: 'approvalStatus',
      title: "สถานะ",
      dataIndex: "approvalStatus",
      width: 200,
      align: 'center',
      render: (text, record) => {
        let color
        let color2 = "geekblue"
        let colorCarLand
        color = colorApprovalStatus(record?.productLoanType, record?.approvalStatus)
        if (record.car === "car") {
          colorCarLand = "purple"
        }
        if (record.car === "land") {
          colorCarLand = "green"
        }
        return (
          <>
            <Tag color={color}>
              {record.approvalStatusName}
            </Tag>
            <Tag color={color2}>
              {record.productLoanType}
            </Tag>
            <Tag color={colorCarLand}>
              {record.car === "car" ? "รถ" : "ที่ดิน"}
            </Tag>
          </>
        );
      },
    },
    {
      key: 'Action',
      title: "Action",
      fixed: 'right',
      align: 'center',
      width: 70,
      render: (record) => (
        <Space size="middle">
          <Button onClick={() => {
            console.log("record", record)
            let dataCar = { carId: record.carId, typeLoan: record.car, loanId: record.loanId }
            dispatch(addGuarantor(dataCar))
            setModalInfo(true)
            setEditData(record)
          }}><SearchOutlined> </SearchOutlined></Button>
        </Space>
      ),
    },
  ];

  const currencyFormatOne = (amount) => {
    return Number(amount)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, '$&,')
  }

  return (
    <>
      <Card
        bordered={false}
        style={{
          width: 'auto',
        }}
      >
        <Row style={{ textAlign: 'center' }}>
          <Col span={24}>
            <Spin spinning={loading} size='large' tip=" Loading... ">
              <div className='text-center'>
                <h2>ตรวจสอบข้อมูล(ผู้จัดการ)</h2>
              </div>

              <Row gutter={24} justify={'center'} style={{ margin: 10 }}>
                <Col className='gutter-row' span={24} style={{ textAlign: 'center' }}>
                  <Space>
                    <>{"เลือกวันที่ต้องการแสดงเคส :"}</>
                    <DatePicker format={'YYYY-MM-DD'}
                      defaultValue={currentDateTime}
                      style={{ height: '40px' }}
                      onChange={(e) => {
                        if (e) {
                          setDataPost({ ...dataPost, days1: e.format('YYYY-MM-DD') })
                        }
                      }} />
                    <>{"ถึง"}</>
                    <DatePicker format={'YYYY-MM-DD'}
                      defaultValue={currentDateTime}
                      style={{ height: '40px' }}
                      onChange={(e) => {
                        if (e) {
                          setDataPost({ ...dataPost, days2: e.format('YYYY-MM-DD') })
                        }
                      }} />
                    {/* <span style={{ display: 'inline-block' }}>
                      <Button type="primary" onClick={(e) => { handleYG(e) }}>ตรวจสอบ</Button>
                    </span> */}
                  </Space>
                </Col>
              </Row>

              <Row gutter={24} justify={'center'} style={{ margin: 10 }}>
                <Col className='gutter-row' span={24} style={{ textAlign: 'center' }}>
                  <Space>
                    <>{"เลือกสถานะ :"}</>
                    <Select
                      defaultValue={"ทั้งหมด"}
                      style={{ width: 200 }}
                      onChange={(value) => setDataPost({ ...dataPost, approvalStatus: value })}
                      options={op}
                    />

                    <span>เลือกการตลาด : </span>
                    <Select
                      style={{ width: 200 }}
                      value={valueName}
                      onChange={handleChangeNamead}
                      //style={{ width: '160px' }}
                      options={namead}

                    >
                    </Select>

                  </Space>
                </Col>
              </Row>
              <Divider style={{ margin: 5 }} />

              <Row gutter={32} justify={'center'}>
                <Radio.Group disabled value={keyWord} onChange={(e) => setKeyWord(e.target.value)} >
                  {
                    cData?.sort((a, b) => {
                      const order = [
                        "รอธุรการรับ",
                        "รออนุมัติราคารถ",
                        "รอวิเคราะห์",
                        "ผ่านการวิเคราะห์",
                        "ไม่ผ่านการวิเคราะห์",
                        "รออนุมัติ",
                        "อนุมัติ",
                        "ไม่อนุมัติ",
                        "ลูกค้าปฏิเสธ",
                        "ลูกค้าคอนเฟิร์ม",
                        "รอตรวจสอบเอกสาร",
                        "รอทำสัญญา",
                        "Reject",
                        "ทั้งหมด",
                      ];

                      return order.indexOf(a.approvalStatus) - order.indexOf(b.approvalStatus);
                    })
                      .map((item, index) => (
                        <Radio.Button
                          key={index}
                          style={{
                            backgroundColor: backc[item.approvalStatus],
                            color: textc[item.approvalStatus]
                          }}
                          value={item.approvalStatus}
                        >
                          {item.approvalStatus} <b>{item.totalCount}</b>
                        </Radio.Button>
                      ))}

                </Radio.Group>
              </Row>
              <Row gutter={32}>
                <Col span={24}>
                  <Table
                    rowKey={(record) => record.uid}
                    scroll={{
                      x: 1500,
                      y: 400,
                    }}
                    dataSource={arrayTable}
                    columns={columns}
                  >
                  </Table>
                </Col>
              </Row>
            </Spin>
          </Col>
        </Row>
      </Card>
      {
        modalInfo ?
          <ModalInfoADPGDC open={modalInfo} close={setModalInfo} dataFromTable={editData} typeLoans={editData?.car} checkPosi={"mg"} />
          : null
      }
      {contextHolder}
    </>
  )
}
