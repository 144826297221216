import React, { useState, useEffect, useRef } from 'react'
import { Form, Modal, Row, Col, Button, Image, Space, Card, DatePicker, Input, Tag } from 'antd'
import { PrinterOutlined } from '@ant-design/icons'
import dayjs from 'dayjs';
import { useReactToPrint } from "react-to-print";
import axios from 'axios';
import { ApprovalStatusColor } from '../../file_mid/status_color';

function ModalPrint({ open, dataFromTable, close }) {

    const [dataModalEdit, setDataModalEdit] = useState(dataFromTable);
    const [form] = Form.useForm()

    const conponentPDF = useRef();

    const generatePDF = useReactToPrint({
        content: () => conponentPDF.current,
        documentTitle: "CarRate0",
        // onAfterPrint:()=>alert("Data saved in PDF")
    });


    const convertDate = (date) => {
        return dayjs(date);
    }

    useEffect(() => {
        setDataModalEdit(dataFromTable)
    }, [dataFromTable])


    const handleOk = async () => {
        console.log('ok', dataModalEdit)
        close(false);
    };

    const handleCancel = () => {
        close(false);
    };

    const currencyFormatOne = (amount) => {
        return Number(amount)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, '$&,')
    }

    let color

    if (dataModalEdit.approvalStatus === 16) {
        color = "gold"
    }
    if (dataModalEdit.approvalStatus === 17) {
        color = "orange"
    }
    if (dataModalEdit.approvalStatus === 18) {
        color = "cyan"
    }
    if (dataModalEdit.approvalStatus === 19) {
        color = "geekblue"
    }
    if (dataModalEdit.approvalStatus === 2) {
        color = "lime"
    }
    if (dataModalEdit.approvalStatus === 3) {
        color = "green"
    }
    if (dataModalEdit.approvalStatus === 21) {
        color = "green"
    }
    if (dataModalEdit.approvalStatus === 22) {
        color = "red"
    }


    // console.log('check', dataModalEdit)
    return (
        <div>
            <Modal title="ตรวจสอบข้อมูล" open={open} onOk={handleOk} onCancel={handleCancel} width={1200} footer={[
                <Button onClick={generatePDF}><PrinterOutlined /> Print</Button>,
                <Button key="back" type='primary' danger onClick={handleCancel}>
                    ปิด
                </Button>,

            ]}>
                <Card
                    title="รายระเอียดรถเรท 0"
                    bordered={false}
                    style={{
                        width: 'auto',
                    }}
                    ref={conponentPDF}
                >
                    <Form
                        labelCol={{
                            span: 10,
                        }}
                        wrapperCol={{
                            span: 12,
                        }}>
                        <Row>
                            <Col span={24}>

                                <div style={{ textAlign: 'right' }}>
                                    <p>สถานะ: <Tag color={ApprovalStatusColor(dataModalEdit.approvalStatus)}>{dataModalEdit.approvalStatus}</Tag> วันที่ถูกเปลี่ยนสถานะ: {dayjs(dataModalEdit.carDateStatus).format("DD-MM-YYYY")} </p>
                                </div>
                            </Col>
                            <Col span={8} >
                                <Form.Item
                                    label="ชื่อลูกค้า"
                                >
                                    {dataModalEdit.snam + "" + dataModalEdit.firstName + " " + dataModalEdit.lastName}
                                </Form.Item>
                                <Form.Item
                                    label="ประเภทสินค้า"
                                >
                                    {dataModalEdit.productType}
                                </Form.Item>

                                <Form.Item
                                    label="ยี่ห้อรถ"
                                >
                                    {dataModalEdit.carBrand}
                                </Form.Item>
                                <Form.Item
                                    label="รุ่นรถ"
                                >
                                    {dataModalEdit.carModel}
                                </Form.Item>
                                <Form.Item
                                    label="	แบบรถ"
                                >
                                    {dataModalEdit.carBaab}
                                </Form.Item>


                            </Col>
                            <Col span={8} >
                                <Form.Item label="" ></Form.Item>
                                <Form.Item
                                    label="ปีรถ"
                                >
                                    {dataModalEdit.carYear}
                                </Form.Item>
                                <Form.Item
                                    label="ทะเบียน"
                                >
                                    {dataModalEdit.carPlateNumber}
                                </Form.Item>
                                <Form.Item
                                    label="สีรถ"
                                >
                                    {dataModalEdit.carColor}
                                </Form.Item>
                                {/* <Form.Item
                                    label="กลุ่มสินค้า"
                                >
                                    {dataModalEdit.gcode}
                                </Form.Item> */}
                                <Form.Item
                                    label="ราคารถหรือเรทรถ"
                                >
                                    {currencyFormatOne(dataModalEdit.carPrice)}
                                </Form.Item>

                                {/* <Form.Item
                                    label="สถานะ"
                                >
                                    <Tag>{dataModalEdit.approvalStatus}</Tag>

                                </Form.Item> */}
                            </Col>
                            <Col span={8} >
                                <Form.Item label="" ></Form.Item>
                                {dataModalEdit.productLoanType === "ย้ายไฟแนนซ์" && (
                                    <Form.Item label='ยอดปิดไฟแนนซ์' name='oldLoanAmount' >
                                        {currencyFormatOne(dataModalEdit.oldLoanAmount)}
                                    </Form.Item>)}
                                {dataModalEdit.productLoanType === "ย้ายไฟแนนซ์" && (
                                    <Form.Item label='ค่างวด/เดือน' name='oldMonthlyPayment' >
                                        {currencyFormatOne(dataModalEdit.oldMonthlyPayment)}
                                    </Form.Item>)}
                                {dataModalEdit.productLoanType === "ย้ายไฟแนนซ์" && (
                                    <Form.Item label='จำนวนผ่อนกี่งวด' name='oldLoanTerm' >
                                        {dataModalEdit.oldLoanTerm}
                                    </Form.Item>)}
                                {dataModalEdit.productLoanType === "ย้ายไฟแนนซ์" && (
                                    <Form.Item label='ยอดที่ค้างทั้งหมด' name='oldKangPayment' >
                                        {currencyFormatOne(dataModalEdit.oldKangPayment)}
                                    </Form.Item>)}
                                {dataModalEdit.productLoanType === "ย้ายไฟแนนซ์" && (
                                    <Form.Item label='ค้างกี่งวด' name='oldKangPaymentTerm' >
                                        {dataModalEdit.oldKangPaymentTerm}
                                    </Form.Item>)}
                            </Col>
                            <Col span={8} >
                                <Form.Item
                                    label="รูปรถ(ทดสอบ)"
                                >
                                    <Space>
                                        <Image
                                            width={200}
                                            src="https://images.unsplash.com/photo-1552519507-ac11af17dcc8?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTh8fGJsdWUlMjBjYXJ8ZW58MHx8MHx8fDA%3D&w=1000&q=80"
                                        />
                                        <Image
                                            width={200}
                                            src="https://images.unsplash.com/photo-1552519507-da3b142c6e3d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fGNhcnN8ZW58MHx8MHx8fDA%3D&w=1000&q=80"
                                        />
                                        <Image
                                            width={200}
                                            src="https://images.unsplash.com/photo-1552519507-ac11af17dcc8?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTh8fGJsdWUlMjBjYXJ8ZW58MHx8MHx8fDA%3D&w=1000&q=80"
                                        />
                                        <Image
                                            width={200}
                                            src="https://images.unsplash.com/photo-1552519507-da3b142c6e3d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fGNhcnN8ZW58MHx8MHx8fDA%3D&w=1000&q=80"
                                        />
                                    </Space>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>

                </Card>
                {/* <Form.Item style={{ textAlign: 'right' }}>
                    <Button onClick={generatePDF}><PrinterOutlined /> Print</Button>
                </Form.Item> */}
            </Modal>
        </div>
    )
}

export default ModalPrint