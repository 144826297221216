import React, { useState, useEffect, useRef } from "react";
import { Button, Col, Row, Tag, Space, Card, Form, Divider, Image, Modal } from 'antd';
import { PrinterOutlined } from '@ant-design/icons'
import axios from "axios";
import { useReactToPrint } from "react-to-print";
import { useSelector } from 'react-redux'
// import '../../../css/Media.css'
import '../../css/Media.css'
import { loadOJS, loadimage1, getImagess } from "../../file_mid/all_api";

function ModalDrescriptionPrint({ close, open, getData1, notes, page, notification3, printData, sendtypesDes, loanTermDATA, resultRateDATA, resultPriceDATA, arr, interestt, installmentWithInterestt, resultRateCarDATA, newLoanTermCarDATA, newResultPriceCarDATA }) {
    const guarantor = useSelector(state => state.guarantor)
    const customers = useSelector(state => state.customers)
    const token = localStorage.getItem('token');
    const [form] = Form.useForm()
    const conponentPDF = useRef();
    const generatePDF = useReactToPrint({
        content: () => conponentPDF.current,
        documentTitle: "CarRate0",
    });
    const Data = { ...getData1, sendtypesDes, printData }
    const [guarantor1, setGuarantor1] = useState(printData)
    const [imageBlobzzCar, setImageBlobzzCar] = useState(getData1?.cars?.img);
    const [imageBlobzzLand, setImageBlobzzLand] = useState(getData1?.lands?.img);
    const [address, setAddresss] = useState(getData1.address)

    const [dataAddress, setDataAddress] = useState([]);
    const [dataPhoneCus, setDataPhoneCus] = useState([]);
    const [dataEmail, setDataEmail] = useState([]);
    const [dataSocials, setDataSocials] = useState([]);
    const [dataCareer, setDataCareer] = useState();
    const [career, setCareer] = useState([]) // เก็บข้อมูลอาชีพ

    // console.log("getData1", getData1)
    // console.log("Data", Data)

    var note = notes?.length;
    var lastIndex = note - 1;

    useEffect(() => {
        setDataAddress({ ...Data.address })
        setDataPhoneCus(Data.phones)
        setDataEmail({ ...Data.emails })
        setDataSocials(Data.socials)
        // if (getData1.ojs !== undefined || getData1.ojs?.length > 0) {
        //     var countIndex = 0
        //     getData1.ojs.map((e, index) => {
        //         if (e.mainOccupation === true) {
        //             countIndex = index
        //             setDataCareer(getData1.ojs[countIndex])
        //         }
        //     })
        // } else {
        //     setDataCareer()
        // }
        // loadGuarantors()
        loadCareer()
        loadImage()
    }, [])

    const loadCareer = async () => {
        const id = { customerId: getData1?.customerId }
        await axios.post(loadOJS, id)
            .then((res) => {
                //console.log("res", res.data)
                setCareer(res.data)
            })
            .catch((err) => console.log(err))
    }

    const loadImage = async () => {
        if (Data.sendtypesDes === "car") {
            await axios.post(loadimage1, { carId: getData1?.cars?.carId, typeLoan: sendtypesDes })
                .then(res => {
                    if (res.status === 200) {
                        setImageBlobzzCar(res.data)
                    } else if (res.status === 204) {

                    }
                }).catch(err => {
                    console.log(err)
                })
        } else {
            await axios.post(loadimage1, { landId: getData1?.lands?.landLoanDetails.landId, typeLoan: sendtypesDes })
                .then(res => {
                    if (res.status === 200) {
                        setImageBlobzzLand(res.data)
                    } else if (res.status === 204) {

                    }
                }).catch(err => {
                    console.log(err)
                })
        }
    }

    const currencyFormatOne = (amount) => {
        return Number(amount)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, '$&,')
    }

    const renderGuarantor = ({ item, index }) => { //item คือตัวแปรที่เอาไว้ดึงค่าของข้อมูลออกมาแสดง โดยใช้ item.ชื่อฟิวล์
        return (
            <>
                <Card style={{ width: 380 }}>
                    <b>
                        <u>
                            <div style={{ marginBottom: 0, textAlign: 'left' }}>
                                <span>ลำดับที่ : </span>
                                {item.garno}
                            </div>
                        </u>
                    </b>

                    <div style={{ marginBottom: 0, textAlign: 'left' }}>
                        <span>ชื่อ-นามสกุล : </span> <b>{item.snam + ' ' + item.firstname + ' ' + item.lastname}</b>
                    </div>

                    <div style={{ marginBottom: 0, textAlign: 'left' }}>
                        <span>ความสัมพันธ์ : </span> <b>{item.description}</b>
                    </div>

                    <div style={{ marginBottom: 0, textAlign: 'left' }}>
                        <span>เบอร์โทรติดต่อ : </span>  <b>{item.phones[0]?.telp}</b>
                    </div>
                    {
                        item?.address?.length > 0 ?
                            <>
                                <div>
                                    <span style={{ marginRight: '15px' }}> ที่อยู่ : </span>
                                    <b>
                                        {
                                            item.address[0]?.houseNumber + '   ' +
                                            " หมู่ " + item.address[0]?.soi + ' ' +
                                            " ถ. " + item.address[0]?.road + ' ' +
                                            " ต. " + item.address[0]?.subdistrict + ' ' +
                                            " อ. " + item.address[0]?.district + ' ' +
                                            " จ. " + item.address[0]?.province + ' '
                                            + item.address[0]?.zipCode

                                        }
                                    </b>
                                </div>
                                <Divider style={{ margin: 5 }} />
                            </>
                            : null
                    }
                </Card>
            </>
        )
    }
    const renderItemAddress = ({ item, index }) => { //item คือตัวแปรที่เอาไว้ดึงค่าของข้อมูลออกมาแสดง โดยใช้ item.ชื่อฟิวล์
        return (
            <>
                <Form
                    name='basic'
                    labelCol={{
                        span: 12,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    style={{
                        width: '95%',
                    }}
                    autoComplete="off"
                    initialValues={{ remember: true }}
                >
                    <Row justify={'left'}>
                        <u> <span>ลำดับที่ : </span><b>{index + 1}</b></u>
                    </Row>
                    <Row gutter={32}>
                        <Col className='gutter-row' span={9}>
                            <Form.Item
                                style={{ marginBottom: 0 }}
                                label='เลขทะเบียนบ้าน'
                            >
                                <b>{item.houseRegistrationNumber}</b>
                            </Form.Item>
                            <Form.Item
                                style={{ marginBottom: 0 }}
                                label='ถนน'
                            >
                                <b>{item.road}</b>
                            </Form.Item>
                            <Form.Item
                                style={{ marginBottom: 0 }}
                                label='ตำบล'
                            >
                                <b>{item.subdistrict}</b>
                            </Form.Item>

                        </Col>
                        <Col className='gutter-row' span={7}>
                            <Form.Item
                                style={{ marginBottom: 0 }}
                                label='บ้านเลขที่'
                            >
                                <b>{item.houseNumber}</b>
                            </Form.Item>
                            <Form.Item
                                style={{ marginBottom: 0 }}
                                label='หมู่บ้าน'
                            >
                                <b>{item.village}</b>
                            </Form.Item>
                            <Form.Item
                                style={{ marginBottom: 0 }}
                                label='อำเภอ'
                            >
                                <b>{item.district}</b>
                            </Form.Item>

                        </Col>
                        <Col className='gutter-row' span={8}>
                            <Form.Item
                                style={{ marginBottom: 0 }}
                                label='หมู่ / ซอย'
                            >
                                <b>{item.soi}</b>
                            </Form.Item>

                            <Form.Item
                                style={{ marginBottom: 0 }}
                                label='จังหวัด'
                            >
                                <b>{item.province}</b>
                            </Form.Item>
                            <Form.Item
                                style={{ marginBottom: 0 }}
                                label='รหัสไปรษณีย์'
                            >
                                <b>{item.zipCode}</b>
                            </Form.Item>

                        </Col>
                    </Row>
                </Form>
                {item?.length > 1 ?
                    <> <Divider style={{ margin: 5 }} /></>
                    : null
                }

            </>
        )
    }
    const renderItemPhones = ({ item, index }) => { //item คือตัวแปรที่เอาไว้ดึงค่าของข้อมูลออกมาแสดง โดยใช้ item.ชื่อฟิวล์
        return (
            <>
                <Form
                    name='basic'
                    labelCol={{
                        span: 12,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    style={{
                        width: '100%',
                    }}
                    autoComplete="off"
                    initialValues={{ remember: true }}
                >
                    <Row gutter={32}>
                        <Col className='gutter-row' span={24}>
                            <Form.Item
                                style={{ marginBottom: 0, textAlign: 'left' }}
                                label={item.phoneType}
                            >
                                <b> {item.telp}</b>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </>
        )
    }
    const handleCancel = () => {
        close(false);
    };
    return (
        <Modal
            open={open}
            onCancel={handleCancel}
            width={'1000px'}
            footer={[
                <Button
                    style={{ margin: '5px', background: '#cf1322', color: '#ffffff' }}
                    icon={<PrinterOutlined />}
                    onClick={handleCancel}>
                    ยกเลิก
                </Button>,
                <Button
                    style={{ margin: '5px', background: '#597ef7', color: '#ffffff' }}
                    icon={<PrinterOutlined />}
                    onClick={generatePDF}>
                    พิมพ์
                </Button>,

            ]}
        >
            <div ref={conponentPDF} style={{ margin: 5 }}>
                <Row justify={'center'}>
                    <Col className='gutter-row' span={24} style={{ fontSize: '25px', textAlign: 'center' }}>
                        <b>รายละเอียดและคนค้ำประกัน</b>
                    </Col>
                </Row>
                {/* <Divider style={{ margin: 5 }} /> */}
                <Row justify={'center'}>
                    <Form
                        labelCol={{
                            span: 12,
                        }}
                        wrapperCol={{
                            span: 24,
                        }}
                        style={{
                            width: '90%',
                        }}
                        form={form}
                        // onFinish={handleSubmit}
                        autoComplete="off"
                        initialValues={{ remember: true }}
                    >

                        <Row gutter={32} justify={'center'}>
                            <Card style={{ width: '1000px' }}>
                                <Row gutter={32}>
                                    <Col span={18} className='gutter-row' style={{ textAlign: 'right' }}>
                                    </Col>
                                    <Col span={6} className='gutter-row' style={{ textAlign: 'right' }}>
                                        {Data?.sendtypesDes === "car" ?
                                            <>
                                                <Form.Item>
                                                    สถานะ : <b><Tag color="cyan">{Data?.cars?.carLoanDetails?.approvalStatus}</Tag></b>
                                                </Form.Item>
                                            </>
                                            :
                                            <>
                                                <Form.Item>
                                                    สถานะ : <b><Tag color="cyan">{Data?.lands?.landLoanDetails?.approvalStatus}</Tag></b>
                                                </Form.Item>
                                            </>
                                        }

                                    </Col>
                                </Row>
                                <Divider />

                                {Data?.nationalId === "นิติบุคคล" ?
                                    <>
                                        <Row justify={'left'}><b><u>รายละเอียดผู้ขอกู้</u></b></Row>
                                        <Row justify={'left'}>
                                            {/* <aside style={{ width: '90%' }}> */}
                                            <Col className='gutter-row' span={8}>
                                                <div label='ประเภท' name='identificationId' style={{ marginBottom: 3 }}>
                                                    <span>ประเภท : </span> <b>{Data?.nationalId}</b>
                                                </div>
                                                <div label='วันจดทะเบียน' name='nickname' style={{ marginBottom: 3 }}>
                                                    <span>วันจดทะเบียน : </span> <b> {Data?.birthdate}</b>
                                                </div>
                                            </Col>
                                            <Col className='gutter-row' span={8}>
                                                <div label='เลขประจำตัวผู้เสียภาษี' name='identificationId' style={{ marginBottom: 3, textAlign: 'left' }}>
                                                    <span>เลขประจำตัวผู้เสียภาษี : </span> <b>{Data?.identificationId}</b>
                                                </div>
                                                <div style={{ marginBottom: 0, textAlign: 'left' }}>
                                                    <b>
                                                        {
                                                            dataPhoneCus?.map((item, index) => {
                                                                return renderItemPhones({ item, index, key: `{item.identification} - ${index}` })
                                                            })
                                                        }
                                                    </b>
                                                </div>

                                            </Col>
                                            <Col className='gutter-row' span={8}>
                                                <div label='ชื่อบริษัท' name='snam' style={{ marginBottom: 3 }}>
                                                    <span>
                                                        {Data?.snam} : </span> <b>{Data?.firstname + ' ' + Data?.lastname}</b>
                                                </div>

                                            </Col>

                                            {/* </aside> */}
                                        </Row>
                                    </>
                                    :
                                    <>
                                        <Row justify={'left'}><b><u>รายละเอียดผู้ขอกู้</u></b></Row>
                                        <Row gutter={32} justify={'center'}>
                                            <Col className='gutter-row' span={9}>
                                                <Form.Item
                                                    style={{ marginBottom: 0, textAlign: 'center' }}
                                                    label='ชื่อ-นามสกุล'
                                                >
                                                    <b>{Data?.snam + '   ' + Data?.firstname + '   ' + Data?.lastname}</b>
                                                </Form.Item>

                                                <Form.Item
                                                    style={{ marginBottom: 0, textAlign: 'center' }}
                                                    label='ชื่อเล่น'
                                                >
                                                    <b>{Data?.nickname}</b>
                                                </Form.Item>
                                            </Col>
                                            <Col className='gutter-row' span={8}>
                                                <Form.Item
                                                    style={{ marginBottom: 0, textAlign: 'center' }}
                                                    label='เลขบัตรประชาชน'
                                                >
                                                    <b>{Data?.identificationId}</b>
                                                </Form.Item>
                                                <Row gutter={32} justify={'left'}>
                                                    <Col className='gutter-row' span={24}>
                                                        <Form.Item
                                                            style={{ marginBottom: 0, textAlign: 'center' }}
                                                        >
                                                            <b>
                                                                {
                                                                    dataPhoneCus?.map((item, index) => {
                                                                        return renderItemPhones({ item, index })
                                                                    })
                                                                }
                                                            </b>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col className='gutter-row' span={7}>
                                                <Form.Item
                                                    style={{ margin: 0, textAlign: 'center' }}
                                                >
                                                    <span>เพศ : </span>
                                                    <b>{Data?.gender}</b>
                                                </Form.Item>
                                                <Form.Item
                                                    style={{ marginBottom: 0, textAlign: 'center' }}
                                                >
                                                    <span>{dataEmail?.length ? <>email : </> : null}  </span>
                                                    <b>{dataEmail?.emailData}</b>
                                                </Form.Item>
                                                {dataSocials?.length ? <>
                                                    <Form.Item
                                                        style={{ marginBottom: 0, textAlign: 'center' }}
                                                    >
                                                        <span>{dataSocials?.length ? <>socials : </> : null}  </span>
                                                        <b>{dataSocials?.socialData}</b>
                                                    </Form.Item>
                                                </> : null}
                                            </Col>
                                        </Row>
                                    </>
                                }
                            </Card>
                            {address?.length >= 1 ?
                                <>
                                    <Card style={{ width: '1000px' }}>
                                        <Row justify={'left'}>
                                            <b><u>รายละเอียดที่อยู่</u></b>
                                        </Row>
                                        <Row gutter={32} justify={'center'}>
                                            {
                                                address?.map((item, index) => {
                                                    return renderItemAddress({ item, index, key: `{item.identificationId} - ${index}` })
                                                })
                                            }
                                        </Row>
                                    </Card>

                                </>
                                : null
                            }

                            {Data?.sendtypesDes === "car" ?
                                <>

                                    <Card style={{ width: '885px' }}>
                                        {getData1?.cars?.carLoanDetails?.productLoanType === 'ย้ายไฟแนนซ์' || getData1?.cars?.carLoanDetails?.productLoanType === 'Pre-Aaprove-ย้ายไฟแนนซ์' ?
                                            <>
                                                <Row justify={'left'}><b><u>รายละเอียดย้ายไฟแนนซ์เดิม</u></b></Row>
                                                <Row gutter={32} justify={'center'}>
                                                    <Col className='gutter-row' span={8}>
                                                        <Form.Item
                                                            style={{ marginBottom: 0, textAlign: 'center' }}
                                                            label='ยอดปิดไฟแนนซ์'
                                                        >
                                                            <b>{currencyFormatOne(getData1?.cars?.carOldLoanDetails?.oldLoanAmount)}</b>
                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                บาท
                                                            </span>
                                                        </Form.Item>

                                                    </Col>
                                                    <Col className='gutter-row' span={8}>
                                                        <Form.Item
                                                            style={{ marginBottom: 0, textAlign: 'center' }}
                                                            label='ค่างวด / เดือน'
                                                        >
                                                            <b>{currencyFormatOne(getData1?.cars?.carOldLoanDetails?.oldMonthlyPayment)}</b>
                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                บาท
                                                            </span>
                                                        </Form.Item>

                                                        {/* <Form.Item
                                                            style={{ marginBottom: 0, textAlign: 'center' }}
                                                            label='ยอดที่ค้างทั้งหมด'
                                                        >
                                                            <b>{currencyFormatOne(getData1?.carLoanDetails?.cars?.oldLoanDetails?.oldKangPayment)}</b>
                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                บาท
                                                            </span>
                                                        </Form.Item> */}
                                                    </Col>
                                                    <Col className='gutter-row' span={8}>
                                                        <Form.Item
                                                            style={{ marginBottom: 0, textAlign: 'center' }}
                                                            label='จำนวนงวดที่ผ่อน'
                                                        >
                                                            <b>{getData1?.cars?.carOldLoanDetails?.oldLoanTerm}</b>
                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                งวด
                                                            </span>
                                                        </Form.Item>
                                                        {/* <Form.Item
                                                            style={{ marginBottom: 0, textAlign: 'center' }}
                                                            label='ค้างกี่งวด'
                                                        >
                                                            <b>{getData1?.carLoanDetails?.cars?.carOldLoanDetails?.oldKangPaymentTerm}</b>
                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                งวด
                                                            </span>
                                                        </Form.Item> */}
                                                    </Col>
                                                </Row>
                                                <Divider />
                                                <Row justify={'left'}><b><u>รายละเอียดยอดไฟแนนซ์</u></b></Row>
                                                <Row justify={'center'} gutter={32}>
                                                    <Col className='gutter-row' span={8}>
                                                        <Form.Item
                                                            style={{ marginBottom: 0, textAlign: 'left' }}
                                                        // label='ยอดกู้'
                                                        >
                                                            <span>ยอดกู้ : </span>
                                                            <b>{currencyFormatOne(getData1?.cars?.carLoanDetails?.loanAmount)}</b>
                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                บาท
                                                            </span>
                                                        </Form.Item>
                                                        <Form.Item
                                                            style={{ marginBottom: 0, textAlign: 'left' }}
                                                        // label='จำนวนงวด'
                                                        > <span>จำนวนงวด : </span>
                                                            <b>{getData1?.cars?.carLoanDetails?.loanTerm}</b>
                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                งวด
                                                            </span>
                                                        </Form.Item>
                                                    </Col>

                                                    <Col className='gutter-row' span={8}>
                                                        <Form.Item
                                                            style={{ marginBottom: 0, textAlign: 'left' }}
                                                        // label='ดอกเบี้ย'
                                                        > <span>ดอกเบี้ย : </span>
                                                            <b>{currencyFormatOne(getData1?.cars?.carLoanDetails?.interest)}</b>
                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                บาท
                                                            </span>
                                                        </Form.Item>
                                                        <Form.Item
                                                            style={{ marginBottom: 0, textAlign: 'left' }}
                                                        // label='จำนวนผ่อนต่อเดือน'
                                                        > <span>จำนวนผ่อนต่อเดือน : </span>
                                                            <b>{currencyFormatOne(getData1?.cars?.carLoanDetails?.monthlyPayment)}</b>
                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                บาท
                                                            </span>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col className='gutter-row' span={8}>
                                                        <Form.Item
                                                            style={{ marginBottom: 0, textAlign: 'left' }}
                                                        // label='ผ่อนรวมดอกเบี้ย'
                                                        > <span>ผ่อนรวมดอกเบี้ย : </span>
                                                            <b>{currencyFormatOne(~~getData1?.cars?.carLoanDetails?.monthlyPayment * ~~getData1?.cars?.carLoanDetails?.loanTerm)}</b>
                                                            {/* <b>{currencyFormatOne(~~getData1?.cars?.carLoanDetails?.loanAmount + ~~getData1?.cars?.carLoanDetails?.interest)}</b> */}
                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                บาท
                                                            </span>
                                                        </Form.Item>
                                                        <Form.Item
                                                            style={{ marginBottom: 0, textAlign: 'left' }}
                                                        // label='ผู้เสนอเคส'
                                                        > <span>ผู้เสนอเคส : </span>
                                                            <b>{getData1?.cars?.carLoanDetails?.proposalBy}</b>

                                                        </Form.Item>
                                                        <b> <Form.Item
                                                            style={{ margin: 0, textAlign: 'left' }}
                                                        // label='เรทรถ'
                                                        > <span>เรทรถ : </span>
                                                            <b style={{ fontSize: '20px' }}><u>{currencyFormatOne(getData1?.cars?.carPrice)}</u></b>

                                                            <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                                                บาท
                                                            </span>
                                                        </Form.Item>
                                                        </b>
                                                    </Col>

                                                </Row>

                                            </>
                                            :
                                            <>
                                                {
                                                    Data?.cars?.carLoanDetails?.productLoanType === "ถือเล่มมา" ||
                                                        Data?.cars?.carLoanDetails?.productLoanType === "ซื้อ-ขาย" ||
                                                        Data?.cars?.carLoanDetails?.productLoanType === "ปรับโครงสร้าง" ||
                                                        Data?.cars?.carLoanDetails?.productLoanType === 'Pre-Aaprove' ||
                                                        Data?.cars?.carLoanDetails?.productLoanType === 'Pre-Aaprove-ถือเล่มมา' ||
                                                        Data?.cars?.carLoanDetails?.productLoanType === 'Pre-Aaprove-ซื้อ-ขาย' ||
                                                        Data?.cars?.carLoanDetails?.productLoanType === 'Pre-Aaprove-รีโอน' ||
                                                        Data?.cars?.carLoanDetails?.productLoanType === 'เปลี่ยนสัญญา' ||
                                                        Data?.cars?.carLoanDetails?.productLoanType === 'Pre-Aaprove-เปลี่ยนสัญญา' ||
                                                        Data?.cars?.carLoanDetails?.productLoanType === 'รี+เปลี่ยนสัญญา' ||
                                                        Data?.cars?.carLoanDetails?.productLoanType === 'ปรับ+เปลี่ยนสัญญา' ||
                                                        Data?.cars?.carLoanDetails?.productLoanType === 'Pre-Aaprove-รี-Ploan' ||
                                                        Data?.cars?.carLoanDetails?.productLoanType === 'รี-3' ||
                                                        Data?.cars?.carLoanDetails?.productLoanType === 'Pre-Aaprove-ปรับโครงสร้าง' ||
                                                        Data?.cars?.carLoanDetails?.productLoanType === 'Pre-Aaprove-รี' ||
                                                        Data?.cars?.carLoanDetails?.productLoanType === 'Pre-Aaprove-รี' ||
                                                        Data?.cars?.carLoanDetails?.productLoanType === 'Pre-Aaprove-รี+เปลี่ยนสัญญา' ||
                                                        Data?.cars?.carLoanDetails?.productLoanType === 'Pre-Aaprove-Ploan' ?
                                                        <>
                                                            <Row justify={'left'}><b><u>รายละเอียด {Data?.cars?.carLoanDetails?.productLoanType}  ({Data?.cars?.carLoanDetails?.productType})</u></b></Row>
                                                            <Row justify={'center'}>
                                                                <Col span={8}>
                                                                    <div style={{ marginBottom: 0, }}>
                                                                        <span>ยอดกู้ : </span>
                                                                        <b>{currencyFormatOne(Data?.cars?.carLoanDetails?.loanAmount)}</b>
                                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                            บาท
                                                                        </span>
                                                                    </div>
                                                                    <div style={{ marginBottom: 0, }}>
                                                                        <span>ผ่อนรวมดอกเบี้ย : </span>
                                                                        {/* <b>{currencyFormatOne(newResultPriceCarDATA)}</b> */}
                                                                        <b>{currencyFormatOne(~~Data?.cars?.carLoanDetails?.monthlyPayment * ~~Data?.cars?.carLoanDetails?.loanTerm)}</b>
                                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                            บาท
                                                                        </span>
                                                                    </div>
                                                                </Col>
                                                                <Col span={8}>
                                                                    <div style={{ marginBottom: 0, }}>
                                                                        <span>จำนวนผ่อน/เดือน : </span>
                                                                        {/* <b>{currencyFormatOne(newLoanTermCarDATA)}</b> */}
                                                                        <b>{currencyFormatOne(Data?.cars?.carLoanDetails?.monthlyPayment)}</b>
                                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                            บาท
                                                                        </span>
                                                                    </div>
                                                                    <div style={{ marginBottom: 0, }}>
                                                                        <span>ดอกเบี้ย : </span>
                                                                        {/* <b>{currencyFormatOne(resultRateCarDATA)}</b> */}
                                                                        <b>{currencyFormatOne(Data?.cars?.carLoanDetails?.interest)}</b>
                                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                            บาท
                                                                        </span>
                                                                    </div>
                                                                </Col>
                                                                <Col span={8}>
                                                                    <div style={{ marginBottom: 0, }}>
                                                                        <span>จำนวนงวด : </span>
                                                                        <b>{Data?.cars?.carLoanDetails?.loanTerm}</b>
                                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                            งวด
                                                                        </span>
                                                                    </div>
                                                                    <div style={{ marginBottom: 0, }}>
                                                                        <span>ผู้เสนอเคส : </span>
                                                                        <b>{Data?.cars?.carLoanDetails?.proposalBy}</b>
                                                                    </div>
                                                                    <div style={{ marginBottom: 0, }}>
                                                                        <b>
                                                                            <span>เรทรถ : </span>
                                                                            <b style={{ fontSize: '20px' }}><u>{currencyFormatOne(Data?.cars?.carPrice)}</u></b>
                                                                            <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                                                                บาท
                                                                            </span>
                                                                        </b>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </>
                                                        : null
                                                }

                                            </>
                                        }

                                    </Card>

                                </>
                                :
                                <>
                                    <Card style={{ width: '885px' }}>
                                        {/* ที่ดิน */}
                                        {Data?.lands?.landLoanDetails?.productLoanLandType === 'ย้ายไฟแนนซ์' || Data?.lands?.landLoanDetails?.productLoanLandType === 'Pre-Aaprove-ย้ายไฟแนนซ์' ?
                                            <>

                                                <Row justify={'left'}><b><u>รายละเอียดย้ายไฟแนนซ์เดิม</u></b></Row>
                                                <Row gutter={32} justify={'center'}>
                                                    <Col className='gutter-row' span={8}>
                                                        <Form.Item
                                                            style={{ marginBottom: 0, textAlign: 'center' }}
                                                            label='ยอดปิดไฟแนนซ์'
                                                        >
                                                            <b>{currencyFormatOne(getData1?.lands?.landOldLoanDetails?.oldLandLoanAmount)}</b>
                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                บาท
                                                            </span>
                                                        </Form.Item>
                                                        <Form.Item
                                                            style={{ marginBottom: 0, textAlign: 'center' }}
                                                            label='ค่างวด / เดือน'
                                                        >
                                                            <b>{currencyFormatOne(Data?.lands?.landOldLoanDetails?.oldLandMonthlyPayment)}</b>
                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                บาท
                                                            </span>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col className='gutter-row' span={8}>
                                                        <Form.Item
                                                            style={{ marginBottom: 0, textAlign: 'center' }}
                                                            label='จำนวนงวดที่ผ่อน'
                                                        >
                                                            <b>{Data?.lands?.landOldLoanDetails?.oldLandLoanTerm}</b>
                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                งวด
                                                            </span>
                                                        </Form.Item>
                                                        <Form.Item
                                                            style={{ marginBottom: 0, textAlign: 'center' }}
                                                            label='ยอดที่ค้างทั้งหมด'
                                                        >
                                                            <b>{currencyFormatOne(Data?.lands?.landOldLoanDetails?.oldLandKangPayment)}</b>
                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                บาท
                                                            </span>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col className='gutter-row' span={8}>
                                                        <Form.Item
                                                            style={{ marginBottom: 0, textAlign: 'center' }}
                                                            label='ค้างกี่งวด'
                                                        >
                                                            <b>{Data?.lands?.landOldLoanDetails?.oldLandKangPaymentTerm}</b>
                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                งวด
                                                            </span>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Divider />
                                                <Row justify={'left'}><b><u>รายละเอียดย้ายไฟแนนซ์</u></b></Row>
                                                <Row gutter={32} justify={'center'}>
                                                    <Col className='gutter-row' span={8}>
                                                        <Form.Item
                                                            label='ยอดจัด'
                                                            style={{ marginBottom: 0, }}
                                                        >
                                                            <b>{currencyFormatOne(getData1?.lands?.landLoanDetails?.loanAmountLand)}</b>
                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                บาท
                                                            </span>
                                                        </Form.Item>
                                                        <Form.Item
                                                            label='ดอกเบี้ย'
                                                            style={{ marginBottom: 0, }}
                                                        >
                                                            <b>{currencyFormatOne(getData1?.lands?.landLoanDetails?.interestLand)}</b>
                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                บาท
                                                            </span>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col className='gutter-row' span={8}>
                                                        <Form.Item
                                                            label='จำนวนงวด'
                                                            style={{ marginBottom: 0, }}
                                                        >
                                                            <b>{getData1?.lands?.landLoanDetails?.loanLandTerm}</b>
                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                งวด
                                                            </span>
                                                        </Form.Item>
                                                        <Form.Item
                                                            label='ราคารวม'
                                                            style={{ marginBottom: 0, }}
                                                        >
                                                            <b>{currencyFormatOne(~~getData1?.lands?.landLoanDetails?.loanAmountLand + ~~getData1?.lands?.landLoanDetails?.interestLand)}</b>
                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                บาท
                                                            </span>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col className='gutter-row' span={8}>
                                                        <Form.Item
                                                            label='งวดละ'
                                                            style={{ marginBottom: 0, }}
                                                        >
                                                            <b>{currencyFormatOne(getData1?.lands?.landLoanDetails?.monthlyPaymentLand)}</b>
                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                บาท
                                                            </span>
                                                        </Form.Item>
                                                        <Form.Item
                                                            label='ผู้เสนอเคส'
                                                            style={{ marginBottom: 0, }}
                                                        >
                                                            <b>{getData1?.lands?.landLoanDetails?.proposalBy}</b>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>

                                            </>
                                            :
                                            <>

                                                <Row justify={'left'}><b><u>รายละเอียด{Data?.lands?.landLoanDetails?.productLoanLandType}</u></b></Row>
                                                <Row justify={'center'} gutter={32}>
                                                    <Col className='gutter-row' span={8}>
                                                        <Form.Item
                                                            style={{ marginBottom: 0, textAlign: 'center' }}
                                                            label='ยอดกู้'
                                                        >
                                                            <b>{currencyFormatOne(Data?.lands?.landLoanDetails?.loanAmountLand)}</b>
                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                บาท
                                                            </span>
                                                        </Form.Item>
                                                        <Form.Item
                                                            style={{ marginBottom: 0, textAlign: 'center' }}
                                                            label='จำนวนผ่อนต่อเดือน'
                                                        >
                                                            <b>{currencyFormatOne(Data?.lands?.landLoanDetails?.monthlyPaymentLand)}</b>
                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                บาท
                                                            </span>
                                                        </Form.Item>

                                                    </Col>
                                                    <Col className='gutter-row' span={7}>
                                                        <Form.Item
                                                            style={{ marginBottom: 0, textAlign: 'center' }}
                                                            label='จำนวนงวด'
                                                        >
                                                            <b>{Data?.lands?.landLoanDetails?.loanLandTerm}</b>
                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                บาท
                                                            </span>
                                                        </Form.Item>
                                                        <Form.Item
                                                            style={{ marginBottom: 0, textAlign: 'center' }}
                                                            label='ผ่อนรวมดอกเบี้ย'
                                                        >
                                                            <b>{currencyFormatOne(~~Data?.lands?.landLoanDetails?.monthlyPaymentLand * ~~Data?.lands?.landLoanDetails?.loanLandTerm)}</b>
                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                บาท
                                                            </span>
                                                        </Form.Item>

                                                    </Col>
                                                    <Col className='gutter-row' span={9}>
                                                        <Form.Item
                                                            style={{ marginBottom: 0, textAlign: 'center' }}
                                                            label='ดอกเบี้ย'
                                                        >
                                                            <b>{currencyFormatOne(Data?.lands?.landLoanDetails?.interestLand)}</b>
                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                งวด
                                                            </span>
                                                        </Form.Item>

                                                        <Form.Item
                                                            style={{ marginBottom: 0, textAlign: 'center' }}
                                                            label='ผู้เสนอเคส'
                                                        >
                                                            <b>{Data?.lands?.landLoanDetails?.proposalBy}</b>
                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                บาท
                                                            </span>
                                                        </Form.Item>
                                                        <b> <Form.Item
                                                            style={{ margin: 0, textAlign: 'center' }}
                                                            label='เรทที่ดิน'
                                                        >
                                                            <b style={{ fontSize: '18px' }}><u>{currencyFormatOne(Data?.lands?.resultLandPrice)}</u></b>
                                                            {/* <b style={{ fontSize: '20px' }}><u>{currencyFormatOne(Data?.landLoanDetails?.land?.landPrice)}</u></b> */}

                                                            <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                                                บาท
                                                            </span>
                                                        </Form.Item>
                                                        </b>
                                                    </Col>
                                                </Row>

                                            </>
                                        }
                                    </Card>
                                </>
                            }

                            {
                                Data?.cars?.carLoanDetails?.approvalStatus === 3 ||
                                    Data?.cars?.carLoanDetails?.approvalStatus === 27 ||
                                    Data?.cars?.carLoanDetails?.approvalStatus === 25 ||
                                    Data?.cars?.carLoanDetails?.approvalStatus === 26 ||
                                    Data?.cars?.carLoanDetails?.approvalStatus === 24 ?
                                    <>
                                        <Card style={{ width: '885px' }}>

                                            {Data?.cars?.carLoanDetails?.productType === 'เช่าซื้อ' ?
                                                <>
                                                    {Data?.cars?.apCarLoanDetails?.loanId !== 0 ?
                                                        <>
                                                            {/* ถ้ามีค่าใน ap */}
                                                            <Row justify={'left'}><b><u>ยอดที่อนุมัติ</u></b></Row>
                                                            <Row gutter={32} justify={'center'} style={{ backgroundColor: "lightgreen" }}>
                                                                <Col className='gutter-row' span={8}>
                                                                    <div
                                                                        style={{ marginBottom: 0, }}
                                                                    >
                                                                        <span>ยอดกู้เช่าซื้อ : </span>
                                                                        <b>{currencyFormatOne(Data?.cars?.apCarLoanDetails?.apLoanAmount)}</b>
                                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                            บาท
                                                                        </span>
                                                                    </div>
                                                                    <div
                                                                        style={{ marginBottom: 0, }}
                                                                    // label='จำนวนผ่อน/เดือนเช่าซื้อ'
                                                                    >
                                                                        <span>จำนวนผ่อน/เดือนเช่าซื้อ : </span>
                                                                        <b>{currencyFormatOne(Data?.cars?.apCarLoanDetails?.apMonthlyPayment)}</b>
                                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                            บาท
                                                                        </span>
                                                                    </div>

                                                                </Col>
                                                                <Col className='gutter-row' span={8}>
                                                                    <div
                                                                        style={{ marginBottom: 0, }}
                                                                    // label='จำนวนงวดเช่าซื้อ'
                                                                    >
                                                                        <span>จำนวนงวดเช่าซื้อ : </span>
                                                                        <b>{Data?.cars?.apCarLoanDetails?.apLoanTerm}</b>
                                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                            งวด
                                                                        </span>
                                                                    </div>
                                                                    <div
                                                                        style={{ marginBottom: 0, }}
                                                                    // label='ผ่อนรวมดอกเบี้ยเช่าซื้อ'
                                                                    >
                                                                        <span>ผ่อนรวมดอกเบี้ยเช่าซื้อ : </span>
                                                                        {/* <b>{currencyFormatOne(Data?.cars?.apCarLoanDetails?.apInstallmentWithInterest)}</b> */}
                                                                        <b>{currencyFormatOne(~~Data?.cars?.apCarLoanDetails?.apLoanTerm * ~~Data?.cars?.apCarLoanDetails?.apMonthlyPayment)}</b>
                                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                            บาท
                                                                        </span>
                                                                    </div>

                                                                </Col>
                                                                <Col className='gutter-row' span={8}>
                                                                    <div
                                                                        style={{ marginBottom: 0, }}
                                                                    // label='ดอกเบี้ยเช่าซื้อ'
                                                                    >
                                                                        <span>ดอกเบี้ยเช่าซื้อ : </span>
                                                                        <b>{currencyFormatOne(Data?.cars?.apCarLoanDetails?.apInterest)}</b>
                                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                            บาท
                                                                        </span>
                                                                    </div>
                                                                    <div
                                                                        style={{ marginBottom: 0, }}
                                                                    // label='ผู้เสนอเคสเช่าซื้อ'
                                                                    >
                                                                        <span>ผู้อนุมัติเช่าซื้อ : </span>
                                                                        <b>{Data?.cars?.apCarLoanDetails?.apApprovedBy}</b>
                                                                    </div>

                                                                </Col>

                                                            </Row>
                                                        </>
                                                        :
                                                        <>
                                                            {/* ถ้าไม่มีค่าใน ap */}
                                                            <Divider />

                                                            <Row justify={'left'}><b><u>ยอดที่อนุมัติ</u></b></Row>
                                                            <Row gutter={32} justify={'center'} style={{ backgroundColor: "lightgreen" }}>
                                                                <Col className='gutter-row' span={8}>
                                                                    <div style={{ marginBottom: 0, }}>
                                                                        <span>ยอดกู้ : </span>
                                                                        <b>{currencyFormatOne(getData1?.cars?.carLoanDetails?.approvedLoanAmount)}</b>
                                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                            บาท
                                                                        </span>
                                                                    </div>
                                                                    <div style={{ marginBottom: 0, }}>
                                                                        <span>จำนวนผ่อน/เดือน : </span>
                                                                        <b>{currencyFormatOne(newLoanTermCarDATA)}</b>
                                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                            บาท
                                                                        </span>
                                                                    </div>
                                                                </Col>
                                                                <Col className='gutter-row' span={8}>
                                                                    <div style={{ marginBottom: 0, }}>
                                                                        <span>จำนวนงวด : </span>
                                                                        <b>{getData1?.cars?.carLoanDetails?.approvedLoanTerm}</b>
                                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                            งวด
                                                                        </span>
                                                                    </div>
                                                                    <div style={{ marginBottom: 0, }}>
                                                                        <span>ผ่อนรวมดอกเบี้ย : </span>
                                                                        <b>{currencyFormatOne(newResultPriceCarDATA)}</b>
                                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                            บาท
                                                                        </span>
                                                                    </div>


                                                                </Col>
                                                                <Col className='gutter-row' span={8}>
                                                                    <div style={{ marginBottom: 0, }}>
                                                                        <span>ดอกเบี้ย : </span>
                                                                        <b>{currencyFormatOne(resultRateCarDATA)}</b>
                                                                        {/* <b>{currencyFormatOne(getData1?.cars?.carLoanDetails?.interest)}</b> */}
                                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                            บาท
                                                                        </span>
                                                                    </div>

                                                                    <div style={{ marginBottom: 0, }}>
                                                                        <span>ผู้อนุมัติ : </span>
                                                                        <b>{Data?.cars?.carLoanDetails?.approvedBy}</b>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </>
                                                    }
                                                </>
                                                :
                                                <>
                                                    {/* p-loan */}
                                                    {getData1?.cars?.apLandLoanDetails?.loanId !== 0 ?
                                                        <>
                                                            {/* ถ้ามีค่าใน ap */}
                                                            <Row justify={'left'}><b><u>ยอดที่อนุมัติ</u></b></Row>
                                                            <Row gutter={32} justify={'center'} style={{ backgroundColor: "lightgreen" }}>
                                                                <Col className='gutter-row' span={8}>
                                                                    <Form.Item
                                                                        style={{ marginBottom: 0, }}
                                                                    >
                                                                        <span>ยอดกู้ p-loan : </span>
                                                                        <b>{currencyFormatOne(getData1?.cars?.apCarLoanDetails?.apLoanAmount)}</b>
                                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                            บาท
                                                                        </span>

                                                                    </Form.Item>
                                                                    <Form.Item
                                                                        style={{ marginBottom: 0, }}
                                                                    >
                                                                        <span>จำนวนผ่อน/เดือน p-loan : </span>
                                                                        <b>{currencyFormatOne(getData1?.cars?.apCarLoanDetails?.apMonthlyPayment)}</b>
                                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                            บาท
                                                                        </span>

                                                                    </Form.Item>
                                                                </Col>
                                                                <Col className='gutter-row' span={8}>
                                                                    <Form.Item
                                                                        style={{ marginBottom: 0, }}
                                                                    >
                                                                        <span>จำนวนงวด p-loan : </span>
                                                                        <b>{getData1?.cars?.apCarLoanDetails?.apLoanTerm}</b>
                                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                            งวด
                                                                        </span>
                                                                    </Form.Item>
                                                                    <Form.Item
                                                                        style={{ marginBottom: 0, }}
                                                                    >
                                                                        <span>ผ่อนรวมดอกเบี้ย p-loan : </span>
                                                                        <b>{currencyFormatOne(~~Data?.cars?.apCarLoanDetails?.apLoanTerm * ~~Data?.cars?.apCarLoanDetails?.apMonthlyPayment)}</b>
                                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                            บาท
                                                                        </span>
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col className='gutter-row' span={8}>
                                                                    <Form.Item
                                                                        style={{ marginBottom: 0, }}
                                                                    >
                                                                        <span>ดอกเบี้ย p-loan : </span>
                                                                        <b>{currencyFormatOne(getData1?.cars?.apCarLoanDetails?.apInterest)}</b>
                                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                            บาท
                                                                        </span>

                                                                    </Form.Item>
                                                                    <Form.Item
                                                                        style={{ marginBottom: 0, }}
                                                                    >
                                                                        <span>ผู้อนุมัติ p-loan : </span>
                                                                        <b>{getData1?.cars?.apCarLoanDetails?.apApprovedBy}</b>

                                                                    </Form.Item>
                                                                </Col>

                                                            </Row>
                                                        </>
                                                        :
                                                        <>
                                                            {/* ถ้าไม่มีค่าใน ap */}
                                                            <Divider />

                                                            <Row justify={'left'}><b><u>ยอดที่อนุมัติ</u></b></Row>
                                                            <Row gutter={32} justify={'center'} style={{ backgroundColor: "lightgreen" }}>
                                                                <Col className='gutter-row' span={8}>
                                                                    <div style={{ marginBottom: 0, }}>
                                                                        <span>ยอดกู้ : </span>
                                                                        <b>{currencyFormatOne(Data?.cars?.carLoanDetails?.approvedLoanAmount)}</b>
                                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                            บาท
                                                                        </span>
                                                                    </div>
                                                                    <div style={{ marginBottom: 0, }}>
                                                                        <span>จำนวนผ่อน/เดือน : </span>
                                                                        <b>{currencyFormatOne(arr)}</b>
                                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                            บาท
                                                                        </span>
                                                                    </div>
                                                                </Col>
                                                                <Col className='gutter-row' span={8}>
                                                                    <div style={{ marginBottom: 0, }}>
                                                                        <span>จำนวนงวด : </span>
                                                                        <b>{Data?.cars?.carLoanDetails?.approvedLoanTerm}</b>
                                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                            งวด
                                                                        </span>
                                                                    </div>
                                                                    <div style={{ marginBottom: 0, }}>
                                                                        <span>ผ่อนรวมดอกเบี้ย : </span>
                                                                        <b>{currencyFormatOne(installmentWithInterestt)}</b>
                                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                            บาท
                                                                        </span>
                                                                    </div>


                                                                </Col>
                                                                <Col className='gutter-row' span={8}>
                                                                    <div style={{ marginBottom: 0, }}>
                                                                        <span>ดอกเบี้ย : </span>
                                                                        <b>{currencyFormatOne(Data?.cars?.carLoanDetails?.interest)}</b>
                                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                            บาท
                                                                        </span>
                                                                    </div>

                                                                    <div style={{ marginBottom: 0, }}>
                                                                        <span>ผู้อนุมัติ : </span>
                                                                        <b>{Data?.cars?.carLoanDetails?.approvedBy}</b>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </>
                                                    }

                                                </>
                                            }

                                        </Card>
                                    </>
                                    : null
                            }
                            {
                                Data?.lands?.landLoanDetails?.approvalStatus === 3 ||
                                    Data?.lands?.landLoanDetails?.approvalStatus === 27 ||
                                    Data?.lands?.landLoanDetails?.approvalStatus === 25 ||
                                    Data?.lands?.landLoanDetails?.approvalStatus === 26 ||
                                    Data?.lands?.landLoanDetails?.approvalStatus === 24 ?
                                    <>
                                        <Card style={{ width: '885px' }}>

                                            {Data?.lands?.apLandLoanDetails?.loanId !== 0 ?
                                                <>
                                                    {/* ถ้ามีค่าใน ap */}
                                                    <Row justify={'left'}><b><u>ยอดที่อนุมัติ</u></b></Row>
                                                    <Row gutter={32} justify={'center'} style={{ backgroundColor: "lightgreen" }}>
                                                        <Col className='gutter-row' span={8}>
                                                            <div
                                                                style={{ marginBottom: 0, }}
                                                            >
                                                                <span>ยอดกู้ : </span>
                                                                <b>{currencyFormatOne(Data?.lands?.apLandLoanDetails?.apLoanAmount)}</b>
                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                    บาท
                                                                </span>
                                                            </div>
                                                            <div
                                                                style={{ marginBottom: 0, }}
                                                            // label='จำนวนผ่อน/เดือน'
                                                            >
                                                                <span>จำนวนผ่อน/เดือน : </span>
                                                                <b>{currencyFormatOne(Data?.lands?.apLandLoanDetails?.apMonthlyPayment)}</b>
                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                    บาท
                                                                </span>
                                                            </div>

                                                        </Col>
                                                        <Col className='gutter-row' span={8}>
                                                            <div
                                                                style={{ marginBottom: 0, }}
                                                            // label='จำนวนงวด'
                                                            >
                                                                <span>จำนวนงวด : </span>
                                                                <b>{Data?.lands?.apLandLoanDetails?.apLoanTerm}</b>
                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                    งวด
                                                                </span>
                                                            </div>
                                                            <div
                                                                style={{ marginBottom: 0, }}
                                                            // label='ผ่อนรวมดอกเบี้ย'
                                                            >
                                                                <span>ผ่อนรวมดอกเบี้ย : </span>
                                                                <b>{currencyFormatOne(Data?.lands?.apLandLoanDetails?.apInstallmentWithInterest)}</b>
                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                    บาท
                                                                </span>
                                                            </div>

                                                        </Col>
                                                        <Col className='gutter-row' span={8}>
                                                            <div
                                                                style={{ marginBottom: 0, }}
                                                            // label='ดอกเบี้ย'
                                                            >
                                                                <span>ดอกเบี้ย : </span>
                                                                <b>{currencyFormatOne(Data?.lands?.apLandLoanDetails?.apInterest)}</b>
                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                    บาท
                                                                </span>
                                                            </div>

                                                            <div
                                                                style={{ marginBottom: 0, }}
                                                            // label='ผู้เสนอเคส'
                                                            >
                                                                <span>ผู้อนุมัติ : </span>
                                                                <b>{Data?.lands?.apLandLoanDetails?.apApprovedBy}</b>
                                                            </div>

                                                        </Col>
                                                    </Row>
                                                </>
                                                :
                                                <>
                                                    {/* ถ้าไม่มีค่าใน ap */}
                                                    <Row justify={'left'}><b><u>ยอดที่อนุมัติ</u></b></Row>
                                                    <Row gutter={32} justify={'center'} style={{ backgroundColor: "lightgreen" }}>
                                                        <Col className='gutter-row' span={8}>
                                                            <div style={{ marginBottom: 0, }}>
                                                                <span>ยอดกู้ : </span>
                                                                <b>{currencyFormatOne(getData1?.lands?.landLoanDetails?.approvedLoanAmount)}</b>
                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                    บาท
                                                                </span>
                                                            </div>
                                                            <div style={{ marginBottom: 0, }}>
                                                                <span>จำนวนผ่อน/เดือน : </span>
                                                                <b>{currencyFormatOne(loanTermDATA)}</b>
                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                    บาท
                                                                </span>
                                                            </div>
                                                        </Col>
                                                        <Col className='gutter-row' span={8}>
                                                            <div style={{ marginBottom: 0, }}>
                                                                <span>จำนวนงวด : </span>
                                                                <b>{getData1?.lands?.landLoanDetails?.approvedLoanTerm}</b>
                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                    งวด
                                                                </span>
                                                            </div>
                                                            <div style={{ marginBottom: 0, }}>
                                                                <span>ผ่อนรวมดอกเบี้ย : </span>
                                                                <b>{currencyFormatOne(resultPriceDATA)}</b>
                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                    บาท
                                                                </span>
                                                            </div>


                                                        </Col>
                                                        <Col className='gutter-row' span={8}>
                                                            <div style={{ marginBottom: 0, }}>
                                                                <span>ดอกเบี้ย : </span>
                                                                <b>{currencyFormatOne(resultRateDATA)}</b>
                                                                {/* <b>{currencyFormatOne(getData1?.lands?.landLoanDetails?.interest)}</b> */}
                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                    บาท
                                                                </span>
                                                            </div>

                                                            <div style={{ marginBottom: 0, }}>
                                                                <span>ผู้อนุมัติ : </span>
                                                                <b>{Data?.lands?.landLoanDetails?.approvedBy}</b>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </>
                                            }

                                        </Card>
                                    </>
                                    : null
                            }


                            {Data?.sendtypesDes === "car" ?
                                <>
                                    <Space direction="vertical">
                                        <Card style={{ width: '885px' }}>

                                            <Row justify={'left'}><b><u>รายละเอียดรถ</u></b></Row>
                                            <Row justify={'center'} gutter={32}>
                                                <Col className='gutter-row' span={8}>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>ยี่ห้อ : </span>
                                                        <b>{Data?.cars?.carBrand}</b>
                                                    </div>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>เลขทะเบียน : </span>
                                                        <b>{Data?.cars?.carPlateNumber}</b>
                                                    </div>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>เลขเครื่อง : </span>
                                                        <b>{Data?.cars?.carEngineNumber}</b>
                                                    </div>
                                                    {/* <Form.Item
                                                    style={{ marginBottom: 0 }}
                                                    label='ยี่ห้อ'
                                                >
                                                   <b>{Data?.carLoanDetails?.cars?.carBrand}</b>
                                                </Form.Item> */}
                                                    {/* <Form.Item
                                                    style={{ marginBottom: 0 }}
                                                    label='เลขทะเบียน'
                                                >
                                                    <b>{Data?.carLoanDetails?.cars?.carPlateNumber}</b>

                                                </Form.Item> */}
                                                    {/* <Form.Item
                                                    style={{ marginBottom: 0 }}
                                                    label='เลขเครื่อง'
                                                >
                                                    <b>{Data?.carLoanDetails?.cars?.carEngineNumber}</b>

                                                </Form.Item> */}
                                                </Col>
                                                <Col className='gutter-row' span={8}>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>รุ่นสินค้า : </span>
                                                        <b>{Data?.cars?.carModel}</b>
                                                    </div>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>เลขคัสซี : </span>
                                                        <b>{Data?.cars?.carChassisNumber}</b>
                                                    </div>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>สี : </span>
                                                        <b>{Data?.cars?.carColor}</b>
                                                    </div>
                                                    {/* <Form.Item
                                                    style={{ marginBottom: 0 }}
                                                    label='รุ่นสินค้า'
                                                >
                                                    <b>{Data?.carLoanDetails?.cars?.carModel}</b>

                                                </Form.Item> */}
                                                    {/* <Form.Item
                                                    style={{ marginBottom: 0 }}
                                                    label='เลขคัสซี'
                                                >
                                                    <b>{Data?.carLoanDetails?.cars?.carChassisNumber}</b>

                                                </Form.Item> */}
                                                    {/* <Form.Item
                                                    style={{ marginBottom: 0 }}
                                                    label='สี'
                                                >
                                                    <b>{Data?.carLoanDetails?.cars?.carColor}</b>

                                                </Form.Item> */}
                                                </Col>
                                                <Col className='gutter-row' span={8}>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>แบบรถ : </span>
                                                        <b>{Data?.cars?.carBaab}</b>
                                                    </div>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>ปีรถ : </span>
                                                        <b>{Data?.cars?.carYear}</b>
                                                    </div>
                                                    {/* <Form.Item
                                                    style={{ marginBottom: 0 }}
                                                    label='แบบรถ'
                                                >
                                                    <b>{Data?.carLoanDetails?.cars?.carBaab}</b>

                                                </Form.Item> */}
                                                    {/* <Form.Item
                                                    style={{ marginBottom: 0 }}
                                                    label='ปีรถ'
                                                >
                                                    <b>{Data?.carLoanDetails?.cars?.carYear}</b>

                                                </Form.Item> */}
                                                </Col>
                                            </Row>

                                        </Card>
                                    </Space>

                                </>
                                :
                                <>
                                    <Space direction="vertical">

                                        <Card style={{ width: '885px' }}>

                                            <Row justify={'left'}><b><u>รายละเอียดที่ดิน</u></b></Row>
                                            <Row justify={'center'} gutter={32}>
                                                <Col className='gutter-row' span={10}>
                                                    <Form.Item
                                                        style={{ marginBottom: 0 }}
                                                    // label='กลุ่มสินค้า'
                                                    >
                                                        <span>กลุ่มสินค้า : </span>
                                                        <b>{Data?.lands?.landLoanDetails?.productType}</b>
                                                    </Form.Item>
                                                    <Form.Item
                                                        style={{ marginBottom: 0 }}
                                                    // label='เลขที่ดินหรือระวาง'
                                                    >
                                                        <span>เลขที่ดินหรือระวาง : </span>
                                                        <b>{Data?.lands?.numberLandlawang}</b>
                                                    </Form.Item>
                                                    <Form.Item
                                                        style={{ marginBottom: 0 }}
                                                    // label='พื้นที่ไร่'
                                                    >
                                                        <span>พื้นที่ไร่ : </span>
                                                        <b>{Data?.lands?.rai}</b>
                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                            ไร่
                                                        </span>
                                                    </Form.Item>
                                                    <Form.Item
                                                        style={{ marginBottom: 0 }}
                                                    // label='ราคาประเมินที่ดิน'
                                                    >  <span>ราคาประเมินที่ดิน : </span>
                                                        <b>{currencyFormatOne(Data?.lands?.landPrice)}</b>
                                                        <span style={{ display: 'inline-block', marginLeft: '5px' }}>
                                                            บาท / ตารางวา
                                                        </span>
                                                    </Form.Item>

                                                </Col>
                                                <Col className='gutter-row' span={7}>
                                                    <Form.Item
                                                        style={{ marginBottom: 0 }}
                                                    // label='ประเภทเอกสาร'
                                                    >
                                                        <span>ประเภทเอกสาร : </span>
                                                        <b>{Data?.lands?.landLoanDetails?.productLoanLandType}</b>
                                                    </Form.Item>
                                                    <Form.Item
                                                        style={{ marginBottom: 0 }}
                                                    // label='จังหวัด'
                                                    >
                                                        <span>จังหวัด : </span>
                                                        <b>{Data?.lands?.provinces}</b>
                                                    </Form.Item>
                                                    <Form.Item
                                                        style={{ marginBottom: 0 }}
                                                    // label='พื้นที่งาน'
                                                    >
                                                        <span>พื้นที่งาน : </span>
                                                        <b>{Data?.lands?.workArea}</b>
                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                            งาน
                                                        </span>
                                                    </Form.Item>
                                                    <Form.Item
                                                        style={{ marginBottom: 0 }}
                                                    // label='ราคารวม'
                                                    >
                                                        <span>ราคารวม : </span>
                                                        <b>{currencyFormatOne(Data?.lands?.resultLandPrice)}</b>
                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                            บาท
                                                        </span>
                                                    </Form.Item>

                                                </Col>
                                                <Col className='gutter-row' span={7}>
                                                    <Form.Item
                                                        style={{ marginBottom: 0 }}
                                                    // label='เลขโฉนดที่ดิน'
                                                    >
                                                        <span>เลขโฉนดที่ดิน : </span>
                                                        <b>{Data?.lands?.numberLand}</b>
                                                    </Form.Item>
                                                    <Form.Item
                                                        style={{ marginBottom: 0 }}
                                                    // label='อำเภอ'
                                                    >
                                                        <span>อำเภอ : </span>
                                                        <b>{Data?.lands?.district}</b>
                                                    </Form.Item>
                                                    <Form.Item
                                                        style={{ marginBottom: 0 }}
                                                    // label='พื้นที่ตารางวา'
                                                    >
                                                        <span>พื้นที่ตารางวา : </span>
                                                        <b>{Data?.lands?.squareWaArea}</b>
                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                            ตารางวา
                                                        </span>
                                                    </Form.Item>

                                                </Col>
                                            </Row>

                                        </Card>
                                    </Space>

                                </>
                            }


                            <Space direction="vertical">
                                <Card style={{ width: '885px' }}>
                                    <Row justify={'left'}>
                                        <b><u>อาชีพ - รายได้</u></b>
                                    </Row>
                                    {/* {dataCareer !== undefined || dataCareer?.length > 0 ? */}
                                    {career !== undefined || career?.length > 0 ?
                                        <>
                                            {career?.map((e, index) => {
                                                return (
                                                    <Row>
                                                        <Col className='gutter-row' span={8}>
                                                            <div style={{ marginBottom: 0, }}>
                                                                <span>กลุ่มอาชีพ : </span>
                                                                <b>{e?.desc}</b>
                                                            </div>
                                                            <div style={{ marginBottom: 0, }}>
                                                                <span>ชื่อบริษัท(สถานที่ทำงาน) : </span>
                                                                <b>{e?.companyName}</b>
                                                            </div>
                                                        </Col>
                                                        <Col className='gutter-row' span={8}>
                                                            <div style={{ marginBottom: 0, }}>
                                                                <span>อาชีพ  : </span>
                                                                <b>{e?.descSub}</b>
                                                            </div>
                                                            <div style={{ marginBottom: 0, }}>
                                                                <span>รายได้ / เดือน : </span>
                                                                <b>{currencyFormatOne(e?.incomeMonth)}</b>
                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                    บาท
                                                                </span>
                                                            </div>
                                                        </Col>
                                                        <Col className='gutter-row' span={8}>
                                                            <div style={{ marginBottom: 0, }}>
                                                                <span>สาขาอาชีพ : </span>
                                                                <b>{e?.descSection}</b>
                                                            </div>

                                                            <div style={{ marginBottom: 0, }}>
                                                                <span>
                                                                    รายจ่าย / เดือน : </span>
                                                                <b>{currencyFormatOne(e?.expensesMonth)}</b>
                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                    บาท
                                                                </span>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                )
                                            })}
                                        </>
                                        :
                                        <>
                                            ***ยังไม่ได้เพิ่มอาชีพ***
                                        </>
                                    }
                                </Card>
                            </Space>

                            <Card style={{ width: '885px' }}>
                                <Row justify={'left'}><Col span={24}><b><u>หมายเหตุ</u></b></Col></Row>
                                <Row gutter={32} justify={'center'}>
                                    {notes.length >= 1 ?

                                        <Card
                                            style={{ width: '270px', textAlign: 'left' }}>
                                            <div style={{ marginBottom: 0 }}>
                                                <span>สาเหตุ : </span>
                                                <b>{notes[lastIndex].cause}</b>
                                            </div>
                                            <div style={{ marginBottom: 0 }}>
                                                <span>หมายเหตุ : </span>
                                                <b>{notes[lastIndex].note}</b>
                                            </div>
                                            <div style={{ marginBottom: 0 }}>
                                                <span>ผู้สร้างหมายเหตุ : </span>
                                                <b>{notes[lastIndex].noteBy}</b>
                                            </div>
                                            <div style={{ marginBottom: 0 }}>
                                                <span>สถานะที่ทำการหมายเหตุ : </span>
                                                <b>{notes[lastIndex].status}</b>
                                            </div>
                                        </Card>

                                        : <>* ยังไม่เพิ่มหมายเหตุ!</>
                                    }
                                </Row>
                            </Card>

                            {/* <Space direction="vertical"> */}
                            {guarantor1?.length >= 1 ?
                                <>
                                    <Card style={{ width: '885px' }}>
                                        <Space direction="vertical">
                                            <Row justify={'left'}><Col span={24}><b><u>รายละเอียดคนค้ำ</u></b></Col></Row>
                                            <Row gutter={32} justify={'left'}>
                                                {
                                                    guarantor1?.map((item, index) => {
                                                        return renderGuarantor({ item, index, key: `{item.identificationId} - ${index}` })
                                                    })
                                                }
                                            </Row>
                                        </Space>

                                    </Card>
                                </>
                                : null
                            }

                        </Row>

                        <Divider style={{ margin: 5 }} />


                        {Data?.sendtypesDes === "car" ?
                            <>
                                <Space direction="vertical">
                                    <div className="space-align-container">
                                        <Row justify={'left'}><b><u>รูปผู้ขอกู้</u></b></Row>
                                        <Row gutter={32} justify={'center'} style={{ width: '878px' }}>
                                            {imageBlobzzCar?.map((e, index) => {
                                                if (e.type === 4) {
                                                    return (
                                                        <div className="space-align-block">
                                                            <Space direction="vertical" align="baseline">
                                                                <Row style={{ width: "210mm", height: "287mm" }} justify={'center'} align={'middle'}>
                                                                    <Image width={"100%"} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                </Row>
                                                            </Space>
                                                        </div>
                                                    );
                                                }
                                                return null;
                                            })}
                                        </Row>
                                    </div>
                                </Space>
                                <Divider />
                                <Space direction="vertical">
                                    <div className="space-align-container">
                                        <Row justify={'left'}><b><u>รูปอาชีพผู้ขอกู้</u></b></Row>
                                        <Row gutter={32} justify={'center'} style={{ width: '878px' }}>
                                            {imageBlobzzCar?.map((e, index) => {
                                                if (e.type === 6) {
                                                    return (
                                                        <div className="space-align-block">
                                                            <Space direction="vertical" align="baseline">
                                                                <Row style={{ width: "210mm", height: "287mm" }} justify={'center'} align={'middle'}>
                                                                    <Image width={"100%"} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                </Row>
                                                            </Space>
                                                        </div>
                                                    );
                                                }
                                                return null;
                                            })}
                                        </Row>
                                    </div>
                                </Space>
                                <Divider />
                                <Space direction="vertical">
                                    <div className="space-align-container">
                                        <Row justify={'left'}><b><u>รูปรถ</u></b></Row>
                                        <Row gutter={32} justify={'center'} style={{ width: '878px' }}>
                                            {imageBlobzzCar?.map((e, index) => {
                                                if (e.type === 1) {
                                                    return (
                                                        <div className="space-align-block">
                                                            <Space direction="vertical" align="baseline">
                                                                <Row style={{ width: "210mm", height: "287mm" }} justify={'center'} align={'middle'}>
                                                                    <Image width={"100%"} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                </Row>
                                                            </Space>
                                                        </div>
                                                    );
                                                }
                                                return null;
                                            })}
                                        </Row>
                                    </div>
                                </Space>
                                <Divider />
                                <Space direction="vertical">
                                    <div className="space-align-container">
                                        <Row justify={'left'}><b><u>รูปเอกสาร</u></b></Row>
                                        <Row gutter={32} justify={'center'} style={{ width: '878px' }}>

                                            {imageBlobzzCar?.map((e, index) => {
                                                if (e.type === 2) {
                                                    return (
                                                        <div className="space-align-block">
                                                            <Space direction="vertical" align="baseline">
                                                                <Row style={{ width: "210mm", height: "287mm" }} justify={'center'} align={'middle'}>
                                                                    <Image width={"100%"} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                </Row>
                                                            </Space>
                                                        </div>
                                                    );
                                                }
                                                return null;
                                            })}

                                        </Row>
                                    </div>
                                </Space>
                                <Divider />
                                <Space direction='vertical'>
                                    <div className="space-align-container">
                                        <Row justify={'left'}><b><u>รูปคนค้ำประกัน</u></b></Row>
                                        <Row gutter={32} justify={'center'} style={{ width: '878px' }}>
                                            {imageBlobzzCar?.map((e, index) => {
                                                if (e.type === 5) {
                                                    return (
                                                        <div className="space-align-block">
                                                            <Space direction="vertical" align="baseline">
                                                                <Row style={{ width: "210mm", height: "287mm" }} justify={'center'} align={'middle'}>
                                                                    <Image width={"100%"} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                </Row>
                                                            </Space>
                                                        </div>
                                                    );
                                                }
                                                return null;
                                            })}
                                        </Row>
                                    </div>
                                </Space>
                                <Divider />
                                <Space direction="vertical">
                                    <div className="space-align-container">
                                        <Row justify={'left'}><b><u>สเตทเม้น</u></b></Row>
                                        <Row gutter={32} justify={'center'} style={{ width: '878px' }}>
                                            {imageBlobzzCar?.map((e, index) => {
                                                if (e.type === 8) {
                                                    return (
                                                        <div className="space-align-block">
                                                            <Space direction="vertical" align="baseline">
                                                                <Row style={{ width: "210mm", height: "287mm" }} justify={'center'} align={'middle'}>
                                                                    <Image width={"100%"} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                </Row>
                                                            </Space>
                                                        </div>
                                                    );
                                                }
                                                return null;
                                            })}

                                        </Row>
                                    </div>
                                </Space>
                                <Divider />
                                <Space direction="vertical">
                                    <div className="space-align-container">
                                        <Row justify={'left'}><b><u>รูปอื่นๆ</u></b></Row>
                                        <Row gutter={32} justify={'center'} style={{ width: '878px' }}>
                                            {imageBlobzzCar?.map((e, index) => {
                                                if (e.type === 9) {
                                                    return (
                                                        <div className="space-align-block">
                                                            <Space direction="vertical" align="baseline">
                                                                <Row style={{ width: "210mm", height: "287mm" }} justify={'center'} align={'middle'}>
                                                                    <Image width={"100%"} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                </Row>
                                                            </Space>
                                                        </div>
                                                    );
                                                }
                                                return null;
                                            })}
                                        </Row>
                                    </div>
                                </Space>

                            </>
                            :
                            <>

                                <Space direction="vertical">
                                    <div className="space-align-container">
                                        <Row justify={'left'}><b><u>รูปผู้ขอกู้</u></b></Row>
                                        <Row gutter={32} justify={'center'} style={{ width: '878px' }}>
                                            {imageBlobzzLand?.map((e, index) => {
                                                if (e.type === 4) {
                                                    return (
                                                        <div className="space-align-block">
                                                            <Space direction="vertical" align="baseline">
                                                                <Row style={{ width: "210mm", height: "287mm" }} justify={'center'} align={'middle'}>
                                                                    <Image width={"100%"} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                </Row>
                                                            </Space>
                                                        </div>
                                                    );
                                                }
                                                return null;
                                            })}

                                        </Row>
                                    </div>
                                </Space>
                                <Divider />
                                <Space direction="vertical">
                                    <div className="space-align-container">
                                        <Row justify={'left'}><b><u>รูปอาชีพผู้ขอกู้</u></b></Row>
                                        <Row gutter={32} justify={'center'} style={{ width: '878px' }}>
                                            {imageBlobzzLand?.map((e, index) => {
                                                if (e.type === 6) {
                                                    return (
                                                        <div className="space-align-block">
                                                            <Space direction="vertical" align="baseline">
                                                                <Row style={{ width: "210mm", height: "287mm" }} justify={'center'} align={'middle'}>
                                                                    <Image width={"100%"} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                </Row>
                                                            </Space>
                                                        </div>
                                                    );
                                                }
                                                return null;
                                            })}
                                        </Row>
                                    </div>
                                </Space>
                                <Divider />
                                <Space direction="vertical">
                                    <div className="space-align-container">
                                        <Row justify={'left'}><b><u>รูปโฉนด/หน้า-หลัง</u></b></Row>
                                        <Row gutter={32} justify={'center'} style={{ width: '878px' }}>
                                            {imageBlobzzLand?.map((e, index) => {
                                                if (e.type === 3) {
                                                    return (
                                                        <div className="space-align-block">
                                                            <Space direction="vertical" align="baseline">
                                                                <Row style={{ width: "210mm", height: "287mm" }} justify={'center'} align={'middle'}>
                                                                    <Image width={"100%"} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                </Row>
                                                            </Space>
                                                        </div>
                                                    );
                                                }
                                                return null;
                                            })}
                                        </Row>
                                    </div>
                                </Space>
                                <Divider />
                                <Space direction="vertical">
                                    <div className="space-align-container">
                                        <Row justify={'left'}><b><u>ใบแบ่งใบต่อ</u></b></Row>
                                        <Row gutter={32} justify={'center'} style={{ width: '878px' }}>
                                            {imageBlobzzLand?.map((e, index) => {
                                                if (e.type === 10) {
                                                    return (
                                                        <div className="space-align-block">
                                                            <Space direction="vertical" align="baseline">
                                                                <Row style={{ width: "210mm", height: "287mm" }} justify={'center'} align={'middle'}>
                                                                    <Image width={"100%"} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                </Row>
                                                            </Space>
                                                        </div>
                                                    );
                                                }
                                                return null;
                                            })}
                                        </Row>
                                    </div>
                                </Space>
                                <Divider />
                                <Space direction="vertical">
                                    <div className="space-align-container">
                                        <Row justify={'left'}><b><u>ระวาง</u></b></Row>
                                        <Row gutter={32} justify={'center'} style={{ width: '878px' }}>
                                            {imageBlobzzLand?.map((e, index) => {
                                                if (e.type === 11) {
                                                    return (
                                                        <div className="space-align-block">
                                                            <Space direction="vertical" align="baseline">
                                                                <Row style={{ width: "210mm", height: "287mm" }} justify={'center'} align={'middle'}>
                                                                    <Image width={"100%"} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                </Row>
                                                            </Space>
                                                        </div>
                                                    );
                                                }
                                                return null;
                                            })}
                                        </Row>
                                    </div>
                                </Space>
                                <Divider />
                                <Space direction="vertical">
                                    <div className="space-align-container">
                                        <Row justify={'left'}><b><u>รูปที่ดิน/สิ่งปลูกสร้าง-ที่ดินปัจจุบัน</u></b></Row>
                                        <Row gutter={32} justify={'center'} style={{ width: '878px' }}>
                                            {imageBlobzzLand?.map((e, index) => {
                                                if (e.type === 1) {
                                                    return (
                                                        <div className="space-align-block">
                                                            <Space direction="vertical" align="baseline">
                                                                <Row style={{ width: "210mm", height: "287mm" }} justify={'center'} align={'middle'}>
                                                                    <Image width={"100%"} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                </Row>
                                                            </Space>
                                                        </div>
                                                    );
                                                }
                                                return null;
                                            })}
                                        </Row>
                                    </div>
                                </Space>
                                <Divider />
                                <Space direction="vertical">
                                    <div className="space-align-container">
                                        <Row justify={'left'}><b><u>รูปคนค้ำประกัน</u></b></Row>
                                        <Row gutter={32} justify={'center'} style={{ width: '878px' }}>
                                            {imageBlobzzLand?.map((e, index) => {
                                                if (e.type === 5) {
                                                    return (
                                                        <div className="space-align-block">
                                                            <Space direction="vertical" align="baseline">
                                                                <Row style={{ width: "210mm", height: "287mm" }} justify={'center'} align={'middle'}>
                                                                    <Image width={"100%"} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                </Row>
                                                            </Space>
                                                        </div>
                                                    );
                                                }
                                                return null;
                                            })}
                                        </Row>
                                    </div>
                                </Space>
                                <Divider />
                                <Space direction="vertical">
                                    <div className="space-align-container">
                                        <Row justify={'left'}><b><u>สเตทเม้น</u></b></Row>
                                        <Row gutter={32} justify={'center'} style={{ width: '878px' }}>
                                            {imageBlobzzLand?.map((e, index) => {
                                                if (e.type === 8) {
                                                    return (
                                                        <div className="space-align-block">
                                                            <Space direction="vertical" align="baseline">
                                                                <Row style={{ width: "210mm", height: "287mm" }} justify={'center'} align={'middle'}>
                                                                    <Image width={"100%"} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                </Row>
                                                            </Space>
                                                        </div>
                                                    );
                                                }
                                                return null;
                                            })}
                                        </Row>
                                    </div>
                                </Space>
                                <Divider />
                                <Space direction="vertical">
                                    <div className="space-align-container">
                                        <Row justify={'left'}><b><u>รูปอื่นๆ</u></b></Row>
                                        <Row gutter={32} justify={'center'} style={{ width: '878px' }}>
                                            {imageBlobzzLand?.map((e, index) => {
                                                if (e.type === 9) {
                                                    return (
                                                        <div className="space-align-block">
                                                            <Space direction="vertical" align="baseline">
                                                                <Row style={{ width: "210mm", height: "287mm" }} justify={'center'} align={'middle'}>
                                                                    <Image width={"100%"} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                </Row>
                                                            </Space>
                                                        </div>
                                                    );
                                                }
                                                return null;
                                            })}

                                        </Row>
                                    </div>
                                </Space>
                            </>
                        }
                    </Form>
                </Row>
            </div >
        </Modal >
    )
};

export default ModalDrescriptionPrint