import React, { useState, useEffect } from 'react'
import { Form, Input, Row, Col, Button, Select, Divider, Space, Modal, Spin, Card, InputNumber } from 'antd'
import axios from 'axios'
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { addCustomer } from '../../../redux/Customer';
import Currency from 'currency.js';
import TextArea from 'antd/es/input/TextArea';
import { productLoanTypeLand } from '../../file_mid/all_options';
import { AllData, GetAmphur1, GetProvince, PostProvince1, editLand, loadimgland, upimgland, deleteNewUpLand } from '../../file_mid/all_api';

export default function EditLand({ onCancel, changepage, editData, AddImgSucc, DeleteImgSucc, DeleteImgError }) {

  const currentTime = dayjs();
  //const delayedTime = currentTime.add(7, 'hour');
  const formattedTime = currentTime.format('YYYY-MM-DDTHH:mm:ssZ');
  const { confirm } = Modal;
  const dispatch = useDispatch()
  const customers = useSelector(state => state.customers)
  const { Option } = Select;
  const [form] = Form.useForm()
  // const token = localStorage.getItem('token');
  const user = localStorage.getItem('username');
  const [loading, setLoading] = useState(false)
  //const [dataModalEdit, setDataModalEdit] = useState(dataFromTable)
  const [dataModalEdit, setDataModalEdit] = useState({})
  const [imageBlobzz, setImageBlobzz] = useState([]);
  //  const [dataModalEdit, setDataModalEdit] = useState({ ...dataFromTable.carLoanDetails, ...dataFromTable.oldLoanDetails, ...dataFromTable.car, })
  //////////////////// ที่ดิน /////////////////////////////
  const [dataPostLand, setDataPostLand] = useState({   // ที่ดิน
    landInput: dayjs().format("YYYY-MM-DDTHH:mm:ssZ"),
    landDateStatus: dayjs().format("YYYY-MM-DDTHH:mm:ssZ"),
    rai: 0,
    workArea: 0,
    squareWaArea: 0,
    landPrice: 0,
    resultLandPrice: 0,
    landPriceStatus: false,
  })
  const [moneyLand, setMoneyLand] = useState({}) //loan land
  // const [moneyOldLand, setMoneyOldLand] = useState({}) //loanOld land
  // const [typeMoneyLand, setTypeMoneyLand] = useState({})

  const [pvcodeS, setPvcodeS] = useState({});
  const [amcodeS, setAmcodeS] = useState({});

  const [provinces, setProvinces] = useState([]);
  const [district, setDistricts] = useState([]);

  ///////////////// อัปโหลดรูป //////////////////////////////////////
  // const [previewVisible, setPreviewVisible] = useState(false);
  // const [previewImage, setPreviewImage] = useState('');
  // const [fileList, setFileList] = useState([]);
  // const [fileListBook, setFileListBook] = useState([]);
  const [fileListResult1, setFileListResult1] = useState([]);
  const [fileListResult2, setFileListResult2] = useState([]);
  const [sell, setSell] = useState(true)
  const [checkButtonLand, setCheckButtonLand] = useState(0)

  useEffect(() => {
    if (customers.data !== "") {
      if (customers.data.productLoanLandType === "Pre-Aaprove" && customers.data.loanAmounttLand === 0) {
        setSell(false)
      }
      loadImage()
      setDataModalEdit(customers.data)
      getProvinces()
      GetAmphur(customers.data.district)
      PostProvince(customers.data.province)
      form.setFieldsValue(
        {
          ...customers.data,
          landPrice: currencyFormatOne(customers.data.landPrice),
          resultLandPrice: currencyFormatOne(customers.data.resultLandPrice),
          monthlyPaymentLand: currencyFormatOne(customers.data.monthlyPaymentLand),
          interesttLand: currencyFormatOne(customers.data.interesttLand),
          installmentWithInterestLand: currencyFormatOne(customers.data.installmentWithInterestLand),
          // rai: parseInt(customers.data?.rai),
          // workArea: parseInt(customers.data?.workArea),
          // squareWaArea: parseInt(customers.data?.squareWaArea),
          // landPrice: parseInt(customers.data?.landPrice),
          // resultLandPrice: parseInt(customers.data?.resultLandPrice),
          // loanAmounttLand: parseFloat(customers.data?.loanAmounttLand),
          // loanLandTerm: parseFloat(customers.data?.loanLandTerm),
          // interestRateLand: parseFloat(customers.data?.interestRateLand),
          // interesttLand: parseFloat(customers.data?.interesttLand),
          // monthlyPaymentLand: parseInt(customers.data?.monthlyPaymentLand),
          // oldLandLoanAmount: parseInt(customers.data?.oldLandLoanAmount),
          // oldLandMonthlyPayment: parseInt(customers.data?.oldLandMonthlyPayment),
          // oldLandLoanTerm: parseInt(customers.data?.oldLandLoanTerm),
          // oldLandKangPayment: parseInt(customers.data?.oldLandKangPayment),
          // oldLandKangPaymentTerm: parseInt(customers.data?.oldLandKangPaymentTerm),
        }
      )
    }
  }, [])

  //console.log("ez2", customers.data)
  // console.log("ez20", dataModalEdit)

  useEffect(() => {
    //console.log("XX")
    land_Calculate()

    if (dataModalEdit?.productLoanLandType === 'ย้ายไฟแนนซ์' || dataModalEdit?.productLoanLandType === 'Pre-Aaprove-ย้ายไฟแนนซ์') {
      // console.log("ez")
      land_Calculate_close()
    }
  }, [dataModalEdit?.loanAmountt, dataModalEdit?.interestRate, dataModalEdit?.installmentAmountt, dataModalEdit?.loanAmounttLand, dataModalEdit?.interestRateLand, dataModalEdit?.loanLandTerm, dataModalEdit?.oldLoanAmount, dataModalEdit?.oldMonthlyPayment, dataModalEdit?.oldLoanTerm, dataModalEdit?.oldKangPayment, dataModalEdit?.oldLandLoanAmount, dataModalEdit?.oldLandMonthlyPayment, dataModalEdit?.oldLandLoanTerm, dataModalEdit?.oldLandKangPayment])

//  console.log("dataModalEdit", dataModalEdit)

  const land_Calculate_close = () => {
    if (dataModalEdit?.oldLandLoanAmount && dataModalEdit?.oldLandMonthlyPayment && dataModalEdit?.oldLandLoanTerm) {
      var loanTermKhangLand = dataModalEdit?.oldLandKangPayment / dataModalEdit?.oldLandMonthlyPayment
      setDataModalEdit({
        ...dataModalEdit,
        oldLandKangPaymentTerm: Currency(loanTermKhangLand, { precision: 2 }).value,
      })
      form.setFieldsValue(
        {
          oldLandKangPaymentTerm: Currency(loanTermKhangLand, { precision: 1 }).value,
        }
      )
    } else {
      console.log("")
    }
  }

  const loadImage = async () => {
    await axios.post(loadimgland, { landId: customers.data.landId })
      .then(res => {
        if (res.status === 200) {
          setImageBlobzz(res.data)
          console.log("6666", res.data)
        } else if (res.status === 204) {
          // NotiErrorIMG("top")
        }
      }).catch(err => {
        console.log(err)
      })
  }

  const onChangeBack = () => {
    changepage('1')
  }
  const handleSubmit = async () => {
    if (checkButtonLand === 1 && dataModalEdit?.productTypeLand === 2) {
      alert("กรุณากดตรวจสอบ")
    } else {
      dispatch(addCustomer(dataModalEdit))
      await axios.put(editLand, dataModalEdit)
        .then(res => {
          if (res.status === 200) {
            editData("top")
            if (fileListResult1.length === 0 && fileListResult2.length === 0) {
              console.log("ไม่มีรูป")
            } else {
              uploadImage()
            }
            setTimeout(() => {
              onCancel(false)
            }, 1000);

          } else {
          }
        }).catch((err) => console.log(err))
    }
  }

  const uploadImage = async () => {
    // console.log("fileList 1", fileListResult1)
    // console.log("fileList 2", fileListResult2)
    if (fileListResult2?.imageListBook && fileListResult1?.imageList) {
      var test = [...fileListResult1.imageList, ...fileListResult2.imageListBook]
    }
    if (fileListResult1?.imageList && !fileListResult2.imageListBook) {
      var test = [...fileListResult1.imageList]
    }
    if (fileListResult2?.imageListBook && !fileListResult1.imageList) {
      var test = [...fileListResult2.imageListBook]
    }

    const inputTest = { mainImg: { landId: dataModalEdit.landId, landInput: formattedTime, img: test } }
    //console.log("รูป",inputTest)
    setLoading(true)
    const headers = { 'Content-Type': 'application/json' }
    await axios.post(upimgland, inputTest, { headers: headers })
      .then(res => {
        if (res.data) {
          AddImgSucc("top")
          console.log("TY", res.data)
          setLoading(false)
        }
      })
      .catch((err) => console.error('เกิดข้อผิดพลาดในการอัปโหลดรูปภาพ:', err))
    setLoading(false)
  }

  /////////////// ที่อยู่ ของ ที่ดิน //////////////////
  const getProvinces = async () => {
    setLoading(true)
    await axios.get(GetProvince)
      .then((res) => {
        //console.log("Province =", res.data)
        setProvinces(res.data)
        setLoading(false)
      }).catch((err) => {
        console.error(err);
      })
    setLoading(false)
  }

  const PostProvince = async (value) => {
    const pr = { pvcode: value }
    //console.log("pr =", pr)
    setLoading(true)
    await axios.post(PostProvince1, pr)
      .then((res) => {
        //console.log("Province =", res.data)
        //console.log("pvcode =", res.data[0].pvcode)
        setPvcodeS(res.data[0].pvcode)
        //setProvinces(res.data)
        setLoading(false)
      }).catch((err) => {
        console.error(err);
      })
    setLoading(false)
  }

  const GetAmphur = async (value) => {
    const am = { pvcode: value }
    //console.log("am =", am)
    setLoading(true)
    await axios.post(GetAmphur1, am)
      .then((res) => {
        //console.log("Amphur =", res.data)
        setDistricts(res.data)
        setAmcodeS(res.data[0].amcode)
        setLoading(false)
      }).catch((err) => {
        console.error(err);
      })
    setLoading(false)
  }

  const handleProvinceChange = async (value, key) => {
    setCheckButtonLand(1)
    //console.log("Province =", key["key"], value)
    setPvcodeS(key["key"])
    setDataModalEdit({ ...dataModalEdit, province: value });
    form.setFieldsValue(
      {
        numberLand: "",
        numberLandLawang: "",
        rai: "",
        workArea: "",
        squareWaArea: "",
        landPrice: "",
        resultLandPrice: "",
        district: "",
        // productLoanLandType: "",
        // loanAmounttLand: "",
        // loanLandTerm: "",
        // interestRateLand: "",
        // interesttLand: "",
        // monthlyPaymentLand: "",
        // installmentWithInterestLand: "",
      }
    )
    setLoading(true)
    const test = { pvcode: key["key"] }
    await axios.post(GetAmphur1, test)
      .then((res) => {
        setDistricts(res.data)
        setLoading(false)
      }).catch((err) => {
        console.error(err);
      })
    setLoading(false)
  };

  const handleDistrictChange = (value, key) => {
    setCheckButtonLand(1)
    //console.log("VV =", key["key"], value)
    setAmcodeS(key["key"])
    setDataModalEdit({ ...dataModalEdit, district: value });
    form.setFieldsValue(
      {
        numberLand: "",
        numberLandLawang: "",
        rai: "",
        workArea: "",
        squareWaArea: "",
        landPrice: "",
        resultLandPrice: "",
        // productLoanLandType: "",
        // loanAmounttLand: "",
        // loanLandTerm: "",
        // interestRateLand: "",
        // interesttLand: "",
        // monthlyPaymentLand: "",
        // installmentWithInterestLand: "",
      }
    )

  };

  const handleNumberLand = (e) => {
    setCheckButtonLand(1)
    setDataModalEdit({ ...dataModalEdit, numberLand: e.target.value })
  };
  ///////////////// กรณี นส.3 ///////////////////////////

  const handleProvinceChange1 = async (value, key) => {
    //console.log("Province =", key["key"], value)
    setPvcodeS(key["key"])
    setDataModalEdit({ ...dataModalEdit, province: value });
    form.setFieldsValue(
      {
        numberLand: "",
        numberLandLawang: "",
        rai: "",
        workArea: "",
        squareWaArea: "",
        district: "",
      }
    )
    setLoading(true)
    const test = { pvcode: key["key"] }
    await axios.post(GetAmphur1, test)
      .then((res) => {
        setDistricts(res.data)
        setLoading(false)
      }).catch((err) => {
        console.error(err);
      })
    setLoading(false)
  };

  const handleDistrictChange2 = (value, key) => {
    //console.log("VV =", key["key"], value)
    setAmcodeS(key["key"])
    setDataModalEdit({ ...dataModalEdit, district: value });
    form.setFieldsValue(
      {
        numberLand: "",
        numberLandLawang: "",
        rai: "",
        workArea: "",
        squareWaArea: "",
      }
    )

  };
  /////////////////// เลือก 1 = นส.3 กับ 2 = นส.4 //////////////////////
  const handleProductLand = (value) => {
    console.log("landType =", value)
    if (value === 1) {
      setDataModalEdit({
        ...dataModalEdit,
        productTypeLand: value,
        numberLand: "-",
        numberLandLawang: "-",
        rai: 0,
        workArea: 0,
        squareWaArea: 0,
        landPrice: 0,
        resultLandPrice: 0,
        landPriceStatus: false,
        subdistrict: null,
      })
      setFileListResult2([]);
      setFileListResult1([]);
      form.setFieldsValue(
        {
          numberLand: "",
          numberLandLawang: "",
          rai: "",
          workArea: "",
          squareWaArea: "",
          landPrice: "",
          resultLandPrice: "",
          province: "",
          district: "",
        }
      )
    } else {
      setDataModalEdit({
        ...dataModalEdit,
        productTypeLand: value,
        numberLand: "-",
        numberLandLawang: "-",
        rai: 0,
        workArea: 0,
        squareWaArea: 0,
        landPrice: 0,
        resultLandPrice: 0,
        landPriceStatus: false,
      })
      setFileListResult2([]);
      setFileListResult1([]);
      form.setFieldsValue(
        {
          numberLand: "",
          numberLandLawang: "",
          rai: "",
          workArea: "",
          squareWaArea: "",
          landPrice: "",
          resultLandPrice: "",
          province: "",
          district: "",
        }
      )
    }
  }

  ////////////// ที่ดิน /////////////////////
  const handleChangeLandType = (value) => {
    if (value === 'ย้ายไฟแนนซ์' || value === 'Pre-Aaprove-ย้ายไฟแนนซ์') {
      setDataModalEdit({ ...dataModalEdit, productLoanLandType: value, proposalBy: user, productType: "ที่ดิน" })
    }
    else {
      setDataModalEdit({ ...dataModalEdit, productLoanLandType: value, proposalBy: user, productType: "ที่ดิน" })
      form.setFieldsValue(
        {
          loanAmounttLand: '',
          loanLandTerm: '',
          interestRateLand: '',
          interesttLand: '',
          monthlyPaymentLand: '',
          installmentWithInterestLand: '',
          oldLandLoanAmount: '',
          oldLandMonthlyPayment: '',
          oldLandLoanTerm: '',
          oldLandKangPayment: '',
          oldLandKangPaymentTerm: '',
        }
      )
    }
  }

  const errorLand = () => {
    setDataModalEdit({
      ...dataModalEdit,
      numberLandLawang: null,
      rai: null,
      workArea: null,
      squareWaArea: null,
      landPrice: 0,
      resultLandPrice: 0,
      landPriceStatus: false
    });
    form.setFieldsValue(
      {
        numberLandLawang: "",
        rai: "",
        workArea: "",
        squareWaArea: "",
        landPrice: 0,
        resultLandPrice: 0,
      }
    )
    Modal.error({
      title: 'ไม่พบข้อมูล',
      content: 'กรุณาตรวจสอบข้อมูลที่กรอกอีกครั้ง',
    })
  }

  const handleCheckLand = async () => {
    setCheckButtonLand(0)
    console.log("pvcodeS =", pvcodeS)
    console.log("amcodeS =", amcodeS)
    console.log("dataPostLand.numberLand =", dataModalEdit.numberLand)
    //const tokens = "eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6IjEiLCJyZWdpc3Rlcl90eXBlX3NlcSI6IjgiLCJ1c2VyX2dyb3VwX2RhdGFfbGlzdCI6IjEsMSwwLDAsMCwwLDAsMSIsInBlcnNvbmFsX2lkIjoiIiwianRpIjoiOTllYmE2OTAtMGFmOC00M2NhLTg2OTktYWUwZDI4N2ZjY2EzIiwiaWF0IjoiMDkvMjcvMjAyMyAwMzoyMDoxNSIsIm5iZiI6MTY5NTc4NDgxNSwiZXhwIjoxNjk1ODcxMjE1LCJpc3MiOiJodHRwczovL2xvY2FsaG9zdDozMDAwMS8iLCJhdWQiOiJMYW5kc01hcHMifQ.Lw3h4tjSbzjscWqkuA9FutwzXPY8UEb49D4ZdsdqKRfR0fwFunY7i4pg5Rdc70Vrw8jDR1qhtQLn5SEqxPguoQ"
    var provids = pvcodeS
    var amphurs = amcodeS
    var landNos = dataModalEdit.numberLand
    var result = { pvcode: provids, amcode: amphurs, landNo: landNos }
    //console.log("result =", result)
    const headers = {
      "Content-Type": "application/json",
      // "Authorization": `Bearer ${tokens}`,
    }
    setLoading(true)
    await axios.post(AllData, result, { headers: headers })
      .then((response) => {
        if (response.data) {
          // console.log("DATA =", response.data.result)
          // console.log("ไร่ =", parseInt(response.data.result[0].rai))
          // console.log("งาน =", parseInt(response.data.result[0].ngan))
          // console.log("ตารางวา =", parseInt(response.data.result[0].wa + "." + response.data.result[0].subwa))
          // console.log("จังหวัด =", response.data.result[0].provname)
          // console.log("อำเภอ =", response.data.result[0].amphurname)
          // console.log("ละติจูด =", response.data.result[0].parcellat)
          // console.log("ลองติจูด =", response.data.result[0].parcellon)
          // console.log("เลขระวาง =", response.data.result[0].utm)
          // console.log("ราคาต่อตารางวา =", parseInt(response.data.result[0].landprice.replace(/,/g, "")))
          const squareWaArea = parseFloat(response.data.result[0].wa + "." + response.data.result[0].subwa)
          const landPricePerWa = parseInt(response.data.result[0].landprice.replace(/,/g, ""))
          const totalWa = (
            ((parseInt(response.data?.result[0].rai) * 400)) +
            ((parseInt(response.data?.result[0].ngan) * 100)) +
            parseFloat(response.data?.result[0].wa + "." + response.data?.result[0].subwa)
          )
          const totalPrice = Currency(totalWa * landPricePerWa).value
          //console.log("รวมตารางวาทั้งหมด =", totalPrice.toFixed(1));
          if (landPricePerWa > 0) {
            setDataModalEdit({
              ...dataModalEdit,
              subdistrict: response.data?.result[0].tumbolname,
              parcellat: response.data?.result[0].parcellat,
              parcellon: response.data?.result[0].parcellon,
              numberLandLawang: response.data?.result[0].utm,
              rai: parseInt(response.data?.result[0].rai),
              workArea: parseInt(response.data?.result[0].ngan),
              squareWaArea: parseFloat(squareWaArea),
              landPrice: parseInt(response.data.result[0].landprice.replace(/,/g, "")) || 0,
              resultLandPrice: totalPrice || 0,
              landPriceStatus: true
            });
          } else {
            setDataModalEdit({
              ...dataModalEdit,
              subdistrict: response.data?.result[0].tumbolname,
              parcellat: response.data?.result[0].parcellat,
              parcellon: response.data?.result[0].parcellon,
              numberLandLawang: response.data?.result[0].utm,
              rai: parseInt(response.data?.result[0].rai),
              workArea: parseInt(response.data?.result[0].ngan),
              squareWaArea: parseFloat(squareWaArea),
              landPrice: parseInt(response.data.result[0].landprice.replace(/,/g, "")) || 0,
              resultLandPrice: totalPrice || 0,
              landPriceStatus: false
            });
          }
          form.setFieldsValue(
            {
              numberLandLawang: response.data?.result[0].utm,
              rai: parseInt(response.data?.result[0].rai),
              workArea: parseInt(response.data?.result[0].ngan),
              squareWaArea: squareWaArea,
              landPrice: currencyFormatOne(parseInt(response.data.result[0].landprice.replace(/,/g, ""))) || 0,
              resultLandPrice: currencyFormatOne(totalPrice) || 0,
            }
          )
          setLoading(false)
        } else {
          console.log("DATA =", response.data)
        }
      })
      .catch((err) => {
        setDataModalEdit({
          ...dataModalEdit,
          parcellat: "-",
          parcellon: "-",
          numberLandLawang: "-",
          rai: 0,
          workArea: 0,
          squareWaArea: 0,
          landPrice: 0,
          resultLandPrice: 0,
          landPriceStatus: false
        });
        errorLand()
      })
      .finally(() => {
        setLoading(false);
      })
    setLoading(false)
  }

  const land_Calculate = () => {

    if (dataModalEdit?.loanAmounttLand && dataModalEdit?.interestRateLand && dataModalEdit?.loanLandTerm) {
      var interestRateLand = dataModalEdit.interestRateLand / 100 // อัตราดอก / 100
      var rate = ((dataModalEdit.loanAmounttLand * Currency(interestRateLand, { precision: 5 }).value) * dataModalEdit.loanLandTerm) // (ยอดจัด * อัตราดอกที่หาร100แล้ว ,precision: 3 คือทศนิยม 3 )*งวด
      var loanTerm = Math.ceil(Currency((rate + dataModalEdit.loanAmounttLand) / dataModalEdit.loanLandTerm, { precision: 2 }).value) // (ดอกเบี้ยทั้งหมด + ยอดจัด)/ งวด
      var resultRate = (loanTerm * dataModalEdit.loanLandTerm) - dataModalEdit.loanAmounttLand
      var resultPrice = loanTerm * dataModalEdit.loanLandTerm
      setDataModalEdit({
        ...dataModalEdit,
        //loanLandTerm: parseInt(event.target.value), //งวด
        monthlyPaymentLand: loanTerm,
        interesttLand: resultRate,
        installmentWithInterestLand: resultPrice
      })
      if (dataModalEdit.loanAmounttLand >= 50000 && dataModalEdit.loanAmounttLand <= 99999) {
        var feeLoan7 = dataModalEdit.loanAmounttLand * 0.07 // 7%
        //console.log("7%")
        if (feeLoan7 <= 5000) {
          form.setFieldsValue(
            {
              fee: Currency(feeLoan7, { precision: 2 }).value,
              monthlyPaymentLand: loanTerm,
              interesttLand: resultRate,
              installmentWithInterestLand: resultPrice
            }
          )
        } else {
          form.setFieldsValue(
            {
              fee: 5000,
              monthlyPaymentLand: loanTerm,
              interesttLand: resultRate,
              installmentWithInterestLand: resultPrice
            }
          )
        }
      }
      if (dataModalEdit.loanAmounttLand >= 100000 && dataModalEdit.loanAmounttLand <= 999999) {
        var feeLoan5 = dataModalEdit.loanAmounttLand * 0.05 // 5%
        //console.log("5%")
        form.setFieldsValue(
          {
            fee: Currency(feeLoan5, { precision: 2 }).value,
            monthlyPaymentLand: loanTerm,
            interesttLand: resultRate,
            installmentWithInterestLand: resultPrice
          }
        )
      }
      if (dataModalEdit.loanAmounttLand >= 1000000) {
        //console.log("3%")
        var feeLoan3 = dataModalEdit.loanAmounttLand * 0.03 // 3%
        form.setFieldsValue(
          {
            fee: Currency(feeLoan3, { precision: 2 }).value,
            monthlyPaymentLand: loanTerm,
            interesttLand: resultRate,
            installmentWithInterestLand: resultPrice
          }
        )
      }
      // form.setFieldsValue(
      //   {
      //     monthlyPaymentLand: loanTerm,
      //     interesttLand: resultRate,
      //     installmentWithInterestLand: resultPrice
      //   }
      // )
    }
    else {
      form.setFieldsValue(
        {
          fee: 0,
          monthlyPaymentLand: 0,
          interesttLand: 0,
          installmentWithInterestLand: 0
        }
      )
    }
  }

  const successImg = () => {
    Modal.success({
      title: 'success',
      content: 'ลบรูปภาพสำเร็จ',
    })
  }
  const errorImg = () => {
    Modal.error({
      title: 'error',
      content: 'เกิดข้อผิดพลาดในการลบรูปภาพ',
    })
  }
  const showConfirm = (e, index) => {
    confirm({
      title: 'คุณต้องการที่จะลบรูปภาพนี้ใช่หรือไม่...?',
      onOk() {
        // message.success('ลบรูปภาพสำเร็จ');
        handleDeleteImage(e, index)
      },
      onCancel() {
        // message.error('ยกเลิกการลบรูปภาพ');
      },
    });
  };

  const handleDeleteImage = async (e, index) => {
    const id = e.idImage
    const path = e.pathImage
    // console.log("idImage =", id)
    // console.log("pathImage =", path)
    setLoading(true)
    await axios.delete(`${deleteNewUpLand}/${id}/${path}`)
      .then(res => {
        if (res.data) {
          console.log("WP", res.data)
          const updatedImageBlobzz = [...imageBlobzz];
          updatedImageBlobzz.splice(index, 1);
          setImageBlobzz(updatedImageBlobzz);
          // successImg()
          DeleteImgSucc("top")
          setLoading(false)
        }
      })
      .catch((err) =>
        // errorImg()
        DeleteImgError("top"))
    setLoading(false)
  }

  const currencyFormatOne = (amount) => {
    return Number(amount)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, '$&,')
  }

  return (
    <Row justify={'center'}>
      <Card style={{ width: '100%' }}>
        <Spin spinning={loading} size='large' tip=" Loading... ">
          <Row style={{ textAlign: 'center' }} >
            <Col span={24} style={{ fontSize: '25px' }} >รายละเอียด</Col>
          </Row>
          <Divider style={{ margin: 5 }} />
          <Form
            labelCol={{
              span: 10,
            }}
            wrapperCol={{
              span: 24,
            }}
            style={{
              width: '100%',
            }}
            form={form}
            onFinish={handleSubmit}
            autoComplete="off"
            initialValues={{ remember: true }}>
            <Row className='inputaside' gutter={18} justify={'center'}>
              <div style={{ margin: 5 }}>
                <Form.Item label='กลุ่มสินค้า' name='productTypeId'>
                  <Select placeholder={'ที่ดิน'} disabled style={{ height: '40px', color: 'black' }}>
                  </Select>
                </Form.Item>
                <Form.Item label='ประเภทเอกสาร' name='productTypeLand'
                  rules={[
                    {
                      required: true,
                      message: 'Please input !'
                    },]}>
                  <Select placeholder={'เลือก'}
                    style={{ height: '40px' }}
                    onChange={(value) => handleProductLand(value)}
                  >
                    <Option value={1}>นส.3 ก.</Option>
                    <Option value={2}>นส.4 จ.</Option>
                  </Select>
                </Form.Item>

                {dataModalEdit && dataModalEdit?.productTypeLand === 1 && (
                  <>
                    <Form.Item name='province' label='จังหวัด'
                      rules={[
                        {
                          required: true,
                          message: 'Please input !'
                        },]}>
                      <Select
                        style={{ height: '40px' }}
                        showSearch
                        loading={loading}
                        name='province'
                        placeholder="จังหวัด"
                        onChange={handleProvinceChange1}
                      >
                        {provinces?.result?.map((pro, index) => (
                          <Option key={pro.pvcode} value={pro.pvnamethai}>
                            {pro.pvnamethai}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>

                    <Form.Item name='district' label='อำเภอ'
                      rules={[
                        {
                          required: true,
                          message: 'Please input !'
                        },]}>
                      <Select
                        style={{ height: '40px' }}
                        showSearch
                        loading={loading}
                        name='district'
                        placeholder="อำเภอ"
                        onChange={handleDistrictChange2}
                      >
                        {district?.map((dis, index) => (
                          <Option key={dis.amcode} value={dis.amnamethai}>
                            {dis.amnamethai}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>

                    <Form.Item label='เลขที่โฉนด/เลขที่' name='numberLand'
                      rules={[
                        {
                          required: true,
                          message: 'Please input !'
                        },]}>
                      <Input type='text' style={{ height: '40px' }}
                        onChange={(e) => setDataModalEdit({ ...dataModalEdit, numberLand: e.target.value })}></Input>
                    </Form.Item>

                    <Form.Item label='พื้นที่ไร่' name='rai'
                      rules={[
                        {
                          required: true,
                          message: 'Please input !'
                        },]}>
                      <Input type='number' suffix="ไร่"
                        onChange={(e) => setDataModalEdit({ ...dataModalEdit, rai: parseInt(e.target.value) })}></Input>
                    </Form.Item>

                    <Form.Item label='พื้นที่งาน' name='workArea'
                      rules={[
                        {
                          required: true,
                          message: 'Please input !'
                        },]}>
                      <Input type='number' suffix="งาน"
                        onChange={(e) => setDataModalEdit({ ...dataModalEdit, workArea: parseInt(e.target.value) })}></Input>
                    </Form.Item>

                    <Form.Item label='พื้นที่ตารางวา' name='squareWaArea'
                      rules={[
                        {
                          required: true,
                          message: 'Please input !'
                        },]}>
                      <Input type='number' suffix="ตารางวา"
                        onChange={(e) => setDataModalEdit({ ...dataModalEdit, squareWaArea: parseInt(e.target.value) })}></Input>
                    </Form.Item>

                  </>
                )}
                {dataModalEdit && dataModalEdit?.productTypeLand === 2 && (
                  <>
                    <Form.Item name='province' label='จังหวัด'
                      rules={[
                        {
                          required: true,
                          message: 'Please input !'
                        },]}>
                      <Select
                        style={{ height: '40px' }}
                        showSearch
                        loading={loading}
                        name='province'
                        placeholder="จังหวัด"
                        onChange={handleProvinceChange}
                      >
                        {provinces?.result?.map((pro, index) => (
                          <Option key={pro.pvcode} value={pro.pvnamethai}>
                            {pro.pvnamethai}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>

                    <Form.Item name='district' label='อำเภอ'
                      rules={[
                        {
                          required: true,
                          message: 'Please input !'
                        },]}>
                      <Select
                        style={{ height: '40px' }}
                        showSearch
                        loading={loading}
                        name='district'
                        placeholder="อำเภอ"
                        onChange={handleDistrictChange}
                      >
                        {district?.map((dis, index) => (
                          <Option key={dis.amcode} value={dis.amnamethai}>
                            {dis.amnamethai}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>

                    <Form.Item label='เลขโฉนดที่ดิน' name='numberLand'
                      rules={[
                        {
                          required: true,
                          message: 'Please input !'
                        },]}>
                      <Input type='text'
                        //onChange={(e) => setDataModalEdit({ ...dataModalEdit, numberLand: e.target.value })}></Input>
                        onChange={(e) => handleNumberLand(e)}></Input>
                    </Form.Item>

                    <Form.Item label='ตรวจสอบ'>
                      <Button type="primary" onClick={handleCheckLand}>ตรวจสอบ</Button>
                    </Form.Item>

                    <Form.Item label='ระวาง' name='numberLandLawang'>
                      <Input type='text' disabled style={{ color: 'black' }}
                        onChange={(e) => setDataModalEdit({ ...dataModalEdit, numberLandLawang: e.target.value })}></Input>
                    </Form.Item>

                    <Form.Item label='พื้นที่ไร่' name='rai'>
                      <Input type='text' suffix="ไร่" disabled style={{ color: 'black' }}
                        onChange={(e) => setDataModalEdit({ ...dataModalEdit, rai: parseInt(e.target.value) })}></Input>
                    </Form.Item>

                    <Form.Item label='พื้นที่งาน' name='workArea'>
                      <Input type='text' suffix="งาน" disabled style={{ color: 'black' }}
                        onChange={(e) => setDataModalEdit({ ...dataModalEdit, workArea: parseInt(e.target.value) })}></Input>
                    </Form.Item>

                    <Form.Item label='พื้นที่ตารางวา' name='squareWaArea'>
                      <Input type='text' suffix="ตารางวา" disabled style={{ color: 'black' }}
                        onChange={(e) => setDataModalEdit({ ...dataModalEdit, squareWaArea: parseInt(e.target.value) })}></Input>
                    </Form.Item>

                    <Form.Item label='ราคาประเมินที่ดิน' name='landPrice'>
                      <Input type='text' suffix="บาทต่อตารางวา" disabled style={{ color: 'black' }}
                        onChange={(e) => setDataModalEdit({ ...dataModalEdit, landPrice: parseInt(e.target.value) })}></Input>
                    </Form.Item>

                    <Form.Item label='ราคารวม' name='resultLandPrice'>
                      <Input type='text' suffix="บาท" disabled style={{ color: 'black' }}
                        onChange={(e) => setDataModalEdit({ ...dataModalEdit, resultLandPrice: parseInt(e.target.value) })}></Input>
                    </Form.Item>

                  </>
                )}
              </div>

              <div style={{ margin: 5 }}>
                <Form.Item label='ประเภทขอกู้ที่ดิน' name='productLoanLandType'
                  rules={[
                    {
                      required: true,
                      message: 'Please input !',
                    },]}>
                  <Select
                    style={{ height: '40px' }}
                    disabled={!sell}
                    placeholder={'เลือก'}
                    onChange={(value) => { handleChangeLandType(value) }}
                    options={productLoanTypeLand}
                  />
                </Form.Item>

                {sell === true ?
                  <>
                    <Form.Item label='ยอดจัด' name='loanAmounttLand'
                      rules={[
                        {
                          required: true,
                          message: 'Please input !'
                        },]}>
                      {/* <Input
                    type='number' suffix="บาท"
                    onChange={(e) => setDataModalEdit({ ...dataModalEdit, loanAmounttLand: parseInt(e.target.value) })} /> */}
                      <InputNumber
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        size="large"
                        style={{ width: '100%', color: 'black', height: '40px' }}
                        onChange={(value) => setDataModalEdit({ ...dataModalEdit, loanAmounttLand: parseInt(value) })}
                      />
                    </Form.Item>

                    <Form.Item label='อัตราดอกเบี้ย' name='interestRateLand'
                      rules={[
                        {
                          required: true,
                          message: 'Please input !'
                        },]}>
                      <Input type='number' suffix="% +VAT" disabled
                        onChange={(e) => setDataModalEdit({ ...dataModalEdit, interestRateLand: parseFloat(e.target.value) })} />
                    </Form.Item>


                    <Form.Item label='ค่าธรรมเนียม' name='fee'>
                      <Input type='number' disabled suffix="บาท" style={{ color: 'black' }}
                        onChange={(e) => setMoneyLand({ ...moneyLand, fee: parseFloat(e.target.value) })} />
                    </Form.Item>

                    <Form.Item label='จำนวนงวด' name='loanLandTerm'
                      rules={[
                        {
                          required: true,
                          message: 'Please input !'
                        },]}>
                      <Input type='number'
                        onChange={(e) => setDataModalEdit({ ...dataModalEdit, loanLandTerm: parseInt(e.target.value) })} />
                    </Form.Item>

                    <Form.Item label='งวดละ' name='monthlyPaymentLand'
                      rules={[
                        {
                          required: true,
                          message: 'Please input !'
                        },]}>
                      <Input type='number' suffix="บาท" disabled style={{ color: 'black' }}
                        onChange={(e) => setDataModalEdit({ ...dataModalEdit, monthlyPaymentLand: parseInt(e.target.value) })} />
                    </Form.Item>

                    <Form.Item label='ดอกเบี้ย' name='interesttLand'
                      rules={[
                        {
                          required: true,
                          message: 'Please input !'
                        },]}>
                      <Input type='number' suffix="บาท" disabled style={{ color: 'black' }}
                        onChange={(e) => setDataModalEdit({ ...dataModalEdit, interesttLand: parseInt(e.target.value) })} />
                    </Form.Item>


                    <Form.Item label='รวมราคา' name='installmentWithInterestLand'>
                      <Input disabled style={{ color: 'black' }} suffix="บาท" />
                    </Form.Item>

                    {(dataModalEdit && dataModalEdit.productLoanLandType === 'ย้ายไฟแนนซ์') || (dataModalEdit && dataModalEdit.productLoanLandType === 'Pre-Aaprove-ย้ายไฟแนนซ์') ? (
                      <>
                        <Form.Item label='ย้ายไฟแนนซ์จาก' name='issuno'
                          rules={[
                            {
                              required: true,
                              message: 'Please input !'
                            },]}>
                          <Input type='text'
                            style={{ color: 'black' }}
                            onChange={(e) => setDataModalEdit({ ...dataModalEdit, issuno: e.target.value })} />
                        </Form.Item>
                        <Form.Item label='ยอดปิดไฟแนนซ์ที่ดิน' name='oldLandLoanAmount'
                          rules={[
                            {
                              required: true,
                              message: 'Please input !'
                            },]}>
                          {/* <Input type='number' suffix="บาท"
                        onChange={(e) => setDataModalEdit({ ...dataModalEdit, oldLandLoanAmount: parseInt(e.target.value) })} /> */}
                          <InputNumber
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            size="large"
                            style={{ width: '100%', color: 'black' }}
                            onChange={(value) => setDataModalEdit({ ...dataModalEdit, oldLandLoanAmount: parseInt(value) })}
                          />
                        </Form.Item>

                        <Form.Item label='ค่างวด/เดือน' name='oldLandMonthlyPayment'
                          rules={[
                            {
                              required: true,
                              message: 'Please input !'
                            },]}>
                          {/* <Input type='number' suffix="บาท"
                        onChange={(e) => setDataModalEdit({ ...dataModalEdit, oldLandMonthlyPayment: parseInt(e.target.value) })} /> */}
                          <InputNumber
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            size="large"
                            style={{ width: '100%', color: 'black' }}
                            onChange={(value) => setDataModalEdit({ ...dataModalEdit, oldLandMonthlyPayment: parseInt(value) })}
                          />
                        </Form.Item>

                        <Form.Item label='จำนวนผ่อนกี่งวด' name='oldLandLoanTerm'
                          rules={[
                            {
                              required: true,
                              message: 'Please input !'
                            },]}>
                          {/* <Input type='number' suffix="งวด"
                        onChange={(e) => setDataModalEdit({ ...dataModalEdit, oldLandLoanTerm: parseInt(e.target.value) })} /> */}
                          <InputNumber
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            size="large"
                            style={{ width: '100%', color: 'black' }}
                            onChange={(value) => setDataModalEdit({ ...dataModalEdit, oldLandLoanTerm: parseInt(value) })}
                          />
                        </Form.Item>

                      </>
                    ) : null}
                  </>
                  : null}
                <Form.Item label='ผู้เสนอเคส' name='proposalBy' style={{ textAlign: 'center' }} >
                  <Input disabled style={{ color: 'black', width: '90%' }} />
                </Form.Item>
              </div>
            </Row>

            <Row justify={'center'}>
              <Col span={22}>
                <span><b style={{ fontSize: '25' }}>สตอรี่รายละเอียดที่ดิน</b></span> <p />
                <Form.Item name="storyLand" label="">
                  <TextArea
                    name='storyLand'
                    style={{ color: 'blue' }}
                    rows={8}
                    onChange={(e) => setDataModalEdit({ ...dataModalEdit, storyLand: e.target.value.trim() })}
                  >
                  </TextArea>
                </Form.Item>
              </Col>
            </Row>
            <Divider />
            <Divider />
            <Row justify={'center'}>
              <Space>
                <Button type="primary" onClick={onChangeBack} style={{ background: '#bfbfbf' }}>ย้อนกลับ</Button>
                <Button type="primary" htmlType="submit" style={{ background: "green" }}>บันทึก</Button>
                <Button type="primary" onClick={onCancel} style={{ background: "red" }}>ยกเลิก</Button>
              </Space>
            </Row>
          </Form>
        </Spin>
      </Card>
    </Row>
  )
}
