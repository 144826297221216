import React, { useState, useEffect } from 'react'
import { Button, Form, Input, Select, Divider, Row, Col, Modal, Checkbox, Space } from 'antd';
import { Option } from 'antd/es/mentions';
import axios from 'axios';
import { getdistrict, getsubdistrict, getzipcodes, loadProvinces,getprovinceall, getdistrictall, getsubdistrictsall } from '../../../../../../file_mid/all_api';

function EditAddress({ open, close, dataindex, shootdata, fucnEdit }) {

  const [form] = Form.useForm();
  const [getData, setGetData] = useState({ ...shootdata.dataedit });
  const [getIndex, setGetIndex] = useState(dataindex);

  const [pv] = useState(getData.province);
  const [dt] = useState(getData.district);
  const [sdt] = useState(getData.subdistrict);
  const [provinces, setProvinces] = useState([]);
  const [district, setDistricts] = useState([]);
  const [subdistricts, setSubdistricts] = useState([]);
  const [zipcode, setZipCodes] = useState([]);


  useEffect(() => {
    setGetData({ ...shootdata.dataedit })
    setGetIndex(dataindex)
    if (getData !== undefined) {
      form.setFieldsValue({
        ...getData
      })
    }

    fetchProvinceByName()
    fetchDistricteByName()
    fetchSubDistricteByName()
    fetchAllProvinces()
  }, [])

  const fetchAllProvinces = async () => {
    await axios.get(loadProvinces)
      .then(response => {
        setProvinces(response.data);
      }).catch(error => {
        console.error(error);
      });
  }
  const fetchProvinceByName = async () => {
    await axios.get(getprovinceall + pv)
      .then(response => {
        fetchDistrictFromProvinceId({ provinceId: response.data.provinceId })
      }).catch(error => {
        console.error(error);
      });
  }
  const fetchDistrictFromProvinceId = async ({ provinceId }) => {
    axios.get(getdistrict + provinceId)
      .then(response => {
        // console.log("DISTRICT", response.data)
        setDistricts(response.data);
      }).catch(error => {
        console.error(error);
      });
  }
  const fetchDistricteByName = async () => {
    await axios.get(getdistrictall + dt)
      .then(response => {
        fetchSubDistrictFromDistrictId({ districtId: response.data.districtId })
      }).catch(error => {
        console.error(error);
      });
  }
  const fetchSubDistrictFromDistrictId = async ({ districtId }) => {
    // console.log(districtId)
    axios.get(getsubdistrict + districtId)
      .then(response => {
        setSubdistricts(response.data);
        // console.log("SUBDISTRICT", response.data)
      }).catch(error => {
        console.error(error);
      });
  }
  const fetchSubDistricteByName = async () => {
    await axios.get(getsubdistrictsall + sdt)
      .then(response => {
        fetchZipcodeFromSubdistrictId({ subdistrictId: response.data.subdistrictId })
        // console.log("zipcode", response.data)
      }).catch(error => {
        console.error(error);
      });
  }
  const fetchZipcodeFromSubdistrictId = async ({ subdistrictId }) => {
    // console.log(subdistrictId)
    await axios.get(getzipcodes + subdistrictId)
      .then(response => {
        // fetchZipcode({ zipcode: response.data.zipcode })
      }).catch(error => {
        console.error(error);
      });
  }
  const handleProvinceChange = (value, element) => {
    form.setFieldsValue({
      district: '',
      subdistrict: '',
      zipCode: '',
    })
    setGetData({ ...getData, province: value, });
    axios.get(getdistrict + element.key)
      .then(response => {
        // console.log(response.data)
        setDistricts(response.data);
      }).catch(error => {
        console.error(error);
      });
  };
  const handleDistrictChange = (value, key) => {
    form.setFieldsValue({
      subdistrict: '',
      zipCode: '',
    })
    setGetData({ ...getData, district: value });
    axios.get(getsubdistrict + key.key)
      .then(response => {
        setSubdistricts(response.data);
        setZipCodes(response.data);
      }).catch(error => {
        console.error(error);
      });
  };
  const handleSubdistrictChange = (value, key) => {
    form.setFieldsValue({
      zipCode: '',
    })
    setGetData({ ...getData, subdistrict: value });
    axios.get(getzipcodes + key.key)
      .then(response => {
        // console.log("zipcodes", response.data);
        setZipCodes(response.data);
      }).catch(error => {
        console.error(error);
      });
  };
  const handleZipcodeChange = (value) => {
    setGetData({ ...getData, zipCode: value });
  };

  //elements
  const handleCancel = () => { //กดปิด Modal
    close(false);
  };

  //handleSubmit
  const handleSubmit = async () => {
    // console.log("submit", getData)
    fucnEdit({ getData, getIndex })
    close(false);
  }
  // data input Form
  const handleInputDataAddress = (e) => { //กรอกข้อมูล
    const { name, value } = e.target;
    setGetData({
      ...getData, [name]: value,
      // เซตข้อมูลใหม่เข้าช่อง Input
    }) // เป็นการกระจายข้อมูลเข้าไปตามชื่อ name 
    // console.log("editData New", editdata)
  }

  return (
    <>
      {/* Modal แก้ไข้ */}
      <Modal
        open={open}
        onCancel={handleCancel}
        // style={{ background: '#2f54eb' }}
        centered
        width={600}
        footer={[null]}>
        <Form
          form={form}
          name='editdata'
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 24,
          }}
          style={{
            maxWidth: '100%',
          }}
          onFinish={handleSubmit}>
          <Row>
            <Col span={24} style={{ fontSize: '30px', textAlign: 'center' }} ><u>แก้ไขที่อยู่</u></Col>
          </Row>
          <Divider />
          <Row>
            <Col className='gutter-row' span={24}>
              <Form.Item name='houseRegistrationNumber' label="เลขทะเบียนบ้าน"
              // rules={[
              //   {
              //     required: true,
              //     message: 'Please input !',
              //   },]}
              >
                {/* input Form เลขบัตรประจำตัวประชาชน*/}
                <Input
                  name='houseRegistrationNumber'
                  placeholder='เลขทะเบียนบ้าน'
                  onChange={handleInputDataAddress}
                  defaultValue={getData.houseRegistrationNumber}
                />
              </Form.Item>
              <Form.Item name='houseNumber' label='บ้านเลขที่'
                rules={[
                  {
                    required: true,
                    message: 'Please input !',
                  },]}
              >
                <Input
                  name='houseNumber'
                  placeholder="บ้านเลขที่"
                  defaultValue={getData.houseNumber}
                  onChange={handleInputDataAddress}
                />
              </Form.Item>
              <Form.Item name='soi' label='ซอย'
              // rules={[
              //   {
              //     required: true,
              //     message: 'Please input !',
              //   },]}
              >
                <Input
                  name='soi'
                  placeholder='ซอย'
                  defaultValue={getData.soi}
                  onChange={handleInputDataAddress}
                />
              </Form.Item>
              <Form.Item name='road' label='ถนน'
              // rules={[
              //   {
              //     required: true,
              //     message: 'Please input !',
              //   },]}
              >
                <Input
                  name='road'
                  placeholder='ถนน'
                  defaultValue={getData.road}
                  onChange={handleInputDataAddress}
                />
              </Form.Item>
              <Form.Item name='village' label='หมู่บ้าน'
              // rules={[
              //   {
              //     required: true,
              //     message: 'Please input !',
              //   },]}
              >
                <Input
                  name='village'
                  placeholder="หมู่บ้าน"
                  defaultValue={getData.village}
                  onChange={handleInputDataAddress}
                />
              </Form.Item>
              <Form.Item
                name='province'
                label='จังหวัด'
                rules={[
                  {
                    required: true,
                    message: 'Please input !',
                  },]}
              >
                <Select
                  showSearch
                  name='province'
                  placeholder='จังหวัด'
                  // defaultValue={editdata.province}
                  onChange={(key, value) => { handleProvinceChange(key, value) }}
                >
                  {provinces.map((pro, index) => (
                    <Option key={pro.provinceId} value={pro.provinceName}>
                      {pro.provinceName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name='district'
                label='อำเภอ'
                rules={[
                  {
                    required: true,
                    message: 'Please input !',
                  },]}
              >
                <Select
                  showSearch
                  name='district'
                  placeholder="อำเภอ"
                  // defaultValue={editdata.district}
                  onChange={(key, value) => { handleDistrictChange(key, value) }}
                >
                  {district.map((dis, index) => (
                    <Option key={dis.districtId} value={dis.districtName}>
                      {dis.districtName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name='subdistrict'
                label='ตำบล'
                rules={[
                  {
                    required: true,
                    message: 'Please input !',
                  },]}
              >
                <Select
                  showSearch
                  name='subdistrict'
                  placeholder='ตำบล'
                  // defaultValue={editdata.subdistrict}
                  onChange={(key, value) => { handleSubdistrictChange(key, value) }}
                >
                  {subdistricts.map((subdis, index) => (
                    <Option key={subdis.subdistrictId} value={subdis.subdistrictName}>
                      {subdis.subdistrictName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name='zipCode'
                label='รหัสไปรษณีย์'
                rules={[
                  {
                    required: true,
                    message: 'Please input !',
                  },]}
              >
                <Select
                  name='zipCode'
                  placeholder="รหัสไปรษณีย์"
                  // defaultValue={editdata.zipCode}
                  onChange={(key, value) => { handleZipcodeChange(key, value) }}
                >
                  {zipcode.map((zip, index) => (
                    <Option key={zip.zipcodeId} value={zip.zipCode}>
                      {zip.zipCode}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Divider></Divider>
              <Row justify={'center'} >
                <Checkbox
                  key={0}
                  checked={getData.addressOnIdcard}
                  name='addressOnIdcard'
                  defaultValue={getData.addressOnIdcard}
                  // label='ตามบัตรประชาชน'
                  onChange={(e) => {
                    setGetData({ ...getData, addressOnIdcard: e.target.checked });
                  }}>"ตามบัตรประชาชน"</Checkbox>
                <Checkbox
                  key={1}
                  checked={getData.houseRegistration}
                  name='houseRegistration'
                  defaultValue={getData.houseRegistration}
                  // label='ตามทะเบียนบ้าน'
                  onChange={(e) => {
                    setGetData({ ...getData, houseRegistration: e.target.checked });
                  }}>"ตามทะเบียนบ้าน"</Checkbox>
                <Checkbox
                  key={2}
                  checked={getData.workAddress}
                  name='workAddress'
                  defaultValue={getData.workAddress}
                  // label='ตามสถานที่ทำงาน'
                  onChange={(e) => {
                    setGetData({ ...getData, workAddress: e.target.checked });
                  }}>"ตามสถานที่ทำงาน"</Checkbox>
                <Checkbox
                  key={3}
                  checked={getData.otherAddress}
                  name='otherAddress'
                  defaultValue={getData.otherAddress}
                  // label='อื่นๆ'
                  onChange={(e) => {
                    setGetData({ ...getData, otherAddress: e.target.checked });
                  }}>"อื่นๆ"</Checkbox>
                <Checkbox
                  key={4}
                  checked={getData.currentAddress}
                  name='currentAddress'
                  defaultValue={getData.currentAddress}
                  // label='อื่นๆ'
                  onChange={(e) => {
                    setGetData({ ...getData, currentAddress: e.target.checked });
                  }}>"ที่อยู่ปัจจุบัน"</Checkbox>
                <Checkbox
                  key={5}
                  checked={getData.sendDocuments}
                  name='sendDocuments'
                  defaultValue={getData.sendDocuments}
                  // label='อื่นๆ'
                  onChange={(e) => {
                    setGetData({ ...getData, sendDocuments: e.target.checked });
                  }}>"ที่อยู่ส่งเอกสาร"</Checkbox>
                <Checkbox
                  key={6}
                  // checked={getData.addressStatus}
                  checked={getData.addressStatus}
                  name='addressStatus'
                  defaultValue={getData.addressStatus}
                  // label='อื่นๆ'
                  onChange={(e) => {
                    setGetData({ ...getData, addressStatus: e.target.checked });
                  }}>"ที่อยู่ที่ใช้งานหรือไม่"</Checkbox>
              </Row>
              <Divider />
            </Col>
          </Row>
          <Row>
            <Col span={24} style={{ textAlign: 'center' }}>
              <Space>
                <Button type="primary" onClick={handleCancel} style={{ background: "red" }}>ยกเลิก</Button>
                <Button type="primary" htmlType="submit" style={{ background: "green" }} >บันทึก</Button>
                {/* <Button type="primary" htmlType="submit" style={{ width: 70, height: 70, background: "green" }}> SAVE</Button>
                <Button type="primary" onClick={handleCancel} style={{ width: 70, height: 70, background: "red" }}> CLOSE</Button> */}
              </Space>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  )

};

export default EditAddress
