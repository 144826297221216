import React, { useState, useEffect } from "react";
import { PlusCircleFilled, HomeFilled, EditOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Table, Spin, Tag, Space, Card, Radio, Statistic, notification, Typography, Pagination, Divider } from 'antd';

import axios from "axios";
import dayjs from "dayjs";
import ModalEditer from "./modals/ModalEditer";

import { useDispatch } from 'react-redux';
import { addCustomer } from '../../redux/Customer';
import { addCar } from '../../redux/Car';
import '../css/Media.css'

import { addAddress } from '../../redux/Address';
import { addPhone } from '../../redux/Phone';
import { addLoan } from '../../redux/Loan';
import { addOldLoan } from '../../redux/OldLoan';
import { addImage } from '../../redux/Image';
import { addLand } from '../../redux/Land';
import { addLoanLand } from '../../redux/LoanLand';
import { addOldLoanLand } from '../../redux/OldLoanLand';
import { addGuarantor } from '../../redux/Guarantor';
import { addCareerIncome } from '../../redux/CareerIncome';
import { addOccupation } from '../../redux/Occupation';
import { addEmail } from '../../redux/Email';
import { addSocial } from '../../redux/Social';
import MainReEdit from "./modals/MainReEdit";

import { backc, textc, colorApprovalStatus, colorProductLoanType } from "../file_mid/status_color";
import { orderCar } from "../file_mid/all_options";
import ModalAddAaprove from "./modals/ModalAddAaprove";
import ModalInfoMK from "../file_mid/info/ModalInfoMK";
import { useLocation } from 'react-router-dom';
import { checkinfomkcar } from "../file_mid/all_api";
const { Countdown } = Statistic;

export default function Main() {
  const location = useLocation();
  const [form] = Form.useForm();
  const { state } = location;
  const { Text } = Typography;
  const [api, contextHolder] = notification.useNotification();
  const dispatch = useDispatch()
  const user = localStorage.getItem('username');
  const [editData, setEditData] = useState();
  const [isModalEditer, setIsModalEditer] = useState(false);
  const [isModalAddAaprove, setIsModalAddAaprove] = useState(false);
  const [axiosData, setAxiosData] = useState([]);
  const [arrayTable, setArrayTable] = useState();
  const [cData, setCData] = useState([]);
  const [keyWord, setKeyWord] = useState("ทั้งหมด");
  const [modalReEdit, setModalReEdit] = useState(false);
  const [testPage, setTestPage] = useState();
  const [ssPage, setSsPage] = useState({ numberPage: 1, pageSize: 10 });
  const [currentPage, setCurrentPage] = useState(1)
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false)
  const token = localStorage.getItem('token')
  const [isModalInfoMK, setIsModalInfoMK] = useState(false);

  const [navigateData, setNavigateData] = useState([]);
  const [datafrommap, setDataFrommap] = useState({});

  useEffect(() => {
    localStorage.removeItem('addCustomer');
    localStorage.removeItem('addAddress');
    localStorage.removeItem('addPhone');
    localStorage.removeItem('addCar');
    localStorage.removeItem('addGuarantor');
    localStorage.removeItem('addCareerIncome');
    localStorage.removeItem('addLoan');
    localStorage.removeItem('addOldLoan');
    localStorage.removeItem('addLand');
    localStorage.removeItem('addLoanLand');
    localStorage.removeItem('addOldLoanLand');
    localStorage.removeItem('addOccupation');
    localStorage.removeItem('addEmail');
    localStorage.removeItem('addSocial');
    localStorage.removeItem('addImage');
    localStorage.removeItem('addBroker');
    dispatch(addCustomer())
    dispatch(addAddress())
    dispatch(addPhone())
    dispatch(addGuarantor())
    dispatch(addCareerIncome())
    dispatch(addCar())
    dispatch(addLoan())
    dispatch(addOldLoan())
    dispatch(addImage())
    dispatch(addLand())
    dispatch(addLoanLand())
    dispatch(addOldLoanLand())
    dispatch(addOccupation())
    dispatch(addEmail())
    dispatch(addSocial())
  }, [])

  useEffect(() => {
    if (!isModalEditer && !modalReEdit && !isModalAddAaprove && !isModalInfoMK) {
      loadData()
    }
  }, [keyWord, isModalEditer, modalReEdit, isModalAddAaprove, ssPage, isModalInfoMK])


  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      // console.log(query)
      searchLoadData(query)
    }, 1500)
    return () => clearTimeout(delayDebounceFn)
  }, [query])

  useEffect(() => {
    const init = async () => {
      if (state?.firstname) {
        countMap();
      }
    };

    init();
  }, [state, navigateData]);

  const countMap = () => {
    setTimeout(() => {
      if (datafrommap?.approvalStatus === 3 &&
        ["Pre-Aaprove-เปลี่ยนสัญญา",
          "Pre-Aaprove-รี+เปลี่ยนสัญญา",
          "Pre-Aaprove-รี-Ploan",
          "Pre-Aaprove-ปรับโครงสร้าง",
          "Pre-Aaprove-รีโอน",
          "Pre-Aaprove-รี"].includes(datafrommap?.productLoanType)) {

        if (datafrommap?.customerId === state?.customerId && datafrommap?.carId === state?.carId) {
          // console.log("เข้า mapdata");
          setIsModalAddAaprove(true);
          setEditData(datafrommap);
          setQuery(state.firstname);
          form.setFieldsValue({
            search: state.firstname
          });
          return; // หยุด loop
        }
      }
      else {
        if (state?.firstname !== '') {
          setQuery(state.firstname);
        }
        form.setFieldsValue({
          search: state.firstname
        });
      }
      setLoading(false);
    }, 2000); // ทำให้ setLoading(false) หลังจากการประมวลผลข้อมูลเสร็จสิ้น
  };


  const searchLoadData = async (data) => {
    // console.log("searchLoadData data", data)
    if (data !== "") {
      // console.log("OK searchLoadData data")
      await loadData(data)
    }
  }

  const loadCountData = async (data) => {
    if (!data) {
      setCData([])
    } else {
      const ez = data.filter(
        (item) =>
          item.approvalStatusId === keyWord
      );
      setTestPage(...ez)
      setCData(data)
    }
  }


  const loadData = async (data) => {
    setLoading(true)
    const tk = JSON.parse(token)
    const headers = {
      "Authorization": `Bearer ${tk}`,
      "Menu": JSON.stringify("3")
    }
    if (query !== "" && data !== "stop") {
      var mainData = { proposalBy: user, approvalStatus: keyWord, numberPage: ssPage?.numberPage, pageSize: ssPage?.pageSize, keyword: query }
    } else {
      if (data === "stop") {
        var mainData = { proposalBy: user, approvalStatus: "ทั้งหมด", numberPage: ssPage?.numberPage, pageSize: ssPage?.pageSize }
      } else {
        var mainData = { proposalBy: user, approvalStatus: keyWord, numberPage: ssPage?.numberPage, pageSize: ssPage?.pageSize }
      }
    }
    await axios.post(checkinfomkcar, mainData, { headers: headers })
      .then(async (res) => {
        setNavigateData(res?.data?.JoinTableShow)
        let datamaps = []
        res?.data?.JoinTableShow?.map((item) => {
          if (item?.approvalStatus === 3) {
            datamaps.push(item)
          }
        })
        setDataFrommap(...datamaps)
        if (res.data.CountStatus) {
          await loadCountData(res.data.CountStatus)
        }
        if (res.data.JoinTableShow) {
          setArrayTable(res.data.JoinTableShow)
          setAxiosData(res.data.JoinTableShow)
        } else {
          setArrayTable([])
          setAxiosData([])
        }
        setLoading(false)
      }).catch((err) => console.log(err))
    setLoading(false)
  }

  // const search = (data) => {
  //   const greaterThanTen = axiosData.filter(
  //     (item) =>
  //       item.firstName.toLowerCase().includes(data.target.value) ||
  //       item.carPlateNumber.toLowerCase().includes(data.target.value)
  //   );
  //   setArrayTable(greaterThanTen)
  // };

  const search = async (data) => {
    if (data.target.value !== "") {
      setQuery(data.target.value)
    } else {
      setSsPage({ numberPage: 1, pageSize: 10 })
      setCurrentPage(1)
      setKeyWord("ทั้งหมด")
      setQuery("")
    }
  };

  const onChangeKeyWord = (value) => {
    setKeyWord(value)
    setCurrentPage(1)
    setSsPage({ numberPage: 1, pageSize: 10 })
  }

  const onChangePagination = (e, pageSize) => {
    setCurrentPage(e)
    setSsPage({ numberPage: e, pageSize: pageSize })
  }

  const SuccSend = (placement) => {
    api.success({
      message: <b>ส่งเคสใหม่สำเร็จ</b>,
      placement,
    });
  }

  const columns = [
    {
      title: "ลำดับ",
      dataIndex: "index",
      key: 'index',
      align: 'center',
      fixed: true,
      width: "6%",
      render: (text, object, index) => index + 1
    },
    {
      title: "ชื่อ-สกุล",
      dataIndex: "firstName",
      key: 'firstName',
      align: 'left',
      // fixed: true,
      width: "15%",
      sorter: {
        compare: (a, b) => a.firstName.length - b.firstName.length,
        multiple: 1,
      },
      render: (text, record) => (
        <>{record.snam}{record.firstName} {record.lastName}</>
      ),
    },
    {
      title: "เลขทะเบียน",
      dataIndex: "carPlateNumber",
      key: 'carPlateNumber',
      align: 'center',
      width: "8%",
      sorter: {
        compare: (a, b) => a.carPlateNumber.length - b.carPlateNumber.length,
        multiple: 5,
      },
    },
    {
      title: "ยอดกู้",
      dataIndex: "loanAmount",
      align: 'center',
      width: "8%",
      sorter: {
        compare: (a, b) => a.loanAmount - b.loanAmount,
        multiple: 3,
      },
      render: (text, record) => (
        <Space size="middle">
          {
            <Space>
              <>{currencyFormatOne(record.loanAmount)}</>
            </Space>
          }
        </Space>
      ),
    },
    {
      title: "เรท",
      dataIndex: "carPrice",
      align: 'center',
      width: "8%",
      sorter: {
        compare: (a, b) => a.carPrice - b.carPrice,
        multiple: 4,
      },
      render: (text, record) => (
        <Space size="middle">
          {
            <Space>
              <>{currencyFormatOne(record.carPrice)}</>
            </Space>
          }
        </Space>
      ),
    },
    {
      title: "วันที่เสนอ",
      dataIndex: "carInput",
      width: "8%",
      align: 'center',
      sorter: {
        compare: (a, b) => dayjs(a.carInput) - dayjs(b.carInput),
        multiple: 1,
      },
      render: (text, record) => (
        <Space size="middle">
          {
            record.carInput !== "0001-01-01T00:00:00Z" ?
              <Space>
                <>{dayjs(record.carInput).format("DD-MM-YYYY HH:mm")}</>
              </Space>
              : null
          }
        </Space>
      ),
      defaultSortOrder: 'descend',
    },
    {
      title: "วันที่รับ",
      dataIndex: "approvalDate",
      width: "11%",
      align: 'center',
      sorter: {
        compare: (a, b) => dayjs(a.acceptDate) - dayjs(b.acceptDate),
        multiple: 2,
      },
      render: (text, record) => (
        <Space size="middle">
          {
            // record.acceptDate !== "" ?
            record.acceptDate !== "0001-01-01T00:00:00Z" ?
              <Space>
                <>{dayjs(record.acceptDate).format("DD-MM-YYYY HH:mm")}</>
              </Space>
              :
              // <p>ธุรการยังไม่รับงาน</p>
              <Text type="secondary">ธุรการยังไม่รับงาน</Text>
          }
        </Space>
      ),
    },
    {
      title: "ระยะเวลาที่คาดว่าจะเสร็จ",
      dataIndex: "",
      width: "11%",
      align: 'center',
      render: (text, record) => (
        <Space size="middle">
          {
            // record.approvalDate !== "" ?
            record.acceptDate !== "0001-01-01T00:00:00Z" ?
              <Space>
                <Countdown value={dayjs(record.acceptDate).add(3, 'hour')} />
              </Space>
              : <Text type="secondary">ธุรการยังไม่รับงาน</Text>
          }
        </Space>
      ),
    },
    {
      title: "สถานะ",
      dataIndex: "approvalStatus",
      align: 'center',
      width: "15%",
      render: (text, record) => {
        let color
        let color2
        color = colorApprovalStatus(record?.productLoanType, record?.approvalStatus)
        color2 = colorProductLoanType(record?.productLoanType)
        return (
          <>
            <Tag color={color}>
              {record.approvalStatusName}
            </Tag>
            <Space>
              <Tag color={color2}>
                {record.productLoanType}
              </Tag>
            </Space>
            <Space>
              {
                record.car === "car" ?
                  <Tag color={"purple"}>
                    {record.car.toUpperCase()}
                  </Tag>
                  : record.car === "land" ?
                    <Tag color={"green"}>
                      {record.car.toUpperCase()}
                    </Tag>
                    : null
              }
            </Space>
          </>
        );
      },
    },
    {
      title: "Action",
      key: 'Action',
      align: 'center',
      width: "10%",
      render: (text, record) => {
        var PreAaproveSplit = []
        PreAaproveSplit = record.productLoanType.split("-")
        return (
          <>
            <Space size="middle">
              {
                record.approvalStatus === 9 ?
                  <Tag color="geekblue">กรุณาติดต่อเร่งรัดภาคสนาม</Tag>
                  : record.approvalStatus === 16 ?
                    <Space>
                      <Button onClick={() => {
                        setIsModalEditer(true)
                        dispatch(addCustomer(record.customerId))
                        dispatch(addCar(record.carId))
                      }}><EditOutlined /></Button>
                      <Button onClick={() => {
                        setIsModalInfoMK(true)
                        setEditData(record)
                      }}><SearchOutlined /></Button>
                    </Space>
                    : record.approvalStatus === 23 ?
                      <Space>
                        <Button onClick={() => {
                          setIsModalInfoMK(true)
                          setEditData(record)
                        }}><SearchOutlined /></Button>
                      </Space>
                      : record.approvalStatus === 20 ?
                        <>
                          <Button onClick={() => {
                            setModalReEdit(true)
                            setEditData(record)
                          }}>แก้ไข</Button>
                          <Button onClick={() => {
                            setIsModalInfoMK(true)
                            setEditData(record)
                          }}><SearchOutlined /></Button>
                        </>
                        : record.approvalStatus === 21 && PreAaproveSplit[0] !== "Pre" ?
                          <>
                            <Button onClick={() => {
                              setModalReEdit(true)
                              setEditData(record)
                            }}>แก้ไข</Button>
                          </>
                          : (record.approvalStatus === 3 && PreAaproveSplit[0] === "Pre") || (record.approvalStatus === 21 && PreAaproveSplit[0] === "Pre") ?
                            <>
                              <Button onClick={() => {
                                setIsModalAddAaprove(true)
                                setEditData(record)
                              }}>เพิ่มข้อมูล</Button>
                            </>
                            :
                            <>
                              <Button onClick={() => {
                                setIsModalInfoMK(true)
                                setEditData(record)
                              }}><SearchOutlined /></Button>
                            </>
              }
            </Space>
          </>
        );
      },
    },
  ];

  const currencyFormatOne = (amount) => {
    return Number(amount)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, '$&,')
  }

  return (
    <>
      <Card>
        <Form
          form={form}
        >
          <Row gutter={32} style={{ textAlign: 'center' }}>
            <Col>
              <Spin spinning={loading} size='large' tip=" Loading... ">
                <div className='text-center'>
                  <h2>ตรวจสอบข้อมูลรถ(การตลาด)</h2>
                </div>
                <Row gutter={32} justify={'center'}>
                  <Radio.Group value={keyWord} onChange={(e) => onChangeKeyWord(e.target.value)} >
                    {
                      cData.sort((a, b) => {
                        const order = orderCar
                        return order.indexOf(a.approvalStatus) - order.indexOf(b.approvalStatus);
                      })
                        .map((item) => (
                          <Radio.Button style={{
                            width: '160px',
                            border: '2px solid',
                            backgroundColor: backc[item.approvalStatus],
                            color: textc[item.approvalStatus]
                          }}
                            value={item.approvalStatusId}>{item.approvalStatus} <b>{item.totalCount}</b></Radio.Button>
                        ))}
                  </Radio.Group>
                </Row>
                <Row gutter={32} style={{ margin: 10 }}>
                  <Col className='gutter-row' span={24} style={{ textAlign: 'right' }}>
                    <Form.Item name="search">
                      <Input.Search
                        style={{ width: '250px' }}
                        placeholder="ค้นหา...."
                        onChange={search}
                        name="search"
                        id="search"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={32}>
                  <Col span={24}>
                    <Table
                      pagination={false}
                      rowKey={(record) => record.uid}
                      scroll={{
                        x: 1500,
                        y: 400,
                      }}
                      dataSource={arrayTable}
                      columns={columns}
                    >
                    </Table>
                    <Divider />
                    <Pagination
                      current={currentPage}
                      onChange={onChangePagination}
                      onShowSizeChange={onChangePagination}
                      defaultPageSize={10}
                      defaultCurrent={1}
                      total={testPage?.totalCount}
                    />
                    <Divider />
                  </Col>
                </Row>
                <Col span={24} style={{ textAlign: 'center' }}>
                  <Space>
                    <Button href="/offercase" type="primary" ><PlusCircleFilled />เพิ่ม</Button>
                    {/* <Button type="primary" ><ScanOutlined />สแกน</Button>
              <Button type="primary"><InfoCircleFilled />วิธีใช้งาน</Button> */}
                    <Button href="/" type="primary"><HomeFilled />หน้าหลัก</Button>
                  </Space>
                </Col>
              </Spin>
            </Col>
          </Row>
        </Form>
      </Card>
      {
        isModalEditer ?
          <ModalEditer open={isModalEditer} close={setIsModalEditer} />
          : null
      }
      {
        isModalAddAaprove ?
          <ModalAddAaprove open={isModalAddAaprove} close={setIsModalAddAaprove} dataFromTable={editData} SuccSend={SuccSend} />
          : null
      }
      {
        modalReEdit ?
          <MainReEdit open={modalReEdit} close={setModalReEdit} dataFromTable={editData} SuccSend={SuccSend} />
          : null
      }
      {
        isModalInfoMK ?
          <ModalInfoMK open={isModalInfoMK} close={setIsModalInfoMK} dataFromTable={editData} typeLoan={"car"} SuccSend={SuccSend} />
          : null
      }
      {contextHolder}
    </>
  )
};
