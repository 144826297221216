import React, { useEffect, useState } from 'react'
import { Form, Card, Col, Row, Button, Space, Modal, Spin, Divider, Image } from 'antd'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom';
import axios from 'axios'
import TextArea from 'antd/es/input/TextArea';

import { useDispatch, useSelector } from 'react-redux'
import '../../offer_case/css/Media.css'
import { addImage } from '../../../redux/Image';
import { addEmail } from '../../../redux/Email';
import { addSocial } from '../../../redux/Social';
import { findlandall, precreateland, getImagess } from '../../file_mid/all_api';

export default function SendDetail({ changepage, newDataLand }) {
    const [form] = Form.useForm()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const customers = useSelector(state => state.customers)
    const lands = useSelector(state => state.lands)
    const loansLand = useSelector(state => state.loansLand)
    const Addresss = useSelector(state => state.Addresss)
    const Phones = useSelector(state => state.Phones)
    const oldLoansLand = useSelector(state => state.oldLoansLand)
    const imgs = useSelector(state => state.imgs)
    const guarantor = useSelector(state => state.guarantor)
    // const careerIncome = useSelector(state => state.careerIncome)
    const occupations = useSelector(state => state.occupations)
    const Email = useSelector(state => state.Email)
    const Social = useSelector(state => state.Social)

    const result1 = { ...customers.data, guarantors: guarantor.data, expenses: occupations.data.expenses, income: occupations.data.income, ojs: occupations.data.ojs, address: [{ ...Addresss.data }], phones: [{ ...Phones.data }], landLoanDetails: [{ ...loansLand.data, land: [{ ...lands.data, img: imgs.data }] }] }
    const result2 = { ...customers.data, guarantors: guarantor.data, expenses: occupations.data.expenses, income: occupations.data.income, ojs: occupations.data.ojs, address: [{ ...Addresss.data }], phones: [{ ...Phones.data }], landLoanDetails: [{ ...loansLand.data, land: [{ ...lands.data, img: imgs.data, oldLandLoanDetails: oldLoansLand.data }] }] }
    const result3 = { ...customers.data, guarantors: guarantor.data, expenses: occupations.data.expenses, income: occupations.data.income, ojs: occupations.data.ojs, phones: [{ ...Phones.data }], landLoanDetails: [{ ...loansLand.data, land: [{ ...lands.data, img: imgs.data }] }] }
    const result4 = { ...customers.data, guarantors: guarantor.data, expenses: occupations.data.expenses, income: occupations.data.income, ojs: occupations.data.ojs, phones: [{ ...Phones.data }], landLoanDetails: [{ ...loansLand.data, land: [{ ...lands.data, img: imgs.data, oldLandLoanDetails: oldLoansLand.data }] }] }

    const show = { ...customers.data, ...lands.data, ...loansLand.data, ...Addresss.data, ...Phones.data, ...Email?.data, ...oldLoansLand.data, ...guarantor.data, ...occupations.data }
    const [resultNO, setResultNO] = useState({}) // ข้อมูลที่จะส่งไปหลังบ้าน 
    const [resultO, setResultO] = useState({}) // ข้อมูลที่จะส่งไปหลังบ้าน มีโอโลน
    const [easy, setEasy] = useState({}) // โชว์ในหน้าเว็บ
    const [fileList, setFileList] = useState([]) // โชว์รูป
    const [guarantors1, setGuarantors] = useState([]) // โชว์คนค้ำ
    const [imagesomlong, setImageSomlong] = useState(Email?.data);
    const [dataPost, setDataPost] = useState({ offercaseNote: "" })
    const token = localStorage.getItem('token')
    const menu = localStorage.getItem('menu')
    const [imageBlobzz, setImageBlobzz] = useState([]);

    useEffect(() => {
        loadData()
        // form.setFieldsValue(
        //     {
        //         storyLand: lands.data.storyLand ? lands.data.storyLand : '',
        //     }
        // )
        newDataLand.map((e, index) => {
            form.setFieldsValue({
                [`storyLand${index}`]: e?.storyLand,
            })
        })
        if (Email?.data) {
            setImageSomlong(Email?.data)
        }
        setEasy(show)
        if (guarantor.data) {
            setGuarantors(guarantor.data)
        }
        if (guarantor?.data === 0 || guarantor.data === '') {
            delete result1.guarantors
            delete result2.guarantors
            delete result3.guarantors
            delete result4.guarantors
        }
        if (imgs.data) {
            setFileList(imgs.data)
        }
        //console.log("address", Addresss.data)
        if (Addresss.data !== undefined || Addresss.data !== null) {
            setResultNO(result1)
            setResultO(result2)
        }
        else {
            setResultNO(result3)
            setResultO(result4)
        }
    }, [])


    const loadData = async () => {
        await axios.post(findlandall, { carId: Social.data?.landId, customerId: Social.data?.customerId })
            .then(res => {
                if (res.status === 200) {
                    // console.log("LandLand", res.data)
                    setImageBlobzz(res.data.landLoanDetails.land.img)
                    // setLand(res.data.landLoanDetails.land)
                }
            }).catch((err) => {
                console.log("err", err)
            })
    }

    const currencyFormatOne = (amount) => {
        return Number(amount).toFixed(0).replace(/\d(?=(\d{3})+(?!\d))/g, '$&,')
    }
    const currencyFormat = (amount) => {
        return Number(amount)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, '$&,')
    }

    const success = () => {
        Modal.success({
            content: 'บันทึกสำเร็จ',
            path: navigate('/checkInfoLand')
        })
    }
    const error = () => {
        Modal.error({
            title: 'บันทึกไม่สำเร็จ',
            content: 'กรุณาลองใหม่อีกครั้ง',
        })
    }
    const unauthorized = () => {
        Modal.error({
            title: 'unauthorized',
            content: 'คุณไม่มีสิทธิ์ใช้งานเมนูนี้',
        })
    }
    const onChangeBack = () => {
        dispatch(addImage(imagesomlong))
        changepage('5')
    }

    const handleSubmit = async (e) => {
        setLoading(true)
        dispatch(addEmail())
        dispatch(addSocial())
        // console.log("result3", result3)
        // console.log("result4", result4)
        // const headers = {
        //   "Authorization": `Bearer ${tk}`,
        //   "Menu": JSON.stringify("2")
        // }

        if (Object.keys(Addresss?.data).length === 0) {
            delete resultNO.address
            delete resultO.address
        }
        if (JSON.stringify(Addresss?.data) === '{}' || JSON.stringify(resultNO?.address[0]) === '{}' || JSON.stringify(resultO?.address[0]) === '{}') {
            delete resultNO.address
            delete resultO.address
        }
        // console.log("ที่ดิน ข้อมูลทั้งหมด ไม่มีโอโลน", resultNO)
        // console.log("ที่ดิน ข้อมูลทั้งหมด มีโอโลน", resultO)
        if (oldLoansLand?.data) {
            console.log("ที่ดิน ข้อมูลทั้งหมด มีโอโลน")
            const resultOnew = { ...resultO, offercaseNote: dataPost?.offercaseNote.trim() }
            //console.log("ที่ดิน ข้อมูลทั้งหมด มีโอโลน", resultO)
            await axios.post(precreateland, resultOnew)
                .then(res => {
                    if (res.status === 200) {
                        //console.log("result2", result2)
                        success()
                        setLoading(false)
                    }
                })
                .catch((err) => {
                    if (err.response.request.status === 401) {
                        unauthorized()
                    }
                    else {
                        error()
                    }
                    console.log("err", err)
                    setLoading(false)
                })
            setLoading(false)
        }
        else {
            console.log("ที่ดิน ข้อมูลทั้งหมด ไม่มีโอโลน")
            const resultNonew = { ...resultNO, offercaseNote: dataPost?.offercaseNote.trim() }
            // console.log("ที่ดิน ข้อมูลทั้งหมด ไม่มีโอโลน", resultNO)
            //await axios.post('http://localhost:8050/info_land/precreateland', resultNonew)
            await axios.post(precreateland, resultNonew)
                .then(res => {
                    if (res.status === 200) {
                        //console.log("result1", result1)
                        success()
                        setLoading(false)
                    }
                })
                .catch((err) => {
                    if (err.response.request.status === 401) {
                        unauthorized()
                    }
                    else {
                        error()
                    }
                    console.log("err", err)
                    setLoading(false)
                })
            setLoading(false)
        }
        setLoading(false)
    }

    function createImageUrl(imageData) {
        return imageData.image64;
    }
    //////////// โชว์ชื่อไฟล์ PDF ////////////////
    function createPDFUrl(imageData) {
        return imageData.pdf;
    }
    // Function to load and render the PDF
    const renderGuarantor = ({ item, index }) => { //item คือตัวแปรที่เอาไว้ดึงค่าของข้อมูลออกมาแสดง โดยใช้ item.ชื่อฟิวล์
        // console.log("item",item, index)
        return (
            <>
                <Form
                    name='basic'
                    labelCol={{
                        span: 12,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    style={{
                        maxWidth: '100%',
                    }}
                    autoComplete="off"
                    initialValues={{ remember: true }}
                >
                    <Card style={{ width: '300px' }}>
                        <b><u>
                            <div style={{ marginBottom: 0, textAlign: 'left' }}
                            >
                                <span>ลำดับที่ : </span>
                                {/* {item.garno} */}
                                {index + 1}
                            </div>
                        </u></b>
                        <div style={{ marginBottom: 0, textAlign: 'left' }}
                        >
                            <span>ชื่อ-นามสกุล : </span>
                            <b>{item?.snam + ' ' + item?.firstname + ' ' + item?.lastname}</b>
                        </div>
                        <p />
                        <n></n>
                        {/* <div
                            style={{ marginBottom: 0, textAlign: 'left' }}
                        >
                            <span>วัน-เดือน-ปีเกิด : </span>
                            <b>{item.birthdate}</b>
                        </div>

                        <div
                            style={{ marginBottom: 0, textAlign: 'left' }}
                        >
                            <span>เลขบัตรประชาชน : </span>
                            <b>{item.identificationId}</b>
                        </div> */}
                        <div
                            style={{ marginBottom: 0, textAlign: 'left' }}
                        >
                            <span>ชื่อเล่น : </span>
                            <b>{item?.nickname}</b>
                        </div>

                        <div
                            style={{ marginBottom: 0, textAlign: 'left' }}
                        >
                            <span>เบอร์โทร : </span>
                            <b>{item?.phones[0]?.telp}</b>
                        </div>


                    </Card>
                </Form>
            </>
        )
    }

    const renderLandNew = ({ item, index }) => {
        console.log("item", item)
        return (
            <>
                <aside style={{ width: '90%' }}>
                    <Row className='text-left' style={{ marginTop: '20px' }}>
                        <b><u>รายละเอียดที่ดิน ({item.numberLand}) {newDataLand.length > 1 ? <>{item.landMain ? <b style={{ color: "red" }} >(ที่ดินหลัก)</b> : null}</> : null}</u></b>
                    </Row><p />
                    <n />
                    <div label='กลุ่มสินค้า' name='productTypeId'>
                        {/* {easy.gcode} */}
                        <span>กลุ่มสินค้า : </span>
                        <b>
                            {
                                easy.productTypeId === 1 ? <>รถ</> : <>ที่ดิน</>
                            }
                        </b>

                    </div>

                    <div label='ประเภทเอกสาร' name='landTypeId'>
                        <span>ประเภทเอกสาร : </span>
                        <b>
                            {
                                easy.landTypeId === 1 ? <>นส.3</> : <>นส.4</>
                            }
                        </b>
                    </div>

                    <div label='เลขโฉนดที่ดิน' name='numberLand'>
                        <span>เลขโฉนดที่ดิน : </span><b>{easy.numberLand}</b>
                    </div>

                    <div label='เลขที่ดินหรือระวาง' name='numberLandLawang' >
                        <span>เลขที่ดินหรือระวาง : </span><b>{easy.numberLandLawang}</b>
                    </div>

                    <div label='จังหวัด' name='province' >
                        <span>จังหวัด : </span><b>{lands?.data?.province}</b>
                    </div>

                    <div label='อำเภอ' name='district'>
                        <span>อำเภอ : </span><b>{lands?.data?.district}</b>
                    </div>

                    {/* <div label='ตำบล' name='subdistrict'>
                                    <span>เลขประจำตัวประชาชน : </span><b>{easy.subdistrict}</b>
                                </div> */}

                    <div label='พื้นที่ไร่' name='rai'>
                        <span>พื้นที่ไร่ : </span><b>{isNaN(easy.rai) ? "-" : currencyFormatOne(easy.rai) + " ไร่"}</b>
                    </div>
                    <div label='พื้นที่งาน' name='workArea'>
                        <span>พื้นที่งาน : </span><b>{isNaN(easy.workArea) ? "-" : currencyFormatOne(easy.workArea) + " งาน"}</b>
                    </div>
                    <div label='พื้นที่ตารางวา' name='squareWaArea'>
                        <span>พื้นที่ตารางวา : </span><b>{isNaN(easy.squareWaArea) ? "-" : currencyFormat(easy.squareWaArea) + " ตารางวา"}</b>
                    </div>
                    <div label='ราคาประเมินที่ดิน' name='landPrice'>
                        <span>ราคาประเมินที่ดิน : </span><b>{isNaN(easy.landPrice) ? "-" : currencyFormat(easy.landPrice) + " บาท / ตารางวา"}</b>
                    </div>
                    <div label='ราคารวม' name='resultLandPrice'>
                        <span>ราคารวม : </span><b>{isNaN(easy.resultLandPrice) ? "-" : currencyFormat(easy.resultLandPrice) + " บาท"}</b>
                    </div>
                </aside>
                <Col span={22}>
                    {/* <span><b style={{ fontSize: '25' }}>สตอรี่รายละเอียดที่ดิน</b></span> <p /> */}
                    <Divider orientation='left'><b>สตอรี่รายละเอียดที่ดิน ({item.numberLand})</b></Divider>
                    <Form.Item name={`storyLand${index}`} label="">
                        <TextArea
                            name='storyLand'
                            style={{ color: 'blue' }}
                            rows={5}
                            disabled
                        />
                    </Form.Item>
                </Col>
                <Divider />
            </>
        )
    }

    const renderTitleDeed = ({ item, index }) => {
        return (
            <>
                <Row justify={'left'}><b>รูปโฉนด/หน้า-หลัง ({item.numberLand})</b></Row>
                <Row gutter={32} justify={'center'}>
                    <Space>
                        <Row justify={'center'}>
                            {item?.img?.map((e, index) => {
                                if (e.type === 3) {
                                    return (
                                        <>
                                            <Row gutter={32} justify={'center'}>
                                                <Col span={24} style={{ textAlign: 'center' }}>
                                                    <Image style={{ margin: 3 }} width={'100px'} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                </Col>

                                            </Row>
                                        </>
                                    );
                                }
                                return null;
                            })}
                        </Row>
                    </Space>
                </Row>
                <Divider />
            </>
        )
    }
    const renderDivision = ({ item, index }) => {
        return (
            <>
                <Row justify={'left'}><b>ใบแบ่งใบต่อ ({item.numberLand})</b></Row>
                <Row gutter={32} justify={'center'}>
                    <Space>
                        <Row justify={'center'}>
                            {item?.img?.map((e, index) => {
                                if (e.type === 10) {
                                    return (
                                        <>
                                            <Row gutter={32} justify={'center'}>
                                                <Col span={24} style={{ textAlign: 'center' }}>
                                                    <Image style={{ margin: 3 }} width={'100px'} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                </Col>

                                            </Row>
                                        </>
                                    );
                                }
                                return null;
                            })}
                        </Row>
                    </Space>
                </Row>
                <Divider />
            </>
        )
    }
    const renderLawang = ({ item, index }) => {
        return (
            <>
                <Row justify={'left'}><b>ระวาง ({item.numberLand})</b></Row>
                <Row gutter={32} justify={'center'}>
                    <Space>
                        <Row justify={'center'}>
                            {item?.img?.map((e, index) => {
                                if (e.type === 11) {
                                    return (
                                        <>
                                            <Row gutter={32} justify={'center'}>
                                                <Col span={24} style={{ textAlign: 'center' }}>
                                                    <Image style={{ margin: 3 }} width={'100px'} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                </Col>

                                            </Row>
                                        </>
                                    );
                                }
                                return null;
                            })}
                        </Row>
                    </Space>
                </Row>
                <Divider />
            </>
        )
    }
    const renderLandBuildings = ({ item, index }) => {
        return (
            <>
                <Row justify={'left'}><b>ใบประเมิน/รูปที่ดิน/สิ่งปลูกสร้าง-ที่ดินปัจจุบัน ({item.numberLand})</b></Row>
                <Row gutter={32} justify={'center'}>
                    <Space>
                        <Row justify={'center'}>
                            {item?.img?.map((e, index) => {
                                if (e.type === 1) {
                                    return (
                                        <>
                                            <Row gutter={32} justify={'center'}>
                                                <Col span={24} style={{ textAlign: 'center' }}>
                                                    <Image style={{ margin: 3 }} width={'100px'} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                </Col>
                                            </Row>
                                        </>
                                    );
                                }
                                return null;
                            })}
                        </Row>
                    </Space>
                </Row>
                <Divider />
            </>
        )
    }

    return (
        <Row justify={'center'} gutter={32}>
            <Card style={{ width: '1000px' }}>
                <Spin spinning={loading} size='large' tip=" Loading... ">
                    <div className='text-center'>
                        <h2>รายละเอียด</h2>
                    </div>
                    <Divider style={{ margin: 5 }} />
                    <Form
                        style={{
                            width: '100%',
                        }}
                        form={form}
                        onFinish={handleSubmit}
                        autoComplete="off"
                        initialValues={{ remember: true }}
                    >
                        <div className='main'>
                            <div id="pdfContent"></div>

                            <Row gutter={32} justify={'center'}>
                                <aside style={{ width: '90%' }}>
                                    {easy?.nationalId === "นิติบุคคล" ?
                                        <>
                                            <div className='text-left' style={{ margin: 0 }}>
                                                <h4><u>รายละเอียดผู้กู้</u></h4>
                                            </div><p />
                                            <n />
                                            <div label='ประเภท' name='nationalId' style={{ marginBottom: 3 }}>
                                                <span>ประเภท : </span> <b>{easy?.nationalId}</b>
                                            </div>
                                            <div label='เลขประจำตัวผู้เสียภาษี' name='identificationId' style={{ marginBottom: 3 }}>
                                                <span>เลขประจำตัวผู้เสียภาษี : </span> <b>{easy.identificationId}</b>
                                            </div>
                                            <div label='ชื่อบริษัท' name='snam' style={{ marginBottom: 3 }}>
                                                <span>
                                                    {easy?.snam} : </span> <b>{easy.firstname + ' ' + easy?.lastname}</b>
                                            </div>
                                            <div label='วันจดทะเบียน' name='nickname' style={{ marginBottom: 3 }}>
                                                <span>วันจดทะเบียน : </span> <b> {easy?.birthdate}</b>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <Row className='text-left' style={{ marginTop: '20px' }}>
                                                <b><u>รายละเอียดผู้กู้</u></b>
                                            </Row><p />
                                            <n />
                                            <div label='เลขประจำตัวประชาชน' name='identificationId'>
                                                <span>เลขประจำตัวประชาชน : </span><b>{easy.identificationId}</b>
                                            </div>
                                            <div label='ชื่อ - นามสกุล' name='snam'>
                                                <span>ชื่อ - นามสกุล : </span><b>{easy?.snam + ' ' + easy?.firstname + ' ' + easy?.lastname}</b>
                                            </div>

                                            <div label='เกิดวันที่' name='birthdate'>
                                                <span>เกิดวันที่ : </span><b>{dayjs(easy.birthdate).format("DD-MM-YYYY")}</b>
                                            </div>

                                            <div label='เพศ' name='gender'>
                                                <span>เพศ : </span><b>{easy.gender}</b>
                                            </div>

                                            <div label='เบอร์โทรติดต่อ' name='telp'>
                                                <span>เบอร์โทรติดต่อ : </span><b>{easy.telp}</b>
                                            </div>

                                            <div label='สถานที่ออกบัตรประชาชน' name='issuby'>
                                                <span>สถานที่ออกบัตรประชาชน : </span><b>{easy.issuby}</b>
                                            </div>

                                            <div label='วันออกบัตร' name='issudt'>
                                                <span>วันออกบัตร : </span><b>{dayjs(easy.issudt).format("DD-MM-YYYY")}</b>
                                            </div>

                                            <div label='วันบัตรหมดอายุ' name='expdt'>
                                                <span>วันบัตรหมดอายุ : </span><b>{dayjs(easy.expdt).format("DD-MM-YYYY")}</b>
                                            </div>
                                        </>
                                    }
                                </aside>
                            </Row>
                            <Divider style={{ margin: 5 }} />
                            <Row gutter={32} justify={'center'}>
                                {/* <aside style={{ width: '90%' }}>
                                    <Row className='text-left' style={{ marginTop: '20px' }}>
                                        <b><u>ประเภทสินค้า</u></b>
                                    </Row><p />
                                    <n />
                                    <div label='กลุ่มสินค้า' name='productTypeId'>
                                        {easy.gcode}
                                        <span>กลุ่มสินค้า : </span>
                                        <b>
                                            {
                                                easy.productTypeId === 1 ? <>รถ</> : <>ที่ดิน</>
                                            }
                                        </b>

                                    </div>

                                    <div label='ประเภทเอกสาร' name='landTypeId'>
                                        <span>ประเภทเอกสาร : </span>
                                        <b>
                                            {
                                                easy.landTypeId === 1 ? <>นส.3</> : <>นส.4</>
                                            }
                                        </b>
                                    </div>

                                    <div label='เลขโฉนดที่ดิน' name='numberLand'>
                                        <span>เลขโฉนดที่ดิน : </span><b>{easy.numberLand}</b>
                                    </div>

                                    <div label='เลขที่ดินหรือระวาง' name='numberLandLawang' >
                                        <span>เลขที่ดินหรือระวาง : </span><b>{easy.numberLandLawang}</b>
                                    </div>

                                    <div label='จังหวัด' name='province' >
                                        <span>จังหวัด : </span><b>{lands?.data?.province}</b>
                                    </div>

                                    <div label='อำเภอ' name='district'>
                                        <span>อำเภอ : </span><b>{lands?.data?.district}</b>
                                    </div>

                                    <div label='ตำบล' name='subdistrict'>
                                    <span>เลขประจำตัวประชาชน : </span><b>{easy.subdistrict}</b>
                                </div>

                                    <div label='พื้นที่ไร่' name='rai'>
                                        <span>พื้นที่ไร่ : </span><b>{isNaN(easy.rai) ? "-" : currencyFormatOne(easy.rai) + " ไร่"}</b>
                                    </div>
                                    <div label='พื้นที่งาน' name='workArea'>
                                        <span>พื้นที่งาน : </span><b>{isNaN(easy.workArea) ? "-" : currencyFormatOne(easy.workArea) + " งาน"}</b>
                                    </div>
                                    <div label='พื้นที่ตารางวา' name='squareWaArea'>
                                        <span>พื้นที่ตารางวา : </span><b>{isNaN(easy.squareWaArea) ? "-" : currencyFormat(easy.squareWaArea) + " ตารางวา"}</b>
                                    </div>
                                    <div label='ราคาประเมินที่ดิน' name='landPrice'>
                                        <span>ราคาประเมินที่ดิน : </span><b>{isNaN(easy.landPrice) ? "-" : currencyFormat(easy.landPrice) + " บาท / ตารางวา"}</b>
                                    </div>
                                    <div label='ราคารวม' name='resultLandPrice'>
                                        <span>ราคารวม : </span><b>{isNaN(easy.resultLandPrice) ? "-" : currencyFormat(easy.resultLandPrice) + " บาท"}</b>
                                    </div>
                                </aside> */}
                                {
                                    newDataLand?.map((item, index) => {
                                        return renderLandNew({ item, index, key: `{item.identificationId} - ${index}` })
                                    })
                                }
                            </Row>

                            <Row justify={'center'} style={{ marginTop: '15px' }}>
                                {/* <Col span={22}>
                                    <span><b style={{ fontSize: '25' }}>สตอรี่รายละเอียดที่ดิน</b></span> <p />
                                    <Form.Item name="storyLand" label="">
                                        <TextArea
                                            name='storyLand'
                                            style={{ color: 'blue' }}
                                            rows={8}
                                            disabled
                                        />
                                    </Form.Item>
                                </Col> */}
                            </Row>

                            <Divider style={{ margin: 5 }} />

                            {easy.productLoanLandType === "ย้ายไฟแนนซ์" ?
                                <>
                                    <Row justify={'center'} gutter={32}>
                                        <aside style={{ width: '90%' }}>
                                            <Row className='text-left' style={{ marginTop: '20px' }}>
                                                <b><u>รายละเอียดขอกู้ย้ายไฟแนนซ์</u></b>
                                            </Row><p />
                                            <n />
                                            <div label='ประเภทขอกู้' name='productLoanLandType'>
                                                <span>ประเภทขอกู้ : </span><b>{easy.productLoanLandType}</b>
                                            </div>

                                            <div label='ยอดจัด' name='loanAmounttLand'>
                                                <span>ยอดจัด : </span><b>{isNaN(easy.loanAmounttLand) ? "-" : currencyFormat(easy.loanAmounttLand) + " บาท"}</b>
                                            </div>

                                            <div label='อัตราดอกเบี้ย' name='interestRateLand'>
                                                <span>อัตราดอกเบี้ย : </span><b>{isNaN(easy.interestRateLand) ? "-" : currencyFormat(easy.interestRateLand) + " % +VAT"}</b>
                                            </div>

                                            <div label='จำนวนงวด' name='loanLandTerm'>
                                                <span>จำนวนงวด : </span><b>{isNaN(easy.loanLandTerm) ? "-" : currencyFormat(easy.loanLandTerm) + " งวด"}</b>
                                            </div>

                                            <div label='ดอกเบี้ย' name='interesttLand'>
                                                <span>ดอกเบี้ย : </span><b>{isNaN(easy.interesttLand) ? "-" : currencyFormat(easy.interesttLand) + " บาท"}</b>
                                            </div>

                                            <div label='งวดละ' name='monthlyPaymentLand'>
                                                <span>งวดละ : </span><b>{isNaN(easy.loanAmounttLand) ? "-" : currencyFormat(easy.monthlyPaymentLand) + " บาท"}</b>
                                            </div>

                                            <div label='ราคารวม' name='installmentWithInterestLand'>
                                                <span>ราคารวม : </span><b>{isNaN(easy.installmentWithInterestLand) ? "-" : currencyFormat(easy.installmentWithInterestLand) + " บาท"}</b>
                                            </div>
                                        </aside>
                                    </Row>
                                    <Divider style={{ margin: 5 }} />
                                    <Row justify={'center'} gutter={32}>
                                        <aside style={{ width: '90%' }}>
                                            <Row className='text-left' style={{ marginTop: '20px' }}>
                                                <b><u>รายละเอียดย้ายไฟแนนซ์</u></b>
                                            </Row><p />
                                            <n />
                                            <div label='ปิดไฟแนนซ์จาก' name='issuno' style={{ marginBottom: 3 }}>
                                                <span>ปิดไฟแนนซ์จาก : </span><b>{easy.issuno}</b>
                                            </div>
                                            <div label='ยอดปิดไฟแนนซ์' name='oldLandLoanAmount' style={{ marginBottom: 3 }}>
                                                <span>ยอดปิดไฟแนนซ์ : </span><b>{isNaN(easy.oldLandLoanAmount) ? "-" : currencyFormatOne(easy.oldLandLoanAmount) + " บาท"}</b>
                                            </div>

                                            <div label='ค่างวด/เดือน' name='oldLandMonthlyPayment' style={{ marginBottom: 3 }}>
                                                <span>ค่างวด/เดือน : </span><b>{isNaN(easy.oldLandMonthlyPayment) ? "-" : currencyFormatOne(easy.oldLandMonthlyPayment) + " บาท"}</b>
                                            </div>

                                            <div label='จำนวนผ่อนกี่งวด' name='oldLandLoanTerm' style={{ marginBottom: 3 }}>
                                                <span>จำนวนผ่อนกี่งวด : </span><b>{isNaN(easy.oldLandLoanTerm) ? "-" : easy.oldLandLoanTerm + " งวด"}</b>
                                            </div>

                                        </aside>
                                    </Row>

                                </>
                                :
                                <>
                                    <Row justify={'center'} gutter={32}>
                                        <aside style={{ width: '90%' }}>
                                            <Row className='text-left' style={{ marginTop: '20px' }}>
                                                <b><u>รายละเอียดยอดกู้</u></b>
                                            </Row><p />
                                            <n />
                                            <div label='ประเภทขอกู้' name='productLoanLandType'>
                                                <span>ประเภทขอกู้ : </span><b>{easy.productLoanLandType}</b>
                                            </div>

                                            <div label='ยอดจัด' name='loanAmounttLand'>
                                                <span>ยอดจัด : </span><b>{isNaN(easy.loanAmounttLand) ? "-" : currencyFormat(easy.loanAmounttLand) + " บาท"}</b>
                                            </div>

                                            <div label='อัตราดอกเบี้ย' name='interestRateLand'>
                                                <span>อัตราดอกเบี้ย : </span><b>{isNaN(easy.interestRateLand) ? "-" : currencyFormat(easy.interestRateLand) + " % +VAT"}</b>
                                            </div>

                                            <div label='จำนวนงวด' name='loanLandTerm'>
                                                <span>จำนวนงวด : </span><b>{isNaN(easy.loanLandTerm) ? "-" : currencyFormat(easy.loanLandTerm) + " งวด"}</b>
                                            </div>

                                            <div label='ดอกเบี้ย' name='interesttLand'>
                                                <span>ดอกเบี้ย : </span><b>{isNaN(easy.interesttLand) ? "-" : currencyFormat(easy.interesttLand) + " บาท"}</b>
                                            </div>

                                            <div label='งวดละ' name='monthlyPaymentLand'>
                                                <span>งวดละ : </span><b>{isNaN(easy.loanAmounttLand) ? "-" : currencyFormat(easy.monthlyPaymentLand) + " บาท"}</b>
                                            </div>

                                            <div label='ราคารวม' name='installmentWithInterestLand'>
                                                <span>ราคารวม : </span><b>{isNaN(easy.installmentWithInterestLand) ? "-" : currencyFormat(easy.installmentWithInterestLand) + " บาท"}</b>
                                            </div>
                                        </aside>
                                    </Row>
                                </>
                            }
                            <Divider style={{ margin: 5 }} />
                            {occupations?.data ?
                                <>
                                    <Row justify={'center'} gutter={32}>
                                        <aside style={{ width: '90%' }}>
                                            <div className='text-left' >
                                                <h4><u>อาชีพ - รายได้</u></h4>
                                            </div><p />
                                            <n />
                                            <div className='text-left'>
                                                {/* <span>ยอดปิดไฟแนนซ์ : </span><b>{isNaN(easy.oldLoanAmount) ? "-" : currencyFormatOne(easy.oldLoanAmount) + " บาท"}</b> */}
                                                <span>กลุ่มอาชีพ : </span><b>{easy?.ojs?.occupationalGroupTH}</b>
                                            </div>
                                            <div className='text-left'>
                                                <span>อาชีพ : </span><b>{easy?.ojs?.governmentPrivateTH}</b>
                                            </div>
                                            <div className='text-left'>
                                                <span>สาขาอาชีพ : </span><b>{easy?.ojs?.jobPositionTH}</b>
                                            </div>
                                            <div className='text-left'>
                                                <span>ชื่อบริษัท(สถานที่ทำงาน) : </span><b>{easy?.ojs?.companyName}</b>
                                            </div>
                                            <div className='text-left'>
                                                <span>รายได้ / เดือน : </span><b>{currencyFormatOne(easy?.income?.incomeMonth)}</b>
                                            </div>
                                            <div className='text-left'>
                                                <span>รายจ่าย / เดือน : </span><b>{currencyFormatOne(easy?.expenses?.expensesMonth)}</b>
                                            </div>
                                        </aside>
                                    </Row>
                                    <Divider style={{ margin: 5 }} />
                                </>
                                :
                                null
                            }

                            {guarantors1?.length >= 1 ?
                                <><Card>
                                    <Row justify={'center'}>
                                        <b><u>รายละเอียดคนค้ำ</u></b>
                                    </Row>
                                    <Row justify={'center'}>
                                        {
                                            guarantors1?.map((item, index) => {
                                                return renderGuarantor({ item, index, key: `{item.identificationId} - ${index}` })
                                            })
                                        }
                                    </Row>
                                </Card>
                                    <Divider style={{ margin: 5 }} />
                                </>
                                :
                                <>
                                    < Divider style={{ margin: 5 }} />
                                </>
                            }
                            <Row justify={'left'}><b><u>รูปบัตรคนกู้</u></b></Row>
                            <Row gutter={32} justify={'center'}>
                                {imageBlobzz?.map((e, index) => {
                                    if (e.type === 4) {
                                        return (
                                            <>
                                                <Image width={"20%"} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                            </>
                                        );
                                    }
                                    return null;
                                })}
                            </Row>
                            <Divider />
                            {/* {
                                newDataLand?.map((item, index) => {
                                    return renderIMGNew({ item, index, key: `{item.identificationId} - ${index}` })
                                })
                            } */}
                            {
                                newDataLand?.map((item, index) => {
                                    return renderTitleDeed({ item, index, key: `{item.identificationId} - ${index}` })
                                })
                            }
                            {
                                newDataLand?.map((item, index) => {
                                    return renderDivision({ item, index, key: `{item.identificationId} - ${index}` })
                                })
                            }
                            {
                                newDataLand?.map((item, index) => {
                                    return renderLawang({ item, index, key: `{item.identificationId} - ${index}` })
                                })
                            }
                            {
                                newDataLand?.map((item, index) => {
                                    return renderLandBuildings({ item, index, key: `{item.identificationId} - ${index}` })
                                })
                            }
                            <Row justify={'left'}><b><u>รูปอาชีพ</u></b></Row>
                            <Row gutter={32} justify={'center'}>
                                {imageBlobzz?.map((e, index) => {
                                    if (e.type === 6) {
                                        return (
                                            <>
                                                <Image width={"20%"} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                            </>
                                        );
                                    }
                                    return null;
                                })}
                            </Row>
                            <Divider />
                            <Row justify={'left'}><b><u>รูปสเตทเมนต์</u></b></Row>
                            <Row gutter={32} justify={'center'}>
                                {imageBlobzz?.map((e, index) => {
                                    if (e.type === 8) {
                                        return (
                                            <>
                                                <Image width={"20%"} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                            </>
                                        );
                                    }
                                    return null;
                                })}
                            </Row>
                            <Divider />
                            <Row justify={'left'}><b><u>รูปอื่นๆ</u></b></Row>
                            <Row gutter={32} justify={'center'}>
                                {imageBlobzz?.map((e, index) => {
                                    if (e.type === 9) {
                                        return (
                                            <>
                                                <Image width={"20%"} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                            </>
                                        );
                                    }
                                    return null;
                                })}
                            </Row>
                            <Divider />
                            {imageBlobzz?.map((e, index) => (
                                <>
                                    <div className='main'>
                                        {/* <div> */}
                                        {/* {fileList[index].type === 4 ? */}
                                        {/* {imageBlobzz[index].type === 4 ?
                                            <>
                                                <Row justify={'center'}><span><b>รูปบัตรคนกู้ :</b></span></Row>
                                                <Row gutter={32} justify={'center'}>
                                                    <Col span={24} style={{ textAlign: 'center' }}>
                                                        <img style={{ margin: 3 }} width={'200px'} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                    </Col>
                                                </Row>
                                            </>
                                            : null
                                        } */}
                                        {/* {lands.data ?
                                            <>
                                                {imageBlobzz[index].type === 1 ?
                                                    <>
                                                        <Row justify={'center'}><span><b>รูปที่ดิน :</b></span></Row>
                                                        <Row gutter={32} justify={'center'}>
                                                            <Col span={24} style={{ textAlign: 'center' }}>
                                                                <img style={{ margin: 3 }} width={'200px'} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                            </Col>
                                                        </Row>
                                                    </>
                                                    : null
                                                }
                                                {imageBlobzz[index].type === 3 ?
                                                    <>
                                                        <Row justify={'center'}><span><b>รูปโฉนด :</b></span></Row>
                                                        <Row gutter={32} justify={'center'}>
                                                            <Col span={24} style={{ textAlign: 'center' }}>
                                                                <img style={{ margin: 3 }} width={'200px'} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                            </Col>
                                                        </Row>
                                                    </>
                                                    : null
                                                }
                                            </>
                                            : null
                                        } */}
                                        {/* {imageBlobzz[index].type === 8 ?
                                            <>
                                                <Row justify={'center'} style={{ marginLeft: 5 }}><span><b>รูปสเตทเมนต์ :</b></span></Row>
                                                <Row gutter={32} justify={'center'}>
                                                    <Col span={24} style={{ textAlign: 'center' }}>
                                                        <img style={{ margin: 3 }} width={'200px'} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                    </Col>
                                                </Row>
                                            </>
                                            : null
                                        } */}
                                        {/* {imageBlobzz[index].type === 7 ?
                                                <>
                                                    <Row justify={'center'} style={{ marginLeft: 5 }}><span><b>ไฟล์ PDF สเตทเมนต์  :</b></span></Row>
                                                    <Row gutter={32} justify={'center'}>
                                                        <Col span={24} style={{ textAlign: 'center' }}>
                                                            <object data={`${getImagess}/${e.pathImage}?token=${token}`} type="application/pdf" width="350px" height="450px">
                                                                <p>Alternative text - include a link <a href={`${getImagess}/${e.pathImage}?token=${token}`}>to the PDF!</a></p>
                                                            </object>
                                                        </Col>
                                                    </Row>
                                                </>
                                                : null
                                            } */}
                                        {/* </div> */}
                                        {/* {imageBlobzz[index].type === 6 ?
                                            <>
                                                <Row justify={'center'}><span><b>รูปอาชีพ :</b></span></Row>
                                                <Row gutter={32} justify={'center'}>
                                                    <Col span={24} style={{ textAlign: 'center' }}>
                                                        <img style={{ margin: 3 }} width={'200px'} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                    </Col>
                                                </Row>
                                            </>
                                            : null
                                        } */}

                                        {imageBlobzz[index].type === 9 ?
                                            <>
                                                <Row justify={'center'}><span><b>รูปอื่นๆ :</b></span></Row>
                                                {/* <Row justify={'center'}>
                                                    <img width={'350px'} src={createImageUrl(imageData)} alt={imageData.name} />
                                                </Row> */}
                                                <Row gutter={32} justify={'center'}>
                                                    <Col span={24} style={{ textAlign: 'center' }}>
                                                        <img style={{ margin: 3 }} width={'200px'} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                    </Col>
                                                </Row>
                                            </>
                                            : null
                                        }

                                    </div >
                                </>
                            ))}
                        </div>
                        <Row justify={'center'}>
                            <Col span={22}>
                                <Form.Item name="offercaseNote" label="">

                                    <span><b style={{ fontSize: '25' }}>หมายเหตุ</b></span> <p />
                                    <span><u style={{ fontSize: '20', color: 'red' }}>กรณีที่เอกสารส่วนไหนไม่ครบหรือไม่มี ให้พิมพ์แจ้งตรงนี้ได้เลย</u></span>
                                    <TextArea
                                        name='offercaseNote'
                                        style={{ color: 'blue' }}
                                        rows={8}
                                        onChange={(e) => setDataPost({ offercaseNote: e.target.value })}
                                    >
                                        {/* <b style={{ color: 'blue' }}>{dataModalCars?.cars?.carMemo}</b> */}
                                    </TextArea>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Divider />

                        <Row gutter={32}>
                            <Col className="gutter-row" span={24} style={{ textAlign: 'center' }}>
                                <Space>
                                    <Button type="text" onClick={onChangeBack} style={{ background: "#bfbfbf", color: 'white' }} >
                                        ย้อนกลับ
                                    </Button>
                                    {
                                        loading === false ?
                                            <>
                                                <Button type="text" htmlType="submit" style={{ background: '#389e0d', color: '#ffffff' }} >
                                                    บันทึก
                                                </Button>
                                            </> :
                                            <>
                                                <Button type='primary' htmlType="submit" disabled
                                                    style={{ color: 'success' }}
                                                >
                                                    บันทึก
                                                </Button>
                                            </>
                                    }
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Card>
        </Row >
    )
}
