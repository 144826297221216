import React, { useState, useEffect, useRef } from "react";
import { Modal, Button, Divider, Row, Col, message, Space, Spin, Input, Select, Form, } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import axios from "axios";
import "../../css/MobileActive.css";
import { loanTermMid } from "../../file_mid/all_options";
import moment from "moment";
import { editapprovalceo } from "../../file_mid/all_api";

const EditApproved = ({ open, close, data }) => {
  const { TextArea } = Input;
  const [formData, setFormData] = useState({
    newDamt: "",
    followPay: "",
    approvedLoanAmount: "",
    approvedLoanTerm: "",
    approvedLoanTermTo: "",
    Memo: "",
  });
  const [loading, setLoading] = useState(false);

  const { confirm } = Modal;
  const [form] = Form.useForm();
  const token = localStorage.getItem("token");
  const user = localStorage.getItem("username");

  const handleClose = () => {
    close(false);
    // loadData()
  };

  // console.log("data", data)

  useEffect(() => {

    if (data) {
      setFormData({
        ...formData,
        newDamt: data?.newDamt,
        followPay: data?.followPay,
        approvedLoanAmount: data?.approvedLoanAmount,
        approvedLoanTerm: data?.approvedLoanTerm,
        approvedLoanTermTo: data?.approvedLoanTermTo,
      });
      form.setFieldsValue({
        ...data,
        newDamt: currencyFormatOne(data?.newDamt),
        followPay: currencyFormatOne(data?.followPay),
        approvedLoanAmount: currencyFormatOne(parseInt(data?.approvedLoanAmount)),
        approvedLoanTerm: data?.approvedLoanTerm,
        approvedLoanTermTo: data?.approvedLoanTermTo,
      });
    }
  }, [data, form]);

  console.log("formData", formData);
  const handleLoanTerm = (value) => {
    if (formData.approvedLoanTermTo < value && formData.approvedLoanTermTo) {
      message.error("จำนวนงวดต้องน้อยกว่างวดถึง โปรดเลือกใหม่!!");
      setFormData({ ...formData, approvedLoanTerm: value });
    } else {
      setFormData({ ...formData, approvedLoanTerm: value });
    }
  };

  const handleLoanTermTo = (value) => {
    if (formData.approvedLoanTerm > value) {
      message.error("จำนวนงวดถึงต้องมากกว่า");
      setFormData({ ...formData, approvedLoanTermTo: value });
    } else {
      setFormData({ ...formData, approvedLoanTermTo: value });
    }
  };

  const sendEdit = async () => {
    setLoading(true);
    const tk = JSON.parse(token);
    const url = editapprovalceo;
    const headers = {
      Authorization: `Bearer ${tk}`,
      Menu: JSON.stringify("25"),
    };

    const datatoSend = {
      id: data?.id,
      approvalStatus: data?.approvalStatus,
      approvedBy: user,
      approvedLoanAmount: parseFloat(formData?.approvedLoanAmount),
      approvedLoanTerm: formData?.approvedLoanTerm,
      approvedLoanTermTo: formData?.approvedLoanTermTo,
      newDamt: parseFloat(formData?.newDamt),
      followPay: parseFloat(formData?.followPay),
      offerBy: data?.offerBy,
      Memo: {
        note: formData.Memo,
        // note: data.note[0].note + formData.Memo,
      }
    }
    console.log("datatoSend", datatoSend)
    await axios.post(url, datatoSend, { headers: headers })
      .then(async (res) => {
        if (res.data) {
          // console.log("res.data", res.data)
          message.success(
            `ยืนยันการแก้ไขข้อมูลสำเร็จ ${data.CONTNO}  ${currentDateTime()}`
          );
          setLoading(false);
          handleClose();
        }
      })
      .catch((err) => console.log(err));
    setLoading(false);
  };

  const onFinish = (values) => {
    console.log('Success:', values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const currentDateTime = () => {
    const date = moment().add(543, "year").format("LLL");
    return date;
  };

  const currencyFormatOne = (amount) => {
    if (amount) {
      return Number(amount)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,");
    } else {
      return 0;
    }
  };

  // function currencyFormatOne(value) {
  //   return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  // }
  // const currencyCutDash = (amount) => {
  //   if (amount) {
  //     return Number(amount).toFixed(2).replace(/-/g, "");
  //   }
  // };

  const showConfirm = () => {
    confirm({
      title: "บันทึกข้อมูลแก้ไข",
      icon: <ExclamationCircleFilled />,
      content: "คุณต้องการบันทึกการแก้ไขหรือไม่",
      okText: "บันทึก",
      cancelText: "ยกเลิก",
      onOk() {
        sendEdit();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  function currencyFormatOne1(value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <>
      <Modal
        open={open}
        onCancel={handleClose}
        width={850}
        footer={[null]}
      >
        <Spin spinning={loading} size="large" tip=" Loading... ">
          <Form
            form={form}
            name="basic"
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 22,
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <>
              <Divider
                orientation="center"
                style={{ marginTop: "30px", fontSize: "larger" }}
              >
                <b><u>แก้ไขยอดอนุมัติ</u></b>
              </Divider>
              <Row justify={'center'} className="main1" align="middle">
                <Col span={22} style={{ fontSize: '16px' }}>
                  <aside>
                    <div>
                      <Form.Item
                        label='ค่างวดที่ต้องชำระ : '
                        name="newDamt"
                        rules={[
                          {
                            required: true,
                            message: 'Please input your newDamt!',
                          },
                        ]}
                      >
                        <Input
                          style={{
                            width: "100%",
                          }}
                          name="newDamt"
                          onChange={(e) => {
                            let inputValue = e.target.value;
                            console.log("inputValue", inputValue)
                            if (inputValue.length >= 4) {
                              console.log("11")
                              var rawValue = inputValue.replace(/,/g, ''); // Remove existing commas
                              let intValue = parseInt(rawValue);
                              let formattedValue = intValue >= 1000 ? currencyFormatOne1(intValue) : rawValue;
                              setFormData({
                                ...formData,
                                newDamt: formattedValue.toString().replace(/,/g, ''),
                              });
                              form.setFieldsValue({
                                newDamt: formattedValue,
                              })
                            } else {
                              console.log("22")
                              setFormData({
                                ...formData,
                                newDamt: parseFloat(inputValue),
                              });
                              form.setFieldsValue({
                                newDamt: inputValue,
                              })
                            }

                          }}
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        label='ค่าติดตาม : '
                        name="followPay"
                        rules={[
                          {
                            required: true,
                            message: 'Please input your followPay!',
                          },
                        ]}
                      >
                        <Input
                          style={{
                            width: "100%",
                          }}
                          name="followPay"
                          onChange={(e) => {
                            let inputValue = e.target.value;
                            console.log("inputValue", inputValue)
                            if (inputValue.length >= 4) {
                              console.log("11")
                              var rawValue = inputValue.replace(/,/g, ''); // Remove existing commas
                              let intValue = parseInt(rawValue);
                              let formattedValue = intValue >= 1000 ? currencyFormatOne1(intValue) : rawValue;
                              setFormData({
                                ...formData,
                                followPay: formattedValue.toString().replace(/,/g, ''),
                              });
                              form.setFieldsValue({
                                followPay: formattedValue,
                              })
                            } else {
                              console.log("22")
                              setFormData({
                                ...formData,
                                followPay: parseFloat(inputValue),
                              });
                              form.setFieldsValue({
                                followPay: inputValue,
                              })
                            }

                          }}
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        label='เงินต้น : '
                        name="approvedLoanAmount"
                        rules={[
                          {
                            required: true,
                            message: 'Please input your approvedLoanAmount!',
                          },
                        ]}
                      >
                        <Input
                          style={{
                            width: "100%",
                          }}
                          name="approvedLoanAmount"
                          onChange={(e) => {
                            let inputValue = e.target.value;
                            console.log("inputValue", inputValue)
                            if (inputValue.length >= 4) {
                              console.log("11")
                              var rawValue = inputValue.replace(/,/g, ''); // Remove existing commas
                              let intValue = parseInt(rawValue);
                              let formattedValue = intValue >= 1000 ? currencyFormatOne1(intValue) : rawValue;
                              setFormData({
                                ...formData,
                                approvedLoanAmount: formattedValue.toString().replace(/,/g, ''),
                              });
                              form.setFieldsValue({
                                approvedLoanAmount: formattedValue,
                              })
                            } else {
                              console.log("22")
                              setFormData({
                                ...formData,
                                approvedLoanAmount: parseFloat(inputValue),
                              });
                              form.setFieldsValue({
                                approvedLoanAmount: inputValue,
                              })
                            }

                          }}
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item>

                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        label='จำนวนงวด : '
                        name="approvedLoanTerm"
                        rules={[
                          {
                            required: true,
                            message: 'Please input your approvedLoanTerm!',
                          },
                        ]}
                      >
                        <span>
                          <Select
                            showSearch
                            style={{ height: "40px" }}
                            name="approvedLoanTerm"
                            placeholder={formData.approvedLoanTerm}
                            onChange={handleLoanTerm}
                            options={loanTermMid}
                          />
                        </span>
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        label='ถึงงวดที่ : '
                        name="approvedLoanTermTo"
                        rules={[
                          {
                            required: true,
                            message: 'Please input your approvedLoanTermTo!',
                          },
                        ]}

                      >

                        <span>
                          <Select
                            showSearch
                            style={{ height: "40px" }}
                            name="approvedLoanTermTo"
                            placeholder={formData.approvedLoanTermTo}
                            onChange={handleLoanTermTo}
                            options={loanTermMid}
                          />
                        </span>
                      </Form.Item>
                    </div>
                  </aside>
                </Col>

                <Col span={22}>
                  <Form.Item
                    label='หมายเหตุ :  '
                    name="Memo"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your Memo!',
                      },
                    ]}
                  >
                    <TextArea
                      style={{
                        width: "100%",
                        marginBottom: "20px",
                      }}
                      placeholder="กรอกข้อมูลหมายเหตุ"
                      type="text"
                      name="Memo"
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          Memo: e.target.value,
                        })
                      }
                    />
                  </Form.Item>
                </Col>

                <Col span={22} className="center">
                  <b style={{ color: "red" }}>
                    **หมายเหตุจากระบบ:
                    ไม่จำเป็นต้องใส่ค่าเข้าไปทุกช่องที่ไม่ได้แก้ไขระบบจะบันทึกค่าเดิม
                  </b>
                </Col>
              </Row>
              <>
                <Divider></Divider>
                <Row justify={"center"}>
                  <Space>
                    <Button
                      style={{
                        background: "red",
                        color: "white",
                      }}
                      onClick={() => {
                        handleClose();
                        console.log("ปิด modal");
                      }}
                    >
                      ยกเลิก
                    </Button>
                    {
                      formData.newDamt !== "" &&
                        formData.approvedLoanAmount !== "" &&
                        formData.followPay !== "" &&
                        formData.approvedLoanTerm &&
                        formData.Memo !== "" &&
                        formData.approvedLoanTermTo &&
                        formData.approvedLoanTerm <= formData.approvedLoanTermTo
                        ?
                        (
                          <Button
                            style={{
                              background: "green",
                              color: "white",
                            }}
                            onClick={() => {
                              if (formData.approvedLoanTerm > formData.approvedLoanTermTo) {
                                message.error("โปรดกดเลือกจำนวนงวดและจำนวนงวดถึงใหม่อีกครั้ง");
                              } else if (
                                // !formData.newDamt || !formData.approvedLoanAmount || !formData.Memo
                                formData.newDamt === null ||
                                formData.approvedLoanAmount === null ||
                                formData.newDamt === undefined ||
                                formData.approvedLoanAmount === undefined ||
                                Number.isNaN(formData.newDamt) ||
                                Number.isNaN(formData.approvedLoanAmount) ||
                                formData.newDamt < 0 ||
                                formData.approvedLoanAmount < 0 ||
                                formData.Memo === ""
                              ) {
                                message.error("เช็คความถูกต้องของข้อมูล");
                              } else {
                                showConfirm();
                              }
                            }}
                          >
                            บันทึก
                          </Button>
                        ) : null}
                  </Space>
                </Row>
              </>
            </>
          </Form>
        </Spin>
      </Modal >
    </>
  );
};

export default EditApproved;
