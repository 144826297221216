import { Button, Card, Col, DatePicker, Divider, Form, Modal, Space, Row, notification } from 'antd'
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import axios from 'axios';
import { editredate } from '../../file_mid/all_api';

function ModalEditDate({ open, close, loanRe, dataFromTable, setResetPage }) {

    const [api, contextHolder] = notification.useNotification();
    const [form] = Form.useForm()
    const [sendData, setSendData] = useState({
        reLoanId: loanRe.reLoanId,
        typeLoan: dataFromTable.typeLoan
    })

    useEffect(() => {
        form.setFieldsValue({
            newOfferDate: dayjs(loanRe.newOfferDate)
        })
    }, [])

    const successSend = (placement) => {
        api.success({
            message: `บันทึกสำเร็จ`,
            placement,
        });
    };

    const handleClose = () => {
        close(false)
    }

    const handleSubmit = async () => {
        console.log("Submit")
        await axios.put(editredate, sendData)
            .then(res => {
                if (res.status === 200) {
                    successSend("top")
                    setResetPage(dayjs())
                    setTimeout(() => {
                        handleClose()
                    }, 1000)
                }
            }).catch((err) => {
                console.log("err", err)
            })
    }

    const handleEditDate = (date, dateString) => {
        // console.log(date, dateString)
        setSendData({ ...sendData, newOfferDate: date })
    }

    return (
        <Modal open={open} onCancel={handleClose} closable={false} footer={[null]}>
            <Form form={form} onFinish={handleSubmit}>
                <Card>
                    <Row justify={'center'}>
                        <aside style={{ width: '90%' }}>
                            <b>
                                <Form.Item name="newOfferDate" label="วันที่นำเคสกลับมารีใหม่ได้" style={{ margin: 0 }}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}
                                >
                                    <DatePicker name='newOfferDate' style={{ width: "100%", height: "40px" }} onChange={handleEditDate}></DatePicker>
                                </Form.Item>
                            </b>
                        </aside>
                    </Row>
                </Card>
                <Divider style={{ margin: 5 }} />
                <Col span={24} style={{ textAlign: 'center' }}>
                    <Space>
                        <Button type='primary' danger onClick={handleClose}>ยกเลิก</Button>
                        <Button type='primary' htmlType='submit' style={{ backgroundColor: 'green' }}>บันทึก</Button>
                    </Space>
                </Col>
            </Form>
            {contextHolder}
        </Modal>
    )
}

export default ModalEditDate