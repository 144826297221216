import React, { useState, useEffect } from 'react'
import { Modal, Row, Col, Radio, Space, Spin, Divider, Card } from 'antd'
import axios from 'axios';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { addCustomer } from '../../../redux/Customer';
import { addCar } from '../../../redux/Car';
import { addAddress } from '../../../redux/Address';
import { addPhone } from '../../../redux/Phone';
import { addGuarantor } from '../../../redux/Guarantor';
// import EditGuarantor from './EditGuarantor';
import EditCLD from './EditCLD';
import EditCareer from './EditCareer';
import EditNewCustomer from './EditNewCustomer';
import DescriptionAll from './DescriptionAll';
import EditOther from './EditOther';
import EditGuarantorNEW from './EditGuarantorNEW';
import EditBroker from './EditBroker';
import { findcarmk } from '../../file_mid/all_api';

function MainReEdit({ open, close, dataFromTable }) {
    const branch = localStorage.getItem('branch');
    const [getData, setGetData] = useState();
    const [count, setCount] = useState(0)
    const [resetPage, setResetPage] = useState(dayjs())
    const [loading, setLoading] = useState(false)
    const [notPassData, setNotPassData] = useState()
    const [saveResendNote, setSaveResendNote] = useState()
    const [countResendNote, setCountResendNote] = useState(0)
    const [notes, setNotes] = useState([])
    const [checkPreNotPass, setCheckPreNotPass] = useState(false)
    const [checkLoan, setCheckLoan] = useState(true)
    const [openProductPage, setOpenProductPage] = useState(true)

    const dispatch = useDispatch()

    var length = notes?.length
    var lastindex = length - 1

    useEffect(() => {
        setLoading(true)
        loadDataCar()
    }, [resetPage, count])

    useEffect(() => {
        getData?.cars?.img?.map((e, index) => {
            var PreAaproveSplit = []
            PreAaproveSplit = e.pathImage.split("/")
            if (PreAaproveSplit[3] === "13") {
                setCheckPreNotPass(true)
            }
        })
    }, [getData])


    const loadDataCar = async () => {
        await axios.post(findcarmk, { carId: dataFromTable.carId, customerId: dataFromTable.customerId })
            .then(res => {
                if (res.status === 200) {
                    console.log("res.data", res.data)
                    if (res.data.cars.carLoanDetails.productType === 'p-loan') {
                        setCheckLoan(false)
                    }
                    if (res.data.cars.carLoanDetails.notPass.loanId !== 0) {
                        setNotPassData(res.data.cars.carLoanDetails.notPass)
                        if (res.data.cars.carLoanDetails.notPass.npLoan === false ||
                            res.data.cars.carLoanDetails.notPass.npProduct === false ||
                            res.data.cars.carLoanDetails.notPass.npOldLoan === false) {
                            setOpenProductPage(false)
                        }
                    }
                    if (res.data.cars.carLoanDetails.noteResend.loanId !== 0) {
                        if (countResendNote === 0) {
                            setSaveResendNote(res.data.cars.carLoanDetails.noteResend)
                            setCountResendNote(1)
                        } else {
                            setSaveResendNote({ ...saveResendNote, loanId: res.data.cars.carLoanDetails.loanId, resendDate: dayjs(), typeLoan: "car" })
                        }
                    } else {
                        setSaveResendNote({ ...saveResendNote, loanId: res.data.cars.carLoanDetails.loanId, resendDate: dayjs(), typeLoan: "car" })
                    }
                    setGetData(res.data)
                    setNotes(res.data.cars.carLoanDetails.notes)
                    setLoading(false)
                }
            }).catch((err) => {
                console.log("err", err)
            })
    }

    const Onclose = () => {
        close(false);
        dispatch(addCustomer())
        dispatch(addCar())
        dispatch(addAddress())
        dispatch(addPhone())
        dispatch(addGuarantor())
    };

    return (
        <div>
            <Modal title="เพิ่ม/แก้ไขข้อมูล" open={open} onCancel={Onclose} width={1100} footer={null}>
                <Space
                    direction="vertical"
                    size="middle"
                    style={{
                        display: 'flex',
                    }}>
                    <Spin tip="Loading..." spinning={loading} >
                        {
                            branch !== 'MIT' && branch !== 'S4' && branch !== 'UD' && branch !== 'LEX' ?
                                <>
                                    <Row>
                                        {
                                            dataFromTable.approvalStatus === 21 ?
                                                <Col span={24} style={{ textAlign: 'center' }}>
                                                    <Radio.Group value={count} onChange={(e) => setCount(e.target.value)}>
                                                        <Radio.Button value={12}>ข้อมูลคนค้ำประกัน</Radio.Button>
                                                        <Radio.Button value={16}>เอกสารเพิ่มเติม</Radio.Button>
                                                        <Radio.Button value={17}>รายละเอียด</Radio.Button>
                                                    </Radio.Group>
                                                    <Divider />
                                                    <Row justify={'left'}><Col span={24} style={{ color: "red" }}><b><u>อนุมัติแบบมีเงื่อนไข!</u></b></Col></Row>
                                                    <Card
                                                        key={lastindex}
                                                        style={{ textAlign: 'left' }}>
                                                        <>
                                                            <div style={{ marginBottom: 0 }}>
                                                                <span>สาเหตุ : </span>
                                                                <b>{notes[lastindex]?.cause}</b>
                                                            </div>
                                                            <div style={{ marginBottom: 0 }}>
                                                                <span>หมายเหตุ : </span>
                                                                <b>{notes[lastindex]?.note}</b>
                                                            </div>
                                                            <div style={{ marginBottom: 0 }}>
                                                                <span>ผู้สร้างหมายเหตุ : </span>
                                                                <b>{notes[lastindex]?.noteBy}</b>
                                                            </div>
                                                            <div style={{ marginBottom: 0 }}>
                                                                <span>สถานะที่ทำการหมายเหตุ : </span>
                                                                <b>{notes[lastindex]?.status}</b>
                                                            </div>
                                                        </>
                                                    </Card>
                                                    <Divider />
                                                </Col>
                                                :
                                                <Col span={24} style={{ textAlign: 'center' }}>
                                                    <Radio.Group value={count} onChange={(e) => setCount(e.target.value)}>
                                                        <Radio.Button disabled={notPassData?.npCustomer} value={11}>ข้อมูลส่วนตัว</Radio.Button>
                                                        <Radio.Button disabled={notPassData?.npGuarantor} value={12}>ข้อมูลคนค้ำประกัน</Radio.Button>
                                                        <Radio.Button disabled={openProductPage} value={13}>รายละเอียดสินค้า</Radio.Button>
                                                        <Radio.Button disabled={notPassData?.npCareer} value={14}>อาชีพ</Radio.Button>
                                                        <Radio.Button disabled={checkLoan} value={15}>รายละเอียดค่าแนะนำ</Radio.Button>

                                                        <Radio.Button value={16}>เอกสารเพิ่มเติม</Radio.Button>
                                                        <Radio.Button value={17}>รายละเอียด</Radio.Button>
                                                    </Radio.Group>
                                                </Col>
                                        }
                                    </Row>
                                    {count === 11 && (<EditNewCustomer getData={getData} close={Onclose} page={count} sendback={setResetPage} notPassData={notPassData} saveResendNote={saveResendNote} setSaveResendNote={setSaveResendNote} checkPreNotPass={checkPreNotPass} />)}
                                    {count === 12 && (<EditGuarantorNEW getData={getData} close={Onclose} page={count} sendback={setResetPage} notPassData={notPassData} saveResendNote={saveResendNote} setSaveResendNote={setSaveResendNote} checkPreNotPass={checkPreNotPass} />)}
                                    {count === 13 && (<EditCLD getData={getData} close={Onclose} page={count} sendback={setResetPage} notPassData={notPassData} saveResendNote={saveResendNote} setSaveResendNote={setSaveResendNote} checkPreNotPass={checkPreNotPass} />)}
                                    {count === 14 && (<EditCareer getData={getData} close={Onclose} page={count} sendback={setResetPage} notPassData={notPassData} saveResendNote={saveResendNote} setSaveResendNote={setSaveResendNote} checkPreNotPass={checkPreNotPass} />)}
                                    {count === 15 && (<EditBroker getData={getData} close={Onclose} page={count} sendback={setResetPage} notPassData={notPassData} saveResendNote={saveResendNote} setSaveResendNote={setSaveResendNote} checkPreNotPass={checkPreNotPass} />)}
                                    {count === 16 && (<EditOther getData={getData} close={Onclose} page={count} sendback={setResetPage} saveResendNote={saveResendNote} setSaveResendNote={setSaveResendNote} checkPreNotPass={checkPreNotPass} />)}
                                    {count === 17 && (<DescriptionAll getData={getData} close={Onclose} page={count} sendback={setResetPage} saveResendNote={saveResendNote} setSaveResendNote={setSaveResendNote} />)}
                                </>
                                :
                                <>
                                    <Row>
                                        {
                                            dataFromTable.approvalStatus === 21 ?
                                                <Col span={24} style={{ textAlign: 'center' }}>
                                                    <Radio.Group value={count} onChange={(e) => setCount(e.target.value)}>
                                                        <Radio.Button value={2}>ข้อมูลคนค้ำประกัน</Radio.Button>
                                                        <Radio.Button value={5}>เอกสารเพิ่มเติม</Radio.Button>
                                                        <Radio.Button value={6}>รายละเอียด</Radio.Button>
                                                    </Radio.Group>
                                                    <Divider />
                                                    <Row justify={'left'}><Col span={24} style={{ color: "red" }}><b><u>อนุมัติแบบมีเงื่อนไข!</u></b></Col></Row>
                                                    <Card
                                                        key={lastindex}
                                                        style={{ textAlign: 'left' }}>
                                                        <>
                                                            <div style={{ marginBottom: 0 }}>
                                                                <span>สาเหตุ : </span>
                                                                <b>{notes[lastindex]?.cause}</b>
                                                            </div>
                                                            <div style={{ marginBottom: 0 }}>
                                                                <span>หมายเหตุ : </span>
                                                                <b>{notes[lastindex]?.note}</b>
                                                            </div>
                                                            <div style={{ marginBottom: 0 }}>
                                                                <span>ผู้สร้างหมายเหตุ : </span>
                                                                <b>{notes[lastindex]?.noteBy}</b>
                                                            </div>
                                                            <div style={{ marginBottom: 0 }}>
                                                                <span>สถานะที่ทำการหมายเหตุ : </span>
                                                                <b>{notes[lastindex]?.status}</b>
                                                            </div>
                                                        </>
                                                    </Card>
                                                    <Divider />
                                                </Col>
                                                :
                                                <Col span={24} style={{ textAlign: 'center' }}>
                                                    <Radio.Group value={count} onChange={(e) => setCount(e.target.value)}>
                                                        <Radio.Button disabled={notPassData?.npCustomer} value={1}>ข้อมูลส่วนตัว</Radio.Button>
                                                        <Radio.Button disabled={notPassData?.npGuarantor} value={2}>ข้อมูลคนค้ำประกัน</Radio.Button>
                                                        <Radio.Button disabled={openProductPage} value={3}>รายละเอียดสินค้า</Radio.Button>
                                                        <Radio.Button disabled={notPassData?.npCareer} value={4}>อาชีพ</Radio.Button>
                                                        {/* <Radio.Button value={1}>ข้อมูลส่วนตัว</Radio.Button>
                                            <Radio.Button value={2}>ข้อมูลคนค้ำประกัน</Radio.Button>
                                            <Radio.Button value={3}>รายละเอียดสินค้า</Radio.Button>
                                            <Radio.Button value={4}>อาชีพ</Radio.Button> */}

                                                        <Radio.Button value={5}>เอกสารเพิ่มเติม</Radio.Button>
                                                        <Radio.Button value={6}>รายละเอียด</Radio.Button>
                                                    </Radio.Group>
                                                </Col>
                                        }
                                    </Row>
                                    {count === 1 && (<EditNewCustomer getData={getData} close={Onclose} page={count} sendback={setResetPage} notPassData={notPassData} saveResendNote={saveResendNote} setSaveResendNote={setSaveResendNote} checkPreNotPass={checkPreNotPass} />)}
                                    {count === 2 && (<EditGuarantorNEW getData={getData} close={Onclose} page={count} sendback={setResetPage} notPassData={notPassData} saveResendNote={saveResendNote} setSaveResendNote={setSaveResendNote} checkPreNotPass={checkPreNotPass} />)}
                                    {/* {count === 99 && (<EditGuarantor getData={getData} close={Onclose} page={count} sendback={setResetPage} notPassData={notPassData} />)} */}
                                    {count === 3 && (<EditCLD getData={getData} close={Onclose} page={count} sendback={setResetPage} notPassData={notPassData} saveResendNote={saveResendNote} setSaveResendNote={setSaveResendNote} checkPreNotPass={checkPreNotPass} />)}
                                    {count === 4 && (<EditCareer getData={getData} close={Onclose} page={count} sendback={setResetPage} notPassData={notPassData} saveResendNote={saveResendNote} setSaveResendNote={setSaveResendNote} checkPreNotPass={checkPreNotPass} />)}
                                    {count === 5 && (<EditOther getData={getData} close={Onclose} page={count} sendback={setResetPage} saveResendNote={saveResendNote} setSaveResendNote={setSaveResendNote} checkPreNotPass={checkPreNotPass} />)}
                                    {count === 6 && (<DescriptionAll getData={getData} close={Onclose} page={count} sendback={setResetPage} saveResendNote={saveResendNote} setSaveResendNote={setSaveResendNote} />)}
                                </>
                        }
                    </Spin>
                </Space>
            </Modal>
            {/* {contextHolder} */}
        </div>
    )
}

export default MainReEdit