import React, { useState, useEffect } from 'react'
import { Form, Modal, Row, Col, Space, Card, Button, Tag, Divider, Checkbox, message, notification, Select, InputNumber, Image } from 'antd'
import dayjs from 'dayjs';
import axios from "axios";
import TextArea from 'antd/es/input/TextArea';
import NoteRe from "../../approval_rate_car/modals/NoteRe";
import ModalMoreNoteRe from './ModalMoreNoteRe';
import ModalAddLandRe from './ModalAddLandRe';
import ModalShowImagesRe from '../../ModalShowImageSlide/ModalShowImages'
import { checklandpntest, shownotepn, approvalPriceNewRe, loadlandrepn, getImagess } from '../../file_mid/all_api';

export default function ModalInfoLandRe({ open, dataFromTable, close }) {

  const token = localStorage.getItem('token');
  const us = localStorage.getItem('username')
  const [dataLand, setDataLand] = useState(dataFromTable);
  const [newdataLand, setNewDataLand] = useState([]);
  const [options, setOptions] = useState([]);
  const [note, setNote] = useState({});
  const [form] = Form.useForm()
  const currentTime = dayjs();
  //const delayedTime = currentTime.add(7, 'hour');
  const formattedTime = currentTime.format('YYYY-MM-DDTHH:mm:ssZ');
  const [isModalCancelCaseRe, setIsModalCancelCaseRe] = useState(false);
  const [allDatas, setAllDatas] = useState();
  const [modalnote, setModalNote] = useState(false)
  const [foundInfo, setFoundInfo] = useState(false)
  const [showOldData2, setShowOldData2] = useState([])
  const [isModalShowNote, setisModalShowNote] = useState(false)
  const [isModalAddLand, setIsModalAddLand] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [dataEdit, setDataEdit] = useState()
  const [dataEditIndex, setDataEditIndex] = useState()
  const [checkEdit, setCheckEdit] = useState(false)
  const [test, settest] = useState()
  const [result, setResult] = useState(null);
  const [mainLand, setMainLand] = useState();
  // const [testmain, setTestmain] = useState([]);
  const [api, contextHolder] = notification.useNotification();
  const [openShowImage, setOpenShowImage] = useState(false);
  const [imageBlobzz, setImageBlobzz] = useState([]);
  // const [currentIndex, setCurrentIndex] = useState(0);
  // แยก type image car
  const [, setCarImage1] = useState([]);
  const [, setCarImage3] = useState([]);
  const [, setCarImage10] = useState([]);
  const [, setCarImage11] = useState([]);

  const [carshowMadol, setCarShowModal] = useState([]); //send to Modal Show Image

  const { confirm } = Modal


  useEffect(() => {
    console.log("GG-EZ", dataFromTable)
    loadDataDup()
    loadMixData()
  }, [])

  useEffect(() => {
    loadImage1()
    loadNoteNameRe()

  }, [showOldData2])

  useEffect(() => {
    if (dataLand?.approvalStatus === 12) {
      if (result === null) {
        console.log("zz")
        newdataLand.map((e, index) => {
          // console.log("e", e)
          form.setFieldsValue({
            [`storyLand${index}`]: e?.storyLand,
          })
          // console.log("index", index)
          if (e?.land?.approvalLandPrice.note === "") {
            form.setFieldsValue({
              [`minPrice${index}`]: e?.land?.approvalLandPrice?.minPrice ? e?.land?.approvalLandPrice?.minPrice : 0,
              [`maxPrice${index}`]: e?.land?.approvalLandPrice?.maxPrice ? e?.land?.approvalLandPrice?.maxPrice : 0,
              // [`note${index}`]: null,
            })
          } else {
            form.setFieldsValue({
              [`minPrice${index}`]: e?.land?.approvalLandPrice?.minPrice ? e?.land?.approvalLandPrice?.minPrice : 0,
              [`maxPrice${index}`]: e?.land?.approvalLandPrice?.maxPrice ? e?.land?.approvalLandPrice?.maxPrice : 0,
              [`note${index}`]: e?.land?.approvalLandPrice?.note,
            })
          }
        })
      } else {
        console.log("yy")
        form.setFieldsValue({
          minPrice: result?.approval?.minPrice ? result?.approval?.minPrice : 0,
          maxPrice: result?.approval?.maxPrice ? result?.approval?.maxPrice : 0,
          note: result?.approval?.note,
        })
      }
    }
  }, [newdataLand, result])

  // console.log("dataLand", dataLand)

  const makeOldMinMax = async (cutData) => {
    for (let i = cutData?.length - 1; i >= 0; i--) {
      if (cutData[i]?.approval?.id === 0) {
        // หากเจอค่า 0 ให้ทำต่อ
        continue;
      } else {
        // เจอค่าที่ไม่ใช่ 0 ให้เก็บค่านั้นและหยุด
        setResult(cutData[i]);
        return cutData[i]
        // break;
      }
    }
    return null;
  }

  // console.log("dataLand", dataLand)
  // console.log("newdataLand", newdataLand)

  const onApprove = async () => {
    var checkNumber = 0
    if (parseInt(dataLand?.minPrice) > parseInt(dataLand?.maxPrice)) {
      checkNumber = 1
    }
    if (checkNumber === 1) {
      alert("ยอดสูงสุดน้อยกว่ายอดต่ำสุด กรุณาตรวจสอบ")
    } else {
      Modal.confirm({
        title: 'บันทึกข้อมูล',
        content: 'คุณต้องการบันทึกข้อมูลใช่หรือไม่ ?',
        onOk() {
          if (dataLand?.approvalStatus === 12) {
            // console.log("1")
            // const dataLandMap = [{ ...dataLand }]
            const updatedDataLand = {
              customerId: dataLand.customerId,
              reLoanId: dataLand.reLoanId,
              landId: dataLand.landId,
              approvalDate: formattedTime,
              maxPrice: dataLand.maxPrice,
              minPrice: dataLand.minPrice,
              priceStatus: true,
              inputPriceDate: formattedTime,
              proposalPriceBy: us,
              note: dataLand.note,
              ISSUNO: dataLand.ISSUNO,
              username: newdataLand[0].land?.landLoanDetailsRe?.proposalBy,
              productLoanType: newdataLand[0].land?.landLoanDetailsRe?.productLoanLandType
            };
            // console.log("updatedDataLand", updatedDataLand)
            handleConfirm(updatedDataLand)
          }
        },
        onCancel() {
          message.error('ยกเลิก');
        },
      });
    }
  }

  const loadNoteNameRe = async () => {
    await axios.get(shownotepn)
      // await axios.get(shownotepnre)
      .then(res => {
        if (res.status === 200) {
          // console.log("loadNoteName", res.data)
          setOptions(res.data)
        }
      }).catch((err) => console.log(err))
  };

  const handleChange = (value, index) => {
    setNote(`${value}`)
    setDataLand({ ...dataLand, note: `${value}` })
  }

  // const handleNoteMultimode = (value, index) => {
  //   setDataLand({ ...dataLand, note: `${value}` })
  //   // if (multiMode) {
  //   //     setMultiModeData(prevData => prevData.map((item, i) => i === index ? { ...item, approval: { ...item.approval, note: `${value}` } } : item));
  //   // } else {
  //   //     setNote(`${value}`)
  //   //     setDataModalEdit({ ...dataModalEdit, note: `${value}` })
  //   //     setMultiModeData(prevData => prevData.map((item, i) => i === index ? { ...item, approval: { ...item.approval, note: `${value}` } } : item));
  //   // }
  // }

  const handleviewImg = (index) => {
    localStorage.setItem('viewPN', JSON.stringify(showOldData2[index]))
    window.open('/view-pn', '_blank');
  }

  const loadMixData = async () => {
    await axios.post(loadlandrepn, { customerId: dataFromTable.customerId, reLoanId: dataFromTable.reLoanId, landId: dataFromTable.landId })
      .then(res => {
        if (res.status === 200) {
          const landRe = [{ ...res.data }]
          setNewDataLand(landRe)
          setImageBlobzz(res.data.land.img)
          setAllDatas(res.data)
          // console.log("res.data", res.data)
          // loadImage1(res.data)
        }
      }).catch((err) => {
        console.log("err", err)
      })
  }

  const loadImage1 = async () => {
    // console.log("value", value);

    newdataLand?.map((data, i) => {
      // console.log("data", data, i);
      // สร้างตัวแปรเพื่อเก็บรูปภาพแต่ละประเภท
      var carimg1 = [];
      var carimg3 = [];
      var carimg10 = [];
      var carimg11 = [];

      data?.img?.map((imgs, j) => {
        // console.log("imgs", imgs, j);

        if (imgs?.type === 1) {
          carimg1.push(imgs);
        } else if (imgs?.type === 3) {
          carimg3.push(imgs);
        } else if (imgs?.type === 10) {
          carimg10.push(imgs);
        } else if (imgs?.type === 11) {
          carimg11.push(imgs);
        }
      });

      // เซ็ตค่ารูปภาพให้กับตัวแปร state ที่เก็บรูปภาพแต่ละประเภท
      setCarImage1(carimg1);
      setCarImage3(carimg3);
      setCarImage10(carimg10);
      setCarImage11(carimg11);
    });
  };

  const loadDataDup = async () => {
    await axios.post(checklandpntest, { numberLand: dataFromTable.numberLand, provinces: dataFromTable.province, district: dataFromTable.district })
      .then(async res => {
        if (res.status === 200) {
          // console.log("res.data289", res.data)
          var cutData = res.data
          if (res?.data?.length > 1) {
            setFoundInfo(true)
            cutData.forEach((e, index) => {
              if (e.landLoanDetails.landLoanId === dataFromTable.landLoanId) {
                cutData.splice(index, 1);
              }
            });
            setShowOldData2(cutData)
            let resultss = await makeOldMinMax(cutData);
            // console.log("resultss",resultss)
            if (resultss) {
              setResult(resultss)
              setDataLand({
                ...dataLand,
                maxPrice: resultss?.approval.maxPrice,
                minPrice: resultss?.approval.minPrice,
                note: resultss?.approval.note,
              })
            }
          }

        }
      }).catch((err) => {
        console.log("err", err)
      })
  }

  const handleMoreNote = (value) => {
    settest(value)
    setisModalShowNote(true)
  };
  const CloseMoreNote = () => {
    setisModalShowNote(false)
  };

  const handleConfirm = async (updatedDataLand) => {
    // var checkNumber = 0
    // if (dataLand.minPrice > dataLand.maxPrice) {
    //   checkNumber = 1
    // }

    // if (checkNumber === 1) {
    //   alert("ยอดสูงสุดน้อยกว่ายอดต่ำสุด กรุณาตรวจสอบ")
    // } else {
    // console.log("updatedDataLand",updatedDataLand)
    await axios.post(approvalPriceNewRe, updatedDataLand)
      .then(res => {
        if (res.status === 200) {
          message.success('บันทึกสำเร็จ');
          close(false);
        } else if (res.status === 204) {
        }
      })
      .catch(err => {
        console.log(err);
      });
    // }
  };

  const handleOk = () => {
    close(false);
  };
  const handleCancel = () => {
    close(false);
  };
  const handleCancel2 = (value) => {
    setModalNote(value)
  };
  const handleCloseAllModal = (value) => {
    setModalNote(value)
    close(value)
  }

  const currencyFormatOne = (amount) => {
    return Number(amount)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, '$&,')
  }

  const handleCancelCase = () => {
    setIsModalCancelCaseRe(true)
  }

  const handleEditLand = async (value, index) => {
    setCheckEdit(true)
    setDataEdit(value)
    setDataEditIndex(index)
    setIsModalAddLand(true)
  }

  const funcEdit = (value, index) => {
    let swapData = [...newdataLand]
    swapData[index] = value
    setNewDataLand(swapData);
  }

  const funcAdd = (value) => {
    setNewDataLand(pre => [...pre, { ...value }]);
  }

  const handleDeleteLand = (value, index) => {
    confirm({
      title: 'ต้องการที่จะยกเลิกจดรวมข้อมูลหรือไม่...?',
      content: 'กด OK เพื่อยืนยันการยกเลิกจดรวม',
      onOk() {
        const deletearray = [...newdataLand]
        deletearray.splice(index, 1)
        setNewDataLand(deletearray)
      },
      onCancel() {
      },
    });
  };

  const handleSelectMainLand = (value, index) => {
    // console.log("value", value)
    setMainLand(index)
    const updatedLandMain = newdataLand.map((item, index2) => {
      if (index2 === index) {
        return { ...item, landMain: true };
      } else {
        return { ...item, landMain: false };
      }
    });
    setNewDataLand(updatedLandMain);
  };

  let color

  if (dataLand?.approvalStatus === 16) {
    color = "gold"
  }
  if (dataLand?.approvalStatus === 12) {
    color = "gold"
  }
  if (dataLand?.approvalStatus === 14) {
    color = "orange"
  }
  if (dataLand?.approvalStatus === 18) {
    color = "cyan"
  }
  if (dataLand?.approvalStatus === 19) {
    color = "geekblue"
  }
  if (dataLand?.approvalStatus === 13) {
    color = "geekblue"
  }
  if (dataLand?.approvalStatus === 2) {
    color = "lime"
  }
  if (dataLand?.approvalStatus === 3) {
    color = "green"
  }
  if (dataLand?.approvalStatus === 21) {
    color = "green"
  }
  if (dataLand?.approvalStatus === 27) {
    color = "green"
  }
  if (dataLand?.approvalStatus === 22) {
    color = "red"
  }
  if (dataLand?.approvalStatus === 20) {
    color = "red"
  }
  if (dataLand?.approvalStatus === 25) {
    color = "red"
  }
  if (dataLand?.approvalStatus === 15) {
    color = "red"
  }


  const renderOldData = ({ item, index }) => {
    // console.log("item440", item)
    var note = item.notes?.length;
    var lastIndex = note - 1;

    let color

    if (item?.landLoanDetails?.approvalStatus === 16) {
      color = "gold"
    }
    if (item?.landLoanDetails?.approvalStatus === 18) {
      color = "gold"
    }
    if (item?.landLoanDetails?.approvalStatus === 24) {
      color = "gold"
    }
    if (item?.landLoanDetails?.approvalStatus === 12) {
      color = "gold"
    }
    if (item?.landLoanDetails?.approvalStatus === 19) {
      color = "geekblue"
    }
    if (item?.landLoanDetails?.approvalStatus === 14) {
      color = "green"
    }
    if (item?.landLoanDetails?.approvalStatus === 20) {
      color = "red"
    }
    if (item?.landLoanDetails?.approvalStatus === 15) {
      color = "red"
    }
    if (item?.landLoanDetails?.approvalStatus === 2) {
      color = "lime"
    }
    if (item?.landLoanDetails?.approvalStatus === 3) {
      color = "green"
    }
    if (item?.landLoanDetails?.approvalStatus === 21) {
      color = "green"
    }
    if (item?.landLoanDetails?.approvalStatus === 22) {
      color = "red"
    }
    if (item?.landLoanDetails?.approvalStatus === 25) {
      color = "red"
    }
    if (item?.landLoanDetails?.approvalStatus === 27) {
      color = "green"
    }
    if (item?.landLoanDetails?.approvalStatus === 17) {
      color = "orange"
    }
    if (item?.landLoanDetails?.approvalStatus === 13) {
      color = "gold"
    }
    return (
      <>
        <Row gutter={32} justify={'center'}>
          <Col className='gutter-row' span={24}>
            <>
              <Card key={index} style={{ width: '100%', textAlign: 'left' }}>
                <div style={{ marginBottom: 0, textAlign: 'right' }}>
                  <Tag color={color}>{item?.landLoanDetails?.approvalStatus}</Tag>
                </div>
                {
                  item.landLoanDetails.landLoanId === dataFromTable.landLoanId ?
                    <>
                      <div style={{ marginBottom: 0, textAlign: 'right' }}>
                        <Tag color="green">เคสปัจจุบัน</Tag>
                      </div>
                    </>
                    :
                    <>
                      <div style={{ marginBottom: 0, textAlign: 'right' }}>
                        <Tag color="red">เคสที่เคยเสนอมาแล้ว</Tag>
                      </div>
                    </>
                }
                <Divider orientation="left">วันที่การเสนอ</Divider>
                <div style={{ marginBottom: 0 }}>
                  <span>เสนอเมื่อ : </span>
                  <b>{dayjs(item.landInput).format("DD/MM/YYYY HH:mm")}</b>
                </div>
                <div style={{ marginBottom: 0 }}>
                  <span>ผู้เสนอเคส : </span>
                  <b>{item.landLoanDetails.proposalBy}</b>
                </div>
                <Divider orientation="left">รายละเอียดที่ดิน</Divider>
                <div style={{ marginBottom: 0 }}>
                  <span>ประเภทที่ดิน : </span>
                  <b>{item.productTypeLand === 1 ? "นส.3 ก." : "นส.4 จ."}</b>
                </div>
                <div style={{ marginBottom: 0 }}>
                  <span>เลขโฉนดที่ดิน : </span>{<b>{item?.numberLand}</b>}
                </div>
                <div style={{ marginBottom: 0 }}>
                  <span>เลขที่ดินหรือระวาง : </span>{<b>{item?.numberLandlawang}</b>}
                </div>
                <div style={{ marginBottom: 0 }}>
                  <span>จังหวัด : </span>{<b>{item?.provinces}</b>}
                </div>
                <div style={{ marginBottom: 0 }}>
                  <span>อำเภอ : </span>{<b>{item?.district}</b>}
                </div>
                <div style={{ marginBottom: 0 }}>
                  <span>พื้นที่ไร่ : </span>{<b>{item?.rai}</b>}
                  <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                    ไร่
                  </span>
                </div>
                <div style={{ marginBottom: 0 }}>
                  <span>พื้นที่งาน : </span>{<b>{item?.workArea}</b>}
                  <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                    งาน
                  </span>
                </div>
                <div style={{ marginBottom: 0 }}>
                  <span>พื้นที่ตารางวา : </span>{<b>{currencyFormatOne(item?.squareWaArea)}</b>}
                  <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                    บาท
                  </span>
                </div>
                <div style={{ marginBottom: 0 }}>
                  <span>ราคาประเมินที่ดิน : </span> {<b>{item?.landPrice}</b>}
                  <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                    บาท / ตารางวา
                  </span>
                </div>
                <div style={{ marginBottom: 0 }}>
                  <span>ราคารวม : </span> {<b>{item?.resultLandPrice}</b>}
                  <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                    บาท
                  </span>
                </div>
                <span>
                  <Button type='primary' onClick={() => handleviewImg(index)} >รูปเอกสาร</Button>
                  {/* <Button type='primary' onClick={handleviewImg} >รูปเอกสาร</Button> */}
                </span>
                <Divider orientation="left">หมายเหตุ</Divider>
                {
                  item?.landLoanDetails.approvalStatus === 13 || item?.landLoanDetails.approvalStatus === 15 ?
                    <>
                      <div style={{ marginBottom: 0 }}>
                        <span>สาเหตุ : </span>
                        <b>{item?.notes[lastIndex]?.cause}</b>
                      </div>
                      <div style={{ marginBottom: 0 }}>
                        <span>สถานะที่ทำการหมายเหตุ : </span>
                        <b>{item?.notes[lastIndex]?.status}</b>
                      </div>
                      <div style={{ marginBottom: 0 }}>
                        <span>ผู้สร้างหมายเหตุ : </span>
                        <b>{item?.notes[lastIndex]?.noteBy}</b>
                      </div>
                      <div style={{ marginBottom: 0 }}>
                        <span>เมื่อวันที่ : </span>
                        <b>{dayjs(item?.notes[lastIndex]?.noteDate).format("DD-MM-YYYY")}</b>
                      </div>
                      {
                        note > 1 ?
                          <>
                            <Button onClick={() => handleMoreNote(item.notes, index)}>เพิ่มเติม</Button>
                            {/* <Button onClick={((index) => handleMoreNote(index))}>เพิ่มเติม</Button> */}

                          </>
                          : null
                      }
                    </>
                    :
                    <>
                      {
                        item?.approval.id !== 0 ?
                          <>
                            <div style={{ marginBottom: 0 }}>
                              <span>หมายเหตุ : </span>{<b>{item?.approval.note}</b>}
                            </div>
                            <div style={{ marginBottom: 0 }}>
                              <span>ราคาต่ำสุด-สูงสุด : </span>{<b>{item?.approval.minPrice} - {item?.approval.maxPrice}</b>}
                            </div>
                            <div style={{ marginBottom: 0 }}>
                              <span>เคาะราคาโดย : </span>{<b>{item?.approval.proposalPriceBy}</b>}
                            </div>
                            <div style={{ marginBottom: 0 }}>
                              <span>เมื่อวันที่ : </span>{<b>{dayjs(item?.approval.inputPriceDate).format('DD-MM-YYYY')}</b>}
                            </div>
                          </>
                          :
                          <>
                            <div style={{ marginBottom: 0 }}>
                              <span style={{ color: "red" }}>*ยังไม่เคาะราคา!</span>
                            </div>
                          </>
                      }
                    </>
                }
              </Card>

            </>
          </Col>
        </Row>
      </>
    )

  }
  const renderHeader = ({ item, index }) => {
    // console.log("item164", item)
    return (
      <>
        <Row justify={'center'}>
          <Col span={12} style={{ textAlign: 'left' }}>
            <b>
              <div style={{ marginBottom: 0 }}>
                <span>เลขสัญญา : </span>{<b style={{ fontSize: '20px', lineHeight: '32px' }}>{item?.land?.ISSUNO}</b>}
              </div>
            </b>
            <b>
              <div style={{ marginBottom: 0 }}>
                <span>เกรด : </span>{<b style={{ fontSize: '20px', lineHeight: '32px' }}>{item?.land?.landLoanDetailsRe?.GRDCOD}</b>}
              </div>
            </b>
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <div style={{ margin: 0 }}>
              ชื่อการตลาดที่เสนอ : <Tag color={color}>{item?.land?.landLoanDetailsRe?.proposalBy}</Tag>
            </div>
            <div style={{ margin: 0 }}>
              สถานะ : <Tag color={color}>{item?.land?.landLoanDetailsRe?.approvalStatusTable?.name}</Tag>
            </div>
            <div style={{ margin: 0 }}>
              ประเภทขอกู้ : <Tag color={"geekblue"}>{item?.land?.landLoanDetailsRe?.productLoanLandType}</Tag>
            </div>
          </Col>
        </Row>

      </>
    )
  }

  const renderLandData = ({ item, index }) => {

    return (
      <>
        <Row gutter={32} justify={'center'}>
          <Col className='gutter-row' span={24}>
            <>
              <Divider orientation={'left'}><b><u>รายละเอียดที่ดิน ({item?.land?.numberLand})</u></b></Divider>
              <Row gutter={32} justify={'center'}>
                <aside style={{ width: '90%' }}>
                  <div style={{ marginBottom: 0 }}>
                    <span>กลุ่มสินค้า : </span>{<b>{item?.land?.landLoanDetailsRe?.productType}</b>}
                  </div>
                  <div style={{ marginBottom: 0 }}>
                    <span>ประเภทเอกสาร : </span>{<b>{item?.land?.productTypeLand === 1 ? "นส.3" : "นส.4"}</b>}
                  </div>
                  <div style={{ marginBottom: 0 }}>
                    <span>เลขโฉนดที่ดิน : </span>{<b>{item?.land?.numberLand}</b>}
                  </div>
                  <div style={{ marginBottom: 0 }}>
                    <span>เลขที่ดินหรือระวาง : </span>{<b>{item?.land?.numberLandLawang}</b>}
                  </div>
                  <div style={{ marginBottom: 0 }}>
                    <span>จังหวัด : </span>{<b>{item?.land?.province}</b>}
                  </div>
                  <div style={{ marginBottom: 0 }}>
                    <span>อำเภอ : </span>{<b>{item?.land?.district}</b>}
                  </div>
                  <div style={{ marginBottom: 0 }}>
                    <span>พื้นที่ไร่ : </span>{<b>{item?.land?.rai}</b>}
                    <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                      ไร่
                    </span>
                  </div>
                  <div style={{ marginBottom: 0 }}>
                    <span>พื้นที่งาน : </span>{<b>{item?.land?.workArea}</b>}
                    <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                      งาน
                    </span>
                  </div>
                  <div style={{ marginBottom: 0 }}>
                    <span>พื้นที่ตารางวา : </span>{<b>{currencyFormatOne(item?.land?.squareWaArea)}</b>}
                    <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                      บาท
                    </span>
                  </div>
                  <div style={{ marginBottom: 0 }}>
                    <span>ราคาประเมินที่ดิน : </span> {<b>{item?.land?.landPrice}</b>}
                    <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                      บาท / ตารางวา
                    </span>
                  </div>
                  <div style={{ marginBottom: 0 }}>
                    <span>ราคารวม : </span> {<b>{item?.land?.resultLandPrice}</b>}
                    <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                      บาท
                    </span>
                  </div>

                </aside>
                {
                  editMode ?
                    <>
                      {
                        index === 0 ?
                          null :
                          <>
                            <Space direction='vertical'>
                              <Button style={{ width: "100px", backgroundColor: "#DCD9D4" }} onClick={() => handleEditLand(newdataLand[index], index)}>เปลี่ยนที่ดิน</Button>
                              <Button style={{ width: "100px" }} type='primary' danger onClick={() => handleDeleteLand(newdataLand[index], index)}>ยกเลิกจดรวม</Button>
                            </Space>
                          </>
                      }
                    </> : null
                }
              </Row>
              <Divider orientation={'left'}><b><u>สตอรี่รายละเอียดที่ดิน อธิบายข้อมูลของที่ดินแปลงนั้น / ที่นา ที่บ้าน บ้านกี่หลัง ({item?.land?.numberLand})</u></b></Divider>
              <Form.Item name={`storyLand${index}`} label="">
                <TextArea style={{ color: 'blue' }} rows={5} disabled />
              </Form.Item>
            </>
          </Col>
        </Row>
      </>
    )

  }
  const renderLandDataOld = ({ item, index }) => {
    // console.log("item774", item)
    return (
      <>
        <Row gutter={32} justify={'center'}>
          <Col className='gutter-row' span={24}>
            <>
              <Divider orientation={'left'}><b><u>รายละเอียดยอดจัดเดิม</u></b></Divider>
              <Row gutter={32} justify={'center'}>
                <aside style={{ width: '90%' }}>
                  <div style={{ marginBottom: 3 }}>
                    <span>ยอดจัดครั้งเดิม : </span>{<b>{currencyFormatOne(item?.land?.landLoanDetailsRe?.tcshprc)}</b>}
                    <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                      บาท
                    </span>
                  </div>
                  <div style={{ marginBottom: 3 }}>
                    <span>ยอดที่ชำระมาแล้วทั้งหมด : </span>{<b>{currencyFormatOne(item?.land?.landLoanDetailsRe?.totalpayment)}</b>}
                    <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                      บาท
                    </span>
                  </div>
                  <div style={{ marginBottom: 3 }}>
                    <span>จำนวนงวดที่ผ่อนมาแล้ว : </span>{<b>{item?.land?.landLoanDetailsRe?.NOPAY}</b>}
                    <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                      งวด
                    </span>
                  </div>
                  <div style={{ marginBottom: 3 }}>
                    <span>ราคาเช่าซื้อครั้งที่แล้ว : </span>{<b>{currencyFormatOne(item?.land?.landLoanDetailsRe?.balanc)}</b>}
                    <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                      บาท
                    </span>
                  </div>

                  <div style={{ marginBottom: 3 }}>
                    <span>ล/น คงเหลือรวม : </span>{<b>{currencyFormatOne(item?.land?.landLoanDetailsRe?.neekong)}</b>}
                    <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                      บาท
                    </span>
                  </div>
                  <div style={{ marginBottom: 3 }}>
                    <span>จำนวนงวดทั้งหมด : </span>{<b>{item?.land?.landLoanDetailsRe?.nopays}</b>}
                    <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                      งวด
                    </span>
                  </div>

                  <div style={{ marginBottom: 3 }}>
                    <span>ค่างวด : </span>{<b>{currencyFormatOne(item?.land?.landLoanDetailsRe?.DAMT)}</b>}
                    <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                      บาท
                    </span>
                  </div>

                  <div style={{ marginBottom: 3 }}>
                    <span>เบี้ยปรับ : </span>{<b>{currencyFormatOne(item?.land?.landLoanDetailsRe?.beeypup)}</b>}
                    <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                      บาท
                    </span>
                  </div>
                  <div style={{ marginBottom: 3 }}>
                    <span>ลูกหนี้อื่นๆ : </span>{<b>{currencyFormatOne(item?.land?.landLoanDetailsRe?.OTHR)}</b>}
                    <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                      บาท
                    </span>
                  </div>

                  <div style={{ marginBottom: 3 }}>
                    <span>ตัดสด งวดที่ : </span>{<b>{dayjs(item?.land?.landLoanDetailsRe?.ftime).format("DD/MM/YYYY")}</b>}
                  </div>

                  <div style={{ marginBottom: 3 }}>
                    <span>ผู้เสนอเคส : </span>{<b>{item?.land?.landLoanDetailsRe?.proposalBy}</b>}
                  </div>
                </aside>
              </Row>
            </>
          </Col>
        </Row>
      </>
    )

  }
  const renderCustomer = ({ item, index }) => {
    return (
      <>
        <Row gutter={32} justify={'center'}>
          <Col className='gutter-row' span={24}>
            <>
              <Divider orientation={'left'}><b><u>รายละเอียดผู้ขอกู้</u></b></Divider>
              <Row gutter={32} justify={'center'}>
                <aside style={{ width: '90%' }}>
                  <div style={{ marginBottom: 0 }}>
                    <span>ชื่อ - นามสกุล : </span>{<b>{item?.firstname + " " + item?.lastname}</b>}
                  </div>
                  {/* <div style={{ marginBottom: 0 }}>
                    <span>ชื่อเล่น  : </span>{<b>{item?.nickname}</b>}
                  </div> */}
                  <div style={{ marginBottom: 0 }}>
                    <span>เลขบัตรประชาชน : </span>{<b>{item?.identificationId}</b>}
                  </div>
                  {/* <div style={{ marginBottom: 0 }}>
                    <span>เพศ : </span>{<b>{item?.gender}</b>}
                  </div> */}
                  <div style={{ marginBottom: 0 }}>
                    {
                      <b>
                        {item?.phones?.length > 1 ?
                          item?.phones?.map((tel, index) => {
                            return (
                              <Row key={index}>
                                <div style={{ marginBottom: 0 }}>
                                  <span>{tel?.phoneType} : </span>{< b > {tel?.telp}</b>}
                                </div>
                              </Row>
                            )
                          })
                          :
                          <>
                            <div style={{ marginBottom: 0 }}>
                              <span>{item?.phones[0]?.phoneType} : </span>{< b > {item?.phones[0]?.telp}</b>}
                            </div>
                          </>
                        }
                      </b>
                    }
                  </div>
                </aside>

              </Row>

            </>
          </Col>
        </Row>
      </>
    )

  }

  const renderSelectMainLand = ({ item, index }) => { // เลือกที่ดินหลัก
    return (
      <>
        <Checkbox checked={mainLand === index} onChange={() => handleSelectMainLand(item, index)}><b>ที่ดิน {item?.land?.numberLand}</b></Checkbox>
      </>
    )
  }

  const renderCoordinates = ({ item, index }) => { // พิกัด
    // console.log("item989", item)
    return (
      <>
        {item?.land?.productTypeLand === 2 ?
          <>
            <Divider orientation='left'><b>พิกัด ({item?.land?.numberLand})</b></Divider>
            <Col className='gutter-row' span={12} style={{ textAlign: 'center' }}>
              <span>รายละเอียดพิกัด ({item?.land?.numberLand}) : </span>
              <a href={`https://www.google.com/maps?q=${item?.land?.parcellat},${item?.land?.parcellon}`} target="_blank">
                {`${item?.land?.parcellat},${item?.land?.parcellon}`}
              </a>
            </Col>
          </>
          : null
        }

      </>
    )
  }

  const renderLandDataApproveRe = ({ item, index }) => {
    // console.log("item949", item)
    return (
      <>
        <Divider orientation='left'><b>เลขโฉนดที่ดิน ({item?.land?.numberLand})</b></Divider>
        <Col className='gutter-row' span={16} style={{ textAlign: 'center' }}>
          <Form.Item label="หมายเหตุ" name='note'>
            <Select
              mode="multiple"
              style={{
                width: '100%',
              }}
              size="large"
              placeholder="เลือกหมายเหตุ"
              onChange={handleChange}
              options={options}
            />
          </Form.Item>
        </Col>
        <Col className='gutter-row' span={16} style={{ textAlign: 'center' }}>
          <Form.Item label='ยอดขั้นต่ำ' name='minPrice'
            rules={[
              {
                required: true,
                message: 'กรุณากรอกยอดที่อนุมัติขั้นต่ำ'
              },
            ]}>
            <InputNumber
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              size="large"
              style={{ width: '100%', color: 'black' }}
              onChange={(value) => setDataLand({ ...dataLand, minPrice: parseFloat(value) })}

            // onChange={(value) => {
            //     setMultiModeData(prevData => prevData.map((item, i) => i === index ? { ...item, approval: { ...item.approval, minPrice: parseFloat(value) } } : item));
            // }}
            />
          </Form.Item>
        </Col>
        <Col className='gutter-row' span={16} style={{ textAlign: 'center' }}>
          <Form.Item label='ยอดสูงสุด' name='maxPrice'
            rules={[
              {
                required: true,
                message: 'กรุณากรอกยอดที่อนุมัติสูงสุด'
              },
            ]}>
            <InputNumber
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              size="large"
              style={{ width: '100%', color: 'black' }}
              onChange={(value) => setDataLand({ ...dataLand, maxPrice: parseFloat(value) })}
            // onChange={(value) => setMultiModeData({ ...multiModeData, [index]: { ...multiModeData[index], approval: { ...multiModeData[index].approval, maxPrice: parseFloat(value) } } })}
            // onChange={(value) => {
            //     setMultiModeData(prevData => prevData.map((item, i) => i === index ? { ...item, approval: { ...item.approval, maxPrice: parseFloat(value) } } : item));
            // }}
            />
          </Form.Item>
        </Col>
        <Divider />
      </>
    )
  }

  return (
    <>
      <Modal open={open} onOk={handleOk} onCancel={handleCancel} width={'80%'}
        footer={[
          <Row justify={'center'}>
            <Button key="back" type='primary' danger onClick={handleCancel}>
              ปิด
            </Button>
          </Row>
        ]} >

        <Row justify={'center'}>
          <Col className='gutter-row' span={24} style={{ fontSize: '25px', textAlign: 'center' }}>
            <b>รายละเอียด</b>
          </Col>
        </Row>
        <Row justify={'center'}>
          <Divider style={{ margin: 5 }} />
          <Form
            labelCol={{
              span: 12,
            }}
            wrapperCol={{
              span: 24,
            }}
            style={{
              width: '90%',
            }}
            form={form}
            autoComplete="off"
            initialValues={{ remember: true }}
          >
            <div className='main'>
              <Card style={{ width: '100%' }}>

                <Col justify={'center'}>
                  {
                    newdataLand?.map((item, index) => {
                      return renderHeader({ item, index })
                    })
                  }
                </Col>
                {
                  foundInfo === true ?
                    <>
                      <Divider orientation={'center'}><b><u>*พบข้อมูลที่เคยเสนอมาแล้ว!</u></b></Divider>
                      <Row gutter={32} justify={'center'}>
                        {
                          showOldData2?.map((item, index) => {
                            return renderOldData({ item, index })
                          })
                        }
                      </Row>
                      <Divider />
                    </>
                    :
                    null
                }

                <Col gutter={32} justify={'center'}>
                  {
                    newdataLand?.map((item, index) => {
                      return renderCustomer({ item, index })
                    })
                  }
                </Col>
                <Col gutter={32} justify={'center'}>
                  {
                    newdataLand?.map((item, index) => {
                      return renderLandDataOld({ item, index })
                    })
                  }
                </Col>
                <Col gutter={32} justify={'center'}>
                  {
                    newdataLand?.map((item, index) => {
                      return renderLandData({ item, index })
                    })
                  }
                </Col>
                <Col gutter={32} justify={'center'}>
                  {
                    newdataLand?.map((item, index) => {
                      return renderCoordinates({ item, index })
                    })
                  }
                </Col>
                {/* <Col gutter={32} justify={'center'}>
                  {
                    newdataLand?.map((item, index) => {
                      return renderApprovalRe({ item, index })
                    })
                  }
                </Col> */}

                <Col span={24}>
                  {/* <Divider orientation="left"><b>รูปภาพ</b></Divider> */}
                  {/* <Image.PreviewGroup> */}
                  {/* <span>โฉนด : </span>
                    <Space size={[10, 10]} wrap>
                      {imageBlobzz?.map((e, index) => {
                        if (e.type === 3) {
                          return (
                            <>
                              <Image width={50} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                            </>
                          );
                        }
                        return null;
                      })}
                    </Space>
                    <Divider />
                    <span>ใบแบ่งใบต่อ : </span>
                    <Space size={[10, 10]} wrap>
                      {imageBlobzz?.map((e, index) => {
                        if (e.type === 10) {
                          return (
                            <>
                              <Image width={50} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                            </>
                          );
                        }
                        return null;
                      })}
                    </Space>
                    <Divider />
                    <span>ระวาง : </span>
                    <Space size={[10, 10]} wrap>
                      {imageBlobzz?.map((e, index) => {
                        if (e.type === 11) {
                          return (
                            <>
                              <Image width={50} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                            </>
                          );
                        }
                        return null;
                      })}
                    </Space>
                    <Divider />
                    <span>รูปที่ดิน : </span> */}
                  {/* <Space size={[10, 10]} wrap>
                      {imageBlobzz?.map((e, index) => {
                        if (e.type === 1) {
                          return (
                            <>
                              <Image width={50} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                            </>
                          );
                        }
                        return null;
                      })}
                    </Space> */}
                  {/* </Image.PreviewGroup> */}
                </Col>
                <Divider />
                <Col span={24}>
                  <Divider orientation="left"><b>เอกสารขอรี/ปรับ/เปลี่ยน</b></Divider>
                  <Image.PreviewGroup>
                    <Space size={[10, 10]} wrap>
                      {imageBlobzz?.map((e, index) => {
                        if (e.type === 14) {
                          return (
                            <>
                              <Image width={50} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                            </>
                          );
                        }
                        return null;
                      })}
                    </Space>
                  </Image.PreviewGroup>
                </Col>
                <Divider />
                <Col gutter={32} justify={'center'}>
                  {
                    newdataLand?.map((item, index) => {
                      return renderLandDataApproveRe({ item, index })
                    })
                  }
                </Col>


                {/* <Col gutter={32} justify={'center'}>
                  {
                    newdataLand?.map((item, index) => {
                      return renderLandDataRe({ item, index })
                    })
                  }
                </Col> */}

                {
                  newdataLand.length > 1 ?
                    <>
                      <Divider orientation={'left'}><b><u>เลือกที่ดินหลัก</u></b></Divider>
                      <Row gutter={32} justify={'center'}>
                        {
                          newdataLand?.map((item, index) => {
                            return renderSelectMainLand({ item, index })
                          })
                        }
                      </Row>
                    </>
                    : null
                }

                <Divider />

                {/* {
                  newdataLand?.map((item, index) => {
                    const imgs = item?.img
                    return renderTitleDeed({ item, imgs, index })
                  })
                }
                {
                  newdataLand?.map((item, index) => {
                    const imgs = item?.img
                    return renderDivision({ item, imgs, index })
                  })
                }
                {
                  newdataLand?.map((item, index) => {
                    const imgs = item?.img
                    return renderLawang({ item, imgs, index })
                  })
                }
                {
                  newdataLand?.map((item, index) => {
                    const imgs = item?.img
                    return renderLandBuildings({ item, imgs, index })
                  })
                } */}

                <Col className='gutter-row' span={24} style={{ textAlign: 'right' }}>
                  <Space>
                    <Button type='primary' danger onClick={handleCancelCase}>ตีกลับ</Button>
                    <Button style={{ background: '#597ef7', color: '#ffffff' }} onClick={onApprove} >อนุมัติ</Button>
                  </Space>
                </Col>
              </Card>
            </div>
          </Form>
        </Row>

      </Modal >
      {contextHolder}
      {/* {
        modalnote ?
          <NotePNRe
            open={modalnote}
            close={handleCloseAllModal}
            close2={handleCancel2}
            shootdata={dataLand}
            appStatus={appStatus}
            newDataPn={setDataLand}
            result={result}
            newdataLand={newdataLand}
            dataFromTable={dataFromTable}
          />
          : null
      } */}
      {
        isModalShowNote ?
          <ModalMoreNoteRe
            open={isModalShowNote}
            close={setisModalShowNote}
            datanote={test}
          />
          : null
      }
      {
        isModalAddLand ?
          <ModalAddLandRe
            open={isModalAddLand}
            close={setIsModalAddLand}
            dataFromTable={dataFromTable}
            funcAdd={funcAdd}
            dataEdit={dataEdit}
            dataEditIndex={dataEditIndex}
            checkEdit={checkEdit}
            setCheckEdit={setCheckEdit}
            funcEdit={funcEdit}
            dataForCheckDup={newdataLand}
          />
          : null
      }
      {
        openShowImage ?
          <ModalShowImagesRe
            open={openShowImage}
            close={setOpenShowImage}
            carimage1={carshowMadol}
          />

          : null
      }
      {
        isModalCancelCaseRe ?
          <NoteRe
            open={isModalCancelCaseRe}
            close={setIsModalCancelCaseRe}
            loanId={dataFromTable.reLoanId}
            CloseAll={handleCancel}
            typeNote={"คำร้องไม่ถูกต้อง"}
            allData={allDatas}
            dataFromTable={dataFromTable}
            typeData={"land"}
          />
          : null
      }
    </>
  )
}
