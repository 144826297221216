import React, { useState, useEffect } from 'react'
import { Button, Form, Select, Col, Row, Input, Modal, Card, Spin, Space, InputNumber, Divider, AutoComplete } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import Currency from 'currency.js';
import { useDispatch, useSelector } from 'react-redux';
import { addCar } from '../../../redux/Car';
import '../../css/Media.css'
import { productLoanTypeCar, loanPLorCH } from "../../file_mid/all_options";
import { dataModel, getprice, loadDetailAunSendPG, ratecarsmodel } from '../../file_mid/all_api';
import TableRateCar from '../../offer_case/rate_car/TableRateCar';
import { Installment } from '../../offer_case/ploan/Installment';
import { HirePurchase } from '../../offer_case/hire_purchase/HirePurchase';

function CarLoan({ page, changepage, close, isu, data }) {
  const currentTime = dayjs();
  const formattedTime = currentTime.format('YYYY-MM-DDTHH:mm:ssZ');
  const [form] = Form.useForm()
  const user = localStorage.getItem('username');
  const branch = localStorage.getItem('branch');
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const { Option } = Select;
  const cars = useSelector(state => state.cars)
  const addCustomers = localStorage.getItem('addCustomer')
  const addCarLoanDetailsRes = localStorage.getItem('addCarLoanDetailsRe')
  const addCars = localStorage.getItem('addCar')
  const addCheckGuas = localStorage.getItem('addCheckGua')
  const addLoans = localStorage.getItem('addLoan')
  const addOldLoans = localStorage.getItem('addOldLoan')
  const dataAddCustomer = JSON.parse(addCustomers)
  const dataAddCarLoanDetailsRes = JSON.parse(addCarLoanDetailsRes)
  const dataAddCars = JSON.parse(addCars)
  const dataAddLoans = JSON.parse(addLoans)
  const dataAddOldLoans = JSON.parse(addOldLoans)
  const dataAddCheckGuas = JSON.parse(addCheckGuas)

  let countoffer = 1;


  /////////////////////////////////////////////////////
  const [dataPost2, setDataPost2] = useState({ productTypeCar: 1, carBrand: "" })  //car
  const [money, setMoney] = useState({}) //loan
  const [moneyOld, setMoneyOld] = useState({}) //loanOld
  const [typeMoney, setTypeMoney] = useState({})
  const [provincesCar, setProvincesCar] = useState([]);

  ///////////////// ตาราง ///////////////////////////////
  const [openTable, setOpenTable] = useState(false); //ตารางค่างวด p-loan
  const [openTableHP, setOpenTableHP] = useState(false); //ตารางค่างวด เช่าซื้อ
  const [openTableCar, setOpenTableCar] = useState(false); //ตารางเรทรถ

  /////////////////// รถ ////////////////////////////////////
  const [resultData, setResultData] = useState([]) // API
  const [typeData, setTypeData] = useState([]) // API
  const [brandData, setBrandData] = useState([]) // API
  const [modelData, setModelData] = useState([]) // API
  const [yearPrice, setYearPrice] = useState([])
  const [typeSelected, setTypeSelected] = useState(null)

  ////////////////// select car ////////////////////////////////
  const [counterBrand, setCounterBrand] = useState(false)
  const [counterModel, setCounterModel] = useState(false)
  const [counterCarBaab, setCounterCarBaab] = useState(false)
  // const [dataAun, setDataAun] = useState()


  //////////////// เลือก p-loan หรือ เช่าซื้อ /////////////////////
  const [checkPCH, setCheckPCH] = useState({ PLorCH: "p-loan" })

  const [selectTypeCar, setSelectTypeCar] = useState()

  // console.log('dataAun',dataAun)
  // console.log('isu',isu)
  // console.log('money',money)
  // console.log('data',data)

  useEffect(() => {
    setSelectTypeCar({ ...selectTypeCar, productTypeCar: dataAddCustomer?.productTypeCar })
    // if (!dataAddLands) {
    //   form.setFieldsValue({ landTypeId: 2, proposalBy: user, loanAmounttLand: 50000 })
    // }  
    if (dataAddCars) {
      // console.log("if2")
      setTypeData(dataAddCars?.productTypeCar)
      setTypeSelected(dataAddCars?.productTypeCar)
      loadDataBrand(dataAddCars?.productTypeCar)
      // setSelectProduct({ ...selectProduct, productTypeId: 1 })
      setDataPost2(dataAddCars)
      setMoney(dataAddLoans)
      if (dataAddCars?.carPrice === 0) {
        var newYear = dataAddCars?.carYear
        form.setFieldsValue(
          {
            ...dataAddCustomer, ...dataAddOldLoans, ...dataAddCars, ...dataAddCarLoanDetailsRes,
            proposalBy: user,
            productLoanType: dataAddLoans?.productLoanType ? dataAddLoans?.productLoanType : '',
            carPrice: currencyFormatOne(dataAddCars?.carPrice),
            birthdate: dayjs(dataAddCustomer?.birthdate),
            issudt: dayjs(dataAddCustomer?.issudt),
            expdt: dayjs(dataAddCustomer?.expdt),
            carInput: dayjs(dataAddCars?.carInput),
            carYear: dayjs().year(newYear),
          }
        )
      } else {
        form.setFieldsValue(
          {
            ...dataAddCustomer, ...dataAddCars, ...dataAddCarLoanDetailsRes,
            proposalBy: user,
            productLoanType: dataAddLoans?.productLoanType ? dataAddLoans?.productLoanType : '',
            carPrice: currencyFormatOne(dataAddCars?.carPrice),
            birthdate: dayjs(dataAddCustomer?.birthdate),
            issudt: dayjs(dataAddCustomer?.issudt),
            expdt: dayjs(dataAddCustomer?.expdt),
            carInput: dayjs(dataAddCars?.carInput),
          }
        )
      }
    }
    if (dataAddCarLoanDetailsRes) {
      setCheckPCH(dataAddLoans)
      setMoney({ ...money, ...dataAddLoans, productType: dataAddCarLoanDetailsRes.productType })
      form.setFieldsValue(
        {
          ...dataAddLoans, ...dataAddCarLoanDetailsRes,
          loanAmountt: currencyFormatOne(dataAddLoans?.loanAmountt),
          interestt: currencyFormatOne(dataAddLoans?.interestt),
          monthlyPayment: currencyFormatOne(dataAddLoans?.monthlyPayment),
          installmentWithInterestt: currencyFormatOne(dataAddLoans?.installmentWithInterestt),
        }
      )
    }
    if(data.tell){
      loadDataReAun();
    }
  }, [cars.data])


  useEffect(() => {
    if (money && dataPost2.productTypeCar > 1 && money?.productType === "เช่าซื้อ") {
      car_Calculate()
    }
    if (money && dataPost2.productTypeCar > 0 && money?.productType === "เช่าซื้อ1") {
      car_Calculate()
    }

  }, [money?.loanAmountt, money?.interestRate, money?.installmentAmountt])

  const handleSubmit = async () => {
    setLoading(true)
    if (money?.loanAmountt > 999999999 || money?.interestRate > 100) {
      alert("กรุณาตรวจสอบยอดขอกู้อีกครั้ง")
    } else {
      if (branch === 'MIT' || branch === 'S4' || branch === 'UD' || branch === 'LEX') {
        if (dataPost2.carPrice === 0) {
          dispatch(addCar())
          localStorage.setItem('addLoan', JSON.stringify(money))
          handleGo()
        }
        if (dataPost2.carPrice !== 0) {
          if (money?.loanAmountt > dataPost2?.carPrice && money?.productLoanType !== 'ย้ายไฟแนนซ์' && money?.productLoanType !== 'Pre-Aaprove-ย้ายไฟแนนซ์') {
            alert("ไม่สามารถใส่วงเงินกู้เกินเรทรถได้")
          } else {
            dispatch(addCar())
            localStorage.setItem('addLoan', JSON.stringify(money))
            handleGo()
          }
        }

      } else {

      }
    }
    setLoading(false)
  }
// console.log('dara',data)
  //-------------------------------------------------------------------------------------------------------------------------------------------
  // useEffect(() => {
  //   loadDataReAun();
  // },[])

  // useEffect(()=> {
  //   // if(data.tell){
  //     if(dataAun){
  //         form.setFieldsValue({
  //           ...dataAun,
  //           loanAmountt: dataAun?.approvedLoanAmount, //ยอดจัด
  //           interestRate: dataAun?.interestRate, //อัตราดอกเบี้ย                           
  //           installmentAmountt: dataAun?.pgLoanTerm, //จำนวนงวด                            
  //         })
  //         setMoney({
  //           ...money,
  //           // loanAmountt: dataAun?.approvedLoanAmount, //ยอดจัด
  //           loanAmountt: parseInt(dataAun?.approvedLoanAmount, 10),
  //           interestRate: parseFloat(dataAun?.interestRate), //อัตราดอกเบี้ย                          
  //           installmentAmountt: parseInt(dataAun?.pgLoanTerm,10), //จำนวนงวด                   
  //         })
  //     }
  //   // }
  // },[dataAun])
  
    const loadDataReAun = async () => {
      setLoading(true);
      // await axios.post(loadDetailAunSendPG, { contno: '8-0013344' })
      await axios.post(loadDetailAunSendPG, { contno: isu.contino })
        .then((res) => {
          // if (res.status === 200) {
          form.setFieldsValue({
            // ...res?.data?.carsApproveve,
            loanAmountt: res?.data?.carsApproveve?.approvedLoanAmount, //ยอดจัด
            interestRate: res?.data?.carsApproveve?.interestRate, //อัตราดอกเบี้ย                           
            installmentAmountt: res?.data?.carsApproveve?.pgLoanTerm, //จำนวนงวด                            
          })
          setMoney((prevMoney) => ({
            ...prevMoney,
            loanAmountt: parseInt(res?.data?.carsApproveve?.approvedLoanAmount, 10), // ยอดจัด
            interestRate: parseFloat(res?.data?.carsApproveve?.interestRate), // อัตราดอกเบี้ย
            installmentAmountt: parseInt(res?.data?.carsApproveve?.pgLoanTerm, 10), // จำนวนงวด
          }));
          setLoading(false);
          // }
        })
        .catch((err) => {
          console.log("err", err);
          setLoading(false);
        });
    };
      //------------------------------------------------------------------------------------------------------------------------------
  //////////////// จังหวัด รถ ////////////////
  // const fetchAllProvinces = async () => {
  //   await axios.get(loadProvinces)
  //     .then(response => {
  //       setProvincesCar(response.data);
  //     }).catch(error => {
  //       console.error(error);
  //     });
  // }

  const handleProvinceChangeCar = (value, element) => {
    setDataPost2({ ...dataPost2, carProvince: value, });
  };

  /////////////// โมดาว ค่า งวด p-loan และ แบบ เช่าซื้อ ///////////////////
  // const currencyFormatOnes = (amount) => {
  //   return Number(amount).toFixed(0).replace(/\d(?=(\d{3})+(?!\d))/g, '$&,')
  // }
  const currencyFormatOne = (amount) => {
    if (amount) {
      return Number(amount)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, '$&,')
    }
  }

  const handleMoney = (values) => {
    setMoney({
      ...money,
      interestRate: values?.interestRate,
      loanAmountt: values?.loanAmountt,
      installmentAmountt: values?.installmentAmountt,
      interestt: values?.interestt,
      monthlyPayment: values?.monthlyPayment,
      installmentWithInterestt: values?.installmentWithInterestt,
      approvalStatus: 16,
      proposalBy: user,
      branch: branch,
      productType: money.productType,
      PLorCH: money.productType
    })
    form.setFieldsValue(
      {
        loanAmountt: currencyFormatOne(values?.loanAmountt),
        installmentAmountt: values?.installmentAmountt,
        interestt: currencyFormatOne(values?.interestt),
        monthlyPayment: currencyFormatOne(values?.monthlyPayment),
        installmentWithInterestt: currencyFormatOne(values?.installmentWithInterestt),
      }
    )
  }
  const handleMoneyHP = (values) => {
    //console.log("ER2", values)
    //setMoney({ ...money, ...values, productType: checkPCH.PLorCH, proposalBy: user, ...checkPCH })
    setMoney({
      ...money,
      interestRate: values?.interestRate,
      loanAmountt: values?.loanAmountt,
      installmentAmountt: values?.installmentAmountt,
      interestt: values?.interestt,
      monthlyPayment: values?.monthlyPayment,
      installmentWithInterestt: values?.installmentWithInterestt,
      approvalStatus: 16,
      proposalBy: user,
      branch: branch,
      productType: money.productType,
      PLorCH: money.productType
    })
    form.setFieldsValue(
      {
        loanAmountt: currencyFormatOne(values?.loanAmountt),
        installmentAmountt: values?.installmentAmountt,
        interestt: currencyFormatOne(values?.interestt),
        monthlyPayment: currencyFormatOne(values?.monthlyPayment),
        installmentWithInterestt: currencyFormatOne(values?.installmentWithInterestt),
      }
    )
  }
  ////////////// รถ ///////////////////
  const handleChangeType = (value) => {
    // setCheckInfo(value)
    if (value === 'ย้ายไฟแนนซ์' || value === 'Pre-Aaprove-ย้ายไฟแนนซ์') {
      setMoney({ ...money, productLoanType: value, proposalBy: user, branch: branch, approvalStatus: 16, productType: money.productType })
      setMoneyOld({ ...moneyOld, productLoanType: value })
      setTypeMoney({ ...typeMoney, productLoanType: 'ย้ายไฟแนนซ์', productType: money.productType })
      //setTypeMoney({ ...typeMoney, productLoanType: value, approvalDate: dayjs().format("YYYY-MM-DDTHH:mm:ssZ"), productType: "รถ" })
    }
    else {
      setMoney({ ...money, productLoanType: value, proposalBy: user, branch: branch, approvalStatus: 16, productType: money.productType })
      setTypeMoney({ ...typeMoney, productLoanType: 'ถือเล่มมา', productType: money.productType })
      setMoneyOld('')
      form.setFieldsValue(
        {
          loanAmountt: "",
          installmentAmountt: "",
          interestt: "",
          interestRate: "",
          monthlyPayment: "",
          installmentWithInterestt: "",
          oldKangPayment: '',
          oldKangPaymentTerm: '',
          oldLoanAmount: '',
          oldLoanTerm: '',
          oldMonthlyPayment: '',
        }
      )
      //setTypeMoney({ ...typeMoney, productLoanType: value, approvalDate: dayjs().format("YYYY-MM-DDTHH:mm:ssZ"), productType: "รถ" })
      //setCount(value)
    }
  }
  /////////////// เลือก p-loan หรือ เช่าซื้อ ///////////////////
  const handleChangePLorCH = (value) => {
    if (value === "p-loan") {
      // setCheckPCH({ PLorCH: value })
      setMoney({
        ...money,
        loanAmountt: "",
        installmentAmountt: "",
        interestt: "",
        interestRate: "",
        monthlyPayment: "",
        installmentWithInterestt: "",
        PLorCH: value,
        productType: value
      })
      form.setFieldsValue(
        {
          loanAmountt: "",
          installmentAmountt: "",
          interestt: "",
          interestRate: "",
          monthlyPayment: "",
          installmentWithInterestt: "",
        }
      )
    } else {
      // setCheckPCH({ PLorCH: value })
      setMoney({
        ...money, loanAmountt: "",
        installmentAmountt: "",
        interestt: "",
        interestRate: "",
        monthlyPayment: "",
        installmentWithInterestt: "",
        PLorCH: value,
        productType: value
      })
      form.setFieldsValue(
        {
          loanAmountt: "",
          installmentAmountt: "",
          interestt: "",
          interestRate: "",
          monthlyPayment: "",
          installmentWithInterestt: "",
        }
      )
    }
  }


  // //////////// กรณีไม่มีรถ /////////////////////
  // const AddBrand = () => {
  //   setCounterBrand(true)
  //   setCounterModel(true)
  //   setDataAun(true)
  //   setDataPost2({ ...dataPost2, carPrice: 0 })
  //   form.setFieldsValue(
  //     {
  //       carBrand: '',
  //       carModel: '',
  //       carBaab: '',
  //       carYear: '',
  //       carPrice: 0,
  //     }
  //   )
  // }
  // const AddBrands = () => {
  //   setCounterBrand(false)
  //   setCounterModel(false)
  //   setCounterCarBaab(false)
  //   setDataPost2({ ...dataPost2, carPrice: 0 })
  //   setMoney({
  //     ...money,
  //     loanAmountt: 0,
  //     installmentAmountt: 0,
  //     interestt: 0,
  //     interestRate: 0,
  //     monthlyPayment: 0,
  //     installmentWithInterestt: 0,
  //   })
  //   form.setFieldsValue(
  //     {
  //       carBrand: '',
  //       carModel: '',
  //       carBaab: '',
  //       carYear: '',
  //       carPrice: 0,
  //       loanAmountt: "",
  //       installmentAmountt: "",
  //       interestt: "",
  //       interestRate: "",
  //       monthlyPayment: "",
  //       installmentWithInterestt: "",
  //     }
  //   )
  // }
  // //////////// กรณีไม่มีรถ /////////////////////
  // const AddModel = () => {
  //   setCounterModel(true)
  //   setCounterCarBaab(true)
  //   setDataPost2({ ...dataPost2, carPrice: 0 })
  //   form.setFieldsValue(
  //     {
  //       carModel: '',
  //       carBaab: '',
  //       carYear: '',
  //       carPrice: 0,
  //     }
  //   )
  // }
  // const AddModels = () => {
  //   setCounterModel(false)
  //   setCounterCarBaab(false)
  //   setDataPost2({ ...dataPost2, carPrice: 0 })
  //   setMoney({
  //     ...money,
  //     loanAmountt: 0,
  //     installmentAmountt: 0,
  //     interestt: 0,
  //     interestRate: 0,
  //     monthlyPayment: 0,
  //     installmentWithInterestt: 0,
  //   })
  //   form.setFieldsValue(
  //     {
  //       carModel: '',
  //       carBaab: '',
  //       carYear: '',
  //       carPrice: 0,
  //       loanAmountt: "",
  //       installmentAmountt: "",
  //       interestt: "",
  //       interestRate: "",
  //       monthlyPayment: "",
  //       installmentWithInterestt: "",
  //     }
  //   )
  // }
  // //////////// กรณีไม่มีรถ /////////////////////
  // const AddCarBaab = () => {
  //   // console.log("-")
  //   setCounterCarBaab(true)
  //   setDataPost2({ ...dataPost2, carPrice: 0 })
  //   form.setFieldsValue(
  //     {
  //       carBaab: '',
  //       carYear: '',
  //       carPrice: 0,
  //     }
  //   )
  // }
  // const AddCarBaabs = () => {

  //   setCounterCarBaab(false)
  //   setDataPost2({ ...dataPost2, carPrice: 0 })
  //   setMoney({
  //     ...money,
  //     loanAmountt: 0,
  //     installmentAmountt: 0,
  //     interestt: 0,
  //     interestRate: 0,
  //     monthlyPayment: 0,
  //     installmentWithInterestt: 0,
  //   })
  //   form.setFieldsValue(
  //     {
  //       carBaab: '',
  //       carYear: '',
  //       carPrice: 0,
  //       loanAmountt: "",
  //       installmentAmountt: "",
  //       interestt: "",
  //       interestRate: "",
  //       monthlyPayment: "",
  //       installmentWithInterestt: "",
  //     }
  //   )
  // }
  // const onYeehor = (value) => {
  //   // console.log("value", value)
  //   setDataPost2({ ...dataPost2, carBrand: value })
  //   // setDataPost2({ ...dataPost2, carBrand: e.target.value })
  //   // //setCarSelected(e)
  // }


  // const onMoChange = (e) => {
  //   //console.log("Modal",e.target.value)
  //   setDataPost2({ ...dataPost2, carModel: e.target.value })
  //   //setCarSelected(e)
  // }
  // // const AddModal = () => {
  // //     setCounterModal(true)
  // // }
  // const onDeChange = (e) => {
  //   //console.log("Baab",e.target.value)
  //   setDataPost2({ ...dataPost2, carBaab: e.target.value })
  // }
  // // const AddYear = () => {
  // //     setCounterYear(true)
  // // }
  // const onReChange = (e) => {
  //   //console.log("Year",e.target.value)
  //   setDataPost2({ ...dataPost2, carYear: parseInt(e.target.value) })
  // }

  ////// ตัวอย่างรถ กับ ตารางค่างวด ////////
  const getTable = () => {
    setOpenTable(true)
  }
  const getTableHP = () => {
    setOpenTableHP(true)
  }
  const getTableCar = () => {
    setOpenTableCar(true)
    setCounterCarBaab(false)
  }
  /////////////////////////////////////

  const loadDataBrand = async (value) => { //รถยนต์ รถเครื่องจักร รถบรรทุก
    setLoading(true)
    await axios.get(`https://shark-app-j9jc9.ondigitalocean.app/ratecars/brand/${value}`)
      .then((res) => {
        setBrandData(res.data)
        setLoading(false)
      })
      .catch((err) => console.log(err))
    setLoading(false)
  }
  const loadDataModel = async (value) => {
    setLoading(true)
    await axios.get(dataModel + value)
      .then((res) => {
        setModelData(res.data)
        form.setFieldsValue(
          {
            carModel: "",
            carBaab: "",
            carYear: "",
            carPrice: 0
          }
        )
        setLoading(false)
      })
      .catch((err) => console.log(err))
    setLoading(false)
  }
  const getResult = async (value) => {
    setLoading(true)
    const result = await axios.get(ratecarsmodel,
      { params: { typeId: typeSelected, modelId: value }, })
    setResultData(result.data)
    setLoading(false)
  }

  const onTypeChange = (value) => {
    setDataPost2({ ...dataPost2, productTypeCar: value, carYear: 0, carPrice: 0, carPriceStatus: false, carInput: formattedTime, carDateStatus: formattedTime })
    setTypeData(value)
    setTypeSelected(value)
    loadDataBrand(value)
    setMoney({
      ...money,
      loanAmountt: 0,
      installmentAmountt: 0,
      interestt: 0,
      interestRate: 0,
      monthlyPayment: 0,
      installmentWithInterestt: 0,
    })
    form.setFieldsValue(
      {
        loanAmountt: "",
        installmentAmountt: "",
        interestt: "",
        interestRate: "",
        monthlyPayment: "",
        installmentWithInterestt: "",
        oldLoanAmount: "",
        oldMonthlyPayment: "",
        oldLoanTerm: "",
        oldKangPayment: "",
        oldKangPaymentTerm: ""
      }
    )
  }
  const onBrandChange = (value) => {
    setDataPost2({ ...dataPost2, carBrand: brandData.find((a) => a.idbrand === value).brand })
    loadDataModel(value)
  }
  const onModelChange = (value) => {
    setCounterBrand(false)
    setDataPost2({ ...dataPost2, carModel: modelData.find((a) => a.idmodel_te === value).model })
    getResult(value)
  }
  const onDetailChange = (value) => {
    setDataPost2({ ...dataPost2, carBaab: resultData.find((a) => a.idmodel === value).models })
    getPrice(typeData, value)
  }
  const getPrice = async (typeId, carId) => {
    setLoading(true)
    await axios.get(getprice, { params: { typeId, carId }, })
      .then((res) => {
        if (res.data) {
          setYearPrice(res.data)
          setLoading(false)
        }
      })
      .catch((err) => alert('ไม่พบข้อมูล ราคารถ'))
    setLoading(false)
  }
  const onResult = (k, v) => {
    const pYear = parseInt(v.key)
    if (k) {
      setDataPost2({ ...dataPost2, carPriceStatus: true, carPrice: k, carYear: pYear, carInput: formattedTime, carDateStatus: formattedTime })
      parseInt(dataPost2.carYear)
      form.setFieldsValue({
        carPrice: currencyFormatOne(v.value)
      })
    }
  }

  const car_Calculate = () => {
    if (money.loanAmountt && money.interestRate && money.installmentAmountt) {
      var interestRateCar = money.interestRate / 100 // อัตราดอก / 100
      var rateCar = ((money.loanAmountt * Currency(interestRateCar, { precision: 5 }).value) * money.installmentAmountt) // (ยอดจัด * อัตราดอกที่หาร100แล้ว ,precision: 3 คือทศนิยม 3 )*งวด
      var loanTermCar = Math.ceil(Currency((rateCar + money.loanAmountt) / money.installmentAmountt, { precision: 2 }).value) // (ดอกเบี้ยทั้งหมด + ยอดจัด)/ งวด
      var newLoanTermCar = Math.ceil(Currency(loanTermCar * 0.07)) + loanTermCar // งวดละใหม่ = ( งวดละ * 0.07 ) + งวดละ
      var resultRateCar = (newLoanTermCar * money.installmentAmountt) - money.loanAmountt // งวดละใหม่ * งวด - ยอดจัด
      //var resultPriceCar = loanTermCar * money.installmentAmountt // งวดละ * งวด
      var newResultPriceCar = newLoanTermCar * money.installmentAmountt // งวดละใหม่ * งวด

      // console.log("อัตราดอก =",interestRateCar)
      // console.log("rateCar =",rateCar,money.loanAmountt , Currency(interestRateCar, { precision: 5 }).value , money.installmentAmountt)
      // console.log("loanTermCar =",Math.ceil(Currency(loanTermCar*0.07))+loanTermCar)
      // console.log("resultRateCar =",resultRateCar)
      // console.log("resultPriceCar =",newLoanTermCar*money.installmentAmountt)

      setMoney({
        ...money,
        monthlyPayment: newLoanTermCar,
        interestt: resultRateCar,
        installmentWithInterestt: newResultPriceCar
      })
      form.setFieldsValue(
        {
          monthlyPayment: newLoanTermCar,
          interestt: resultRateCar,
          installmentWithInterestt: newResultPriceCar
        }
      )
    }
    else {
      form.setFieldsValue(
        {
          monthlyPayment: 0,
          interestt: 0,
          installmentWithInterestt: 0
        }
      )
    }
  }

  const checkMoneyCar = (value) => {
    if (money?.productLoanType === "ย้ายไฟแนนซ์" || money?.productLoanType === 'Pre-Aaprove-ย้ายไฟแนนซ์') {
      setMoney({ ...money, loanAmountt: parseInt(value) })
    } else {
      if (dataPost2?.carPrice === 0) {
        setMoney({ ...money, loanAmountt: parseInt(value) })
      } else {
        if (parseInt(value) > dataPost2?.carPrice) {
          alert("ไม่สามารถใส่ยอดจัดเกินเรทรถได้")
          form.setFieldsValue(
            {
              loanAmountt: 0,
            }
          )
        } else {
          setMoney({ ...money, loanAmountt: parseInt(value) })
        }
      }
    }
  }
  const checkMoneyCarSmall = (value) => {
    setMoneyOld({ ...moneyOld, oldLoanAmount: parseInt(value.oldLoanAmount) })
  }

  const handleGo = () => {
    changepage(page + 1)
  }
  const handleBack = () => {
    if (dataAddCheckGuas === "ค้ำ-ไม่โอน" || dataAddCheckGuas === "ค้ำ-โอน") {
      changepage(page - 1)
    } else {
      changepage(page - 2)
    }

  }
  const handleClose = () => {
    close()
  }
  return (
    <Row justify={'center'}>
      <Card style={{ width: '100%' }}>
        <Spin spinning={loading} size='large' tip=" Loading... ">
          <Row style={{ textAlign: 'center', marginTop: '30px' }} >
            <Col span={24} style={{ fontSize: '30px' }} >รายละเอียด</Col>
          </Row>
          <Divider />
          <Form
            labelCol={{
              span: 12,
            }}
            wrapperCol={{
              span: 12,
            }}

            form={form}
            onFinish={handleSubmit}
            autoComplete="off"
            initialValues={{ remember: true }}
          >
            <Card style={{ width: '100%' }}>
              <Row justify={'center'}>
                <aside style={{ width: '100%', textAlign: 'center' }}>
                  <div>
                    {/* <Col span={12}> */}
                    <Form.Item label='ชนิดรถ' name='productTypeCar'
                      style={{ color: 'black', margin: 0 }}
                      rules={[
                        {
                          required: true,
                          message: 'Please input !'
                        },]}>
                      <Select
                        style={{ color: 'black', height: '40px' }}
                        allowClear
                        disabled
                        onChange={onTypeChange}>
                        <Option value={1}>รถยนต์</Option>
                        <Option value={2}>รถเครื่องจักการเกษตร</Option>
                        <Option value={3}>รถบรรทุก</Option>
                      </Select>
                    </Form.Item>
                    <Form.Item label='เรทรถ'
                      style={{ margin: 0 }}>
                      <Button type='primary' disabled style={{ width: "100%" }} onClick={getTableCar}>ดู</Button>
                      {
                        openTableCar ?
                          <TableRateCar
                            open={openTableCar}
                            close={setOpenTableCar}
                            countoffer={countoffer}
                            productTypeCar={dataPost2?.productTypeCar}
                          />
                          : null
                      }
                    </Form.Item>
                    <Form.Item label='ยี่ห้อ'
                      style={{ margin: 0 }}
                      name='carBrand'
                      rules={[
                        {
                          required: true,
                          message: 'Please input !'
                        },]}>
                      <Select
                        disabled
                        showSearch
                        style={{ height: '40px' }}
                        onChange={onBrandChange}
                        optionFilterProp="children"
                        placeholder="Search to Select"
                        filterOption={(inputValue, option) =>
                          option.children.join('').toLowerCase().includes(inputValue.toLowerCase())
                        }
                      >
                        {brandData?.map((e) => {
                          return (
                            <Select.Option key={e.idbrand} value={e.idbrand}>
                              {e.brand} ({e.brand_th})
                            </Select.Option>
                          )
                        })}
                      </Select>
                    </Form.Item>
                    <Form.Item label='รุ่นรถ'
                      name='carModel'
                      style={{ margin: 0 }}
                      rules={[
                        {
                          required: true,
                          message: 'Please input !'
                        },]}>
                      <Select //ไม่จริงเข้าตรงนี้
                        showSearch
                        disabled
                        style={{ height: '40px' }}
                        onChange={onModelChange}
                        placeholder="Search to Select"
                        optionFilterProp="children"
                        filterOption={(inputValue, option) =>
                          option.children.join('').toLowerCase().includes(inputValue.toLowerCase())
                        }
                      >
                        {modelData?.map((e) => {
                          return (
                            <Select.Option key={e.idmodel_te} value={e.idmodel_te}>
                              {e.model} {e.model_te ? e.model_te : null}
                            </Select.Option>
                          )
                        })}
                      </Select>
                    </Form.Item>
                    <Form.Item label='แบบรถ'
                      name='carBaab'
                      style={{ margin: 0 }}
                      rules={[
                        {
                          required: true,
                          message: 'Please input !'
                        },]}>
                      <Select
                        showSearch
                        disabled
                        style={{ height: '40px' }}
                        onChange={(value) => { onDetailChange(value) }}
                        placeholder="Search to Select"

                      >
                        {resultData?.map((e) => {
                          return (
                            <Select.Option style={{ width: '250px' }} key={e.idmodel} value={e.idmodel}>
                              {e.models} {e.idmodel ? e.idmodel : null}
                            </Select.Option>
                          )
                        })}
                      </Select>
                    </Form.Item>
                    <Form.Item label='ปีรถ'
                      name='carYear'
                      style={{ margin: 0 }}
                      rules={[
                        {
                          required: true,
                          message: 'Please input !'
                        },]}>
                      <Select
                        disabled
                        style={{ height: '40px' }}
                        onChange={(k, v) => (onResult(k, v))}
                      >
                        {Object.entries(yearPrice).map(([k, v]) => {
                          if (k.charAt(0) === 'p' && v) {
                            const year = k.replace('p', '')
                            return (
                              <Select.Option style={{ width: '250px' }} key={year} value={v * 1000}>
                                {year}
                              </Select.Option>
                            )
                          }
                        })}
                      </Select>



                    </Form.Item>
                    <Form.Item label='ราคา'
                      name='carPrice'
                      style={{ margin: 0 }}
                    >
                      <Input disabled defaultValue={0} style={{ color: 'black', height: '40px' }}
                        onChange={(e) => setDataPost2({ ...dataPost2, carPrice: e.value })} />
                    </Form.Item>
                    <Form.Item label='จังหวัด'
                      name='carProvince'
                      style={{ margin: 0 }}
                      rules={[
                        {
                          required: true,
                          message: 'Please input !',
                        },]}>
                      <Select
                        showSearch
                        disabled
                        style={{ height: '40px' }}
                        name='carProvince'
                        placeholder='จังหวัด'
                        onChange={(key, value) => { handleProvinceChangeCar(key, value) }}
                      >
                        {provincesCar?.map((pro, index) => (
                          <Option key={pro.provinceId} value={pro.provinceName}>
                            {pro.provinceName}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item label='เลขทะเบียน'
                      name='carPlateNumber'
                      style={{ margin: 0 }}
                      rules={[
                        {
                          required: true,
                          message: 'Please input !',
                        },]}>
                      <Input
                        disabled
                        style={{ color: 'black', height: '40px' }}
                        onChange={(e) => setDataPost2({ ...dataPost2, carPlateNumber: e.target.value })} />
                    </Form.Item>
                    {/* </Col> */}
                  </div>
                  <div>
                    {/* <Col span={12}> */}
                    <Form.Item label='ประเภทขอกู้รถ'
                      name='productLoanType'
                      style={{ margin: 0 }}
                      rules={[
                        {
                          required: true,
                          message: 'Please input !',
                        },]}>
                      <Select
                        disabled
                        placeholder={'เลือก'}
                        style={{ height: '40px' }}
                        onChange={(value) => { handleChangeType(value) }}
                        options={productLoanTypeCar}
                      />
                    </Form.Item>
                    <Form.Item label='ประเภทค่างวด'
                      name='productType'
                      style={{ margin: 0 }}
                    >
                      <Select
                        disabled
                        placeholder={'เลือก'}
                        style={{ height: '40px' }}
                        onChange={(value) => { handleChangePLorCH(value) }}
                        options={loanPLorCH}
                      />
                    </Form.Item>
                    {
                      dataPost2?.productTypeCar === 1 && money?.productType === "p-loan" ?
                        <>
                          <Form.Item label='P-LOAN'
                            style={{ margin: 0 }}
                          >
                            <Button type="primary" onClick={getTable}>
                              ตารางค่างวด P-LOAN
                            </Button>
                            {
                              openTable ?
                                <Installment open={openTable} close={setOpenTable} money={handleMoney} type={money} cp={dataPost2?.carPrice} />
                                : null
                            }
                          </Form.Item>

                          <Form.Item label='ยอดจัด' name='loanAmountt'
                            style={{ margin: 0 }}
                            rules={[
                              {
                                required: true,
                                message: 'Please input !'
                              },]}>
                            <Input disabled style={{ color: 'black', height: '40px' }}
                              onChange={(e) => setMoney({ ...money, loanAmountt: e.target.value })} />
                          </Form.Item>

                          <Form.Item label='จำนวนงวด' name='installmentAmountt'
                            style={{ margin: 5 }}
                            rules={[
                              {
                                required: true,
                                message: 'Please input !'
                              },]}>
                            <Input disabled style={{ color: 'black', height: '40px', margin: 5 }}
                              onChange={(e) => setMoney({ ...money, installmentAmountt: e.target.value })} />
                          </Form.Item>

                          <Form.Item label='งวดละ' name='monthlyPayment'
                            style={{ margin: 5 }}
                          >
                            <Input disabled style={{ color: 'black', height: '40px' }}
                              onChange={(e) => setMoney({ ...money, monthlyPayment: e.target.value })} />
                          </Form.Item>

                          <Form.Item label='ดอกเบี้ย' name='interestt'
                            style={{ margin: 0 }}
                          >
                            <Input disabled style={{ color: 'black', height: '40px' }}
                              onChange={(e) => setMoney({ ...money, interestt: e.target.value })} />
                          </Form.Item>

                          <Form.Item label='รวมราคา' name='installmentWithInterestt'
                            style={{ margin: 0 }}
                          >
                            <Input disabled style={{ color: 'black', height: '40px' }}
                              onChange={(e) => setMoney({ ...money, installmentWithInterestt: e.target.value })} />
                          </Form.Item>
                          <Form.Item label='ผู้เสนอเคส' name='proposalBy'
                            style={{ margin: 0 }}
                          >
                            <Input disabled style={{ color: 'black', height: '40px' }}
                              onChange={(e) => setMoney({ ...money, proposalBy: e.target.value })} />
                          </Form.Item>
                        </>
                        : dataPost2?.productTypeCar === 1 && money?.productType === "เช่าซื้อ" ?
                          <>
                            <Form.Item label='เช่าซื้อ' style={{ margin: 0 }}>
                              <Button type="primary" style={{ width: '100%' }} onClick={getTableHP}>
                                ตารางค่างวด เช่าซื้อ
                              </Button>
                              {
                                openTableHP ?
                                  <HirePurchase open={openTableHP} close={setOpenTableHP} money={handleMoneyHP} type={money} cp={dataPost2?.carPrice} />
                                  : null
                              }
                            </Form.Item>

                            <Form.Item label='ยอดจัด' name='loanAmountt'
                              style={{ margin: 0 }}
                              rules={[
                                {
                                  required: true,
                                  message: 'Please input !',
                                },]}>
                              <Input disabled style={{ color: 'black', height: '40px' }}
                                onChange={(e) => setMoney({ ...money, loanAmountt: e.target.value })} />
                            </Form.Item>

                            <Form.Item label='จำนวนงวด' name='installmentAmountt'
                              style={{ margin: 5 }}
                              rules={[
                                {
                                  required: true,
                                  message: 'Please input !',
                                },]}>
                              <Input disabled style={{ color: 'black', height: '40px', margin: 5 }}
                                onChange={(e) => setMoney({ ...money, installmentAmountt: e.target.value })} />
                            </Form.Item>

                            <Form.Item label='งวดละ' name='monthlyPayment'
                              style={{ margin: 5 }}
                            >
                              <Input disabled style={{ color: 'black', height: '40px' }}
                                onChange={(e) => setMoney({ ...money, monthlyPayment: e.target.value })} />
                            </Form.Item>

                            <Form.Item label='ดอกเบี้ย' name='interestt'
                              style={{ margin: 0 }}
                            >
                              <Input disabled style={{ color: 'black', height: '40px' }}
                                onChange={(e) => setMoney({ ...money, interestt: e.target.value })} />
                            </Form.Item>

                            <Form.Item label='ราคารวม' name='installmentWithInterestt'
                              style={{ margin: 0 }}
                            >
                              <Input disabled style={{ color: 'black', height: '40px' }}
                                onChange={(e) => setMoney({ ...money, installmentWithInterestt: e.target.value })} />
                            </Form.Item>
                            <Form.Item label='ผู้เสนอเคส' name='proposalBy'
                              style={{ margin: 0 }}
                            >
                              <Input disabled style={{ color: 'black', height: '40px' }}
                                onChange={(e) => setMoney({ ...money, proposalBy: e.target.value })} />
                            </Form.Item>
                          </>
                          : dataPost2?.productTypeCar === 1 && money?.productType === "เช่าซื้อ1" ?
                            <>
                              <Form.Item label='ยอดจัด' name='loanAmountt'
                                style={{ margin: 0 }}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please input !'
                                  },]}>
                                <InputNumber
                                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                  size="large"
                                  style={{ width: '100%', color: 'black', height: '40px' }}
                                  onChange={(value) => checkMoneyCar(value)}
                                />
                              </Form.Item>
                              <Form.Item label='อัตราดอกเบี้ย' name='interestRate'
                                style={{ textAlign: 'center' }}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please input !'
                                  },]}>
                                <Select
                                  style={{ textAlign: 'center', height: '40px' }}
                                  placeholder={'เลือก'}
                                  onChange={(value) => setMoney({ ...money, interestRate: value })}
                                  label="จำนวนงวด"
                                  options={[
                                    { label: 0.75, value: 0.75 }, { label: 0.84, value: 0.84 },
                                  ]}
                                >
                                </Select>
                              </Form.Item>
                              <Form.Item label='จำนวนงวด' name='installmentAmountt'
                                style={{ textAlign: 'center', marginBottom: 15 }}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please input !'
                                  },]}>
                                <Select
                                  style={{ textAlign: 'center', height: '40px', marginBottom: 15 }}
                                  placeholder={'เลือก'}
                                  onChange={(value) => setMoney({ ...money, installmentAmountt: value })}
                                  label="จำนวนงวด"
                                  options={[
                                    { label: '12', value: 12 }, { label: 18, value: 18 }, { label: '24', value: 24 },
                                    { label: '30', value: 30 }, { label: 36, value: 36 }, { label: '42', value: 42 },
                                    { label: '48', value: 48 }, { label: 54, value: 54 }, { label: '60', value: 60 },
                                    { label: '66', value: 66 }, { label: 72, value: 72 }, { label: '78', value: 78 },
                                    { label: '84', value: 84 }, { label: 90, value: 90 }, { label: '96', value: 96 },
                                    { label: '102', value: 102 }, { label: 108, value: 108 }, { label: '114', value: 114 },
                                    { label: '120', value: 120 }
                                  ]}
                                >
                                </Select>
                              </Form.Item>

                              <Form.Item label='งวดละ' name='monthlyPayment'
                                style={{ textAlign: 'center', margin: 5 }}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please input !'
                                  },]}>
                                <Input type='number' disabled style={{ color: 'black', height: '40px', margin: 5 }}
                                  onChange={(e) => setMoney({ ...money, monthlyPayment: parseInt(e.target.value) })} />
                              </Form.Item>

                              <Form.Item label='ดอกเบี้ย' name='interestt'
                                style={{ textAlign: 'center' }}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please input !'
                                  },]}>
                                <Input type='number' disabled style={{ color: 'black', height: '40px' }}
                                  onChange={(e) => setMoney({ ...money, interestt: parseInt(e.target.value) })} />
                              </Form.Item>

                              <Form.Item label='รวมราคา' name='installmentWithInterestt'
                                style={{ textAlign: 'center' }}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please input !'
                                  },]}>
                                <Input type='number' disabled style={{ color: 'black', height: '40px' }}
                                  onChange={(e) => setMoney({ ...money, installmentWithInterestt: parseInt(e.target.value) })} />
                              </Form.Item>
                              <Form.Item label='ผู้เสนอเคส' name='proposalBy'
                                style={{ margin: 0 }}
                              >
                                <Input disabled style={{ color: 'black', height: '40px' }}
                                  onChange={(e) => setMoney({ ...money, proposalBy: e.target.value })} />
                              </Form.Item>
                            </>
                            : dataPost2?.productTypeCar === 2 && money?.productType === "เช่าซื้อ" ?
                              <>
                                <Form.Item label='ยอดจัด' name='loanAmountt'
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please input !'
                                    },]}>

                                  <InputNumber
                                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                    size="large"
                                    style={{ color: 'black', width: '100%', height: '40px' }}
                                    onChange={(value) => checkMoneyCar(value)}
                                  />
                                </Form.Item>

                                <Form.Item label='อัตราดอกเบี้ย' name='interestRate'
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please input !'
                                    },]}>
                                  <Input type='number'  suffix="% +VAT" name="interestRate"

                                    onChange={(e) => setMoney({ ...money, interestRate: parseFloat(e.target.value) })} />
                                </Form.Item>

                                <Form.Item label='จำนวนงวด' name='installmentAmountt'
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please input !'
                                    },]}>
                                  <Select
                                    style={{ height: '40px' }}
                                    placeholder={'เลือก'}
                                    onChange={(value) => setMoney({ ...money, installmentAmountt: value })}
                                    label="จำนวนงวด"
                                    options={[
                                      { label: '12', value: 12 }, { label: 18, value: 18 }, { label: '24', value: 24 },
                                      { label: '30', value: 30 }, { label: 36, value: 36 }, { label: '42', value: 42 },
                                      { label: '48', value: 48 }, { label: 54, value: 54 }, { label: '60', value: 60 },
                                      { label: '66', value: 66 }, { label: 72, value: 72 }, { label: '78', value: 78 },
                                      { label: '84', value: 84 }, { label: 90, value: 90 }, { label: '96', value: 96 },
                                      { label: '102', value: 102 }, { label: 108, value: 108 }, { label: '114', value: 114 },
                                      { label: '120', value: 120 }
                                    ]}
                                  >
                                  </Select>
                                </Form.Item>

                                <Form.Item label='งวดละ' name='monthlyPayment'
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please input !'
                                    },]}>
                                  <Input type='number' disabled style={{ color: 'black', marginTop: 15 }}
                                    onChange={(e) => setMoney({ ...money, monthlyPayment: parseInt(e.target.value) })} />
                                </Form.Item>

                                <Form.Item label='ดอกเบี้ย' name='interestt'
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please input !'
                                    },]}>
                                  <Input type='number' disabled style={{ color: 'black' }}
                                    onChange={(e) => setMoney({ ...money, interestt: parseInt(e.target.value) })} />
                                </Form.Item>

                                <Form.Item label='รวมราคา' name='installmentWithInterestt'
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please input !'
                                    },]}>
                                  <Input type='number' disabled style={{ color: 'black' }}
                                    onChange={(e) => setMoney({ ...money, installmentWithInterestt: parseInt(e.target.value) })} />
                                </Form.Item>
                                <Form.Item label='ผู้เสนอเคส' name='proposalBy'
                                >
                                  <Input disabled style={{ color: 'black', height: '40px' }}
                                    onChange={(e) => setMoney({ ...money, proposalBy: e.target.value })} />
                                </Form.Item>
                              </>
                              : dataPost2?.productTypeCar === 3 && money?.productType === "เช่าซื้อ" ?
                                <>
                                  <Form.Item label='ยอดจัด' name='loanAmountt'
                                    style={{ marginBottom: 15 }}
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Please input !'
                                      },]}>
                                    <InputNumber
                                      formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                      parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                      size="large"
                                      style={{ color: 'black', width: "100%", height: '40px' }}
                                      onChange={(value) => checkMoneyCar(value)}
                                    />
                                  </Form.Item>
                                  <Form.Item label='อัตราดอกเบี้ย' name='interestRate'
                                    style={{ marginBottom: 15 }}
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Please input !'
                                      },]}>
                                    <Input type='number' suffix="% +VAT"
                                      style={{ color: 'black' }}
                                      onChange={(e) => setMoney({ ...money, interestRate: parseFloat(e.target.value) })} />
                                  </Form.Item>
                                  <Form.Item label='จำนวนงวด' name='installmentAmountt'
                                    style={{ marginBottom: 15 }}
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Please input !'
                                      },]}>
                                    <Select
                                      style={{ textAlign: 'center', height: '40px' }}
                                      placeholder={'เลือก'}
                                      onChange={(value) => setMoney({ ...money, installmentAmountt: value })}
                                      label="จำนวนงวด"
                                      options={[
                                        { label: '12', value: 12 }, { label: 18, value: 18 }, { label: '24', value: 24 },
                                        { label: '30', value: 30 }, { label: 36, value: 36 }, { label: '42', value: 42 },
                                        { label: '48', value: 48 }, { label: 54, value: 54 }, { label: '60', value: 60 },
                                        { label: '66', value: 66 }, { label: 72, value: 72 }, { label: '78', value: 78 },
                                        { label: '84', value: 84 }, { label: 90, value: 90 }, { label: '96', value: 96 },
                                        { label: '102', value: 102 }, { label: 108, value: 108 }, { label: '114', value: 114 },
                                        { label: '120', value: 120 }
                                      ]}
                                    >
                                    </Select>
                                  </Form.Item>
                                  <Form.Item label='งวดละ' name='monthlyPayment'
                                    style={{ marginBottom: 15 }}
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Please input !'
                                      },]}>
                                    <Input type='number' disabled style={{ color: 'black', height: '40px' }}
                                      onChange={(e) => setMoney({ ...money, monthlyPayment: parseInt(e.target.value) })} />
                                  </Form.Item>
                                  <Form.Item label='ดอกเบี้ย' name='interestt'
                                    style={{ margin: 0 }}
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Please input !'
                                      },]}>
                                    <Input type='number' disabled style={{ color: 'black', height: '40px' }}
                                      onChange={(e) => setMoney({ ...money, interestt: parseInt(e.target.value) })} />
                                  </Form.Item>
                                  <Form.Item label='รวมราคา' name='installmentWithInterestt'
                                    style={{ marginBottom: 15 }}
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Please input !'
                                      },]}>
                                    <Input type='number' disabled style={{ color: 'black', height: '40px' }}
                                      onChange={(e) => setMoney({ ...money, installmentWithInterestt: parseInt(e.target.value) })} />
                                  </Form.Item>
                                  <Form.Item label='ผู้เสนอเคส' name='proposalBy'
                                    style={{ marginBottom: 15 }}
                                  >
                                    <Input disabled style={{ color: 'black', height: '40px' }}
                                      onChange={(e) => setMoney({ ...money, proposalBy: e.target.value })} />
                                  </Form.Item>
                                </>
                                : dataPost2?.productTypeCar > 1 && money?.productType === "p-loan" ?
                                  <>
                                    <Form.Item label='P-LOAN' style={{ margin: 0 }}>
                                      <Button type="primary" style={{ width: "100%" }} onClick={getTable}>
                                        ตารางค่างวด P-LOAN
                                      </Button>
                                      {
                                        openTable ?
                                          <Installment open={openTable} close={setOpenTable} money={handleMoney} type={money} cp={dataPost2?.carPrice} />
                                          : null
                                      }
                                    </Form.Item>
                                    <Form.Item label='ยอดจัด' name='loanAmountt'
                                      style={{ margin: 0 }}
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Please input !'
                                        },]}>
                                      <Input disabled style={{ color: 'black', height: '40px' }}
                                        onChange={(e) => setMoney({ ...money, loanAmountt: e.target.value })} />
                                    </Form.Item>

                                    <Form.Item label='จำนวนงวด' name='installmentAmountt'
                                      style={{ margin: 0 }}
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Please input !'
                                        },]}>
                                      <Input disabled style={{ color: 'black', height: '40px' }}
                                        onChange={(e) => setMoney({ ...money, installmentAmountt: e.target.value })} />
                                    </Form.Item>

                                    <Form.Item label='งวดละ' name='monthlyPayment'
                                      style={{ margin: 0 }}
                                    >
                                      <Input disabled style={{ color: 'black', height: '40px' }}
                                        onChange={(e) => setMoney({ ...money, monthlyPayment: e.target.value })} />
                                    </Form.Item>

                                    <Form.Item label='ดอกเบี้ย' name='interestt'
                                      style={{ margin: 0 }}
                                    >
                                      <Input disabled style={{ color: 'black', height: '40px' }}
                                        onChange={(e) => setMoney({ ...money, interestt: e.target.value })} />
                                    </Form.Item>

                                    <Form.Item label='รวมราคา' name='installmentWithInterestt'
                                      style={{ margin: 0 }}
                                    >
                                      <Input disabled style={{ color: 'black', height: '40px' }}
                                        onChange={(e) => setMoney({ ...money, installmentWithInterestt: e.target.value })} />
                                    </Form.Item>
                                    <Form.Item label='ผู้เสนอเคส' name='proposalBy'
                                      style={{ margin: 0 }}
                                    >
                                      <Input disabled style={{ color: 'black', height: '40px' }}
                                        onChange={(e) => setMoney({ ...money, proposalBy: e.target.value })} />
                                      {/* {currencyFormatOne(money.installmentWithInterestt)} บาท */}
                                    </Form.Item>

                                  </>
                                  : moneyOld && moneyOld?.productLoanType === 'Pre-Aaprove-ย้ายไฟแนนซ์' ?
                                    <>
                                      <Form.Item label='ปิดไฟแนนซ์จาก' name='issuno'
                                        style={{ margin: 0 }}
                                        rules={[
                                          {
                                            required: true,
                                            message: 'Please input !'
                                          },]}>
                                        <Input type='text'
                                          style={{ color: 'black', width: '250px', height: '40px' }}
                                          onChange={(e) => setMoneyOld({ ...moneyOld, issuno: e.target.value.trim() })} />
                                      </Form.Item>
                                      <Form.Item label='ยอดปิด(ที่เดิม)' name='oldLoanAmount'
                                        style={{ margin: 0 }}
                                        rules={[
                                          {
                                            required: true,
                                            message: 'Please input !'
                                          },]}>
                                        <InputNumber
                                          formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                          size="large"
                                          style={{ width: '250px', color: 'black', height: '40px' }}
                                          onChange={(value) => checkMoneyCarSmall({ oldLoanAmount: value })}
                                        />
                                      </Form.Item>
                                      <Form.Item label='ค่างวด/เดือน' name='oldMonthlyPayment'
                                        style={{ margin: 0 }}
                                        rules={[
                                          {
                                            required: true,
                                            message: 'Please input !'
                                          },]}>
                                        <Input
                                          formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                          size="large"
                                          style={{ color: 'black', width: '250px', height: '40px' }}
                                          onChange={(e) => setMoneyOld({ ...moneyOld, oldMonthlyPayment: parseInt(e.target.value) })}
                                        />
                                      </Form.Item>
                                      <Form.Item label='จำนวนงวด' name='oldLoanTerm'
                                        style={{ margin: 0 }}
                                        rules={[
                                          {
                                            required: true,
                                            message: 'Please input !'
                                          },]}>

                                        <Input
                                          formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                          size="large"
                                          style={{ color: 'black', width: '250px', height: '40px' }}
                                          onChange={(e) => setMoneyOld({ ...moneyOld, oldLoanTerm: parseInt(e.target.value) })}
                                        />
                                      </Form.Item>
                                      <Form.Item label='ผู้เสนอเคส' name='proposalBy'
                                        style={{ margin: 0 }}
                                      >
                                        <Input disabled style={{ color: 'black', height: '40px' }}
                                          onChange={(e) => setMoney({ ...money, proposalBy: e.target.value })} />
                                      </Form.Item>

                                    </>
                                    : null
                    }
                  </div>
                </aside>
              </Row>
            </Card>
            <Divider />
            <Col span={24} style={{ textAlign: 'center' }}>
              <Space>
                <Button type='primary' onClick={handleBack} >ย้อนกลับ</Button>
                <Button type='primary' danger onClick={handleClose} >ยกเลิก</Button>
                <Button type='primary' htmlType='submit' style={{ backgroundColor: "green" }} >ต่อไป</Button>
              </Space>
            </Col>
          </Form>
        </Spin >
      </Card >
    </Row >
  )
}

export default CarLoan