import React, { useState, useEffect } from "react";
import { Table, Button, Modal, Form, Input, notification } from "antd";
import axios from "axios";
import Dayjs from "dayjs";
import DataColums from "./dataColums";
import "./css/responsive-table.css";
import DataModal from "./dataModal";
import { loadmaineditdate } from "../../file_mid/all_api";

function MainTabel() {
  const [api, contextHolder] = notification.useNotification();
  const token = localStorage.getItem("token");
  //เก็บ approvalStatus Api ข้อมูลตาราง
  const [dataAPITabel, setDataAPITabel] = useState([]);
  //เก็บเปิด-ปิด Modal
  const [isModalVisible, setIsModalVisible] = useState(false);
  //เก็บข้อมูล Modal
  const [currentRecord, setCurrentRecord] = useState(null);
  //เปิด-ปิด Modal
  const showModal = (record) => {
    setCurrentRecord(record);
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const columnsWithModal = DataColums.map((col) => {
    if (col.title === "เลือก") {
      return {
        ...col,
        render: (text, record) => (
          <Button type="primary" onClick={() => showModal(record)}>
            เลือก
          </Button>
        ),
      };
    }
    return col;
  });
  //form-Error
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  //เก็บเปิด-ปิด ตาราง
  const [dataTabel, setDataTabel] = useState(false);
  //form-Success
  const onFinish = (values) => {
    //console.log("Success:", values.inputText);
    const searchContno = values.inputText;
    if (searchContno) {
      const filteredSearch = dataAPITabel.filter((item) =>
        item.CONTNO.includes(searchContno)
      );
      setDataTabel(filteredSearch);
    }
  };
  //ดึงข้อมูลรถ
  useEffect(() => {
    //token
    const tk = JSON.parse(token);
    const headers = {
      Authorization: `Bearer ${tk}`,
      Menu: JSON.stringify("25"),
    };
    const fetchData = async () => {
      try {
        const res = await axios.get(
          loadmaineditdate,
          { headers: headers }
        );
        console.log("res.dataasdadas", res.data);
        setDataAPITabel(res.data);
      } catch (err) {
        console.log(err.message);
      }
    };
    //const checkName = localStorage.getItem("username");
    //แก้สิทธิเข้าใช้เมนู จากเช็คชื่อผู้ใช้เป็น กลุ่มผู้ใช้
    const checkIdSep = localStorage.getItem("idSep");
    if(checkIdSep === "1" || checkIdSep === "5"){
      fetchData();
    }else{
      openNotification('top')
    }
    
  }, []);

  useEffect(() => {
    setDataTabel(dataAPITabel);
  }, [dataAPITabel]);

  const openNotification = (placement) => {
    api.error({
      message: `รหัสผู้ใช้ ไม่มีสิทธิใช้งาน`,
      description:
        '',
      placement,
    });
  };

  return (
    <div style={{ padding: "20px" }}>
      <center>
        <h1>เคสที่โดนระงับ</h1>
      </center>
      <div className="responsive-table">
        <Form
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item label="ค้นหาเลขสัญญา" style={{ marginBottom: 10 }}>
            <div className="input-button-group">
              <Form.Item name="inputText" noStyle>
                <Input placeholder="กรอก 1 ตัวขึ้นไปเพื่อค้นหา" />
              </Form.Item>
              <Form.Item noStyle>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ backgroundColor: "green", borderColor: "green" }}
                >
                  ค้นหา
                </Button>
              </Form.Item>
            </div>
          </Form.Item>
        </Form>
        <Table
          columns={columnsWithModal}
          dataSource={
            dataTabel
              ? dataTabel.map((item, index) => ({
                  ...item,
                  key: index,
                  sendPgDate: Dayjs(item.sendPgDate).format("DD/MM/YYYY HH:mm"),
                  newOfferDate: Dayjs(item.newOfferDate).format("DD/MM/YYYY HH:mm"),
                }))
              : []
          }
          pagination={false}
        />
      </div>
      <Modal
        title="กรอกข้อมูล"
        open={isModalVisible}
        onCancel={handleCancel}
        width={1000}
        footer={[
          <Button key="submit" type="primary" onClick={handleCancel} style={{ backgroundColor: "red", borderColor: "red" }}>
            ออก
          </Button>,
        ]}
      >
        {/* You can customize the content of the modal based on currentRecord */}
        {currentRecord && (
          <div>
            <DataModal currentRecord={currentRecord} />
          </div>
        )}
      </Modal>
      {contextHolder}
    </div>
  );
}
export default MainTabel;
