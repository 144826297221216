import React, { useState, useEffect } from 'react'
import { Modal, Spin, Col } from 'antd'
import { useDispatch } from 'react-redux';
import { addCar } from '../../../redux/Car';
import AddOcc from './AddOcc';
import Guarantor from './Guarantor';
import UploadImg from './UploadImg';
import Customer from './Customer';
import CheckGua from './CheckGua';
import Detail from './Detail';
import axios from 'axios';
import { deleteNewUpCar, loadDataRe, storeDBLine } from '../../file_mid/all_api';
import CarLoan from './CarLoan';


export default function ModalEditerRe({ open, close, data, isu }) {
    const user = localStorage.getItem('username');
    const { confirm } = Modal
    const dispatch = useDispatch()
    const [count, setCount] = useState(1)
    const [loading, setLoading] = useState(false)
    const [allData, setAllData] = useState()
    const [sendBack, setSendBack] = useState()
    const addCars = localStorage.getItem('addCar')

    // console.log('data',data)

    useEffect(() => {
        loadDataContno()
    }, [sendBack])

    // console.log("allData", allData)

    const loadDataContno = async () => {
        if (data?.reLoanId && data?.customerId && data?.carId) {
            var mainData = { "reLoanId": data?.reLoanId, "customerId": data?.customerId, "carId": data?.carId }
            setLoading(true)
            await axios.post(loadDataRe, mainData)
                .then(async (res) => {
                    // console.log("loadDataContno", res.data)
                    setAllData(res.data)
                    if (!addCars) {
                        localStorage.setItem('addCar', JSON.stringify(res.data?.cars))
                    }
                    localStorage.setItem('addCustomer', JSON.stringify(res.data))
                    localStorage.setItem('addPhone', JSON.stringify(res.data?.phones))
                    localStorage.setItem('addCarLoanDetailsRe', JSON.stringify(res.data?.cars?.carLoanDetailsRe))
                    // if (res.data?.cars?.carLoanDetailsRe?.notesCarRe?.length > 0 ) {
                    //     setNoteCarRe(res.data?.cars?.carLoanDetailsRe?.notesCarRe)
                    // }
                    // if (res.data?.cars?.carLoanDetailsRe?.GRDCOD !== "A") {
                    //     console.log("!A")
                    // await loadCareer()
                    // await loadOccupation()
                    // }
                    setLoading(false)
                }).catch((err) => {
                    console.log(err)
                    alert("ไม่พบข้อมูล !!!")
                })
            setLoading(false)
        } else {
            alert("การโหลดข้อมูลมีปัญหา กรุณาปิดแล้วเปิดใหม่ครับ/ค่ะ")
        }
    }

    const showConfirmCancel = () => {
        confirm({
            title: 'คุณต้องการยกเลิก...?',
            onOk() {
                localStorage.removeItem('addCustomer');
                localStorage.removeItem('addCustomer2');
                localStorage.removeItem('addPhone2');
                localStorage.removeItem('addAddress');
                localStorage.removeItem('addLoan');
                localStorage.removeItem('addGuarantor');
                localStorage.removeItem('addCarLoanDetailsRe');
                localStorage.removeItem('addPhone');
                localStorage.removeItem('addCheckGua');
                localStorage.removeItem('addCareerIncome');
                localStorage.removeItem('addCar');
                localStorage.removeItem('addOccupation');
                handleDeleteStepOne()
                dispatch(addCar());
                close(false);
            },
            onCancel() {
            },
        });
    };

    const Onclose = () => {
        localStorage.removeItem('addCustomer');
        localStorage.removeItem('addCustomer2');
        localStorage.removeItem('addPhone2');
        localStorage.removeItem('addAddress');
        localStorage.removeItem('addLoan');
        localStorage.removeItem('addGuarantor');
        localStorage.removeItem('addCarLoanDetailsRe');
        localStorage.removeItem('addPhone');
        localStorage.removeItem('addCheckGua');
        localStorage.removeItem('addCareerIncome');
        localStorage.removeItem('addCar');
        localStorage.removeItem('addOccupation');
        dispatch(addCar());
        close(false);
    };

    const handleDeleteStepOne = async () => {
        allData?.cars?.img?.map((e) => {
            if (e.type === 4) { // บัตรปชช
                handleDeleteStepTwo(e)
            }
            if (e.type === 2) { // หน้าคู่มือ
                handleDeleteStepTwo(e)
            }
        })
    }

    const handleDeleteStepTwo = async (e) => {
        const id = e.idImage
        const path = e.pathImage
        setLoading(true)
        await axios.delete(`${deleteNewUpCar}/${id}/${path}`)
            .then(async res => {
                if (res.data) {
                    console.log("ลบแล้ว รถ")
                    await handleNoti(path)
                }
            })
            .catch((err) =>
                console.log("ErrorDelete", err)
            )
        setLoading(false)
    }

    const handleNoti = async (path) => {
        const ez = { nameInput: user, path: path }
        await axios.post(storeDBLine, ez)
            .then(res => {
                if (res.data) {
                    console.log("OK")
                    setLoading(false)
                }
            })
            .catch((err) =>
                console.log("ErrorDeleteNoti", err)
            )
        setLoading(false)
    }


    return (
        <div>
            <Modal title="รายละเอียด" open={open} width={"90%"} footer={null} onCancel={showConfirmCancel}>
                <Spin tip="Loading..." spinning={loading} >
                    <div className='main'>
                        <Col span={24}>
                            {count === 1 && (<UploadImg close={showConfirmCancel} page={count} changepage={setCount} data={data} allData={allData} setSendBack={setSendBack} />)} {/* อัพรูป */}
                            {count === 2 && (<Customer close={showConfirmCancel} page={count} changepage={setCount} data={data} />)} {/* ข้อมูลลูกค้า */}
                            {count === 3 && (<CheckGua close={showConfirmCancel} page={count} changepage={setCount} />)} {/* เช็คคนค้ำ */}
                            {count === 4 && (<Guarantor close={showConfirmCancel} page={count} changepage={setCount} data={data} />)} {/* คนค้ำ */}
                            {count === 5 && (<CarLoan close={showConfirmCancel} page={count} changepage={setCount} isu={isu} data={data}/>)} {/* รายละเอียด รถ/ที่ดิน */}
                            {count === 6 && (<AddOcc close={showConfirmCancel} page={count} changepage={setCount} />)} {/* อาชีพ */}
                            {count === 7 && (<Detail close={showConfirmCancel} closeAll={Onclose} page={count} changepage={setCount} data={data} allData={allData} />)} {/* สรุป */}
                        </Col>
                    </div>
                </Spin>
            </Modal>
        </div >
    )
}
