import React, { useState, useEffect } from 'react'
import { Button, Form, Select, Col, Row, Input, Modal, Card, Upload, Spin, Space, InputNumber, Divider } from 'antd';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import Currency from 'currency.js';
import { useDispatch, useSelector } from 'react-redux';
import '../../css/Media.css'
import TextArea from 'antd/es/input/TextArea';
import { productLoanTypeLand } from '../../file_mid/all_options';
import { AllData, GetAmphur1, GetProvince, PostProvince1, createPreLand } from '../../file_mid/all_api';


export default function PreLand() {
    const currentTime = dayjs();
    //const delayedTime = currentTime.add(7, 'hour');
    const formattedTime = currentTime.format('YYYY-MM-DDTHH:mm:ssZ');
    // dayjs.extend(utc);
    // dayjs.extend(timezone);
    // const bangkokTimeZone = 'Asia/Bangkok';
    // dayjs.tz.setDefault(bangkokTimeZone);
    // const timeInBangkok = dayjs().format('YYYY-MM-DD HH:mm:ss');
    const [form] = Form.useForm()
    const user = localStorage.getItem('username');
    const branch = localStorage.getItem('branch');
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const { Option } = Select;
    // const { customers, cars, loans, oldloans, lands, loansLand, oldLoansLand } = useSelector((state) => state)
    const customers = useSelector(state => state.customers)
    const cars = useSelector(state => state.cars)
    const loans = useSelector(state => state.loans)
    const oldloans = useSelector(state => state.oldloans)
    const lands = useSelector(state => state.lands)
    const loansLand = useSelector(state => state.loansLand)
    const oldLoansLand = useSelector(state => state.oldLoansLand)
    const imgs = useSelector(state => state.imgs)
    const [checkSubmit, setCheckSubmit] = useState(false)
    const [dataPost, setDataPost] = useState({
        CreatedAt: "2023-11-27T10:37:41.999+07:00",
        birthdate: "1992-10-02",
        customerId: 1746,
        expdt: "2099-10-01",
        firstname: "zenon4314",
        gender: "M",
        identificationId: "1499999999999",
        issuby: "นายอรรษิษ สัมพันรัตน์",
        issudt: "2023-10-10",
        lastname: "leasing",
        nationalId: "พิเศษ",
        nickname: "onemoneyleasing",
        snam: "นาย",
    })
    const [selectProduct, setSelectProduct] = useState({}) // 1 รถ , 2 ที่ดิน
    //////////////////// ที่ดิน /////////////////////////////
    const [dataPostLand, setDataPostLand] = useState({   // ที่ดิน
        landTypeId: 2,
        productTypeLand: 2,
        landInput: formattedTime,
        landDateStatus: formattedTime,
        rai: 0,
        workArea: 0,
        squareWaArea: 0,
        landPrice: 0,
        resultLandPrice: 0,
        landPriceStatus: false,
    })
    const [moneyLand, setMoneyLand] = useState({
        loanAmounttLand: 0,
        interestRateLand: 1.1,
        productLoanLandType: "Pre-Aaprove",
        proposalBy: user,
        branch: branch,
        productType: "ที่ดิน",
        approvalStatus: 11,
        activeStatus: true,
    }) //loan land
    const [moneyOldLand, setMoneyOldLand] = useState({}) //loanOld land
    //const [typeMoneyLand, setTypeMoneyLand] = useState({})

    const [pvcodeS, setPvcodeS] = useState({});
    const [amcodeS, setAmcodeS] = useState({});

    const [provinces, setProvinces] = useState([]);
    const [district, setDistricts] = useState([]);

    /////////////////////////////////////////////////////
    const [dataPost1, setDataPost1] = useState({}) //customer
    const [checkButtonLand, setCheckButtonLand] = useState(0)

    useEffect(() => {
        // if (customers?.data?.productTypeId === 1) { fetchAllProvinces() }
        if (customers?.data?.productTypeId === 3) {
            getProvinces()
            //loadDataOne()
        }
        if (!lands.data) {
            form.setFieldsValue({
                landTypeId: 2,
                proposalBy: user,
                loanAmounttLand: 50000,
                productLoanLandType: "Pre-Aaprove",
            })
        }
        if (lands.data) {
            console.log("DD")
            getProvinces()
            GetAmphur(lands.data.district)
            //PostAmphur(lands.data.district)
            PostProvince(lands.data.province)
            setSelectProduct({ ...selectProduct, productTypeId: 2 })
            setDataPost1(customers.data)
            setDataPostLand(lands.data)
            // setMoney(loans.data)
            setMoneyLand(loansLand.data)
            setMoneyOldLand(oldLoansLand.data)
            form.setFieldsValue(
                {
                    ...customers.data, ...lands.data, ...loansLand.data, ...oldLoansLand.data,
                    proposalBy: user,
                    birthdate: dayjs(customers.data?.birthdate),
                    issudt: dayjs(customers.data?.issudt),
                    expdt: dayjs(customers.data?.expdt),
                    landInput: dayjs(cars.data?.carInput),
                }
            )
        }

    }, [])

    // useEffect(() => {
    //     if (customers?.data?.productTypeId === 3) {
    //         land_Calculate()
    //     }
    // }, [moneyLand?.loanAmounttLand, moneyLand?.interestRateLand, moneyLand?.loanLandTerm])

    const success = () => {
        Modal.success({
            content: 'บันทึกสำเร็จ',
            path: navigate('/preLandPn')
        })
    }
    const error = () => {
        setCheckSubmit(false)
        Modal.error({
            title: 'บันทึกไม่สำเร็จ',
            content: 'กรุณาลองใหม่อีกครั้ง',
        })
    }
    const unauthorized = () => {
        setCheckSubmit(false)
        Modal.error({
            title: 'unauthorized',
            content: 'คุณไม่มีสิทธิ์ใช้งานเมนูนี้',
        })
    }

    const handleSubmit = async () => {
        const result = { ...dataPost, landLoanDetails: [{ ...moneyLand, land: [{ ...dataPostLand, img: imgs.data }] }] }
        //console.log("result", result)
        setLoading(true)
        if (checkButtonLand === 1 && dataPostLand?.landTypeId === 2) {
            alert("กรุณากดตรวจสอบ")
        } else {
            setCheckSubmit(true)
            await axios.post(createPreLand, result)
                .then(res => {
                    if (res.status === 200) {
                        success()
                        setLoading(false)
                        // navigate('/checkInfoLand')
                    }
                })
                .catch((err) => {
                    if (err.response.request.status === 401) {
                        unauthorized()
                    }
                    else {
                        error()
                    }
                    console.log("err", err)
                    setLoading(false)
                })
        }
        setLoading(false)
    }

    /////////////// ที่อยู่ ของ ที่ดิน //////////////////
    const getProvinces = async () => {
        setLoading(true)
        await axios.get(GetProvince)
            //await axios.get('https://landsmaps.dol.go.th/data/province.json')
            //await axios.get('https://landsmaps.dol.go.th/apiService/JWT/GetJWTAccessToken')
            //await axios.get('https://landsmaps.dol.go.th/apiService/LandsMaps/GetParcelByParcelNo/96/10/10001',{ headers: headers })
            .then((res) => {
                //console.log("Province =", res.data)
                setProvinces(res.data)
                setLoading(false)
            }).catch((err) => {
                console.error(err);
            })
        setLoading(false)
    }

    const PostProvince = async (value) => {
        console.log("value =", value)
        const pr = { pvcode: value }
        //console.log("pr =", pr)
        setLoading(true)
        await axios.post(PostProvince1, pr)
            .then((res) => {
                //console.log("Province =", res.data)
                //console.log("pvcode =", res.data[0].pvcode)
                setPvcodeS(res.data[0].pvcode)
                setProvinces(res.data)
                setLoading(false)
            }).catch((err) => {
                console.error(err);
            })
        setLoading(false)
    }

    const GetAmphur = async (value) => {
        const am = { pvcode: value }
        //console.log("am =", am)
        setLoading(true)
        await axios.post(GetAmphur1, am)
            .then((res) => {
                //console.log("Amphur =", res.data)
                setDistricts(res.data)
                setAmcodeS(res.data[0].amcode)
                setLoading(false)
            }).catch((err) => {
                console.error(err);
            })
        setLoading(false)
    }

    const handleProvinceChange = async (value, key) => {
        setCheckButtonLand(1)
        //console.log("Province =", key["key"], value)
        setPvcodeS(key["key"])
        setDataPostLand({ ...dataPostLand, province: value });
        form.setFieldsValue(
            {
                numberLand: "",
                numberLandLawang: "",
                rai: "",
                workArea: "",
                squareWaArea: "",
                landPrice: "",
                resultLandPrice: "",
                district: "",
                // productLoanLandType: "",
                // loanAmounttLand: "",
                // loanLandTerm: "",
                // interestRateLand: "",
                // interesttLand: "",
                // monthlyPaymentLand: "",
                // installmentWithInterestLand: "",
            }
        )
        setLoading(true)
        const test = { pvcode: key["key"] }
        await axios.post(GetAmphur1, test)
            .then((res) => {
                setDistricts(res.data)
                setLoading(false)
            }).catch((err) => {
                console.error(err);
            })
        setLoading(false)
    };

    const handleDistrictChange = (value, key) => {
        setCheckButtonLand(1)
        //console.log("VV =", key["key"], value)
        setAmcodeS(key["key"])
        setDataPostLand({ ...dataPostLand, district: value });
        form.setFieldsValue(
            {
                numberLand: "",
                numberLandLawang: "",
                rai: "",
                workArea: "",
                squareWaArea: "",
                landPrice: "",
                resultLandPrice: "",
                // productLoanLandType: "",
                // loanAmounttLand: "",
                // loanLandTerm: "",
                // interestRateLand: "",
                // interesttLand: "",
                // monthlyPaymentLand: "",
                // installmentWithInterestLand: "",
            }
        )

    };
    const handleNumberLand = (e) => {
        setCheckButtonLand(1)
        setDataPostLand({ ...dataPostLand, numberLand: e.target.value })
    };

    ///////////////// กรณี นส.3 ///////////////////////////
    const handleProvinceChange1 = async (value, key) => {
        //console.log("Province =", key["key"], value)
        setPvcodeS(key["key"])
        setDataPostLand({ ...dataPostLand, province: value });
        form.setFieldsValue(
            {
                numberLand: "",
                numberLandLawang: "",
                rai: "",
                workArea: "",
                squareWaArea: "",
                district: "",
            }
        )
        setLoading(true)
        const test = { pvcode: key["key"] }
        await axios.post(GetAmphur1, test)
            .then((res) => {
                setDistricts(res.data)
                setLoading(false)
            }).catch((err) => {
                console.error(err);
            })
        setLoading(false)
    };

    const handleDistrictChange2 = (value, key) => {
        //console.log("VV =", key["key"], value)
        setAmcodeS(key["key"])
        setDataPostLand({ ...dataPostLand, district: value });
        form.setFieldsValue(
            {
                numberLand: "",
                numberLandLawang: "",
                rai: "",
                workArea: "",
                squareWaArea: "",
            }
        )

    };


    ////////////// ที่ดิน /////////////////////
    const handleChangeLandType = (value) => {
        if (value === 'ย้ายไฟแนนซ์') {
            setMoneyLand({ ...moneyLand, productLoanLandType: value, proposalBy: user, branch: branch, productType: "ที่ดิน", approvalStatus: 11 })
            setMoneyOldLand({ ...moneyOldLand, productLoanLandType: value })
            // setTypeMoneyLand({ ...typeMoney, productLoanLandType: 'ย้ายไฟแนนซ์', productType: "ที่ดิน" })
        }
        else {
            setMoneyLand({ ...moneyLand, productLoanLandType: value, proposalBy: user, branch: branch, productType: "ที่ดิน", approvalStatus: 11 })
            setMoneyOldLand("")
            form.setFieldsValue(
                {
                    //loanAmounttLand: '',
                    loanLandTerm: '',
                    //interestRateLand: '',
                    interesttLand: '',
                    monthlyPaymentLand: '',
                    installmentWithInterestLand: '',
                    oldLandLoanAmount: '',
                    oldLandMonthlyPayment: '',
                    oldLandLoanTerm: '',
                    oldLandKangPayment: '',
                    oldLandKangPaymentTerm: '',
                }
            )
        }
    }

    const currencyFormatOnes = (amount) => {
        return Number(amount).toFixed(0).replace(/\d(?=(\d{3})+(?!\d))/g, '$&,')
    }
    const currencyFormatOne = (amount) => {
        if (amount) {
            return Number(amount)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, '$&,')
        }
    }
    /////////////////// เลือก 1 = นส.3 กับ 2 = นส.4 //////////////////////
    const handleProductLand = (value) => {
        console.log("landTypeId =", value)
        if (value === 1) {
            setDataPostLand({
                ...dataPostLand,
                productTypeLand: value,
                landTypeId: value,
                numberLand: "-",
                numberLandLawang: "-",
                rai: 0,
                workArea: 0,
                squareWaArea: 0,
                landPrice: 0,
                resultLandPrice: 0,
                landPriceStatus: false,
                subdistrict: null,
            })
            form.setFieldsValue(
                {
                    numberLand: "",
                    numberLandLawang: "",
                    rai: "",
                    workArea: "",
                    squareWaArea: "",
                    landPrice: "",
                    resultLandPrice: "",
                    province: "",
                    district: "",
                }
            )
        } else {
            setDataPostLand({
                ...dataPostLand,
                productTypeLand: value,
                landTypeId: value,
                numberLand: "-",
                numberLandLawang: "-",
                rai: 0,
                workArea: 0,
                squareWaArea: 0,
                landPrice: 0,
                resultLandPrice: 0,
                landPriceStatus: false,
            })
            form.setFieldsValue(
                {
                    numberLand: "",
                    numberLandLawang: "",
                    rai: "",
                    workArea: "",
                    squareWaArea: "",
                    landPrice: "",
                    resultLandPrice: "",
                    province: "",
                    district: "",
                }
            )
        }
    }

    const errorLand = () => {
        setDataPostLand({
            ...dataPostLand,
            numberLandLawang: null,
            rai: null,
            workArea: null,
            squareWaArea: null,
            landPrice: 0,
            resultLandPrice: 0,
            landPriceStatus: false
        });
        form.setFieldsValue(
            {
                numberLandLawang: "",
                rai: "",
                workArea: "",
                squareWaArea: "",
                landPrice: 0,
                resultLandPrice: 0,
            }
        )
        Modal.error({
            title: 'ไม่พบข้อมูล',
            content: 'กรุณาตรวจสอบข้อมูลที่กรอกอีกครั้ง',
        })
    }

    const handleCheckLand = async () => {
        setCheckButtonLand(0)
        console.log("pvcodeS =", pvcodeS)
        console.log("amcodeS =", amcodeS)
        console.log("dataPostLand.numberLand =", dataPostLand.numberLand)
        //const tokens = "eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6IjEiLCJyZWdpc3Rlcl90eXBlX3NlcSI6IjgiLCJ1c2VyX2dyb3VwX2RhdGFfbGlzdCI6IjEsMSwwLDAsMCwwLDAsMSIsInBlcnNvbmFsX2lkIjoiIiwianRpIjoiOTllYmE2OTAtMGFmOC00M2NhLTg2OTktYWUwZDI4N2ZjY2EzIiwiaWF0IjoiMDkvMjcvMjAyMyAwMzoyMDoxNSIsIm5iZiI6MTY5NTc4NDgxNSwiZXhwIjoxNjk1ODcxMjE1LCJpc3MiOiJodHRwczovL2xvY2FsaG9zdDozMDAwMS8iLCJhdWQiOiJMYW5kc01hcHMifQ.Lw3h4tjSbzjscWqkuA9FutwzXPY8UEb49D4ZdsdqKRfR0fwFunY7i4pg5Rdc70Vrw8jDR1qhtQLn5SEqxPguoQ"
        var provids = pvcodeS
        var amphurs = amcodeS
        var landNos = dataPostLand.numberLand
        var result = { pvcode: provids, amcode: amphurs, landNo: landNos }
        //console.log("result =", result)
        const headers = {
            "Content-Type": "application/json",
            // "Authorization": `Bearer ${tokens}`,
        }
        setLoading(true)
        await axios.post(AllData, result, { headers: headers })
            .then((response) => {
                if (response.data) {
                    console.log("DATA =", response.data.result)
                    // console.log("ไร่ =", parseInt(response.data.result[0].rai))
                    // console.log("งาน =", parseInt(response.data.result[0].ngan))
                    // console.log("ตารางวา =", parseInt(response.data.result[0].wa + "." + response.data.result[0].subwa))
                    // console.log("จังหวัด =", response.data.result[0].provname)
                    // console.log("อำเภอ =", response.data.result[0].amphurname)
                    // console.log("ละติจูด =", response.data.result[0].parcellat)
                    // console.log("ลองติจูด =", response.data.result[0].parcellon)
                    // console.log("เลขระวาง =", response.data.result[0].utm)
                    // console.log("ราคาต่อตารางวา =", parseInt(response.data.result[0].landprice.replace(/,/g, "")))
                    const squareWaArea = parseFloat(response.data.result[0].wa + "." + response.data.result[0].subwa)
                    const landPricePerWa = parseInt(response.data.result[0].landprice.replace(/,/g, ""))
                    const totalWa = (
                        ((parseInt(response.data?.result[0].rai) * 400)) +
                        ((parseInt(response.data?.result[0].ngan) * 100)) +
                        parseFloat(response.data?.result[0].wa + "." + response.data?.result[0].subwa)
                    )
                    const totalPrice = Currency(totalWa * landPricePerWa).value
                    console.log("รวมตารางวาทั้งหมด =", totalPrice.toFixed(1));
                    if (landPricePerWa > 0) {
                        setDataPostLand({
                            ...dataPostLand,
                            subdistrict: response.data?.result[0].tumbolname,
                            parcellat: response.data?.result[0].parcellat,
                            parcellon: response.data?.result[0].parcellon,
                            numberLandLawang: response.data?.result[0].utm,
                            rai: parseInt(response.data?.result[0].rai),
                            workArea: parseInt(response.data?.result[0].ngan),
                            squareWaArea: parseFloat(squareWaArea),
                            landPrice: parseInt(response.data.result[0].landprice.replace(/,/g, "")) || 0,
                            resultLandPrice: totalPrice || 0,
                            landPriceStatus: true
                        });
                        form.setFieldsValue(
                            {
                                numberLandLawang: response.data?.result[0].utm,
                                rai: parseInt(response.data?.result[0].rai),
                                workArea: parseInt(response.data?.result[0].ngan),
                                squareWaArea: squareWaArea,
                                landPrice: currencyFormatOne(parseInt(response.data.result[0].landprice.replace(/,/g, ""))) || 0,
                                resultLandPrice: currencyFormatOne(totalPrice) || 0,
                            }
                        )
                    } else {
                        setDataPostLand({
                            ...dataPostLand,
                            subdistrict: response.data?.result[0].tumbolname,
                            parcellat: response.data?.result[0].parcellat,
                            parcellon: response.data?.result[0].parcellon,
                            numberLandLawang: response.data?.result[0].utm,
                            rai: parseInt(response.data?.result[0].rai),
                            workArea: parseInt(response.data?.result[0].ngan),
                            squareWaArea: parseFloat(squareWaArea),
                            landPrice: 0,
                            resultLandPrice: 0,
                            landPriceStatus: false
                        });
                        form.setFieldsValue(
                            {
                                numberLandLawang: response.data?.result[0].utm,
                                rai: parseInt(response.data?.result[0].rai),
                                workArea: parseInt(response.data?.result[0].ngan),
                                squareWaArea: squareWaArea,
                                landPrice: 0,
                                resultLandPrice: 0,
                            }
                        )
                    }
                    setLoading(false)
                } else {
                    console.log("DATA =", response.data)
                }
            })
            .catch((err) => {
                setDataPostLand({
                    ...dataPostLand,
                    parcellat: "-",
                    parcellon: "-",
                    numberLandLawang: "-",
                    rai: 0,
                    workArea: 0,
                    squareWaArea: 0,
                    landPrice: 0,
                    resultLandPrice: 0,
                    landPriceStatus: false
                });
                errorLand()
            })
            .finally(() => {
                setLoading(false);
            })
        setLoading(false)
    }


    const land_Calculate = () => {
        if (moneyLand.loanAmounttLand && moneyLand.interestRateLand && moneyLand.loanLandTerm) {
            var interestRateLand = moneyLand.interestRateLand / 100 // อัตราดอก / 100
            var rate = ((moneyLand.loanAmounttLand * Currency(interestRateLand, { precision: 5 }).value) * moneyLand.loanLandTerm) // (ยอดจัด * อัตราดอกที่หาร100แล้ว ,precision: 3 คือทศนิยม 3 )*งวด
            var loanTerm = Math.ceil(Currency((rate + moneyLand.loanAmounttLand) / moneyLand.loanLandTerm, { precision: 2 }).value) // (ดอกเบี้ยทั้งหมด + ยอดจัด)/ งวด
            var resultRate = (loanTerm * moneyLand.loanLandTerm) - moneyLand.loanAmounttLand
            var resultPrice = loanTerm * moneyLand.loanLandTerm
            setMoneyLand({
                ...moneyLand,
                // loanLandTerm: parseInt(event.target.value), //งวด
                monthlyPaymentLand: loanTerm,
                interesttLand: resultRate,
                installmentWithInterestLand: resultPrice
            })
            if (moneyLand.loanAmounttLand >= 50000 && moneyLand.loanAmounttLand <= 99999) {
                var feeLoan7 = moneyLand.loanAmounttLand * 0.07 // 7%
                //console.log("7%")
                if (feeLoan7 <= 5000) {
                    form.setFieldsValue(
                        {
                            fee: Currency(feeLoan7, { precision: 2 }).value,
                            monthlyPaymentLand: loanTerm,
                            interesttLand: resultRate,
                            installmentWithInterestLand: resultPrice
                        }
                    )
                } else {
                    form.setFieldsValue(
                        {
                            fee: 5000,
                            monthlyPaymentLand: loanTerm,
                            interesttLand: resultRate,
                            installmentWithInterestLand: resultPrice
                        }
                    )
                }
            }
            if (moneyLand.loanAmounttLand >= 100000 && moneyLand.loanAmounttLand <= 999999) {
                var feeLoan5 = moneyLand.loanAmounttLand * 0.05 // 5%
                //console.log("5%")
                form.setFieldsValue(
                    {
                        fee: Currency(feeLoan5, { precision: 2 }).value,
                        monthlyPaymentLand: loanTerm,
                        interesttLand: resultRate,
                        installmentWithInterestLand: resultPrice
                    }
                )
            }
            if (moneyLand.loanAmounttLand >= 1000000) {
                //console.log("3%")
                var feeLoan3 = moneyLand.loanAmounttLand * 0.03 // 3%
                form.setFieldsValue(
                    {
                        fee: Currency(feeLoan3, { precision: 2 }).value,
                        monthlyPaymentLand: loanTerm,
                        interesttLand: resultRate,
                        installmentWithInterestLand: resultPrice
                    }
                )
            }
        }
        else {
            form.setFieldsValue(
                {
                    fee: 0,
                    monthlyPaymentLand: 0,
                    interesttLand: 0,
                    installmentWithInterestLand: 0
                }
            )
        }
    }


    const handleGoHome = () => {
        // navigate('/offercase/addcustomer')
        navigate('/offercase')
    }

    return (
        <Row justify={'center'}>
            <Card style={{ width: '1100px' }}>
                <Spin spinning={loading} size='large' tip=" Loading... ">
                    <Row style={{ textAlign: 'center', marginTop: '30px' }} >
                        <Col span={24} style={{ fontSize: '30px' }} >รายละเอียด</Col>
                    </Row>
                    <Divider />
                    <Form
                        labelCol={{
                            span: 10,
                        }}
                        wrapperCol={{
                            span: 24,
                        }}
                        style={{
                            maxWidth: "100%",
                        }}
                        form={form}
                        onFinish={handleSubmit}
                        autoComplete="off"
                        initialValues={{ remember: true }}
                    >
                        <Card>
                            <Row className='main2' justify={'center'}>
                                <aside>
                                    <div style={{ marginRight: 15, marginLeft: 15 }}>
                                        {/* ที่ดิน */}

                                        {dataPost && customers?.data?.productTypeId === 3 && (
                                            <>
                                                <div>
                                                    <Row justify={'center'}>
                                                        <Col span={20}>
                                                            <Form.Item label='ประเภทเอกสาร' name='landTypeId'
                                                                style={{ marginLeft: 15 }}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please input !'
                                                                    },]}>
                                                                <Select placeholder={'เลือก'}

                                                                    onChange={(value) => handleProductLand(value)}
                                                                //onChange={(value) => setDataPost2({ ...dataPost2, productTypeId: value })}
                                                                >
                                                                    <Option value={1}>นส.3 ก.</Option>
                                                                    <Option value={2}>นส.4 จ.</Option>
                                                                </Select>
                                                            </Form.Item>
                                                            {/* นส3 */}
                                                            {selectProduct && dataPostLand?.landTypeId === 1 && (
                                                                <>
                                                                    <Col style={{ margin: 15 }}>
                                                                        <Form.Item name='province' label='จังหวัด'
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: 'Please input !'
                                                                                },]}>
                                                                            <Select
                                                                                showSearch
                                                                                loading={loading}
                                                                                name='province'
                                                                                placeholder="จังหวัด"
                                                                                onChange={handleProvinceChange1}
                                                                            >
                                                                                {provinces?.result?.map((pro, index) => (
                                                                                    <Option key={pro.pvcode} value={pro.pvnamethai}>
                                                                                        {pro.pvnamethai}
                                                                                    </Option>
                                                                                ))}
                                                                            </Select>
                                                                        </Form.Item>

                                                                        <Form.Item name='district' label='อำเภอ'
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: 'Please input !'
                                                                                },]}>
                                                                            <Select
                                                                                showSearch
                                                                                loading={loading}
                                                                                name='district'
                                                                                placeholder="อำเภอ"
                                                                                onChange={handleDistrictChange2}
                                                                            >
                                                                                {district?.map((dis, index) => (
                                                                                    <Option key={dis.amcode} value={dis.amnamethai}>
                                                                                        {dis.amnamethai}
                                                                                    </Option>
                                                                                ))}
                                                                            </Select>
                                                                        </Form.Item>

                                                                        <Form.Item label='เลขที่โฉนด/เลขที่' name='numberLand'
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: 'Please input !'
                                                                                },]}>
                                                                            <Input type='text'
                                                                                onChange={(e) => setDataPostLand({ ...dataPostLand, numberLand: e.target.value })}></Input>
                                                                        </Form.Item>

                                                                        <Form.Item label='พื้นที่ไร่' name='rai'
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: 'Please input !'
                                                                                },]}>
                                                                            <Input type='number' suffix="ไร่"
                                                                                onChange={(e) => setDataPostLand({ ...dataPostLand, rai: parseInt(e.target.value) })}></Input>
                                                                        </Form.Item>

                                                                        <Form.Item label='พื้นที่งาน' name='workArea'
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: 'Please input !'
                                                                                },]}>
                                                                            <Input type='number' suffix="งาน"
                                                                                onChange={(e) => setDataPostLand({ ...dataPostLand, workArea: parseInt(e.target.value) })}></Input>
                                                                        </Form.Item>

                                                                        <Form.Item label='พื้นที่ตารางวา' name='squareWaArea'
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: 'Please input !'
                                                                                },]}>
                                                                            <Input type='number' suffix="ตารางวา"
                                                                                onChange={(e) => setDataPostLand({ ...dataPostLand, squareWaArea: parseFloat(e.target.value) })}></Input>
                                                                        </Form.Item>
                                                                    </Col>
                                                                </>
                                                            )}
                                                            {/* นส4 */}
                                                            {selectProduct && dataPostLand?.landTypeId === 2 && (
                                                                <>
                                                                    <Col style={{ margin: 15 }}>
                                                                        <Form.Item name='province' label='จังหวัด'
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: 'Please input !'
                                                                                },]}>
                                                                            <Select
                                                                                showSearch
                                                                                loading={loading}
                                                                                name='province'
                                                                                placeholder="จังหวัด"
                                                                                onChange={handleProvinceChange}
                                                                            >
                                                                                {provinces?.result?.map((pro, index) => (
                                                                                    <Option key={pro.pvcode} value={pro.pvnamethai}>
                                                                                        {pro.pvnamethai}
                                                                                    </Option>
                                                                                ))}
                                                                            </Select>
                                                                        </Form.Item>

                                                                        <Form.Item name='district' label='อำเภอ'
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: 'Please input !'
                                                                                },]}>
                                                                            <Select
                                                                                showSearch
                                                                                loading={loading}
                                                                                name='district'
                                                                                placeholder="อำเภอ"
                                                                                onChange={handleDistrictChange}
                                                                            >
                                                                                {district?.map((dis, index) => (
                                                                                    <Option key={dis.amcode} value={dis.amnamethai}>
                                                                                        {dis.amnamethai}
                                                                                    </Option>
                                                                                ))}
                                                                            </Select>
                                                                        </Form.Item>

                                                                        <Form.Item label='เลขโฉนดที่ดิน' name='numberLand'
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: 'Please input !'
                                                                                },]}>
                                                                            <Input type='text'
                                                                                //onChange={(e) => setDataPostLand({ ...dataPostLand, numberLand: e.target.value })}></Input>
                                                                                onChange={(e) => handleNumberLand(e)}></Input>
                                                                        </Form.Item>

                                                                        <Form.Item label='ตรวจสอบ'>
                                                                            <Button type="primary" onClick={handleCheckLand}>ตรวจสอบ</Button>
                                                                        </Form.Item>

                                                                        <Form.Item label='ระวาง' name='numberLandLawang'>
                                                                            <Input type='text' disabled style={{ color: 'black' }}
                                                                                onChange={(e) => setDataPostLand({ ...dataPostLand, numberLandLawang: e.target.value })}></Input>
                                                                        </Form.Item>

                                                                        <Form.Item label='พื้นที่ไร่' name='rai'>
                                                                            <Input type='text' suffix="ไร่" disabled style={{ color: 'black' }}
                                                                                onChange={(e) => setDataPostLand({ ...dataPostLand, rai: parseInt(e.target.value) })}></Input>
                                                                        </Form.Item>

                                                                        <Form.Item label='พื้นที่งาน' name='workArea'>
                                                                            <Input type='text' suffix="งาน" disabled style={{ color: 'black' }}
                                                                                onChange={(e) => setDataPostLand({ ...dataPostLand, workArea: parseInt(e.target.value) })}></Input>
                                                                        </Form.Item>

                                                                        <Form.Item label='พื้นที่ตารางวา' name='squareWaArea'>
                                                                            <Input type='text' suffix="ตารางวา" disabled style={{ color: 'black' }}
                                                                                onChange={(e) => setDataPostLand({ ...dataPostLand, squareWaArea: parseFloat(e.target.value) })}></Input>
                                                                        </Form.Item>

                                                                        <Form.Item label='ราคาประเมินที่ดิน' name='landPrice'>
                                                                            <Input type='text' suffix="บาทต่อตารางวา" disabled style={{ color: 'black' }}
                                                                                onChange={(e) => setDataPostLand({ ...dataPostLand, landPrice: parseInt(e.target.value) })}></Input>
                                                                        </Form.Item>

                                                                        <Form.Item label='ราคารวม' name='resultLandPrice'>
                                                                            <Input type='text' suffix="บาท" disabled style={{ color: 'black' }}
                                                                                onChange={(e) => setDataPostLand({ ...dataPostLand, resultLandPrice: parseInt(e.target.value) })}></Input>

                                                                        </Form.Item>

                                                                    </Col>
                                                                </>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </>
                                        )}
                                    </div>

                                    <div style={{ marginLeft: 15, marginLeft: 15 }}>
                                        {/* โลนที่ดิน */}

                                        {dataPost && customers?.data?.productTypeId === 3 && (
                                            <>
                                                <Col style={{ margin: 15 }}>
                                                    <Form.Item label='ประเภทขอกู้ที่ดิน' name='productLoanLandType'>
                                                        <Select
                                                            disabled
                                                            //placeholder={'เลือก'}
                                                            onChange={(value) => { handleChangeLandType(value) }}
                                                            options={productLoanTypeLand}
                                                        />
                                                    </Form.Item>

                                                    {/* <Form.Item label='ยอดจัด' name='loanAmounttLand'>
                                                        <InputNumber
                                                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                            size="large"
                                                            style={{ width: '100%', color: 'black' }}
                                                            onChange={(value) => setMoneyLand({ ...moneyLand, loanAmounttLand: parseInt(value) })}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item>
                                                        <span style={{ color: 'red' }}><u>ยอดจัดขั้นต่ำ 50,000 บาท</u></span>
                                                    </Form.Item>
                                                    <Form.Item label='อัตราดอกเบี้ย' name='interestRateLand'>
                                                        <Input defaultValue={1.1} type='number' suffix="% +VAT" disabled
                                                            onChange={(e) => setMoneyLand({ ...moneyLand, interestRateLand: parseFloat(e.target.value) })} />
                                                    </Form.Item>

                                                    <Form.Item label='ค่าธรรมเนียม' name='fee'>
                                                        <Input defaultValue={3500} type='number' disabled suffix="บาท" style={{ color: 'black' }}
                                                            onChange={(e) => setMoneyLand({ ...moneyLand, fee: parseFloat(e.target.value) })} />
                                                    </Form.Item>

                                                    <Form.Item label='จำนวนงวด' name='loanLandTerm'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <InputNumber
                                                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                            suffix="งวด"
                                                            size="large"
                                                            style={{ width: '100%', color: 'black' }}
                                                            onChange={(value) => setMoneyLand({ ...moneyLand, loanLandTerm: parseInt(value) })}
                                                        />
                                                    </Form.Item>

                                                    <Form.Item label='งวดละ' name='monthlyPaymentLand'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <Input type='number' suffix="บาท" disabled style={{ color: 'black' }}
                                                            onChange={(e) => setMoneyLand({ ...moneyLand, monthlyPaymentLand: parseInt(e.target.value) })} />
                                                    </Form.Item>


                                                    <Form.Item label='ดอกเบี้ย' name='interesttLand'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <Input type='number' suffix="บาท" disabled style={{ color: 'black' }}
                                                            onChange={(e) => setMoneyLand({ ...moneyLand, interesttLand: parseInt(e.target.value) })} />
                                                    </Form.Item>


                                                    <Form.Item label='รวมราคา' name='installmentWithInterestLand'>
                                                        <Input disabled style={{ color: 'black' }} suffix="บาท"
                                                            onChange={(e) => setMoneyLand({ ...moneyLand, installmentWithInterestLand: parseInt(e.target.value) })} />
                                                    </Form.Item> */}

                                                    <Col style={{ margin: 15 }}>
                                                        <Form.Item label='ผู้เสนอ' name='proposalBy'>
                                                            <Input disabled style={{ color: 'black' }}
                                                                onChange={(e) => setMoneyLand({ ...moneyLand, proposalBy: e.target.value })} />
                                                        </Form.Item>
                                                    </Col>
                                                </Col>
                                            </>
                                        )}
                                    </div>
                                </aside>
                            </Row>
                            {dataPost && customers?.data?.productTypeId === 3 && (
                                <Row justify={'center'}>
                                    <Col span={22}>
                                        <span><b style={{ fontSize: '25' }}>สตอรี่รายละเอียดที่ดิน (อธิบายข้อมูลของที่ดินแปลงนั้น / ที่นา ที่บ้าน บ้านกี่หลัง)</b></span> <p />
                                        <Form.Item name="storyLand" label=""
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'กรุณากรอกสตอรี่รายละเอียดที่ดิน ไม่มีให้ใส่ - '
                                                },]}>
                                            <TextArea
                                                name='storyLand'
                                                style={{ color: 'blue' }}
                                                rows={8}
                                                onChange={(e) => setDataPostLand({ ...dataPostLand, storyLand: e.target.value.trim() })}
                                            >
                                            </TextArea>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            )}
                            <Divider />

                            <Row justify={'center'}>
                                <Space>
                                    {/* <Link to='/offercase'> */}
                                    <Button
                                        type="text"
                                        style={{ background: '#bfbfbf', color: '#000000', }}
                                        onClick={(e) => { handleGoHome(e) }}
                                    >
                                        ย้อนกลับ
                                    </Button>
                                    {/* </Link> */}
                                    {dataPost && customers?.data?.productTypeId === 3 && (
                                        <>
                                            {
                                                checkSubmit === false ?
                                                    <>
                                                        <Button type="primary" style={{ background: '#389e0d', color: '#ffffff' }} htmlType="submit" >
                                                            บันทึก
                                                        </Button>
                                                    </> :
                                                    <>
                                                        <Button type='primary' htmlType="submit" disabled
                                                            style={{ color: 'success' }}
                                                        >
                                                            บันทึก
                                                        </Button>
                                                    </>
                                            }
                                        </>
                                    )}
                                </Space>
                            </Row>
                        </Card>
                    </Form>
                </Spin>
            </Card>
        </Row>
    )
}
