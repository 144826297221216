import React, { useState, useEffect } from 'react'
import { Form, Modal, Row, Col, Space, Card, Button, Tag, Divider, Checkbox, notification, Image } from 'antd'
import dayjs from 'dayjs';
import axios from "axios";
import TextArea from 'antd/es/input/TextArea';
import NotePN from "./NotePN";
import ModalMoreNote from './ModalMoreNote';
import ModalAddLand from './ModalAddLand';
import ModalShowImages from '../../ModalShowImageSlide/ModalShowImages'
import { checklandpntest, findlandpn, getImagess } from '../../file_mid/all_api';
import {
  DownloadOutlined,
  LeftOutlined,
  RightOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  SwapOutlined,
  UndoOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons';
import { auto } from 'async';
import { ApprovalStatusColor } from '../../file_mid/status_color';

export default function ModalInfoLand({ open, dataFromTable, close }) {

  const token = localStorage.getItem('token');
  const [dataLand, setDataLand] = useState(dataFromTable);
  const [newdataLand, setNewDataLand] = useState([]);
  const [newdataLandNew, setNewDataLandNew] = useState([]);
  const [form] = Form.useForm()

  const [modalnote, setModalNote] = useState(false)
  const [appStatus, setAppStatus] = useState({})
  const [foundInfo, setFoundInfo] = useState(false)
  const [showOldData2, setShowOldData2] = useState([])
  const [isModalShowNote, setisModalShowNote] = useState(false)
  const [isModalAddLand, setIsModalAddLand] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [dataEdit, setDataEdit] = useState()
  const [dataEditIndex, setDataEditIndex] = useState()
  const [checkEdit, setCheckEdit] = useState(false)
  const [test, settest] = useState()
  const [result, setResult] = useState(null);
  const [mainLand, setMainLand] = useState();
  const [api, contextHolder] = notification.useNotification();
  const [openShowImage, setOpenShowImage] = useState(false);
  // const [currentIndex, setCurrentIndex] = useState(0);
  const [carimage1, setCarImage1] = useState([]);
  const [carimagenew1, setCarImagenew1] = useState([]);
  const [carimage2, setCarImage2] = useState([]);
  const [carimagenew2, setCarImagenew2] = useState([]);
  const [carimage3, setCarImage3] = useState([]);
  const [carimagenew3, setCarImagenew3] = useState([]);
  const [carimage4, setCarImage4] = useState([]);
  const [carimagenew4, setCarImagenew4] = useState([]);
  const [carimage5, setCarImage5] = useState([]);
  const [carimagenew5, setCarImagenew5] = useState([]);
  const [carimage6, setCarImage6] = useState([]);
  const [carimagenew6, setCarImagenew6] = useState([]);
  const [carimage7, setCarImage7] = useState([]);
  const [carimagenew7, setCarImagenew7] = useState([]);
  const [carimage8, setCarImage8] = useState([]);
  const [carimagenew8, setCarImagenew8] = useState([]);
  const [carimage9, setCarImage9] = useState([]);
  const [carimagenew9, setCarImagenew9] = useState([]);
  const [carimage10, setCarImage10] = useState([]);
  const [carimagenew10, setCarImagenew10] = useState([]);
  const [carimage11, setCarImage11] = useState([]);
  const [carimagenew11, setCarImagenew11] = useState([]);
  const [carimage12, setCarImage12] = useState([]);
  const [carimagenew12, setCarImagenew12] = useState([]);
  const [carimage14, setCarImage14] = useState([]);
  const [carimagenew14, setCarImagenew14] = useState([]);
  const [carshowMadol, setCarShowModal] = useState([]); //send to Modal Show Image
  const [nuarantorNew, setGuarantorNew] = useState([]); //send to Modal Show Image
  const [nuarantorNum, setGuarantorNum] = useState(); //send to Modal Show Image
  const [imageBlobzz, setImageBlobzz] = useState([]);
  const [current, setCurrent] = React.useState(0);
  const [selectedImages, setSelectedImages] = useState([]);

  const { confirm } = Modal

  // console.log("carshowMadol", carshowMadol)
  // console.log("newdataLand", newdataLand)
  // console.log("carimage1", carimage1)
  // console.log("carimage3", carimage3)
  // console.log("carimage10", carimage10)
  // console.log("carimage11", carimage11)
  // console.log("result", result)

  useEffect(() => {
    loadDataDup()
    loadMixData()
  }, [])

  useEffect(() => {
    makeOldMinMax()
    // loadImage1()

  }, [showOldData2])

  useEffect(() => {
    newdataLand.map((e, index) => {
      form.setFieldsValue({
        [`storyLand${index}`]: e?.storyLand,
      })
      return null
    })

  }, [newdataLand])


  const handleSelectImage = (e, image) => {
    // ล็อกิกการเพิ่มรูปภาพเข้าใน selectedImages
    setSelectedImages(prevSelected => [...prevSelected, image]);
  };

  const onDownload = () => {
    const imageObj = imageBlobzz[current]; // ดึง object ของภาพที่ต้องการ
    const filename = `${Date.now()}_${imageObj.name}`; // สร้างชื่อไฟล์

    // ใช้ fetch ดึงรูปภาพจาก path
    fetch(imageObj.path)
      .then((response) => response.blob()) // เปลี่ยน response เป็น blob
      .then((blob) => {
        // สร้าง Blob URL สำหรับการดาวน์โหลด
        const blobUrl = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = filename; // ตั้งชื่อไฟล์สำหรับการดาวน์โหลด
        document.body.appendChild(link);
        link.click(); // จำลองการคลิกเพื่อดาวน์โหลด
        URL.revokeObjectURL(blobUrl); // ยกเลิก Blob URL หลังการใช้งาน
        link.remove(); // ลบ link ออกจาก DOM
      })
      .catch((error) => {
        console.error("Error downloading the image:", error);
      });

  }

  function extractFolderGroup(path) {
    const match = path?.pathImage?.match(/\/5\/(\d+)\//);  // ดึงตัวเลขที่อยู่ในรูปแบบ /5/x/
    return match ? match[1] : null;
  }

  // const loadImage1 = async () => {

  //   newdataLand?.map((data, i) => {
  //     // สร้างตัวแปรเพื่อเก็บรูปภาพแต่ละประเภท
  //     var carimg1 = [];
  //     var carimg3 = [];
  //     var carimg10 = [];
  //     var carimg11 = [];

  //     data?.img?.map((imgs, j) => {
  //       console.log("imgs", imgs, j);

  //       if (imgs?.type === 1) {
  //         carimg1.push(imgs);
  //       } else if (imgs?.type === 3) {
  //         carimg3.push(imgs);
  //       } else if (imgs?.type === 10) {
  //         carimg10.push(imgs);
  //       } else if (imgs?.type === 11) {
  //         carimg11.push(imgs);
  //       }
  //     });

  //     // เซ็ตค่ารูปภาพให้กับตัวแปร state ที่เก็บรูปภาพแต่ละประเภท
  //     setCarImage1(carimg1);
  //     setCarImage3(carimg3);
  //     setCarImage10(carimg10);
  //     setCarImage11(carimg11);
  //   });
  // };

  const loadImage = async (value, data) => {
    var carimg1 = [];
    var carimg2 = [];
    var carimg3 = [];
    var carimg4 = [];
    var carimg5 = [];
    var carimg6 = [];
    var carimg7 = [];
    var carimg8 = [];
    var carimg9 = [];
    var carimg10 = [];
    var carimg11 = [];
    var carimg12 = [];
    var carimg14 = [];
    var carimgnew1 = [];
    var carimgnew2 = [];
    var carimgnew3 = [];
    var carimgnew4 = [];
    var carimgnew5 = [];
    var carimgnew6 = [];
    var carimgnew7 = [];
    var carimgnew8 = [];
    var carimgnew9 = [];
    var carimgnew10 = [];
    var carimgnew11 = [];
    var carimgnew12 = [];
    var carimgnew14 = [];
    if (value === 'land') {
      setImageBlobzz(data)
      data?.map((land, i) => {
        console.log("land", land)
        if (land.type === 1) {
          var PreAaproveSplit = []
          PreAaproveSplit = land.pathImage.split("/")
          if (PreAaproveSplit[3] !== "13" && land?.type === 1) {
            carimg1.push(land)
            setCarImage1(carimg1)
          } else if (PreAaproveSplit[3] === "13" && land?.type === 1) {
            carimgnew1.push(land)
            setCarImagenew1(carimgnew4)
          }
          // carimg1.push(land)
          // setCarImage1(carimg1)
        } else if (land.type === 3) {
          var PreAaproveSplit = []
          PreAaproveSplit = land.pathImage.split("/")
          if (PreAaproveSplit[3] !== "13" && land?.type === 3) {
            carimg3.push(land)
            setCarImage3(carimg3)
          } else if (PreAaproveSplit[3] === "13" && land?.type === 3) {
            carimgnew3.push(land)
            setCarImagenew3(carimgnew3)
          }
          // carimg3.push(land)
          // setCarImage3(carimg3)
        } else if (land.type === 4) {
          var PreAaproveSplit = []
          PreAaproveSplit = land.pathImage.split("/")
          if (PreAaproveSplit[3] !== "13" && land?.type === 4) {
            carimg4.push(land)
            setCarImage4(carimg4)
          } else if (PreAaproveSplit[3] === "13" && land?.type === 4) {
            carimgnew4.push(land)
            setCarImagenew4(carimgnew4)
          }
          // carimg4.push(land)
          // setCarImage4(carimg4)
        } else if (land.type === 5) {
          var group1 = [];
          const group = extractFolderGroup(land);
          if (group === '1') {
            group1.push({ ...land, num: 1 });
          } else if (group === '2') {
            group1.push({ ...land, num: 2 });
          } else if (group === '3') {
            group1.push({ ...land, num: 3 });
          } else if (group === '4') {
            group1.push({ ...land, num: 4 });
          } else if (group === '5') {
            group1.push({ ...land, num: 5 });
          } else if (group === '6') {
            group1.push({ ...land, num: 6 });
          }
          carimg5.push(...group1)
          setCarImage5(carimg5)
        } else if (land.type === 6) {
          var PreAaproveSplit = []
          PreAaproveSplit = land.pathImage.split("/")
          if (PreAaproveSplit[3] !== "13" && land?.type === 6) {
            carimg6.push(land)
            setCarImage6(carimg6)
          } else if (PreAaproveSplit[3] === "13" && land?.type === 6) {
            carimgnew6.push(land)
            setCarImagenew6(carimgnew6)
          }
          // carimg6.push(land)
          // setCarImage6(carimg6)
        } else if (land.type === 7) {
          var PreAaproveSplit = []
          PreAaproveSplit = land.pathImage.split("/")
          if (PreAaproveSplit[3] !== "13" && land?.type === 7) {
            carimg7.push(land)
            setCarImage7(carimg7)
          } else if (PreAaproveSplit[3] === "13" && land?.type === 7) {
            carimgnew7.push(land)
            setCarImagenew7(carimgnew7)
          }
          // carimg7.push(land)
          // setCarImage7(carimg7)
        } else if (land.type === 8) {
          var PreAaproveSplit = []
          PreAaproveSplit = land.pathImage.split("/")
          if (PreAaproveSplit[3] !== "13" && land?.type === 8) {
            carimg8.push(land)
            setCarImage8(carimg8)
          } else if (PreAaproveSplit[3] === "13" && land?.type === 8) {
            carimgnew8.push(land)
            setCarImagenew8(carimgnew8)
          }
          // carimg8.push(land)
          // setCarImage8(carimg8)
        } else if (land.type === 9) {
          var PreAaproveSplit = []
          PreAaproveSplit = land.pathImage.split("/")
          if (PreAaproveSplit[3] !== "13" && land?.type === 9) {
            carimg9.push(land)
            setCarImage9(carimg9)
          } else if (PreAaproveSplit[3] === "13" && land?.type === 9) {
            carimgnew9.push(land)
            setCarImagenew9(carimgnew9)
          }
          // carimg9.push(land)
          // setCarImage9(carimg9)
        } else if (land.type === 10) {
          var PreAaproveSplit = []
          PreAaproveSplit = land.pathImage.split("/")
          if (PreAaproveSplit[3] !== "13" && land?.type === 10) {
            carimg10.push(land)
            setCarImage10(carimg10)
          } else if (PreAaproveSplit[3] === "13" && land?.type === 10) {
            carimgnew10.push(land)
            setCarImagenew10(carimgnew10)
          }
          // carimg10.push(land)
          // setCarImage10(carimg10)
        } else if (land.type === 11) {
          var PreAaproveSplit = []
          PreAaproveSplit = land.pathImage.split("/")
          if (PreAaproveSplit[3] !== "13" && land?.type === 11) {
            carimg11.push(land)
            setCarImage11(carimg11)
          } else if (PreAaproveSplit[3] === "13" && land?.type === 11) {
            carimgnew11.push(land)
            setCarImagenew11(carimgnew11)
          }
          // carimg11.push(land)
          // setCarImage11(carimg11)
        } else if (land.type === 14) {
          var PreAaproveSplit = []
          PreAaproveSplit = land.pathImage.split("/")
          if (PreAaproveSplit[3] !== "13" && land?.type === 14) {
            carimg14.push(land)
            setCarImage14(carimg14)
          } else if (PreAaproveSplit[3] === "13" && land?.type === 14) {
            carimgnew14.push(land)
            setCarImagenew14(carimgnew14)
          }
          // carimg14.push(land)
          // setCarImage14(carimg14)
        } else {
          var PreAaproveSplit = []
          PreAaproveSplit = land.pathImage.split("/")
          if (PreAaproveSplit[3] !== "13" && land?.type === 12) {
            carimg12.push(land)
            setCarImage12(carimg12)
          } else if (PreAaproveSplit[3] === "13" && land?.type === 12) {
            carimgnew12.push(land)
            setCarImagenew12(carimgnew12)
          }
          // carimg12.push(land)
          // setCarImage12(carimg12)
        }
      })
    }
  }


  const errorCheckMain = (placement) => {
    api.warning({
      message: "กรุณาเลือกที่ดินหลัก",
      // description: 
      // 'กรุณาเลือกที่ดินอื่น',
      placement,
    });
  };

  const makeOldMinMax = async () => {
    for (let i = showOldData2?.length - 1; i >= 0; i--) {
      if (showOldData2[i]?.approval?.id === 0) {
        // หากเจอค่า 0 ให้ทำต่อ
        continue;
      } else {
        // เจอค่าที่ไม่ใช่ 0 ให้เก็บค่านั้นและหยุด
        setResult(showOldData2[i]);
        break;
      }
    }
  }

  const onApprove = async () => {
    if (result !== null) {
      setNewDataLand([
        {
          ...newdataLand[0],
          approval: {
            ...newdataLand[0].approval,
            minPrice: parseInt(result.approval.minPrice),
            maxPrice: parseInt(result.approval.maxPrice),
            note: result.approval.note
          }
        },
        ...newdataLand.slice(1) // คงค่า index อื่นๆ ไว้เหมือนเดิม
      ])
    }

    if (newdataLand.length > 1) {
      var checkMain = true
      newdataLand.map((item, i) => {

        if (item.landMain === false || item.landMain === undefined) {
          checkMain = false
        }
        if (item.landMain === true || item.landMain !== undefined) {
          checkMain = true
        }
      })
      if (checkMain) {
        setAppStatus("อนุมัติ")
        setModalNote(true)
      } else {
        errorCheckMain("top")
      }
    } else {
      setAppStatus("อนุมัติ")
      setModalNote(true)
    }
  }
  const onUnApprove = async () => {
    setAppStatus("ปัดตก")
    setModalNote(true)
  }

  const onRequest = async () => {
    setAppStatus("ขอเอกสารเพิ่ม")
    setModalNote(true)
  }

  const handleviewImg = (index) => {
    localStorage.setItem('viewPN', JSON.stringify(showOldData2[index]))
    window.open('/view-pn', '_blank');
  }

  const loadMixData = async () => {
    await axios.post(findlandpn, { landLoanId: dataFromTable.landLoanId })
      .then(res => {
        if (res.status === 200) {
          console.log("res.data", res.data)
          setNewDataLand(res.data)
          loadImage('land', res.data[0].img)
        }
      }).catch((err) => {
        console.log("err", err)
      })
  }


  ////////////////////////////////////////////////////////////////
  const openModalImages = (data, index) => {
    // console.log("data", data, index);
    let imageToShow = [];
    var carimg1 = [...data];
    var carimg3 = [...data];
    var carimg10 = [...data];
    var carimg11 = [...data];

    if (data[index].type === 3) {
      imageToShow = carimg3; // เลือกรูปภาพจาก carimage1 โดยใช้ index ที่ได้จากการคลิก
      // carimg1.push(data)
      setCarImage1(carimg3);
    } else if (data[index].type === 10) {
      imageToShow = carimg10;  // เลือกรูปภาพจาก carimage3 โดยใช้ index ที่ได้จากการคลิก
      // carimg3.push(data)
      setCarImage3(carimg10);

    } else if (data[index].type === 11) {
      imageToShow = carimg11;  // เลือกรูปภาพจาก carimage10 โดยใช้ index ที่ได้จากการคลิก
      // carimg10.push(data)
      setCarImage10(carimg11);

    } else if (data[index].type === 1) {
      imageToShow = carimg1;  // เลือกรูปภาพจาก carimage11 โดยใช้ index ที่ได้จากการคลิก
      // carimg11.push(data)
      setCarImage11(carimg1);

    }
    // console.log("imageToShow", imageToShow)
    setCarShowModal(imageToShow);
    setOpenShowImage(true);
  };

  const loadDataDup = async () => {
    await axios.post(checklandpntest, { numberLand: dataFromTable.numberLand, provinces: dataFromTable.province, district: dataFromTable.district })
      .then(res => {
        if (res.status === 200) {
          var cutData = res.data
          if (res.data.length > 1) {
            setFoundInfo(true)
            cutData.forEach((e, index) => {
              if (e.landLoanDetails.landLoanId === dataFromTable.landLoanId) {
                cutData.splice(index, 1);
              }
            });
            setShowOldData2(cutData)
          }
        }
      }).catch((err) => {
        console.log("err", err)
      })
  }


  const handleMoreNote = (value) => {
    settest(value)
    setisModalShowNote(true)
  };

  const CloseMoreNote = () => {
    setisModalShowNote(false)
  };

  const handleOk = () => {
    close(false);
  };

  const handleCancel = () => {
    close(false);
  };
  const handleCancel2 = (value) => {
    setModalNote(value)
  };
  const handleCloseAllModal = (value) => {
    setModalNote(value)
    close(value)
  }

  const currencyFormatOne = (amount) => {
    return Number(amount)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, '$&,')
  }

  const handleEditLand = async (value, index) => {
    setCheckEdit(true)
    setDataEdit(value)
    setDataEditIndex(index)
    setIsModalAddLand(true)
  }

  const funcEdit = (value, index) => {
    let swapData = [...newdataLand]
    swapData[index] = value
    setNewDataLand(swapData);
  }

  const funcAdd = (value) => {
    setNewDataLand(pre => [...pre, { ...value }]);
  }

  const handleAddLand = async () => {
    setIsModalAddLand(true)
  }

  const handleEditMode = async () => {
    if (editMode) {
      setEditMode(false)
    } else {
      setEditMode(true)
    }
  }

  const handleDeleteLand = (value, index) => {
    confirm({
      title: 'ต้องการที่จะยกเลิกจดรวมข้อมูลหรือไม่...?',
      content: 'กด OK เพื่อยืนยันการยกเลิกจดรวม',
      onOk() {
        const deletearray = [...newdataLand]
        deletearray.splice(index, 1)
        setNewDataLand(deletearray)
      },
      onCancel() {
      },
    });
  };

  const handleSelectMainLand = (value, index) => {
    // console.log("value", value)
    setMainLand(index)
    const updatedLandMain = newdataLand.map((item, index2) => {
      if (index2 === index) {
        return { ...item, landMain: true };
      } else {
        return { ...item, landMain: false };
      }
    });
    setNewDataLand(updatedLandMain);
  };

  const renderOldData = ({ item, index }) => {
    var note = item.notes?.length;
    var lastIndex = note - 1;

    return (
      <>
        <Row gutter={32} justify={'center'}>
          <Col className='gutter-row' span={24}>
            <>
              <Card key={index} style={{ width: '100%', textAlign: 'left' }}>
                <div style={{ marginBottom: 0, textAlign: 'right' }}>
                  <Tag color={ApprovalStatusColor(item?.landLoanDetails?.approvalStatus)}>{item.landLoanDetails?.approvalStatusTable?.name}</Tag>
                </div>
                {
                  item.landLoanDetails.landLoanId === dataFromTable.landLoanId ?
                    <>
                      <div style={{ marginBottom: 0, textAlign: 'right' }}>
                        <Tag color="green">เคสปัจจุบัน</Tag>
                      </div>
                    </>
                    :
                    <>
                      <div style={{ marginBottom: 0, textAlign: 'right' }}>
                        <Tag color="red">เคสที่เคยเสนอมาแล้ว</Tag>
                      </div>
                    </>
                }
                <Divider orientation="left">วันที่การเสนอ</Divider>
                <div style={{ marginBottom: 0 }}>
                  <span>เสนอเมื่อ : </span>
                  <b>{dayjs(item.landInput).format("DD/MM/YYYY HH:mm")}</b>
                </div>
                <div style={{ marginBottom: 0 }}>
                  <span>ผู้เสนอเคส : </span>
                  <b>{item.landLoanDetails.proposalBy}</b>
                </div>
                <Divider orientation="left">รายละเอียดที่ดิน</Divider>
                <div style={{ marginBottom: 0 }}>
                  <span>ประเภทที่ดิน : </span>
                  <b>{item.productTypeLand === 1 ? "นส.3 ก." : "นส.4 จ."}</b>
                </div>
                <div style={{ marginBottom: 0 }}>
                  <span>เลขโฉนดที่ดิน : </span>{<b>{item?.numberLand}</b>}
                </div>
                <div style={{ marginBottom: 0 }}>
                  <span>เลขที่ดินหรือระวาง : </span>{<b>{item?.numberLandlawang}</b>}
                </div>
                <div style={{ marginBottom: 0 }}>
                  <span>จังหวัด : </span>{<b>{item?.provinces}</b>}
                </div>
                <div style={{ marginBottom: 0 }}>
                  <span>อำเภอ : </span>{<b>{item?.district}</b>}
                </div>
                <div style={{ marginBottom: 0 }}>
                  <span>พื้นที่ไร่ : </span>{<b>{item?.rai}</b>}
                  <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                    ไร่
                  </span>
                </div>
                <div style={{ marginBottom: 0 }}>
                  <span>พื้นที่งาน : </span>{<b>{item?.workArea}</b>}
                  <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                    งาน
                  </span>
                </div>
                <div style={{ marginBottom: 0 }}>
                  <span>พื้นที่ตารางวา : </span>{<b>{currencyFormatOne(item?.squareWaArea)}</b>}
                  <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                    บาท
                  </span>
                </div>
                <div style={{ marginBottom: 0 }}>
                  <span>ราคาประเมินที่ดิน : </span> {<b>{item?.landPrice}</b>}
                  <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                    บาท / ตารางวา
                  </span>
                </div>
                <div style={{ marginBottom: 0 }}>
                  <span>ราคารวม : </span> {<b>{item?.resultLandPrice}</b>}
                  <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                    บาท
                  </span>
                </div>
                <span>
                  <Button type='primary' onClick={() => handleviewImg(index)} >รูปเอกสาร</Button>
                  {/* <Button type='primary' onClick={handleviewImg} >รูปเอกสาร</Button> */}
                </span>
                <Divider orientation="left">หมายเหตุ</Divider>
                {
                  item?.landLoanDetails.approvalStatus === 13 || item?.landLoanDetails.approvalStatus === 15 ?
                    <>
                      <div style={{ marginBottom: 0 }}>
                        <span>สาเหตุ : </span>
                        <b>{item?.notes[lastIndex]?.cause}</b>
                      </div>
                      <div style={{ marginBottom: 0 }}>
                        <span>สถานะที่ทำการหมายเหตุ : </span>
                        <b>{item?.notes[lastIndex]?.status}</b>
                      </div>
                      <div style={{ marginBottom: 0 }}>
                        <span>ผู้สร้างหมายเหตุ : </span>
                        <b>{item?.notes[lastIndex]?.noteBy}</b>
                      </div>
                      <div style={{ marginBottom: 0 }}>
                        <span>เมื่อวันที่ : </span>
                        <b>{dayjs(item?.notes[lastIndex]?.noteDate).format("DD-MM-YYYY")}</b>
                      </div>
                      {
                        note > 1 ?
                          <>
                            <Button onClick={() => handleMoreNote(item.notes, index)}>เพิ่มเติม</Button>
                            {/* <Button onClick={((index) => handleMoreNote(index))}>เพิ่มเติม</Button> */}

                          </>
                          : null
                      }
                    </>
                    :
                    <>
                      {
                        item?.approval.id !== 0 ?
                          <>
                            <div style={{ marginBottom: 0 }}>
                              <span>หมายเหตุ : </span>{<b>{item?.approval.note}</b>}
                            </div>
                            <div style={{ marginBottom: 0 }}>
                              <span>ราคาต่ำสุด-สูงสุด : </span>{<b>{item?.approval.minPrice} - {item?.approval.maxPrice}</b>}
                            </div>
                            <div style={{ marginBottom: 0 }}>
                              <span>เคาะราคาโดย : </span>{<b>{item?.approval.proposalPriceBy}</b>}
                            </div>
                            <div style={{ marginBottom: 0 }}>
                              <span>เมื่อวันที่ : </span>{<b>{dayjs(item?.approval.inputPriceDate).format('DD-MM-YYYY')}</b>}
                            </div>
                          </>
                          :
                          <>
                            <div style={{ marginBottom: 0 }}>
                              <span style={{ color: "red" }}>*ยังไม่เคาะราคา!</span>
                            </div>
                          </>
                      }
                    </>
                }
              </Card>

            </>
          </Col>
        </Row>
      </>
    )

  }

  const renderLandData = ({ item, index }) => {

    return (
      <>
        <Row gutter={32} justify={'center'}>
          <Col className='gutter-row' span={24}>
            <>
              <Card key={index} style={{ width: '100%', textAlign: 'left' }}>
                <Col style={{ marginBottom: 0, textAlign: 'right' }}>
                  <Tag color={ApprovalStatusColor(~~item.landLoanDetails.approvalStatus)}>{item.landLoanDetails.approvalStatusTable?.name}</Tag>
                </Col>
                <Divider orientation={'left'}><b><u>รายละเอียดที่ดิน ({item?.numberLand})</u></b></Divider>
                <Row gutter={32} justify={'center'}>
                  <aside style={{ width: '90%' }}>
                    <div style={{ marginBottom: 0 }}>
                      <span>กลุ่มสินค้า : </span>{<b>{item?.landLoanDetails.productType}</b>}
                    </div>
                    <div style={{ marginBottom: 0 }}>
                      <span>ประเภทเอกสาร : </span>{<b>{item?.productTypeLand === 1 ? "นส.3" : "นส.4"}</b>}
                    </div>
                    <div style={{ marginBottom: 0 }}>
                      <span>เลขโฉนดที่ดิน : </span>{<b>{item?.numberLand}</b>}
                    </div>
                    <div style={{ marginBottom: 0 }}>
                      <span>เลขที่ดินหรือระวาง : </span>{<b>{item?.numberLandlawang}</b>}
                    </div>
                    <div style={{ marginBottom: 0 }}>
                      <span>จังหวัด : </span>{<b>{item?.provinces}</b>}
                    </div>
                    <div style={{ marginBottom: 0 }}>
                      <span>อำเภอ : </span>{<b>{item?.district}</b>}
                    </div>
                    <div style={{ marginBottom: 0 }}>
                      <span>พื้นที่ไร่ : </span>{<b>{item?.rai}</b>}
                      <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                        ไร่
                      </span>
                    </div>
                    <div style={{ marginBottom: 0 }}>
                      <span>พื้นที่งาน : </span>{<b>{item?.workArea}</b>}
                      <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                        งาน
                      </span>
                    </div>
                    <div style={{ marginBottom: 0 }}>
                      <span>พื้นที่ตารางวา : </span>{<b>{currencyFormatOne(item?.squareWaArea)}</b>}
                      <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                        บาท
                      </span>
                    </div>
                    <div style={{ marginBottom: 0 }}>
                      <span>ราคาประเมินที่ดิน : </span> {<b>{item?.landPrice}</b>}
                      <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                        บาท / ตารางวา
                      </span>
                    </div>
                    <div style={{ marginBottom: 0 }}>
                      <span>ราคารวม : </span> {<b>{item?.resultLandPrice}</b>}
                      <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                        บาท
                      </span>
                    </div>

                  </aside>
                  {
                    editMode ?
                      <>
                        {
                          index === 0 ?
                            null :
                            <>
                              <Space direction='vertical'>
                                <Button style={{ width: "100px", backgroundColor: "#DCD9D4" }} onClick={() => handleEditLand(newdataLand[index], index)}>เปลี่ยนที่ดิน</Button>
                                <Button style={{ width: "100px" }} type='primary' danger onClick={() => handleDeleteLand(newdataLand[index], index)}>ยกเลิกจดรวม</Button>
                              </Space>
                            </>
                        }
                      </> : null
                  }
                </Row>
                <Divider orientation={'left'}><b><u>สตอรี่รายละเอียดที่ดิน อธิบายข้อมูลของที่ดินแปลงนั้น / ที่นา ที่บ้าน บ้านกี่หลัง ({item?.numberLand})</u></b></Divider>
                <Form.Item name={`storyLand${index}`} label="">
                  <TextArea style={{ color: 'blue' }} rows={5} disabled />
                </Form.Item>
              </Card>
            </>
          </Col>
        </Row>
      </>
    )

  }

  const renderSelectMainLand = ({ item, index }) => { // เลือกที่ดินหลัก
    return (
      <>
        <Checkbox checked={mainLand === index} onChange={() => handleSelectMainLand(item, index)}><b>ที่ดิน {item.numberLand}</b></Checkbox>
      </>
    )
  }

  const renderCoordinates = ({ item, index }) => { // พิกัด
    return (
      <>
        <div>
          <span>รายละเอียดพิกัด ({item?.numberLand}) : </span>
          <a href={`https://www.google.com/maps?q=${item.parcellat},${item.parcellon}`} target="_blank">
            {`${item.parcellat},${item.parcellon}`}
          </a>
        </div>
      </>
    )
  }

  const renderTitleDeed = ({ item, imgs, imgsid, index }) => {
    console.log("item", item,);
    console.log("imgs", imgs,);
    console.log("imgsid", imgsid,);
    var items = [item]

    return (
      <>
        <Row justify={'left'}><b><u>รูปโฉนด/หน้า-หลัง ({item.numberLand})</u></b></Row>
        {items.map((itemData, itemIndex) => (
          <>
            <React.Fragment key={itemIndex}>
              <Row justify={'center'}>
                <Col span={24} style={{ textAlign: 'center' }}>
                  <Space>
                    <Image.PreviewGroup
                      preview={{
                        countRender: (index, total) => {
                          const imageCounts3 = carimage3?.length || 0; // นับจำนวนภาพจริง
                          const displayIndex = Math.min(index, imageCounts3);
                          const displayTotal = Math.min(imageCounts3, imageCounts3);
                          return `${displayIndex} / ${displayTotal}`;
                        }, // ใช้เพื่อแสดงจำนวนภาพ
                        toolbarRender: (
                          _,
                          {
                            transform: { scale },
                            actions: {
                              onActive,
                              onFlipY,
                              onFlipX,
                              onRotateLeft,
                              onRotateRight,
                              onZoomOut,
                              onZoomIn,
                              onReset,
                            },
                          },
                        ) => (
                          <>
                            <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                              {carimage3?.map((e, index) => {
                                if (e.type === 3 && e?.landId === imgsid) {
                                  return (
                                    <>
                                      <Image
                                        width={auto}
                                        key={index}
                                        onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                        src={`${getImagess}/${e.pathImage}?token=${token}`}
                                        alt={`รูปภาพ ${index + 1}`}
                                        style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                      />
                                    </>
                                  );
                                }
                              })}
                            </Row>
                            <Row style={{ zIndex: 2 }}>
                              <Space size={12} className="toolbar-wrapper">
                                <LeftOutlined onClick={() => onActive?.(-1)} />
                                <RightOutlined onClick={() => onActive?.(1)} />
                                <DownloadOutlined onClick={onDownload} />
                                <SwapOutlined rotate={90} onClick={onFlipY} />
                                <SwapOutlined onClick={onFlipX} />
                                <RotateLeftOutlined onClick={onRotateLeft} />
                                <RotateRightOutlined onClick={onRotateRight} />
                                <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                <UndoOutlined onClick={onReset} />
                              </Space>
                            </Row>
                          </>
                        ),
                        onChange: (index) => {
                          setCurrent(index);
                        },
                      }}
                    >
                      <Row gutter={32} justify={'center'}>
                        {carimage3?.map((e, index) => {
                          if (e.type === 3 && e?.landId === imgsid) {
                            return (
                              <>
                                <Image
                                  width={150}
                                  key={index}
                                  onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                  src={`${getImagess}/${e.pathImage}?token=${token}`}
                                  alt={`รูปภาพ ${index + 1}`}
                                  style={{ cursor: 'pointer' }}
                                />
                              </>
                            );
                          }
                        })}
                      </Row>

                    </Image.PreviewGroup>
                  </Space>
                </Col>
              </Row>
              <Divider />
            </React.Fragment>
          </>
        ))}
      </>
    );
  };

  const renderDivision = ({ item, imgs, imgsid, index }) => { // render ใบแบ่งใบต่อ
    var items = [item]
    return (
      <>
        <Row justify={'left'}><b><u>ใบแบ่งใบต่อ ({item.numberLand})</u></b></Row>
        {items.map((itemData, itemIndex) => (
          <>
            <React.Fragment key={itemIndex}>
              <Row justify={'center'}>
                <Col span={24} style={{ textAlign: 'center' }}>
                  <Space>
                    <Image.PreviewGroup
                      preview={{
                        countRender: (index, total) => {
                          const imageCounts10 = carimage10?.length || 0; // นับจำนวนภาพจริง
                          const displayIndex = Math.min(index, imageCounts10);
                          const displayTotal = Math.min(imageCounts10, imageCounts10);
                          return `${displayIndex} / ${displayTotal}`;
                        }, // ใช้เพื่อแสดงจำนวนภาพ
                        toolbarRender: (
                          _,
                          {
                            transform: { scale },
                            actions: {
                              onActive,
                              onFlipY,
                              onFlipX,
                              onRotateLeft,
                              onRotateRight,
                              onZoomOut,
                              onZoomIn,
                              onReset,
                            },
                          },
                        ) => (
                          <>
                            <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                              {carimage10?.map((e, index) => {
                                if (e.type === 10 && e?.landId === imgsid) {
                                  return (
                                    <>
                                      <Image
                                        width={auto}
                                        key={index}
                                        onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                        src={`${getImagess}/${e.pathImage}?token=${token}`}
                                        alt={`รูปภาพ ${index + 1}`}
                                        style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                      />
                                    </>
                                  );
                                }
                              })}
                            </Row>
                            <Row style={{ zIndex: 2 }}>
                              <Space size={12} className="toolbar-wrapper">
                                <LeftOutlined onClick={() => onActive?.(-1)} />
                                <RightOutlined onClick={() => onActive?.(1)} />
                                <DownloadOutlined onClick={onDownload} />
                                <SwapOutlined rotate={90} onClick={onFlipY} />
                                <SwapOutlined onClick={onFlipX} />
                                <RotateLeftOutlined onClick={onRotateLeft} />
                                <RotateRightOutlined onClick={onRotateRight} />
                                <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                <UndoOutlined onClick={onReset} />
                              </Space>
                            </Row>
                          </>
                        ),
                        onChange: (index) => {
                          setCurrent(index);
                        },
                      }}
                    >
                      <Row gutter={32} justify={'center'}>
                        {carimage10?.map((e, index) => {
                          if (e.type === 10 && e?.landId === imgsid) {
                            return (
                              <>
                                <Image
                                  width={150}
                                  key={index}
                                  onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                  src={`${getImagess}/${e.pathImage}?token=${token}`}
                                  alt={`รูปภาพ ${index + 1}`}
                                  style={{ cursor: 'pointer' }}
                                />
                              </>
                            );
                          }
                        })}
                      </Row>
                    </Image.PreviewGroup>
                  </Space>
                </Col>
              </Row>
              <Divider />
            </React.Fragment>
          </>
        ))}
      </>
    )
  }
  const renderLawang = ({ item, imgs, imgsid, index }) => { // render ระวาง
    var items = [item]
    return (
      <>
        <Row justify={'left'}><b><u>ระวาง ({item.numberLand})</u></b></Row>
        {items.map((itemData, itemIndex) => (
          <>
            <React.Fragment key={itemIndex}>
              <Row justify={'center'}>
                <Col span={24} style={{ textAlign: 'center' }}>
                  <Space>
                    <Image.PreviewGroup
                      preview={{
                        countRender: (index, total) => {
                          const imageCounts11 = carimage11?.length || 0; // นับจำนวนภาพจริง
                          const displayIndex = Math.min(index, imageCounts11);
                          const displayTotal = Math.min(imageCounts11, imageCounts11);
                          return `${displayIndex} / ${displayTotal}`;
                        }, // ใช้เพื่อแสดงจำนวนภาพ
                        toolbarRender: (
                          _,
                          {
                            transform: { scale },
                            actions: {
                              onActive,
                              onFlipY,
                              onFlipX,
                              onRotateLeft,
                              onRotateRight,
                              onZoomOut,
                              onZoomIn,
                              onReset,
                            },
                          },
                        ) => (
                          <>
                            <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                              {carimage11?.map((e, index) => {
                                if (e.type === 11 && e?.landId === imgsid) {
                                  return (
                                    <>
                                      <Image
                                        width={auto}
                                        key={index}
                                        onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                        src={`${getImagess}/${e.pathImage}?token=${token}`}
                                        alt={`รูปภาพ ${index + 1}`}
                                        style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                      />
                                    </>
                                  );
                                }
                              })}
                            </Row>
                            <Row style={{ zIndex: 2 }}>
                              <Space size={12} className="toolbar-wrapper">
                                <LeftOutlined onClick={() => onActive?.(-1)} />
                                <RightOutlined onClick={() => onActive?.(1)} />
                                <DownloadOutlined onClick={onDownload} />
                                <SwapOutlined rotate={90} onClick={onFlipY} />
                                <SwapOutlined onClick={onFlipX} />
                                <RotateLeftOutlined onClick={onRotateLeft} />
                                <RotateRightOutlined onClick={onRotateRight} />
                                <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                <UndoOutlined onClick={onReset} />
                              </Space>
                            </Row>
                          </>
                        ),
                        onChange: (index) => {
                          setCurrent(index);
                        },
                      }}
                    >

                      <Row gutter={32} justify={'center'}>
                        {carimage11?.map((e, index) => {
                          if (e.type === 11 && e?.landId === imgsid) {
                            return (
                              <>
                                <Image
                                  width={150}
                                  key={index}
                                  onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                  src={`${getImagess}/${e.pathImage}?token=${token}`}
                                  alt={`รูปภาพ ${index + 1}`}
                                  style={{ cursor: 'pointer' }}
                                />
                              </>
                            );
                          }
                        })}
                      </Row>
                    </Image.PreviewGroup>
                  </Space>
                </Col>
              </Row>
              <Divider />
            </React.Fragment>
          </>
        ))}
      </>
    )
  }
  const renderLandBuildings = ({ item, imgs, imgsid, index }) => { // render รูปที่ดิน/สิ่งปลูกสร้าง-ที่ดินปัจจุบัน
    var items = [item]
    return (
      <>
        <Row justify={'left'}><b><u>รูปที่ดิน/สิ่งปลูกสร้าง-ที่ดินปัจจุบัน ({item.numberLand})</u></b></Row>

        {items.map((itemData, itemIndex) => (
          <>
            <React.Fragment key={itemIndex}>
              <Row justify={'center'}>
                <Col span={24} style={{ textAlign: 'center' }}>
                  <Space>

                    <Image.PreviewGroup
                      preview={{
                        countRender: (index, total) => {
                          const imageCounts1 = carimage1?.length || 0; // นับจำนวนภาพจริง
                          const displayIndex = Math.min(index, imageCounts1);
                          const displayTotal = Math.min(imageCounts1, imageCounts1);
                          return `${displayIndex} / ${displayTotal}`;
                        }, // ใช้เพื่อแสดงจำนวนภาพ
                        toolbarRender: (
                          _,
                          {
                            transform: { scale },
                            actions: {
                              onActive,
                              onFlipY,
                              onFlipX,
                              onRotateLeft,
                              onRotateRight,
                              onZoomOut,
                              onZoomIn,
                              onReset,
                            },
                          },
                        ) => (
                          <>
                            <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                              {carimage1?.map((e, index) => {
                                if (e.type === 1 && e?.landId === imgsid) {
                                  return (
                                    <>
                                      <Image
                                        width={auto}
                                        key={index}
                                        onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                        src={`${getImagess}/${e.pathImage}?token=${token}`}
                                        alt={`รูปภาพ ${index + 1}`}
                                        style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                      />
                                    </>
                                  );
                                }
                              })}
                            </Row>
                            <Row style={{ zIndex: 2 }}>
                              <Space size={12} className="toolbar-wrapper">
                                <LeftOutlined onClick={() => onActive?.(-1)} />
                                <RightOutlined onClick={() => onActive?.(1)} />
                                <DownloadOutlined onClick={onDownload} />
                                <SwapOutlined rotate={90} onClick={onFlipY} />
                                <SwapOutlined onClick={onFlipX} />
                                <RotateLeftOutlined onClick={onRotateLeft} />
                                <RotateRightOutlined onClick={onRotateRight} />
                                <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                <UndoOutlined onClick={onReset} />
                              </Space>
                            </Row>
                          </>
                        ),
                        onChange: (index) => {
                          setCurrent(index);
                        },
                      }}


                    >

                      <Row gutter={32} justify={'center'}>
                        {carimage1?.map((e, index) => {
                          if (e.type === 1 && e?.landId === imgsid) {
                            return (
                              <>
                                <Image
                                  width={150}
                                  key={index}
                                  onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                  src={`${getImagess}/${e.pathImage}?token=${token}`}
                                  alt={`รูปภาพ ${index + 1}`}
                                  style={{ cursor: 'pointer' }}
                                />
                              </>
                            );
                          }
                        })}
                      </Row>
                    </Image.PreviewGroup>
                  </Space>
                </Col>
              </Row>
              <Divider />
            </React.Fragment>
          </>
        ))}
      </>
    )
  }

  return (
    <>
      <Modal open={open} onOk={handleOk} onCancel={handleCancel} width={'90%'}
        footer={[
          <Row justify={'center'}>
            <Button key="back" type='primary' danger onClick={handleCancel}>
              ปิด
            </Button>
          </Row>
        ]} >

        <Row justify={'center'}>
          <Col className='gutter-row' span={24} style={{ fontSize: '25px', textAlign: 'center' }}>
            <b>รายละเอียด</b>
          </Col>
        </Row>
        <Row justify={'center'}>
          <Divider style={{ margin: 5 }} />
          <Form
            labelCol={{
              span: 12,
            }}
            wrapperCol={{
              span: 24,
            }}
            style={{
              width: '90%',
            }}
            form={form}
            // onFinish={handleSubmit}
            autoComplete="off"
            initialValues={{ remember: true }}
          >

            <style>
              {`
                .toolbar-wrapper {
                    padding: 0px 24px;
                    color: #fff;
                    font-size: 20px;
                    background-color: rgba(0, 0, 0, 0.1);
                    border-radius: 100px;
                }

                .toolbar-wrapper .anticon {
                    padding: 12px;
                    cursor: pointer;
                }

                .toolbar-wrapper .anticon[disabled] {
                    cursor: not-allowed;
                    opacity: 0.3;
                }

                .toolbar-wrapper .anticon:hover {
                    opacity: 0.3;
                }
                `}
            </style>
            <div className='main'>
              <Card style={{ width: '100%' }}>
                <Row justify={'center'}>
                  <Col span={24} style={{ textAlign: 'right' }}>
                    <div style={{ margin: 0 }}>
                      ชื่อการตลาดที่เสนอ : <Tag color="geekblue">{dataLand?.proposalBy}</Tag>
                    </div>
                    <div style={{ margin: 0 }}>
                    </div>
                  </Col>
                  <Col span={24} style={{ textAlign: 'right' }}>
                    <div style={{ margin: 0 }}>
                      สถานะ : <Tag color={ApprovalStatusColor(dataLand?.approvalStatus)}>{dataLand?.approvalStatusName}</Tag>
                    </div>
                    <div style={{ margin: 0 }}>
                    </div>
                  </Col>
                </Row>
                <Divider />
                {
                  foundInfo === true ?
                    <>
                      <Row justify={'left'} style={{ color: 'red' }}><b><u> *พบข้อมูลที่เคยเสนอมาแล้ว!</u></b></Row>
                      <Row gutter={32} justify={'center'}>
                        {
                          showOldData2?.map((item, index) => {
                            return renderOldData({ item, index })
                          })
                        }
                      </Row>
                      <Divider />
                    </>
                    :
                    null
                }

                {/* <Row justify={'left'} style={{ color: 'red' }}><b><u> *LAND!</u></b></Row> */}
                <Col gutter={32} justify={'center'}>
                  {
                    newdataLand?.map((item, index) => {
                      return renderLandData({ item, index })
                    })
                  }
                </Col>
                <Divider />
                {
                  newdataLand.length > 1 ?
                    <>
                      <Row justify={'left'}><b><u>เลือกที่ดินหลัก</u></b></Row>
                      <Row gutter={32} justify={'center'}>
                        {
                          newdataLand?.map((item, index) => {
                            return renderSelectMainLand({ item, index })
                          })
                        }
                      </Row>
                      <Divider />
                    </>
                    : null
                }
                {
                  dataLand?.parcellat ?
                    <>
                      <Row justify={'left'}><b><u>พิกัด</u></b></Row>
                      <Row gutter={32} justify={'center'}>
                        <aside style={{ width: '90%' }}>
                          <div style={{ marginBottom: 0 }} >
                            {/* <span>รายละเอียดพิกัด : </span> */}
                            {
                              newdataLand?.map((item, index) => {
                                return renderCoordinates({ item, index })
                              })
                            }
                            {/* <a href={`https://www.google.com/maps?q=${dataLand.parcellat},${dataLand.parcellon}`} target="_blank">
                              {`${dataLand.parcellat},${dataLand.parcellon}`}
                            </a> */}
                          </div>
                        </aside>
                      </Row>
                    </>
                    : null
                }
                <Divider />

                {
                  newdataLand?.map((item, index) => {
                    // console.log("item1105", item)
                    const imgs = item?.img
                    const imgsid = item?.landId
                    return renderTitleDeed({ item, imgs, imgsid, index })
                    // })
                  })
                }
                {
                  newdataLand?.map((item, index) => {
                    // console.log("item1096", item)
                    const imgs = item?.img
                    const imgsid = item?.landId
                    return renderDivision({ item, imgs, imgsid, index })
                  })
                }
                {
                  newdataLand?.map((item, index) => {
                    // console.log("item1102", item)
                    const imgs = item?.img
                    const imgsid = item?.landId
                    return renderLawang({ item, imgs, imgsid, index })
                  })
                }
                {
                  newdataLand?.map((item, index) => {
                    // console.log("item1105", item)
                    const imgs = item?.img
                    const imgsid = item?.landId
                    return renderLandBuildings({ item, imgs, imgsid, index })
                  })
                }
                <Divider />
                <Col className='gutter-row' span={24} style={{ textAlign: 'right' }}>
                  <Space>
                    <Button type='primary' style={{ margin: '5px', }} onClick={onRequest} >ขอเอกสารเพิ่ม</Button>
                    <Button type='primary' style={{ backgroundColor: "orange" }} onClick={handleAddLand} >เพิ่มที่ดิน</Button>
                    {
                      editMode ?
                        <Button type='primary' style={{ backgroundColor: "orange" }} onClick={handleEditMode} >ยกเลิกแก้ไข</Button>
                        :
                        <Button type='primary' style={{ backgroundColor: "orange" }} onClick={handleEditMode} >แก้ไข</Button>
                    }

                    <Button style={{ background: 'red', color: '#ffffff' }} onClick={onUnApprove} >ปัดตก</Button>
                    <Button style={{ background: '#597ef7', color: '#ffffff' }} onClick={onApprove} >อนุมัติ</Button>
                  </Space>
                </Col>
              </Card>
            </div>
          </Form>
        </Row>

      </Modal >
      {contextHolder}
      {
        modalnote ?
          <NotePN
            open={modalnote}
            close={handleCloseAllModal}
            close2={handleCancel2}
            shootdata={dataLand}
            appStatus={appStatus}
            newDataPn={setDataLand}
            result={result}
            newdataLand={newdataLand}
            dataFromTable={dataFromTable}
          />
          : null
      }
      {
        isModalShowNote ?
          <ModalMoreNote
            open={isModalShowNote}
            close={setisModalShowNote}
            datanote={test}
          />
          : null
      }
      {
        isModalAddLand ?
          <ModalAddLand
            open={isModalAddLand}
            close={setIsModalAddLand}
            dataFromTable={dataFromTable}
            funcAdd={funcAdd}
            dataEdit={dataEdit}
            dataEditIndex={dataEditIndex}
            checkEdit={checkEdit}
            setCheckEdit={setCheckEdit}
            funcEdit={funcEdit}
            dataForCheckDup={newdataLand}
          />
          : null
      }
      {
        openShowImage ?
          <ModalShowImages
            open={openShowImage}
            close={setOpenShowImage}
            carimage1={carshowMadol}
            nuarantorNew={nuarantorNew}
            nuarantorNum={nuarantorNum}
            setGuarantorNum={setGuarantorNum}
          />

          : null
      }



    </>
  )
}
