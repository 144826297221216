import React, { useState, useEffect } from "react";
import { Button, Col, Input, Row, Table, Spin, Space, Card, Divider, Select, Tag, message, } from "antd";
import { FileSearchOutlined, ProfileOutlined, CheckOutlined, SearchOutlined, } from "@ant-design/icons";
import axios from "axios";
import dayjs from "dayjs";
import ModalInstallment from "../../check_info_PG_RE/modals/ModalInstallment";
import BillExpedite from "./modals/BillExpedite";
import "../../css/MobileActive.css";
import WaitApproval from "./modals/WaitApproval";
import "../../css/buttonNickie.css";
import "../../css/Media.css";
import { loadMainBoss, mainexpeditethedebt, searchmainexpeditethedebt, } from "../../file_mid/all_api";

const Cars = () => {
  // const user = localStorage.getItem("username");
  const idPosition = localStorage.getItem("idSep");
  const [arrayTable, setArrayTable] = useState(); // ข้อมูลใน Table
  const [totalPage, setTotalPage] = useState();
  const [totalDataAll, setTotalDataAll] = useState();
  const [query, setQuery] = useState("");
  const [isModalInstallment, setIsModalInstallment] = useState(false);
  const [isModalInfoCar, setIsModalInfoCar] = useState(false);
  const [isModalRefinance, setIsModalRefinance] = useState(false);
  const [dataInstallment, setDataInstallment] = useState(null);
  const [dataInfoCar, setDataInfoCar] = useState(null);
  const [dataRefinance, setInfoRefinance] = useState(null);
  const [dataArr, setDataArr] = useState([]);
  const [dataSend, setDataSend] = useState({});
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");
  const [dataApproval, setDataApproval] = useState([]);
  const [selectedValue, setSelectedValue] = useState("reset");
  const [dataWait, setDataWait] = useState();
  const [dataSearch, setDataSearch] = useState();
  const [dataValue, setDataValue] = useState();
  const [queryContno, setQueryContno] = useState('');
  const [bunsheClose, setBunsheClose] = useState([]);
  const [data, setData] = useState([]);

  // console.log("queryContno", queryContno)

  useEffect(() => {
    loadData()
    if (queryContno === '') {
      setArrayTable([]);
    }
  }, [dataValue]);

  useEffect(() => {
    if (queryContno === '') {
      setArrayTable([]);
    }
  }, [queryContno]);


  const loadData = async () => {
    setLoading(true);
    const tk = JSON.parse(token);
    const url = mainexpeditethedebt;
    const headers = {
      Authorization: `Bearer ${tk}`,
      Menu: JSON.stringify("25"),
    };
    await axios.get(url, { headers: headers })
      .then(async (res) => {
        let i = 1;
        if (res.data) {
          console.log("res.dataaaaaaaaaaaaaa", res.data)
          const newData = res.data.map((item) => ({
            ...item,
            key: i++,
          }));
          setDataArr(newData);
          setTotalPage(res.data.length);
          setTotalDataAll(res.data.length);
          // loadCheckClose(newData)
          setData(newData)
        } else {
          setArrayTable([]);
        }
      })
      .catch((err) => console.log(err));
    setLoading(false);
  };


  const onQuery = (e) => {
    // console.log("e",e)
    if (queryContno === '') {
      setArrayTable([]);
    } else {
      queryData();
    }
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setQueryContno(inputValue);
    if (queryContno === '') {
      setArrayTable([]);
    }
  };



  const queryData = async () => {
    setLoading(true);
    let result = dataArr.filter(
      (item) =>
        item.CONTNO.includes(queryContno)
      // item.CNAME.includes(queryContno) ||
      // item.CUSCOD.includes(queryContno) ||
      // item.REGNO.includes(queryContno) ||
      // item.TELP.includes(queryContno)
    );
    setArrayTable(result);
    setLoading(false);
  };


  const handleChangeStatus = (data, status) => {
    setDataValue(status)
    console.log("TEST", data, status);
    const result = dataArr.map((item) => {
      if (item.CONTNO === data.CONTNO) {
        return { ...data };
      } else {
        return { ...item };
      }
    });
    setDataArr(result);
    // handleStatusApproval(result);
    if (status === "reset") {
      setArrayTable(result);
    } else {
      const arr = result.filter((item) =>
        item.ApprovalStatus === status
      );
      setArrayTable(arr);

    }
  };

  const columns = [
    {
      title: "ลำดับ",
      dataIndex: "key",
      key: "key",
      align: "center",
      width: "10%",
      render: (text, object, key) => key + 1,
      sorter: {
        compare: (a, b) => a.key - b.key,
        multiple: 5,
      },
    },
    {
      title: "รายละเอียด",
      dataIndex: "CONTNO",
      key: "CONTNO",
      align: "center",
      width: "40%",
      render: (text, record1) => {
        console.log("record", record1);
        const dataSource = [record1]
        const filteredData = dataSource.filter(record => record.products !== 'land');
        console.log("filteredData", filteredData)
        // หาก products === 'land' ให้ return null เพื่อไม่แสดงในตาราง
        if (record1?.products === 'land') {
          return null;
        }

        const dataNew = () => {
          return (
            <>
              <div> เลขสัญญา <b>{record1?.CONTNO ? record1?.CONTNO : null}</b> </div>
              <div>{record1.CNAME ? <>{record1.CNAME ? record1.CNAME : null}</> : <>{record1.snam && record1?.firstname && record1?.lastname ? record1.snam + ' ' + record1?.firstname + ' ' + record1?.lastname : null}</>}</div>
              {
                record1.BAAB || record1.TYPE || record1.MODEL || record1.MANUYR || record1.REGNO ?
                  <div>
                    {record1.BAAB ? record1.BAAB : null}
                    {record1.TYPE ? record1.TYPE : null}
                    {record1.MODEL ? record1.MODEL + ' ปีรถ' : null} <br />
                    {record1.MANUYR ? parseInt(record1.MANUYR) + ' เลขทะเบียน' : null}
                    {record1.REGNO ? record1.REGNO : null}
                  </div>
                  : null
              }
              <div>เสนอโดย <Space style={{ color: "red" }}><b>{record1.SALCOD ? record1.SALCOD : record1?.proposalBy}</b></Space></div>
            </>
          )
        }

        return (
          <div>
            {record1?.approvalStatus === 3 ? (
              <div>
                {dataNew()}
                วันที่เฮียอนุมัติ <Space style={{ color: "green" }}>
                  <Space style={{ color: "red" }}>
                    {dayjs(record1.approvalDate).format("DD-MM-YYYY")}
                  </Space>
                </Space>
              </div>
            ) : record1?.approvalStatus === 4 ? (
              <div>
                {dataNew()}
                วันที่เฮียอนุมัติ <Space style={{ color: "red" }}>
                  <Space style={{ color: "red" }}>
                    {dayjs(record1.approvalDate).format("DD-MM-YYYY")}
                  </Space>
                </Space>
              </div>
            ) : selectedValue === 'close' ? (
              <div>
                {dataNew()}
                วันที่ส่งหา PG <Space style={{ color: "red" }}>
                  <Space style={{ color: "red" }}>
                    {record1?.sendPgDate ? dayjs(record1?.sendPgDate).format("DD-MM-YYYY HH:mm:ss") : null}
                  </Space>
                </Space>
              </div>
            ) : (
              <div>
                {dataNew()}
              </div>
            )}
          </div>
        );
      }
    },
    {
      title: "สถานะสัญญา",
      dataIndex: "ApprovalStatus",
      key: "ApprovalStatus",
      align: "center",
      width: "30%",
      // sorter: {
      //   compare: (a, b) => a.approvalStatus.length - b.approvalStatus.length,
      //   multiple: 5,
      // },
      render: (text, record) => (
        <>
          {
            selectedValue !== "close" ?
              <>
                {idPosition === "1" || idPosition === "5" ? (
                  <>
                    {
                      record.approvalStatus === 0 ? (
                        <Tag >
                          <b>ยังไม่ได้ส่งขอ</b>
                        </Tag>
                      ) : record.approvalStatus === 4 ? (
                        <Tag style={{ backgroundColor: "#72f70d" }}>
                          <b>สำเร็จ</b>
                        </Tag>
                      ) : record.approvalStatus === 2 ? (
                        <Tag style={{ backgroundColor: "yellow" }}>
                          <b>รอCEOอนุมัติ</b>
                        </Tag>
                      ) : record.approvalStatus === 28 ? (
                        <Tag style={{ backgroundColor: "orange" }}>
                          <b>รอนำเสนอCEO</b>
                        </Tag>
                      ) : record.approvalStatus === 3 ? (
                        <Tag style={{ backgroundColor: "#dcedc8" }}>
                          <b>รอส่งเคสอนุมัติ</b>
                        </Tag>
                      ) : null}
                  </>
                ) : (
                  "-"
                )}
              </>
              :
              <>
                <Tag style={{ backgroundColor: "pink" }}>
                  <b>สัญญาที่ปิดแล้ว</b>
                </Tag>
              </>
          }

        </>
      ),
    },
  ];

  return (
    <>
      <Card>
        <Row gutter={32} style={{ textAlign: "center" }}>
          <Col>
            <Spin spinning={loading} size="large" tip=" Loading... ">
              <Col>
                <div className="text-center">
                  <h2>ตรวจสอบข้อมูลตามงวด</h2>
                </div>
              </Col>
              <Row justify={'center'}>
                <Col span={24}>
                  <div>
                    <b>
                      จำนวนทั้งหมด {totalDataAll} {" เคส"}
                    </b>
                  </div>
                </Col>
              </Row>
              <Row style={{ marginLeft: "5px" }} justify={'center'}>
                <Col
                  span={24}
                >

                  <Space>
                    <Input
                      style={{ width: "300px" }}
                      placeholder="ค้นหาเลขที่สัญญา"
                      onChange={handleInputChange}
                      // value={searchValue}
                      // onChange={handleInputChange}
                      // onKeyPress={handleKeyPress}
                      name="search"
                      id="search"
                    />
                    <Button
                      style={{ marginRight: "5px" }}
                      // onClick={onQuery}
                      onClick={(e) => onQuery(e)}
                    >
                      <SearchOutlined />
                    </Button>
                  </Space>
                </Col>
              </Row>
              <Row gutter={32}>
                <Col span={24}>
                  {
                    selectedValue !== "close" ?
                      <>
                        <Table
                          style={{ marginTop: "5px" }}
                          scroll={{
                            y: 400,
                          }}
                          expandable={{
                            expandedRowRender: (record) => {
                              if (record.CONTNO)
                                return (
                                  <div>
                                    <Space size="small">
                                      <Button
                                        title="ติดตามค่างวด"
                                        onClick={() => {
                                          setDataInfoCar(record.CONTNO);
                                          // console.log("dataInfoCar", dataInfoCar);
                                          // console.log("record.CONTNO", record.CONTNO);
                                          setIsModalInfoCar(true);
                                          setDataSend(record);
                                        }}
                                      >
                                        <FileSearchOutlined />
                                      </Button>
                                      <Button
                                        title="ตารางการผ่อน"
                                        onClick={() => {
                                          setDataInstallment(record.CONTNO);
                                          setIsModalInstallment(true);
                                          // console.log("ตารางการผ่อน", record.CONTNO);
                                        }}
                                      >
                                        <ProfileOutlined />
                                      </Button>
                                      {record.approvalStatus !== 0 &&
                                        (idPosition === "1" || idPosition === "5") ? (
                                        <Button
                                          title="ส่งอนุมัติรีไฟแนนซ์"
                                          onClick={() => {
                                            setInfoRefinance(record.CONTNO);
                                            setDataWait(record.idCarsApproveCeo);
                                            setIsModalRefinance(true);
                                            setDataApproval(record.MEMO1);
                                            setDataSend(record);
                                            // console.log("ส่งอนุมัติรีไฟแนนซ์");
                                          }}
                                        >
                                          <CheckOutlined />
                                        </Button>
                                      ) : null}
                                    </Space>
                                  </div>
                                );
                            },
                          }}
                          dataSource={arrayTable}
                          columns={columns}
                        ></Table>
                      </>
                      :
                      <>
                        <Table
                          style={{ marginTop: "5px" }}
                          scroll={{
                            y: 400,
                          }}
                          expandable={{
                            expandedRowRender: (record) => {
                              if (record.CONTNO)
                                return (
                                  <div>
                                    <Space size="small">
                                      {record.approvalStatus !== 0 &&
                                        (idPosition === "1" || idPosition === "5") ? (
                                        <Button
                                          title="ส่งอนุมัติรีไฟแนนซ์"
                                          onClick={() => {
                                            setInfoRefinance(record.CONTNO);
                                            setDataWait(record.id);
                                            setIsModalRefinance(true);
                                            setDataApproval(record.MEMO1);
                                            setDataSend(record);
                                            // console.log("ส่งอนุมัติรีไฟแนนซ์");
                                          }}
                                        >
                                          <CheckOutlined />
                                        </Button>
                                      ) : null}
                                    </Space>
                                  </div>
                                );
                            },
                          }}
                          dataSource={arrayTable}
                          columns={columns}
                        ></Table>
                      </>
                  }
                  <Divider />
                  <Row justify={"center"}></Row>
                  <Divider />
                </Col>
              </Row>
            </Spin>
          </Col>
        </Row>
      </Card>
      {isModalInfoCar ?
        <BillExpedite
          open={isModalInfoCar}
          close={setIsModalInfoCar}
          continoBill={dataInfoCar}
          data={dataSend}
          onChangeStatus={handleChangeStatus}
          status={selectedValue}
        />
        : null}
      {isModalInstallment ?
        <ModalInstallment
          open={isModalInstallment}
          close={setIsModalInstallment}
          contno={dataInstallment}
        />
        : null}
      {isModalRefinance ?
        <WaitApproval
          open={isModalRefinance}
          close={setIsModalRefinance}
          continoRe={dataRefinance}
          id={dataWait}
          memo={dataApproval}
          data={dataSend}
          onChangeStatus={handleChangeStatus}
          status={selectedValue}
        />
        : null}
    </>
  );
};

export default Cars;
