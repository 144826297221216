import React, { useState, useEffect } from "react";
import { PlusCircleFilled, HomeFilled, EditOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Table, Spin, Tag, Space, Card, Radio, Statistic, notification, Typography, Pagination, Divider } from 'antd';

import axios from "axios";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import ModalEditer from "./modals/ModalEditer";
import MainReEditLand from "./modals/MainReEditLand";

import { useDispatch } from "react-redux";
import '../css/Media.css'
import { addCustomer } from '../../redux/Customer';
import { addCar } from '../../redux/Car';
import { addAddress } from '../../redux/Address';
import { addPhone } from '../../redux/Phone';
import { addLoan } from '../../redux/Loan';
import { addOldLoan } from '../../redux/OldLoan';
import { addImage } from '../../redux/Image';
import { addLand } from '../../redux/Land';
import { addLoanLand } from '../../redux/LoanLand';
import { addOldLoanLand } from '../../redux/OldLoanLand';
import { addGuarantor } from '../../redux/Guarantor';
import { addCareerIncome } from '../../redux/CareerIncome';
import { addOccupation } from '../../redux/Occupation';
import { addEmail } from '../../redux/Email';
import { addSocial } from '../../redux/Social';

import { orderLand } from "../file_mid/all_options";
import { colorApprovalStatus, colorProductLoanType, backc, textc } from "../file_mid/status_color";
import ModalAddAaprove from "./modals/ModalAddAaprove";
import { newfindlandinfo } from "../file_mid/all_api";
import ModalInfoMK from "../file_mid/info/ModalInfoMK";
import { useLocation } from 'react-router-dom';

const { Countdown } = Statistic;

function Main() {
  const location = useLocation();
  const { state } = location;
  const [form] = Form.useForm();
  // const menu = localStorage.getItem('menu')
  const user = localStorage.getItem('username');
  const token = localStorage.getItem('token')
  const { Text } = Typography;
  const [api, contextHolder] = notification.useNotification();
  dayjs.extend(utc);
  const dispatch = useDispatch()
  const [editData, setEditData] = useState();
  const [isModalEditer, setIsModalEditer] = useState(false);
  const [axiosData, setAxiosData] = useState([]);
  const [arrayTable, setArrayTable] = useState();
  const [cData, setCData] = useState([]);
  const [keyWord, setKeyWord] = useState("ทั้งหมด");
  const [loading, setLoading] = useState(false)
  const [modalReEdit, setModalReEdit] = useState(false);
  const [isModalAddAaprove, setIsModalAddAaprove] = useState(false);
  const [testPage, setTestPage] = useState();
  const [ssPage, setSsPage] = useState({ numberPage: 1, pageSize: 10 });
  const [currentPage, setCurrentPage] = useState(1)
  const [query, setQuery] = useState("");
  const [isModalInfoMK, setIsModalInfoMK] = useState(false);


  const [navigateData, setNavigateData] = useState([]);
  const [datafrommap, setDataFrommap] = useState({});


  useEffect(() => {
    localStorage.removeItem('addCustomer');
    localStorage.removeItem('addAddress');
    localStorage.removeItem('addPhone');
    localStorage.removeItem('addCar');
    localStorage.removeItem('addGuarantor');
    localStorage.removeItem('addCareerIncome');
    localStorage.removeItem('addLoan');
    localStorage.removeItem('addOldLoan');
    localStorage.removeItem('addLand');
    localStorage.removeItem('addLoanLand');
    localStorage.removeItem('addOldLoanLand');
    localStorage.removeItem('addOccupation');
    localStorage.removeItem('addEmail');
    localStorage.removeItem('addSocial');
    localStorage.removeItem('addImage');
    localStorage.removeItem('addBroker');
    dispatch(addCustomer())
    dispatch(addAddress())
    dispatch(addGuarantor())
    dispatch(addCareerIncome())
    dispatch(addPhone())
    dispatch(addCar())
    dispatch(addLoan())
    dispatch(addOldLoan())
    dispatch(addImage())
    dispatch(addLand())
    dispatch(addLoanLand())
    dispatch(addOldLoanLand())
    dispatch(addOccupation())
    dispatch(addEmail())
    dispatch(addSocial())
  }, [])


  useEffect(() => {
    if (isModalEditer === false && modalReEdit === false && isModalAddAaprove === false && isModalInfoMK === false) {
      loadData()
    }
  }, [keyWord, isModalEditer, modalReEdit, isModalAddAaprove, ssPage, isModalInfoMK])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      console.log(query)
      searchLoadData(query)
    }, 1500)
    return () => clearTimeout(delayDebounceFn)
  }, [query])

  useEffect(() => {
    const init = async () => {
      if (state?.firstname) {
        if (datafrommap) {
          countMap();
        }
      }
    };

    init();
  }, [state, navigateData]);

  const countMap = () => {
    setLoading(true)
    setTimeout(() => {
      if (datafrommap?.approvalStatus === 3 &&
        ["Pre-Aaprove-เปลี่ยนสัญญา",
          "Pre-Aaprove-รี+เปลี่ยนสัญญา",
          "Pre-Aaprove-รี-Ploan",
          "Pre-Aaprove-ปรับโครงสร้าง",
          "Pre-Aaprove-รีโอน",
          "Pre-Aaprove-รี"].includes(datafrommap?.productLoanLandType)) {
        if (datafrommap?.customerId === state?.customerId && datafrommap?.landId === state?.landId) {
          console.log("เข้า mapdata");
          setIsModalAddAaprove(true);
          setEditData(datafrommap);
          setQuery(state.firstname);
          form.setFieldsValue({
            search: state.firstname
          });
          return; // หยุด loop
        }
      } else {
        if (state?.firstname !== '') {
          setQuery(state.firstname);
        }
        form.setFieldsValue({
          search: state.firstname
        });
      }

      setLoading(false);
    }, 2000); // ทำให้ setLoading(false) หลังจากการประมวลผลข้อมูลเสร็จสิ้น
  };


  const searchLoadData = async (data) => {
    console.log("searchLoadData data", data)
    if (data !== "") {
      console.log("OK searchLoadData data")
      await loadData(data)
    }
  }

  const loadCountData = async (data) => {
    // const initialValue = 0;
    // const sumWithInitial = data.reduce(
    //   (accumulator, currentValue) => accumulator + currentValue.A,
    //   initialValue
    // );
    // data.push({ approvalStatus: 'ทั้งหมด', A: sumWithInitial })
    // setCData(data)
    // const ez = data.filter(
    //   (item) =>
    //     item.approvalStatus === keyWord
    // );
    // console.log("ez", ez)
    // setTestPage(...ez)
    if (!data) {
      setCData([])
    } else {
      const ez = data.filter(
        (item) =>
          item.approvalStatusId === keyWord
      );
      setTestPage(...ez)
      setCData(data)
    }

  }
  const loadData = async (data) => {
    const tk = JSON.parse(token)
    const headers = {
      "Authorization": `Bearer ${tk}`,
      "Menu": JSON.stringify("3")
    }
    if (query !== "" && data !== "stop") {
      var mainData = { proposalBy: user, approvalStatus: keyWord, numberPage: ssPage?.numberPage, pageSize: ssPage?.pageSize, keyword: query }
    } else {
      if (data === "stop") {
        var mainData = { proposalBy: user, approvalStatus: "ทั้งหมด", numberPage: ssPage?.numberPage, pageSize: ssPage?.pageSize }
      } else {
        var mainData = { proposalBy: user, approvalStatus: keyWord, numberPage: ssPage?.numberPage, pageSize: ssPage?.pageSize }
      }
    }
    setLoading(true)
    await axios.post(newfindlandinfo, mainData, { headers: headers })
      .then(async (res) => {
        console.log("b", res.data)
        setNavigateData(res?.data?.GetInfoLand)
        let datamaps = []
        res?.data?.GetInfoLand?.map((item) => {
          if (item?.firstname === state?.firstname && item?.approvalStatus === 3) {
            datamaps.push(item)
          }
        })
        setDataFrommap(...datamaps)

        if (res.data.GetInfoLand) {
          var newGetInfoLand = res.data.GetInfoLand.filter((data) => {
            return ![14, 12, 11, 15].includes(data.approvalStatus);
          });
          // res.data.GetInfoLand.map((data, index) => {
          //   if (data.approvalStatus === 14 ||
          //     data.approvalStatus === 12 ||
          //     data.approvalStatus === 11 ||
          //     data.approvalStatus === 15
          //   ) {
          //     var num = index
          //   }
          //   delete res.data.GetInfoLand[num]
          // })
          setArrayTable(newGetInfoLand)
          setAxiosData(newGetInfoLand)
          // setArrayTable(res.data.GetInfoLand)
          // setAxiosData(res.data.GetInfoLand)
        } else {
          setArrayTable([])
          setAxiosData([])
        }
        if (res.data.CountStatus) {
          var newCountStatus = res.data.CountStatus.filter((data) => {
            return !["14", "12", "11", "15"].includes(data.approvalStatusId);
          });
          await loadCountData(newCountStatus)
          // await loadCountData(res.data.CountStatus)
        }
        setLoading(false)
      }).catch((err) => console.log(err))
    setLoading(false)
  }

  const search = async (data) => {
    if (data.target.value !== "") {
      setQuery(data.target.value)
    } else {
      setSsPage({ numberPage: 1, pageSize: 10 })
      setCurrentPage(1)
      setKeyWord("ทั้งหมด")
      setQuery("")
    }
  };

  const onChangeKeyWord = (value) => {
    setKeyWord(value)
    setCurrentPage(1)
    setSsPage({ numberPage: 1, pageSize: 10 })
  }
  const onChangePagination = (e, pageSize) => {
    // console.log(e, pageSize)
    setCurrentPage(e)
    setSsPage({ numberPage: e, pageSize: pageSize })
  }

  const SuccSend = (placement) => {
    api.success({
      message: <b>ส่งเคสใหม่สำเร็จ</b>,
      placement,
    });
  }

  const columns = [
    {
      title: "ลำดับ",
      dataIndex: "index",
      key: 'index',
      align: 'center',
      fixed: true,
      width: "5%",
      render: (text, object, index) => index + 1
    },
    {
      title: "ชื่อ-สกุล",
      dataIndex: "firstName",
      key: 'firstName',
      align: 'left',
      width: "13%",
      // fixed: true,
      sorter: {
        compare: (a, b) => a.firstName.length - b.firstName.length,
        multiple: 1,
      },
      render: (text, record) => (
        <>{record.snam}{record.firstname} {record.lastname}</>
      ),
    },
    {
      title: "เลขที่ดิน",
      dataIndex: "numberLand",
      key: 'numberLand',
      align: 'center',
      width: "6%",
      // sorter: {
      //   compare: (a, b) => a.numberLand.length - b.numberLand.length,
      //   multiple: 5,
      // },
      render: (text, record) => (
        <Space size="middle">
          {
            <Space>
              <>{record.numberLand}</>
            </Space>
          }
        </Space>
      ),
    },
    {
      title: "ระวาง",
      dataIndex: "numberLandLawang",
      key: 'numberLandLawang',
      align: 'center',
      width: "10%",
      // sorter: {
      //   compare: (a, b) => a.numberLandLawang.length - b.numberLandLawang.length,
      //   multiple: 5,
      // },
      render: (text, record) => (
        <Space size="middle">
          {
            <Space>
              <>{record.numberLandLawang}</>
            </Space>
          }
        </Space>
      ),
    },
    {
      title: "จังหวัด",
      dataIndex: "province",
      align: 'center',
      width: "7%",
      sorter: {
        compare: (a, b) => a.province - b.province,
        multiple: 4,
      },
      render: (text, record) => (
        <>{record.province}</>
      ),
    },
    {
      title: "อำเภอ",
      dataIndex: "district",
      align: 'center',
      width: "7%",
      sorter: {
        compare: (a, b) => a.district - b.district,
        multiple: 4,
      },
      render: (text, record) => (
        <>{record.district}</>
      ),
    },
    {
      title: "พื้นที่ตารางวา",
      dataIndex: "squareWaArea",
      align: 'center',
      width: "7%",
      sorter: {
        compare: (a, b) => a.squareWaArea - b.squareWaArea,
        multiple: 4,
      },
      render: (text, record) => {
        const totalWa = (
          ((parseInt(record.rai) * 400)) +
          ((parseInt(record.workArea) * 100)) +
          parseFloat(record.squareWaArea)
        );

        return (
          <Space size="middle">
            <Space>
              <>{totalWa}</>
            </Space>
          </Space>
        );
      },
    },
    // {
    //   title: "ราคา/ตารางวา",
    //   dataIndex: "landPrice",
    //   align: 'center',
    //   width: 120,
    //   sorter: {
    //     compare: (a, b) => a.landPrice - b.landPrice,
    //     multiple: 3,
    //   },
    //   render: (text, record) => (
    //     <Space size="middle">
    //       {
    //         <Space>
    //           <>{currencyFormatOne(record.landPrice)}</>
    //         </Space>
    //       }
    //     </Space>
    //   ),
    // },
    {
      title: "วันที่เสนอ",
      dataIndex: "landInput",
      width: "7%",
      align: 'center',
      sorter: {
        compare: (a, b) => dayjs(a.landInput) - dayjs(b.landInput),
        multiple: 1,
      },
      render: (text, record) => (
        <Space size="middle">
          {
            record.landInput !== "0001-01-01T00:00:00Z" ?
              <Space>
                <>{dayjs(record.landInput).format("DD-MM-YYYY HH:mm")}</>
              </Space>
              : null
          }
        </Space>
      ),
      defaultSortOrder: 'descend',
    },
    {
      title: "วันที่รับ",
      dataIndex: "acceptDate",
      width: "7%",
      align: 'center',
      sorter: {
        compare: (a, b) => dayjs(a.acceptDate) - dayjs(b.acceptDate),
        multiple: 2,
      },
      render: (text, record) => (
        <Space size="middle">
          {
            record.acceptDate !== "0001-01-01T00:00:00Z" && record.acceptDate !== null ?
              <Space>
                <>{dayjs(record.acceptDate).format("DD-MM-YYYY HH:mm")}</>
              </Space>
              : <Text type="secondary">ธุรการยังไม่รับงาน</Text>
          }
        </Space>
      ),
    },
    {
      title: "ระยะเวลาที่คาดว่าจะเสร็จ",
      dataIndex: "",
      width: "9%",
      align: 'center',
      render: (text, record) => (
        <Space size="middle">
          {
            record.acceptDate !== "0001-01-01T00:00:00Z" && record.acceptDate !== null ?
              <Space>
                <Countdown value={dayjs(record.acceptDate).add(3, 'hour')} />
              </Space>
              : <Text type="secondary">ธุรการยังไม่รับงาน</Text>
          }
        </Space>
      ),
    },
    {
      title: "สถานะ",
      dataIndex: "approvalStatus",
      align: 'center',
      width: "12%",
      render: (text, record) => {
        let color
        let color2
        color = colorApprovalStatus(record?.productLoanLandType, record?.approvalStatus)
        color2 = colorProductLoanType(record?.productLoanLandType)
        return (
          <>
            <Tag color={color}>
              {record.approvalStatusName}
            </Tag>
            <Space>
              <Tag color={color2}>
                {record.productLoanLandType}
              </Tag>
            </Space>
          </>
        );
      },
    },
    {
      title: "Action",
      key: 'Action',
      align: 'center',
      width: "10%",
      render: (_, record) => {
        var PreAaproveSplit = []
        PreAaproveSplit = record.productLoanLandType.split("-")
        return (< Space size="middle" >
          {
            record.approvalStatus === 9 ?
              <Tag color="geekblue">กรุณาติดต่อเร่งรัดภาคสนาม</Tag>
              : record.approvalStatus === 11 && !record.priceStatus ?
                <Space>
                  <Button onClick={() => {
                    setIsModalEditer(true)
                    dispatch(addCustomer({
                      CreatedAt: record.CreatedAt,
                      IsAccepted: record.IsAccepted,
                      LandDateStatus: record.LandDateStatus,
                      UpdatedAt: record.LandDateStatus,
                      approvalDate: record.approvalDate,
                      approvalStatus: record.approvalStatus,
                      approvedBy: record.approvedBy,
                      approvedLoanAmount: record.approvedLoanAmount,
                      birthdate: record.birthdate,
                      customerApprovedLoan: record.customerApprovedLoan,
                      customerId: record.customerId,
                      district: record.district,
                      expdt: record.expdt,
                      firstname: record.firstname,
                      gcode: record.gcode,
                      gender: record.gender,
                      identificationId: record.identificationId,
                      issuby: record.issuby,
                      issudt: record.issudt,
                      landContno: record.landContno,
                      landId: record.landId,
                      landInput: record.landInput,
                      landLoanId: record.landLoanId,
                      landStatus: record.landStatus,
                      landTypeId: record.landTypeId,
                      lastname: record.lastname,
                      nationalId: record.nationalId,
                      nickname: record.nickname,
                      productLoanLandType: record.productLoanLandType,
                      productType: record.productType,
                      productTypeLand: record.productTypeLand,
                      proposalBy: record.proposalBy,
                      province: record.province,
                      reviewedBy: record.reviewedBy,
                      snam: record.snam,
                      subdistrict: record.subdistrict,
                      numberLand: record.numberLand,
                      numberLandLawang: record.numberLandLawang,
                      landMemo: record.landMemo,
                      storyLand: record.storyLand,
                      parcellat: record.parcellat,
                      parcellon: record.parcellon,
                      rai: parseInt(record?.rai),
                      workArea: parseInt(record?.workArea),
                      squareWaArea: parseFloat(record?.squareWaArea),
                      landPrice: parseInt(record?.landPrice),
                      resultLandPrice: parseInt(record?.resultLandPrice),
                      loanAmounttLand: parseFloat(record?.loanAmounttLand),
                      loanLandTerm: parseFloat(record?.loanLandTerm),
                      interestRateLand: parseFloat(record?.interestRateLand),
                      interesttLand: parseFloat(record?.interesttLand),
                      monthlyPaymentLand: parseInt(record?.monthlyPaymentLand),
                      activeStatus: record?.activeStatus,
                      oldLandLoanId: record.oldLandLoanId,
                      oldLandDownPayment: record.oldLandDownPayment,
                      oldLandInterestRate: record.oldLandInterestRate,
                      oldLandLoanAmount: parseInt(record?.oldLandLoanAmount),
                      oldLandMonthlyPayment: parseInt(record?.oldLandMonthlyPayment),
                      oldLandLoanTerm: parseInt(record?.oldLandLoanTerm),
                      oldLandKangPayment: parseInt(record?.oldLandKangPayment),
                      oldLandKangPaymentTerm: parseInt(record?.oldLandKangPaymentTerm),
                      issuno: record?.issuno,
                    }))
                  }}><EditOutlined /></Button>
                  <Button onClick={() => {
                    setIsModalInfoMK(true)
                    setEditData(record)
                  }}><SearchOutlined> </SearchOutlined></Button>
                </Space>
                : record.approvalStatus === 23 ?
                  <Space>
                    <Button onClick={() => {
                      setIsModalInfoMK(true)
                      setEditData(record)
                    }}><SearchOutlined ></SearchOutlined></Button>
                  </Space>
                  : record.approvalStatus === 20 ?
                    <>
                      <Button onClick={() => {
                        setModalReEdit(true)
                        setEditData(record)
                      }}>แก้ไข</Button>
                      <Button onClick={() => {
                        setIsModalInfoMK(true)
                        setEditData(record)
                      }}><SearchOutlined ></SearchOutlined></Button>
                    </>
                    : record.approvalStatus === 21 && PreAaproveSplit[0] !== "Pre" ?
                      <>
                        <Button onClick={() => {
                          setModalReEdit(true)
                          setEditData(record)
                        }}>แก้ไข</Button>
                      </>
                      : (record.approvalStatus === 3 && PreAaproveSplit[0] === "Pre") || (record.approvalStatus === 21 && PreAaproveSplit[0] === "Pre") ?
                        <>
                          <Button onClick={() => {
                            setIsModalAddAaprove(true)
                            setEditData(record)
                          }}>เพิ่มข้อมูล</Button>
                        </>
                        :
                        <>
                          <Button onClick={() => {
                            setIsModalInfoMK(true)
                            setEditData(record)
                          }}><SearchOutlined /></Button>
                        </>
          }
        </Space >)

      },
    },
  ];

  const currencyFormatOne = (amount) => {
    return Number(amount)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, '$&,')
  }

  return (
    <>
      <Card>
        <Form
          form={form}
        >
          <Row gutter={32} style={{ textAlign: 'center' }}>
            <Col>
              <Spin spinning={loading} size='large' tip=" Loading... ">
                <div className='text-center'>
                  <h2>ตรวจสอบข้อมูลพรี-ที่ดิน(การตลาด)</h2>
                </div>
                <Row gutter={32} justify={'center'}>
                  {/* <Radio.Group value={keyWord} onChange={(e) => setKeyWord(e.target.value)} > */}
                  <Radio.Group value={keyWord} onChange={(e) => onChangeKeyWord(e.target.value)} >
                    {
                      cData.sort((a, b) => {
                        const order = orderLand
                        return order.indexOf(a.approvalStatus) - order.indexOf(b.approvalStatus);
                      })
                        .map((item) => (
                          <Radio.Button style={{
                            width: '160px',
                            border: '2px solid',
                            backgroundColor: backc[item.approvalStatus],
                            color: textc[item.approvalStatus]
                          }}
                            value={item.approvalStatusId}>{item.approvalStatus} <b>{item.totalCount}</b></Radio.Button>
                        ))}
                  </Radio.Group>
                </Row>
                <Row gutter={32} style={{ margin: 10 }}>
                  <Col className='gutter-row' span={24} style={{ textAlign: 'right' }}>
                    <Form.Item name="search">
                      <Input.Search
                        style={{ width: '250px' }}
                        placeholder="ค้นหา...."
                        onChange={search}
                        name="search"
                        id="search"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={32}>
                  <Col span={24}>
                    <Table
                      pagination={false}
                      rowKey={(record) => record.uid}
                      scroll={{
                        x: 1700,
                        y: 400,
                      }}
                      dataSource={arrayTable}
                      columns={columns}
                    >
                    </Table>
                    <Divider />
                    <Pagination
                      current={currentPage}
                      onChange={onChangePagination}
                      onShowSizeChange={onChangePagination}
                      defaultPageSize={10}
                      defaultCurrent={1}
                      total={testPage?.totalCount}
                    />
                    <Divider />
                  </Col>
                </Row>

                <Col span={24} style={{ textAlign: 'center' }}>
                  <Space>
                    <Button href="/offercase" type="primary" ><PlusCircleFilled />เพิ่ม</Button>
                    <Button href="/" type="primary"><HomeFilled />หน้าหลัก</Button>
                  </Space>
                </Col>
              </Spin>
            </Col>
          </Row>
        </Form>
      </Card>
      {
        isModalEditer ?
          <ModalEditer open={isModalEditer} close={setIsModalEditer} />
          : null
      }
      {
        modalReEdit ?
          <MainReEditLand open={modalReEdit} close={setModalReEdit} dataFromTable={editData} SuccSend={SuccSend} />
          : null
      }
      {
        isModalAddAaprove ?
          <ModalAddAaprove open={isModalAddAaprove} close={setIsModalAddAaprove} dataFromTable={editData} SuccSend={SuccSend} />
          : null
      }
      {
        isModalInfoMK ?
          <ModalInfoMK open={isModalInfoMK} close={setIsModalInfoMK} dataFromTable={editData} typeLoan={"land"} SuccSend={SuccSend} />
          : null
      }
      {contextHolder}
    </>
  )
};
export default Main