import React, { useState, useEffect } from 'react'
import { Modal, Form, Button, Card, Divider, Tag, Space, Row, Col, notification } from 'antd'
import axios from 'axios';
import dayjs from 'dayjs';
import { acceptpg2 } from '../../file_mid/all_api';

function ModalAccept({ open, dataFromTable, close }) {

    const [api, contextHolder] = notification.useNotification();
    const [loading, setLoading] = useState(false);
    const user = localStorage.getItem('username');

    const [checkRe] = useState(dataFromTable.car ? dataFromTable.car : dataFromTable.typeLoan);
    const [dataModalEdit, setDataModalEdit] = useState(dataFromTable);
    const [checkResend, setCheckResend] = useState(false);
    const [statusAccept, setstatusAccept] = useState({
        customerId: '', carId: '', loanId: '', approvalStatus: '', phoneId: '',
    });

    useEffect(() => {
        // console.log("PG", dataFromTable)
        setDataModalEdit(dataFromTable)
        pickData();
        if (dataModalEdit.acceptByPg !== "" && dataModalEdit.acceptByPg !== null) {
            if (user !== dataModalEdit.acceptByPg) {
                // console.log("1")
                setCheckResend(true)
            }
        }

    }, [dataFromTable])

    const handleCancel = () => {
        close(false);
    };

    const currencyFormatOne = (amount) => {
        return Number(amount)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, '$&,')
    }

    const successAccept = (placement) => {
        api.success({
            message: `รับงานสำเร็จ`,
            description:
                'สถานะเปลี่ยนเป็น รออนุมัติ',
            placement,
        });
    };
    const errorAccept = (placement) => {
        api.error({
            message: `รับงานไม่สำเร็จ`,
            description:
                'เนื่องจากเคสนี้ถูกรับงานไปแล้ว',
            placement,
        });
    };

    const handleOk = async () => {
        console.log("ss", { ...dataFromTable, ...statusAccept })
        setLoading(true)
        await axios.put(acceptpg2, { ...dataFromTable, ...statusAccept })
            .then(res => {
                console.log(res.status)
                if (res.status === 200) {
                    successAccept("top")
                    setTimeout(() => {
                        close(false);
                    }, 2000)
                } else {
                    errorAccept("top")
                    setTimeout(() => {
                        close(false);
                    }, 2000)
                }
            }).catch((err) => console.log(err))
    };

    const pickData = () => {
        if (dataFromTable.car === "car") {
            setstatusAccept({
                customerId: dataModalEdit.customerId,
                carId: dataModalEdit.carId,
                loanId: dataModalEdit.loanId,
                acceptByPg: user,
                // set เวลา
                acceptDatePg: dayjs(),
                approvalStatus: 2
            })
        } else if (dataFromTable.car === "land") {
            setstatusAccept({
                customerId: dataModalEdit.customerId,
                carId: dataModalEdit.carId,
                loanId: dataModalEdit.loanId,
                acceptByPg: user,
                // set เวลา
                acceptDatePg: dayjs(),
                approvalStatus: 2
            })
        } else {
            setstatusAccept({
                car: dataFromTable.typeLoan,
                customerId: dataModalEdit.customerId,
                carId: dataModalEdit.carId,
                loanId: dataModalEdit.loanId,
                acceptByPg: user,
                // set เวลา
                acceptDatePg: dayjs(),
                approvalStatus: 2
            })
        }


    }
    // dataModalEdit
    return (
        <div>
            <Modal title="กรุณายืนยันข้อมูล" open={open} onCancel={handleCancel}
                footer={[null]}>
                <Card>
                    {
                        dataModalEdit.acceptByPg !== "" && dataModalEdit.acceptByPg !== null ?
                            <h1 style={{ color: "red" }}><span>เคยรับงานโดย :</span> {dataModalEdit.acceptByPg}!</h1>
                            : null
                    }

                    {
                        checkRe === "car" ?
                            <Divider orientation="left"><b>รายละเอียดรถ</b></Divider>
                            :
                            <Divider orientation="left"><b>รายละเอียดที่ดิน</b></Divider>
                    }
                    <Form
                        name="basic"
                        labelCol={{
                            span: 8,
                        }}
                        wrapperCol={{
                            span: 16,
                        }}
                        onFinish={handleOk}>
                        <Form.Item label="ชื่อลูกค้า" style={{ margin: 0 }}>
                            {dataModalEdit.snam}{dataModalEdit.firstName} {dataModalEdit.lastName}
                        </Form.Item>
                        {checkRe === "car" ? (
                            <>
                                <Form.Item label="รายละเอียด" style={{ margin: 0 }}>
                                    {dataModalEdit.carBrand} {dataModalEdit.carModel} {dataModalEdit.carYear}
                                </Form.Item>
                                <Form.Item label="เลขทะเบียนรถ" style={{ margin: 0 }}>
                                    {dataModalEdit.carPlateNumber}
                                </Form.Item>
                                <Form.Item label="ยอดขอกู้" style={{ margin: 0 }}>
                                    {currencyFormatOne(dataModalEdit.loanAmount)}
                                </Form.Item>
                                <Form.Item label="เรท" style={{ margin: 0 }}>
                                    {currencyFormatOne(dataModalEdit.carPrice)}
                                </Form.Item>
                            </>
                        ) : (
                            <>
                                <Form.Item label="รายละเอียด" style={{ margin: 0 }}>
                                    {dataModalEdit.carBrand} {dataModalEdit.carModel} เลขโฉนด {dataModalEdit.carPlateNumber}
                                </Form.Item>
                                <Form.Item label="พื้นที่ตารางวา" style={{ margin: 0 }}>
                                    {currencyFormatOne(dataModalEdit.carYear)}
                                </Form.Item>
                                <Form.Item label="ราคาที่ดิน/ตารางวา" style={{ margin: 0 }}>
                                    {currencyFormatOne(dataModalEdit.carPrice)}
                                </Form.Item>
                            </>
                        )}

                        <Form.Item label="สถานะ">
                            <Tag color="gold">{dataModalEdit.approvalStatusName}</Tag>
                            <Tag color="green">{dataModalEdit.productLoanType}</Tag>
                        </Form.Item>

                        <Row>
                            <Divider />
                            <Col span={24} style={{ textAlign: "center" }}>
                                <Space>
                                    {
                                        checkResend ?
                                            null
                                            :
                                            <>
                                                <Button type='primary' htmlType='submit' disabled={loading} style={{ backgroundColor: "Green" }}>ยืนยัน</Button>
                                            </>
                                    }
                                    <Button key="back" type='primary' danger onClick={handleCancel}>ปิด</Button>
                                </Space>
                            </Col>
                        </Row>

                    </Form>
                </Card>
            </Modal>
            {contextHolder}
        </div>
    )
}

export default ModalAccept