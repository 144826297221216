import React, { useState, useEffect } from "react";
import { Button, Card, Col, Divider, Form, Input, Row, Space, Spin, Table, notification, InputNumber, Tag, DatePicker, Checkbox, Select } from 'antd'
import axios from 'axios';
// import dayjs from "dayjs";
// import ExcelJS from 'exceljs';
import { msError, msConnt } from "../file_mid/allFormat";
import { columnsCl } from "../loan_history/table/cm_cancel";
import ModelCancel from "./ModelCancel";
import { loadMainCancelpay } from "../file_mid/all_api";

export default function Main() {
  // const token = localStorage.getItem('token')
  // const currentDateTime = dayjs()
  const [form] = Form.useForm();
  const [api, contextHolder] = notification.useNotification();
  const [dataSend, setDataSend] = useState({ CONTNO: "", });
  const [dataTable, setDataTable] = useState([]);
  const [arrayTable, setArrayTable] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalCancel, setIsModalCancel] = useState(false);
  const [dataDue, setDataDue] = useState();
  // useEffect(() => {
  // }, [])

  const handleSubmit = async () => {
    if (dataSend?.CONTNO !== "") {
      setLoading(true)
      // await axios.post("http://localhost:8070/api-db2/load-main-cancelpay", dataSend)
      await axios.post(loadMainCancelpay, dataSend)
        .then(async (res) => {
          if (res.status === 200) {
            setDataTable(res.data)
            setArrayTable(res.data)
          } else {
            setDataTable([])
            setArrayTable([])
            setDataDue()
            msConnt(api, "top")
          }
          console.log("res.data", res.data)
          setLoading(false)
        })
        .catch((err) => {
          console.log("err", err)
          setDataTable([])
          setArrayTable([])
          setDataDue()
          msError(api, "top")
          setLoading(false)
        })
    } else {
      msConnt(api, "top")
    }
  }

  const search = (data) => {
    const searchValue = data.target.value.toLowerCase();  // ทำให้ค่าใน input เป็นตัวพิมพ์เล็ก
    const greaterThanTen = arrayTable.filter(
      (item) =>
        item.CONTNO.toLowerCase().includes(searchValue) ||
        item.CUSCOD.toLowerCase().includes(searchValue) ||
        item.TMBILL.toLowerCase().includes(searchValue)
    );
    setDataTable(greaterThanTen)
  };

  const handleSetData = (record) => {
    if (record) {
      console.log('Record :', record);
      setDataDue(record)
      setIsModalCancel(true)
    }
  };

  const handleCancel = () => {
    console.log('handleCancel');
    setIsModalCancel(false)
  };
  const handleCancelLoad = async () => {
    console.log('handleCancelLoad');
    setIsModalCancel(false)
    await handleSubmit()
  };

  return (
    <Card style={{ height: '100%' }}>
      <Form
        form={form}
      >
        <Row style={{ textAlign: 'center' }}>
          <Col span={24}>
            <Spin spinning={loading} size='large' tip="Loading...">
              <Col span={24}>
                <div className='text-center'>
                  <h2>รับเงิน (ยกเลิก)</h2>
                </div>
              </Col>
              <Divider />
              <Row gutter={16}>
                <Col span={16}>
                  <Card title="รายละเอียดสัญญา" bordered={false}>
                    <Space>
                      <Form.Item label="เลขสัญญา" style={{ height: '30px', width: '100%' }}>
                        <Input onChange={(e) => setDataSend({ ...dataSend, CONTNO: e.target.value })}></Input>
                      </Form.Item>
                      <Form.Item label="" style={{ height: '30px', width: '100%' }}>
                        <Button type='primary' onClick={handleSubmit} >ตกลง</Button>
                      </Form.Item>
                    </Space>
                    <Divider />
                    <Col span={16}>
                    </Col>
                  </Card>
                </Col>
              </Row>
              <Divider />
              <Row gutter={32} style={{ margin: 10 }}>
                <Col className='gutter-row' span={24} style={{ textAlign: 'right' }}>
                  <Input.Search
                    style={{ width: '250px' }}
                    placeholder="ค้นหาในตาราง...."
                    onChange={search}
                    name="search"
                    id="search"
                    size="large"
                  />
                </Col>
              </Row>
              <Table
                rowKey={(record) => record.uid}
                dataSource={dataTable}
                columns={columnsCl(handleSetData)}
                scroll={{
                  x: 1500,
                  y: 400,
                }}
                style={{ padding: 20 }}
              >
              </Table>
            </Spin>
          </Col>
        </Row >
      </Form >
      {contextHolder}
      {
        isModalCancel ?
          <ModelCancel
            open={isModalCancel}
            close={handleCancel}
            dataFromTable={dataDue}
            closeload={handleCancelLoad}
          />
          : null
      }
    </Card >
  )
}
