import axios from 'axios';
import { printReceipt, getPaytype } from "../../file_mid/all_api"

export const loadPrintDataPayment = async (data) => {
    console.log("loadPrintDataPayment", data)
    try {
        const response = await axios.post(printReceipt, data);
        if (response.status === 200) {
            console.log("response.data",response.data)
            return response.data;
        } else {
            return null;
        }
    } catch (error) {
        console.log("err", error);
        return null;
    }
};


export const loadDataPayment = async () => {
    console.log("loadDataPayment")
    try {
        const response = await axios.get(getPaytype);
        if (response.status === 200) {
            console.log("response.data",response.data)
            return response.data;
        } else {
            return null;
        }
    } catch (error) {
        console.log("err", error);
        return null;
    }
};