import React, { useState, useEffect } from 'react'
import { Modal, Form, Button, Card, Divider, Tag, Row, Col, Collapse } from 'antd'
import axios from 'axios';
import dayjs from 'dayjs';
import { accept, checkcarad, checklandad } from '../../file_mid/all_api';
import { ApprovalStatusColor } from '../../file_mid/status_color';


function ModalAccept({ open, dataFromTable, close, Success, RateCar, RateLand }) {
    const [loading, setLoading] = useState(false);
    const [dataModalEdit, setDataModalEdit] = useState(dataFromTable);
    const [statusAccept, setstatusAccept] = useState({
        customerId: '', carId: '', loanId: '', approvalStatus: '',
    });
    const [checkOld, setCheckOld] = useState(false);
    const [oldData, setOldData] = useState();
    const [getSize, setGetSize] = useState(500);
    const { Panel } = Collapse;

    useEffect(() => {
        setDataModalEdit(dataFromTable)
        if (dataFromTable.car === "car") {
            loadDupDataCar()
        } else {
            loadDupDataLand()
        }
        pickData();
    }, [dataFromTable])

    const handleCancel = () => {
        close(false);
    };

    const currencyFormatOne = (amount) => {
        return Number(amount)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, '$&,')
    }

    const loadDupDataCar = async () => {
        // setLoading(true)
        // await axios.post(checkcarad, { carId: 653, carPlateNumber: "TEST-0058", carProvince: "ขอนแก่น" })
        await axios.post(checkcarad, { carId: dataFromTable.carId, carPlateNumber: dataFromTable.carPlateNumber, carProvince: dataFromTable.carProvince })
            .then(res => {
                if (res.status === 200) {
                    if (res.data !== null) {
                        setCheckOld(true)
                        setGetSize(1000)
                        setOldData(res.data)
                    }
                }
            }).catch((err) => console.log(err))
    };

    const loadDupDataLand = async () => {
        // setLoading(true)
        // await axios.post(checklandad, { landId: 1920, numberLand: "11", provinces: "ยะลา", district: "เมืองยะลา" })
        await axios.post(checklandad, { landId: dataFromTable.carId, provinces: dataFromTable.carBrand, district: dataFromTable.carYear, numberLand: dataFromTable.carPlateNumber })
            .then(res => {
                if (res.status === 200) {
                    if (res.data !== null) {
                        setCheckOld(true)
                        setGetSize(1000)
                        setOldData(res.data)
                    }
                }
            }).catch((err) => console.log(err))
    };

    const handleOk = async () => {
        setLoading(true)
        await axios.put(accept, statusAccept)
            .then(res => {
                if (res.status === 200) {
                    if (dataFromTable.carPriceStatus === false) {
                        if (dataFromTable.car === "car") {
                            RateCar("top")
                        } else {
                            RateLand("top")
                        }
                        // setLoading(false)
                    } else {
                        Success("top")
                        // setLoading(false)
                    }
                    setTimeout(() => {
                        close(false);
                    }, 1000);
                }
            }).catch((err) => console.log(err))
        // setLoading(false)
    };

    const pickData = () => {
        if (dataFromTable.car === "car") {
            if (dataFromTable.carPriceStatus === false) {
                setstatusAccept({
                    customerId: dataFromTable.customerId,
                    carId: dataFromTable.carId,
                    loanId: dataFromTable.loanId,
                    car: dataFromTable.car,
                    approvalStatus: 17,

                })
            } else {
                setstatusAccept({
                    customerId: dataFromTable.customerId,
                    carId: dataFromTable.carId,
                    loanId: dataFromTable.loanId,
                    car: dataFromTable.car,
                    // set เวลา
                    acceptDate: dayjs(),
                    approvalStatus: 18
                })
            }
        } else if (dataFromTable.car === "land") {
            setstatusAccept({
                customerId: dataFromTable.customerId,
                carId: dataFromTable.carId,
                loanId: dataFromTable.loanId,
                car: dataFromTable.car,
                // carDateStatus: formattedTime,

                // set เวลา
                acceptDate: dayjs(),
                approvalStatus: 18
            })
        }
    }

    const renderDupNote = ({ item, index }) => {
        return (
            <Row gutter={32} justify={'center'}>
                <Col className='gutter-row' span={24}>
                    <Card key={index} style={{ width: '270px', textAlign: 'left' }}>
                        <div style={{ marginBottom: 0 }}>
                            <span>สาเหตุ : </span>
                            {item?.cause}
                        </div>
                        <div style={{ marginBottom: 0 }}>
                            <span>ผู้สร้างหมายเหตุ : </span>
                            {item?.noteBy}
                        </div>
                        <div style={{ marginBottom: 0 }}>
                            <span>หมายเหตุ : </span>
                            {item?.note}
                        </div>
                        <div style={{ marginBottom: 0 }}>
                            <span>วันที่หมายเหตุ : </span>
                            {dayjs(item?.noteDate).format("DD/MM/YYYY")}
                        </div>
                        <div style={{ marginBottom: 0 }}>
                            <span>สถานะ : </span>
                            <Tag color="gold">{item?.status}</Tag>
                        </div>
                    </Card >
                </Col>
            </Row>
        )
    }

    return (
        <div>
            <Modal title="การรับงาน" open={open} onCancel={handleCancel} width={getSize}
                footer={[
                    <Button type='primary' onClick={handleOk} disabled={loading} style={{ backgroundColor: "green" }}>ยืนยัน</Button>,
                    <Button type='primary' danger onClick={handleCancel}>ปิด</Button>
                ]}>
                <Card>
                    {
                        dataModalEdit?.car === "car" ?
                            <Divider orientation="left"><b>รายละเอียดรถ</b></Divider>
                            :
                            <Divider orientation="left"><b>รายละเอียดที่ดิน</b></Divider>
                    }
                    <Form
                        name="basic"
                        labelCol={{
                            span: 8,
                        }}
                        wrapperCol={{
                            span: 16,
                        }}>
                        {
                            checkOld ?
                                <>
                                    {
                                        dataModalEdit?.car === "car" ?
                                            <>
                                                <Row>
                                                    <Col span={12}>
                                                        <Form.Item label="ชื่อลูกค้า" style={{ marginBottom: 0 }}>
                                                            {dataModalEdit?.snam}{dataModalEdit?.firstName} {dataModalEdit?.lastName}
                                                        </Form.Item>
                                                        <Form.Item label="เลขทะเบียนรถ" style={{ marginBottom: 0 }}>
                                                            {dataModalEdit?.carPlateNumber} {dataModalEdit?.carProvince}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Form.Item label="รายละเอียด" style={{ marginBottom: 0 }}>
                                                            {dataModalEdit?.carBrand} {dataModalEdit?.carModel} {dataModalEdit?.carYear}
                                                        </Form.Item>
                                                        <Form.Item label="เรท" style={{ marginBottom: 0 }}>
                                                            {currencyFormatOne(dataModalEdit?.carPrice)}
                                                        </Form.Item>
                                                        <Form.Item label="สถานะ" style={{ marginBottom: 0 }}>
                                                            <Tag color="gold">{dataModalEdit?.approvalStatusName}</Tag>
                                                            <Tag color="green">{dataModalEdit?.productLoanType}</Tag>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Divider orientation="left"><b style={{ color: "red" }}>*พบข้อมูลที่เคสเสนอมาแล้ว!</b></Divider>
                                                <Collapse>
                                                    {
                                                        oldData?.map((car, index) => (
                                                            <Panel
                                                                header={
                                                                    <>
                                                                        <strong>วันที่เสนอ :</strong> {dayjs(car.carInput).format("DD/MM/YYYY")} <strong>อยู่ในสถานะ :</strong> <Tag color={ApprovalStatusColor(car?.carLoanDetails?.approvalStatus)}>{car?.carLoanDetails?.approvalStatusTable?.name}</Tag>
                                                                    </>
                                                                }
                                                                key={index}
                                                            >
                                                                <div style={{ marginBottom: 0 }}>
                                                                    <strong><span>ชื่อลูกค้า : </span></strong>
                                                                    {car?.customer?.snam}{car.customer?.firstname} {car.customer?.lastname}
                                                                </div>
                                                                <div style={{ marginBottom: 0 }}>
                                                                    <strong><span>เลขทะเบียนรถ : </span></strong>
                                                                    {car?.carPlateNumber} {car?.carProvince}
                                                                </div>
                                                                <div style={{ marginBottom: 0 }}>
                                                                    <strong><span>รายละเอียด : </span></strong>
                                                                    {car?.carBrand} {car?.carModel} {car?.carYear}
                                                                </div>
                                                                <div style={{ marginBottom: 0 }}>
                                                                    <strong><span>เรท : </span></strong>
                                                                    {currencyFormatOne(car?.carPrice)}
                                                                </div>
                                                                <div style={{ marginBottom: 0 }}>
                                                                    <strong><span>สถานะ : </span></strong>
                                                                    <Tag color={ApprovalStatusColor(car?.carLoanDetails?.approvalStatus)}>{car?.carLoanDetails?.approvalStatusTable?.name}</Tag>
                                                                    <Tag color="green">{car?.carLoanDetails?.productLoanType}</Tag>
                                                                </div>
                                                                <Divider orientation="left"><b style={{ color: "red" }}>*การอนุมัติ!</b></Divider>
                                                                {
                                                                    car?.carLoanDetails.approvedBy ?
                                                                        <>
                                                                            <div style={{ marginBottom: 0 }}>
                                                                                <strong><span>ผู้อนุมัติ : </span></strong>
                                                                                {car?.carLoanDetails.approvedBy}
                                                                            </div>
                                                                            <div style={{ marginBottom: 0 }}>
                                                                                <strong><span>ยอดที่อนุมัติ : </span></strong>
                                                                                {car?.carLoanDetails.approvedLoanAmount}
                                                                            </div>
                                                                            <div style={{ marginBottom: 0 }}>
                                                                                <strong><span>งวดที่อนุมัติ : </span></strong>
                                                                                {car?.carLoanDetails.approvedLoanTerm}
                                                                            </div>
                                                                        </>
                                                                        : <span>*ยังไม่อนุมัติ!</span>
                                                                }
                                                                <Divider />
                                                                <div style={{ marginBottom: 0 }}>
                                                                    <strong><span>ผู้เสนอเคส : </span></strong>
                                                                    {car?.carLoanDetails.proposalBy}
                                                                </div>
                                                                <div style={{ marginBottom: 0 }}>
                                                                    <strong><span>สาขา : </span></strong>
                                                                    {car?.carLoanDetails.branch}
                                                                </div>
                                                                <div style={{ marginBottom: 0 }}>
                                                                    <strong><span>วันที่เสนอ : </span></strong>
                                                                    {dayjs(car?.carInput).format("DD/MM/YYYY")}
                                                                </div>
                                                                <Divider orientation="left"><b style={{ color: "red" }}>*หมายเหตุ!</b></Divider>
                                                                <Row gutter={32} justify={'center'}>
                                                                    {
                                                                        car?.carLoanDetails?.notes?.map((item, index) => {
                                                                            return renderDupNote({ item, index })
                                                                        })
                                                                    }
                                                                </Row>
                                                            </Panel>

                                                        ))}
                                                </Collapse>
                                            </>
                                            :
                                            <>
                                                <Row>
                                                    <Col span={12}>
                                                        <Form.Item label="ชื่อลูกค้า" style={{ marginBottom: 0 }}>
                                                            {dataModalEdit?.snam}{dataModalEdit?.firstName} {dataModalEdit?.lastName}
                                                        </Form.Item>
                                                        <Form.Item label="รายละเอียด" style={{ marginBottom: 0 }}>
                                                            {dataModalEdit?.carBrand} {dataModalEdit?.carYear} เลขโฉนด {dataModalEdit?.carPlateNumber}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Form.Item label="พื้นที่ตารางวา" style={{ marginBottom: 0 }}>
                                                            {parseFloat(dataModalEdit?.carPrice)}
                                                        </Form.Item>
                                                        <Form.Item label="ราคาที่ดิน/ตารางวา" style={{ marginBottom: 0 }}>
                                                            {parseFloat(dataModalEdit?.carProvince)}
                                                        </Form.Item>
                                                        <Form.Item label="สถานะ" style={{ marginBottom: 0 }}>
                                                            <Tag color="gold">{dataModalEdit?.approvalStatusName}</Tag>
                                                            <Tag color="green">{dataModalEdit?.productLoanType}</Tag>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Divider orientation="left"><b style={{ color: "red" }}>*พบข้อมูลที่เคสเสนอมาแล้ว!</b></Divider>
                                                <Collapse>
                                                    {
                                                        oldData?.map((land, index) => (
                                                            <Panel
                                                                header={
                                                                    <>
                                                                        <strong>วันที่เสนอ :</strong> {dayjs(land.landInput).format("DD/MM/YYYY")} <strong>อยู่ในสถานะ :</strong> <Tag color={ApprovalStatusColor(land?.landLoanDetails?.approvalStatus)}>{land?.landLoanDetails?.approvalStatusTable?.name}</Tag>
                                                                    </>
                                                                }
                                                                key={index}
                                                            >
                                                                <div style={{ marginBottom: 0 }}>
                                                                    <strong><span>ชื่อลูกค้า : </span></strong>
                                                                    {land?.customer?.snam}{land?.customer?.firstname} {land?.customer?.lastname}
                                                                </div>
                                                                <div style={{ marginBottom: 0 }}>
                                                                    <strong><span>รายละเอียด : </span></strong>
                                                                    {land?.provinces} {land?.district} เลขโฉนด {land?.numberLand}
                                                                </div>
                                                                <div style={{ marginBottom: 0 }}>
                                                                    <strong><span>สถานะ : </span></strong>
                                                                    <Tag color={ApprovalStatusColor(land?.landLoanDetails?.approvalStatus)}>{land?.landLoanDetails?.approvalStatusTable?.name}</Tag>
                                                                    <Tag color="green">{land?.landLoanDetails?.productLoanLandType}</Tag>
                                                                </div>
                                                                <Divider orientation="left"><b style={{ color: "red" }}>*การอนุมัติ!</b></Divider>
                                                                {
                                                                    land?.landLoanDetails.approvedBy ?
                                                                        <>
                                                                            <div style={{ marginBottom: 0 }}>
                                                                                <strong><span>ผู้อนุมัติ : </span></strong>
                                                                                {land?.landLoanDetails.approvedBy}
                                                                            </div>
                                                                            <div style={{ marginBottom: 0 }}>
                                                                                <strong><span>ยอดที่อนุมัติ : </span></strong>
                                                                                {land?.landLoanDetails.approvedLoanAmount}
                                                                            </div>
                                                                            <div style={{ marginBottom: 0 }}>
                                                                                <strong><span>งวดที่อนุมัติ : </span></strong>
                                                                                {land?.landLoanDetails.approvedLoanTerm}
                                                                            </div>
                                                                        </>
                                                                        : <span>*ยังไม่อนุมัติ!</span>
                                                                }
                                                                <Divider />
                                                                <div style={{ marginBottom: 0 }}>
                                                                    <strong><span>ผู้เสนอเคส : </span></strong>
                                                                    {land?.landLoanDetails.proposalBy}
                                                                </div>
                                                                <div style={{ marginBottom: 0 }}>
                                                                    <strong><span>สาขา : </span></strong>
                                                                    {land?.landLoanDetails.branch}
                                                                </div>
                                                                <div style={{ marginBottom: 0 }}>
                                                                    <strong><span>วันที่เสนอ : </span></strong>
                                                                    {dayjs(land?.carInput).format("DD/MM/YYYY")}
                                                                </div>
                                                                <Divider orientation="left"><b style={{ color: "red" }}>*หมายเหตุ!</b></Divider>
                                                                <Row gutter={32} justify={'center'}>
                                                                    {
                                                                        land?.landLoanDetails?.notes?.map((item, index) => {
                                                                            return renderDupNote({ item, index })
                                                                        })
                                                                    }
                                                                </Row>
                                                            </Panel>

                                                        ))}
                                                </Collapse>
                                            </>
                                    }
                                </>
                                :
                                <>
                                    <Form.Item label="ชื่อลูกค้า" style={{ marginBottom: 0 }}>
                                        {dataModalEdit?.snam}{dataModalEdit?.firstName} {dataModalEdit?.lastName}
                                    </Form.Item>
                                    {dataModalEdit?.car === "car" ?
                                        <>
                                            <Form.Item label="รายละเอียด" style={{ marginBottom: 0 }}>
                                                {dataModalEdit?.carBrand} {dataModalEdit?.carModel} {dataModalEdit?.carYear}
                                            </Form.Item>
                                            <Form.Item label="เลขทะเบียนรถ" style={{ marginBottom: 0 }}>
                                                {dataModalEdit?.carPlateNumber} {dataModalEdit?.carProvince}
                                            </Form.Item>
                                            <Form.Item label="เรท" style={{ marginBottom: 0 }}>
                                                {currencyFormatOne(dataModalEdit?.carPrice)}
                                            </Form.Item>
                                        </>
                                        :
                                        <>
                                            <Form.Item label="รายละเอียด" style={{ marginBottom: 0 }}>
                                                {dataModalEdit?.carBrand} {dataModalEdit?.carYear} เลขโฉนด {dataModalEdit?.carPlateNumber}
                                            </Form.Item>
                                            <Form.Item label="พื้นที่ตารางวา" style={{ marginBottom: 0 }}>
                                                {parseFloat(dataModalEdit?.carPrice)}
                                            </Form.Item>
                                            <Form.Item label="ราคาที่ดิน/ตารางวา" style={{ marginBottom: 0 }}>
                                                {parseFloat(dataModalEdit?.carProvince)}
                                            </Form.Item>
                                        </>
                                    }
                                    <Form.Item label="สถานะ" style={{ marginBottom: 0 }}>
                                        <Tag color="gold">{dataModalEdit?.approvalStatusName}</Tag>
                                        <Tag color="green">{dataModalEdit?.productLoanType}</Tag>
                                    </Form.Item>
                                </>
                        }
                    </Form>
                </Card>
            </Modal>
        </div >
    )
}

export default ModalAccept