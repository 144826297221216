import React, { useRef, useState, useEffect } from "react";
import { useReactToPrint } from "react-to-print";
import { Button, Card, Col, Divider, Form, Input, Row, Space, Spin, Table, notification, InputNumber, Tag, DatePicker, Checkbox, Select, Modal } from 'antd'
import axios from 'axios';
import dayjs from "dayjs";
import ExcelJS from 'exceljs';
import { PrinterOutlined } from '@ant-design/icons';
import { DataExcelPay } from './excel/data_excel';
import { mainDataExcel, headersThai, headersColumsName, mainDataExcelDetail, headersThaiDetail, headersColumsNameDetail } from './excel/cl';
import { columns, columnsDetail, columnsHDPAYMENT, columnsAROTHR } from './table/cm';
import { currencyFormatOne, msSale, msError, msDue, msNil, msLimit, msCreateDue, msDueNil, msConnt, msPAYCODE, msOK, msPrint, msSaleFollow, msData, msCancel, msClose, msCal, currencyFormat, msPayGRADE, msErrorInsert, getRandomInt, msErrorInst } from "../file_mid/allFormat";
import { calPay, calPayAROTHR, calPayGRADE } from "./cal/Cal";
import Payment from "../file_mid/reportPayment/Payment3New";
import { loadPrintDataPayment, loadDataPayment } from "./load_data_print/data_pay";
import { payamt, confirmPay, loadPayamt } from "../file_mid/all_api";
import { optionsPayType } from "../file_mid/all_options";

export default function Main() {
  const { confirm } = Modal
  const token = localStorage.getItem('token');
  const currentToken = localStorage.getItem("currentToken");
  const currentDateTime = dayjs()
  const [form] = Form.useForm();
  const componentPDF = useRef();
  const generatePDF = useReactToPrint({ content: () => componentPDF.current, documentTitle: "ใบรับเงิน", });
  const [loading, setLoading] = useState(false);
  const [checkGuas, setCheckGuas] = useState("")
  const [dataRec, setDataRec] = useState();
  const [showTable, setShowTable] = useState(false);
  const [isClicked, setIsClicked] = useState(1);
  const [checkAgain, setCheckAgain] = useState(true);
  const [checkDue, setCheckDue] = useState(false);
  const [checkDataInsert, setCheckDataInsert] = useState(false);
  const [checkButton, setCheckButton] = useState(1);
  const [checkButtonPay, setCheckButtonPay] = useState(true);
  const [checkCt, setCheckCt] = useState(true);
  const [api, contextHolder] = notification.useNotification();
  const [dataCode, setDataCode] = useState({ codeQ: "" })
  const [dataSend, setDataSend] = useState({
    CONTNO: "",
    money: 0,
    sale: 0,
    saleFollow: 0,
    date: currentDateTime,
    payType: "จ่ายเข้าตารางดิว",
    PAYCODE: "",
    selectPay: 1,
  });
  const [getPAYCODE, setGetPAYCODE] = useState([]);
  const [dataAll, setDataAll] = useState(null);
  const [dataTable, setDataTable] = useState([]);
  const [dataHDPAYMENT, setDataHDPAYMENT] = useState([]);
  const [dataAROTHR, setDataAROTHR] = useState([]);
  const [dataTableDetail, setDataTableDetail] = useState([]);
  const [dataPay, setDataPay] = useState([]);
  const [calData, setCalData] = useState({
    ALLPAYAMT: 0,
    ALLPAYFOLLOW: 0,
    ALLPAYINTEFF: 0,
    ALLPAYTON: 0,
    ALLCAL: 0,
    text: "",
  });
  const [sum, setSum] = useState({
    sumAmt: 0,
    sumFollow: 0,
    sumAll: 0,
    sumMoney: 0,
    HDPAY: 0,
    amt: 0,
    nopay: 0,
    GRDCOD: "",
    AROTHR: 0,
    payGRADE: 0,
    flagTMPTELDEBT: null,
  });
  useEffect(() => {
    loadData()
  }, [])
  const loadData = async () => {
    setLoading(true)
    try {
      const result = await loadDataPayment();
      setGetPAYCODE(result)
    } catch (error) {
      console.error("Error in loadData: ", error);
      setGetPAYCODE([])
    } finally {
      setLoading(false);
    }
  }
  const changeSelectPay = (value) => {
    console.log("value =:", value)
    setDataSend({ ...dataSend, selectPay: value, money: 0, sale: 0, saleFollow: 0 })
    form.setFieldsValue({ money: "", sale: "", saleFollow: "", });
  }
  const changePAYCODE = (value) => {
    console.log("value =:", value)
    setDataSend({ ...dataSend, PAYCODE: value })
  }
  const funcMoney = (value) => {
    console.log("value =:", value)
    setCheckDataInsert(false)
    if (value) {
      setDataSend({ ...dataSend, money: parseFloat(value) })
    } else {
      setDataSend({ ...dataSend, money: 0 })
    }
  }
  const funcSale = (value) => {
    setCheckDataInsert(false)
    console.log("value", value)
    console.log("(dataPay?.mixDok)", dataPay?.mixDok)
    if (dataSend.selectPay === 1) { // ปกติ
      if (value) {
        if (value <= dataPay?.mixDok) {
          // console.log("if", value)
          setDataSend({ ...dataSend, sale: parseFloat(value) })
        } else {
          // console.log("else", value)
          setDataSend({ ...dataSend, sale: 0 })
          form.setFieldsValue({ sale: "", });
          msSale(api, "top")
        }
      } else {
        setDataSend({ ...dataSend, sale: 0 })
      }
    } else { // พิเศษ
      if (value) {
        setDataSend({ ...dataSend, sale: parseFloat(value) })
      } else {
        setDataSend({ ...dataSend, sale: 0 })
      }
    }
  }
  const funcSaleFollow = (value) => {
    setCheckDataInsert(false)
    console.log("vvv", value)
    console.log("dataPay?.followCCB", dataPay?.followCCB)
    if (value) {
      if (value <= dataPay?.followCCB) {
        setDataSend({ ...dataSend, saleFollow: parseFloat(value) })
      } else {
        setDataSend({ ...dataSend, saleFollow: 0 })
        form.setFieldsValue({ saleFollow: "", });
        msSaleFollow(api, "top")
      }
    } else {
      setDataSend({ ...dataSend, saleFollow: 0 })
    }
  }
  const onChangecheckGuas = (value) => {
    console.log(value)
    if (value) {
      setCheckGuas(value);
      setDataSend({ ...dataSend, payType: value })
    }
  };

  const callHandleSubmitPay = async () => {
    if (sum?.flagTMPTELDEBT && sum?.flagTMPTELDEBT === true) {
      console.log("mm", (dataSend.money + dataSend.sale + dataSend.saleFollow))
      if ((dataSend.money + dataSend.sale + dataSend.saleFollow) >= sum?.payGRADE) {
        handleSubmitPay()
      } else {
        msPayGRADE(api, "top")
      }
    } else {
      handleSubmitPay()
    }
  }

  const handleSubmitPay = async () => {
    // if (dataSend.CONTNO !== "" && dataSend.money > 0 && dataSend.payType !== "" && (dataSend.money + dataSend.sale + dataSend.saleFollow) >= sum?.amt) {
    if (dataSend.CONTNO !== "" && (dataSend.money + dataSend.sale + dataSend.saleFollow) > 0 && dataSend.payType !== "" && dataSend.selectPay > 0) {
      setCheckButtonPay(false)
      let mixData
      // if (dataSend?.sale > 0) {
      mixData = {
        CONTNO: dataSend.CONTNO, money: dataSend.money,
        sale: dataSend.sale ? dataSend.sale : 0, saleFollow: dataSend.saleFollow ? dataSend.saleFollow : 0,
        date: dataSend.date, payType: dataSend.payType, selectPay: dataSend.selectPay,
      }
      // } else {
      //   mixData = { CONTNO: dataSend.CONTNO, money: dataSend.money, sale: 0, date: dataSend.date, payType: dataSend.payType }
      // }
      console.log("mixData", mixData)
      // console.log("sumMoney", mixData.money + mixData.sale + mixData.saleFollow)
      // const tk = JSON.parse(token)
      // const headers = {
      //   "Authorization": `Bearer ${tk}`,
      //   "Menu": JSON.stringify("5")
      // }
      setLoading(true)
      // await axios.post("http://localhost:8070/api-db2/payamt", mixData)
      await axios.post(payamt, mixData)
        .then(async (res) => {
          if (res.status === 200) {
            if (res.data) {
              setDataAll(res.data)
              setCheckDataInsert(true)
              console.log("res.data payamt", res.data)
              // await calPay(res.data, "")
              const result = await calPay(res.data, "");
              console.log("result calPay", result)
              setSum({ ...sum, sumMoney: res.data.money, HDPAY: res.data.HDPAY })
              // sumMoney = mixData.money + mixData.sale + mixData.saleFollow
              setCalData(result)
              setDataTable(res.data.result)
              if (res.data?.detailPay) {
                setDataTableDetail(res.data.detailPay)
              } else {
                setDataTableDetail([])
              }
            } else {
              setDataAll(null)
              setCalData({ ALLPAYAMT: 0, ALLPAYFOLLOW: 0, ALLPAYINTEFF: 0, ALLPAYTON: 0, ALLCAL: 0, text: "ไม่สามารถปิดบช.ได้ กรุณาติดต่อไอที" })
              setDataTable([])
              setDataTableDetail([])
              msClose(api, "top")
            }
          } else if (res.status === 202) {
            // setDataAll(res.data)
            console.log("ไม่ได้จ่ายล่วงหน้า คิดดอกเป็นรายวัน", res.data)
            // await calPay(res.data, "ไม่ได้จ่ายล่วงหน้า คิดดอกเป็นรายวัน")
            const result = await calPay(res.data, "ไม่ได้จ่ายล่วงหน้า คิดดอกเป็นรายวัน");
            setCalData(result)
            setDataTable([])
            setDataTableDetail([])
            // setDataPay([])
          } else if (res.status === 203) {
            console.log("จำนวนเงินที่จ่ายเข้ามา มากกว่าเงินต้นคงเหลือ", res.data)
            // setCalData({ ALLPAYAMT: 0, ALLPAYFOLLOW: 0, ALLPAYINTEFF: 0, ALLPAYTON: 0, ALLCAL: res.data.ccb, text: "จำนวนเงินที่จ่ายเข้ามา มากกว่าเงินต้นคงเหลือ" })
            setCalData({ ALLPAYAMT: 0, ALLPAYFOLLOW: 0, ALLPAYINTEFF: 0, ALLPAYTON: 0, ALLCAL: 0, text: "จำนวนเงินที่จ่ายเข้ามา มากกว่าเงินต้นคงเหลือ" })
            setDataTable([])
            setDataTableDetail([])
            // setDataPay([])
            msLimit(api, "top")
          } else if (res.status === 204) {
            console.log("กรอกยอดเงินไม่ถูกต้อง")
            setCalData({ ALLPAYAMT: 0, ALLPAYFOLLOW: 0, ALLPAYINTEFF: 0, ALLPAYTON: 0, ALLCAL: 0, text: "กรอกยอดเงินไม่ถูกต้อง" })
            setDataTable([])
            setDataTableDetail([])
            // setDataPay([])
            msNil(api, "top")
          } else if (res.status === 205) {
            console.log("เงินน้อยกว่าดอกเบี้ยสร้างดิว")
            setDataTable([])
            setDataTableDetail([])
            msCreateDue(api, "top")
          } else if (res.status === 208) {
            console.log("ไม่พบดิวล่าสุด")
            setDataTable([])
            setDataTableDetail([])
            msDueNil(api, "top")
          } else {
            setCalData({ ALLPAYAMT: 0, ALLPAYFOLLOW: 0, ALLPAYINTEFF: 0, ALLPAYTON: 0, ALLCAL: 0, text: "" })
            setDataTable([])
            setDataTableDetail([])
            // setDataPay([])
            msNil(api, "top")
          }
          setTimeout(() => {
            setDataCode({ codeQ: "" })
            form.setFieldsValue({ codeQ: "", });
            setLoading(false);
          }, 1000);
        })
        .catch((err) => {
          console.log("err", err)
          setCalData({ ALLPAYAMT: 0, ALLPAYFOLLOW: 0, ALLPAYINTEFF: 0, ALLPAYTON: 0, ALLCAL: 0, text: "" })
          setDataTable([])
          setDataTableDetail([])
          // setDataPay([])
          msError(api, "top")
          setLoading(false)
        })
    } else {
      msNil(api, "top")
    }
  }

  const handleSubmit = async () => {
    if (dataSend?.CONTNO !== "") {
      setCheckCt(false);
      setCheckDataInsert(false);
      setCheckButtonPay(true);
      setCheckButton(1);
      setIsClicked(1);
      setDataRec();
      setShowTable(false);
      setDataAll(null);
      let sumAmt = 0;
      let sumFollow = 0;
      let sumAll = 0;
      let resultCalPayAROTHR = 0;
      let resultCalPayGRADE = 0;
      setLoading(true)
      // await axios.post("http://localhost:8070/api-db2/load-payamt", dataSend)
      await axios.post(loadPayamt, dataSend)
        .then(async (res) => {
          console.log("res", res.data)
          if (res.data?.AROTHR) {
            resultCalPayAROTHR = await calPayAROTHR(res.data.AROTHR);
            console.log("resultCalPayAROTHR", resultCalPayAROTHR)
          }
          if (res.status === 200) {
            onChangecheckGuas("จ่ายเข้าตารางดิว")
            console.log("res.data load-payamt", res.data)
            setDataPay(res.data)
            if (res.data?.result && res.data?.ccb !== 0) {
              setCheckDue(true)
              setDataTable(res.data.result)
              if (res.data?.flagTMPTELDEBT?.Flag === true) {
                resultCalPayGRADE = await calPayGRADE(res.data.result[0]?.GRDCOD, res.data.result[0]?.DUEAMT, res.data?.flagTMPTELDEBT.GRDCAL);
                console.log("resultCalPayGRADE", resultCalPayGRADE)
              }
              if (res.data.flagDue === false) {
                await res.data.result.forEach((item) => {
                  sumAmt += item.DUEAMT - item.PAYAMT;
                  sumFollow += item.FOLLOWAMT - item.PAYFOLLOW;
                });
                sumAll = sumAmt + sumFollow
                setSum({
                  sumAmt: sumAmt, sumFollow: sumFollow, sumAll: sumAll + resultCalPayAROTHR, sumMoney: dataSend.money + dataSend.sale + dataSend.saleFollow,
                  HDPAY: 0, amt: res.data.result[0]?.DUEAMT, nopay: res.data.result?.length, GRDCOD: res.data.result[0]?.GRDCOD, AROTHR: resultCalPayAROTHR, payGRADE: resultCalPayGRADE, flagTMPTELDEBT: res.data?.flagTMPTELDEBT?.Flag,
                  // tonKongCCB: 0, dokCCB: 0, followCCB: 0,
                })
              } else {
                setSum({
                  sumAmt: 0, sumFollow: 0, sumAll: 0, sumMoney: dataSend.money + dataSend.sale + dataSend.saleFollow,
                  HDPAY: 0, amt: res.data.result[0]?.DUEAMT, nopay: 0, GRDCOD: res.data.result[0]?.GRDCOD, AROTHR: resultCalPayAROTHR, payGRADE: resultCalPayGRADE, flagTMPTELDEBT: res.data?.flagTMPTELDEBT?.Flag,

                })
                if (res.data.ccb <= 0) {
                  setCheckCt(true)
                  setCheckDue(false)
                }
              }
              if (res.data?.detailPay) {
                setDataTableDetail(res.data?.detailPay)
              } else {
                setDataTableDetail([])
              }
              console.log("sumAmt", sumAmt)
              console.log("sumFollow", sumFollow)
            } else {
              setCheckCt(true)
              setCheckDue(false)
              setSum({
                sumAmt: 0, sumFollow: 0, sumAll: 0, sumMoney: dataSend.money + dataSend.sale + dataSend.saleFollow,
                HDPAY: 0, amt: 0, nopay: 0, GRDCOD: "", AROTHR: 0, payGRADE: 0, flagTMPTELDEBT: null
              })
              setDataTable([])
              setDataTableDetail([])
            }
          } else if (res.status === 201) {
            console.log("res.data", res.data)
            setCheckCt(true)
            onChangecheckGuas("")
            setCheckDue(false)
            setDataTable([])
            setDataTableDetail([])
            setDataPay([])
            setSum({
              sumAmt: 0, sumFollow: 0, sumAll: 0, sumMoney: dataSend.money + dataSend.sale + dataSend.saleFollow,
              HDPAY: 0, amt: 0, nopay: 0, GRDCOD: "", AROTHR: 0, payGRADE: 0, flagTMPTELDEBT: null
            })
            msDue(api, "top")
          } else {
            setCheckCt(true)
            onChangecheckGuas("")
            setCheckDue(false)
            setDataTable([])
            setDataTableDetail([])
            setDataPay([])
            setSum({
              sumAmt: 0, sumFollow: 0, sumAll: 0, sumMoney: dataSend.money + dataSend.sale + dataSend.saleFollow,
              HDPAY: 0, amt: 0, nopay: 0, GRDCOD: "", AROTHR: 0, payGRADE: 0
            })
            msConnt(api, "top")
          }
          setTimeout(() => {
            setCheckAgain(true);
            setDataHDPAYMENT(res.data?.HDPAYMENT)
            setDataAROTHR(res.data?.AROTHR)
            setDataSend({ ...dataSend, money: 0, sale: 0, saleFollow: 0, date: currentDateTime, payType: "จ่ายเข้าตารางดิว", PAYCODE: "", selectPay: 1, })
            setCalData({ ALLPAYAMT: 0, ALLPAYFOLLOW: 0, ALLPAYINTEFF: 0, ALLPAYTON: 0, ALLCAL: 0, text: "" })
            setDataCode({ codeQ: "" })
            form.setFieldsValue({
              money: "",
              sale: "",
              saleFollow: "",
              codeQ: "",
              PAYCODE: "",
              selectPay: 1,
            });
            setLoading(false);
          }, 1000);
        })
        .catch((err) => {
          console.log("err", err)
          setCheckAgain(true);
          setCheckCt(true)
          onChangecheckGuas("")
          setCheckDue(false)
          setCalData({ ALLPAYAMT: 0, ALLPAYFOLLOW: 0, ALLPAYINTEFF: 0, ALLPAYTON: 0, ALLCAL: 0, text: "" })
          setDataTable([])
          setDataTableDetail([])
          setDataHDPAYMENT([])
          setDataAROTHR([])
          setDataPay([])
          setDataCode({ codeQ: "" })
          msError(api, "top")
          setLoading(false)
        })
    } else {
      msConnt(api, "top")
    }
  }
  const showConfirm = () => {
    // const randomNumber = getRandomInt(3)
    // console.log("randomNumber", randomNumber)
    confirm({
      title: (
        <Row>
          <Form form={form}>
            <Col span={24}>
              <Form.Item label="" style={{ margin: 0 }}>
                <b>{"คอมเฟิร์มทำรายการ"}</b>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="เลขที่สัญญา" style={{ margin: 0 }}>
                <b style={{ color: 'blue' }}>{dataSend?.CONTNO}</b>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="รับเงิน" style={{ margin: 0 }}>
                <b style={{ color: 'blue' }}>{currencyFormatOne(dataSend?.money)}</b>
                <span style={{ marginLeft: '10px' }}>บาท</span>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="ส่วนลด" style={{ margin: 0 }}>
                <b style={{ color: 'blue' }}>{currencyFormatOne(dataSend?.sale)}</b>
                <span style={{ marginLeft: '10px' }}>บาท</span>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="ส่วนลดค่าทวงถาม" style={{ margin: 0 }}>
                <b style={{ color: 'blue' }}>{currencyFormatOne(dataSend?.saleFollow)}</b>
                <span style={{ marginLeft: '10px' }}>บาท</span>
              </Form.Item>
            </Col>
          </Form>
        </Row>
      ),
      content: 'กด OK เพื่อยืนยัน',
      onOk() {
        setLoading(true);
        // if (randomNumber > 0) {
        //   setTimeout(() => {
        insert();
        // }, randomNumber * 1000);
        // } else {
        //   insert();
        // }
      },
      onCancel() {
        // form.resetFields()
        msCancel(api, "top");
      },
    });
  };
  const showConfirmClear = () => {
    confirm({
      title: 'คุณต้องการที่ล้างข้อมูลนี้ใช่หรือไม่ ?',
      content: 'กด OK เพื่อยืนยัน',
      onOk() {
        setLoading(true)
        clearData()
      },
      onCancel() {
        msCancel(api, "top")
      },
    });
  }
  const clearData = async () => {
    form.setFieldsValue({
      CONTNO: "",
      money: "",
      sale: "",
      saleFollow: "",
      codeQ: "",
      PAYCODE: "",
    });
    setTimeout(() => {
      setCheckGuas("")
      setDataRec();
      setCheckAgain(true);
      setShowTable(false);
      setIsClicked(1);
      setCheckDue(false);
      setCheckDataInsert(false);
      setCheckButton(1);
      setCheckButtonPay(true);
      setCheckCt(true);
      setDataCode({ codeQ: "" })
      setDataSend({ CONTNO: "", money: 0, sale: 0, saleFollow: 0, date: currentDateTime, payType: "จ่ายเข้าตารางดิว", PAYCODE: "", selectPay: 1, });
      setDataAll(null);
      setDataHDPAYMENT([]);
      setDataAROTHR([]);
      setDataTable([]);
      setDataTableDetail([]);
      setDataPay([]);
      setCalData({ ALLPAYAMT: 0, ALLPAYFOLLOW: 0, ALLPAYINTEFF: 0, ALLPAYTON: 0, ALLCAL: 0, text: "" });
      setSum({ sumAmt: 0, sumFollow: 0, sumAll: 0, sumMoney: dataSend.money + dataSend.sale + dataSend.saleFollow, HDPAY: 0, amt: 0, nopay: 0, GRDCOD: "", AROTHR: 0, payGRADE: 0, flagTMPTELDEBT: null });
      setLoading(false)
    }, 1000);
  }
  const insert = async () => {
    let mix = {}
    if (checkAgain === true) {
      // if (dataAll && dataAll?.result && dataAll?.detailPay && (dataSend.money + dataSend.sale + dataSend.saleFollow) >= sum?.amt) {
      if (dataAll && dataAll?.result && dataAll?.detailPay) {
        if (checkDataInsert === true) {
          if (dataSend.PAYCODE && dataSend.PAYCODE !== "") {
            console.log("dataAll", dataAll)
            const tk = JSON.parse(token)
            const headers = {
              "Authorization": `Bearer ${tk}`,
              "Menu": JSON.stringify("26")
            }
            mix = { ...dataAll, PAYCODE: dataSend.PAYCODE, fcmToken: currentToken }
            console.log("mix", mix)
            // await axios.post("http://localhost:8070/auth-pay/confirm-pay", mix, { headers: headers })
            await axios.post(confirmPay, mix, { headers: headers })
              .then(async (res) => {
                console.log("ok insert", res.data)
                if (res.status === 200) {
                  form.setFieldsValue({ codeQ: res.data?.codeQ });
                  setTimeout(() => {
                    setCheckAgain(false)
                    setDataCode({ codeQ: res.data?.codeQ })
                    setLoading(false)
                    msOK(api, "top")
                  }, 1000);
                } else {
                  msErrorInst(api, "top")
                  setLoading(false)
                }
              })
              .catch((err) => {
                console.log("err", err)
                msError(api, "top")
                setLoading(false)
              })
          } else {
            msPAYCODE(api, "top")
            setLoading(false)
          }
        } else {
          msCal(api, "top")
          setLoading(false)
        }
      } else {
        msError(api, "top")
        setLoading(false)
      }
    } else {
      msErrorInsert(api, "top")
      setLoading(false)
    }
  }

  const exportToExcel = async () => {
    if (dataTable?.length > 0) {
      var allData = []
      const workbook = new ExcelJS.Workbook();
      if (isClicked === 1) {
        allData = await DataExcelPay(allData, dataTable);
        // สร้างชีทและเพิ่มข้อมูลลงในแต่ละชีท
        const addSheet = (sheetName, dataSource, headers_colums_name, headers_thai) => {
          const worksheet = workbook.addWorksheet(sheetName);
          // กำหนดความกว้างของแต่ละคอลัมน์
          worksheet.columns = mainDataExcel
          worksheet.addRow(headers_thai);
          dataSource.forEach((row) => {
            const rowData = headers_colums_name.map((column) => row[column]);
            worksheet.addRow(rowData);
          });
        };
        // หัวข้อใน Excel
        const headers_thai = headersThai
        const headers_colums_name = headersColumsName
        // // นำหัวข้อลงในแถวแรกของ Excel
        // // เพิ่มข้อมูลลงในแต่ละชีท 
        addSheet(`รายงานรับเงินสัญญา ${dataSend.CONTNO}`, allData, headers_colums_name, headers_thai);
        // addSheet(`รายงานรับเงินสัญญา ${"3-0001310"}`, allData, headers_colums_name, headers_thai);
        // console.log("addSheet")
        // บันทึกไฟล์ Excel
        workbook.xlsx.writeBuffer().then((buffer) => {
          const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          const url = window.URL.createObjectURL(blob);
          // สร้างลิงก์สำหรับดาวน์โหลดไฟล์ Excel
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `dataPay ${dataSend.CONTNO}.xlsx`);
          // link.setAttribute('download', `dataPay ${"3-0001310"}.xlsx`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
      } else if (isClicked === 2) {
        allData = await DataExcelPay(allData, dataTableDetail);
        const addSheet = (sheetName, dataSource, headers_colums_name, headers_thai) => {
          const worksheet = workbook.addWorksheet(sheetName);
          worksheet.columns = mainDataExcelDetail
          worksheet.addRow(headers_thai);
          dataSource.forEach((row) => {
            const rowData = headers_colums_name.map((column) => row[column]);
            worksheet.addRow(rowData);
          });
        };
        const headers_thai = headersThaiDetail
        const headers_colums_name = headersColumsNameDetail
        addSheet(`รายงานรับเงินสัญญา ${dataSend.CONTNO}`, allData, headers_colums_name, headers_thai);
        workbook.xlsx.writeBuffer().then((buffer) => {
          const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `dataPayDetail ${dataSend.CONTNO}.xlsx`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
      } else {
        console.log("รับฝาก")
      }
    } else {
      console.log("ไม่มีข้อมูล")
    }
  }

  const printpay = async () => {
    if (dataSend.CONTNO !== "" && dataCode.codeQ !== "") {
      let printData = {}
      let result = {}
      setLoading(true)
      printData = { "CONTNO": dataSend.CONTNO, "TMBILL": dataCode.codeQ }
      try {
        result = await loadPrintDataPayment(printData);
        setDataRec(result)
      } catch (error) {
        console.error("Error in handleInsert: ", error);
      } finally {

      }
      if (result === null) {
        console.log("เกิดข้อผิดพลาด");
        msPrint(api, "top")
        setDataRec(null)
        setLoading(false);
      } else {
        setShowTable(true);
        setTimeout(async () => {
          setLoading(false);
          generatePDF();
        }, 1000);
      }
    } else {
      msData(api, "top")
    }
  }

  const buttonAmt = async (value) => {
    setCheckButton(value)
    setIsClicked(value)
  }

  return (
    <Card style={{ height: '100%' }}>
      <Row style={{ textAlign: 'center' }}>
        <Col span={24}>
          <Spin spinning={loading} size='large' tip="Loading...">
            <Form
              form={form}
            // onFinish={handleSubmit}
            // autoComplete="off"
            // initialValues={{ remember: true }}
            // labelCol={{
            //   span: 8,
            // }}
            // wrapperCol={{
            //   span: 16,
            // }}
            >
              <Col span={24}>
                <div className='text-center'>
                  <h2>รับเงิน test</h2>
                </div>
                <div className='text-right'>
                  <Space>
                    <Button onClick={printpay} type="primary" icon={<PrinterOutlined style={{ fontSize: '16px' }} />}
                      style={{ backgroundColor: '#5ceb97', borderColor: '#5ceb97' }}
                    >
                      พิมพ์ใบเสร็จ</Button>
                    {/* <Button onClick={exportToExcel}>Export to Excel</Button> */}
                    <Button disabled={!dataAll} type='primary' onClick={showConfirm} >บันทึกข้อมูล</Button>
                  </Space>
                </div>
              </Col>
              {/* <Col span={24} style={{ textAlign: 'right' }}>
              </Col> */}
              <Divider />
              <Row gutter={32} justify={'center'}>
                <Col span={8}>
                  <Card title="รายละเอียดสัญญา" bordered={false}>
                    {/* <Space> */}
                    <Form.Item label="วันที่ชำระ" name="date" style={{ height: '30px', width: '100%' }}>
                      <DatePicker format={'YYYY-MM-DD'}
                        defaultValue={currentDateTime}
                        style={{ height: '40px', width: '100%' }}
                        onChange={(e) => {
                          if (e) {
                            setDataSend({ ...dataSend, date: e.format("YYYY-MM-DDTHH:mm:ssZ") })
                          }
                        }} />
                    </Form.Item>
                    {/* <Form.Item label="" style={{ height: '30px', width: '100%' }}></Form.Item> */}
                    {/* </Space> */}
                    {/* <Space> */}
                    <Form.Item label="เลขสัญญา" name="CONTNO" style={{ height: '30px', width: '100%' }}>
                      <Input onChange={(e) => setDataSend({ ...dataSend, CONTNO: e.target.value })}></Input>
                    </Form.Item>
                    {/* </Space> */}
                    <Space>
                      <Form.Item label="" style={{ height: '30px', width: '100%', margin: '10px' }}>
                        {/* <Button disabled={loading} type='primary' htmlType="submit" >ค้นหา</Button> */}
                        <Button disabled={loading} type='primary' onClick={handleSubmit} >ค้นหา</Button>
                      </Form.Item>
                      <Form.Item label="" style={{ height: '30px', width: '100%', margin: '10px' }}>
                        <Button disabled={loading} onClick={showConfirmClear} >Clear</Button>
                      </Form.Item>
                    </Space>
                    <Divider />
                    {
                      calData?.ccb > 0 || dataPay?.ccb > 0 ?
                        <>
                          <Card style={{ borderColor: '#000000', margin: 0 }}>
                            <Row gutter={32} justify={"center"} align="middle">
                              <Col span={16}>
                                <Form.Item label="ยอดปิด" style={{ margin: 0 }}>
                                  <b style={{ fontSize: '18px', color: 'red' }}>{currencyFormatOne(dataPay?.ccb ? dataPay?.ccb : calData?.ccb)}</b>
                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}> บาท</span>
                                </Form.Item>
                                <Form.Item label="ต้นคงเหลือ" style={{ margin: 0 }}>
                                  <b style={{ fontSize: '14px', color: 'red' }}>{currencyFormatOne(dataPay?.mixTon)}</b>
                                  <span style={{ display: 'inline-block', marginLeft: '10px' }}> บาท</span>
                                </Form.Item>
                                <Form.Item label="ดอกเบี้ย" style={{ margin: 0 }}>
                                  <b style={{ fontSize: '14px', color: 'red' }}>{currencyFormatOne(dataPay?.mixDok)}</b>
                                  <span style={{ display: 'inline-block', marginLeft: '10px' }}> บาท</span>
                                </Form.Item>
                                <Form.Item label="ค่าทวงถาม" style={{ margin: 0 }}>
                                  <b style={{ fontSize: '14px', color: 'red' }}>{currencyFormatOne(dataPay?.followCCB)}</b>
                                  <span style={{ display: 'inline-block', marginLeft: '10px' }}> บาท</span>
                                </Form.Item>
                              </Col>
                            </Row>
                          </Card>
                          <Divider />
                          <Row gutter={32} justify={"center"}>
                            <Col>
                              <Space>
                                <Form.Item label="เกรด" style={{ marginRight: 10 }}>
                                  <b style={{ fontSize: '18px', color: 'red' }}>{sum?.GRDCOD}</b>
                                </Form.Item>
                                <Form.Item label="ค่างวด" style={{ marginLeft: 10 }}>
                                  <b style={{ fontSize: '18px', color: 'red' }}>{currencyFormatOne(sum?.amt)}</b>
                                  <span style={{ display: 'inline-block', marginLeft: '10px' }}> บาท</span>
                                </Form.Item>
                              </Space>
                              {
                                sum?.payGRADE > 0 ?
                                  <>
                                    <Form.Item label="ชำระขั้นต่ำ" style={{ margin: 0 }}>
                                      <b style={{ fontSize: '18px', color: 'red' }}>{currencyFormatOne(sum?.payGRADE)}</b>
                                      <span style={{ display: 'inline-block', marginLeft: '10px' }}> บาท</span>
                                    </Form.Item>
                                  </> : null
                              }
                            </Col>
                          </Row>
                        </>
                        : dataPay?.ccb === 0 ?
                          <b><div style={{ margin: 0, fontSize: '20px' }}>** สัญญาน่าจะปิดแล้ว  **</div></b>
                          : null
                    }
                    <Divider />
                    <Row gutter={32} justify={"center"}>
                      <Col span={16}>
                        <Form.Item label="ค้างค่างวด" style={{ margin: 0 }}>
                          <b style={{ fontSize: '14px', }}>{currencyFormatOne(sum?.sumAmt)}</b>
                          <span style={{ display: 'inline-block', marginLeft: '10px' }}> บาท</span>
                        </Form.Item>
                        <Form.Item label="ค้างกี่งวด" style={{ margin: 0 }}>
                          <b style={{ fontSize: '14px', }}>{currencyFormat(sum?.nopay)}</b>
                          <span style={{ display: 'inline-block', marginLeft: '10px' }}> งวด</span>
                        </Form.Item>
                        <Form.Item label="ค้างค่าทวงถาม" style={{ margin: 0 }}>
                          <b style={{ fontSize: '14px', }}>{currencyFormatOne(sum?.sumFollow)}</b>
                          <span style={{ display: 'inline-block', marginLeft: '10px' }}> บาท</span>
                        </Form.Item>
                        <Form.Item label="ลูกหนี้อื่นๆ" style={{ margin: 0 }}>
                          <b style={{ fontSize: '14px', }}>{currencyFormatOne(sum?.AROTHR)}</b>
                          <span style={{ display: 'inline-block', marginLeft: '10px' }}> บาท</span>
                        </Form.Item>
                        <Form.Item label="รวมยอดค้าง" style={{ margin: 0 }}>
                          <b style={{ fontSize: '14px', }}>{currencyFormatOne(sum?.sumAll)}</b>
                          <span style={{ display: 'inline-block', marginLeft: '10px' }}> บาท</span>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col span={8}>
                  <Card title="รับเงิน" bordered={false}>
                    <Checkbox
                      style={{ fontSize: '16px' }}
                      checked={checkGuas === "จ่ายเข้าตารางดิว"}
                      // isPacked={true}
                      onChange={() => onChangecheckGuas("จ่ายเข้าตารางดิว")}
                    >จ่ายเข้าตารางดิว
                    </Checkbox>
                    <Checkbox
                      style={{ fontSize: '16px' }}
                      checked={checkGuas === "จ่ายแบบไม่เข้าตารางดิว"}
                      // isPacked={true}
                      // disabled={checkDue}
                      disabled
                      onChange={() => onChangecheckGuas("จ่ายแบบไม่เข้าตารางดิว")}
                    >จ่ายแบบไม่เข้าตารางดิว
                    </Checkbox>
                    <Divider />
                    {/* {
                      checkGuas !== "" && dataTable?.length > 0 ?
                        <> */}
                    <Form.Item label="เลือก" name="selectPay">
                      <Select
                        style={{ width: '100%', height: '40px' }}
                        defaultValue={dataSend.selectPay}
                        onChange={changeSelectPay}
                        options={optionsPayType}
                      />
                    </Form.Item>
                    <Form.Item label="ประเภท" name="PAYCODE">
                      <Select
                        showSearch
                        style={{ width: '100%', height: '40px' }}
                        onChange={changePAYCODE}
                        optionFilterProp="children"
                        placeholder="Search to Select"
                        filterOption={(inputValue, option) =>
                          option.children.join('').toLowerCase().includes(inputValue.toLowerCase())
                        }
                      >
                        {getPAYCODE?.map((e) => {
                          return (
                            <Select.Option key={e.keyId} value={e.PAYCODE}>
                              {e.PAYCODE} ({e.PAYDESC})
                              {/* ({e.PAYDESC}) */}
                            </Select.Option>
                          )
                        })}
                      </Select>
                    </Form.Item>
                    <Form.Item label="จำนวนเงิน" name="money">
                      <InputNumber
                        addonAfter="บาท"
                        disabled={checkCt}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        size="large"
                        placeholder="กรอกจำนวนเงิน"
                        style={{ width: '100%', color: 'black' }}
                        onChange={(value) => funcMoney(value)}
                      />
                    </Form.Item>
                    <Form.Item label="ส่วนลด" name="sale">
                      <InputNumber
                        addonAfter="บาท"
                        disabled={checkCt}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        size="large"
                        placeholder="กรอกจำนวนเงิน"
                        style={{ width: '100%', color: 'black' }}
                        onChange={(value) => funcSale(value)}
                      />
                    </Form.Item>
                    <Form.Item label="ส่วนลดค่าทวงถาม" name="saleFollow">
                      <InputNumber
                        addonAfter="บาท"
                        disabled={checkCt}
                        // disabled
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        size="large"
                        placeholder="กรอกจำนวนเงิน"
                        style={{ width: '100%', color: 'black' }}
                        onChange={(value) => funcSaleFollow(value)}
                      />
                    </Form.Item>
                    <Form.Item label="">
                      <Button disabled={loading || !checkAgain} type='primary' onClick={callHandleSubmitPay} >คำนวณ</Button>
                    </Form.Item>
                    {/* </>
                        : null
                    } */}
                  </Card>
                </Col>
                <Col span={8}>
                  <Card title="รายละเอียดที่คำนวณได้" bordered={false}>
                    <Col span={24}>
                      <Form.Item label="เลขที่บิล" name="codeQ" style={{ margin: 5 }}>
                        <Input
                          variant="borderless"
                          disabled
                          size="large"
                          value={dataCode.codeQ}
                          style={{ width: '100%', color: 'black' }}
                        />
                      </Form.Item>
                    </Col>
                    <Divider />
                    <Row gutter={32} justify={"center"}>
                      <Col span={16}>
                        <Form.Item label="รับเงิน" name="money" style={{ margin: 0 }}>
                          <b>{currencyFormatOne(dataSend?.money)}</b>
                          <span style={{ display: 'inline-block', marginLeft: '10px' }}> บาท</span>
                        </Form.Item>
                        {
                          sum.HDPAY > 0 ?
                            <Form.Item label="เข้ารับฝาก" name="HDPAY" style={{ margin: 0 }}>
                              <b>{currencyFormatOne(sum.HDPAY)}</b>
                              <span style={{ display: 'inline-block', marginLeft: '10px' }}> บาท</span>
                            </Form.Item>
                            : null
                        }
                      </Col>
                    </Row>
                    <Divider />
                    <Row gutter={32} justify={"center"}>
                      {
                        calData.text === "" ?
                          <Col span={16}>
                            {
                              sum?.sumMoney > 0 && sum?.sumMoney === dataPay?.ccb && dataAll !== null ?
                                <Form.Item label="ข้อความ" style={{ margin: 0 }}>
                                  <Tag color="geekblue">{"ปิด บช."}</Tag>
                                </Form.Item>
                                : null
                            }
                            <Form.Item label="ชำระค่างวด" style={{ margin: 0 }}>
                              <b>{currencyFormatOne(calData.ALLPAYAMT)}</b>
                              <span style={{ display: 'inline-block', marginLeft: '10px' }}> บาท</span>
                            </Form.Item>
                            <Form.Item label="ชำระดอกเบี้ย" style={{ margin: 0 }}>
                              <b>{currencyFormatOne(calData.ALLPAYINTEFF)}</b>
                              <span style={{ display: 'inline-block', marginLeft: '10px' }}> บาท</span>
                            </Form.Item>
                            <Form.Item label="ชำระเงินต้น" style={{ margin: 0 }}>
                              <b>{currencyFormatOne(calData.ALLPAYTON)}</b>
                              <span style={{ display: 'inline-block', marginLeft: '10px' }}> บาท</span>
                            </Form.Item>
                            <Form.Item label="ชำระค่าทวงถาม" style={{ margin: 0 }}>
                              <b>{currencyFormatOne(calData.ALLPAYFOLLOW)}</b>
                              <span style={{ display: 'inline-block', marginLeft: '10px' }}> บาท</span>
                            </Form.Item>
                            {
                              calData?.Dok > 0 ?
                                <Form.Item label="ดอกเบี้ยรายวัน" style={{ margin: 0 }}>
                                  <b>{currencyFormatOne(calData.Dok)}</b>
                                  <span style={{ display: 'inline-block', marginLeft: '10px' }}> บาท</span>
                                </Form.Item>
                                : null
                            }
                            {
                              calData?.PaySm > 0 ?
                                <Form.Item label="ตัดเข้าเงินต้นคงเหลือ" style={{ margin: 0 }}>
                                  <b>{currencyFormatOne(calData.PaySm)}</b>
                                  <span style={{ display: 'inline-block', marginLeft: '10px' }}> บาท</span>
                                </Form.Item>
                                : null
                            }
                            {
                              dataSend?.sale > 0 ?
                                <Form.Item label="ส่วนลด" name="sale" style={{ margin: 0 }}>
                                  <b>{currencyFormatOne(dataSend?.sale)}</b>
                                  <span style={{ display: 'inline-block', marginLeft: '10px' }}> บาท</span>
                                </Form.Item>
                                : null
                            }
                            {
                              dataSend?.saleFollow > 0 ?
                                <Form.Item label="ส่วนลดค่าทวงถาม" name="saleFollow" style={{ margin: 0 }}>
                                  <b>{currencyFormatOne(dataSend?.saleFollow)}</b>
                                  <span style={{ display: 'inline-block', marginLeft: '10px' }}> บาท</span>
                                </Form.Item>
                                : null
                            }
                            <Form.Item label="รวมยอดชำระ" style={{ margin: 0 }}>
                              <b>{currencyFormatOne(calData.ALLCAL)}</b>
                              <span style={{ display: 'inline-block', marginLeft: '10px' }}> บาท</span>
                            </Form.Item>
                          </Col>
                          : calData.text === "เงินที่จ่ายเข้ามาเกินเงินต้นคงเหลือ" || calData.text === "จำนวนเงินที่จ่ายเข้ามา มากกว่าเงินต้นคงเหลือ" ?
                            <Col span={16}>
                              <Form.Item label="Note" style={{ margin: 0 }}>
                                <Tag color="geekblue">{calData.text}</Tag>
                              </Form.Item>
                              <Form.Item label="ยอดที่รับได้ไม่เกิน" style={{ margin: 0 }}>
                                <b>{currencyFormatOne(dataPay?.ccb)}</b>
                                <span style={{ display: 'inline-block', marginLeft: '10px' }}> บาท</span>
                              </Form.Item>
                            </Col>
                            : calData.text === "ไม่ตัดเข้าตารางดิว" ?
                              <Col span={16}>
                                <Form.Item label="Note" style={{ margin: 0 }}>
                                  <Tag color="geekblue">{calData.text}</Tag>
                                </Form.Item>
                                <Form.Item label="ชำระค่างวด" style={{ margin: 0 }}>
                                  <b>{currencyFormatOne(calData.ALLPAYAMT)}</b>
                                  <span style={{ display: 'inline-block', marginLeft: '10px' }}> บาท</span>
                                </Form.Item>
                                <Form.Item label="ชำระดอกเบี้ย" style={{ margin: 0 }}>
                                  <b>{currencyFormatOne(calData.ALLPAYINTEFF)}</b>
                                  <span style={{ display: 'inline-block', marginLeft: '10px' }}> บาท</span>
                                </Form.Item>
                                <Form.Item label="ชำระเงินต้น" style={{ margin: 0 }}>
                                  <b>{currencyFormatOne(calData.ALLPAYTON)}</b>
                                  <span style={{ display: 'inline-block', marginLeft: '10px' }}> บาท</span>
                                </Form.Item>
                                <Form.Item label="ชำระค่าทวงถาม" style={{ margin: 0 }}>
                                  <b>{currencyFormatOne(calData.ALLPAYFOLLOW)}</b>
                                  <span style={{ display: 'inline-block', marginLeft: '10px' }}> บาท</span>
                                  <span style={{ marginLeft: '10px' }}></span>
                                </Form.Item>
                                <Form.Item label="รวมยอดชำระ" style={{ margin: 0 }}>
                                  <b>{currencyFormatOne(calData.ALLCAL)}</b>
                                  <span style={{ display: 'inline-block', marginLeft: '10px' }}> บาท</span>
                                </Form.Item>
                              </Col>
                              : <Col span={12}>
                                <Form.Item label="Note" style={{ margin: 0 }}>
                                  <Tag color="geekblue">{calData.text}</Tag>
                                </Form.Item>
                              </Col>
                      }
                    </Row>
                  </Card>
                </Col>
              </Row>
              <Divider />
              <Col span={24}>
                <div className='text-right'>
                  <Space>
                    <Button onClick={() => buttonAmt(1)} style={{ background: isClicked === 1 ? '#00FF00' : 'white' }}>ตารางค่างวด</Button>
                    <Button disabled={checkButtonPay} onClick={() => buttonAmt(2)} style={{ background: isClicked === 2 ? '#00FF00' : 'white' }}>ตารางรายละเอียดการจ่าย</Button>
                    <Button onClick={() => buttonAmt(3)} style={{ background: isClicked === 3 ? '#00FF00' : 'white' }}>ตารางรับฝาก</Button>
                    <Button onClick={() => buttonAmt(4)} style={{ background: isClicked === 4 ? '#00FF00' : 'white' }}>ตารางลูกหนี้อื่นๆ</Button>
                  </Space>
                </div>
              </Col>
              {
                checkButton === 1 ?
                  <Table
                    rowKey={(record) => record.uid}
                    dataSource={dataTable}
                    columns={columns}
                    scroll={{
                      x: 1500,
                      y: 400,
                    }}
                    style={{ padding: 20 }}
                  >
                  </Table>
                  : checkButton === 2 ?
                    <Table
                      rowKey={(record) => record.uid}
                      dataSource={dataTableDetail}
                      columns={columnsDetail}
                      scroll={{
                        x: 1500,
                        y: 400,
                      }}
                      style={{ padding: 20 }}
                    >
                    </Table>
                    : checkButton === 3 ?
                      <Table
                        rowKey={(record) => record.uid}
                        dataSource={dataHDPAYMENT}
                        columns={columnsHDPAYMENT}
                        scroll={{
                          x: 1500,
                          y: 400,
                        }}
                        style={{ padding: 20 }}
                      >
                      </Table>
                      : checkButton === 4 ?
                        <Table
                          rowKey={(record) => record.uid}
                          dataSource={dataAROTHR}
                          columns={columnsAROTHR}
                          scroll={{
                            x: 1500,
                            y: 400,
                          }}
                          style={{ padding: 20 }}
                        >
                        </Table>
                        : <><Divider />*** ไม่พบข้อมูล ***<Divider /></>
              }
            </Form >
          </Spin>
        </Col>
      </Row >
      {contextHolder}
      {showTable &&
        <div className="print-only" ref={componentPDF} style={{ textAlign: 'center' }}>
          <Payment
            dataPrint={dataRec}
          />
        </div>
      }
    </Card >
  )
}
