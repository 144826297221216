import React, { useState, useEffect } from 'react';
import './Index.css';
import { Modal, Row, Col, Form, Button, Space, Input, Table, Checkbox, message, notification } from "antd";
import axios from 'axios';
import {
    HomeOutlined as HomeOutlinedIcon,
    UserAddOutlined as UserAddOutlinedIcon,
    CarOutlined as CarOutlinedIcon,
    DollarOutlined as DollarOutlinedIcon,
    FrownOutlined as FrownOutlinedIcon,
    IdcardOutlined as IdcardOutlinedIcon,
    SolutionOutlined as SolutionOutlinedIcon,
    SaveOutlined as SaveOutlinedIcon,
    FileDoneOutlined as FileDoneOutlinedIcon,
    ExceptionOutlined as ExceptionOutlinedIcon,
    CheckOutlined as CheckOutlinedIcon,
    CarOutlined,
    FormOutlined,
    PhoneOutlined,
    UserOutlined
} from "@ant-design/icons";
// import { log, select } from 'async';
// import logo from './../../../components/location_3396770.png'

const ModalEditRole = ({ open, close, record }) => {
    const [roleName, setRoleName] = useState('');
    const [formData, setFormData] = useState();
    const [description, setDescription] = useState('');
    const [roleId, setRoleId] = useState('');
    const [dataList, setDataList] = useState([])
    const [form] = Form.useForm();
    const [api, contextHolder] = notification.useNotification();
    const [data, setData] = useState([
        {
            key: 1,
            name: 'หน้าแรก',
            selected: true,
            read: true,
            write: false,
            delete: false,
            pageName: "home",
            path: "/",
            icon: <HomeOutlinedIcon />,
            label: "หน้าแรก V 3.4",
        },
        {
            key: 2,
            name: 'เสนอเคส',
            selected: false,
            read: false,
            write: false,
            delete: false,
            pageName: "offercase",
            path: "/offercase",
            icon: <ExceptionOutlinedIcon />,
            label: "เสนอเคส",
        },
        {
            key: 3,
            name: 'ตรวจสอบข้อมูล',
            selected: false,
            read: false,
            write: false,
            delete: false,
            pageName: "checkinfo",
            path: null,
            icon: <FormOutlined />,
            label: "ตรวจสอบข้อมูล",
            children: [
                {
                    key: 31, name: 'รถ', icon: <CarOutlined />,
                    pageName: "checkInfoCar",
                    path: "/checkInfoCar",
                    label: "รถ",
                    read: false, write: false, delete: false
                },
                {
                    key: 32, name: 'พรี-ที่ดิน',
                    // icon: <img src={logo} width={'15px'} lt="" />,
                    pageName: "checkInfoLand",
                    path: "/checkInfoLand",
                    label: "พรี-ที่ดิน",
                    read: false, write: false, delete: false
                },
                {
                    key: 33, name: 'ขอยอดที่ดิน',
                    // icon: <img src={logo} width={'15px'} lt="" />,
                    pageName: "preLandPn",
                    path: "/preLandPn",
                    label: "ขอยอดที่ดิน",
                    read: false, write: false, delete: false
                },
                {
                    key: 34, name: 'สัญญาทั้งหมดของคุณ',
                    icon: <SolutionOutlinedIcon />,
                    pageName: "case-re",
                    path: "/case-re",
                    label: "สัญญาทั้งหมดของคุณ",
                    read: false, write: false, delete: false
                },
                {
                    key: 35, name: 'ขอยอดเคสรี(รถ)',
                    icon: <ExceptionOutlinedIcon />,
                    pageName: "offercase-re",
                    path: "/offercase-re",
                    label: "ขอยอดเคสรี(รถ)",
                    read: false, write: false, delete: false
                },
                {
                    key: 36, name: 'ขอยอดเคสรี(ที่ดิน)',
                    icon: <ExceptionOutlinedIcon />,
                    pageName: "offercaseland-re",
                    path: "/offercaseland-re",
                    label: "ขอยอดเคสรี(ที่ดิน)", read: false, write: false, delete: false
                },
            ],
        },
        {
            key: 4,
            name: 'ตรวจสอบข้อมูลธุรการ',
            selected: false,
            read: false,
            write: false,
            delete: false,
            pageName: "checkAd",
            path: null,
            icon: <FormOutlined />,
            label: "ตรวจสอบข้อมูลธุรการ",
            children: [
                {
                    key: 41, name: 'ตรววจสอบข้อมูล(ธุรการ)',
                    icon: <ExceptionOutlinedIcon />,
                    pageName: "checkInfoAd",
                    path: "/checkInfoAd",
                    label: "ตรวจสอบข้อมูล(ธุรการ)",
                    read: false, write: false, delete: false
                },
                {
                    key: 42, name: 'แบบฟอร์มชุดจดที่ดิน',
                    icon: <ExceptionOutlinedIcon />,
                    pageName: "formLandAd",
                    path: "/formLandAd",
                    label: "แบบฟอร์มชุดจดที่ดิน",
                    read: false, write: false, delete: false
                },
                {
                    key: 43, name: "แบบฟอร์มชุดไถ่ถอนที่ดิน",
                    icon: <ExceptionOutlinedIcon />,
                    pageName: "formLandRedemption",
                    path: "/formLandRedemption ",
                    label: "แบบฟอร์มชุดไถ่ถอนที่ดิน",
                    read: false, write: false, delete: false
                },
            ],
        },
        {
            key: 5,
            name: 'ตรวจสอบข้อมูล',
            selected: false,
            read: false,
            write: false,
            delete: false,
            pageName: "approvalPG",
            path: null,
            icon: <FormOutlined />,
            label: "ตรวจสอบข้อมูล",
            children: [
                {
                    key: 51, name: 'การอนุมัติ',
                    icon: <FormOutlined />,
                    pageName: "checkInfoPG",
                    path: "/checkInfoPG",
                    label: "การอนุมัติ",
                    read: false, write: false, delete: false
                },
                {
                    key: 52, name: 'การอนุมัติเคสรี',
                    icon: <FormOutlined />,
                    pageName: "checkinfopgRE",
                    path: "/checkinfopgRE",
                    label: "การอนุมัติเคสรี",
                    read: false, write: false, delete: false
                },
                {
                    key: 53, name: 'กำหนดร๊/ปรับ/เปลี่ยน',
                    icon: <FormOutlined />,
                    pageName: "checkInfoPGRECont",
                    path: "/checkInfoPGRECont",
                    label: "กำหนดรี/ปรับ/เปลี่ยน",
                    read: false, write: false, delete: false
                },
                {
                    key: 54, name: 'สัญญาการตลาดที่ออก',
                    icon: <ExceptionOutlinedIcon />,
                    pageName: "checkInfoPGMKLeaving",
                    path: "/checkInfoPGMKLeaving",
                    label: "สัญญาการตลาดที่ออก",
                    read: false, write: false, delete: false
                },
                {
                    key: 55, name: 'เปลี่ยนการตลาด',
                    icon: <ExceptionOutlinedIcon />,
                    pageName: "checkInfoPGMKChange",
                    path: "/checkInfoPGMKChange",
                    label: "เปลี่ยนการตลาด",
                    read: false, write: false, delete: false
                },
                {
                    key: 56, name: 'โยกงาน',
                    icon: <FormOutlined />,
                    pageName: "approvalRockingPG",
                    path: "/approvalRockingPG",
                    label: "โยกงาน",
                    read: false, write: false, delete: false
                },
                {
                    key: 57, name: 'หยุดรับงาน',
                    icon: <FormOutlined />,
                    pageName: "approvalStopWorkPG",
                    path: "/approvalStopWorkPG",
                    label: "หยุดรับงาน",
                    read: false, write: false, delete: false
                },
                {
                    key: 58, name: 'รายงาน',
                    icon: <FormOutlined />,
                    pageName: "reportWorkPG",
                    path: "/reportWorkPG",
                    label: "รายงาน",
                    read: false, write: false, delete: false
                },
            ],
        },
        {
            key: 6,
            name: 'ตรวจสอบข้อมูลพี่หนุ่ม',
            selected: false,
            read: false,
            write: false,
            delete: false,
            pageName: "detailPN",
            path: null,
            icon: <FormOutlined />,
            label: "ตรวจสอบข้อมูลพื่หนุ่ม",
            children: [
                {
                    key: 61, name: 'ประะเมินที่ดิน',
                    // icon: <img src={logo} width={'15px'} lt="" />,
                    pageName: "checkInfoPN",
                    path: "/checkInfoPN",
                    label: "ประเมินที่ดิน",
                    read: false, write: false, delete: false
                },
                {
                    key: 62, name: 'ประเมินที่ดิน(เคสรี)',
                    // icon: <img src={logo} width={'15px'} lt="" />,
                    pageName: "checkInfoRePN",
                    path: "/checkInfoRePN",
                    label: "ประเมินที่ดิน(เคสรี)",
                    read: false, write: false, delete: false
                },
                {
                    key: 63, name: 'รายงาน',
                    icon: <FormOutlined />,
                    pageName: "report-pn",
                    path: "/report-pn",
                    label: "รายงาน",
                    read: false, write: false, delete: false
                },
                {
                    key: 64, name: 'รายงาน2',
                    icon: <FormOutlined />,
                    pageName: "report-pn2",
                    path: "/report-pn2",
                    label: "รายงาน2",
                    read: false, write: false, delete: false
                },
                {
                    key: 65, name: 'รายงาน3',
                    icon: <FormOutlined />,
                    pageName: "report-pn3",
                    path: "/report-pn3",
                    label: "รายงาน3",
                    read: false, write: false, delete: false
                },
                {
                    key: 66, name: 'รายงาน4',
                    icon: <FormOutlined />,
                    pageName: "report-pn4",
                    path: "/report-pn4",
                    label: "รายงาน4",
                    read: false, write: false, delete: false
                },

            ],
        },
        {
            key: 7,
            name: 'ตรวจสอบข้อมูลผู้จัดการ',
            selected: false,
            read: false,
            write: false,
            delete: false,
            pageName: "detailManager",
            path: null,
            icon: <FormOutlined />,
            label: "ตรวจสอบข้อมูลผู้จัดการ",
            children: [
                {
                    key: 71, name: 'ตารางตรวจสอบข้อมูล',
                    icon: <FormOutlined />,
                    pageName: "checkInfoManager",
                    path: "/checkInfoManager",
                    label: "ตารางตรวจสอบข้อมูล",
                    read: false, write: false, delete: false
                },
                {
                    key: 72, name: 'รายงาน',
                    icon: <FormOutlined />,
                    pageName: "checkInfoManager",
                    path: "/checkInfoManager",
                    label: "ตารางตรวจสอบข้อมูล",
                    read: false, write: false, delete: false
                },
                {
                    key: 73, name: 'รายงาน2',
                    icon: <FormOutlined />,
                    pageName: "report-mg2",
                    path: "/report-mg2",
                    label: "รายงาน2", read: false, write: false, delete: false
                },

                {
                    key: 74, name: 'รายงาน3',
                    icon: <FormOutlined />,
                    pageName: "report-mg3",
                    path: "/report-mg3",
                    label: "รายงาน3",
                    read: false, write: false, delete: false
                },
                {
                    key: 75, name: 'รายงาน4',
                    icon: <FormOutlined />,
                    pageName: "report-mg4",
                    path: "/report-mg4",
                    label: "รายงาน4",
                    read: false, write: false, delete: false
                },
            ],
        },
        {
            key: 8,
            name: 'ตรวจสอบข้อมูลบอส',
            selected: false,
            read: false,
            write: false,
            delete: false,
            pageName: "checkInfoBoss",
            path: "/checkInfoBoss",
            icon: <FormOutlined />,
            label: "ตรวจสอบข้อมูลบอส",
            children: [
                {
                    key: 81, name: 'ตารางตรวจสอบข้อมูล',
                    icon: <FormOutlined />,
                    pageName: "checkInfoBoss",
                    path: "/checkInfoBoss",
                    label: "ตารางตรวจสอบข้อมูล",
                    read: false, write: false, delete: false
                },
                {
                    key: 82, name: 'รายงาน',
                    icon: <FormOutlined />,
                    pageName: "checkInfoBossReport",
                    path: "/checkInfoBossReport",
                    label: "รายงาน",
                    read: false, write: false, delete: false
                },
            ]
        },
        {
            key: 9,
            name: 'บันทึกประวัติลูกค้า',
            selected: false,
            read: false,
            write: false,
            delete: false,
            pageName: "addcustomer",
            path: "/addcustomer",
            icon: <UserAddOutlinedIcon />,
            label: "บันทึกประวัติลูกค้า",

        },
        {
            key: 10,
            name: 'บันทึกสืบทรัพย์สิน',
            selected: false,
            read: false,
            write: false,
            delete: false,
            pageName: "addproperty",
            path: "/addproperty",
            icon: <CarOutlinedIcon />,
            label: "บันทึกสืบทรัพย์สิน",
        },
        {
            key: 11,
            name: 'บันทึกสัญญากู้เงิน',
            selected: false,
            read: false,
            write: false,
            delete: false,
            pageName: "addloan",
            path: "/addloan",
            icon: <DollarOutlinedIcon />,
            label: "บันทึกสัญญาเงินกู้",
        },
        {
            key: 12,
            name: 'สอบถามข้อมูล blacklist',
            selected: false,
            read: false,
            write: false,
            delete: false,
            pageName: "blacklist",
            path: "/blacklist",
            icon: <FrownOutlinedIcon />,
            label: "สอบถามข้อมูล Blacklist",
        },
        {
            key: 13,
            name: 'รับเงินเข้างวด ออโต้ test',
            selected: false,
            read: false,
            write: false,
            delete: false,
            pageName: "customer-history",
            path: "/customer-history",
            icon: <IdcardOutlinedIcon />,
            label: "รับเงินเข้างวด ออโต้ test",
        },
        {
            key: 14,
            name: 'รับเงิน test',
            selected: false,
            read: false,
            write: false,
            delete: false,
            pageName: "loan-history",
            path: "/loan-history",
            icon: <SolutionOutlinedIcon />,
            label: "รับเงิน test",
        },
        {
            key: 15,
            name: 'รับเงิน (ยกเลิก)',
            selected: false,
            read: false,
            write: false,
            delete: false,
            pageName: "payment-daily",
            path: "/payment-daily",
            icon: <SaveOutlinedIcon />,
            label: "รับเงิน (ยกเลิก)",
        },
        {
            key: 16,
            name: 'พิมพ์ใบเสร็จ test',
            selected: false,
            read: false,
            write: false,
            delete: false,
            pageName: "receipt",
            path: "/receipt",
            icon: <FileDoneOutlinedIcon />,
            label: "พิมพ์ใบเสร็จ test",
        },
        {
            key: 20,
            name: 'memo',
            selected: false,
            read: false,
            write: false,
            delete: false,
            pageName: "comment",
            path: "/comment",
            icon: <ExceptionOutlinedIcon />,
            label: "memo",
        },
        {
            key: 21,
            name: 'ตรวจสอบเอกสาร',
            selected: false,
            read: false,
            write: false,
            delete: false,
            pageName: "checkDocuments",
            path: "/checkDocuments",
            icon: <FormOutlined />,
            label: "ตรวจสอบเอกสาร",
        },
        {
            key: 22,
            name: 'อนุมัติเรทรถ',
            selected: false,
            read: false,
            write: false,
            delete: false,
            pageName: "apRateCar",
            path: "/apRateCar",
            icon: <CarOutlinedIcon />,
            label: "อนุมัติเรทรถ",
        },
        {
            key: 23,
            name: 'โอเปอร์เรเตอร์',
            selected: false,
            read: false,
            write: false,
            delete: false,
            pageName: "operators",
            path: "/operators",
            icon: <PhoneOutlined />,
            label: "โอเปอร์เรเตอร์",
        },
        {
            key: 24,
            name: 'HR',
            selected: false,
            read: false,
            write: false,
            delete: false,
            pageName: "managers",
            path: "/managers",
            icon: <UserOutlined />,
            label: "HR",
            children: [
                {
                    key: 241, name: 'รายชื่อพนักงาน',
                    icon: <UserAddOutlinedIcon />,
                    pageName: "listofemployees",
                    path: "/listofemployees",
                    label: "รายชื่อพนักงาน",
                    read: false, write: false, delete: false
                },
                {
                    key: 242, name: 'สร้างสิทธิ์',
                    icon: <UserAddOutlinedIcon />,
                    pageName: "createRights",
                    path: "/createRights",
                    label: "สร้างสิทธิ์",
                    read: false, write: false, delete: false
                },
            ],
        },
        {
            key: 25,
            name: 'เร่งรัดหนี้สิน',
            selected: false,
            read: false,
            write: false,
            delete: false,
            pageName: "expediteTheDebt",
            path: "/expeditethedebt",
            icon: <UserOutlined />,
            label: "เร่งรัดหนี้สิน",
            children: [
                {
                    key: 251, name: 'รถ',
                    icon: <UserAddOutlinedIcon />,
                    pageName: "expeditethedebtcars",
                    path: "/expeditethedebtcars",
                    label: "รถ", read: false, write: false, delete: false
                },
                {
                    key: 252, name: 'ติดตามเคส',
                    icon: <FormOutlined />,
                    pageName: "followCase",
                    path: "/followCase",
                    label: "ติดตามเคส", read: false, write: false, delete: false
                },
                {
                    key: 253, name: 'เคสที่โดนระงับ',
                    icon: <UserAddOutlinedIcon />,
                    pageName: "suspensioncase",
                    path: "/suspensioncase",
                    label: "เคสที่โดนระงับ", read: false, write: false, delete: false
                },

            ],
        },
        {
            key: 26,
            pageName: "finance",
            name: "การาเงิน",
            selected: false,
            read: false,
            write: false,
            delete: false,
            path: null,
            icon: <FormOutlined />,
            label: "การเงิน",
            children: [
                {
                    key: 261, name: 'เช็คสัญญา',
                    icon: <ExceptionOutlinedIcon />,
                    pageName: "finance",
                    path: "/finance",
                    label: "เช็คสัญญา",
                    read: false, write: false, delete: false
                }
            ]
        },
        {
            key: 27,
            pageName: "reportResult",
            name: 'reportResult',
            selected: false,
            read: false,
            write: false,
            delete: false,
            path: null,
            icon: <FormOutlined />,
            label: "reportResult",
            children: [
                {
                    key: 271, name: 'Result',
                    icon: <ExceptionOutlinedIcon />,
                    pageName: "infoResult",
                    path: "/infoResult",
                    label: "Result",
                    read: false, write: false, delete: false
                }
            ]
        },
        {
            key: 28,
            pageName: "expediteTheDebts",
            name: 'เร่งรัด-ภาคสนาม',
            selected: false,
            read: false,
            write: false,
            delete: false,
            path: null,
            icon: <FormOutlined />,
            label: "เร่งรัด-ภาคสนาม",
            children: [
                {
                    key: 281, name: 'รถ',
                    icon: <ExceptionOutlinedIcon />,
                    pageName: "expeditethedebtcarsexdite",
                    path: "/expeditethedebtcarsexdite",
                    label: "รถ", read: false, write: false, delete: false
                },
                // {
                //     key: 282, name: 'ติดตามเคส',
                //     icon: <FormOutlined />,
                //     pageName: "followCaseexdite",
                //     path: "/followCaseexdite",
                //     label: "ติดตามเคส", read: false, write: false, delete: false
                // },
                // {
                //     key: 283, name: 'เคสที่โดนระงับ',
                //     icon: <UserAddOutlinedIcon />,
                //     pageName: "suspensioncaseexdite",
                //     path: "/suspensioncaseexdite",
                //     label: "เคสที่โดนระงับ", read: false, write: false, delete: false
                // },
            ]
        },
    ]);

    useEffect(() => {
        if (record) {
            setRoleName(record.role_name);
            setDescription(record.description);
            setRoleId(record.role_id);
            form.setFieldsValue({
                roleName: record.role_name,
                description: record.description,
                roleId: record.role_id,
            })
        }
    }, [record]);

    const permissionObject = JSON.parse(record.permission);

    const permissionArray = Object.keys(permissionObject).map(key => ({
        key: parseInt(key, 10),
        selected: true,
        ...permissionObject[key]
    }));

    const dataSource = permissionArray

    const updateDataWithDataSource = (data, dataSource) => {
        return data.map(item => {
            const dataSourceItem = dataSource.find(dsItem => dsItem.key === item.key);
            let updatedChildren = null;

            if (item.children) {
                updatedChildren = item.children.map(child => {
                    const childDataSourceItem = dataSource.find(dsItem => dsItem.key === child.key);
                    if (childDataSourceItem) {
                        return {
                            ...child,
                            ...childDataSourceItem
                        };
                    }
                    return child;
                });
            }

            if (dataSourceItem) {
                return {
                    ...item,
                    children: updatedChildren,
                    ...dataSourceItem
                };
            }
            return item;
        });
    };

    useEffect(() => {
        const resData = updateDataWithDataSource(data, dataSource);
        setDataList(resData)
        console.log('resData', resData)

    }, [])

    useEffect(() => {
        console.log("DATA LIST", dataList)
    }, [dataList])

    const openNotification = (placement) => {
        api.info({
            message: 'บันทึกสำเร็จ',
            description: 'แก้ไขสิทธิ์ผู้ใช้สำเร็จ.',
            placement,
        });
    };

    const handleCreate = async () => {
        let roleID = record.role_id;
        let maxRoleId;
        let newRoleId;

        if (Array.isArray(record)) {
            maxRoleId = Math.max(...record.map(item => item.role_id), 0);
            newRoleId = maxRoleId + 1;
        } else {
            maxRoleId = 0;
            newRoleId = 0;
        }

        const generateMenu = (items) => {

            return items.map((e) => {

                const menuItem = {
                    pageName: e.pageName,
                    key: e.key,
                    path: e.path,
                    label: e.label,
                    icon: e.icon ? e.icon.type.render.name : null,
                };
                if (e.children) {
                    menuItem.children = generateMenu(e.children);
                }
                return menuItem;

            });

        };

        const maprole = dataList.map(item => {

            const filteredChildren = item.children
                ? item.children.filter(child => child.selected)
                : [];

            return item.selected || filteredChildren.length > 0
                ? {
                    ...item,
                    key: item.key,
                    selected: item.selected,
                    read: item.read,
                    write: item.write,
                    delete: item.delete,
                    children: filteredChildren.map(child => ({
                        key: child.key,
                        selected: child.selected,
                        read: child.read,
                        write: child.write,
                        delete: child.delete,
                    })),
                }
                : null;
        }).filter(item => item !== null);

        const flattenedPermissions = maprole.reduce((acc, curr) => {
            acc[curr.key] = { read: curr.read, write: curr.write, delete: curr.delete };

            curr.children.forEach(child => {
                acc[child.key] = { read: child.read, write: child.write, delete: child.delete };
            });

            return acc;
        }, {});

        const permissionString = JSON.stringify(flattenedPermissions);

        if (record !== null) {
            let createRight = {
                role_id: roleId,
                role_name: roleName,
                description: description,
                permission: permissionString,
                menu: generateMenu(dataList.filter(item => item.selected || (item.children && item.children.some(child => child.selected)))),

            }
            // console.log('createRight', createRight)
            // console.log('permission', createRight.permission)
            await axios.put(`https://api-mongodb-i67f6gaaqa-as.a.run.app/update-role/${roleID}`, createRight)
                .then(response => {
                    // console.log('response', response.data)
                    if (response.data === 'OK') {
                        openNotification('top');
                        setTimeout(() => {
                            close(false);
                        }, 2000)
                    }
                })
                .catch(err => {
                    console.error('Error Create Right:', err);
                })

            // close(false);

        } else {
            let createRight = {
                role_id: roleId,
                role_name: roleName,
                description: description,
                permission: permissionString,
                menu: generateMenu(dataList.filter(item => item.selected || (item.children && item.children.some(child => child.selected)))),
            }
            // console.log('createData', createRight)
            await axios.put(`https://api-mongodb-i67f6gaaqa-as.a.run.app/update-role/${roleID}`, createRight)
                .then(response => {
                    // console.log('response', response.data)
                    if (response.data === 'OK') {
                        openNotification('top');
                        setTimeout(() => {
                            close(false);
                        }, 2000)
                    }
                })
                .catch(err => {
                    console.error('Error Create Right:', err);
                })

            // close(false);
            // console.log('createRight', createRight)
        }
    }

    const handleCancel = () => {
        close(false);
    }

    const handleOk = () => {
        if (!roleName.trim()) {
            message.error('กรุณาใส่ชื่อสิทธิ์');
            return;
        }
        if (!description.trim()) {
            message.error('กรุณากรอก Description')
            return;
        }
        handleCreate();
    }

    // const rowSelection = {
    //     onChange: (selectedRowKeys, selectedRows) => {
    //         // console.log("selectedRowKeys", selectedRowKeys);
    //         // console.log('selectedRows: ', selectedRows);
    //     },
    //     onSelect: async (record, selectedItem, selected) => {
    //         // console.log('selected111', items);
    //         // const updateData = await createData(data)
    //         // console.log("selectedRows", selectedRows);
    //         // console.log('selected', selected)
    //         const createData = (items) => {

    //             // const result = items.map((item) =>{
    //             //    if (item.key === record.key) {
    //             //     console.log("trap in");

    //             //     return {...items,selected: selectedItem}

    //             //    }

    //             // })
    //             // console.log("result",result);
    //             return items.map(item => {
    //                 // console.log("record---->", record);

    //                 if (item.key === record.key) {
    //                     // console.log('selected222', selectedItem)

    //                     return {
    //                         ...item,
    //                         selected: selected,
    //                         read: selected ? true : false,
    //                         write: selected ? item.write : false,
    //                         delete: selected ? item.delete : false,
    //                         children: item.children ? item.children.map(child => ({
    //                             ...child,
    //                             selected: selected,
    //                             read: selected ? true : false,
    //                             write: selected ? item.write : false,
    //                             delete: selected ? item.delete : false,

    //                         })) : null,
    //                     };
    //                 }
    //                 // console.log("item retrun--->", item);

    //                 if (item.children) {
    //                     return { ...item, children: createData(item.children) };
    //                 }

    //                 // console.log("item2222222222", item);
    //                 return item;
    //             });

    //         };
    //         // console.log('selected', selected);
    //         //  console.log('dataList2', dataList);


    //         // console.log("updateData===", updateData)
    //         // setData(updateData);
    //         //setCreateRole(updateData)

    //     },
    //     onSelectAll: (selected, selectedRows, changeRows) => {
    //         const selectAll = (items, selected) => {
    //             return items.map(item => ({
    //                 ...item,
    //                 selected: selected,
    //                 read: selected ? true : false,
    //                 write: selected ? item.write : false,
    //                 delete: selected ? item.delete : false,
    //                 children: item.children ? selectAll(item.children, selected) : null,
    //             }));
    //         };
    //         // const dataAll = selectAll(data, selected)
    //         // setData(dataAll);
    //         // setCreateRole(dataAll)
    //         // setData(selectAll(data, selected));
    //     },
    // };

    const headerCheckbox = (checked, key, type) => {

        const updateData = (items) => {
            return items.map(item => {
                if (item.key === key) {
                    return {
                        ...item,
                        selected: checked,
                        read: checked ? true : false,
                        write: checked ? item.write : false,
                        delete: checked ? item.delete : false,
                        children: item.children ? item.children.map(child => ({
                            ...child,
                            selected: checked,
                            read: checked ? true : false,
                            write: checked ? child.write : false,
                            delete: checked ? child.delete : false,
                        })) : null,
                    };
                }

                if (item.children) {
                    return {
                        ...item,
                        children: updateData(item.children)
                    };
                }
                return item;
            });
        };
        const updatedData = updateData(dataList);
        setDataList(updatedData);
        // console.log('updateData:', updatedData);
    };

    const handleChangeCheckBox = (checked, key, type) => {
        // console.log('checked', checked, 'key', key, 'type', type)
        const updateData = dataList.map(item => {
            if (item.key === key) {
                return {
                    ...item,
                    [type]: checked,
                    selected: true,
                    children: item.children ? item.children.map(child => ({
                        ...child,
                        [type]: checked,
                    })) : null,
                };
            }
            if (item.children) {
                return {
                    ...item,
                    children: item.children.map(child =>
                        child.key === key
                            ? {
                                ...child,
                                [type]: checked,
                                selected: true,
                            }
                            : child
                    )
                };
            }
            return item;
        });

        setDataList(updateData);
        // console.log('updateData:', updateData);

    };

    const columns = [
        {
            // title: 'read',
            dataIndex: 'selected',
            key: 'selected',
            width: '5%',
            render: (text, record) => (
                <Checkbox
                    // disabled={!record.selected}
                    checked={record.selected}
                    onChange={(e) => headerCheckbox(e.target.checked, record.key, 'selected')}
                />
            )
        },
        {
            title: 'ชื่อสิทธิ์',
            dataIndex: 'name',
            key: 'name',
        },
        // {
        //     title: 'key',
        //     dataIndex: 'key',
        //     key: 'key',
        // },
        {
            title: 'read',
            dataIndex: 'read',
            key: 'read',
            width: '10%',
            render: (text, record) => (
                <Checkbox
                    disabled={!record.selected}
                    checked={record.read}
                    onChange={(e) => handleChangeCheckBox(e.target.checked, record.key, 'read', record.selected)}
                />
            )
        },
        {
            title: 'write',
            dataIndex: 'write',
            key: 'write',
            width: '10%',
            render: (text, record) => (
                <Checkbox
                    disabled={!record.selected}
                    checked={record.write}
                    onChange={(e) => handleChangeCheckBox(e.target.checked, record.key, 'write', record.selected)}
                />
            )
        },
        {
            title: 'delete',
            dataIndex: 'delete',
            key: 'delete',
            width: '10%',
            render: (text, record) => (
                <Checkbox
                    disabled={!record.selected}
                    checked={record.delete}
                    onChange={(e) => handleChangeCheckBox(e.target.checked, record.key, 'delete', record.selected)}
                />
            )
        },
    ];

    return (
        <>
            {contextHolder}

            <Modal
                open={open}
                title={<div style={{ marginBottom: '30px' }}>แก้ไขสิทธิ์</div>}
                onCancel={handleCancel}
                onOk={handleOk}
                footer={null}
                width={1000}
            >
                <Row>
                    <Col span={24}>
                        <Row justify="center">
                            <Col span={24}>
                                <Form form={form} >
                                    <Row justify="center">
                                        <Col span={18}>
                                            <Form.Item
                                                label="ชื่อสิทธิ์"
                                                name="roleName"
                                                rules={[{ required: true, message: 'กรุณากรอกชื่อสิทธิ์!' }]}
                                            >
                                                <Input
                                                    placeholder="ชื่อสิทธิ์"
                                                    value={roleName}
                                                    onChange={(e) => setRoleName(e.target.value)}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                label="Description"
                                                name="description"
                                                rules={[{ required: true, message: 'กรุณากรอกคำอธิบาย!' }]}
                                            >
                                                <Input.TextArea
                                                    value={description}
                                                    onChange={(e) => setDescription(e.target.value)}
                                                    style={{
                                                        height: '100px',
                                                        width: '100%',
                                                        fontSize: '18px'
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Table
                                    columns={columns}
                                    // rowSelection={{
                                    //     ...rowSelection,
                                    //     checkStrictly,
                                    // }}
                                    dataSource={dataList}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col span={24}>
                        <Row justify="end">
                            <Space>
                                <Button
                                    key="cancel"
                                    className='button-cancel'
                                    onClick={handleCancel}
                                >
                                    ยกเลิก
                                </Button>
                                <Button
                                    key="save"
                                    className='button-save'
                                    type='submit'
                                    onClick={handleOk}
                                >
                                    บันทึก
                                </Button>
                            </Space>
                        </Row>
                    </Col>
                </Row>
            </Modal>
        </>
    )
}
export default ModalEditRole;
