import React, { useState, useEffect } from "react";
import { Button, Col, Row, Typography, Space, Card, message, Form, Divider, Modal, notification } from 'antd';
import { PlusOutlined, DeleteOutlined, EditFilled, SearchOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux';
import axios from 'axios';

// import Note from "./Note";
import ModalAddGuarantor from "./Guarantor/ModalAddGuarantor";
import ModalEditGuarantor from "./Guarantor/ModalEditGuarantor";
import ViewGuarantor from "./Guarantor/ViewGuarantor";
import { useNavigate } from "react-router-dom";
import { deleteguarantors, makegarno } from "../../file_mid/all_api";

function Guarantor({ close, changepage, getData1, page, NotiNotFond }) {
    const navigate = useNavigate();

    const { customers } = useSelector((state) => ({ ...state }))
    const { cars } = useSelector((state) => ({ ...state }))
    const [dataPost, setDataPost] = useState(customers.data); //มาจากรีดัค
    const [carId, setCar] = useState(cars.data.DATA.carLoanDetails[0].cars[0].carId); //มาจากรีดัค



    console.log('carId', carId)

    const [modalAddGuarantor, setModalAddGuarantor] = useState(false)
    const [ModalEditData, setModalEditData] = useState(false)
    const [modalView, setModalView] = useState(false)

    const [modalnote, setModalNote] = useState(false)
    const [appStatus, setAppStatus] = useState("")
    // const [btnDisabled, setBtnDisabled] = useState(true)
    const [getData, setGetData] = useState()
    const [getCarId, setGetCarId] = useState()
    const [dataedit, setDataEdit] = useState([]); // for edit
    const [index1, setIndex] = useState();
    const [disBtn, setDisBtn] = useState(true);
    const [guarantor, setGuarantor] = useState([])
    const [api, contextHolder] = notification.useNotification();

    const { Title } = Typography;
    const { confirm } = Modal

    useEffect(() => {
        // pickdata()
        loadGuarantors()
    }, [])

    const loadGuarantors = async (e) => {
        await axios.get(`https://garfe-i67f6gaaqa-as.a.run.app/api/checkgu/${dataPost}`)
            .then(res => {
                if (res.status === 200) {
                    console.log("res", res)
                    setGuarantor(res.data)
                } else if (res.status === 204) {
                    console.log("ไม่พบข้อมูล")
                }
            }).catch((err) => {
                console.log("err", err)
            })
    }

    const modalG = () => {
        setModalAddGuarantor(true)
    };

    const currencyFormatOne = (amount) => {
        return Number(amount)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, '$&,')
    }

    const handleSubmit = async (e) => {
        // setBtnDisabled(false)
        console.log("handleSubmit", guarantor)
        await axios.post(makegarno, guarantor)
            .then(res => {
                if (res.status === 200) {
                    console.log("บันทึกแล้ว", res)
                    setDisBtn(false)
                }
            })
            .catch(err => {
                console.log(err)
            }
            )
        navigate('/addcustomer/descriptions')
    }

    const NotiError = (placement) => {
        api.error({
            message: <b>พบข้อมูลซ้ำกันระหว่างผู้กู้และผู้ค้ำ!</b>,
            description:
                'โปรดเปลี่ยนผู้ค้ำประกัน',
            placement,
        });
    }

    const deleteData = async (value) => {
        console.log("TY", value)
        delete value.status
        const test2 = value
        await axios.delete(deleteguarantors, { data: test2, })
            .then((res) => {
                if (res.status === 200) {
                    message.success('ลบข้อมูลแล้ว');
                }
                else {
                    error()
                }
            }).catch((err) => console.log(err))
    }

    const showConfirm = (value, index) => {
        confirm({
            title: 'คุณต้องการที่จะลบข้อมูลหรือไม่...?',
            content: 'กด OK เพื่อยืนยันการลบ',
            onOk() {
                const data = guarantor.filter(item => item.garno !== index + 1);

                // เปลี่ยน "garno" ที่มีค่าเป็น 3 เป็น 2
                data.forEach((item, index) => {
                    item.garno = index + 1;
                });
                setGuarantor(data)
                deleteData(value)
            },
            onCancel() {
                message.error('ยกเลิกการลบข้อมูล')
            },
        });
    };
    const error = () => {
        Modal.error({
            title: 'ไม่สำเร็จ',
            content: 'กรุณาลองใหม่อีกครั้ง',
        })
    }

    // const onUnApprove = async () => {
    //     setAppStatus("ไม่ผ่านพิจารณา")
    //     setModalNote(true)
    // }
    // const onApprove = async () => {
    //     setAppStatus("ผ่านพิจารณา")
    //     setModalNote(true)
    // }

    // const handleCancel = () => {
    //     close(false)
    // }

    const fucnEdit = ({ value, getIndex }) => {
        let Theedit = [...guarantor]
        Theedit[getIndex] = value
        //ข้อมูลใหม่แทนที่ข้อมูลเดิมในช่อง Input
        setGuarantor(Theedit);
    }

    console.log("guarantor.length", guarantor.length)
    const fucnAdd = (value) => {
        if (guarantor.length === 0) {
            setGuarantor(pre => [...pre, { ...value.value, garno: 1 }]);
        } else {
            setGuarantor(pre => [...pre, { ...value.value, garno: guarantor.length + 1 }]);
        }
    }
    const onChangeBack = () => {
        navigate('/addcustomer/products')
    }
    const onChangeGo = () => {
        confirm({
            title: 'ท่านกดบันทึกแล้วใช่หรือมั้ย?',
            // content: 'กด OK เพื่อยืนยันการลบ',
            onOk() {
                navigate('/addcustomer/descriptions')
            },
            onCancel() {
                // message.error('ยกเลิกการลบข้อมูล')
            },
        });
    }
    const renderItem = ({ item, index }) => { //item คือตัวแปรที่เอาไว้ดึงค่าของข้อมูลออกมาแสดง โดยใช้ item.ชื่อฟิวล์

        const handleEdit = (dataedit, index1) => {
            setIndex(index1) //เซตค่า index ให้กับชุด ข้อมูล
            setDataEdit({ dataedit, index1 }) //ส่งข้อมูลด index ไปหน้าแก้ไข
            setModalEditData(true);
        }

        const handleView = (dataedit, index1) => {
            setIndex(index1) //เซตค่า index ให้กับชุด ข้อมูล
            setDataEdit({ dataedit, index1 }) //ส่งข้อมูลด index ไปหน้าแก้ไข
            setModalView(true);
        }

        console.log("guarantor", guarantor)

        return (
            <Row style={{ margin: '3px' }} justify={'center'} >
                <Card
                    title={
                        "ลำดับที่ " + (item.garno)
                    }
                    style={{ textAlign: "center" }}
                >
                    <Col span={24} style={{ textAlign: "left" }}>
                        <Form.Item style={{ marginBottom: 0 }} label='ชื่อ-นามสกุล'>
                            <b>{item.snam + '' + item.firstname + ' ' + item.lastname}</b>
                        </Form.Item>
                        <Form.Item style={{ marginBottom: 0 }} label='เบอร์โทรติดต่อ'>
                            <b>{item.telp}</b>
                        </Form.Item>
                        <Form.Item style={{ marginBottom: 0 }} label='ความสัมพันธ์'>
                            <b>{item.description}</b>
                        </Form.Item>
                        <Form.Item style={{ marginBottom: 0 }} label='ที่อยู่'>
                            <b>{item.address[0].houseNumber} {item.address[0].subdistrict} {item.address[0].district} {item.address[0].province} {item.address[0].zipCode}</b>
                        </Form.Item>
                        <Divider></Divider>
                        <div style={{ textAlign: "center" }} >
                            <Space>
                                <Button
                                    type='primary'
                                    style={{ background: "#52c41a" }}
                                    icon={<SearchOutlined />}
                                    onClick={() => { handleView(guarantor[index], index); }}
                                >View</Button>
                                <Button
                                    type="primary"
                                    style={{ background: "#0958d9" }}
                                    icon={<EditFilled />}
                                    onClick={() => { handleEdit(guarantor[index], index); }}
                                >Edit</Button>
                                <Button
                                    type="primary"
                                    icon={<DeleteOutlined />}
                                    style={{ background: "#f5222d" }}

                                    onClick={() => showConfirm(guarantor[index], index)}
                                // onClick={() => showConfirm(index)}
                                >Delete</Button>
                            </Space>
                        </div>
                    </Col>
                </Card>
            </Row>
        )
    }
    return (

        <Card>
            <Col span={24}>
                <Row gutter={32} justify={'center'}>
                    <Col className='gutter-row' span={24} style={{ fontSize: '25px', textAlign: 'center' }}>
                        <b>ผู้ค้ำประกัน</b>
                        <div style={{ color: "red", fontSize: '20px', }}>
                            <u>*ถ้าไม่กดบันทึกข้อมูลผู้ค้ำจะไม่ถูกบันทึก</u>
                        </div>
                    </Col>
                </Row>
                <Divider style={{ margin: '5px' }} />
                <Row gutter={32} style={{ margin: 5 }}>
                    <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
                        <Button type="primary" onClick={modalG} style={{ margin: 10 }}><PlusOutlined />เพิ่มคนค้ำประกัน</Button>
                    </Col>
                </Row>

                <Row gutter={32} justify={'center'}>
                    {
                        guarantor.map((item, index) => {
                            return renderItem({ item, index })
                        })
                    }
                </Row>
            </Col>

            <Row gutter={32} justify={'center'}>
                <Col className="gutter-row" span={24} style={{ textAlign: 'center' }}>
                    <Button type="primary" onClick={handleSubmit} style={{ margin: 10 }}>บันทึก</Button>
                </Col>
            </Row>
            <Divider style={{ margin: '5px' }} />
            <Row
                gutter={32}
                justify={'center'}
            >
                <Col className='gutter-row' span={12} style={{ textAlign: 'left' }}>
                    <Button
                        style={{ margin: '5px', background: '#bfbfbf' }}
                        htmlType='submit'
                        type="text"
                        onClick={onChangeBack}
                    // icon={<DoubleLeftOutlined />}
                    >
                        ย้อนกลับ
                    </Button>
                </Col>
                <Col className='gutter-row' span={12} style={{ textAlign: 'right' }}>
                    <Button
                        style={{ margin: '5px' }}
                        htmlType='submit'
                        type="primary"
                        onClick={onChangeGo}
                    // icon={<DoubleRightOutlined />}
                    >
                        ต่อไป
                    </Button>
                </Col>
            </Row>
            {
                modalAddGuarantor ?
                    <ModalAddGuarantor
                        open={modalAddGuarantor}
                        close={setModalAddGuarantor}
                        getData={getData}
                        fucnAdd={fucnAdd}
                        dataCusid={dataPost}
                        getCarId={getCarId}
                        sendcarId={carId}
                    />
                    : null
            }
            {
                ModalEditData ?
                    <ModalEditGuarantor
                        open={ModalEditData}
                        close={setModalEditData}
                        fucnEdit={fucnEdit}
                        dataindex={index1}
                        shootdata={dataedit}
                        getCarId={getCarId} />
                    : null
            }
            {
                modalView ?
                    <ViewGuarantor
                        open={modalView}
                        close={setModalView}
                        // fucnEdit={fucnEdit}
                        dataindex={index1}
                        shootdata={dataedit} />
                    : null
            }
            {contextHolder}
        </Card>
    )
};

export default Guarantor
