import React, { useState, useEffect } from 'react'
import { Form, Modal, Row, Col, Image, Space, Card, Button, Tag, Divider } from 'antd'
import dayjs from 'dayjs';
import axios from "axios";
import '../../css/Media.css'
import { loadlandrepn, getImagess } from '../../file_mid/all_api';

export default function ModalDetailRe({ open, dataFromTable, close }) {

    const token = localStorage.getItem('token');
    const [dataLand, setDataLand] = useState(dataFromTable);
    // const [newDataLand, setNewDataLand] = useState([]);
    const [newDataLandRe, setNewDataLandRe] = useState([]);
    const [foundInfo, setFoundInfo] = useState(false)
    const [form] = Form.useForm()
    const [imageBlobzz, setImageBlobzz] = useState([]);
    const [note, setNote] = useState([])

    // console.log("newDataLandRe", newDataLandRe)
    // console.log("dataFromTable", dataFromTable)
    // console.log("dataLand", dataLand)

    useEffect(() => {
        console.log("ModalDetailRe")
        setDataLand(dataFromTable)
        // loadData()
        loaddatalandrepn()
        // loadDataNew() // Array
    }, [])

    // useEffect(() => {
    //     newDataLand.map((e, index) => {
    //         form.setFieldsValue({
    //             [`storyLand${index}`]: e?.storyLand,
    //         })
    //     })
    // }, [newDataLand])



    const loaddatalandrepn = async () => {
        await axios.post(loadlandrepn, { customerId: dataFromTable.customerId, reLoanId: dataFromTable.reLoanId, landId: dataFromTable.landId })
            .then(res => {
                // console.log("res.data", res.data)
                // if (res.status === 200) {
                // console.log("res.data", res.data)
                var setNewDataLand = [res.data]
                // setNewDataLandRe(setNewDataLand)
                // var cutData = res.data
                setImageBlobzz(res.data.land.img)
                if (res.data.length > 1) {
                    setFoundInfo(true)
                    setNewDataLand.forEach((e, index) => {
                        if (e.landLoanDetails.landLoanId === dataFromTable.landLoanId) {
                            setNewDataLand.splice(index, 1);
                        }
                    });
                    setNewDataLandRe(setNewDataLand)
                } else {
                    setNewDataLandRe(setNewDataLand)
                }
                // }
            }).catch((err) => {
                console.log("err", err)
            })
    };

    // const loadData = async () => {
    //     await axios.post(checklandpn, { numberLand: dataFromTable.numberLand, provinces: dataFromTable.province, district: dataFromTable.district })
    //         .then(res => {
    //             if (res.status === 200) {
    //                 console.log("LandLand", res.data)
    //                 setNote(res.data.notes)
    //             }
    //         }).catch((err) => {
    //             console.log("err", err)
    //         })
    // }

    // const loadDataNew = async () => {
    //     await axios.post(findlandpn, { landLoanId: dataFromTable.landLoanId })
    //         .then(res => {
    //             if (res.status === 200) {
    //                 console.log("res.data", res.data)
    //                 setNewDataLand(res.data)
    //             }
    //         }).catch((err) => {
    //             console.log("err", err)
    //         })
    // }


    const handleOk = () => {
        close(false);
    };

    const handleCancel = () => {
        close(false);
    };

    const currencyFormatOne = (amount) => {
        return Number(amount)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, '$&,')
    }

    let color

    if (dataLand?.approvalStatus === 16) {
        color = "gold"
    }
    if (dataLand?.approvalStatus === 12) {
        color = "gold"
    }
    if (dataLand?.approvalStatus === 14) {
        color = "orange"
    }
    if (dataLand?.approvalStatus === 5) {
        color = "orange"
    }
    if (dataLand?.approvalStatus === 18) {
        color = "cyan"
    }
    if (dataLand?.approvalStatus === 19) {
        color = "geekblue"
    }
    if (dataLand?.approvalStatus === 13) {
        color = "geekblue"
    }
    if (dataLand?.approvalStatus === 18) {
        color = "cyan"
    }
    if (dataLand?.approvalStatus === 19) {
        color = "geekblue"
    }
    if (dataLand?.approvalStatus === 2) {
        color = "lime"
    }
    if (dataLand?.approvalStatus === 3) {
        color = "green"
    }
    if (dataLand?.approvalStatus === 27) {
        color = "green"
    }
    if (dataLand?.approvalStatus === 22) {
        color = "red"
    }
    if (dataLand?.approvalStatus === 20) {
        color = "red"
    }
    if (dataLand?.approvalStatus === 20) {
        color = "red"
    }
    if (dataLand?.approvalStatus === 25) {
        color = "red"
    }
    if (dataLand?.approvalStatus === 15) {
        color = "red"
    }
    if (dataLand?.approvalStatus === 8) {
        color = "red"
    }
    if (dataLand?.approvalStatus === 10) {
        color = "green"
    }
    if (dataLand?.approvalStatus === 7) {
        color = "cyan"
    }

    const renderHeader = ({ item, index }) => {
        // console.log("item164", item)
        return (
            <>
                <Row justify={'center'}>
                    <Col span={12} style={{ textAlign: 'left' }}>
                        <b>
                            <div style={{ marginBottom: 0 }}>
                                <span>เลขสัญญา : </span>{<b style={{ fontSize: '20px', lineHeight: '32px' }}>{item?.land?.ISSUNO}</b>}
                            </div>
                        </b>
                        <b>
                            <div style={{ marginBottom: 0 }}>
                                <span>เกรด : </span>{<b style={{ fontSize: '20px', lineHeight: '32px' }}>{item?.land?.landLoanDetailsRe?.GRDCOD}</b>}
                            </div>
                        </b>
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                        <div style={{ margin: 0 }}>
                            ชื่อการตลาดที่เสนอ : <Tag color={"orange"}>{item?.land?.landLoanDetailsRe?.proposalBy}</Tag>
                        </div>
                        <div style={{ margin: 0 }}>
                            สถานะ : <Tag color={color}>{item?.land?.landLoanDetailsRe?.approvalStatusTable?.name}</Tag>
                        </div>
                        <div style={{ margin: 0 }}>
                            ประเภทขอกู้ : <Tag color={"geekblue"}>{item?.land?.landLoanDetailsRe?.productLoanLandType}</Tag>
                        </div>
                    </Col>
                </Row>

            </>
        )
    }

    const renderNoteADRe = ({ item, index }) => {
        return (
            <>
                <Row gutter={32} justify={'center'}>
                    <Col className='gutter-row' span={24}>
                        <>
                            <Card
                                key={index}
                                style={{ width: '270px', textAlign: 'left' }}>
                                <>
                                    <div style={{ marginBottom: 0 }}>
                                        <span>สาเหตุ : </span>
                                        <b>{item?.cause}</b>
                                    </div>
                                    <div style={{ marginBottom: 0 }}>
                                        <span>ผู้สร้างหมายเหตุ : </span>
                                        <b>{item?.noteBy}</b>
                                    </div>
                                    <div style={{ marginBottom: 0 }}>
                                        <span>สถานะที่ทำการหมายเหตุ : </span>
                                        <b>{item?.status}</b>
                                    </div>
                                    <div style={{ marginBottom: 0 }}>
                                        <span>เมื่อวันที่ : </span>
                                        <b>{dayjs(item?.noteDate).format("DD-MM-YYYY")}</b>
                                    </div>
                                </>
                            </Card>
                        </>
                    </Col>
                </Row>
            </>
        )
    }
    const renderLandDataReCustomer = ({ item, index }) => {
        // console.log("item", item)
        return (
            <>
                <Divider orientation={'left'}><b><u>รายละเอียดผู้ขอกู้</u></b></Divider>
                <Row justify={'center'} className='main'>
                    <aside style={{ width: '90%' }}>
                        <div style={{ marginBottom: 0 }}>
                            <span>ชื่อ - นามสกุล : </span>{<b>{item?.firstname + " " + item?.lastname}</b>}
                        </div>
                        {/* <div style={{ marginBottom: 0 }}>
                            <span>ชื่อเล่น  : </span>{<b>{item?.nickname}</b>}
                        </div> */}
                        <div style={{ marginBottom: 0 }}>
                            <span>เลขบัตรประชาชน : </span>{<b>{item?.identificationId}</b>}
                        </div>
                        {/* <div style={{ marginBottom: 0 }}>
                            <span>เพศ : </span>{<b>{item?.gender}</b>}
                        </div> */}
                        <div style={{ marginBottom: 0 }}>
                            {
                                <b>
                                    {item?.phones?.length > 1 ?
                                        item?.phones?.map((tel, index) => {
                                            return (
                                                <Row key={index}>
                                                    <div style={{ marginBottom: 0 }}>
                                                        <span>{tel?.phoneType} : </span>{< b > {tel?.telp}</b>}
                                                    </div>
                                                </Row>
                                            )
                                        })
                                        :
                                        <>
                                            <div style={{ marginBottom: 0 }}>
                                                <span>{item?.phones[0]?.phoneType} : </span>{< b > {item?.phones[0]?.telp}</b>}
                                            </div>
                                        </>
                                    }
                                </b>
                            }
                        </div>
                    </aside>
                </Row>
            </>
        )
    }
    const renderLandDataReCLoan = ({ item, index }) => {
        // console.log("item276", item)
        return (
            <>
                <Row gutter={32} justify={'center'}>
                    <Col className='gutter-row' span={24}>
                        <>
                            <Divider orientation={'left'}><b><u>รายละเอียดยอดจัดเดิม</u></b></Divider>
                            <Row justify={'center'}>
                                <aside style={{ width: '90%' }}>
                                    <div style={{ marginBottom: 3 }}>
                                        <span>ยอดจัดครั้งเดิม : </span>{<b>{currencyFormatOne(item?.land?.landLoanDetailsRe?.tcshprc)}</b>}
                                        <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                            บาท
                                        </span>
                                    </div>
                                    <div style={{ marginBottom: 3 }}>
                                        <span>ยอดที่ชำระมาแล้วทั้งหมด : </span>{<b>{currencyFormatOne(item?.land?.landLoanDetailsRe?.totalpayment)}</b>}
                                        <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                            บาท
                                        </span>
                                    </div>
                                    <div style={{ marginBottom: 3 }}>
                                        <span>จำนวนงวดที่ผ่อนมาแล้ว : </span>{<b>{item?.land?.landLoanDetailsRe?.NOPAY}</b>}
                                        <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                            งวด
                                        </span>
                                    </div>
                                    <div style={{ marginBottom: 3 }}>
                                        <span>ราคาเช่าซื้อครั้งที่แล้ว : </span>{<b>{currencyFormatOne(item?.land?.landLoanDetailsRe?.balanc)}</b>}
                                        <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                            บาท
                                        </span>
                                    </div>

                                    <div style={{ marginBottom: 3 }}>
                                        <span>ล/น คงเหลือรวม : </span>{<b>{currencyFormatOne(item?.land?.landLoanDetailsRe?.neekong)}</b>}
                                        <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                            บาท
                                        </span>
                                    </div>
                                    <div style={{ marginBottom: 3 }}>
                                        <span>จำนวนงวดทั้งหมด : </span>{<b>{item?.land?.landLoanDetailsRe?.nopays}</b>}
                                        <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                            งวด
                                        </span>
                                    </div>

                                    <div style={{ marginBottom: 3 }}>
                                        <span>ค่างวด : </span>{<b>{currencyFormatOne(item?.land?.landLoanDetailsRe?.DAMT)}</b>}
                                        <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                            บาท
                                        </span>
                                    </div>

                                    <div style={{ marginBottom: 3 }}>
                                        <span>เบี้ยปรับ : </span>{<b>{currencyFormatOne(item?.land?.landLoanDetailsRe?.beeypup)}</b>}
                                        <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                            บาท
                                        </span>
                                    </div>
                                    <div style={{ marginBottom: 3 }}>
                                        <span>ลูกหนี้อื่นๆ : </span>{<b>{currencyFormatOne(item?.land?.landLoanDetailsRe?.OTHR)}</b>}
                                        <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                            บาท
                                        </span>
                                    </div>

                                    <div style={{ marginBottom: 3 }}>
                                        <span>ตัดสด งวดที่ : </span>{<b>{dayjs(item?.land?.landLoanDetailsRe?.ftime).format("DD/MM/YYYY")}</b>}
                                    </div>

                                    <div style={{ marginBottom: 3 }}>
                                        <span>ผู้เสนอเคส : </span>{<b>{item?.land?.landLoanDetailsRe?.proposalBy}</b>}
                                    </div>
                                </aside>
                            </Row>
                        </>
                    </Col>
                </Row>
            </>
        )
    }
    const renderLandDataRe = ({ item, index }) => {
        // console.log("item", item)
        return (
            <>
                <Divider orientation={'left'}><b><u>รายละเอียดที่ดิน ({item?.land?.numberLand}) {newDataLandRe.length > 1 ? <>{item.landMain ? <b style={{ color: "red" }} >(ที่ดินหลัก)</b> : null}</> : null} </u></b></Divider>
                <Row justify={'center'} className='main'>
                    <aside style={{ width: '90%' }}>
                        <div style={{ marginBottom: 0 }}>
                            <span>กลุ่มสินค้า : </span>{<b>{item?.land?.landLoanDetailsRe?.productType}</b>}
                        </div>
                        <div style={{ marginBottom: 0 }}>
                            <span>เลขที่ดินหรือระวาง : </span>{<b>{item?.land?.numberLandlawang}</b>}
                        </div>
                        <div style={{ marginBottom: 0 }}>
                            <span>พื้นที่ไร่ : </span>{<b>{item?.land?.rai}</b>}
                            <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                ไร่
                            </span>
                        </div>
                        <div style={{ marginBottom: 0 }}>
                            <span>ราคาประเมินที่ดิน : </span> {<b>{item?.land?.landPrice}</b>}
                            <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                บาท / ตารางวา
                            </span>
                        </div>
                        <div style={{ marginBottom: 0 }}>
                            <span>ประเภทเอกสาร : </span>{<b>{item?.land?.productTypeLand === 1 ? "นส.3" : "นส.4"}</b>}
                        </div>
                        <div style={{ marginBottom: 0 }}>
                            <span>จังหวัด : </span>{<b>{item?.land?.province}</b>}
                        </div>
                        <div style={{ marginBottom: 0 }}>
                            <span>พื้นที่งาน : </span>{<b>{item?.land?.workArea}</b>}
                            <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                งาน
                            </span>
                        </div>
                        <div style={{ marginBottom: 0 }}>
                            <span>ราคารวม : </span> {<b>{item?.land?.resultLandPrice}</b>}
                            <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                บาท
                            </span>
                        </div>
                        <div style={{ marginBottom: 0 }}>
                            <span>เลขโฉนดที่ดิน : </span>{<b>{item?.land?.numberLand}</b>}
                        </div>
                        <div style={{ marginBottom: 0 }}>
                            <span>อำเภอ : </span>{<b>{item?.land?.district}</b>}
                        </div>
                        <div style={{ marginBottom: 0 }}>
                            <span>พื้นที่ตารางวา : </span>{<b>{currencyFormatOne(item?.land?.squareWaArea)}</b>}
                            <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                ตารางวา
                            </span>
                        </div>
                    </aside>
                    {/* <Divider orientation={'left'}><b><u>สตอรี่รายละเอียดที่ดิน ({item?.land?.numberLand})</u></b></Divider>
                    <Form.Item name={`storyLand${index}`} label="" style={{ width: '90%' }}>
                        <TextArea style={{ color: 'blue', width: '100%' }} rows={5} disabled />
                    </Form.Item> */}
                </Row>
            </>
        )
    }
    const renderCoordinates = ({ item, index }) => { // พิกัด
        // console.log("item989", item)
        return (
            <>


                {item?.land?.productTypeLand === 2 ?
                    <>
                        <Divider orientation='left'><b>พิกัด ({item?.land?.numberLand})</b></Divider>
                        <Col className='gutter-row' span={12} style={{ textAlign: 'center' }}>
                            <span>รายละเอียดพิกัด ({item?.land?.numberLand}) : </span>
                            <a href={`https://www.google.com/maps?q=${item?.land?.parcellat},${item?.land?.parcellon}`} target="_blank">
                                {`${item?.land?.parcellat},${item?.land?.parcellon}`}
                            </a>
                        </Col>

                    </>
                    : null
                }

            </>
        )
    }
    const renderApprovalRe = ({ item, index }) => {
        return (
            <>
                <Divider orientation={'left'}><b><u>รายละเอียดยอดที่อนุมัติ ({item?.land?.numberLand})</u></b></Divider>
                <Row justify={'center'} style={{ background: 'lightgreen' }}>
                    <aside style={{ width: '90%' }}>
                        <div style={{ marginBottom: 0 }}>
                            <span>ยอดที่อนุมัติ : </span>{<b>{currencyFormatOne(item?.land?.approvalLandPrice?.minPrice) + " - " + currencyFormatOne(item?.land?.approvalLandPrice?.maxPrice)}  </b>}
                            {/* <span>ยอดที่อนุมัติ : </span>{<b>{item?.land?.approvalLandPrice?.minPrice + " - " + item?.land?.approvalLandPrice?.maxPrice}  </b>} */}
                            <span>  บาท </span>
                        </div>
                        <div style={{ marginBottom: 0 }}>
                            <span>หมายเหตุ  : </span>{<b>{item?.land?.approvalLandPrice?.note}</b>}
                        </div>
                        <div style={{ marginBottom: 0 }}>
                            {/* ขาดตัวแปล */}
                            <span>หมายเหตุการจดรวม : </span>{<b>{ }</b>}
                        </div>
                    </aside>
                </Row>


            </>
        )
    }
    // const renderTitleDeedRe = ({ item, index }) => {
    //     return (
    //         <>
    //             {/* <Row justify={'left'}><b><u>รูปโฉนด/หน้า-หลัง ({item?.land?.numberLand})</u></b></Row> */}
    //             <Divider orientation={'left'}><b><u>รูปโฉนด/หน้า-หลัง ({item?.land?.numberLand})</u></b></Divider>

    //             <Space>
    //                 <Row gutter={32} justify={'center'}>
    //                     {item?.img?.map((e, index) => {
    //                         var PreAaproveSplit = []
    //                         PreAaproveSplit = e.pathImage.split("/")
    //                         if (PreAaproveSplit[3] !== "13") {
    //                             if (e.type === 3) {
    //                                 return (
    //                                     <>
    //                                         <Image width={"10%"} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
    //                                     </>
    //                                 );
    //                             }
    //                         }
    //                         return null;
    //                     })}
    //                 </Row>
    //             </Space>
    //         </>
    //     )
    // }
    // const renderDivisionRe = ({ item, index }) => {
    //     return (
    //         <>
    //             {/* <Row justify={'left'}><b><u>ใบแบ่งใบต่อ ({item?.land?.numberLand})</u></b></Row> */}
    //             <Divider orientation={'left'}><b><u>ใบแบ่งใบต่อ ({item?.land?.numberLand})</u></b></Divider>

    //             <Space>
    //                 <Row gutter={32} justify={'center'}>
    //                     {item?.img?.map((e, index) => {
    //                         var PreAaproveSplit = []
    //                         PreAaproveSplit = e.pathImage.split("/")
    //                         if (PreAaproveSplit[3] !== "13") {
    //                             if (e.type === 10) {
    //                                 return (
    //                                     <>
    //                                         <Image width={"10%"} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
    //                                     </>
    //                                 );
    //                             }
    //                         }
    //                         return null;
    //                     })}
    //                 </Row>
    //             </Space>
    //         </>
    //     )
    // }
    // const renderLawangRe = ({ item, index }) => {
    //     return (
    //         <>
    //             {/* <Row justify={'left'}><b><u>ระวาง ({item?.land?.numberLand})</u></b></Row> */}
    //             <Divider orientation={'left'}><b><u>ระวาง ({item?.land?.numberLand})</u></b></Divider>

    //             <Space>
    //                 <Row gutter={32} justify={'center'}>
    //                     {item?.img?.map((e, index) => {
    //                         var PreAaproveSplit = []
    //                         PreAaproveSplit = e.pathImage.split("/")
    //                         if (PreAaproveSplit[3] !== "13") {
    //                             if (e.type === 11) {
    //                                 return (
    //                                     <>
    //                                         <Image width={"10%"} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
    //                                     </>
    //                                 );
    //                             }
    //                         }
    //                         return null;
    //                     })}
    //                 </Row>
    //             </Space>
    //         </>
    //     )
    // }
    // const renderLandBuildingsRe = ({ item, index }) => {
    //     return (
    //         <>
    //             {/* <Row justify={'left'}><b><u>รูปที่ดิน/สิ่งปลูกสร้าง-ที่ดินปัจจุบัน ({item?.land?.numberLand})</u></b></Row> */}
    //             <Divider orientation={'left'}><b><u>รูปที่ดิน/สิ่งปลูกสร้าง-ที่ดินปัจจุบัน ({item?.land?.numberLand})</u></b></Divider>

    //             <Space>
    //                 <Row gutter={32} justify={'center'}>
    //                     {item?.img?.map((e, index) => {
    //                         var PreAaproveSplit = []
    //                         PreAaproveSplit = e.pathImage.split("/")
    //                         if (PreAaproveSplit[3] !== "13") {
    //                             if (e.type === 1) {
    //                                 return (
    //                                     <>
    //                                         <Image width={"10%"} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
    //                                     </>
    //                                 );
    //                             }
    //                         }
    //                         return null;
    //                     })}
    //                 </Row>
    //             </Space>

    //         </>
    //     )
    // }

    return (
        <>
            <Modal open={open} onOk={handleOk} onCancel={handleCancel} width={'1180px'}
                footer={[null]} >

                <Row justify={'center'}>
                    <Col className='gutter-row' span={24} style={{ fontSize: '25px', textAlign: 'center' }}>
                        <b>รายละเอียด</b>
                    </Col>
                </Row>
                <Row justify={'center'}>
                    <Divider style={{ margin: 5 }} />
                    <Form
                        labelCol={{
                            span: 12,
                        }}
                        wrapperCol={{
                            span: 24,
                        }}
                        style={{
                            width: '90%',
                        }}
                        form={form}
                        autoComplete="off"
                        initialValues={{ remember: true }}
                    >
                        <div className='main'>
                            <Card style={{ width: '100%' }}>

                                <Col justify={'center'}>
                                    {
                                        newDataLandRe?.map((item, index) => {
                                            return renderHeader({ item, index })
                                        })
                                    }
                                </Col>
                                <Col justify={'center'}>
                                    {
                                        newDataLandRe?.map((item, index) => {
                                            return renderLandDataReCustomer({ item, index })
                                        })
                                    }
                                </Col>
                                <Col justify={'center'}>
                                    {
                                        newDataLandRe?.map((item, index) => {
                                            return renderLandDataReCLoan({ item, index })
                                        })
                                    }
                                </Col>
                                <Col justify={'center'}>
                                    {
                                        newDataLandRe?.map((item, index) => {
                                            return renderLandDataRe({ item, index })
                                        })
                                    }
                                </Col>
                                <Col justify={'center'}>
                                    {
                                        newDataLandRe?.map((item, index) => {
                                            return renderCoordinates({ item, index })
                                        })
                                    }

                                </Col>

                                <Col justify={'center'}>
                                    {
                                        newDataLandRe?.map((item, index) => {
                                            return renderApprovalRe({ item, index })
                                        })
                                    }
                                </Col>
                                {
                                    note?.length > 0 ?
                                        <>
                                            <Divider style={{ marginBottom: 5 }} />
                                            <Row justify={'left'}>
                                                <b><u>หมายเหตุ</u></b>
                                            </Row>
                                            <Row gutter={32} justify={'center'}>
                                                {
                                                    note?.map((item, index) => {
                                                        return renderNoteADRe({ item, index })
                                                    })
                                                }

                                            </Row>
                                        </>
                                        : null
                                }

                                {/* {
                                    newDataLandRe?.map((item, index) => {
                                        return renderTitleDeedRe({ item, index })
                                    })
                                }
                                {
                                    newDataLandRe?.map((item, index) => {
                                        return renderDivisionRe({ item, index })
                                    })
                                }
                                {
                                    newDataLandRe?.map((item, index) => {
                                        return renderLawangRe({ item, index })
                                    })
                                }
                                {
                                    newDataLandRe?.map((item, index) => {
                                        return renderLandBuildingsRe({ item, index })
                                    })
                                } */}

                                {/* <>
                                    <Row justify={'left'}><b><u>รูปโฉนด/หน้า-หลัง</u></b></Row>
                                    <Space>
                                        <Row gutter={32} justify={'center'}>
                                            {imageBlobzz?.map((e, index) => {
                                                if (e.type === 3) {
                                                    return (
                                                        <>
                                                            <Image width={"20%"} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                        </>

                                                    );
                                                }
                                                return null;
                                            })}
                                        </Row>
                                    </Space>

                                    <Divider />
                                    <Row justify={'left'}><b><u>ใบแบ่งใบต่อ</u></b></Row>
                                    <Space>
                                        <Row gutter={32} justify={'center'}>
                                            {imageBlobzz?.map((e, index) => {
                                                if (e.type === 10) {
                                                    return (
                                                        <>
                                                            <Image width={"20%"} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                        </>
                                                    );
                                                }
                                                return null;
                                            })}
                                        </Row>
                                    </Space>
                                    <Divider />
                                    <Row justify={'left'}><b><u>ระวาง</u></b></Row>
                                    <Space>
                                        <Row gutter={32} justify={'center'}>
                                            {imageBlobzz?.map((e, index) => {
                                                if (e.type === 11) {
                                                    return (
                                                        <>
                                                            <Image width={"20%"} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                        </>
                                                    );
                                                }
                                                return null;
                                            })}
                                        </Row>
                                    </Space>
                                    <Divider />
                                    <Row justify={'left'}><b><u>รูปที่ดิน/สิ่งปลูกสร้าง-ที่ดินปัจจุบัน</u></b></Row>
                                    <Space>
                                        <Row gutter={32} justify={'center'}>
                                            {imageBlobzz?.map((e, index) => {
                                                if (e.type === 1) {
                                                    return (
                                                        <>
                                                            <Image width={"20%"} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                        </>
                                                    );
                                                }
                                                return null;
                                            })}
                                        </Row>
                                    </Space>
                                    <Divider />
                                    <Col className='gutter-row' span={24} style={{ textAlign: 'right' }}>
                                        <Space>
                                            <Button key="back" type='primary' danger onClick={handleCancel}>ปิด</Button>
                                        </Space>
                                    </Col>
                                </> */}
                                <Divider />
                                <Col span={24}>
                                    <Divider orientation="left"><b>รูปภาพ</b></Divider>
                                    <Image.PreviewGroup>
                                        {/* <span>โฉนด : </span>
                                        <Space size={[10, 10]} wrap>
                                            {imageBlobzz?.map((e, index) => {
                                                if (e.type === 3) {
                                                    return (
                                                        <>
                                                            <Image width={50} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                        </>
                                                    );
                                                }
                                                return null;
                                            })}
                                        </Space>
                                        <Divider />
                                        <span>ใบแบ่งใบต่อ : </span>
                                        <Space size={[10, 10]} wrap>
                                            {imageBlobzz?.map((e, index) => {
                                                if (e.type === 10) {
                                                    return (
                                                        <>
                                                            <Image width={50} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                        </>
                                                    );
                                                }
                                                return null;
                                            })}
                                        </Space>
                                        <Divider />
                                        <span>ระวาง : </span>
                                        <Space size={[10, 10]} wrap>
                                            {imageBlobzz?.map((e, index) => {
                                                if (e.type === 11) {
                                                    return (
                                                        <>
                                                            <Image width={50} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                        </>
                                                    );
                                                }
                                                return null;
                                            })}
                                        </Space>
                                        <Divider />
                                        <span>รูปที่ดิน : </span> */}
                                        <Space size={[10, 10]} wrap>
                                            {imageBlobzz?.map((e, index) => {
                                                if (e.type === 1) {
                                                    return (
                                                        <>
                                                            <Image width={50} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                        </>
                                                    );
                                                }
                                                return null;
                                            })}
                                        </Space>
                                    </Image.PreviewGroup>
                                </Col>
                                <Divider />
                                <Col span={24}>
                                    <Divider orientation="left"><b>เอกสารขอรี/ปรับ/เปลี่ยน</b></Divider>
                                    <Image.PreviewGroup>
                                        <Space size={[10, 10]} wrap>
                                            {imageBlobzz?.map((e, index) => {
                                                if (e.type === 14) {
                                                    return (
                                                        <>
                                                            <Image width={50} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                        </>
                                                    );
                                                }
                                                return null;
                                            })}
                                        </Space>
                                    </Image.PreviewGroup>
                                </Col>
                                <Divider />
                                <Col className='gutter-row' span={24} style={{ textAlign: 'right' }}>
                                    <Space>
                                        <Button key="back" type='primary' danger onClick={handleCancel}>ปิด</Button>
                                    </Space>
                                </Col>
                            </Card>
                        </div>
                    </Form>
                </Row>
            </Modal >
        </>
    )
}
