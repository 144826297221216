import React, { useState, useEffect } from 'react';
import { Col, Form, Input, Select, Modal, Upload, Divider, Row, Button, Card, message } from 'antd';
import { DeleteOutlined, SaveOutlined, PlusOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Currency from 'currency.js';
import dayjs from 'dayjs';
import axios from 'axios';
import TableRateCar from '../../offer_case/rate_car/TableRateCar';
import { HirePurchase } from '../../offer_case/hire_purchase/HirePurchase';
import { Installment } from '../../offer_case/ploan/Installment';
import { addCar } from '../../../redux/Car';
import { addLoan } from '../../../redux/Loan';
import { addOldLoan } from '../../../redux/OldLoan';
import { addImage } from '../../../redux/Image';
import { addLand } from '../../../redux/Land';
import { addLoanLand } from '../../../redux/LoanLand';
import { productLoanTypeCar, productLoanTypeLand } from '../../file_mid/all_options';
import { AllData, GetAmphur1, GetProvince, PostProvince1, allcreate, allcreateland, dataModel, getprice, loadProvinces, ratecarsmodel, upimg } from '../../file_mid/all_api';

const { Option } = Select;

function Product() {
    const getBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    const [messageApi, contextHolder] = message.useMessage();
    const user = localStorage.getItem('username');
    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const formData = new FormData()

    const { Addresss } = useSelector((state) => ({ ...state }))
    const { Phones } = useSelector((state) => ({ ...state }))
    const { customers } = useSelector((state) => ({ ...state }))
    const { cars } = useSelector((state) => ({ ...state }))
    const { loans } = useSelector((state) => ({ ...state }))
    const { oldloans } = useSelector((state) => ({ ...state }))
    const { lands } = useSelector((state) => ({ ...state }))
    const { loansLand } = useSelector((state) => ({ ...state }))
    const { imgs } = useSelector((state) => ({ ...state }))
    const [selectProduct, setSelectProduct] = useState({})

    //Set data
    const result1 = { ...customers.data, address: [{ ...Addresss.data }], phones: [{ ...Phones.data }], carLoanDetails: [{ ...loans.data, cars: [{ ...cars.data }] }] }
    const result2 = { ...customers.data, address: [{ ...Addresss.data }], phones: [{ ...Phones.data }], carLoanDetails: [{ ...loans.data, cars: [{ ...cars.data, oldLoanDetails: { ...oldloans.data } }] }] }
    const result3 = { ...customers.data, phones: [{ ...Phones.data }], carLoanDetails: [{ ...loans.data, cars: [{ ...cars.data }] }] }
    const result4 = { ...customers.data, phones: [{ ...Phones.data }], carLoanDetails: [{ ...loans.data, cars: [{ ...cars.data, oldLoanDetails: { ...oldloans.data } }] }] }

    const result5 = { ...customers.data, address: [{ ...Addresss.data }], phones: [{ ...Phones.data }], landLoanDetails: [{ ...loansLand.data, land: [{ ...lands.data, img: imgs.data }] }] }
    const result6 = { ...customers.data, phones: [{ ...Phones.data }], landLoanDetails: [{ ...loansLand.data, land: [{ ...lands.data, img: imgs.data }] }] }
    const show = { ...customers.data, ...lands.data, ...loansLand.data, ...Addresss.data, ...Phones.data }

    //Set Data for send to database
    const [resultNO, setResultNO] = useState({}) // ข้อมูลที่จะส่งไปหลังบ้าน ไม่มีโอโลน
    const [resultO, setResultO] = useState({}) // ข้อมูลที่จะส่งไปหลังบ้าน มีโอโลน
    const [resultNoLand, setResultNOLand] = useState({}) // ข้อมูลที่ดินที่จะส่งไปหลังบ้าน มีโอโลน
    const [resultOLand, setResultOLand] = useState({}) // ข้อมูลที่ดินที่จะส่งไปหลังบ้าน มีโอโลน
    //Loan Land
    const [dataPostLand, setDataPostLand] = useState({})  // Land
    const [moneyLand, setMoneyLand] = useState({}) //loan land
    const [moneyOldLand, setMoneyOldLand] = useState({}) //loanOld land
    const [typeMoneyLand, setTypeMoneyLand] = useState({})
    const [easy, setEasy] = useState({}) // โชว์ในหน้าเว็บ


    //State Data
    const [dataPost1, setDataPost1] = useState({}) //customer
    const [dataPost2, setDataPost2] = useState({ productTypeCar: 1 })  //car
    const [money, setMoney] = useState({}) //loan
    const [moneyOld, setMoneyOld] = useState({}) //loanOld
    const [typeMoney, setTypeMoney] = useState({})
    const [provincesCar, setProvincesCar] = useState([]);


    //Table
    const [openTable, setOpenTable] = useState(false); //ตารางค่างวด
    const [openTableHP, setOpenTableHP] = useState(false); //ตารางค่างวด เช่าซื้อ
    const [openTableCar, setOpenTableCar] = useState(false); //ตารางเรทรถ
    const [opensave, setOpenSave] = useState(false); //Modalยืนยัน

    //Car
    const [resultData, setResultData] = useState([]) // API
    const [typeData, setTypeData] = useState([]) // API
    const [brandData, setBrandData] = useState([]) // API
    const [modelData, setModelData] = useState([]) // API
    const [yearPrice, setYearPrice] = useState([]) //ปี
    const [typeSelected, setTypeSelected] = useState(null)
    //SelectCar
    const [counterBrand, setCounterBrand] = useState(false) //กรณีเพิ่มยี่ห้อรถใหม่
    const [counterModel, setCounterModel] = useState(false)//กรณีเพิ่มรุ่นรถใหม่
    const [counterCarBaab, setCounterCarBaab] = useState(false)//กรณีเพิ่มแบบรถใหม่

    //Select p-loan or Hire purchase
    const [checkPCH, setCheckPCH] = useState({ PLorCH: "p-loan" }) //กรณีเลือก p-loan

    //senddatatoguarantor
    const [carid1, setCarId1] = useState({})

    //Upload Image
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [fileList, setFileList] = useState([]);
    const [fileListBook, setFileListBook] = useState([]);
    const [fileListResult1, setFileListResult1] = useState([]);
    const [fileListResult2, setFileListResult2] = useState([]);

    //Province
    const [provinces, setProvinces] = useState([]);
    const [district, setDistricts] = useState([]);

    const [pvcodeS, setPvcodeS] = useState({});
    const [amcodeS, setAmcodeS] = useState({});

    useEffect(() => {
        if (imgs.data) {
            setFileList(imgs.data)
        }
        setCarId1(carid1)
        form.setFieldsValue({ proposalBy: user, PLorCH: "p-loan" })
        if (cars.data) {
            setSelectProduct({ ...selectProduct, productTypeId: 1 })
            setDataPost1(customers.data)
            setDataPost2(cars.data)
            setMoney(loans.data)
            setMoneyOld(oldloans.data)
            form.setFieldsValue(
                {
                    ...customers.data, ...loans.data, ...oldloans.data, ...cars.data,
                    proposalBy: user,
                    birthdate: dayjs(customers.data?.birthdate),
                    issudt: dayjs(customers.data?.issudt),
                    expdt: dayjs(customers.data?.expdt),
                    carInput: dayjs(cars.data?.carInput),
                }
            )
        }
        if (loans.data) {
            setCheckPCH(loans.data)
        }

        if (lands.data) {
            //console.log("DD")
            getProvinces()
            GetAmphur(lands.data.district)
            PostProvince(lands.data.province)
            setSelectProduct({ ...selectProduct, productTypeId: 2 })
            setDataPost1(customers.data)
            setDataPostLand(lands.data)
            setMoneyLand(loansLand.data)
            form.setFieldsValue(
                {
                    ...customers.data, ...lands.data, ...loansLand.data,
                    proposalBy: user,
                    birthdate: dayjs(customers.data?.birthdate),
                    issudt: dayjs(customers.data?.issudt),
                    expdt: dayjs(customers.data?.expdt),
                    landInput: dayjs(cars.data?.carInput),
                }
            )
        }

        if (Addresss.data !== undefined || Addresss.data !== null) {
            setResultNO(result1) //car have address
            setResultO(result2) //car have address
        }
        if (Addresss.data === undefined || Addresss.data === null) {
            setResultNO(result3) //car not address
            setResultO(result4) //car not address
        }
        if (Addresss.data !== undefined || Addresss.data !== null) {
            setResultNOLand(result5) //land have address
        }
        if (Addresss.data === undefined || Addresss.data === null) {
            setResultOLand(result6) //land not address
        }

        setEasy(show)
    }, [openTableCar])

    useEffect(() => {
        if (money && dataPost2.productTypeCar > 1 && checkPCH.PLorCH === "เช่าซื้อ") {
            console.log("ss")
            car_Calculate()
        }
        if (moneyLand && selectProduct.productTypeId === 2) {
            console.log("XX")
            land_Calculate()
        }
    }, [money?.loanAmountt, money?.interestRate, money?.installmentAmountt, moneyLand?.loanAmounttLand, moneyLand?.interestRateLand, moneyLand?.loanLandTerm])


    const currencyFormatOne = (amount) => {
        return Number(amount).toFixed(0).replace(/\d(?=(\d{3})+(?!\d))/g, '$&,')
    }
    const currencyFormat = (amount) => {
        return Number(amount)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, '$&,')
    }
    //SaveData
    const handleSubmit = async () => {
        setLoading(true)
        if (JSON.stringify(Addresss.data) === '{}') {
            delete resultNO.address
        }
        if (selectProduct.productTypeId === 1) {
            console.log(" selectProduct =", selectProduct.productTypeId)
            if (oldloans.data) {
                console.log("ข้อมูลทั้งหมด มีโอโลน", resultO)
                await axios.post(allcreate, resultO)
                    .then(res => {
                        if (res.status === 200) {
                            console.log("OOO", res.data.DATA);
                            uploadImg(res.data.DATA.carLoanDetails[0].cars[0].carId)
                            setOpenSave(false);
                            dispatch(addCar(res.data))
                            navigate('/addcustomer/guarantor')

                        }
                    })
                    .catch((err) => {
                        if (err.response.request.status === 401) {
                            unauthorized()
                        }
                        else {
                            error()
                        }
                        console.log("err", err)
                        setLoading(false)
                    })
            }
            else {
                console.log("ข้อมูลที่ดินทั้งหมด ไม่มีโอโลน", resultNoLand)
            };
        } else {
            console.log("productTypeId =", selectProduct.productTypeId)
            if (JSON.stringify(Addresss.data) === '{}') {
                delete resultNO.address
            }
            console.log("ที่ดิน")
            //console.log("ที่ดิน =", resultNO)

            setLoading(true)
            await axios.post(allcreateland, resultNO)
                .then(res => {
                    if (res.status === 200) {
                        // dispatch(addCustomer())
                        // dispatch(addAddress())
                        // dispatch(addPhone())
                        dispatch(addLand())
                        dispatch(addLoanLand())
                        dispatch(addImage())
                        setLoading(false)
                        navigate('/checkinfo')
                        // success()
                    }
                })
                .catch((err) => {
                    if (err.response.request.status === 401) {
                        unauthorized()
                    }
                    else {
                        error()
                    }
                    console.log("err", err)
                    setLoading(false)
                })
            setLoading(false)
        }
    }

    const error = () => {
        Modal.error({
            title: 'บันทึกไม่สำเร็จ',
            content: 'กรุณาลองใหม่อีกครั้ง',
        })
    }
    const unauthorized = () => {
        Modal.error({
            title: 'unauthorized',
            content: 'คุณไม่มีสิทธิ์ใช้งานเมนูนี้',
        })
    }


    //UploadImages
    const uploadImg = async (carId) => {
        console.log("fileList", fileList)
        console.log("carId111", carId)
        // แปลงและเพิ่มไฟล์ใน formData
        var imageList = []
        for (const file of fileList) {
            var image = {}
            var filename = file.name
            var image64 = await getBase64(file.originFileObj)
            image.filename = filename
            image.image64 = image64
            imageList.push(image)
        }
        //const dataii = {mainImg: {carId: car, carInput: "2023-09-20T15:30:42+07:00", img: imageList}}
        const img = { mainImg: { carId, carInput: dayjs().format(), img: imageList } }
        console.log("DATA", img)
        setLoading(true)
        const headers = { 'Content-Type': 'application/json' }
        await axios.post(upimg, img, { headers: headers })
            .then(res => {
                if (res.data) {
                    console.log("TY", res.data)
                    dispatch(addImage(res.data))
                    setLoading(false)
                }
            })
            .catch((err) => console.error('เกิดข้อผิดพลาดในการอัปโหลดรูปภาพ:', err))
    }
    //ShowModal
    const showModal = () => {
        setOpenSave(true);

        dispatch(addCar(dataPost2))     // inport to redux
        dispatch(addLoan(money))        // inport to redux
        dispatch(addOldLoan(moneyOld))  // inport to redux
        if (JSON.stringify(Addresss.data) === '{}') {
            delete resultNO.address
            delete resultO.address
        }
        setLoading(true)
        if (moneyOld) {
            setResultO({ ...customers.data, address: [{ ...Addresss.data }], phones: [{ ...Phones.data }], carLoanDetails: [{ ...money, cars: [{ ...dataPost2, oldLoanDetails: { ...moneyOld } }] }] })
        }
        else {
            setResultNO({ ...customers.data, address: [{ ...Addresss.data }], phones: [{ ...Phones.data }], carLoanDetails: [{ ...money, cars: [{ ...dataPost2 }] }] })
        };
    }

    //////////////// จังหวัด รถ /////////////////////
    const fetchAllProvinces = async () => {
        await axios.get(loadProvinces)
            .then(response => {
                setProvincesCar(response.data);
            }).catch(error => {
                console.error(error);
            });
    }

    const handleProvinceChangeCar = (value, element) => {
        setDataPost2({ ...dataPost2, carProvince: value, });
    };

    const getProvinces = async () => {
        // const tokens = "eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6IjEiLCJyZWdpc3Rlcl90eXBlX3NlcSI6IjgiLCJ1c2VyX2dyb3VwX2RhdGFfbGlzdCI6IjEsMSwwLDAsMCwwLDAsMSIsInBlcnNvbmFsX2lkIjoiIiwianRpIjoiYzg5MjliZjktOGQ2Ny00ODhkLWE0M2MtMWYyYTcyZGQ0ZmZjIiwiaWF0IjoiMDkvMjkvMjAyMyAwNDo1OTo1NCIsIm5iZiI6MTY5NTk2MzU5NCwiZXhwIjoxNjk2MDQ5OTk0LCJpc3MiOiJodHRwczovL2xvY2FsaG9zdDozMDAwMS8iLCJhdWQiOiJMYW5kc01hcHMifQ.BX_IYnEKbaNr8vvixELjaJlzllUcAGf-T7D1ZedJ7aty23GZTLjIGxO7YXrsM-yH1JUe6wLym0vdbyQ1bStSTQ"
        // const provids = pvcodeS
        // const amphurs = amcodeS
        // const landNos = dataPostLand.numberLand
        // const result = { pvcode: provids, amcode: amphurs, landNo: landNos }
        // //console.log("result =", result)
        // const headers = {
        //     // "Content-Type": "application/json",
        //     "Authorization": `Bearer ${tokens}`,
        // }
        setLoading(true)
        await axios.get(GetProvince)
            //await axios.get('https://landsmaps.dol.go.th/data/province.json')
            //await axios.get('https://landsmaps.dol.go.th/apiService/JWT/GetJWTAccessToken')
            //await axios.get('https://landsmaps.dol.go.th/apiService/LandsMaps/GetParcelByParcelNo/96/10/10001',{ headers: headers })
            .then((res) => {
                console.log("Province =", res.data)
                setProvinces(res.data)
                setLoading(false)
            }).catch((err) => {
                console.error(err);
            })
        setLoading(false)
    }

    //เรียกข้อมูลจังหวัด
    const PostProvince = async (value) => {
        const pr = { pvcode: value }
        //console.log("pr =", pr)
        setLoading(true)
        await axios.post(PostProvince1, pr)
            .then((res) => {
                //console.log("Province =", res.data)
                //console.log("pvcode =", res.data[0].pvcode)
                setPvcodeS(res.data[0].pvcode)
                //setProvinces(res.data)
                setLoading(false)
            }).catch((err) => {
                console.error(err);
            })
        setLoading(false)
    }

    //เรียกข้อมูลอำเภอ
    const GetAmphur = async (value) => {
        const am = { pvcode: value }
        //console.log("am =", am)
        setLoading(true)
        await axios.post(GetAmphur1, am)
            .then((res) => {
                //console.log("Amphur =", res.data)
                setDistricts(res.data)
                setLoading(false)
            }).catch((err) => {
                console.error(err);
            })
        setLoading(false)
    }

    //จังหวัดที่ดิน
    const handleProvinceChange = async (value, key) => {
        //console.log("Province =", key["key"], value)
        setPvcodeS(key["key"])
        setDataPostLand({ ...dataPostLand, province: value });
        form.setFieldsValue(
            {
                numberLand: "",
                numberLandLawang: "",
                rai: "",
                workArea: "",
                squareWaArea: "",
                landPrice: "",
                resultLandPrice: "",
                district: "",
                // productLoanLandType: "",
                // loanAmounttLand: "",
                // loanLandTerm: "",
                // interestRateLand: "",
                // interesttLand: "",
                // monthlyPaymentLand: "",
                // installmentWithInterestLand: "",
            }
        )
        setLoading(true)
        const test = { pvcode: key["key"] }
        await axios.post(GetAmphur1, test)
            .then((res) => {
                setDistricts(res.data)
                setLoading(false)
            }).catch((err) => {
                console.error(err);
            })
        setLoading(false)
    };


    //อำเภอที่ดิน
    const handleDistrictChange = (value, key) => {
        //console.log("VV =", key["key"], value)
        setAmcodeS(key["key"])
        setDataPostLand({ ...dataPostLand, district: value });
        form.setFieldsValue(
            {
                numberLand: "",
                numberLandLawang: "",
                rai: "",
                workArea: "",
                squareWaArea: "",
                landPrice: "",
                resultLandPrice: "",
                // productLoanLandType: "",
                // loanAmounttLand: "",
                // loanLandTerm: "",
                // interestRateLand: "",
                // interesttLand: "",
                // monthlyPaymentLand: "",
                // installmentWithInterestLand: "",
            }
        )

    };

    //Land
    const handleChangeLandType = (value) => {
        setMoneyLand({ ...moneyLand, productLoanLandType: value, proposalBy: user, productType: "ที่ดิน", approvalStatus: 16 })
        form.setFieldsValue(
            {
                loanAmounttLand: '',
                loanLandTerm: '',
                interestRateLand: '',
                interesttLand: '',
                monthlyPaymentLand: '',
                installmentWithInterestLand: ''
            }
        )
    }

    const currencyFormatOnes = (amount) => {
        return Number(amount).toFixed(0).replace(/\d(?=(\d{3})+(?!\d))/g, '$&,')
    }

    const handleMoneyHP = (values) => {
        //console.log("ER", values)
        setMoney({
            ...money,
            interestRate: values?.interestRate,
            loanAmountt: values?.loanAmountt,
            installmentAmountt: values?.installmentAmountt,
            interestt: values?.interestt,
            monthlyPayment: values?.monthlyPayment,
            installmentWithInterestt: values?.installmentWithInterestt,
            approvalStatus: values?.approvalStatus,
            proposalBy: user,
            productType: checkPCH.PLorCH,
            PLorCH: checkPCH.PLorCH
        })
        form.setFieldsValue(
            {
                loanAmountt: values?.loanAmountt,
                installmentAmountt: values?.installmentAmountt,
                interestt: values?.interestt,
                monthlyPayment: values?.monthlyPayment,
                installmentWithInterestt: Currency(values?.installmentWithInterestt),
            }
        )
    }
    //Car
    const handleChangeType = (value) => {
        if (value === 'ย้ายไฟแนนซ์') {
            setMoney({ ...money, productLoanType: value, proposalBy: user, approvalStatus: 16, productType: checkPCH.PLorCH })
            setMoneyOld({ ...moneyOld, productLoanType: value })
            setTypeMoney({ ...typeMoney, productLoanType: 'ย้ายไฟแนนซ์', productType: checkPCH.PLorCH })
        }
        else {
            setMoney({ ...money, productLoanType: value, proposalBy: user, approvalStatus: 16, productType: checkPCH.PLorCH })
            setTypeMoney({ ...typeMoney, productLoanType: 'ถือเล่มมา', productType: checkPCH.PLorCH })
            setMoneyOld('')
            form.setFieldsValue(
                {
                    oldKangPayment: '',
                    oldKangPaymentTerm: '',
                    oldLoanAmount: '',
                    oldLoanTerm: '',
                    oldMonthlyPayment: '',
                }
            )
        }
    }
    //Select p-loan or Hire purchase
    const handleChangePLorCH = (value) => {
        if (value === "p-loan") {
            setCheckPCH({ PLorCH: value })
            setMoney({
                ...money, loanAmountt: "",
                installmentAmountt: "",
                interestt: "",
                interestRate: "",
                monthlyPayment: "",
                installmentWithInterestt: "",
                PLorCH: value,
                productType: value
            })
            form.setFieldsValue(
                {
                    loanAmountt: "",
                    installmentAmountt: "",
                    interestt: "",
                    interestRate: "",
                    monthlyPayment: "",
                    installmentWithInterestt: "",
                }
            )
        } else {
            setCheckPCH({ PLorCH: value })
            setMoney({
                ...money, loanAmountt: "",
                installmentAmountt: "",
                interestt: "",
                interestRate: "",
                monthlyPayment: "",
                installmentWithInterestt: "",
                PLorCH: value,
                productType: value
            })
            form.setFieldsValue(
                {
                    loanAmountt: "",
                    installmentAmountt: "",
                    interestt: "",
                    interestRate: "",
                    monthlyPayment: "",
                    installmentWithInterestt: "",
                }
            )
        }
    }
    //////////// กรณีไม่มีรถ /////////////////////
    const AddBrand = () => {
        setCounterBrand(true)
        setCounterModel(true)
        setCounterCarBaab(true)
        form.setFieldsValue(
            {
                carBrand: '',
                carModel: '',
                carBaab: '',
                carYear: '',
                carPrice: 0,
            }
        )
    }
    const AddBrands = () => {
        setCounterBrand(false)
        setCounterModel(false)
        setCounterCarBaab(false)
        form.setFieldsValue(
            {
                carBrand: '',
                carModel: '',
                carBaab: '',
                carYear: '',
                carPrice: 0,
            }
        )
    }
    //////////// กรณีไม่มีรถ /////////////////////
    const AddModel = () => {
        setCounterModel(true)
        setCounterCarBaab(true)
        form.setFieldsValue(
            {
                carModel: '',
                carBaab: '',
                carYear: '',
                carPrice: 0,
            }
        )
    }
    const AddModels = () => {
        setCounterModel(false)
        setCounterCarBaab(false)
        form.setFieldsValue(
            {
                carModel: '',
                carBaab: '',
                carYear: '',
                carPrice: 0,
            }
        )
    }
    //////////// กรณีไม่มีรถ /////////////////////
    const AddCarBaab = () => {
        setCounterCarBaab(true)
        form.setFieldsValue(
            {
                carBaab: '',
                carYear: '',
                carPrice: 0,
            }
        )
    }
    const AddCarBaabs = () => {
        setCounterCarBaab(false)
        form.setFieldsValue(
            {
                carBaab: '',
                carYear: '',
                carPrice: 0,
            }
        )
    }
    const onMoChange = (e) => {
        setDataPost2({ ...dataPost2, carModel: e.target.value })
        //setCarSelected(e)
    }
    const onDeChange = (e) => {
        setDataPost2({ ...dataPost2, carBaab: e.target.value })
    }
    const onReChange = (e) => {
        //console.log("Year",e.target.value)
        setDataPost2({ ...dataPost2, carYear: parseInt(e.target.value) })
    }
    ////// ตัวอย่างรถ กับ ตารางค่างวด ////////
    const getTable = () => {
        setOpenTable(true)
    }
    const getTableCar = () => {
        setOpenTableCar(true)
    }
    const getTableHP = () => {
        setOpenTableHP(true)
    }
    const handleModalOk = () => {
        setOpenSave(false);
    };
    const handleModalCancel = () => {
        setOpenSave(false);
    };
    //Value for Get Brands
    const loadDataBrand = async (value) => {
        console.log("value", value)
        setLoading(true)
        await axios.get(`https://shark-app-j9jc9.ondigitalocean.app/ratecars/brand/${value}`)
            .then((res) => {
                setBrandData(res.data)
                setLoading(false)
                form.setFieldsValue(
                    {
                        carBrand: "",
                        carModel: "",
                        carBaab: "",
                        carYear: "",
                        carPrice: 0
                    }
                )
            })
            .catch((err) => console.log(err))
        setLoading(false)
    }
    //Value for Get Models
    const loadDataModel = async (value) => {
        setLoading(true)
        await axios.get(dataModel + value)
            .then((res) => {
                setModelData(res.data)
                setLoading(false)
            })
            .catch((err) => console.log(err))
        setLoading(false)
    }

    const getResult = async (value) => {
        console.log("getResult", value)
        setLoading(true)
        const result = await axios.get(ratecarsmodel,
            { params: { typeId: typeSelected, modelId: value }, })
        setResultData(result.data)
        setLoading(false)
    }

    const onTypeChange = (value) => {
        setDataPost2({ ...dataPost2, productTypeCar: value, carYear: 0, carPrice: 0, carPriceStatus: "0", carInput: dayjs().format("YYYY-MM-DDTHH:mm:ssZ"), carDateStatus: dayjs().format("YYYY-MM-DDTHH:mm:ssZ") })
        setTypeData(value)
        setTypeSelected(value)
        loadDataBrand(value)
        form.setFieldsValue(
            {
                carProvince: "",
                carPlateNumber: "",
                loanAmountt: "",
                installmentAmountt: "",
                interestt: "",
                interestRate: "",
                monthlyPayment: "",
                installmentWithInterestt: "",
                oldLoanAmount: "",
                oldMonthlyPayment: "",
                oldLoanTerm: "",
                oldKangPayment: "",
                oldKangPaymentTerm: ""
            }
        )
    }
    const onBrandChange = (value) => {
        setDataPost2({ ...dataPost2, carBrand: brandData.find((a) => a.idbrand === value).brand })
        loadDataModel(value)
    }
    const onModelChange = (value) => {
        setCounterBrand(false)
        setDataPost2({ ...dataPost2, carModel: modelData.find((a) => a.idmodel_te === value).model })
        getResult(value)
    }
    const onDetailChange = (value) => {
        setDataPost2({ ...dataPost2, carBaab: resultData.find((a) => a.idmodel === value).models })
        getPrice(typeData, value)
    }
    const getPrice = async (typeId, carId) => {
        setLoading(true)
        await axios.get(getprice, { params: { typeId, carId }, })
            .then((res) => {
                if (res.data) {
                    setYearPrice(res.data)
                    setLoading(false)
                }
            })
            .catch((err) => alert('ไม่พบข้อมูล ราคารถ'))
        setLoading(false)
    }
    const onResult = (k, v) => {
        console.log("GG", k)
        console.log("VV", v)
        const pYear = parseInt(v.key)
        if (k) {
            setDataPost2({ ...dataPost2, carPriceStatus: "1", carPrice: k, carYear: pYear, carInput: dayjs().format("YYYY-MM-DDTHH:mm:ssZ"), carDateStatus: dayjs().format("YYYY-MM-DDTHH:mm:ssZ") })
            parseInt(dataPost2.carYear)
            form.setFieldsValue({
                carPrice: v.value
            })

        }
    }
    //Select 1 Car, 2 Land
    const handleProduct = (value) => {
        setSelectProduct({ ...selectProduct, productTypeId: value })
        if (value === 1) {
            dispatch(addLand())
            dispatch(addLoanLand())
            setCheckPCH({ PLorCH: "p-loan" })
            setDataPost2({ ...dataPost2, productTypeId: value, productTypeCar: 1 })
            setDataPostLand({})
            setMoneyOldLand({})
            setTypeMoneyLand({})
            form.setFieldsValue(
                {
                    PLorCH: "p-loan",
                    numberLand: "",
                    numberLandLawang: "",
                    rai: "",
                    workArea: "",
                    squareWaArea: "",
                    landPrice: "",
                    resultLandPrice: "",
                    province: "",
                    district: "",
                    subdistricts: "",
                    zipcode: "",
                    productLoanLandType: "",
                    loanAmounttLand: "",
                    loanLandTerm: "",
                    interestRateLand: "",
                    interesttLand: "",
                    monthlyPaymentLand: "",
                    installmentWithInterestLand: "",
                }
            )
        } else {
            getProvinces()
            dispatch(addCar())
            dispatch(addLoan())
            dispatch(addOldLoan())
            setDataPostLand({
                ...dataPostLand,
                productTypeId: value,
                productTypeLand: 2,
                landInput: dayjs().format("YYYY-MM-DDTHH:mm:ssZ"),
                landDateStatus: dayjs().format("YYYY-MM-DDTHH:mm:ssZ"),
                rai: 0,
                workArea: 0,
                squareWaArea: 0,
                landPrice: 0,
                resultLandPrice: 0
            })
            setDataPost2({})
            setMoneyOld({})
            setTypeMoney({})
            form.setFieldsValue(
                {
                    PLorCH: "",
                    productTypeCar: "",
                    carBrand: "",
                    carModel: "",
                    carBaab: "",
                    carYear: "",
                    carPrice: 0,
                    carTy: "",
                    carProvince: "",
                    carPlateNumber: "",
                    productLoanType: "",
                    loanAmountt: "",
                    installmentAmountt: "",
                    interestt: "",
                    monthlyPayment: "",
                    installmentWithInterestt: "",
                    oldLoanAmount: "",
                    oldMonthlyPayment: "",
                    oldLoanTerm: "",
                    oldKangPayment: "",
                    oldKangPaymentTerm: ""
                }
            )
        }
    }
    //Upload Images
    const handleChange = async ({ fileList }) => {
        //console.log("fileList", fileList)
        setFileList(fileList);
        var imageList = []
        for (const file of fileList) {
            var image = {}
            var filename = file.name
            var image64 = await getBase64(file.originFileObj)
            image.filename = filename
            image.image64 = image64
            image.type = 1
            imageList.push(image)
        }
        setFileListResult1({ ...fileListResult1, imageList });
        //dispatch(addImage(imageList))
    };

    //เพิ่มเล่มรถ
    const handleChangeBook = async ({ fileList }) => {
        //console.log("fileListBook", fileList)
        setFileListBook(fileList);
        var imageListBook = []
        for (const file of fileList) {
            var image = {}
            var filename = file.name
            var image64 = await getBase64(file.originFileObj)
            image.filename = filename
            image.image64 = image64
            image.type = 2
            imageListBook.push(image)
        }
        setFileListResult2({ ...fileListResult2, imageListBook });
        //dispatch(addImage(imageListBook))
    };

    //เพิ่มรูปโฉนด
    const handleChangeDeed = async ({ fileList }) => {
        setFileListBook(fileList);
        var imageListBook = []
        for (const file of fileList) {
            var image = {}
            var filename = file.name
            var image64 = await getBase64(file.originFileObj)
            image.filename = filename
            image.image64 = image64
            image.type = 3
            imageListBook.push(image)
        }
        setFileListResult2({ ...fileListResult2, imageListBook });
        //dispatch(addImage(imageListBook))
    };

    const handleCancel = () => {
        setPreviewVisible(false);
    }
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);
    };

    const errorLand = () => {
        Modal.error({
            title: 'ไม่พบข้อมูล',
            content: 'กรุณาตรวจสอบข้อมูลที่กรอกอีกครั้ง',
        })
    }

    //ดึงข้อมูลการคำนวนที่ดิน
    const handleCheckLand = async () => {
        //const tokens = "eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6IjEiLCJyZWdpc3Rlcl90eXBlX3NlcSI6IjgiLCJ1c2VyX2dyb3VwX2RhdGFfbGlzdCI6IjEsMSwwLDAsMCwwLDAsMSIsInBlcnNvbmFsX2lkIjoiIiwianRpIjoiOTllYmE2OTAtMGFmOC00M2NhLTg2OTktYWUwZDI4N2ZjY2EzIiwiaWF0IjoiMDkvMjcvMjAyMyAwMzoyMDoxNSIsIm5iZiI6MTY5NTc4NDgxNSwiZXhwIjoxNjk1ODcxMjE1LCJpc3MiOiJodHRwczovL2xvY2FsaG9zdDozMDAwMS8iLCJhdWQiOiJMYW5kc01hcHMifQ.Lw3h4tjSbzjscWqkuA9FutwzXPY8UEb49D4ZdsdqKRfR0fwFunY7i4pg5Rdc70Vrw8jDR1qhtQLn5SEqxPguoQ"
        const provids = pvcodeS
        const amphurs = amcodeS
        const landNos = dataPostLand.numberLand
        const result = { pvcode: provids, amcode: amphurs, landNo: landNos }
        //console.log("result =", result)
        const headers = {
            "Content-Type": "application/json",
            // "Authorization": `Bearer ${tokens}`,
        }
        setLoading(true)
        await axios.post(AllData, result, { headers: headers })
            .then((response) => {
                if (response.data) {
                    // console.log("DATA =", response.data.result)
                    // console.log("ไร่ =", parseInt(response.data.result[0].rai))
                    // console.log("งาน =", parseInt(response.data.result[0].ngan))
                    // console.log("ตารางวา =", parseInt(response.data.result[0].wa + "." + response.data.result[0].subwa))
                    // console.log("จังหวัด =", response.data.result[0].provname)
                    // console.log("อำเภอ =", response.data.result[0].amphurname)
                    // console.log("ละติจูด =", response.data.result[0].parcellat)
                    // console.log("ลองติจูด =", response.data.result[0].parcellon)
                    // console.log("เลขระวาง =", response.data.result[0].utm)
                    // console.log("ราคาต่อตารางวา =", parseInt(response.data.result[0].landprice.replace(/,/g, "")))
                    const squareWaArea = parseFloat(response.data.result[0].wa + "." + response.data.result[0].subwa)
                    const landPricePerWa = parseInt(response.data.result[0].landprice.replace(/,/g, ""))
                    const totalWa = (
                        ((parseInt(response.data?.result[0].rai) * 400)) +
                        ((parseInt(response.data?.result[0].ngan) * 100)) +
                        parseFloat(response.data?.result[0].wa + "." + response.data?.result[0].subwa)
                    )
                    const totalPrice = totalWa * landPricePerWa
                    //console.log("รวมตารางวาทั้งหมด =", totalPrice.toFixed(1));
                    setDataPostLand({
                        ...dataPostLand,
                        numberLandLawang: response.data?.result[0].utm,
                        rai: parseInt(response.data?.result[0].rai),
                        workArea: parseInt(response.data?.result[0].ngan),
                        squareWaArea: squareWaArea,
                        landPrice: parseInt(response.data.result[0].landprice.replace(/,/g, "")) || 0,
                        resultLandPrice: totalPrice || 0
                    });
                    form.setFieldsValue(
                        {
                            numberLandLawang: response.data?.result[0].utm,
                            rai: parseInt(response.data?.result[0].rai),
                            workArea: parseInt(response.data?.result[0].ngan),
                            squareWaArea: squareWaArea,
                            landPrice: parseInt(response.data.result[0].landprice.replace(/,/g, "")) || 0,
                            resultLandPrice: totalPrice || 0,
                        }
                    )
                    setLoading(false)
                }
            })
            .catch((err) => errorLand())
            .finally(() => {
                setLoading(false);
            })
        setLoading(false)
    }

    //คำนวน_ที่ดิน
    const land_Calculate = () => {
        if (moneyLand.loanAmounttLand && moneyLand.interestRateLand && moneyLand.loanLandTerm) {
            var interestRateLand = moneyLand.interestRateLand / 100 // อัตราดอก / 100
            var rate = ((moneyLand.loanAmounttLand * Currency(interestRateLand, { precision: 3 }).value) * moneyLand.loanLandTerm) // (ยอดจัด * อัตราดอกที่หาร100แล้ว ,precision: 3 คือทศนิยม 3 )*งวด
            var loanTerm = Math.ceil(Currency((rate + moneyLand.loanAmounttLand) / moneyLand.loanLandTerm, { precision: 2 }).value) // (ดอกเบี้ยทั้งหมด + ยอดจัด)/ งวด
            var resultRate = (loanTerm * moneyLand.loanLandTerm) - moneyLand.loanAmounttLand
            var resultPrice = loanTerm * moneyLand.loanLandTerm
            setMoneyLand({
                ...moneyLand,
                //loanLandTerm: parseInt(event.target.value), //งวด
                monthlyPaymentLand: loanTerm,
                interesttLand: resultRate,
                installmentWithInterestLand: resultPrice
            })
            form.setFieldsValue(
                {
                    monthlyPaymentLand: loanTerm,
                    interesttLand: resultRate,
                    installmentWithInterestLand: resultPrice
                }
            )
        }
        else {
            form.setFieldsValue(
                {
                    monthlyPaymentLand: 0,
                    interesttLand: 0,
                    installmentWithInterestLand: 0
                }
            )
        }
    }

    //คำนวน_รถ
    const car_Calculate = () => {
        if (money.loanAmountt && money.interestRate && money.installmentAmountt) {
            var interestRateCar = money.interestRate / 100 // อัตราดอก / 100
            var rateCar = ((money.loanAmountt * Currency(interestRateCar, { precision: 3 }).value) * money.installmentAmountt) // (ยอดจัด * อัตราดอกที่หาร100แล้ว ,precision: 3 คือทศนิยม 3 )*งวด
            var loanTermCar = Math.ceil(Currency((rateCar + money.loanAmountt) / money.installmentAmountt, { precision: 2 }).value) // (ดอกเบี้ยทั้งหมด + ยอดจัด)/ งวด
            var resultRateCar = (loanTermCar * money.installmentAmountt) - money.loanAmountt
            var resultPriceCar = loanTermCar * money.installmentAmountt
            setMoney({
                ...money,
                monthlyPayment: loanTermCar,
                interestt: resultRateCar,
                installmentWithInterestt: resultPriceCar
            })
            form.setFieldsValue(
                {
                    monthlyPayment: loanTermCar,
                    interestt: resultRateCar,
                    installmentWithInterestt: resultPriceCar
                }
            )
        }
        else {
            form.setFieldsValue(
                {
                    monthlyPayment: 0,
                    interestt: 0,
                    installmentWithInterestt: 0
                }
            )
        }
    }

    const onChangeBack = () => {
        navigate('/addcustomer/careers')
    }
    const onChangeGo = () => {
        navigate('/addcustomer/guarantor')
    }

    return (
        <>
            <Row justify={'center'}>
                <Card style={{ width: '100%' }}>
                    <Row gutter={32} justify={'center'}>
                        <Col className='gutter-row' span={24} style={{ fontSize: '25px', textAlign: 'center' }}>
                            <b>รายละเอียด</b>
                        </Col>
                    </Row>
                    <Divider />
                    <Row justify={'center'}>
                        <Card style={{ width: '900px' }}>
                            <Form
                                name='basic'
                                labelCol={{
                                    span: 8,
                                }}
                                wrapperCol={{
                                    span: 16,
                                }}
                                style={{
                                    maxWidth: 900,
                                }}
                                onFinish={handleSubmit}
                                autoComplete="off"
                                initialValues={{ remember: true }}
                                form={form}
                            >
                                <Row gutter={32} >
                                    <Col className="gutter-row" span={12}>
                                        <Form.Item label='กลุ่มสินค้า' name='productTypeId'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input !'
                                                },]}>
                                            <Select placeholder={'เลือก'}
                                                onChange={(value) => handleProduct(value)}
                                            //onChange={(value) => setDataPost2({ ...dataPost2, productTypeId: value })}
                                            >
                                                <Option value={1}>รถ</Option>
                                                <Option value={2}>ที่ดิน</Option>
                                            </Select>
                                        </Form.Item>
                                        {/* รถ */}
                                        {customers.data && selectProduct?.productTypeId !== 2 && (
                                            <>
                                                <Form.Item label='เรทรถ'>
                                                    <Button type='primary' onClick={getTableCar} >ดู</Button>
                                                    {
                                                        openTableCar ?
                                                            <TableRateCar open={openTableCar} close={setOpenTableCar} />
                                                            : null
                                                    }
                                                </Form.Item>

                                                <Form.Item label='ชนิดรถ' name='productTypeCar'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input !'
                                                        },]}>
                                                    <Select placeholder={'เลือก'} allowClear
                                                        onChange={onTypeChange}>
                                                        <Option value={1}>รถยนต์</Option>
                                                        <Option value={2}>รถเครื่องจักการเกษตร</Option>
                                                        <Option value={3}>รถบรรทุก</Option>
                                                    </Select>
                                                </Form.Item>
                                                <Row gutter={32}>
                                                    <Col className='gutter-row' span={22}>
                                                        <Form.Item label='ยี่ห้อ' name='carBrand' style={{ lineHeight: '32px' }}>
                                                            {!counterBrand ? (
                                                                <Select
                                                                    showSearch
                                                                    onChange={onBrandChange}
                                                                    optionFilterProp="children"
                                                                    placeholder="Search to Select"
                                                                    filterOption={(inputValue, option) =>
                                                                        option.children.join('').toLowerCase().includes(inputValue.toLowerCase())
                                                                    }
                                                                >
                                                                    {brandData?.map((e) => {
                                                                        return (
                                                                            <Select.Option key={e.idbrand} value={e.idbrand}>
                                                                                {e.brand} ({e.brand_th})
                                                                            </Select.Option>
                                                                        )
                                                                    })}
                                                                </Select>
                                                            ) : (<>

                                                                {counterBrand ? (<Input />) : (<Select
                                                                    showSearch
                                                                    onChange={onBrandChange}
                                                                    placeholder="Search to Select"
                                                                    optionFilterProp="children"
                                                                    filterOption={
                                                                        (inputValue, option) =>
                                                                            option.children.join('').toLowerCase().includes(inputValue.toLowerCase())
                                                                    }
                                                                >
                                                                    {modelData?.map((e) => {
                                                                        return (
                                                                            <Select.Option key={e.idmodel_te} value={e.idmodel_te}>
                                                                                {e.model} {e.model_te ? e.model_te : null}
                                                                            </Select.Option>
                                                                        )
                                                                    })}
                                                                </Select>)
                                                                }
                                                            </>

                                                            )}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col className='gutter-row' span={1} style={{ textAlign: 'center' }}>
                                                        {counterBrand === false && (
                                                            <Button onClick={AddBrand} style={{ height: '33px', lineHeight: '15px' }}>
                                                                +
                                                            </Button>)
                                                        }
                                                        {counterBrand === true && (
                                                            <Button onClick={AddBrands} >
                                                                -
                                                            </Button>
                                                        )}
                                                    </Col>
                                                </Row>
                                                <Row gutter={32}>
                                                    <Col className='gutter-row' span={22}>
                                                        <Form.Item label='รุ่นรถ' name='carModel'>
                                                            {counterBrand ? ( //ไม่จริงเข้าตรงนี้
                                                                <>
                                                                    {!counterModel ? (
                                                                        <Select //ไม่จริงเข้าตรงนี้
                                                                            showSearch
                                                                            onChange={onModelChange}
                                                                            placeholder="Search to Select"
                                                                            optionFilterProp="children"
                                                                            filterOption={(inputValue, option) =>
                                                                                option.children.join('').toLowerCase().includes(inputValue.toLowerCase())
                                                                            }
                                                                        >
                                                                            {modelData?.map((e) => {
                                                                                return (
                                                                                    <Select.Option key={e.idmodel_te} value={e.idmodel_te}>
                                                                                        {e.model} {e.model_te ? e.model_te : null}
                                                                                    </Select.Option>
                                                                                )
                                                                            })}
                                                                        </Select>
                                                                    ) :
                                                                        (
                                                                            <Input onChange={onMoChange} />
                                                                        )}
                                                                </>
                                                            ) : (<>

                                                                {counterModel ? (<Input onChange={onMoChange} />) : (<Select
                                                                    showSearch
                                                                    onChange={onModelChange}
                                                                    placeholder="Search to Select"
                                                                    optionFilterProp="children"
                                                                    filterOption={
                                                                        (inputValue, option) =>
                                                                            option.children.join('').toLowerCase().includes(inputValue.toLowerCase())
                                                                    }
                                                                >
                                                                    {modelData?.map((e) => {
                                                                        return (
                                                                            <Select.Option key={e.idmodel_te} value={e.idmodel_te}>
                                                                                {e.model} {e.model_te ? e.model_te : null}
                                                                            </Select.Option>
                                                                        )
                                                                    })}
                                                                </Select>)
                                                                }
                                                            </>
                                                            )}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col className='gutter-row' span={1} style={{ textAlign: 'center' }}>
                                                        {counterModel === false && (
                                                            <Button onClick={AddModel} style={{ height: '33px', lineHeight: '15px' }}>
                                                                +
                                                            </Button>)
                                                        }
                                                        {counterModel === true && (
                                                            <Button onClick={AddModels} >
                                                                -
                                                            </Button>
                                                        )}
                                                    </Col>
                                                </Row>
                                                <Row gutter={32}>
                                                    <Col className='gutter-row' span={22}>
                                                        <Form.Item label='แบบรถ' name='carBaab'>
                                                            {counterBrand ? (
                                                                <>
                                                                    {!counterCarBaab ? (
                                                                        <Select
                                                                            showSearch
                                                                            onChange={(value) => { onDetailChange(value) }}
                                                                            placeholder="Search to Select"

                                                                        >
                                                                            {resultData?.map((e) => {
                                                                                return (
                                                                                    <Select.Option key={e.idmodel} value={e.idmodel}>
                                                                                        {e.models} {e.idmodel ? e.idmodel : null}
                                                                                    </Select.Option>
                                                                                )
                                                                            })}
                                                                        </Select>
                                                                    ) :
                                                                        (
                                                                            <Input onChange={onDeChange} />
                                                                        )}
                                                                </>
                                                            ) : (<>

                                                                {counterCarBaab ? (<Input onChange={onDeChange} />) : (<Select
                                                                    showSearch
                                                                    onChange={(value) => { onDetailChange(value) }}
                                                                    placeholder="Search to Select"
                                                                >
                                                                    {resultData?.map((e) => {
                                                                        return (
                                                                            <Select.Option key={e.idmodel} value={e.idmodel}>
                                                                                {e.models} {e.idmodel ? e.idmodel : null}
                                                                            </Select.Option>
                                                                        )
                                                                    })}
                                                                </Select>)
                                                                }
                                                            </>
                                                            )}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col className='gutter-row' span={1} style={{ textAlign: 'center' }}>
                                                        {counterCarBaab === false && (
                                                            <Button onClick={AddCarBaab} style={{ height: '33px', lineHeight: '15px' }}>
                                                                +
                                                            </Button>)
                                                        }
                                                        {counterCarBaab === true && (
                                                            <Button onClick={AddCarBaabs} >
                                                                -
                                                            </Button>
                                                        )}
                                                    </Col>
                                                </Row>
                                                <Form.Item label='ปีรถ' name='carYear' >
                                                    {counterBrand ? (
                                                        <>
                                                            {!counterCarBaab ? (
                                                                <Select
                                                                    onChange={(k, v) => (onResult(k, v))}
                                                                >
                                                                    {Object.entries(yearPrice).map(([k, v]) => {
                                                                        if (k.charAt(0) === 'p' && v) {
                                                                            const year = k.replace('p', '')
                                                                            return (
                                                                                <Select.Option key={year} value={v * 1000}>
                                                                                    {year}
                                                                                </Select.Option>
                                                                            )
                                                                        }
                                                                    })}
                                                                </Select>
                                                            ) :
                                                                (
                                                                    <>
                                                                        {/* <Input onChange={onReChange} /> */}
                                                                        < Select
                                                                            onChange={(k, v) => (onResult(k, v))}
                                                                        >
                                                                            {Object.entries(yearPrice).map(([k, v]) => {
                                                                                if (k.charAt(0) === 'p' && v) {
                                                                                    const year = k.replace('p', '')
                                                                                    return (
                                                                                        <Select.Option key={year} value={v * 1000}>
                                                                                            {year}
                                                                                        </Select.Option>
                                                                                    )
                                                                                }
                                                                            })}
                                                                        </Select>
                                                                    </ >
                                                                )}

                                                        </>
                                                    )
                                                        :
                                                        (
                                                            <>
                                                                {counterCarBaab ? (
                                                                    <Input onChange={onReChange} />
                                                                ) : (
                                                                    <Select
                                                                        onChange={(k, v) => (onResult(k, v))}
                                                                    >
                                                                        {Object.entries(yearPrice).map(([k, v]) => {
                                                                            if (k.charAt(0) === 'p' && v) {
                                                                                const year = k.replace('p', '')
                                                                                return (
                                                                                    <Select.Option key={year} value={v * 1000}>
                                                                                        {year}
                                                                                    </Select.Option>
                                                                                )
                                                                            }
                                                                        })}
                                                                    </Select>
                                                                )}
                                                            </>
                                                        )}
                                                </Form.Item>

                                                <Form.Item label='ราคา' name='carPrice'>
                                                    <Input disabled defaultValue={0}
                                                        onChange={(e) => setDataPost2({ ...dataPost2, carPrice: e.value })} />
                                                </Form.Item>

                                                <Form.Item label='เลขทะเบียน' name='carPlateNumber'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input !',
                                                        },]}>
                                                    <Input
                                                        onChange={(e) => setDataPost2({ ...dataPost2, carPlateNumber: e.target.value })} />
                                                </Form.Item>
                                                <Form.Item label='จังหวัด' name='carProvince'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input !',
                                                        },]}>
                                                    <Select
                                                        showSearch
                                                        name='carProvince'
                                                        placeholder='จังหวัด'
                                                        onChange={(key, value) => { handleProvinceChangeCar(key, value) }}
                                                    >
                                                        {provincesCar?.map((pro, index) => (
                                                            <Option key={pro.provinceId} value={pro.provinceName}>
                                                                {pro.provinceName}
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>

                                                <Form.Item label='เลขคัสซี' name='carChassisNumber'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input !',
                                                        },]}>
                                                    <Input
                                                        onChange={(e) => setDataPost2({ ...dataPost2, carChassisNumber: e.target.value })} />
                                                </Form.Item>
                                                <Form.Item label='เลขเครื่อง' name='carEngineNumber'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input !',
                                                        },]}>
                                                    <Input
                                                        onChange={(e) => setDataPost2({ ...dataPost2, carEngineNumber: e.target.value })} />
                                                </Form.Item>
                                                <Form.Item label='สี' name='carColor'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input !',
                                                        },]}>
                                                    <Input
                                                        onChange={(e) => setDataPost2({ ...dataPost2, carColor: e.target.value })} />
                                                </Form.Item>
                                                {/* <Form.Item label="ประเภทรถ" name="carTy">
                                                    <Radio.Group onChange={(e) => setDataPost2({ ...dataPost2, carTy: e.target.value })} >
                                                        <Radio value="ปกติ"> ปกติ </Radio>
                                                        <Radio value="รถแต่ง"> รถแต่ง </Radio>
                                                        <Radio value="รถอุบัติเหตุ"> รถอุบัติเหตุ </Radio>
                                                    </Radio.Group>
                                                </Form.Item> */}
                                                <Form.Item label='เพิ่มรูปภาพรถ'>
                                                    <Form
                                                        autoComplete="off"
                                                        encType='mutipart/form-data'
                                                    >
                                                        <Upload
                                                            multiple={true}
                                                            listType="picture-card"
                                                            fileList={fileList}
                                                            onPreview={handlePreview}
                                                            onChange={handleChange}
                                                            beforeUpload={() => false}
                                                        >
                                                            {fileList.length >= 8 ? null : (
                                                                <div>
                                                                    <PlusOutlined />
                                                                    <div className="ant-upload-text">upload</div>
                                                                </div>
                                                            )}
                                                        </Upload>
                                                        <Modal open={previewVisible} onCancel={handleCancel}>
                                                            <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                        </Modal>
                                                    </Form>
                                                </Form.Item>
                                                <Form.Item label='เพิ่มเล่มรถ'>
                                                    <Form
                                                        autoComplete="off"
                                                        encType='mutipart/form-data'
                                                    >
                                                        <Upload
                                                            multiple={true}
                                                            listType="picture-card"
                                                            fileList={fileListBook}
                                                            onPreview={handlePreview}
                                                            onChange={handleChangeBook}
                                                            beforeUpload={() => false}
                                                        >
                                                            {fileList.length >= 8 ? null : (
                                                                <div>
                                                                    <PlusOutlined />
                                                                    <div className="ant-upload-text">upload</div>
                                                                </div>
                                                            )}
                                                        </Upload>
                                                        <Modal open={previewVisible} onCancel={handleCancel}>
                                                            <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                        </Modal>
                                                    </Form>
                                                </Form.Item>
                                            </>
                                        )}
                                        {/* ที่ดิน */}
                                        {customers.data && selectProduct?.productTypeId === 2 && (
                                            <>
                                                <Form.Item name='province' label='จังหวัด'>
                                                    <Select
                                                        showSearch
                                                        loading={loading}
                                                        name='province'
                                                        placeholder="จังหวัด"
                                                        onChange={handleProvinceChange}
                                                    >
                                                        {provinces?.result?.map((pro, index) => (
                                                            <Option key={pro.pvcode} value={pro.pvnamethai}>
                                                                {pro.pvnamethai}
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>

                                                <Form.Item name='district' label='อำเภอ'>
                                                    <Select
                                                        showSearch
                                                        loading={loading}
                                                        name='district'
                                                        placeholder="อำเภอ"
                                                        onChange={handleDistrictChange}
                                                    >
                                                        {district?.map((dis, index) => (
                                                            <Option key={dis.amcode} value={dis.amnamethai}>
                                                                {dis.amnamethai}
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                                <Form.Item label='เลขโฉนดที่ดิน' name='numberLand'>
                                                    <Input type='text'
                                                        onChange={(e) => setDataPostLand({ ...dataPostLand, numberLand: e.target.value })}></Input>
                                                </Form.Item>
                                                <Form.Item label='ตรวจสอบ'>
                                                    <Button type="primary" onClick={handleCheckLand}>ตรวจสอบ</Button>
                                                </Form.Item>

                                                <Form.Item label='เลขที่ดินหรือระวาง' name='numberLandLawang'>
                                                    <Input type='text' disabled
                                                        onChange={(e) => setDataPostLand({ ...dataPostLand, numberLandLawang: e.target.value })}></Input>
                                                </Form.Item>

                                                <Form.Item label='พื้นที่ไร่' name='rai'>
                                                    <Input type='text' suffix="ไร่" disabled
                                                        onChange={(e) => setDataPostLand({ ...dataPostLand, rai: parseInt(e.target.value) })}></Input>
                                                </Form.Item>

                                                <Form.Item label='พื้นที่งาน' name='workArea'>
                                                    <Input type='text' suffix="งาน" disabled
                                                        onChange={(e) => setDataPostLand({ ...dataPostLand, workArea: parseInt(e.target.value) })}></Input>
                                                </Form.Item>

                                                <Form.Item label='พื้นที่ตารางวา' name='squareWaArea'>
                                                    <Input type='text' suffix="ตารางวา" disabled
                                                        onChange={(e) => setDataPostLand({ ...dataPostLand, squareWaArea: parseInt(e.target.value) })}></Input>
                                                </Form.Item>

                                                <Form.Item label='ราคาประเมินที่ดิน' name='landPrice'>
                                                    <Input type='text' suffix="บาทต่อตารางวา" disabled
                                                        onChange={(e) => setDataPostLand({ ...dataPostLand, landPrice: parseInt(e.target.value) })}></Input>
                                                </Form.Item>

                                                <Form.Item label='ราคารวม' name='resultLandPrice'>
                                                    <Input type='text' suffix="บาท" disabled
                                                        onChange={(e) => setDataPostLand({ ...dataPostLand, resultLandPrice: parseInt(e.target.value) })}></Input>
                                                </Form.Item>



                                                <Form.Item label='เพิ่มรูปภาพที่ดิน'>
                                                    <Upload
                                                        multiple={true}
                                                        listType="picture-card"
                                                        fileList={fileList}
                                                        onPreview={handlePreview}
                                                        onChange={handleChange}
                                                        beforeUpload={() => false}
                                                    >
                                                        {fileList.length >= 10 ? null : (
                                                            <div>
                                                                <PlusOutlined />
                                                                <div className="ant-upload-text">upload</div>
                                                            </div>
                                                        )}
                                                    </Upload>
                                                    <Modal open={previewVisible} onCancel={handleCancel}>
                                                        <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                    </Modal>

                                                </Form.Item>
                                                <Form.Item label='เพิ่มรูปภาพโฉนด'>
                                                    <Upload
                                                        multiple={true}
                                                        listType="picture-card"
                                                        fileList={fileListBook}
                                                        onPreview={handlePreview}
                                                        onChange={handleChangeDeed}
                                                        beforeUpload={() => false}
                                                    >
                                                        {fileList.length >= 10 ? null : (
                                                            <div>
                                                                <PlusOutlined />
                                                                <div className="ant-upload-text">upload</div>
                                                            </div>
                                                        )}
                                                    </Upload>
                                                    <Modal open={previewVisible} onCancel={handleCancel}>
                                                        <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                    </Modal>

                                                </Form.Item>
                                            </>
                                        )}
                                    </Col>
                                    <Col className="gutter-row" span={12}>
                                        {/* โลนรถ */}
                                        {customers.data && selectProduct?.productTypeId !== 2 && (
                                            <>
                                                <Form.Item label='ประเภทขอกู้รถ' name='productLoanType'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input !',
                                                        },]}>
                                                    <Select
                                                        placeholder={'เลือก'}
                                                        onChange={(value) => { handleChangeType(value) }}
                                                        options={productLoanTypeCar}
                                                    />
                                                </Form.Item>

                                                <Form.Item label='ประเภทค่างวด' name='PLorCH'>
                                                    <Select
                                                        placeholder={'เลือก'}
                                                        onChange={(value) => { handleChangePLorCH(value) }}
                                                        options={[
                                                            {
                                                                label: 'P-LOAN',
                                                                value: 'p-loan',
                                                            },
                                                            {
                                                                label: 'เช่าซื้อ',
                                                                value: 'เช่าซื้อ',
                                                            }
                                                        ]}
                                                    />
                                                </Form.Item>
                                                {/* P-LOAN */}
                                                {checkPCH && typeof checkPCH.PLorCH !== 'undefined' && (checkPCH.PLorCH === "p-loan") ? (
                                                    <>
                                                        <Form.Item label='P-LOAN'>
                                                            <Button type="primary" onClick={getTable}>
                                                                ตารางค่างวด P-LOAN
                                                            </Button>
                                                            {
                                                                openTable ?
                                                                    <Installment open={openTable} close={setOpenTable} money={handleMoneyHP} />
                                                                    : null
                                                            }
                                                        </Form.Item>

                                                        <Form.Item label='ยอดกู้' name='loanAmountt'>
                                                            <Input disabled style={{ color: 'black' }}
                                                                onChange={(e) => setMoney({ ...money, loanAmountt: e.target.value })} />
                                                        </Form.Item>

                                                        <Form.Item label='จำนวนงวด' name='installmentAmountt'>
                                                            <Input disabled style={{ color: 'black' }}
                                                                onChange={(e) => setMoney({ ...money, installmentAmountt: e.target.value })} />
                                                        </Form.Item>

                                                        <Form.Item label='ดอกเบี้ย' name='interestt'>
                                                            <Input disabled style={{ color: 'black' }}
                                                                onChange={(e) => setMoney({ ...money, interestt: e.target.value })} />
                                                        </Form.Item>

                                                        <Form.Item label='จำนวนผ่อนต่อเดือน' name='monthlyPayment'>
                                                            <Input disabled style={{ color: 'black' }}
                                                                onChange={(e) => setMoney({ ...money, monthlyPayment: e.target.value })} />
                                                        </Form.Item>

                                                        <Form.Item label='ผ่อนรวมดอกเบี้ย' name='installmentWithInterestt'>
                                                            <Input disabled style={{ color: 'black' }}
                                                                onChange={(e) => setMoney({ ...money, installmentWithInterestt: e.target.value })} />
                                                        </Form.Item>
                                                    </>
                                                ) : null}
                                                {/* เช่าซื้อ */}
                                                {dataPost2 && typeof dataPost2.productTypeCar !== 'undefined' && (dataPost2.productTypeCar === 1 && checkPCH.PLorCH === "เช่าซื้อ") ? (
                                                    <>
                                                        <Form.Item label='เช่าซื้อ'>
                                                            <Button type="primary" onClick={getTableHP}>
                                                                ตารางค่างวด เช่าซื้อ
                                                            </Button>
                                                            {
                                                                openTableHP ?
                                                                    <HirePurchase open={openTableHP} close={setOpenTableHP} money={handleMoneyHP} />
                                                                    : null
                                                            }
                                                        </Form.Item>

                                                        <Form.Item label='ยอดกู้เช่าซื้อ' name='loanAmountt'>
                                                            <Input disabled style={{ color: 'black' }}
                                                                onChange={(e) => setMoney({ ...money, loanAmountt: e.target.value })} />
                                                        </Form.Item>

                                                        <Form.Item label='จำนวนงวดเช่าซื้อ' name='installmentAmountt'>
                                                            <Input disabled style={{ color: 'black' }}
                                                                onChange={(e) => setMoney({ ...money, installmentAmountt: e.target.value })} />
                                                        </Form.Item>

                                                        <Form.Item label='ดอกเบี้ยเช่าซื้อ' name='interestt'>
                                                            <Input disabled style={{ color: 'black' }}
                                                                onChange={(e) => setMoney({ ...money, interestt: e.target.value })} />
                                                        </Form.Item>

                                                        <Form.Item label='จำนวนผ่อนต่อเดือนเช่าซื้อ' name='monthlyPayment'>
                                                            <Input disabled style={{ color: 'black' }}
                                                                onChange={(e) => setMoney({ ...money, monthlyPayment: e.target.value })} />
                                                        </Form.Item>

                                                        <Form.Item label='ผ่อนรวมดอกเบี้ยเช่าซื้อ' name='installmentWithInterestt'>
                                                            <Input disabled style={{ color: 'black' }}
                                                                onChange={(e) => setMoney({ ...money, installmentWithInterestt: e.target.value })} />
                                                        </Form.Item>
                                                    </>
                                                ) : null}
                                                {dataPost2 && typeof dataPost2.productTypeCar !== 'undefined' && (dataPost2.productTypeCar === 2 && checkPCH.PLorCH === "เช่าซื้อ") ? (
                                                    <>
                                                        <Form.Item label='ยอดกู้' name='loanAmountt'
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please input !'
                                                                },]}>
                                                            <Input
                                                                onChange={(e) => setMoney({ ...money, loanAmountt: parseInt(e.target.value) })} />
                                                        </Form.Item>

                                                        <Form.Item label='จำนวนงวด' name='installmentAmountt'
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please input !'
                                                                },]}>
                                                            <Input
                                                                onChange={(e) => setMoney({ ...money, installmentAmountt: parseInt(e.target.value) })} />
                                                        </Form.Item>

                                                        <Form.Item label='ดอกเบี้ย' name='interestt'
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please input !'
                                                                },]}>
                                                            <Input
                                                                onChange={(e) => setMoney({ ...money, interestt: parseInt(e.target.value) })} />
                                                        </Form.Item>

                                                        <Form.Item label='จำนวนผ่อนต่อเดือน' name='monthlyPayment'
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please input !'
                                                                },]}>
                                                            <Input
                                                                onChange={(e) => setMoney({ ...money, monthlyPayment: parseInt(e.target.value) })} />
                                                        </Form.Item>

                                                        <Form.Item label='ผ่อนรวมดอกเบี้ย' name='installmentWithInterestt'
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please input !'
                                                                },]}>
                                                            <Input
                                                                onChange={(e) => setMoney({ ...money, installmentWithInterestt: parseInt(e.target.value) })} />
                                                        </Form.Item>
                                                    </>
                                                ) : null}
                                                {dataPost2 && typeof dataPost2.productTypeCar !== 'undefined' && (dataPost2.productTypeCar === 3 && checkPCH.PLorCH === "เช่าซื้อ") ? (
                                                    <>
                                                        <Form.Item label='ยอดกู้' name='loanAmountt'
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please input !'
                                                                },]}>
                                                            <Input
                                                                onChange={(e) => setMoney({ ...money, loanAmountt: parseInt(e.target.value) })} />
                                                        </Form.Item>

                                                        <Form.Item label='จำนวนงวด' name='installmentAmountt'
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please input !'
                                                                },]}>
                                                            <Input
                                                                onChange={(e) => setMoney({ ...money, installmentAmountt: parseInt(e.target.value) })} />
                                                        </Form.Item>

                                                        <Form.Item label='ดอกเบี้ย' name='interestt'
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please input !'
                                                                },]}>
                                                            <Input
                                                                onChange={(e) => setMoney({ ...money, interestt: parseInt(e.target.value) })} />
                                                        </Form.Item>

                                                        <Form.Item label='จำนวนผ่อนต่อเดือน' name='monthlyPayment'
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please input !'
                                                                },]}>
                                                            <Input
                                                                onChange={(e) => setMoney({ ...money, monthlyPayment: parseInt(e.target.value) })} />
                                                        </Form.Item>

                                                        <Form.Item label='ผ่อนรวมดอกเบี้ย' name='installmentWithInterestt'
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please input !'
                                                                },]}>
                                                            <Input
                                                                onChange={(e) => setMoney({ ...money, installmentWithInterestt: parseInt(e.target.value) })} />
                                                        </Form.Item>
                                                    </>
                                                ) : null}
                                                {moneyOld && moneyOld.productLoanType === 'ย้ายไฟแนนซ์' ? (
                                                    <>
                                                        <Form.Item label='ยอดปิดไฟแนนซ์' name='oldLoanAmount'
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please input !'
                                                                },]}>
                                                            <Input suffix="บาท"
                                                                onChange={(e) => setMoneyOld({ ...moneyOld, oldLoanAmount: parseInt(e.target.value) })} />
                                                        </Form.Item>

                                                        <Form.Item label='ค่างวด/เดือน' name='oldMonthlyPayment'
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please input !'
                                                                },]}>
                                                            <Input suffix="บาท"
                                                                onChange={(e) => setMoneyOld({ ...moneyOld, oldMonthlyPayment: parseInt(e.target.value) })} />
                                                        </Form.Item>

                                                        <Form.Item label='จำนวนผ่อนกี่งวด' name='oldLoanTerm'
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please input !'
                                                                },]}>
                                                            <Input suffix="งวด"
                                                                onChange={(e) => setMoneyOld({ ...moneyOld, oldLoanTerm: parseInt(e.target.value) })} />
                                                        </Form.Item>

                                                        <Form.Item label='ยอดที่ค้างทั้งหมด' name='oldKangPayment'
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please input !'
                                                                },]}>
                                                            <Input suffix="บาท"
                                                                onChange={(e) => setMoneyOld({ ...moneyOld, oldKangPayment: parseInt(e.target.value) })} />
                                                        </Form.Item>

                                                        <Form.Item label='ค้างกี่งวด' name='oldKangPaymentTerm'
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please input !'
                                                                },]}>
                                                            <Input suffix="งวด"
                                                                onChange={(e) => setMoneyOld({ ...moneyOld, oldKangPaymentTerm: parseInt(e.target.value) })} />
                                                        </Form.Item>
                                                    </>
                                                ) : null}
                                            </>
                                        )}
                                        {/* โลนที่ดิน */}
                                        {customers.data && selectProduct?.productTypeId === 2 && (
                                            <>
                                                <Form.Item label='ประเภทขอกู้ที่ดิน' name='productLoanLandType'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input !',
                                                        },]}>
                                                    <Select
                                                        placeholder={'เลือก'}
                                                        onChange={(value) => { handleChangeLandType(value) }}
                                                        options={productLoanTypeLand}
                                                    />
                                                </Form.Item>

                                                <Form.Item label='ยอดจัด' name='loanAmounttLand'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input !'
                                                        },]}
                                                >
                                                    <Input type='number' suffix="บาท" onChange={(e) => setMoneyLand({ ...moneyLand, loanAmounttLand: parseInt(e.target.value) })} />
                                                </Form.Item>
                                                <Form.Item label='อัตราดอกเบี้ย' name='interestRateLand'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input !'
                                                        },]}>
                                                    <Input type='number' suffix="% +VAT"
                                                        onChange={(e) => setMoneyLand({ ...moneyLand, interestRateLand: parseFloat(e.target.value) })} />
                                                    {/* onChange={(ev) => land_Calculate(ev)} /> */}
                                                </Form.Item>
                                                <Form.Item label='จำนวนงวด' name='loanLandTerm'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input !'
                                                        },]}
                                                >
                                                    <Input onChange={(e) => setMoneyLand({ ...moneyLand, loanLandTerm: parseInt(e.target.value) })} />
                                                </Form.Item>
                                                <Form.Item label='งวดละ' name='monthlyPaymentLand'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input !'
                                                        },]}>
                                                    <Input type='number' suffix="บาท" disabled style={{ color: 'black' }}
                                                        onChange={(e) => setMoneyLand({ ...moneyLand, monthlyPaymentLand: parseInt(e.target.value) })} />
                                                </Form.Item>
                                                <Form.Item label='ดอกเบี้ย' name='interesttLand'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input !'
                                                        },]}
                                                >
                                                    <Input type='number' suffix="บาท" onChange={(e) => setMoneyLand({ ...moneyLand, interesttLand: parseInt(e.target.value) })} />
                                                </Form.Item>
                                                <Form.Item label='รวมราคา' name='installmentWithInterestLand'>
                                                    <Input disabled style={{ color: 'black' }} suffix="บาท"
                                                        onChange={(e) => setMoney({ ...money, installmentWithInterestLand: e.target.value })} />
                                                </Form.Item>

                                            </>
                                        )}
                                        <Form.Item label='ผู้เสนอเคส' name='proposalBy'>
                                            <Input disabled style={{ color: 'black' }}
                                                onChange={(e) => setMoney({ ...money, proposalBy: e.target.value })} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row justify={'center'} gutter={32}>
                                    <Form.Item style={{ textAlign: 'center' }}>
                                        <Button
                                            style={{ margin: '5px', background: '#597ef7', color: '#ffffff' }}
                                            type="primary"
                                            onClick={showModal}
                                            icon={<SaveOutlined />}
                                        >
                                            บันทึก
                                        </Button>
                                        <Modal title="ต้องการบันทึกข้อมูลใช่หรือไม่" open={opensave} onOk={handleModalOk} onCancel={handleModalCancel}
                                            footer={[
                                                <Button
                                                    style={{ margin: '5px', background: '#cf1322', color: '#ffffff' }}
                                                    // htmlType='submit'
                                                    type="primary"
                                                    onClick={handleModalCancel}
                                                    icon={<DeleteOutlined />}
                                                >
                                                    ยกเลิก
                                                </Button>,
                                                <Button
                                                    style={{ margin: '5px', background: '#597ef7', color: '#ffffff' }}
                                                    htmlType='submit'
                                                    type="primary"
                                                    onClick={handleSubmit}
                                                    icon={<SaveOutlined />}
                                                >
                                                    ยืนยัน
                                                </Button>
                                            ]}
                                        >
                                        </Modal>
                                    </Form.Item>
                                </Row>
                            </Form>
                        </Card>
                    </Row>
                    <Divider style={{ margin: '5px' }} />
                    <Row
                        gutter={32}
                        justify={'center'}
                    >
                        <Col className='gutter-row' span={12} style={{ textAlign: 'left' }}>
                            <Button
                                style={{ margin: '5px', background: '#bfbfbf' }}
                                htmlType='submit'
                                type="text"
                                onClick={onChangeBack}
                            >
                                ย้อนกลับ
                            </Button>
                        </Col>
                        <Col className='gutter-row' span={12} style={{ textAlign: 'right' }}>
                            <Button
                                style={{ margin: '5px' }}
                                htmlType='submit'
                                type="primary"
                                onClick={onChangeGo}
                            >
                                ต่อไป
                            </Button>
                        </Col>
                    </Row>
                </Card>
                {contextHolder}
            </Row >

        </>
    )
};
export default Product
