import { Form, Card, Col, Row, Spin, InputNumber, Divider, Select, notification, Button, Space } from 'antd';
import React, { useState, useEffect } from 'react'
import '../../css/Media.css'
import axios from 'axios';
import dayjs from 'dayjs';
import BrokerNew from '../../offer_case/savecustomer/BrokerNew';
import EditBrokerModal from './EditBrokerModal';
import { detailBroker, customerbroker, payBroker } from '../../file_mid/all_api';

export default function EditBroker({ getData, sendback, close }) {
    const [api, contextHolder] = notification.useNotification();
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()
    const [form2] = Form.useForm()
    const [dataBroker, setDataBroker] = useState([])
    const [customerBroker, setCustomerBroker] = useState([]);
    const [modalBrokerNew, setModalBrokerNew] = useState(false)
    const [dataedit, setDataEdit] = useState();
    const [typeAmt, setTypeAmt] = useState();
    const [modaleditdata, setModalEditData] = useState(false);
    const [check630, setCheck630] = useState(true);
    const [check627, setCheck627] = useState(true);
    const [dataPost, setDataPost] = useState({
        "loanId": getData?.cars?.carLoanDetails?.loanId,
        "cuscod": getData?.identificationId,
        "carLandId": getData?.cars?.carId,
        "cusBroker": null,
        "amount": 0,
        "approvalService": 0
    })

    useEffect(() => {
        setCheck630(true)
        setCheck627(true)
        loadCustomerBroker()
    }, [getData, modalBrokerNew, modaleditdata])

    const loadCustomerBroker = async () => {
        setLoading(true)
        await axios.get(customerbroker)
            .then(async response => {
                setCustomerBroker(response.data);
                await loadBroker()
            }).catch(error => {
                console.error(error);
            });
    }

    const loadBroker = async () => {
        const result = { loanId: getData?.cars?.carLoanDetails?.loanId }
        await axios.post(detailBroker, result)
            .then((res) => {
                if (res.data) {
                    setDataBroker(res.data)
                    res.data.map((item) => {
                        if (item?.payfor === "630") {
                            setCheck630(false)
                        }
                        else if (item?.payfor === "627") {
                            setCheck627(false)
                        } else {
                            setCheck630(true)
                            setCheck627(true)
                        }
                    })
                }
                setLoading(false)
            })
            .catch((err) => console.log(err))
        setLoading(false)
    }

    const SuccSend = (placement) => {
        api.success({
            message: <b>แก้ไขข้อมูลสำเร็จ</b>,
            placement,
        });
    }

    const SuccSend2 = (placement) => {
        api.success({
            message: <b>บันทึกข้อมูลสำเร็จ</b>,
            placement,
        });
    }

    const handleSubmit = async () => {
        setLoading(true)
        if (dataPost?.cusBroker != null || dataPost?.amount > 0 || dataPost?.approvalService > 0) {
            await axios.post(payBroker, dataPost)
                .then(response => {
                    setCheck630(false)
                    setCheck627(false)
                    setDataPost({
                        "loanId": getData?.cars?.carLoanDetails?.loanId,
                        "cuscod": getData?.identificationId,
                        "carLandId": getData?.cars?.carId,
                        "cusBroker": null,
                        "amount": 0,
                        "approvalService": 0
                    })
                    sendback(dayjs())
                    SuccSend2("top")
                    console.log("ok data", response.data)
                }).catch(error => {
                    console.error(error);
                });
        } else {
            sendback(dayjs())
            SuccSend("top")
        }
        setLoading(false)
    }
    const currencyFormatOne = (amount) => {
        if (amount) {
            return Number(amount)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, '$&,')
        } else {
            return 0
        }
    }
    const onBroker = async (value) => {
        setDataPost({ ...dataPost, cusBroker: value })
    }
    const handleCancel1 = () => {
        close();
    }
    const handleEdits = (value, number) => {
        setDataEdit(value)
        setTypeAmt(number)
        setModalEditData(true);
    }
    const addBroker = () => {
        setModalBrokerNew(true)
    }

    const renderBroker630 = ({ item, index }) => {
        const handleEdit = (dataedit, number) => {
            setDataEdit(dataedit)
            setTypeAmt(number)
            setModalEditData(true);
        }
        return (
            <>
                <Form
                    labelCol={{
                        span: 12,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    style={{
                        maxWidth: '100%',
                    }}
                    form={form2}
                    onFinish={handleSubmit}
                    autoComplete="off"
                    initialValues={{ remember: true }}
                >
                    {
                    item.payfor === "630" ?
                        <Card key={index} style={{ width: '100%', textAlign: 'center' }} >
                            <Col span={24} style={{ textAlign: 'left' }}>
                                <Space>
                                    {/* <Form.Item style={{ marginBottom: 0 }} label='จำนวนเงิน' > */}
                                    <span>จำนวนเงิน:</span><b>{currencyFormatOne(item.payamt)}</b>
                                    {/* </Form.Item> */}
                                    <Button
                                        style={{ background: '#bfbfbf', color: '#000000', marginBottom: 0 }}
                                        type='submit'
                                        onClick={() => { handleEdit(item, index, 630); }}
                                    >แก้ไข</Button>
                                </Space>
                            </Col>
                        </Card>
                        :
                        null
                    }
                </Form>
            </>
        )
    }

    const renderBroker627 = ({ item, index }) => {
        const handleEdit = (dataedit, number) => {
            setDataEdit(dataedit)
            setTypeAmt(number)
            setModalEditData(true);
        }
        return (
            <>
                <Form
                    labelCol={{
                        span: 12,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    style={{
                        maxWidth: '100%',
                    }}
                    form={form2}
                    onFinish={handleSubmit}
                    autoComplete="off"
                    initialValues={{ remember: true }}
                >
                    {item.payfor === "627" ?
                        <Card key={index} style={{ width: '100%', textAlign: 'center' }} >
                            <Col span={24} style={{ textAlign: 'left' }}>
                                <Space>
                                    <span>ค่าอนุมัติ/บริการ:</span><b>{currencyFormatOne(item.payamt)}</b>
                                    <Button
                                        style={{ background: '#bfbfbf', color: '#000000', marginBottom: 0 }}
                                        type='submit'
                                        onClick={() => { handleEdit(item, index, 627); }}
                                    >แก้ไข</Button>
                                </Space>
                            </Col>
                        </Card>
                        :
                        null
                    }
                    {/* <Divider /> */}
                </Form>
            </>
        )
    }
    return (
        <Row justify={'center'}>
            <Spin spinning={loading} size='large' tip=" Loading... ">
                <Row justify={'center'} style={{ margin: '5px', fontSize: '18px' }} >รายละเอียดค่าแนะนำ</Row>
                <Divider />
                <Form
                    labelCol={{
                        span: 12,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    style={{
                        maxWidth: '100%',
                    }}
                    form={form}
                    onFinish={handleSubmit}
                    autoComplete="off"
                    initialValues={{ remember: true }}
                >

                    <Col style={{ textAlign: 'right' }}>
                        <Button type="primary" onClick={addBroker}>
                            เพิ่มผู้แนะนำ
                        </Button>
                    </Col>

                    <Divider />
                    {
                        dataBroker?.length > 0 ?
                            <>
                                {
                                    dataBroker[0]?.idCard !== "" && dataBroker[0]?.idCard !== undefined && dataBroker[0]?.idCard !== null ?
                                        <Col>
                                            <Space>
                                                <span>ผู้แนะนำ:</span><b>{dataBroker[0]?.snam + ' ' + dataBroker[0]?.firstname + ' ' + dataBroker[0]?.lastname}</b>
                                                <Button
                                                    style={{ background: '#bfbfbf', color: '#000000', marginBottom: 0 }}
                                                    type='submit'
                                                    onClick={() => { handleEdits(dataBroker[0], 0); }}
                                                >แก้ไข</Button>
                                            </Space>
                                        </Col>
                                        : null
                                }

                                <Divider />
                                {

                                    dataBroker.map((item, index) => {
                                        return renderBroker630({ item, index, key: `{item.id} - ${index}` })
                                    })
                                }
                                <Divider />
                                {
                                    dataBroker.map((item, index) => {
                                        return renderBroker627({ item, index, key: `{item.id} - ${index}` })
                                    })
                                }
                                <Divider />
                                {
                                    check630 ?
                                        <Row justify={'center'}>
                                            <aside style={{ width: '85%', textAlign: 'center' }}>
                                                <Form.Item label='จำนวนเงิน' name='amount'
                                                    style={{ textAlign: 'center', width: '200px' }}
                                                >
                                                    <InputNumber
                                                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                        size="large"
                                                        style={{ width: '240px', height: '40px', color: 'black' }}
                                                        onChange={(value) => {
                                                            setDataPost({ ...dataPost, amount: parseFloat(value), cusBroker: dataBroker[0].cusBroker });
                                                        }}
                                                    />
                                                </Form.Item>
                                            </aside>
                                            <Divider />
                                        </Row>
                                        : null
                                }
                                {
                                    check627 ?
                                        <Row justify={'center'}>
                                            <aside style={{ width: '85%', textAlign: 'center' }}>
                                                <Form.Item label='ค่าอนุมัติ/บริการ' name='approvalService'
                                                    style={{ textAlign: 'center', width: '200px' }}
                                                >
                                                    <InputNumber
                                                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                        size="large"
                                                        style={{ width: '240px', height: '40px', color: 'black' }}
                                                        onChange={(value) => {
                                                            setDataPost({ ...dataPost, approvalService: parseFloat(value), cusBroker: dataBroker[0].cusBroker });
                                                        }}
                                                    />
                                                </Form.Item>
                                            </aside>
                                            <Divider />
                                        </Row>
                                        : null
                                }

                            </>
                            :

                            <Row justify={'center'}>
                                <aside style={{ width: '85%', textAlign: 'center' }}>
                                    <Form.Item label='ผู้แนะนำ'
                                        name='idBroker'
                                    // style={{ lineHeight: '32px', textAlign: 'center', width: '200px' }}
                                    >
                                        <Select
                                            showSearch
                                            style={{ width: '240px', height: '40px' }}
                                            onChange={onBroker}
                                            optionFilterProp="children"
                                            placeholder="Search to Select"
                                            filterOption={(inputValue, option) =>
                                                option.children.join('').toLowerCase().includes(inputValue.toLowerCase())
                                            }
                                        >
                                            {customerBroker?.map((e) => {
                                                return (
                                                    <Select.Option key={e.idBroker} value={e.idBroker}>
                                                        {e.firstname} {e.lastname}
                                                    </Select.Option>
                                                )
                                            })}
                                        </Select>
                                    </Form.Item>

                                    <Form.Item label='จำนวนเงิน' name='amount'
                                    // style={{ textAlign: 'center', width: '200px' }}
                                    >
                                        <InputNumber
                                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                            size="large"
                                            style={{ width: '240px', height: '40px', color: 'black' }}
                                            onChange={(value) => {
                                                setDataPost({ ...dataPost, amount: parseFloat(value) });
                                            }}
                                        />
                                    </Form.Item>

                                    <Form.Item label='ค่าอนุมัติ/บริการ' name='approvalService'
                                    // style={{ textAlign: 'center', width: '200px' }}
                                    >
                                        <InputNumber
                                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                            size="large"
                                            style={{ width: '240px', height: '40px', color: 'black' }}
                                            onChange={(value) => {
                                                setDataPost({ ...dataPost, approvalService: parseFloat(value) });
                                            }}
                                        />
                                    </Form.Item>

                                    {/* <div>
                                            <>
                                                <Form.Item label='ค่าจัด-โอน' name='transfer' style={{ textAlign: 'center', width: '200px' }}>
                                                    <InputNumber
                                                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                        size="large"
                                                        style={{ width: '240px', height: '40px', color: 'black' }}
                                                        onChange={(value) => {
                                                            setDataPost({ ...dataPost, transfer: parseFloat(value) });
                                                        }}
                                                    />
                                                </Form.Item>

                                                <Form.Item label='ค่าน้ำมัน' name='oil' style={{ textAlign: 'center', width: '200px' }}>
                                                    <InputNumber
                                                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                        size="large"
                                                        style={{ width: '240px', height: '40px', color: 'black' }}
                                                        onChange={(value) => {
                                                            setDataPost({ ...dataPost, oil: parseFloat(value) });
                                                        }}
                                                    />
                                                </Form.Item>

                                                <Form.Item label='ค่าทางด่วนที่ดิน' name='landExpressway' style={{ textAlign: 'center', width: '200px' }}>
                                                    <InputNumber
                                                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                        size="large"
                                                        style={{ width: '240px', height: '40px', color: 'black' }}
                                                        onChange={(value) => {
                                                            setDataPost({ ...dataPost, landExpressway: parseFloat(value) });
                                                        }}
                                                    />
                                                </Form.Item>

                                                <Form.Item label='ค่าเลี่ยงประกัน' name='guarantee' style={{ textAlign: 'center', width: '200px' }}>
                                                    <InputNumber
                                                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                        size="large"
                                                        style={{ width: '240px', height: '40px', color: 'black' }}
                                                        onChange={(value) => {
                                                            setDataPost({ ...dataPost, guarantee: parseFloat(value) });
                                                        }}
                                                    />
                                                </Form.Item>

                                                <Form.Item label='ค่าแจ้งย้าย' name='notificationOfMoving' style={{ textAlign: 'center', width: '200px' }}>
                                                    <InputNumber
                                                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                        size="large"
                                                        style={{ width: '240px', height: '40px', color: 'black' }}
                                                        onChange={(value) => {
                                                            setDataPost({ ...dataPost, notificationOfMoving: parseFloat(value) });
                                                        }}
                                                    />
                                                </Form.Item>

                                                <Form.Item label='ค่าฝากตรวจ' name='inspection' style={{ textAlign: 'center', width: '200px' }}>
                                                    <InputNumber
                                                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                        size="large"
                                                        style={{ width: '240px', height: '40px', color: 'black' }}
                                                        onChange={(value) => {
                                                            setDataPost({ ...dataPost, inspection: parseFloat(value) });
                                                        }}
                                                    />
                                                </Form.Item>

                                                <Form.Item label='ค่าภาษี+พรบ' name='tax' style={{ textAlign: 'center', width: '200px' }}>
                                                    <InputNumber
                                                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                        size="large"
                                                        style={{ width: '240px', height: '40px', color: 'black' }}
                                                        onChange={(value) => {
                                                            setDataPost({ ...dataPost, tax: parseFloat(value) });
                                                        }}
                                                    />
                                                </Form.Item>

                                                <Form.Item label='ยอดคงเหลือ' name='balance' style={{ textAlign: 'center', width: '200px' }}>
                                                    <Input disabled style={{ color: 'black', width: '240px', height: '40px' }}
                                                        onChange={(e) => setDataPost({ ...dataPost, balance: e.target.value })} />
                                                </Form.Item>

                                            </>
                                        </div> */}
                                </aside>
                                <Divider />
                            </Row>
                    }
                    {/* <Divider /> */}
                    <Row justify={'center'}>
                        <Space>
                            <Button disabled={loading} type="primary" danger onClick={handleCancel1} >ปิด</Button>
                            <Button disabled={loading} type="primary" htmlType="submit">บันทึก</Button>
                        </Space>
                    </Row>
                </Form>
            </Spin>
            {
                //เพื่มผู้แนะนำ
                modalBrokerNew ?
                    <BrokerNew
                        open={modalBrokerNew}
                        close={setModalBrokerNew}
                    />
                    : null
            }
            {
                //แก้ไขข้อมูลในการ์ด
                modaleditdata ?
                    <EditBrokerModal
                        open={modaleditdata}
                        close={setModalEditData}
                        shootdata={dataedit}
                        typeAmt={typeAmt}
                    />
                    : null
            }
            {contextHolder}
        </Row >
    )
}
