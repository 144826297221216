import { Button, Col, Modal, Row } from 'antd';
import React, { useEffect, useState, useRef } from 'react'
import { Carousel } from 'react-responsive-carousel';
import { UndoOutlined, RedoOutlined } from "@ant-design/icons";
import { getImagess, getImagessGua } from '../file_mid/all_api';
import { map } from 'async';

function ModalShowImages({ open, close, carimage1, nuarantorNew, nuarantorNum, setGuarantorNum }) {


    // console.log("nuarantorNew", nuarantorNew)
    // console.log("nuarantorNum", nuarantorNum)
    // console.log("setGuarantorNum", setGuarantorNum)
    // console.log("carimage1", carimage1)


    const token = localStorage.getItem('token');
    const [currentIndex, setCurrentIndex] = useState(0);
    const [rotateDegree, setRotateDegree] = useState(0);
    const [imgGu, setImgGu] = useState([]);


    const handleRotate = (degrees) => {
        setRotateDegree(prevDegree => (prevDegree + degrees) % 360); // ป้องกันองศาเกิน 360
    };
    const carouselRef = useRef(null);


    // console.log("imgGu", imgGu)

    useEffect(() => {
        dataImg()
    }, [carimage1, nuarantorNew, nuarantorNum,]); // เรียกใช้เมื่อ carimage1 หรือ nuarantorNew เปลี่ยนแปลง

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'ArrowRight') {
                setCurrentIndex((prevItem) => (prevItem + 1));
            } else if (event.key === 'ArrowLeft') {
                setCurrentIndex((prevItem) => (prevItem - 1));
            }
        };
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    const dataImg = () => {
        const rr = [];
        let nums = carimage1.filter(obj => obj?.num == nuarantorNew);
        rr.push(...nums);
        setImgGu(rr);
    }

    const renderImageNew = (data, index) => {
        // console.log("data", data)
        if (!data || data.length === 0) return null; // ตรวจสอบว่ามีข้อมูลหรือไม่
        const e = data[0]; // เลือกแค่ภาพแรก
        return (
            <img
                key={index} // ควรใส่ key ที่ไม่ซ้ำกันใน map
                style={{ width: '100%', transform: `rotate(${rotateDegree}deg)` }}
                src={`${nuarantorNum === 5 ? getImagessGua : getImagess}/${data?.pathImage}?token=${token}`}
                alt={`รูปภาพ ${index + 1}`} // ใช้ index ที่ถูกต้อง
            />
        );
    };

    const handleCancel = () => {
        setGuarantorNum(0)
        close(false); // ปิด modal        
    };

    return (
        <Modal
            title={"รูปภาพ"}
            open={open}
            onCancel={handleCancel}
            width={1000}
            footer={null}
        >
            <Row justify="center" style={{ marginBottom: 15 }}>
                <Button onClick={() => handleRotate(-90)}> <b style={{ fontSize: '18px' }}><UndoOutlined />หมุนซ้าย </b></Button>
                <Button onClick={() => handleRotate(90)}><b style={{ fontSize: '18px' }}>หมุนขวา <RedoOutlined /></b></Button>
            </Row>
            <Row jutify={'center'} style={{ marginTop: 15 }}>
                <Col span={24}>
                    {nuarantorNum === 5 ?
                        <>
                            <Carousel
                                showArrows={true}
                                infiniteLoop
                                dynamicHeight
                                width={'100%'}
                                textAlign={'center'}
                                ref={carouselRef}
                                selectedItem={currentIndex}
                                onChange={setCurrentIndex}
                            >
                                {imgGu?.map((e, index) => {
                                    // console.log("e", e)
                                    const segments = e.pathImage.split("/")
                                    // console.log("seg", segments)
                                    if (segments[4] === nuarantorNew) {
                                        let nums = e
                                        // let nums = carimage1.filter(obj => obj?.num == nuarantorNew);
                                        // console.log("nums", nums)
                                        return (
                                            <div key={index}>
                                                {renderImageNew(nums, index)}
                                            </div>
                                        )
                                    }

                                })}
                            </Carousel>
                        </>
                        :
                        <Carousel
                            showArrows={true}
                            infiniteLoop
                            dynamicHeight
                            width={'100%'}
                            textAlign={'center'}
                            ref={carouselRef}
                            selectedItem={currentIndex}
                            onChange={setCurrentIndex}
                        >
                            {carimage1?.map((e, index) => {
                                return (
                                    <div key={index}>
                                        <img
                                            style={{ width: '100%', transform: `rotate(${rotateDegree}deg)`, }}
                                            src={`${getImagess}/${e.pathImage}?token=${token}`}
                                            alt={`รูปภาพ ${index + 1}`}
                                        />
                                    </div>
                                )
                            })}
                        </Carousel>
                    }
                </Col>
            </Row>
        </Modal >
    )
}
export default ModalShowImages