import React, { useState, useEffect } from 'react'
import { Button, Form, Select, Col, Row, Input, Modal, Card, notification, Spin, Space, Divider, Upload } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import Currency from 'currency.js';
import { useSelector } from 'react-redux';
import { HirePurchase } from '../../offer_case/hire_purchase/HirePurchase';
import { Installment } from '../../offer_case/ploan/Installment';
import TableRateCar from '../../offer_case/rate_car/TableRateCar';
import Yearpick from './../../check_infoCar/modals/Yearpick'
import { PlusOutlined } from '@ant-design/icons';
import Compressor from 'compressorjs';
import { productLoanTypeCar, productLoanTypeLand } from '../../file_mid/all_options';
import { AllData, GetAmphur1, GetProvince, PostProvince1, dataModel, editProductDocCar, editProductDocLand, getprice, loadProvinces, newUpimg, newUpimgland, ratecarsmodel } from '../../file_mid/all_api';

export default function EditProductDoc({ dt, page, close, getData, changepage, sendback }) {
    const token = localStorage.getItem('token');
    const [api, contextHolder] = notification.useNotification();
    const [loading, setLoading] = useState(false)
    const { Option } = Select;
    // const guarantor = useSelector(state => state.guarantor)
    // const customers = useSelector(state => state.customers)
    const cars = useSelector(state => state.cars)

    const [form] = Form.useForm()
    const [selectProduct, setSelectProduct] = useState({}) // 1 รถ , 2 ที่ดิน
    //////////////////// ที่ดิน /////////////////////////////
    const [dataPostLand, setDataPostLand] = useState({   // ที่ดิน
        landTypeId: 2,
        productTypeLand: 2,
        landInput: dayjs().format("YYYY-MM-DDTHH:mm:ssZ"),
        landDateStatus: dayjs().format("YYYY-MM-DDTHH:mm:ssZ"),
        rai: 0,
        workArea: 0,
        squareWaArea: 0,
        landPrice: 0,
        resultLandPrice: 0,
    })

    const [moneyLand, setMoneyLand] = useState({}) //loan land
    const [moneyOldLand, setMoneyOldLand] = useState({}) //loanOld land
    const [typeMoneyLand, setTypeMoneyLand] = useState({})

    const [pvcodeS, setPvcodeS] = useState({});
    const [amcodeS, setAmcodeS] = useState({});

    const [provinces, setProvinces] = useState([]);
    const [district, setDistricts] = useState([]);

    /////////////////////////////////////////////////////
    // const [dataPost1, setDataPost1] = useState({}) //customer
    const [dataPost2, setDataPost2] = useState({ productTypeCar: 1 })  //car
    const [money, setMoney] = useState({}) //loan
    const [moneyOld, setMoneyOld] = useState({}) //loanOld
    const [typeMoney, setTypeMoney] = useState({})
    const [provincesCar, setProvincesCar] = useState([]);

    ///////////////// ตาราง ///////////////////////////////
    const [openTable, setOpenTable] = useState(false); //ตารางค่างวด p-loan
    const [openTableHP, setOpenTableHP] = useState(false); //ตารางค่างวด เช่าซื้อ
    const [openTableCar, setOpenTableCar] = useState(false); //ตารางเรทรถ
    // const [count, setCount] = useState(false) //ย้ายไฟแนนซ์

    /////////////////// รถ ////////////////////////////////////
    const [resultData, setResultData] = useState([]) // API
    const [typeData, setTypeData] = useState([]) // API
    const [brandData, setBrandData] = useState([]) // API
    const [modelData, setModelData] = useState([]) // API
    const [yearPrice, setYearPrice] = useState([])
    const [typeSelected, setTypeSelected] = useState(null)
    //const [brandSelected, setBrandSelected] = useState(null)
    //const [carSelected, setCarSelected] = useState(null)

    ////////////////// select car ////////////////////////////////
    const [counterBrand, setCounterBrand] = useState(false)
    const [counterModel, setCounterModel] = useState(false)
    const [counterCarBaab, setCounterCarBaab] = useState(false)

    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [fileListCustomerCar, setFileListCustomerCar] = useState([]);
    const [fileListCustomerCar1, setFileListCustomerCar1] = useState([]);
    const [fileListCustomerDocument, setFileListCustomerDocument] = useState([]);
    const [fileListCustomerDocument1, setFileListCustomerDocument1] = useState([]);
    const [fileListCustomerLand, setFileListCustomerLand] = useState([]);
    const [fileListCustomerLand1, setFileListCustomerLand1] = useState([]);
    const [fileListCustomerDeed, setFileListCustomerDeed] = useState([]);
    const [fileListCustomerDeed1, setFileListCustomerDeed1] = useState([]);
    const [fileListCustomerLeafDivider, setFileListCustomerLeafDivider] = useState([]);
    const [fileListCustomerLeafDivider1, setFileListCustomerLeafDivider1] = useState([]);
    const [fileListCustomerBurden, setFileListCustomerBurden] = useState([]);
    const [fileListCustomerBurden1, setFileListCustomerBurden1] = useState([]);

    //////////////// เลือก p-loan หรือ เช่าซื้อ /////////////////////
    // const [checkPCH, setCheckPCH] = useState({ PLorCH: "" })
    const [checkPCH, setCheckPCH] = useState({})

    const currentTime = dayjs();
    const delayedTime = currentTime.add(7, 'hour');
    const formattedTime = delayedTime.format('YYYY-MM-DDTHH:mm:ssZ');

    const options = [
        {
            value: "แดง",
            label: "แดง",
        },
        {
            value: "น้ำเงิน",
            label: "น้ำเงิน",
        },
        {
            value: "เหลือง",
            label: "เหลือง",
        },
        {
            value: "ขาว",
            label: "ขาว",
        },
        {
            value: "ดำ",
            label: "ดำ",
        },
        {
            value: "ม่วง",
            label: "ม่วง",
        },
        {
            value: "เขียว",
            label: "เขียว",
        },
        {
            value: "ส้ม",
            label: "ส้ม",
        },
        {
            value: "น้ำตาล",
            label: "น้ำตาล",
        },
        {
            value: "ชมพู",
            label: "ชมพู",
        },
        {
            value: "ฟ้า",
            label: "ฟ้า",
        },
        {
            value: "เทา",
            label: "เทา",
        },
    ];

    useEffect(() => {
        if (dt?.car === "car") {
            if (cars?.data?.brand_id !== undefined) {
                setDataPost2({ ...cars.data, carYear: cars.data.carYear.toString(), carId: getData?.cars?.carId, productTypeId: 1 })
                setSelectProduct({ ...selectProduct, productTypeId: 1 })
                setMoney({ ...getData?.cars?.carLoanDetails })
                setTypeData(getData?.cars?.carLoanDetails?.productTypeCar)
                setTypeSelected(getData?.cars?.carLoanDetails.productTypeCar)
                loadDataBrand(getData?.cars?.carLoanDetails.productTypeCar)
                if (getData.cars.carLoanDetails.productLoanType === "ย้ายไฟแนนซ์" || getData.cars.carLoanDetails.productLoanType === 'Pre-Aaprove-ย้ายไฟแนนซ์') {
                    setMoneyOld({ ...getData.cars.carOldLoanDetails, productLoanType: getData.cars.carLoanDetails.productLoanType })
                }
                form.setFieldsValue({
                    ...getData.cars,
                    ...getData.cars.carLoanDetails,
                    ...checkPCH,
                    ...getData.cars.carOldLoanDetails,
                    ...cars.data,
                    productTypeId: 1,
                })
            } else {
                if (getData?.cars?.carId !== undefined) {
                    setDataPost2({ ...getData?.cars, productTypeId: 1 })
                    setSelectProduct({ ...selectProduct, productTypeId: 1 })
                    setMoney({ ...getData?.cars?.carLoanDetails })
                    setTypeData(getData?.cars?.carLoanDetails?.productTypeCar)
                    setTypeSelected(getData?.cars?.carLoanDetails?.productTypeCar)
                    loadDataBrand(getData?.cars?.carLoanDetails?.productTypeCar)
                    if (getData.cars.carLoanDetails.carColor === "" || getData.cars.carLoanDetails.carColor === null) {
                        delete getData.cars.carLoanDetails.carColor
                    }
                    if (getData.cars.carLoanDetails.productLoanType === "ย้ายไฟแนนซ์" || getData.cars.carLoanDetails.productLoanType === 'Pre-Aaprove-ย้ายไฟแนนซ์') {
                        setMoneyOld({ ...getData.cars.carOldLoanDetails, productLoanType: getData.cars.carLoanDetails.productLoanType })
                    }
                    setCheckPCH({ PLorCH: getData.cars.carLoanDetails.productType })
                    form.setFieldsValue({
                        ...getData.cars.carLoanDetails,
                        ...getData.cars.carOldLoanDetails,
                        ...checkPCH,
                        ...getData.cars,
                        PLorCH: getData.cars.carLoanDetails.productType,
                        productTypeId: 1,
                    })
                }

            }
        } else if (dt?.car === "land") {
            fetchAllProvinces()
            getProvinces()
            GetAmphur(getData?.lands?.district)
            PostProvince(getData?.lands?.provinces)
            if (getData?.lands?.landId !== undefined) {
                setDataPostLand({ ...getData.lands })
                setMoneyLand({
                    ...getData.lands.landLoanDetails,
                    interestLand: parseInt(getData.lands.landLoanDetails.interestLand),
                    interestRateLand: parseFloat(getData.lands.landLoanDetails.interestRateLand),
                    loanAmountLand: parseInt(getData.lands.landLoanDetails.loanAmountLand),
                    loanLandTerm: parseInt(getData.lands.landLoanDetails.loanLandTerm),
                    monthlyPaymentLand: parseInt(getData.lands.landLoanDetails.monthlyPaymentLand),
                })
                setCheckPCH({ PLorCH: getData.lands.landLoanDetails.productType })
                setSelectProduct({ ...selectProduct, productTypeId: 2 })
                if (getData.lands.landLoanDetails.productLoanLandType === "ย้ายไฟแนนซ์" || getData.lands.landLoanDetails.productLoanLandType === "Pre-Aaprove-ย้ายไฟแนนซ์") {
                    setMoneyOldLand({ ...getData.lands.landOldLoanDetails, productLoanLandType: getData.lands.landLoanDetails.productLoanLandType })
                }
                form.setFieldsValue({
                    ...getData.lands.landLoanDetails,
                    ...getData.lands.landOldLoanDetails,
                    ...checkPCH,
                    ...getData.lands,
                    productTypeId: 2,
                })
            }
        } else {
            console.log("error", dt?.car)
        }
        fetchAllProvinces()
    }, [cars.data])

    useEffect(() => {
        if (money && dataPost2.productTypeCar > 1 && checkPCH.PLorCH === "เช่าซื้อ") {
            // console.log("ss")
            car_Calculate()
        }
        if (money && dataPost2.productTypeCar === 1 && checkPCH.PLorCH === "เช่าซื้อ1") {
            // console.log("ss")
            car_Calculate()
        }
        if (moneyLand && selectProduct.productTypeId === 2) {
            // console.log("XX")
            land_Calculate()
        }
        if (moneyOld && moneyOld?.productLoanType === 'ย้ายไฟแนนซ์') {
            console.log("GG")
            car_Calculate_close()
        }
        if (moneyOldLand && moneyOldLand?.productLoanLandType === 'ย้ายไฟแนนซ์') {
            console.log("ez")
            land_Calculate_close()
        }
    }, [money?.loanAmount, money?.interestRate, money?.loanTerm, money?.installmentAmountt, moneyLand?.loanAmountLand, moneyLand?.interestRateLand, moneyLand?.loanLandTerm, moneyOld?.oldLoanAmount, moneyOld?.oldMonthlyPayment, moneyOld?.oldLoanTerm, moneyOld?.oldKangPayment, moneyOldLand?.oldLandLoanAmount, moneyOldLand?.oldLandMonthlyPayment, moneyOldLand?.oldLandLoanTerm, moneyOldLand?.oldLandKangPayment])

    const car_Calculate_close = () => {
        if (moneyOld?.oldLoanAmount && moneyOld?.oldMonthlyPayment && moneyOld?.oldLoanTerm) {
            var loanTermKhang = moneyOld?.oldKangPayment / moneyOld?.oldMonthlyPayment
            setMoneyOld({
                ...moneyOld,
                oldKangPaymentTerm: loanTermKhang,
            })
            form.setFieldsValue(
                {
                    oldKangPaymentTerm: loanTermKhang,
                }
            )
        } else {
            console.log("")
        }
    }

    const land_Calculate_close = () => {
        if (moneyOldLand?.oldLandLoanAmount && moneyOldLand?.oldLandMonthlyPayment && moneyOldLand?.oldLandLoanTerm) {
            var loanTermKhangLand = moneyOldLand?.oldLandKangPayment / moneyOldLand?.oldLandMonthlyPayment
            setMoneyOld({
                ...moneyOldLand,
                oldLandKangPaymentTerm: Currency(loanTermKhangLand, { precision: 2 }).value,
            })
            form.setFieldsValue(
                {
                    oldLandKangPaymentTerm: Currency(loanTermKhangLand, { precision: 1 }).value,
                }
            )
        } else {
            console.log("")
        }
    }


    const handleCancel = () => {
        close(false)
    }

    const success = () => {
        Modal.success({
            content: 'บันทึกสำเร็จ',
        })
    }

    const uploadImage = async (resultImg) => {
        if (dt?.car === "car") {
            var inputTest = { mainImg: { carId: getData.cars.carId, carInput: dayjs().format("YYYY-MM-DDTHH:mm:ssZ"), img: resultImg } }
            console.log("inputTest-car", inputTest)
            const headers = { 'Content-Type': 'application/json' }
            await axios.post(newUpimg, inputTest, { headers: headers })
                .then(res => {
                    if (res.data) {
                        console.log("OK car")
                        setFileListCustomerCar([])
                        setFileListCustomerCar1([])
                        setFileListCustomerDocument([])
                        setFileListCustomerDocument1([])
                    }
                })
                .catch((err) => console.error('เกิดข้อผิดพลาดในการอัปโหลดรูปภาพ:', err))
        } else {
            var inputTest = { mainImg: { landId: getData.lands.landId, landInput: dayjs().format("YYYY-MM-DDTHH:mm:ssZ"), img: resultImg } }
            console.log("inputTest-land", inputTest)
            const headers = { 'Content-Type': 'application/json' }
            await axios.post(newUpimgland, inputTest, { headers: headers })
                .then(res => {
                    if (res.data) {
                        console.log("OK land")
                        setFileListCustomerLand([])
                        setFileListCustomerLand1([])
                        setFileListCustomerDeed([])
                        setFileListCustomerDeed1([])
                        setFileListCustomerLeafDivider([])
                        setFileListCustomerLeafDivider1([])
                        setFileListCustomerBurden([])
                        setFileListCustomerBurden1([])
                    }
                })
                .catch((err) => console.error('เกิดข้อผิดพลาดในการอัปโหลดรูปภาพ:', err))
        }
    }

    const handleSubmit = async () => {
        setLoading(true)
        var resultImg = []
        if (fileListCustomerCar1?.imageListCustomerCar) {
            resultImg.push(...fileListCustomerCar1?.imageListCustomerCar)
        }
        if (fileListCustomerDocument1?.imageListCustomerDocument) {
            resultImg.push(...fileListCustomerDocument1?.imageListCustomerDocument)
        }
        if (fileListCustomerDeed1?.imageListCustomerDeed) {
            resultImg.push(...fileListCustomerDeed1?.imageListCustomerDeed)
        }
        if (fileListCustomerLeafDivider1?.imageListCustomerLeafDivider) {
            resultImg.push(...fileListCustomerLeafDivider1?.imageListCustomerLeafDivider)
        }
        if (fileListCustomerBurden1?.imageListCustomerBurden) {
            resultImg.push(...fileListCustomerBurden1?.imageListCustomerBurden)
        }
        if (fileListCustomerLand1?.imageListCustomerLand) {
            resultImg.push(...fileListCustomerLand1?.imageListCustomerLand)
        }
        if (resultImg?.length > 0) {
            await uploadImage(resultImg)
        }
        if (dt?.car === "car") {
            const resultCar = {
                ...getData,
                cars: {
                    ...dataPost2,
                    carLoanDetails: {
                        ...money,
                    },
                    carOldLoanDetails: {
                        ...moneyOld,
                    }
                }
            }
            // console.log("resultCar", resultCar)
            await axios.put(editProductDocCar, resultCar)
                .then(res => {
                    if (res.data) {
                        sendback(dayjs())
                        success()
                    }
                })
                .catch((err) => {
                    console.log("err", err)
                })
        } else {
            const resultLand = {
                ...getData,
                lands: {
                    ...dataPostLand,
                    landLoanDetails: {
                        ...moneyLand,
                    },
                    landOldLoanDetails: {
                        ...moneyOldLand,
                    }
                }
            }
            // console.log("resultLand", resultLand)
            await axios.put(editProductDocLand, resultLand)
                .then(res => {
                    if (res.data) {
                        sendback(dayjs())
                        success()
                    }
                })
                .catch((err) => {
                    console.log("err", err)
                })
        }
        setLoading(false)
    }

    //////////////// จังหวัด รถ /////////////////////
    const fetchAllProvinces = async () => {
        await axios.get(loadProvinces)
            .then(response => {
                setProvincesCar(response.data);
            }).catch(error => {
                console.error(error);
            });
    }

    const handleProvinceChangeCar = (value, element) => {
        setDataPost2({ ...dataPost2, carProvince: value, });
    };

    /////////////// ที่อยู่ ของ ที่ดิน //////////////////
    const getProvinces = async () => {
        setLoading(true)
        await axios.get(GetProvince)
            .then((res) => {
                //console.log("Province =", res.data)
                setProvinces(res.data)
                setLoading(false)
            }).catch((err) => {
                console.error(err);
            })
        setLoading(false)
    }

    const PostProvince = async (value) => {
        const pr = { pvcode: value }
        //console.log("pr =", pr)
        setLoading(true)
        await axios.post(PostProvince1, pr)
            .then((res) => {
                setPvcodeS(res.data[0].pvcode)
                setLoading(false)
            }).catch((err) => {
                console.error(err);
            })
        setLoading(false)
    }

    const GetAmphur = async (value) => {
        const am = { pvcode: value }
        //console.log("am =", am)
        setLoading(true)
        await axios.post(GetAmphur1, am)
            .then((res) => {
                setDistricts(res.data)
                setAmcodeS(res.data[0].amcode)
                setLoading(false)
            }).catch((err) => {
                console.error(err);
            })
        setLoading(false)
    }

    const handleProvinceChange = async (value, key) => {
        setPvcodeS(key["key"])
        setDataPostLand({ ...dataPostLand, provinces: value });
        form.setFieldsValue(
            {
                numberLand: "",
                numberLandlawang: "",
                rai: "",
                workArea: "",
                squareWaArea: "",
                landPrice: "",
                resultLandPrice: "",
                district: "",
            }
        )
        setLoading(true)
        const test = { pvcode: key["key"] }
        await axios.post(GetAmphur1, test)
            .then((res) => {
                setDistricts(res.data)
                setLoading(false)
            }).catch((err) => {
                console.error(err);
            })
        setLoading(false)
    };

    const handleDistrictChange = (value, key) => {
        setAmcodeS(key["key"])
        setDataPostLand({ ...dataPostLand, district: value });
        form.setFieldsValue(
            {
                numberLand: "",
                numberLandlawang: "",
                rai: "",
                workArea: "",
                squareWaArea: "",
                landPrice: "",
                resultLandPrice: "",
            }
        )

    };

    ///////////////// กรณี นส.3 ///////////////////////////
    const handleProvinceChange1 = async (value, key) => {
        setPvcodeS(key["key"])
        setDataPostLand({ ...dataPostLand, provinces: value });
        form.setFieldsValue(
            {
                numberLandlawang: "",
                rai: "",
                workArea: "",
                squareWaArea: "",
                district: "",
            }
        )
        setLoading(true)
        const test = { pvcode: key["key"] }
        await axios.post(GetAmphur1, test)
            .then((res) => {
                setDistricts(res.data)
                setLoading(false)
            }).catch((err) => {
                console.error(err);
            })
        setLoading(false)
    };

    const handleDistrictChange2 = (value, key) => {
        setAmcodeS(key["key"])
        setDataPostLand({ ...dataPostLand, district: value });
        form.setFieldsValue(
            {
                numberLandlawang: "",
                rai: "",
                workArea: "",
                squareWaArea: "",
            }
        )
    };


    ////////////// ที่ดิน /////////////////////
    const handleChangeLandType = (value) => {

        if (value === 'ย้ายไฟแนนซ์' || value === 'Pre-Aaprove-ย้ายไฟแนนซ์') {
            setMoneyLand({ ...moneyLand, productLoanLandType: value, productType: "ที่ดิน" })
            setMoneyOldLand({ ...moneyOldLand, productLoanLandType: value })
        }
        else {
            setMoneyLand({ ...moneyLand, productLoanLandType: value, productType: "ที่ดิน" })
            setMoneyOldLand({ oldLandLoanId: moneyOldLand.oldLandLoanId })
            form.setFieldsValue(
                {
                    loanAmountLand: '',
                    loanLandTerm: '',
                    interestLand: '',
                    monthlyPaymentLand: '',
                    installmentWithInterestLand: '',
                    oldLandLoanAmount: '',
                    oldLandMonthlyPayment: '',
                    oldLandLoanTerm: '',
                    oldLandKangPayment: '',
                    oldLandKangPaymentTerm: '',
                }
            )
        }
    }

    /////////////// โมดาว ค่า งวด p-loan และ แบบ เช่าซื้อ ///////////////////
    // const currencyFormatOnes = (amount) => {
    //     return Number(amount).toFixed(0).replace(/\d(?=(\d{3})+(?!\d))/g, '$&,')
    // }

    const handleMoney = (values) => {
        setMoney({
            ...money,
            interestRate: values?.interestRate,
            loanAmount: values?.loanAmountt,
            loanTerm: values?.installmentAmountt,
            interest: values?.interestt,
            monthlyPayment: values?.monthlyPayment,
            installmentWithInterest: values?.installmentWithInterestt,
            approvalStatus: values?.approvalStatus,
            productType: checkPCH.PLorCH,
            PLorCH: checkPCH.PLorCH
        })
        form.setFieldsValue(
            {
                loanAmount: values?.loanAmountt,
                loanTerm: values?.installmentAmountt,
                interest: values?.interestt,
                monthlyPayment: values?.monthlyPayment,
                installmentWithInterest: values?.installmentWithInterestt,
            }
        )
    }
    const handleMoneyHP = (values) => {
        setMoney({
            ...money,
            interestRate: values?.interestRate,
            loanAmount: values?.loanAmountt,
            loanTerm: values?.installmentAmountt,
            interest: values?.interestt,
            monthlyPayment: values?.monthlyPayment,
            installmentWithInterest: values?.installmentWithInterestt,
            approvalStatus: values?.approvalStatus,
            productType: checkPCH.PLorCH,
            PLorCH: checkPCH.PLorCH
        })
        form.setFieldsValue(
            {
                loanAmount: values?.loanAmountt,
                loanTerm: values?.installmentAmountt,
                interest: values?.interestt,
                monthlyPayment: values?.monthlyPayment,
                installmentWithInterest: Currency(values?.installmentWithInterestt),
            }
        )
    }
    ////////////// รถ ///////////////////
    const handleChangeType = (value) => {
        if (value === 'ย้ายไฟแนนซ์' || value === 'Pre-Aaprove-ย้ายไฟแนนซ์') {
            setMoney({ ...money, productLoanType: value, productType: checkPCH.PLorCH })
            setMoneyOld({ ...moneyOld, productLoanType: value })
            setTypeMoney({ ...typeMoney, productLoanType: 'ย้ายไฟแนนซ์', productType: checkPCH.PLorCH })
        }
        else {
            setMoney({ ...money, productLoanType: value, productType: checkPCH.PLorCH })
            setTypeMoney({ ...typeMoney, productLoanType: 'ถือเล่มมา', productType: checkPCH.PLorCH })
            setMoneyOld({ oldLoanId: getData.cars.carOldLoanDetails.oldLoanId })
            form.setFieldsValue(
                {
                    oldKangPayment: '',
                    oldKangPaymentTerm: '',
                    oldLoanAmount: '',
                    oldLoanTerm: '',
                    oldMonthlyPayment: '',
                }
            )
        }
    }
    /////////////// เลือก p-loan หรือ เช่าซื้อ ///////////////////
    const handleChangePLorCH = (value) => {
        if (value === "p-loan") {
            setCheckPCH({ PLorCH: value })
            setMoney({
                ...money,
                loanAmount: "",
                loanTerm: "",
                interest: "",
                interestRate: "",
                monthlyPayment: "",
                installmentWithInterest: "",
                PLorCH: value,
                productType: value
            })
            form.setFieldsValue(
                {
                    loanAmount: "",
                    loanTerm: "",
                    interest: "",
                    interestRate: "",
                    monthlyPayment: "",
                    installmentWithInterest: "",
                }
            )
        } else if (value === "เช่าซื้อ1") {
            setCheckPCH({ PLorCH: value })
            setMoney({
                ...money,
                loanAmount: "",
                loanTerm: "",
                interest: "",
                interestRate: "",
                monthlyPayment: "",
                installmentWithInterest: "",
                PLorCH: value,
                productType: value
            })
            form.setFieldsValue(
                {
                    loanAmount: "",
                    loanTerm: "",
                    interest: "",
                    interestRate: "",
                    monthlyPayment: "",
                    installmentWithInterest: "",
                }
            )
        } else {
            setCheckPCH({ PLorCH: value })
            setMoney({
                ...money,
                loanAmount: "",
                loanTerm: "",
                interest: "",
                interestRate: "",
                monthlyPayment: "",
                installmentWithInterest: "",
                PLorCH: value,
                productType: value
            })
            form.setFieldsValue(
                {
                    loanAmount: "",
                    loanTerm: "",
                    interest: "",
                    interestRate: "",
                    monthlyPayment: "",
                    installmentWithInterest: "",
                }
            )
        }
    }


    //////////// กรณีไม่มีรถ /////////////////////
    const AddBrand = () => {
        setCounterBrand(true)
        setCounterModel(true)
        setCounterCarBaab(true)
        setDataPost2({ ...dataPost2, carPrice: "0", carPriceStatus: false })
        form.setFieldsValue(
            {
                carBrand: '',
                carModel: '',
                carBaab: '',
                carYear: '',
                carPrice: 0,
            }
        )
    }
    const AddBrands = () => {
        setCounterBrand(false)
        setCounterModel(false)
        setCounterCarBaab(false)
        setDataPost2({ ...dataPost2, carPrice: "0", carPriceStatus: false })
        form.setFieldsValue(
            {
                carBrand: '',
                carModel: '',
                carBaab: '',
                carYear: '',
                carPrice: 0,
            }
        )
    }
    //////////// กรณีไม่มีรถ /////////////////////
    const AddModel = () => {
        setCounterModel(true)
        setCounterCarBaab(true)
        setDataPost2({ ...dataPost2, carPrice: "0", carPriceStatus: false })
        form.setFieldsValue(
            {
                carModel: '',
                carBaab: '',
                carYear: '',
                carPrice: 0,
            }
        )
    }
    const AddModels = () => {
        setCounterModel(false)
        setCounterCarBaab(false)
        setDataPost2({ ...dataPost2, carPrice: "0", carPriceStatus: false })
        form.setFieldsValue(
            {
                carModel: '',
                carBaab: '',
                carYear: '',
                carPrice: 0,
            }
        )
    }
    //////////// กรณีไม่มีรถ /////////////////////
    const AddCarBaab = () => {
        setCounterCarBaab(true)
        setDataPost2({ ...dataPost2, carPrice: "0", carPriceStatus: false })
        form.setFieldsValue(
            {
                carBaab: '',
                carYear: '',
                carPrice: 0,
            }
        )
    }
    const AddCarBaabs = () => {
        setCounterCarBaab(false)
        setDataPost2({ ...dataPost2, carPrice: "0", carPriceStatus: false })
        form.setFieldsValue(
            {
                carBaab: '',
                carYear: '',
                carPrice: 0,
            }
        )
    }
    const onYeehor = (e) => {
        setDataPost2({ ...dataPost2, carBrand: e.target.value })
    }


    const onMoChange = (e) => {
        setDataPost2({ ...dataPost2, carModel: e.target.value })
    }

    const onDeChange = (e) => {
        setDataPost2({ ...dataPost2, carBaab: e.target.value })
    }

    ////// ตัวอย่างรถ กับ ตารางค่างวด ////////
    const getTable = () => {
        setOpenTable(true)
    }
    const getTableHP = () => {
        setOpenTableHP(true)
    }
    const getTableCar = () => {
        setOpenTableCar(true)
    }
    /////////////////////////////////////

    const loadDataBrand = async (value) => { //รถยนต์ รถเครื่องจักร รถบรรทุก
        setLoading(true)
        await axios.get(`https://shark-app-j9jc9.ondigitalocean.app/ratecars/brand/${value}`)
            .then((res) => {
                setBrandData(res.data)
                setLoading(false)
            })
            .catch((err) => console.log(err))
        setLoading(false)
    }

    const loadDataModel = async (value) => {
        setLoading(true)
        await axios.get(dataModel + value)
            .then((res) => {
                setModelData(res.data)
                setLoading(false)
            })
            .catch((err) => console.log(err))
        setLoading(false)
    }
    const getResult = async (value) => {
        setLoading(true)
        const result = await axios.get(ratecarsmodel,
            { params: { typeId: typeSelected, modelId: value }, })
        setResultData(result.data)
        setLoading(false)
    }

    const onTypeChange = (value) => {
        setDataPost2({ ...dataPost2, productTypeCar: value, carYear: "0", carPrice: 0, carPriceStatus: false, carInput: dayjs().format("YYYY-MM-DDTHH:mm:ssZ"), carDateStatus: dayjs().format("YYYY-MM-DDTHH:mm:ssZ") })
        setTypeData(value)
        setTypeSelected(value)
        loadDataBrand(value)
        form.setFieldsValue(
            {
                carProvince: "",
                carPlateNumber: "",
                loanAmount: "",
                loanTerm: "",
                interest: "",
                interestRate: "",
                monthlyPayment: "",
                installmentWithInterest: "",
                oldLoanAmount: "",
                oldMonthlyPayment: "",
                oldLoanTerm: "",
                oldKangPayment: "",
                oldKangPaymentTerm: ""
            }
        )
    }
    const onBrandChange = (value) => {
        setDataPost2({ ...dataPost2, carBrand: brandData.find((a) => a.idbrand === value).brand })
        loadDataModel(value)
    }
    const onModelChange = (value) => {
        setCounterBrand(false)
        setDataPost2({ ...dataPost2, carModel: modelData.find((a) => a.idmodel_te === value).model })
        getResult(value)
    }
    const onDetailChange = (value) => {
        setDataPost2({ ...dataPost2, carBaab: resultData.find((a) => a.idmodel === value).models })
        getPrice(typeData, value)
    }
    const getPrice = async (typeId, carId) => {
        setLoading(true)
        await axios.get(getprice, { params: { typeId, carId }, })
            .then((res) => {
                if (res.data) {
                    setYearPrice(res.data)
                    setLoading(false)
                }
            })
            .catch((err) => alert('ไม่พบข้อมูล ราคารถ'))
        setLoading(false)
    }
    const onResult = (k, v) => {
        const pYear = parseInt(v.key)
        if (k) {
            setDataPost2({ ...dataPost2, carPriceStatus: true, carPrice: k, carYear: pYear.toString(), carInput: dayjs().format("YYYY-MM-DDTHH:mm:ssZ"), carDateStatus: dayjs().format("YYYY-MM-DDTHH:mm:ssZ") })
            form.setFieldsValue({
                carPrice: v.value
            })

        }
    }
    /////////////////// เลือก 1 = นส.3 กับ 2 = นส.4 //////////////////////
    const handleProductLand = (value) => {
        if (value === 1) {
            setDataPostLand({
                ...dataPostLand,
                landTypeId: value,
                productTypeLand: value,
                numberLand: "-",
                numberLandLawang: "-",
                rai: 0,
                workArea: 0,
                squareWaArea: 0,
                landPrice: 0,
                resultLandPrice: 0,
            })
            form.setFieldsValue(
                {
                    numberLand: "",
                    numberLandlawang: "",
                    rai: "",
                    workArea: "",
                    squareWaArea: "",
                    landPrice: "",
                    resultLandPrice: "",
                    provinces: "",
                    district: "",
                }
            )
        } else {
            setDataPostLand({
                ...dataPostLand,
                landTypeId: value,
                productTypeLand: value,
                numberLand: "-",
                numberLandLawang: "-",
                rai: 0,
                workArea: 0,
                squareWaArea: 0,
                landPrice: 0,
                resultLandPrice: 0,
            })
            form.setFieldsValue(
                {
                    numberLand: "",
                    numberLandlawang: "",
                    rai: "",
                    workArea: "",
                    squareWaArea: "",
                    landPrice: "",
                    resultLandPrice: "",
                    provinces: "",
                    district: "",
                }
            )
        }
    }

    /////////////////// เลือก 1 รถ, 2 ที่ดิน ///////////////////////
    const handleProduct = (value) => {
        setSelectProduct({ ...selectProduct, productTypeId: value })
        if (value === 1) {
            setCheckPCH({ PLorCH: "p-loan" })
            setDataPost2({ ...dataPost2, productTypeId: value, productTypeCar: 1 })
            setDataPostLand({})
            setMoneyLand({})
            setMoneyOldLand({})
            setTypeMoneyLand({})
            form.setFieldsValue(
                {
                    PLorCH: "p-loan",
                    numberLand: "",
                    numberLandlawang: "",
                    rai: "",
                    workArea: "",
                    squareWaArea: "",
                    landPrice: "",
                    resultLandPrice: "",
                    provinces: "",
                    district: "",
                    subdistricts: "",
                    zipcode: "",
                    productLoanLandType: "",
                    loanAmountLand: "",
                    loanLandTerm: "",
                    interestLand: "",
                    monthlyPaymentLand: "",
                    installmentWithInterestLand: "",
                }
            )
        } else {
            getProvinces()
            setDataPostLand({
                ...dataPostLand,
                productTypeId: value,
                productTypeLand: 2,
                landInput: dayjs().format("YYYY-MM-DDTHH:mm:ssZ"),
                landDateStatus: dayjs().format("YYYY-MM-DDTHH:mm:ssZ"),
                rai: 0,
                workArea: 0,
                squareWaArea: 0,
                landPrice: 0,
                resultLandPrice: 0
            })
            setDataPost2({})
            setMoneyOld({})
            setTypeMoney({})
            form.setFieldsValue(
                {
                    PLorCH: "",
                    productTypeCar: "",
                    carBrand: "",
                    carModel: "",
                    carBaab: "",
                    carYear: "",
                    carPrice: 0,
                    carTy: "",
                    carProvince: "",
                    carPlateNumber: "",
                    productLoanType: "",
                    loanAmount: "",
                    loanTerm: "",
                    interest: "",
                    monthlyPayment: "",
                    installmentWithInterest: "",
                    oldLoanAmount: "",
                    oldMonthlyPayment: "",
                    oldLoanTerm: "",
                    oldKangPayment: "",
                    oldKangPaymentTerm: ""
                }
            )
        }
    }

    const errorLand = () => {
        Modal.error({
            title: 'ไม่พบข้อมูล',
            content: 'กรุณาตรวจสอบข้อมูลที่กรอกอีกครั้ง',
        })
    }

    const handleCheckLand = async () => {
        var provids = pvcodeS
        var amphurs = amcodeS
        var landNos = dataPostLand.numberLand
        var result = { pvcode: provids, amcode: amphurs, landNo: landNos }
        const headers = {
            "Content-Type": "application/json",
        }
        setLoading(true)
        await axios.post(AllData, result, { headers: headers })
            .then((response) => {
                if (response.data) {
                    const squareWaArea = parseFloat(response.data.result[0].wa + "." + response.data.result[0].subwa)
                    const landPricePerWa = parseInt(response.data.result[0].landprice.replace(/,/g, ""))
                    const totalWa = (
                        ((parseInt(response.data?.result[0].rai) * 400)) +
                        ((parseInt(response.data?.result[0].ngan) * 100)) +
                        parseFloat(response.data?.result[0].wa + "." + response.data?.result[0].subwa)
                    )
                    const totalPrice = Currency(totalWa * landPricePerWa).value
                    setDataPostLand({
                        ...dataPostLand,
                        subdistrict: response.data?.result[0].tumbolname,
                        numberLandlawang: response.data?.result[0].utm,
                        rai: parseInt(response.data?.result[0].rai),
                        workArea: parseInt(response.data?.result[0].ngan),
                        squareWaArea: squareWaArea,
                        landPrice: parseInt(response.data.result[0].landprice.replace(/,/g, "")) || 0,
                        resultLandPrice: totalPrice || 0
                    });
                    form.setFieldsValue(
                        {
                            numberLandlawang: response.data?.result[0].utm,
                            rai: parseInt(response.data?.result[0].rai),
                            workArea: parseInt(response.data?.result[0].ngan),
                            squareWaArea: squareWaArea,
                            landPrice: parseInt(response.data.result[0].landprice.replace(/,/g, "")) || 0,
                            resultLandPrice: totalPrice || 0,
                        }
                    )
                    setLoading(false)
                } else {
                    console.log("DATA =", response.data)
                }
            })
            .catch((err) => errorLand())
            .finally(() => {
                setLoading(false);
            })
        setLoading(false)
    }

    const land_Calculate = () => {
        if (moneyLand.loanAmountLand && moneyLand.interestRateLand && moneyLand.loanLandTerm) {
            var interestRateLand = parseFloat(moneyLand.interestRateLand) / 100 // อัตราดอก / 100
            var rate = (parseFloat(moneyLand.loanAmountLand) * Currency(interestRateLand, { precision: 3 }).value) * parseInt(moneyLand.loanLandTerm) // (ยอดจัด * อัตราดอกที่หาร100แล้ว ,precision: 3 คือทศนิยม 3 )*งวด
            var loanTerm = Math.ceil(Currency((rate + parseFloat(moneyLand.loanAmountLand)) / parseInt(moneyLand.loanLandTerm), { precision: 2 }).value) // (ดอกเบี้ยทั้งหมด + ยอดจัด)/ งวด
            var resultRate = (loanTerm * parseInt(moneyLand.loanLandTerm)) - moneyLand.loanAmountLand
            var resultPrice = loanTerm * parseInt(moneyLand.loanLandTerm)
            setMoneyLand({
                ...moneyLand,
                //loanLandTerm: parseInt(event.target.value), //งวด
                monthlyPaymentLand: loanTerm,
                interestLand: resultRate,
                installmentWithInterestLand: resultPrice
            })
            form.setFieldsValue(
                {
                    monthlyPaymentLand: loanTerm,
                    interestLand: resultRate,
                    installmentWithInterestLand: resultPrice
                }
            )
        }
        else {
            form.setFieldsValue(
                {
                    monthlyPaymentLand: 0,
                    interestLand: 0,
                    installmentWithInterestLand: 0
                }
            )
        }
    }

    const car_Calculate = () => {
        if (money.loanAmount && money.interestRate && money.loanTerm) {
            var interestRateCar = parseFloat(money.interestRate) / 100 // อัตราดอก / 100
            var rateCar = ((parseFloat(money.loanAmount) * Currency(interestRateCar, { precision: 5 }).value) * parseInt(money.loanTerm)) // (ยอดจัด * อัตราดอกที่หาร100แล้ว ,precision: 3 คือทศนิยม 3 )*งวด
            var loanTermCar = Math.ceil(Currency((rateCar + parseFloat(money.loanAmount)) / parseInt(money.loanTerm), { precision: 2 }).value) // (ดอกเบี้ยทั้งหมด + ยอดจัด)/ งวด
            var newLoanTermCar = Math.ceil(Currency(loanTermCar * 0.07)) + loanTermCar // งวดละใหม่ = ( งวดละ * 0.07 ) + งวดละ
            var resultRateCar = (newLoanTermCar * parseInt(money.loanTerm)) - parseFloat(money.loanAmount) // งวดละใหม่ * งวด - ยอดจัด
            var newResultPriceCar = newLoanTermCar * parseInt(money.loanTerm) // งวดละใหม่ * งวด
            setMoney({
                ...money,
                monthlyPayment: newLoanTermCar,
                interest: resultRateCar,
                installmentWithInterest: newResultPriceCar
            })
            form.setFieldsValue(
                {
                    monthlyPayment: newLoanTermCar,
                    interest: resultRateCar,
                    installmentWithInterest: newResultPriceCar
                }
            )
        }
        else {
            form.setFieldsValue(
                {
                    monthlyPayment: 0,
                    interest: 0,
                    installmentWithInterest: 0
                }
            )
        }
    }
    const currencyFormatOne = (amount) => {
        return Number(amount)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, '$&,')
    }

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);
    };
    const handleCancel1 = () => {
        setPreviewVisible(false);
    }
    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };
    const handleChangeCustomerDocument = async ({ fileList }) => { // 2
        setLoading(true)
        setFileListCustomerDocument(fileList);
        var imageListCustomerDocument = []
        for (const file of fileList) {
            const compressedFile = await new Promise((resolve) => {
                new Compressor(file.originFileObj, {
                    quality: 0.3, // เช่น, 60%
                    success: (result) => {
                        resolve(result);
                    },
                });
            });
            var image = {}
            var filename = file.name
            var image64 = await getBase64(compressedFile)
            image.filename = filename
            image.image64 = image64
            image.type = 2
            imageListCustomerDocument.push(image)
        }
        setFileListCustomerDocument1({ ...fileListCustomerDocument1, imageListCustomerDocument });
        setLoading(false)
    };
    const handleChangeCustomerCar = async ({ fileList }) => { // 1
        setLoading(true)
        setFileListCustomerCar(fileList);
        var imageListCustomerCar = []
        for (const file of fileList) {
            const compressedFile = await new Promise((resolve) => {
                new Compressor(file.originFileObj, {
                    quality: 0.3, // เช่น, 60%
                    success: (result) => {
                        resolve(result);
                    },
                });
            });
            var image = {}
            var filename = file.name
            var image64 = await getBase64(compressedFile)
            image.filename = filename
            image.image64 = image64
            image.type = 1
            imageListCustomerCar.push(image)
        }
        setFileListCustomerCar1({ ...fileListCustomerCar1, imageListCustomerCar });
        setLoading(false)
    };
    const handleChangeCustomerDeed = async ({ fileList }) => { // 3
        setLoading(true)
        setFileListCustomerDeed(fileList);
        var imageListCustomerDeed = []
        for (const file of fileList) {
            const compressedFile = await new Promise((resolve) => {
                new Compressor(file.originFileObj, {
                    quality: 0.3, // เช่น, 60%
                    success: (result) => {
                        resolve(result);
                    },
                });
            });
            var image = {}
            var filename = file.name
            var image64 = await getBase64(compressedFile)
            image.filename = filename
            image.image64 = image64
            image.type = 3
            imageListCustomerDeed.push(image)
        }
        setFileListCustomerDeed1({ ...fileListCustomerDeed1, imageListCustomerDeed });
        setLoading(false)
    };
    const handleChangeCustomerLeafDivider = async ({ fileList }) => { // 10
        setLoading(true)
        setFileListCustomerLeafDivider(fileList);
        var imageListCustomerLeafDivider = []
        for (const file of fileList) {
            const compressedFile = await new Promise((resolve) => {
                new Compressor(file.originFileObj, {
                    quality: 0.3, // เช่น, 60%
                    success: (result) => {
                        resolve(result);
                    },
                });
            });
            var image = {}
            var filename = file.name
            var image64 = await getBase64(compressedFile)
            image.filename = filename
            image.image64 = image64
            image.type = 10
            imageListCustomerLeafDivider.push(image)
        }
        setFileListCustomerLeafDivider1({ ...fileListCustomerLeafDivider1, imageListCustomerLeafDivider });
        setLoading(false)
    };
    const handleChangeCustomerBurden = async ({ fileList }) => { // 11
        setLoading(true)
        setFileListCustomerBurden(fileList);
        var imageListCustomerBurden = []
        for (const file of fileList) {
            const compressedFile = await new Promise((resolve) => {
                new Compressor(file.originFileObj, {
                    quality: 0.3, // เช่น, 60%
                    success: (result) => {
                        resolve(result);
                    },
                });
            });
            var image = {}
            var filename = file.name
            var image64 = await getBase64(compressedFile)
            image.filename = filename
            image.image64 = image64
            image.type = 11
            imageListCustomerBurden.push(image)
        }
        setFileListCustomerBurden1({ ...fileListCustomerBurden1, imageListCustomerBurden });
        setLoading(false)
    };
    const handleChangeCustomerLand = async ({ fileList }) => { // 1
        setLoading(true)
        setFileListCustomerLand(fileList);
        var imageListCustomerLand = []
        for (const file of fileList) {
            const compressedFile = await new Promise((resolve) => {
                new Compressor(file.originFileObj, {
                    quality: 0.3, // เช่น, 60%
                    success: (result) => {
                        resolve(result);
                    },
                });
            });
            var image = {}
            var filename = file.name
            var image64 = await getBase64(compressedFile)
            image.filename = filename
            image.image64 = image64
            image.type = 1
            imageListCustomerLand.push(image)
        }
        setFileListCustomerLand1({ ...fileListCustomerLand1, imageListCustomerLand });
        setLoading(false)
    };

    return (
        <div>
            <Row justify={'center'}>
                <Card style={{ width: 900 }}>
                    <Spin spinning={loading} size='large' tip=" Loading... ">
                        <Row style={{ textAlign: 'center', marginTop: '30px' }} >
                            <Col span={24} style={{ fontSize: '30px' }} >รายละเอียด</Col>
                        </Row>
                        <Form
                            labelCol={{
                                span: 8,
                            }}
                            wrapperCol={{
                                span: 16,
                            }}
                            style={{
                                maxWidth: 900,
                            }}
                            form={form}
                            onFinish={handleSubmit}
                            autoComplete="off"
                            initialValues={{ remember: true }}
                        >
                            <Row gutter={32}>
                                <Col className="gutter-row" span={12}>
                                    <Form.Item label='กลุ่มสินค้า' name='productTypeId'
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input !'
                                            },]}>
                                        <Select placeholder={'เลือก'} disabled
                                            onChange={(value) => handleProduct(value)}
                                        >
                                            <Option value={1}>รถ</Option>
                                            <Option value={2}>ที่ดิน</Option>
                                        </Select>
                                    </Form.Item>

                                    {/* รถ */}

                                    {dt?.car === "car" && (
                                        <>
                                            <Form.Item label='เรทรถ'>
                                                <Button type='primary' onClick={getTableCar} >ดู</Button>
                                                {
                                                    openTableCar ?
                                                        <TableRateCar open={openTableCar} close={setOpenTableCar} productTypeCar={dataPost2?.productTypeCar} water={getData?.cars?.carId} />
                                                        : null
                                                }
                                            </Form.Item>

                                            <Form.Item label='ชนิดรถ' name='productTypeCar'
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input !'
                                                    },]}>
                                                <Select placeholder={'เลือก'} allowClear
                                                    onChange={onTypeChange}>
                                                    <Option value={1}>รถยนต์</Option>
                                                    <Option value={2}>รถเครื่องจักการเกษตร</Option>
                                                    <Option value={3}>รถบรรทุก</Option>
                                                </Select>
                                            </Form.Item>
                                            <Row gutter={32}>
                                                <Col className='gutter-row' span={22}>
                                                    <Form.Item label='ยี่ห้อ' name='carBrand' style={{ lineHeight: '32px' }}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        {!counterBrand ? (
                                                            <Select
                                                                showSearch
                                                                onChange={onBrandChange}
                                                                optionFilterProp="children"
                                                                placeholder="Search to Select"
                                                                filterOption={(inputValue, option) =>
                                                                    option.children.join('').toLowerCase().includes(inputValue.toLowerCase())
                                                                }
                                                            >
                                                                {brandData?.map((e) => {
                                                                    return (
                                                                        <Select.Option key={e.idbrand} value={e.idbrand}>
                                                                            {e.brand} ({e.brand_th})
                                                                        </Select.Option>
                                                                    )
                                                                })}
                                                            </Select>
                                                        ) : (<>

                                                            {counterBrand ? (<Input onChange={onYeehor} />) : (<Select
                                                                showSearch
                                                                onChange={onBrandChange}
                                                                placeholder="Search to Select"
                                                                optionFilterProp="children"
                                                                filterOption={
                                                                    (inputValue, option) =>
                                                                        option.children.join('').toLowerCase().includes(inputValue.toLowerCase())
                                                                }
                                                            >
                                                                {modelData?.map((e) => {
                                                                    return (
                                                                        <Select.Option key={e.idmodel_te} value={e.idmodel_te}>
                                                                            {e.model} {e.model_te ? e.model_te : null}
                                                                        </Select.Option>
                                                                    )
                                                                })}
                                                            </Select>)
                                                            }
                                                        </>

                                                        )}
                                                    </Form.Item>
                                                </Col>
                                                <Col className='gutter-row' span={1} style={{ textAlign: 'center' }}>
                                                    {counterBrand === false && (
                                                        <Button onClick={AddBrand} style={{ height: '33px', lineHeight: '15px' }}>
                                                            +
                                                        </Button>)
                                                    }
                                                    {counterBrand === true && (
                                                        <Button onClick={AddBrands} >
                                                            -
                                                        </Button>
                                                    )}
                                                </Col>
                                            </Row>
                                            <Row gutter={32}>
                                                <Col className='gutter-row' span={22}>
                                                    <Form.Item label='รุ่นรถ' name='carModel'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        {counterBrand ? ( //ไม่จริงเข้าตรงนี้
                                                            <>
                                                                {!counterModel ? (
                                                                    <Select //ไม่จริงเข้าตรงนี้
                                                                        showSearch
                                                                        onChange={onModelChange}
                                                                        placeholder="Search to Select"
                                                                        optionFilterProp="children"
                                                                        filterOption={(inputValue, option) =>
                                                                            option.children.join('').toLowerCase().includes(inputValue.toLowerCase())
                                                                        }
                                                                    >
                                                                        {modelData?.map((e) => {
                                                                            return (
                                                                                <Select.Option key={e.idmodel_te} value={e.idmodel_te}>
                                                                                    {e.model} {e.model_te ? e.model_te : null}
                                                                                </Select.Option>
                                                                            )
                                                                        })}
                                                                    </Select>
                                                                ) : (

                                                                    <Input onChange={onMoChange} />
                                                                )}

                                                            </>
                                                        ) : (<>

                                                            {counterModel ? (<Input onChange={onMoChange} />) : (<Select
                                                                showSearch
                                                                onChange={onModelChange}
                                                                placeholder="Search to Select"
                                                                optionFilterProp="children"
                                                                filterOption={
                                                                    (inputValue, option) =>
                                                                        option.children.join('').toLowerCase().includes(inputValue.toLowerCase())
                                                                }
                                                            >
                                                                {modelData?.map((e) => {
                                                                    return (
                                                                        <Select.Option key={e.idmodel_te} value={e.idmodel_te}>
                                                                            {e.model} {e.model_te ? e.model_te : null}
                                                                        </Select.Option>
                                                                    )
                                                                })}
                                                            </Select>)
                                                            }
                                                        </>
                                                        )}
                                                    </Form.Item>
                                                </Col>
                                                <Col className='gutter-row' span={1} style={{ textAlign: 'center' }}>
                                                    {counterModel === false && (
                                                        <Button onClick={AddModel} style={{ height: '33px', lineHeight: '15px' }}>
                                                            +
                                                        </Button>)
                                                    }
                                                    {counterModel === true && (
                                                        <Button onClick={AddModels} >
                                                            -
                                                        </Button>
                                                    )}
                                                </Col>
                                            </Row>
                                            <Row gutter={32}>
                                                <Col className='gutter-row' span={22}>
                                                    <Form.Item label='แบบรถ' name='carBaab'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        {counterBrand ? (
                                                            <>
                                                                {!counterCarBaab ? (
                                                                    <Select
                                                                        showSearch
                                                                        onChange={(value) => { onDetailChange(value) }}
                                                                        placeholder="Search to Select"

                                                                    >
                                                                        {resultData?.map((e) => {
                                                                            return (
                                                                                <Select.Option key={e.idmodel} value={e.idmodel}>
                                                                                    {e.models} {e.idmodel ? e.idmodel : null}
                                                                                </Select.Option>
                                                                            )
                                                                        })}
                                                                    </Select>
                                                                ) : (

                                                                    <Input onChange={onDeChange} />
                                                                )}
                                                            </>
                                                        ) : (<>

                                                            {counterCarBaab ? (<Input onChange={onDeChange} />) : (<Select
                                                                showSearch
                                                                onChange={(value) => { onDetailChange(value) }}
                                                                placeholder="Search to Select"
                                                            >
                                                                {resultData?.map((e) => {
                                                                    return (
                                                                        <Select.Option key={e.idmodel} value={e.idmodel}>
                                                                            {e.models} {e.idmodel ? e.idmodel : null}
                                                                        </Select.Option>
                                                                    )
                                                                })}
                                                            </Select>)
                                                            }
                                                        </>
                                                        )}
                                                    </Form.Item>
                                                </Col>
                                                <Col className='gutter-row' span={1} style={{ textAlign: 'center' }}>
                                                    {counterCarBaab === false && (
                                                        <Button onClick={AddCarBaab} style={{ height: '33px', lineHeight: '15px' }}>
                                                            +
                                                        </Button>)
                                                    }
                                                    {counterCarBaab === true && (
                                                        <Button onClick={AddCarBaabs} >
                                                            -
                                                        </Button>
                                                    )}
                                                </Col>
                                            </Row>
                                            <Form.Item label='ปีรถ' name='carYear'
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input !'
                                                    },]}>
                                                {counterBrand ? (
                                                    <>
                                                        {!counterCarBaab ? (
                                                            <Select
                                                                onChange={(k, v) => (onResult(k, v))}
                                                            >
                                                                {Object.entries(yearPrice).map(([k, v]) => {
                                                                    if (k.charAt(0) === 'p' && v) {
                                                                        const year = k.replace('p', '')
                                                                        return (
                                                                            <Select.Option key={year} value={v * 1000}>
                                                                                {year}
                                                                            </Select.Option>
                                                                        )
                                                                    }
                                                                })}
                                                            </Select>
                                                        ) : (
                                                            <Yearpick
                                                                picker="year"
                                                                onChange={(e, string) => {
                                                                    if (e) {
                                                                        let year = e.$y
                                                                        let yearstr = year.toString();
                                                                        setDataPost2({ ...dataPost2, carYear: yearstr })
                                                                    }
                                                                }} />
                                                        )}

                                                    </>
                                                )
                                                    :
                                                    (
                                                        <>
                                                            {counterCarBaab ? (
                                                                <Yearpick
                                                                    picker="year"
                                                                    onChange={(e, string) => {
                                                                        if (e) {
                                                                            let year = e.$y
                                                                            setDataPost2({ ...dataPost2, carYear: year })
                                                                        }
                                                                    }} />
                                                            ) : (
                                                                <Select
                                                                    onChange={(k, v) => (onResult(k, v))}
                                                                >
                                                                    {Object.entries(yearPrice).map(([k, v]) => {
                                                                        if (k.charAt(0) === 'p' && v) {
                                                                            const year = k.replace('p', '')
                                                                            return (
                                                                                <Select.Option key={year} value={v * 1000}>
                                                                                    {year}
                                                                                </Select.Option>
                                                                            )
                                                                        }
                                                                    })}
                                                                </Select>
                                                            )}
                                                        </>

                                                    )}
                                            </Form.Item>
                                            <Form.Item label='ราคา' name='carPrice'>
                                                <Input disabled defaultValue={0}
                                                    onChange={(e) => setDataPost2({ ...dataPost2, carPrice: e.value })} />
                                            </Form.Item>

                                            <Form.Item label='จังหวัด' name='carProvince'
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input !',
                                                    },]}>
                                                <Select
                                                    showSearch
                                                    name='carProvince'
                                                    placeholder='จังหวัด'
                                                    onChange={(key, value) => { handleProvinceChangeCar(key, value) }}
                                                >
                                                    {provincesCar?.map((pro, index) => (
                                                        <Option key={pro.provinceId} value={pro.provinceName}>
                                                            {pro.provinceName}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>

                                            <Form.Item label='เลขทะเบียน' name='carPlateNumber'
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input !',
                                                    },]}>
                                                <Input
                                                    onChange={(e) => setDataPost2({ ...dataPost2, carPlateNumber: e.target.value })} />
                                            </Form.Item>
                                            <Form.Item label='เลขคัสซี' name='carChassisNumber'
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input !',
                                                    },]}>
                                                <Input
                                                    onChange={(e) => setDataPost2({ ...dataPost2, carChassisNumber: e.target.value })} />
                                            </Form.Item>
                                            <Form.Item label='เลขเครื่อง' name='carEngineNumber'
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input !',
                                                    },]}>
                                                <Input
                                                    onChange={(e) => setDataPost2({ ...dataPost2, carEngineNumber: e.target.value })} />
                                            </Form.Item>
                                            <Form.Item label='สี' name='carColor'
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input !',
                                                    },]}>
                                                <Select
                                                    mode="multiple"
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                    size="large"
                                                    placeholder="เลือกหมายเหตุ"
                                                    onChange={(e) => setDataPost2({ ...dataPost2, carColor: `${e}` })}
                                                    options={options}
                                                />
                                            </Form.Item>
                                        </>
                                    )}
                                    {/* ที่ดิน */}

                                    {dt?.car === "land" && (
                                        <>
                                            <Form.Item label='ประเภทเอกสาร' name='productTypeLand'
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input !'
                                                    },]}>
                                                <Select placeholder={'เลือก'}
                                                    onChange={(value) => handleProductLand(value)}
                                                >
                                                    <Option value={1}>นส.3</Option>
                                                    <Option value={2}>นส.4</Option>
                                                </Select>
                                            </Form.Item>

                                            {selectProduct && dataPostLand?.productTypeLand === 1 && (
                                                <>
                                                    <Form.Item name='provinces' label='จังหวัด'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <Select
                                                            showSearch
                                                            loading={loading}
                                                            name='provinces'
                                                            placeholder="จังหวัด"
                                                            onChange={handleProvinceChange1}
                                                        >
                                                            {provinces?.result?.map((pro, index) => (
                                                                <Option key={pro.pvcode} value={pro.pvnamethai}>
                                                                    {pro.pvnamethai}
                                                                </Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>

                                                    <Form.Item name='district' label='อำเภอ'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <Select
                                                            showSearch
                                                            loading={loading}
                                                            name='district'
                                                            placeholder="อำเภอ"
                                                            onChange={handleDistrictChange2}
                                                        >
                                                            {district?.map((dis, index) => (
                                                                <Option key={dis.amcode} value={dis.amnamethai}>
                                                                    {dis.amnamethai}
                                                                </Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>

                                                    <Form.Item label='เลขที่โฉนด/เลขที่' name='numberLand'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <Input type='text'
                                                            onChange={(e) => setDataPostLand({ ...dataPostLand, numberLand: e.target.value })}></Input>
                                                    </Form.Item>

                                                    <Form.Item label='พื้นที่ไร่' name='rai'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <Input type='number' suffix="ไร่"
                                                            onChange={(e) => setDataPostLand({ ...dataPostLand, rai: parseInt(e.target.value) })}></Input>
                                                    </Form.Item>

                                                    <Form.Item label='พื้นที่งาน' name='workArea'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <Input type='number' suffix="งาน"
                                                            onChange={(e) => setDataPostLand({ ...dataPostLand, workArea: parseInt(e.target.value) })}></Input>
                                                    </Form.Item>

                                                    <Form.Item label='พื้นที่ตารางวา' name='squareWaArea'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <Input type='number' suffix="ตารางวา"
                                                            onChange={(e) => setDataPostLand({ ...dataPostLand, squareWaArea: parseInt(e.target.value) })}></Input>
                                                    </Form.Item>
                                                </>
                                            )}
                                            {selectProduct && dataPostLand?.productTypeLand === 2 && (
                                                <>
                                                    <Form.Item name='provinces' label='จังหวัด'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <Select
                                                            showSearch
                                                            loading={loading}
                                                            name='provinces'
                                                            placeholder="จังหวัด"
                                                            onChange={handleProvinceChange}
                                                        >
                                                            {provinces?.result?.map((pro, index) => (
                                                                <Option key={pro.pvcode} value={pro.pvnamethai}>
                                                                    {pro.pvnamethai}
                                                                </Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>

                                                    <Form.Item name='district' label='อำเภอ'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <Select
                                                            showSearch
                                                            loading={loading}
                                                            name='district'
                                                            placeholder="อำเภอ"
                                                            onChange={handleDistrictChange}
                                                        >
                                                            {district?.map((dis, index) => (
                                                                <Option key={dis.amcode} value={dis.amnamethai}>
                                                                    {dis.amnamethai}
                                                                </Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>

                                                    <Form.Item label='เลขโฉนดที่ดิน' name='numberLand'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <Input type='text'
                                                            onChange={(e) => setDataPostLand({ ...dataPostLand, numberLand: e.target.value })}></Input>
                                                    </Form.Item>

                                                    <Form.Item label='ตรวจสอบ'>
                                                        <Button type="primary" onClick={handleCheckLand}>ตรวจสอบ</Button>
                                                    </Form.Item>

                                                    <Form.Item label='เลขที่ดินหรือระวาง' name='numberLandlawang'>
                                                        <Input type='text' disabled
                                                            onChange={(e) => setDataPostLand({ ...dataPostLand, numberLandlawang: e.target.value })}></Input>
                                                    </Form.Item>



                                                    <Form.Item label='พื้นที่ไร่' name='rai'>
                                                        <Input type='text' suffix="ไร่" disabled
                                                            onChange={(e) => setDataPostLand({ ...dataPostLand, rai: parseInt(e.target.value) })}></Input>
                                                    </Form.Item>

                                                    <Form.Item label='พื้นที่งาน' name='workArea'>
                                                        <Input type='text' suffix="งาน" disabled
                                                            onChange={(e) => setDataPostLand({ ...dataPostLand, workArea: parseInt(e.target.value) })}></Input>
                                                    </Form.Item>

                                                    <Form.Item label='พื้นที่ตารางวา' name='squareWaArea'>
                                                        <Input type='text' suffix="ตารางวา" disabled
                                                            onChange={(e) => setDataPostLand({ ...dataPostLand, squareWaArea: parseInt(e.target.value) })}></Input>
                                                    </Form.Item>

                                                    <Form.Item label='ราคาประเมินที่ดิน' name='landPrice'>
                                                        <Input type='text' suffix="บาทต่อตารางวา" disabled
                                                            onChange={(e) => setDataPostLand({ ...dataPostLand, landPrice: parseInt(e.target.value) })}></Input>
                                                    </Form.Item>

                                                    <Form.Item label='ราคารวม' name='resultLandPrice'>
                                                        <Input type='text' suffix="บาท" disabled
                                                            onChange={(e) => setDataPostLand({ ...dataPostLand, resultLandPrice: parseInt(e.target.value) })}></Input>
                                                    </Form.Item>
                                                </>
                                            )}
                                        </>
                                    )}
                                </Col>

                                <Col className="gutter-row" span={12}>

                                    {/* โลนรถ */}

                                    {getData && selectProduct?.productTypeId !== 2 && (
                                        <>
                                            <Form.Item label='ประเภทขอกู้รถ' name='productLoanType'
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input !',
                                                    },]}>
                                                <Select
                                                    placeholder={'เลือก'}
                                                    onChange={(value) => { handleChangeType(value) }}
                                                    options={productLoanTypeCar}
                                                />
                                            </Form.Item>
                                            {
                                                dataPost2.productTypeCar === 1 ?
                                                    <Form.Item label='ประเภทค่างวด' name='PLorCH'>
                                                        <Select
                                                            placeholder={'เลือก'}
                                                            // style={{ width: '240px', height: '40px' }}
                                                            onChange={(value) => { handleChangePLorCH(value) }}
                                                            options={[
                                                                {
                                                                    label: 'P-LOAN',
                                                                    value: 'p-loan',
                                                                },
                                                                {
                                                                    label: 'เช่าซื้อ',
                                                                    value: 'เช่าซื้อ',
                                                                },
                                                                {
                                                                    label: 'เช่าซื้อ(0.84)-(0.75)',
                                                                    value: 'เช่าซื้อ1',
                                                                }
                                                            ]}
                                                        />
                                                    </Form.Item>
                                                    :
                                                    <Form.Item label='ประเภทค่างวด' name='PLorCH'>
                                                        <Select
                                                            placeholder={'เลือก'}
                                                            onChange={(value) => { handleChangePLorCH(value) }}
                                                            options={[
                                                                {
                                                                    label: 'P-LOAN',
                                                                    value: 'p-loan',
                                                                },
                                                                {
                                                                    label: 'เช่าซื้อ',
                                                                    value: 'เช่าซื้อ',
                                                                },
                                                            ]}
                                                        />
                                                    </Form.Item>
                                            }

                                            {/* P-LOAN */}
                                            {checkPCH && typeof checkPCH.PLorCH !== 'undefined' && (checkPCH.PLorCH === "p-loan") ? (
                                                <>
                                                    <Form.Item label='P-LOAN'>
                                                        <Button type="primary" onClick={getTable}>
                                                            ตารางค่างวด P-LOAN
                                                        </Button>
                                                        {
                                                            openTable ?
                                                                <Installment open={openTable} close={setOpenTable} money={handleMoney} />
                                                                : null
                                                        }
                                                    </Form.Item>

                                                    <Form.Item label='ยอดจัด' name='loanAmount'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <Input disabled style={{ color: 'black' }} suffix="บาท"
                                                            onChange={(e) => setMoney({ ...money, loanAmount: parseInt(e.target.value) })} />
                                                    </Form.Item>

                                                    <Form.Item label='จำนวนงวด' name='loanTerm'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <Input disabled style={{ color: 'black' }} suffix="งวด"
                                                            onChange={(e) => setMoney({ ...money, loanTerm: e.target.value })} />
                                                    </Form.Item>

                                                    <Form.Item label='งวดละ' name='monthlyPayment'>
                                                        <Input disabled style={{ color: 'black' }} suffix="บาท"
                                                            onChange={(e) => setMoney({ ...money, monthlyPayment: e.target.value })} />
                                                    </Form.Item>

                                                    <Form.Item label='ดอกเบี้ย' name='interest'>
                                                        <Input disabled style={{ color: 'black' }} suffix="บาท"
                                                            onChange={(e) => setMoney({ ...money, interest: e.target.value })} />
                                                    </Form.Item>

                                                    <Form.Item label='รวมราคา' name='installmentWithInterest'>
                                                        <Input disabled style={{ color: 'black' }} suffix="บาท"
                                                            defaultValue={currencyFormatOne(~~getData?.cars?.carLoanDetails?.monthlyPayment * ~~getData?.cars?.carLoanDetails?.loanTerm)}
                                                            onChange={(e) => setMoney({ ...money, installmentWithInterest: e.target.value })} />
                                                    </Form.Item>
                                                </>
                                            ) : null}

                                            {/* เช่าซื้อ */}

                                            {dataPost2 && typeof dataPost2.productTypeCar !== 'undefined' && (dataPost2.productTypeCar === 1 && checkPCH.PLorCH === "เช่าซื้อ") ? (
                                                <>
                                                    <Form.Item label='เช่าซื้อ'>
                                                        <Button type="primary" onClick={getTableHP}>
                                                            ตารางค่างวด เช่าซื้อ
                                                        </Button>
                                                        {
                                                            openTableHP ?
                                                                <HirePurchase open={openTableHP} close={setOpenTableHP} money={handleMoneyHP} />
                                                                : null
                                                        }
                                                    </Form.Item>

                                                    <Form.Item label='ยอดจัด' name='loanAmount'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !',
                                                            },]}>
                                                        <Input disabled style={{ color: 'black' }} suffix="บาท"
                                                            onChange={(e) => setMoney({ ...money, loanAmount: parseInt(e.target.value) })} />
                                                    </Form.Item>

                                                    <Form.Item label='จำนวนงวด' name='loanTerm'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !',
                                                            },]}>
                                                        <Input disabled style={{ color: 'black' }} suffix="งวด"
                                                            onChange={(e) => setMoney({ ...money, loanTerm: e.target.value })} />
                                                    </Form.Item>

                                                    <Form.Item label='งวดละ' name='monthlyPayment'>
                                                        <Input disabled style={{ color: 'black' }} suffix="บาท"
                                                            onChange={(e) => setMoney({ ...money, monthlyPayment: e.target.value })} />
                                                    </Form.Item>

                                                    <Form.Item label='ดอกเบี้ย' name='interest'>
                                                        <Input disabled style={{ color: 'black' }} suffix="บาท"
                                                            onChange={(e) => setMoney({ ...money, interest: e.target.value })} />
                                                    </Form.Item>

                                                    <Form.Item label='ราคารวม' name='installmentWithInterest'>
                                                        <Input disabled style={{ color: 'black' }} suffix="บาท"
                                                            defaultValue={currencyFormatOne(~~getData?.carLoanDetails?.loanAmount + ~~getData?.carLoanDetails?.interest)}
                                                            onChange={(e) => setMoney({ ...money, installmentWithInterest: e.target.value })} />
                                                    </Form.Item>
                                                </>
                                            ) : dataPost2.productTypeCar === 1 && checkPCH.PLorCH === "เช่าซื้อ1" ?
                                                <>
                                                    <Form.Item label='ยอดจัด' name='loanAmount'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <Input type='number' suffix="บาท"
                                                            onChange={(e) => setMoney({ ...money, loanAmount: parseInt(e.target.value) })} />
                                                    </Form.Item>

                                                    <Form.Item label='อัตราดอกเบี้ย' name='interestRate'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <Select
                                                            placeholder={'เลือกอัตราดอกเบี้ย'}
                                                            style={{ height: '40px' }}
                                                            // onChange={(value) => { handleChangePLorCH(value) }}
                                                            onChange={(e) => setMoney({ ...money, interestRate: parseFloat(e) })}
                                                            options={[
                                                                {
                                                                    label: '0.84%',
                                                                    value: 0.84,
                                                                },
                                                                {
                                                                    label: '0.75%',
                                                                    value: 0.75,
                                                                },
                                                            ]}
                                                        />
                                                        {/* <Input type='number' suffix="% +VAT"
                                                        onChange={(e) => setMoney({ ...money, interestRate: parseFloat(e.target.value) })} /> */}
                                                    </Form.Item>

                                                    <Form.Item label='จำนวนงวด' name='loanTerm'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <Input type='number' suffix="งวด"
                                                            onChange={(e) => setMoney({ ...money, loanTerm: parseInt(e.target.value) })} />
                                                        {/* onChange={(e) => { car_Calculate(e) }} /> */}
                                                    </Form.Item>

                                                    <Form.Item label='งวดละ' name='monthlyPayment'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <Input type='number' name='monthlyPayment' suffix="บาท" disabled style={{ color: 'black' }}
                                                            onChange={(e) => setMoney({ ...money, monthlyPayment: parseInt(e.target.value) })} />
                                                    </Form.Item>

                                                    <Form.Item label='ดอกเบี้ย' name='interest'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <Input type='number' suffix="บาท" disabled style={{ color: 'black' }}
                                                            onChange={(e) => setMoney({ ...money, interest: parseInt(e.target.value) })} />
                                                    </Form.Item>

                                                    <Form.Item label='รวมราคา' name='installmentWithInterest'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <Input type='number' suffix="บาท" disabled style={{ color: 'black' }}
                                                            defaultValue={currencyFormatOne(~~getData?.cars?.carLoanDetails?.monthlyPayment * ~~getData?.cars?.carLoanDetails?.loanTerm)}
                                                            onChange={(e) => setMoney({ ...money, installmentWithInterest: parseInt(e.target.value) })} />
                                                    </Form.Item>
                                                </>
                                                : null
                                            }

                                            {dataPost2 && typeof dataPost2.productTypeCar !== 'undefined' && (dataPost2.productTypeCar === 2 && checkPCH.PLorCH === "เช่าซื้อ") ? (
                                                <>
                                                    <Form.Item label='ยอดจัด' name='loanAmount'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <Input type='number' suffix="บาท" name="loanAmount"
                                                            onChange={(e) => setMoney({ ...money, loanAmount: parseInt(e.target.value) })} />
                                                        {/* onChange={(e) => { car_Calculate(e) }} /> */}
                                                    </Form.Item>

                                                    <Form.Item label='อัตราดอกเบี้ย' name='interestRate'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <Input type='number' suffix="% +VAT" name="interestRate"
                                                            onChange={(e) => setMoney({ ...money, interestRate: parseFloat(e.target.value) })} />
                                                        {/* onChange={(e) => { car_Calculate(e) }} /> */}
                                                    </Form.Item>

                                                    <Form.Item label='จำนวนงวด' name='loanTerm'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <Input type='number' suffix="งวด" name="loanTerm"
                                                            onChange={(e) => setMoney({ ...money, loanTerm: parseInt(e.target.value) })} />
                                                        {/* onChange={(e) => { car_Calculate(e) }} /> */}
                                                    </Form.Item>

                                                    <Form.Item label='งวดละ' name='monthlyPayment'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <Input type='number' suffix="บาท" disabled style={{ color: 'black' }}
                                                            onChange={(e) => setMoney({ ...money, monthlyPayment: parseInt(e.target.value) })} />
                                                    </Form.Item>

                                                    <Form.Item label='ดอกเบี้ย' name='interest'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <Input type='number' suffix="บาท" disabled style={{ color: 'black' }}
                                                            onChange={(e) => setMoney({ ...money, interest: parseInt(e.target.value) })} />
                                                    </Form.Item>

                                                    <Form.Item label='รวมราคา' name='installmentWithInterest'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <Input type='number' suffix="บาท" disabled style={{ color: 'black' }}
                                                            defaultValue={currencyFormatOne(~~getData?.cars?.carLoanDetails?.monthlyPayment * ~~getData?.cars?.carLoanDetails?.loanTerm)}
                                                            onChange={(e) => setMoney({ ...money, installmentWithInterest: parseInt(e.target.value) })} />
                                                    </Form.Item>
                                                </>
                                            ) : null}

                                            {dataPost2 && typeof dataPost2.productTypeCar !== 'undefined' && (dataPost2.productTypeCar === 3 && checkPCH.PLorCH === "เช่าซื้อ") ? (
                                                <>
                                                    <Form.Item label='ยอดจัด' name='loanAmount'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <Input type='number' suffix="บาท"
                                                            onChange={(e) => setMoney({ ...money, loanAmount: parseInt(e.target.value) })} />
                                                    </Form.Item>

                                                    <Form.Item label='อัตราดอกเบี้ย' name='interestRate'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <Input type='number' suffix="% +VAT"
                                                            onChange={(e) => setMoney({ ...money, interestRate: parseFloat(e.target.value) })} />
                                                    </Form.Item>

                                                    <Form.Item label='จำนวนงวด' name='loanTerm'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <Input type='number' suffix="งวด"
                                                            onChange={(e) => setMoney({ ...money, loanTerm: parseInt(e.target.value) })} />
                                                        {/* onChange={(e) => { car_Calculate(e) }} /> */}
                                                    </Form.Item>

                                                    <Form.Item label='งวดละ' name='monthlyPayment'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <Input type='number' suffix="บาท" disabled style={{ color: 'black' }}
                                                            onChange={(e) => setMoney({ ...money, monthlyPayment: parseInt(e.target.value) })} />
                                                    </Form.Item>

                                                    <Form.Item label='ดอกเบี้ย' name='interest'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <Input type='number' suffix="บาท" disabled style={{ color: 'black' }}
                                                            onChange={(e) => setMoney({ ...money, interest: parseInt(e.target.value) })} />
                                                    </Form.Item>

                                                    <Form.Item label='รวมราคา' name='installmentWithInterest'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <Input type='number' suffix="บาท" disabled style={{ color: 'black' }}
                                                            defaultValue={currencyFormatOne(~~getData?.cars?.carLoanDetails?.monthlyPayment * ~~getData?.cars?.carLoanDetails?.loanTerm)}
                                                            onChange={(e) => setMoney({ ...money, installmentWithInterest: parseInt(e.target.value) })} />
                                                    </Form.Item>
                                                </>
                                            ) : null}

                                            {(moneyOld && moneyOld.productLoanType === 'ย้ายไฟแนนซ์') || (moneyOld && moneyOld.productLoanType === 'Pre-Aaprove-ย้ายไฟแนนซ์') ? (
                                                <>
                                                    <Form.Item label='ปิดไฟแนนซ์จาก' name='issuno'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <Input type='text'
                                                            style={{ color: 'black' }}
                                                            onChange={(e) => setMoneyOld({ ...moneyOld, issuno: e.target.value })} />
                                                    </Form.Item>
                                                    <Form.Item label='ยอดปิดไฟแนนซ์' name='oldLoanAmount'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <Input type='number' suffix="บาท"
                                                            onChange={(e) => setMoneyOld({ ...moneyOld, oldLoanAmount: parseInt(e.target.value) })} />
                                                    </Form.Item>

                                                    <Form.Item label='ค่างวด/เดือน' name='oldMonthlyPayment'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <Input type='number' suffix="บาท"
                                                            onChange={(e) => setMoneyOld({ ...moneyOld, oldMonthlyPayment: parseInt(e.target.value) })} />
                                                    </Form.Item>

                                                    <Form.Item label='จำนวนผ่อนกี่งวด' name='oldLoanTerm'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <Input type='number' suffix="งวด"
                                                            onChange={(e) => setMoneyOld({ ...moneyOld, oldLoanTerm: parseInt(e.target.value) })} />
                                                    </Form.Item>
                                                </>
                                            ) : null}
                                        </>
                                    )}
                                    {/* โลนที่ดิน */}

                                    {getData && selectProduct?.productTypeId === 2 && (
                                        <>
                                            <Form.Item label='ประเภทขอกู้ที่ดิน' name='productLoanLandType'
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input !',
                                                    },]}>
                                                <Select
                                                    placeholder={'เลือก'}
                                                    onChange={(value) => { handleChangeLandType(value) }}
                                                    options={productLoanTypeLand}
                                                />
                                            </Form.Item>

                                            <Form.Item label='ยอดจัด' name='loanAmountLand'
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input !'
                                                    },]}>
                                                <Input
                                                    type='number' suffix="บาท"
                                                    onChange={(e) => setMoneyLand({ ...moneyLand, loanAmountLand: parseInt(e.target.value) })} />
                                            </Form.Item>


                                            <Form.Item label='อัตราดอกเบี้ย' name='interestRateLand'
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input !'
                                                    },]}>
                                                <Input type='number' suffix="% +VAT" disabled
                                                    onChange={(e) => setMoneyLand({ ...moneyLand, interestRateLand: parseFloat(e.target.value) })} />
                                            </Form.Item>

                                            <Form.Item label='จำนวนงวด' name='loanLandTerm'
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input !'
                                                    },]}>
                                                <Input type='number' suffix="งวด"
                                                    onChange={(e) => setMoneyLand({ ...moneyLand, loanLandTerm: parseInt(e.target.value) })} />
                                            </Form.Item>

                                            <Form.Item label='งวดละ' name='monthlyPaymentLand'
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input !'
                                                    },]}>
                                                <Input type='number' suffix="บาท" disabled style={{ color: 'black' }}
                                                    onChange={(e) => setMoneyLand({ ...moneyLand, monthlyPaymentLand: parseInt(e.target.value) })} />
                                            </Form.Item>

                                            <Form.Item label='ดอกเบี้ย' name='interestLand'
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input !'
                                                    },]}>
                                                <Input type='number' suffix="บาท" disabled style={{ color: 'black' }}
                                                    onChange={(e) => setMoneyLand({ ...moneyLand, interestLand: parseInt(e.target.value) })} />
                                            </Form.Item>


                                            <Form.Item label='รวมราคา' name='installmentWithInterestLand'>
                                                <Input disabled style={{ color: 'black' }} suffix="บาท"
                                                    defaultValue={currencyFormatOne(~~getData?.lands?.landLoanDetails?.interestLand * ~~getData?.lands?.landLoanDetails?.monthlyPaymentLand)}
                                                    onChange={(e) => setMoney({ ...money, installmentWithInterestLand: parseInt(e.target.value) })} />
                                            </Form.Item>

                                            {(moneyOldLand && moneyOldLand?.productLoanLandType === 'ย้ายไฟแนนซ์') || (moneyOldLand && moneyOldLand?.productLoanLandType === 'Pre-Aaprove-ย้ายไฟแนนซ์') ? (
                                                <>
                                                    <Form.Item label='ย้ายไฟแนนซ์จาก' name='issuno'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <Input type='text'
                                                            style={{ color: 'black' }}
                                                            onChange={(e) => setMoneyOldLand({ ...moneyOldLand, issuno: e.target.value })} />
                                                    </Form.Item>
                                                    <Form.Item label='ยอดปิดไฟแนนซ์ที่ดิน' name='oldLandLoanAmount'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <Input type='number' suffix="บาท"
                                                            onChange={(e) => setMoneyOldLand({ ...moneyOldLand, oldLandLoanAmount: parseInt(e.target.value) })} />
                                                    </Form.Item>

                                                    <Form.Item label='ค่างวด/เดือน' name='oldLandMonthlyPayment'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <Input type='number' suffix="บาท"
                                                            onChange={(e) => setMoneyOldLand({ ...moneyOldLand, oldLandMonthlyPayment: parseInt(e.target.value) })} />
                                                    </Form.Item>

                                                    <Form.Item label='จำนวนผ่อนกี่งวด' name='oldLandLoanTerm'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <Input type='number' suffix="งวด"
                                                            onChange={(e) => setMoneyOldLand({ ...moneyOldLand, oldLandLoanTerm: parseInt(e.target.value) })} />
                                                    </Form.Item>
                                                </>
                                            ) : null}
                                        </>
                                    )}
                                    <Form.Item label='ผู้เสนอเคส' name='proposalBy'>
                                        <Input disabled style={{ color: 'black' }}
                                            onChange={(e) => setMoney({ ...money, proposalBy: e.target.value })} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Divider />
                            {
                                dt?.car === "car" ?
                                    <>
                                        <Row className='main' justify={'center'}>
                                            <aside style={{ width: '90%' }}>
                                                <div>
                                                    <Row justify={'left'}><b><h3><u>หน้าคู่มือ</u></h3></b></Row>
                                                    <b> <Form.Item
                                                        style={{ textAlign: 'center', width: '300px' }}
                                                    >
                                                        <Upload
                                                            accept="image/*"
                                                            multiple={true}
                                                            style={{ textAlign: 'center' }}
                                                            listType="picture-card"
                                                            fileList={fileListCustomerDocument}
                                                            onPreview={handlePreview}
                                                            onChange={handleChangeCustomerDocument}
                                                            beforeUpload={() => false}
                                                        >
                                                            {fileListCustomerDocument?.length >= 20 ? null : (
                                                                <div>
                                                                    <PlusOutlined />
                                                                    <div className="ant-upload-text">upload</div>
                                                                </div>
                                                            )}
                                                        </Upload>
                                                        <Modal open={previewVisible} onCancel={handleCancel1}>
                                                            <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                        </Modal>
                                                    </Form.Item>
                                                    </b>
                                                </div>
                                                <div>
                                                    <Row justify={'left'}><b><h3><u>ใบประเมิน / รูปรถ / สัญญาไฟแนนซ์เดิม / ใบเสร็จไฟแนนซ์เดิม</u></h3></b></Row>
                                                    <b> <Form.Item
                                                        style={{ textAlign: 'center', width: '500px' }}
                                                    >
                                                        <Upload
                                                            accept="image/*"
                                                            multiple={true}
                                                            style={{ textAlign: 'center' }}
                                                            listType="picture-card"
                                                            fileList={fileListCustomerCar}
                                                            onPreview={handlePreview}
                                                            onChange={handleChangeCustomerCar}
                                                            beforeUpload={() => false}
                                                        >
                                                            {fileListCustomerCar?.length >= 20 ? null : (
                                                                <div>
                                                                    <PlusOutlined />
                                                                    <div className="ant-upload-text">upload</div>
                                                                </div>
                                                            )}
                                                        </Upload>
                                                        <Modal open={previewVisible} onCancel={handleCancel1}>
                                                            <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                        </Modal>
                                                    </Form.Item>
                                                    </b>
                                                </div>
                                            </aside>
                                        </Row>
                                    </>
                                    :
                                    <>
                                        <Row className='main' justify={'center'}>
                                            <aside style={{ width: '90%' }}>
                                                <div>
                                                    <Row justify={'left'}><b><h3><u>รูปโฉนด/หน้า-หลัง</u></h3></b></Row>
                                                    <b> <Form.Item
                                                        style={{ textAlign: 'center', width: '300px' }}
                                                    >
                                                        <Upload
                                                            accept="image/*"
                                                            multiple={true}
                                                            style={{ textAlign: 'center' }}
                                                            listType="picture-card"
                                                            fileList={fileListCustomerDeed}
                                                            onPreview={handlePreview}
                                                            onChange={handleChangeCustomerDeed}
                                                            beforeUpload={() => false}
                                                        >
                                                            {fileListCustomerDeed?.length >= 20 ? null : (
                                                                <div>
                                                                    <PlusOutlined />
                                                                    <div className="ant-upload-text">upload</div>
                                                                </div>
                                                            )}
                                                        </Upload>
                                                        <Modal open={previewVisible} onCancel={handleCancel1}>
                                                            <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                        </Modal>
                                                    </Form.Item>
                                                    </b>
                                                </div>
                                                <div>
                                                    <Row justify={'left'}><b><h3><u>ใบแบ่งใบต่อ</u></h3></b></Row>
                                                    <b> <Form.Item
                                                        style={{ textAlign: 'center', width: '500px' }}
                                                    >
                                                        <Upload
                                                            accept="image/*"
                                                            multiple={true}
                                                            style={{ textAlign: 'center' }}
                                                            listType="picture-card"
                                                            fileList={fileListCustomerLeafDivider}
                                                            onPreview={handlePreview}
                                                            onChange={handleChangeCustomerLeafDivider}
                                                            beforeUpload={() => false}
                                                        >
                                                            {fileListCustomerLeafDivider?.length >= 20 ? null : (
                                                                <div>
                                                                    <PlusOutlined />
                                                                    <div className="ant-upload-text">upload</div>
                                                                </div>
                                                            )}
                                                        </Upload>
                                                        <Modal open={previewVisible} onCancel={handleCancel1}>
                                                            <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                        </Modal>
                                                    </Form.Item>
                                                    </b>
                                                </div>
                                            </aside>
                                        </Row>
                                        <Row className='main' justify={'center'}>
                                            <aside style={{ width: '90%' }}>
                                                <div>
                                                    <Row justify={'left'}><b><h3><u>ระวาง</u></h3></b></Row>
                                                    <b> <Form.Item
                                                        style={{ textAlign: 'center', width: '300px' }}
                                                    >
                                                        <Upload
                                                            accept="image/*"
                                                            multiple={true}
                                                            style={{ textAlign: 'center' }}
                                                            listType="picture-card"
                                                            fileList={fileListCustomerBurden}
                                                            onPreview={handlePreview}
                                                            onChange={handleChangeCustomerBurden}
                                                            beforeUpload={() => false}
                                                        >
                                                            {fileListCustomerBurden?.length >= 20 ? null : (
                                                                <div>
                                                                    <PlusOutlined />
                                                                    <div className="ant-upload-text">upload</div>
                                                                </div>
                                                            )}
                                                        </Upload>
                                                        <Modal open={previewVisible} onCancel={handleCancel1}>
                                                            <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                        </Modal>
                                                    </Form.Item>
                                                    </b>
                                                </div>
                                                <div>
                                                    <Row justify={'left'}><b><h3><u>ใบประเมิน/รูปที่ดิน/สิ่งปลูกสร้าง-ที่ดินปัจจุบัน</u></h3></b></Row>
                                                    <b> <Form.Item
                                                        style={{ textAlign: 'center', width: '500px' }}
                                                    >
                                                        <Upload
                                                            accept="image/*"
                                                            multiple={true}
                                                            style={{ textAlign: 'center' }}
                                                            listType="picture-card"
                                                            fileList={fileListCustomerLand}
                                                            onPreview={handlePreview}
                                                            onChange={handleChangeCustomerLand}
                                                            beforeUpload={() => false}
                                                        >
                                                            {fileListCustomerLand?.length >= 20 ? null : (
                                                                <div>
                                                                    <PlusOutlined />
                                                                    <div className="ant-upload-text">upload</div>
                                                                </div>
                                                            )}
                                                        </Upload>
                                                        <Modal open={previewVisible} onCancel={handleCancel1}>
                                                            <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                        </Modal>
                                                    </Form.Item>
                                                    </b>
                                                </div>
                                            </aside>
                                        </Row>
                                    </>
                            }
                            <Divider />
                            <Row justify={'center'}>
                                <Space>
                                    <Button type="primary" danger onClick={handleCancel}>
                                        ยกเลิก
                                    </Button>
                                    <Button type="primary" htmlType="submit" style={{ background: 'green' }}>
                                        บันทึก
                                    </Button>
                                </Space>
                            </Row>
                        </Form>
                    </Spin>
                </Card>
            </Row >
            {contextHolder}
        </div>
    )
}
