import React, { useState, useEffect, useRef } from "react";
import { Modal, Divider, Row, Col, FloatButton, message, Spin, Radio, notification } from "antd";
import { DownloadOutlined, CloseOutlined, ExclamationCircleFilled, SendOutlined } from "@ant-design/icons";
import axios from "axios";
import logo from "../../../../assets/images/logo.png";
import "../../../css/MobileActive.css";
import { getcontnoJojonoi, loadCuscarceo, loadDetailAunSendPG, loadDetailBoss } from "../../../file_mid/all_api";
import html2canvas from "html2canvas";
import moment from "moment";
import MemoOne from "./MemoOne";
import Memo from "./Memo";
import dayjs from "dayjs";
import YellowCardApproval from "./YellowCardApproval";

const WaitApproval = ({ open, close, continoRe, id, data, onChangeStatus, status }) => {
  const [dataArr, setDataArr] = useState([]);
  const elementRef = useRef(null);
  const elementRef2 = useRef(null);
  const [dataApproved, setDataApproved] = useState();
  const [sendForshow, setSendForshow] = useState("0");
  const [loading, setLoading] = useState();
  const { confirm } = Modal;
  const token = localStorage.getItem("token");
  const [isModalMemo, setIsModalMemo] = useState(false);
  const [yellowCardApproval, setYellowCardApproval] = useState(false);
  const [isModalMemoOne, setIsModalMemoOne] = useState(true);
  const [keys, setKeys] = useState(1);
  const [carsApproveve, setCarsApproveve] = useState();
  const [dataDB2, setDataDB2] = useState();
  const [outstandingdebt, setOutstandingdebt] = useState(); // outstandingdebt มูลหนี้คงเหลือ
  const [deductdiscount, setDeductdiscount] = useState(); // deductdiscount หักส่วนลด
  const [remaining, setRemaining] = useState(); // remaining  คงเหลือ
  const [pay, setPay] = useState(); // pay   จ่ายเงิน
  const [Excellent, setExcellent] = useState(); // Excellent   ยอดจัด
  const [value4, setValue4] = useState("1");
  const [isLastIndex, setIsLastIndex] = useState();
  const [resetPage, setResetPage] = useState();
  const [api, contextHolder] = notification.useNotification();

  const optionsWithDisabled = [
    {
      label: "เอกสารอนุมัติแล้ว",
      value: "1",
    },
    {
      label: "ใบเหลือง",
      value: "2",
    },
  ];

  // console.log("dataApproved", dataApproved)
  // console.log("status", status)
  // console.log("dataArr", dataArr)
  // console.log("value4", value4)
  // console.log("data", data)
  // console.log("data", data)

  useEffect(() => {
    if (status === "close") {
      loadDataCloseBunShe(continoRe)
    } else {
      loadData();
    }
  }, [resetPage]);

  useEffect(() => {
    loadDataReAun();
    // console.log(elementRef2.current); //
  }, [keys, sendForshow, value4]);

  useEffect(() => {
    if (carsApproveve?.id > 0) {
      setOutstandingdebt(
        carsApproveve?.balanc ? ~~carsApproveve?.balanc : null
      );
      setDeductdiscount(
        carsApproveve?.balanc
          ? ~~carsApproveve?.balanc - (~~carsApproveve?.approvedLoanAmount + ~~carsApproveve?.newDamt + ~~carsApproveve?.followPay)
          : null
      );
      setRemaining(
        carsApproveve?.balanc ? ~~outstandingdebt - ~~deductdiscount : null
      );
      setPay(
        carsApproveve?.newDamt
          ? ~~carsApproveve?.newDamt + ~~carsApproveve?.followPay
          : null
      );
      setExcellent(
        carsApproveve?.approvedLoanAmount
          ? ~~carsApproveve?.approvedLoanAmount
          : null
      );
      {
        /* 
                มูลหนี้ balance
                หักส่วนลด (balance-approvedLoanAmount)+(newDamt+followPay)
                คงเหลือ  มูลหนี้ - หักส่วนลด
                จ่ายเงิน (newDamt+followPay)
                ยอดจัด approvedLoanAmount
            */
      }
    }
  }, [deductdiscount, carsApproveve]);

  const handleClose = () => {
    close(false);
  };

  const handleResetPage = () => {
    setResetPage(dayjs());
    setValue4("1")
  }

  const loadData = async () => {
    setLoading(true);
    console.log("continoRe", continoRe)
    await axios.get(`${getcontnoJojonoi}/${continoRe}`)
      .then(async (resData) => {
        if (resData?.data) {
          // console.log("getcontnoJojonoi", resData?.data);
          setDataArr(resData?.data);
          setLoading(false);
        } else {
          setDataArr([]);
        }
      })
      .catch((err) => console.log(err));

    const tk = JSON.parse(token);
    const url = loadDetailBoss;
    const headers = {
      Authorization: `Bearer ${tk}`,
      Menu: JSON.stringify("25"),
    };
    await axios.post(url, { id: id }, { headers: headers })
      .then(async (res1) => {
        if (res1.data) {
          setDataApproved(res1.data);
          setIsLastIndex(res1.data?.note?.length - 1)
          // console.log("load-detail-boss", res1.data);
          setLoading(false);
        } else {
          setDataApproved([]);
        }
      })
      .catch((err) => console.log(err));
    setLoading(false);
  };


  const loadDataReAun = async () => {
    setLoading(true);
    axios
      .post(loadDetailAunSendPG, { contno: continoRe })
      .then((res) => {
        if (res.status === 200) {
          console.log("res.dataNewPageAun", res.data);
          setCarsApproveve(res?.data?.carsApproveve);
          setDataDB2(res.data?.dataDB2);
          // calData(res?.data?.carsApproveve);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
      });
  };

  const currencyFormatOne = (amount) => {
    if (amount) {
      return Number(amount)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,");
    } else {
      return 0;
    }
  };

  const loadDataCloseBunShe = async (data) => {
    setLoading(true)
    console.log("data", data)
    await axios.post(loadCuscarceo, { ISSUNO: data })
      .then(async (res) => {
        if (res.status === 200) {
          // console.log("res.data", res.data)
          setDataApproved(res.data);
          setIsLastIndex(res.data?.carsApproveCeo?.note?.length - 1)
          setLoading(false)
        } else if (res.status === 201) {
          notFoundData("top")
          setLoading(false)
        }
      }).catch((err) => {
        console.log(err)
        setLoading(false)
      })
  }

  const notFoundData = (placement) => {
    api.warning({
      message: <b>ไม่พบข้อมูลการเสนอเคส!</b>,
      placement,
    });
  }

  const htmlToImageConvert = (value, typeDoc) => {
    html2canvas(value.current, { cacheBust: false })
      .then((canvas) => {
        canvas.toBlob((blob) => {
          console.log(blob);
          if (blob) {
            const file = new File(
              [blob],
              `${typeDoc} ${continoRe} ${moment()
                .add(543, "year")
                .format("DD/MM/YYYY")}.png`,
              { type: "image/png" }
            );

            const link = document.createElement("a");
            link.href = URL.createObjectURL(file);
            link.download = file.name;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            message.success(`บันทึกรูปภาพสำเร็จ ${continoRe}`);
          } else {
            message.error(
              `ไม่สามารถบันทึกรูปภาพได้โปรดเข้าออกใหม่ ${continoRe}`
            );
          }
        }, "image/png");
      })
      .catch((err) => {
        console.log("เกิดข้อผิดพลาด ", err);
        message.error("ยกเลิกการบันทึกภาพ");
      });
  };

  const showConfirmSave = () => {
    confirm({
      title: "คำร้องขอบันทึกรูปภาพ",
      icon: <ExclamationCircleFilled />,
      content: "คุณต้องการบันทึกรูปภาพใช่หรือไม่",
      okText: "บันทึก",
      cancelText: "ยกเลิก",
      onOk() {
        if (keys === "1") {
          htmlToImageConvert(elementRef, "การ์ดลูกหนี้ เลขที่สัญญา:");
        } else if (keys === "2") {
          htmlToImageConvert(elementRef2, "ใบเหลือง เลขที่สัญญา:");
        }
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const dataCustmast = (custmast, index) => {
    return (
      <>
        <Row key={custmast?.CONTNO ? custmast?.CONTNO + index : Math.random()}>
          <Col span={24} style={{ textAlign: "center" }}>
            <div label="เลขที่สัญญา">
              {" "}
              <b>เลขที่สัญญา : </b>
              {!custmast?.custmast?.CONTNO ? "-" : custmast?.custmast?.CONTNO}
            </div>
            <div label="ตัดสด ณ วันที่">
              {" "}
              <b>ตัดสด ณ วันที่ : </b>
              {!dataApproved?.firstTime ||
                dataApproved?.firstTime === "0001-01-01T00:00:00Z"
                ? "-"
                : moment(dataApproved.firstTime)
                  .add(543, "year")
                  .format("DD/MM/YYYY")}
            </div>
          </Col>
          <Col span={24} style={{ textAlign: "center" }}>
            <div label="ชื่อผู้ทำสัญญา" style={{ marginTop: "10px" }}>
              {" "}
              <b>ชื่อผู้ทำสัญญา : </b>
              {!custmast.custmast.SNAM ? "-" : custmast.custmast.SNAM}{" "}
              {!custmast.custmast.NAME1 ? "-" : custmast.custmast.NAME1}{" "}
              {!custmast.custmast.NAME2 ? "-" : custmast.custmast.NAME2}
            </div>
            <div label="เกรด">
              {" "}
              <b>เกรด : </b>
              {!custmast.custmast.GRDCOD ? "-" : custmast.custmast.GRDCOD}
            </div>
            <div label="เบอร์โทรผู้ทำสัญญา">
              {" "}
              <b>เบอร์โทรผู้ทำสัญญา : </b>
              {!custmast.custmast.TELP ? "-" : custmast.custmast.TELP}
            </div>
            <div label="ชื่อผู้ขาย">
              {" "}
              <b>ชื่อผู้ขาย : </b>
              {!custmast.custmast.SALCOD ? "-" : custmast.custmast.SALCOD}
            </div>
          </Col>

          <Divider
            orientation="center"
            align={"middle"}
            style={{ marginTop: "10px", marginBottom: "10px" }}
          >
            <b>รายละเอียดรถ</b>
          </Divider>

          <Col span={24} style={{ textAlign: "center" }}>
            <div label="ยี่ห้อ">
              {" "}
              <b>ยี่ห้อ : </b>
              {!custmast.custmast.TYPE ? "-" : custmast.custmast.TYPE}
            </div>
            <div label="รุ่น">
              {" "}
              <b>รุ่น : </b>
              {!custmast.custmast.MODEL ? "-" : custmast.custmast.MODEL}
            </div>
            <div label="สี">
              {" "}
              <b>สี : </b>
              {!custmast.custmast.COLOR ? "-" : custmast.custmast.COLOR}
            </div>
            <div label="ชนิดรถ">
              {" "}
              <b>ชนิดรถ : </b>
              {!custmast.custmast.BAAB ? "-" : custmast.custmast.BAAB}
            </div>
            <div label="ทะเบียน">
              {" "}
              <b>ทะเบียน : </b>
              {!custmast.custmast.REGNO ? "-" : custmast.custmast.REGNO}
            </div>
            <div label="ปีรถ">
              {" "}
              <b>ปีรถ : </b>
              {!custmast.custmast.MANUYR ? "-" : custmast.custmast.MANUYR}
            </div>
            <div label="เลขตัวถังรถ">
              {" "}
              <b>เลขตัวถังรถ : </b>
              {!custmast.custmast.STRNO ? "-" : custmast.custmast.STRNO}
            </div>
          </Col>
        </Row>
      </>
    );
  };

  const dataBu1 = (bu1, index) => {
    return (
      <Row key={bu1.id ? bu1.id : Math.random()}>
        <Col span={24} style={{ textAlign: "center" }}>
          <Divider
            orientation="center"
            align={"middle"}
            style={{ marginTop: "10px", marginBottom: "10px" }}
          >
            <b>รายละเอียดสัญญา</b>
          </Divider>
          <div label="ยอดจัดครั้งที่แล้ว">
            <b>ยอดจัดครั้งที่แล้ว : </b>
            {!bu1.bu1.tcshprc ? "-" : currencyFormatOne(bu1.bu1.tcshprc)} บาท
          </div>
          <div label="ราคาเช่าซื้อครั้งที่แล้ว">
            <b>ราคาเช่าซื้อครั้งที่แล้ว : </b>
            {!bu1.bu1.balanc ? "-" : currencyFormatOne(bu1.bu1.balanc)} บาท
          </div>
          <div label="ยอดชำระมาแล้วทั้งหมด">
            <b>คงเหลือ : </b>
            {!bu1.bu1.totalpayment
              ? "-"
              : currencyFormatOne(bu1.bu1.totalpayment)}{" "}
            บาท
          </div>

          <div label="ล/น คงเหลือรวม ">
            <b>ล/น คงเหลือรวม : </b>
            {!bu1.bu1.neekong ? "-" : currencyFormatOne(bu1.bu1.neekong)} บาท
          </div>

          <div label="เบี้ยปรับ">
            <b>เบี้ยปรับ : </b>
            {!bu1.bu1.beeypup ? "-" : currencyFormatOne(bu1.bu1.beeypup)} บาท
          </div>

          <div label="ลูกหนี้อื่น ๆ">
            <b>ลูกหนี้อื่น ๆ : </b>
            {!bu1.bu1.OTHR ? "-" : currencyFormatOne(bu1.bu1.OTHR)} บาท
          </div>

          <div label="จำนวนงวดที่ผ่อนมาแล้ว">
            <b>จำนวนงวดที่ผ่อนมาแล้ว : </b>
            {!bu1.bu1.NOPAY ? "-" : bu1.bu1.NOPAY} งวด
          </div>
          <div label="จำนวนงวดทั้งหมด">
            <b>จำนวนงวดทั้งหมด : </b>
            {!bu1.bu1.nopay ? "-" : bu1.bu1.nopay} งวด
          </div>
          <div label="ตัดสด งวดที่">
            <b>ตัดสด งวดที่ : </b>
            {!bu1.bu1.req_no ? "-" : bu1.bu1.req_no}
          </div>
        </Col>
        <Col
          span={24}
          align={"center"}
          style={{
            marginTop: "20px",
            fontSize: "medium",
          }}
        >
          <b style={{ color: "red" }}>
            ยอดรีไฟแนนซ์เพื่อจัดใหม่ :{" "}
            {dataArr.re1 > dataArr.re2
              ? currencyFormatOne(dataArr.re2)
              : currencyFormatOne(dataArr.re1)}
            {" บาท"}
          </b>
        </Col>
      </Row>
    );
  };

  const onChange4 = (e) => {
    setValue4(e.target.value);
    setKeys(e.target.value);
  };

  const handleOnClick = () => {

    if (!carsApproveve?.pgLoanTerm || !carsApproveve?.installment) {
      setIsModalMemo(true);
    } else {
      setYellowCardApproval(true)
    }

  }
  return (
    <Modal
      title="เอกสารขอรีไฟแนนซ์"
      open={open}
      onCancel={handleClose}
      width={850}
      footer={[null]}
    >
      <Spin spinning={loading} size="large" tip=" Loading... ">
        <Row justify={'center'}>
          {<Radio.Group
            options={optionsWithDisabled}
            onChange={onChange4}
            value={value4}
            optionType="button"
            buttonStyle="solid"
          />}
        </Row>
        <Row justify={'center'}>
          {value4 === "1" ? (
            <>
              {dataApproved?.CONTNO || dataApproved?.ISSUNO ? (
                <div
                  ref={elementRef}
                  className="center"
                  style={{
                    backgroundColor: "#ffffff", // กำหนดสีพื้นหลัง
                    margin: "0 auto",
                    border: "1px solid #ccc", // กำหนดเส้นขอบ
                    fontFamily: "Arial, Helvetica, sans-serif",
                    borderCollapse: "collapse",
                  }}
                >
                  <Col span={24} style={{ marginLeft: "0.5%" }}>
                    <h5 style={{ opacity: 0.5 }}>
                      {moment().add(543, "year").format("DD/MM/YYYY")}
                    </h5>
                  </Col>
                  <Row className="main" align={"center"}>
                    <Col span={24} align={"center"}>
                      <img width={"50%"} src={logo} alt="Logo" />
                    </Col>
                    {
                      status !== "close" ?
                        <>
                          {dataArr?.custmast?.map((custmast, index) => {
                            return dataCustmast({ custmast, index });
                          })}
                        </>
                        :
                        <>
                          <Row>
                            <Col span={24} style={{ textAlign: "center" }}>
                              <div label="เลขที่สัญญา">
                                {" "}
                                <b>เลขที่สัญญา : </b>
                                {continoRe}
                              </div>
                              <div label="ตัดสด ณ วันที่">
                                {" "}
                                <b>ตัดสด ณ วันที่ : </b>
                                {moment(dataApproved?.firstTime).add(543, "year").format("DD/MM/YYYY")}
                              </div>
                            </Col>
                            <Col span={24} style={{ textAlign: "center" }}>
                              <div label="ชื่อผู้ทำสัญญา" style={{ marginTop: "10px" }}>
                                {" "}
                                <b>ชื่อผู้ทำสัญญา : </b>
                                {dataApproved?.customer?.snam}{dataApproved?.customer?.firstname} {dataApproved?.customer?.lastname}
                              </div>
                              <div label="เกรด">
                                {" "}
                                <b>เกรด : </b>
                                {dataApproved?.carsApproveCeo?.GRDCOD}
                              </div>
                              <div label="เบอร์โทรผู้ทำสัญญา">
                                {" "}
                                <b>เบอร์โทรผู้ทำสัญญา : </b>
                                {dataApproved.phones?.length > 0 ? dataApproved.phones[0].telp : ""}
                              </div>
                              <div label="ชื่อผู้ขาย">
                                {" "}
                                <b>ชื่อผู้ขาย : </b>
                                {dataApproved?.carsApproveCeo?.proposalBy}
                              </div>
                            </Col>

                            <Divider
                              orientation="center"
                              align={"middle"}
                              style={{ marginTop: "10px", marginBottom: "10px" }}
                            >
                              <b>รายละเอียดรถ</b>
                            </Divider>

                            <Col span={24} style={{ textAlign: "center" }}>
                              <div label="ยี่ห้อ">
                                {" "}
                                <b>ยี่ห้อ : </b>
                                {dataApproved?.carBrand}
                              </div>
                              <div label="รุ่น">
                                {" "}
                                <b>รุ่น : </b>
                                {dataApproved?.carModel}
                              </div>
                              <div label="สี">
                                {" "}
                                <b>สี : </b>
                                {dataApproved?.carColor}
                              </div>
                              <div label="ชนิดรถ">
                                {" "}
                                <b>ชนิดรถ : </b>
                                {dataApproved?.carBaab}
                              </div>
                              <div label="ทะเบียน">
                                {" "}
                                <b>ทะเบียน : </b>
                                {dataApproved?.carPlateNumber}
                              </div>
                              <div label="ปีรถ">
                                {" "}
                                <b>ปีรถ : </b>
                                {dataApproved?.carYear}
                              </div>
                              <div label="เลขตัวถังรถ">
                                {" "}
                                <b>เลขตัวถังรถ : </b>
                                {dataApproved?.carEngineNumber}
                              </div>
                            </Col>
                          </Row>
                        </>
                    }
                    {
                      status !== "close" ?
                        <>
                          {dataArr?.bu1?.map((bu1, index) => {
                            return dataBu1({ bu1, index });
                          })}
                        </>
                        :
                        <>
                          <Row>
                            <Col span={24} style={{ textAlign: "center" }}>
                              <Divider
                                orientation="center"
                                align={"middle"}
                                style={{ marginTop: "10px", marginBottom: "10px" }}
                              >
                                <b>รายละเอียดสัญญา</b>
                              </Divider>
                              <div label="ยอดจัดครั้งที่แล้ว">
                                <b>ยอดจัดครั้งที่แล้ว : </b>
                                {currencyFormatOne(dataApproved?.carsApproveCeo?.tcshprc)} บาท
                              </div>
                              <div label="ราคาเช่าซื้อครั้งที่แล้ว">
                                <b>ราคาเช่าซื้อครั้งที่แล้ว : </b>
                                {currencyFormatOne(dataApproved?.carsApproveCeo?.balanc)} บาท
                              </div>
                              <div label="ยอดชำระมาแล้วทั้งหมด">
                                <b>คงเหลือ : </b>
                                {currencyFormatOne(dataApproved?.carsApproveCeo?.totalpayment)} บาท
                              </div>
                              <div label="ล/น คงเหลือรวม ">
                                <b>ล/น คงเหลือรวม : </b>
                                {currencyFormatOne(dataApproved?.carsApproveCeo?.neekong)} บาท
                              </div>
                              <div label="เบี้ยปรับ">
                                <b>เบี้ยปรับ : </b>
                                {currencyFormatOne(dataApproved?.carsApproveCeo?.beeypup)} บาท
                              </div>
                              <div label="ลูกหนี้อื่น ๆ">
                                <b>ลูกหนี้อื่น ๆ : </b>
                                {currencyFormatOne(dataApproved?.carsApproveCeo?.OTHR)} บาท
                              </div>
                              <div label="จำนวนงวดที่ผ่อนมาแล้ว">
                                <b>จำนวนงวดที่ผ่อนมาแล้ว : </b>
                                {dataApproved?.carsApproveCeo?.NOPAY} งวด
                              </div>
                              <div label="จำนวนงวดทั้งหมด">
                                <b>จำนวนงวดทั้งหมด : </b>
                                {dataApproved?.carsApproveCeo?.nopays} งวด
                              </div>
                              <div label="ตัดสด งวดที่">
                                <b>ตัดสด งวดที่ : </b>
                                {dataApproved?.carsApproveCeo?.reqNo}
                              </div>
                            </Col>
                            <Col
                              span={24}
                              align={"center"}
                              style={{
                                marginTop: "20px",
                                fontSize: "medium",
                              }}
                            >
                              <b style={{ color: "red" }}>
                                ยอดรีไฟแนนซ์เพื่อจัดใหม่ :{" "}
                                {dataApproved?.carsApproveCeo?.re1}
                                {" บาท"}
                              </b>
                            </Col>
                          </Row>
                        </>
                    }
                  </Row>
                  <Divider
                    orientation="center"
                    align={"middle"}
                    style={{ marginTop: "10px", marginBottom: "10px" }}
                  >
                    <b>ค่าอนุมัติใหม่</b>
                  </Divider>
                  {
                    status !== "close" ?
                      <>
                        <Row>
                          <Col span={24} style={{ textAlign: "center" }}>
                            <div
                              style={{
                                fontFamily: "Arial, Helvetica, sans-serif",
                              }}
                            >
                              <b>ค่างวดที่ต้องชำระ : </b>
                              {!dataApproved?.newDamt
                                ? "-"
                                : currencyFormatOne(dataApproved?.newDamt)}{" "}
                              บาท
                            </div>
                            <div
                              label="ค่าติดตาม"
                              style={{
                                fontFamily: "Arial, Helvetica, sans-serif",
                              }}
                            >
                              <b>ค่าติดตาม : </b>
                              {!dataApproved?.followPay
                                ? "-"
                                : currencyFormatOne(dataApproved?.followPay)}{" "}
                              บาท
                            </div>
                            <div
                              label="เงินต้น"
                              style={{
                                fontFamily: "Arial, Helvetica, sans-serif",
                              }}
                            >
                              <b>เงินต้น : </b>
                              {!dataApproved?.approvedLoanAmount
                                ? "-"
                                : currencyFormatOne(dataApproved?.approvedLoanAmount)}{" "}
                              บาท
                            </div>
                            <div
                              label="จำนวนงวด"
                              style={{
                                fontFamily: "Arial, Helvetica, sans-serif",
                              }}
                            >
                              <b>จำนวนงวด : </b>
                              {!dataApproved?.approvedLoanTerm
                                ? null
                                : dataApproved?.approvedLoanTerm}{" "}
                              {" ถึง: "}
                              {!dataApproved?.approvedLoanTermTo
                                ? "-"
                                : dataApproved?.approvedLoanTermTo}{" "}
                              งวด
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={22}>
                            <div
                              className="center"
                              style={{
                                marginTop: "10px",
                                textWrap: "brek-word",
                                margin: "10px",
                              }}
                            >
                              <span style={{ marginTop: "20px" }}>
                                <b>หมายเหตุ :</b>{" "}
                              </span>
                              <br />
                              *(ค่างวด + ค่าติดตาม = {""}
                              {currencyFormatOne(
                                parseInt(dataApproved?.newDamt) +
                                parseInt(dataApproved?.followPay)
                              )}
                              ){" *"}
                              {dataApproved?.note?.length > 0
                                ? dataApproved?.note[isLastIndex]?.note
                                : null}
                            </div>
                          </Col>
                        </Row>
                      </>
                      :
                      <>
                        <Row>
                          <Col span={24} style={{ textAlign: "center" }}>
                            <div
                              style={{
                                fontFamily: "Arial, Helvetica, sans-serif",
                              }}
                            >
                              <b>ค่างวดที่ต้องชำระ : </b>
                              {currencyFormatOne(dataApproved?.carsApproveCeo?.newDamt)}{" "}
                              บาท
                            </div>
                            <div
                              label="ค่าติดตาม"
                              style={{
                                fontFamily: "Arial, Helvetica, sans-serif",
                              }}
                            >
                              <b>ค่าติดตาม : </b>
                              {currencyFormatOne(dataApproved?.carsApproveCeo?.followPay)}{" "}
                              บาท
                            </div>
                            <div
                              label="เงินต้น"
                              style={{
                                fontFamily: "Arial, Helvetica, sans-serif",
                              }}
                            >
                              <b>เงินต้น : </b>
                              {currencyFormatOne(dataApproved?.carsApproveCeo?.approvedLoanAmount)}{" "}
                              บาท
                            </div>
                            <div
                              label="จำนวนงวด"
                              style={{
                                fontFamily: "Arial, Helvetica, sans-serif",
                              }}
                            >
                              <b>จำนวนงวด : </b>
                              {dataApproved?.carsApproveCeo?.approvedLoanTerm}{" "}
                              {" ถึง: "}
                              {dataApproved?.carsApproveCeo?.approvedLoanTermTo}{" "}
                              งวด
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={22}>
                            <div
                              className="center"
                              style={{
                                marginTop: "10px",
                                textWrap: "brek-word",
                                margin: "10px",
                              }}
                            >
                              <span style={{ marginTop: "20px" }}>
                                <b>หมายเหตุ :</b>{" "}
                              </span>
                              <br />
                              *(ค่างวด + ค่าติดตาม = {""}
                              {currencyFormatOne(
                                parseInt(dataApproved?.carsApproveCeo?.newDamt) +
                                parseInt(dataApproved?.carsApproveCeo?.followPay)
                              )}
                              ){" *"}
                              {dataApproved?.carsApproveCeo?.note?.length > 0
                                ? dataApproved?.carsApproveCeo?.note[isLastIndex]?.note
                                : null}
                            </div>
                          </Col>
                        </Row>
                      </>
                  }

                </div>
              ) :
                null
              }
            </>
          ) : (
            <>
              {
                (!data?.sendPg && carsApproveve?.pgLoanTerm === "") || (!data?.sendPg && carsApproveve?.pgLoanTerm === null) ?
                  <Row style={{ width: '100%' }}>
                    <Col span={24}>
                      <MemoOne
                        dataArr={dataArr}
                        close={setIsModalMemoOne}
                        closeModal={close}
                        keys={keys}
                        data={data}
                        onChangeStatus={onChangeStatus}
                        dataApproved={dataApproved}
                        sendForshow={sendForshow}
                        continoRe={continoRe}
                        carsApproveve={carsApproveve}
                        status={status}
                        handleResetPage={handleResetPage}
                      />
                    </Col>
                  </Row>
                  :
                  <>
                    <div ref={elementRef2} style={{ margin: "0 auto" }}>
                      {
                        isModalMemoOne ?
                          <MemoOne
                            // ref={elementRef2}
                            open={isModalMemoOne}
                            closeModal={close}
                            close={setIsModalMemoOne}
                            keys={keys}
                            data={data}
                            dataApproved={dataApproved}
                            sendForshow={sendForshow}
                            carsApproveve={carsApproveve}
                            status={status}
                            handleResetPage={handleResetPage}
                          // checkLoadPage1={checkLoadPage}
                          />
                          : null
                      }
                    </div>
                  </>
              }
            </>
          )}
        </Row>
        {keys === "2" ?
          null
          :
          <FloatButton.Group open={open} style={{ right: 24 }}>
            <FloatButton
              icon={<DownloadOutlined />}
              title="บันทึกรูปภาพ"
              style={{ boxShadow: "0 4px 8px" }}
              onClick={showConfirmSave}
            />
            {dataApproved?.approvalStatus ===3 && !dataApproved?.sendPg
              ?
              <>
                {keys === "2" ?
                  null
                  :
                  <>
                    (
                    <FloatButton
                      icon={<SendOutlined />}
                      title="ส่งข้อมูลให้คุณกั๊ง"
                      style={{ boxShadow: "0 4px 8px" }}
                      onClick={handleOnClick}
                    >
                    </FloatButton>
                    )
                  </>
                }
              </>
              :
              null
            }
            <FloatButton
              icon={<CloseOutlined />}
              title="ปิด"
              style={{ boxShadow: "0 4px 8px", zIndex: 200 }}
              onClick={() => {
                handleClose();
                console.log("ปิด modal");
              }}
            ></FloatButton>
          </FloatButton.Group>
        }
      </Spin>
      {
        isModalMemo ? (
          <Memo
            open={isModalMemo}
            close={setIsModalMemo}
            closeModal={close}
            dataArr={dataArr}
            id={id}
            keys={keys}
            data={data}
            onChangeStatus={onChangeStatus}
            status={status}
            setCloseModal={handleClose}
          />
        ) : null
      }
      {
        yellowCardApproval ? (
          <YellowCardApproval
            open={yellowCardApproval}
            close={setYellowCardApproval}
            carsApproveve={carsApproveve}
            dataDB2={dataDB2}
            dataTonew={carsApproveve}
            closeModal={close}
            onChangeStatus={onChangeStatus}
            status={status}
          />
        ) : null
      }
      {contextHolder}
    </Modal >
  );
};

export default WaitApproval;
