import React, { useEffect, useState } from 'react'
import { Button, Input, Form, DatePicker, Card, Col, Row, message, Select, Radio, Spin, Space, Divider, Modal } from 'antd';
import dayjs from 'dayjs';
import { checkSNAM } from '../../file_mid/condition';


function CustomerLand({ page, changepage, close, data }) {

  const currentDate = dayjs();
  const [form] = Form.useForm()
  // const [CheckIdCard, setCheckIdCard] = useState(false)
  const [dataPost, setDataPost] = useState({}) // customer
  const [dataPhone, setDataPhone] = useState({}) // phone
  const [loading, setLoading] = useState(false)
  // const [step, setStep] = useState(0) // ตรวจสอบการกดตรวจบัตร

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  const addCustomers = localStorage.getItem('addCustomer')
  const addCustomers2 = localStorage.getItem('addCustomer2')
  const addPhones = localStorage.getItem('addPhone')
  const addPhones2 = localStorage.getItem('addPhone2')
  const dataAddCustomer = JSON.parse(addCustomers)
  const dataAddCustomer2 = JSON.parse(addCustomers2)
  const dataAddPhone = JSON.parse(addPhones)
  const dataAddPhone2 = JSON.parse(addPhones2)
  const [holds, setHolds] = useState();

  useEffect(() => {
    // console.log("viewss",dataAddCustomer)
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [])

  useEffect(() => {
    if (dataAddCustomer2) {
      if (dataAddPhone2?.length > 0) {
        setDataPhone(dataAddPhone2[0])
        form.setFieldsValue(
          {
            ...dataAddCustomer2, ...dataAddPhone2[0],
            birthdate: dataAddCustomer2?.birthdate ? dayjs(dataAddCustomer2?.birthdate) : null,
            issudt: dataAddCustomer2?.issudt ? dayjs(dataAddCustomer2?.issudt) : null,
            expdt: dataAddCustomer2?.expdt ? dayjs(dataAddCustomer2?.expdt) : null,
          }
        )
      } else {
        setDataPhone([])
        form.setFieldsValue(
          {
            ...dataAddCustomer2,
            birthdate: dataAddCustomer2?.birthdate ? dayjs(dataAddCustomer2?.birthdate) : null,
            issudt: dataAddCustomer2?.issudt ? dayjs(dataAddCustomer2?.issudt) : null,
            expdt: dataAddCustomer2?.expdt ? dayjs(dataAddCustomer2?.expdt) : null,
          }
        )
      }
      setDataPost({ ...dataAddCustomer2 })
    } else {
      if (dataAddPhone?.length > 0) {
        setDataPhone(dataAddPhone[0])
        form.setFieldsValue(
          {
            ...dataAddCustomer, ...dataAddPhone[0],
            birthdate: dataAddCustomer?.birthdate ? dayjs(dataAddCustomer?.birthdate) : null,
            issudt: dataAddCustomer?.issudt ? dayjs(dataAddCustomer?.issudt) : null,
            expdt: dataAddCustomer?.expdt ? dayjs(dataAddCustomer?.expdt) : null,
          }
        )
      } else {
        setDataPhone([])
        form.setFieldsValue(
          {
            ...dataAddCustomer,
            birthdate: dataAddCustomer?.birthdate ? dayjs(dataAddCustomer?.birthdate) : null,
            issudt: dataAddCustomer?.issudt ? dayjs(dataAddCustomer?.issudt) : null,
            expdt: dataAddCustomer?.expdt ? dayjs(dataAddCustomer?.expdt) : null,
          }
        )
      }
      if (dataAddCustomer?.nationalId === "" || dataAddCustomer?.nationalId === null) {
        if (checkSNAM(data.snam)) {
          console.log("1")
          setHolds("นิติบุคคล")
          setDataPost({ ...dataAddCustomer, nationalId: "นิติบุคคล" })
          form.setFieldsValue({
            nationalId: "นิติบุคคล"
          })
        } else {
          console.log("2")
          setHolds("บุคคลธรรมดา")
          setDataPost({ ...dataAddCustomer, nationalId: "บุคคลธรรมดา" })
          form.setFieldsValue({
            nationalId: "บุคคลธรรมดา"
          })
        }
      } else {
        form.setFieldsValue({
          ...dataAddCustomer,
          birthdate: dataAddCustomer?.birthdate ? dayjs(dataAddCustomer?.birthdate) : null,
          issudt: dataAddCustomer?.issudt ? dayjs(dataAddCustomer?.issudt) : null,
          expdt: dataAddCustomer?.expdt ? dayjs(dataAddCustomer?.expdt) : null,
        })
        setDataPost({ ...dataAddCustomer })
      }
    }
  }, [data])

  const handleProduct = (value) => {
    setHolds(value)
    setDataPost({ ...dataPost, nationalId: value })
    // if (value === "นิติบุคคล") {
    // setCheckIdCard(true)
    // setNumber(1)
    // }
  }
  const handletypeCompany = (value) => {
    setDataPost({ ...dataPost, snam: value })
  }
  const handletypeCompanyed = (value) => {
    setDataPost({ ...dataPost, lastname: value })
  }

  const handleSubmit = async (e) => {
    setLoading(true)
    const expDate = dayjs(dataPost.expdt);
    if (dataPost.snam === "นางสา  \u0000" || dataPost.snam === "") {
      alert("กรุณาเลือกคำนำหน้าชื่อ")
    } else {
      if (holds === "นิติบุคคล") {
        delete dataPost.address
        delete dataPost.phones
        // localStorage.setItem('addCustomer', JSON.stringify({ ...dataPost }))
        // if (dataPhone) {
        //   localStorage.setItem('addPhone', JSON.stringify([dataPhone]))
        // }
        if (dataAddCustomer2) {
          localStorage.setItem('addCustomer2', JSON.stringify({ ...dataPost }))
          localStorage.setItem('addPhone2', JSON.stringify([dataPhone]))
        } else {
          localStorage.setItem('addCustomer', JSON.stringify({ ...dataPost }))
          localStorage.setItem('addPhone', JSON.stringify([dataPhone]))
        }
        setLoading(false)
        handleGo()
      } else {
        if (expDate.isAfter(currentDate, 'day') || expDate.isSame(currentDate, 'day')) {
          delete dataPost.address
          delete dataPost.phones
          if (dataAddCustomer2) {
            localStorage.setItem('addCustomer2', JSON.stringify({ ...dataPost }))
            localStorage.setItem('addPhone2', JSON.stringify([dataPhone]))
          } else {
            localStorage.setItem('addCustomer', JSON.stringify({ ...dataPost }))
            localStorage.setItem('addPhone', JSON.stringify([dataPhone]))
          }
          // localStorage.setItem('addCustomer', JSON.stringify({ ...dataPost }))
          // if (dataPhone) {
          //   localStorage.setItem('addPhone', JSON.stringify([dataPhone]))
          // }
          setLoading(false)
          handleGo()
        } else {
          alert("บัตรประชาชนหมดอายุ")
        }
      }
    }

    setLoading(false)
  }

  // console.log("dataPost", dataPost)

  function isThaiNationalID(id) {
    if (!/^[0-9]{13}$/g.test(id)) {
      return false
    }
    let i; let sum = 0
    for ((i = 0), (sum = 0); i < 12; i++) {
      sum += Number.parseInt(id.charAt(i)) * (13 - i)

    }
    const checkSum = (11 - sum % 11) % 10
    if (checkSum === Number.parseInt(id.charAt(12))) {
      //console.log(id, 'เลขบัตรถูกต้อง')
      return true, message.info('เลขบัตรถูกต้อง')
    }
    //console.log("เลขบัตรไม่ถูกต้อง", id)
    return false, message.info('เลขบัตรไม่ถูกต้อง')

  }
  ////
  const inputIdCard = (e) => {
    setDataPost({ ...dataPost, identificationId: e.target.value })
    isThaiNationalID(e.target.value)
  }
  const inputTaxId = (e) => {
    setDataPost({ ...dataPost, identificationId: e.target.value })
    // isThaiNationalID(e.target.value)
  }

  ////////////////// Phone /////////////////////////////////
  const handlePhone = (e) => {
    setDataPhone({ ...dataPhone, telp: e.target.value, phoneType: "เบอร์ติดต่อ", sendSms: "1" })
  }
  /////////////////////////////////////////////////////////

  const handleGo = () => {
    changepage(page + 1)
  }
  const handleBack = () => {
    changepage(page - 1)
  }
  const handleClose = () => {
    close()
  }

  return (
    <Row justify={'center'}>
      <Card>
        <Col style={{ textAlign: 'center' }} >
          <b><h2>ข้อมูลส่วนตัว</h2></b>
        </Col>
        <Spin spinning={loading} size='large' tip=" Loading... ">
          <Row justify={'center'}>
            <Form
              name="basic"
              labelCol={{
                span: 12,
              }}
              // wrapperCol={{
              //   span: 16,
              // }}
              style={{
                width: '100%',
              }}
              initialValues={{
                remember: true,
              }}
              form={form}
              onFinish={handleSubmit}
              autoComplete="off"
            >
              <Divider style={{ margin: 5 }} />
              <Row justify={'center'} gutter={32} style={{ margin: '5px' }} >
                <Col span={24} style={{ textAlign: 'center' }}>
                  <Row className='main2' justify={'center'}>
                    <aside style={{ width: '90%' }}>
                      <Row justify={'center'} >
                        <Form.Item label='ประเภท' name='nationalId'
                          style={{ textAlign: 'left', width: '500px', marginLeft: '10px' }}
                          rules={[
                            {
                              required: true,
                              message: 'Please input !',
                            },]}>
                          <Select
                            placeholder={'เลือก'}
                            style={{ width: '250px', height: '40px' }}
                            // defaultValue={'บุคคลธรรมดา'}
                            name='nationalId'
                            onChange={(value) => handleProduct(value)}
                            options={[
                              {
                                label: 'บุคคลธรรมดา',
                                value: 'บุคคลธรรมดา',
                              },
                              {
                                label: 'นิติบุคคล',
                                value: 'นิติบุคคล',
                              },
                            ]}
                          />
                        </Form.Item>
                      </Row>
                    </aside>
                  </Row>
                  {holds === "นิติบุคคล" ?
                    <Row justify={'center'}>
                      <aside style={{ width: '90%' }}>
                        <div>
                          <Form.Item label='ประเภทห้าง' name='snam'
                            style={{ textAlign: 'left', width: '300px' }}
                            rules={[
                              {
                                required: true,
                                message: 'Please input !',
                              },]}>
                            <Select
                              placeholder={'เลือก'}
                              style={{ width: '250px', height: '40px', }}
                              name='snam'
                              initialvalues={1}
                              onChange={(value) => handletypeCompany(value)}
                              options={[
                                {
                                  label: 'หจก.',
                                  value: 'หจก',
                                },
                                {
                                  label: 'บริษัท',
                                  value: 'บริษัท',
                                },
                              ]}
                            />


                          </Form.Item>
                        </div>
                        <div>
                          <Form.Item
                            label='เลขประจำตัวผู้เสียภาษี'
                            // name='taxID'
                            name='identificationId'
                            style={{ textAlign: 'left', width: '300px' }}
                            rules={[
                              {
                                required: true,
                                message: 'Please input !',
                              },]}>
                            <Input type='text'
                              //maxLength={13}
                              name='identificationId'
                              disabled
                              // disabled={CheckIdCard ? false : true}
                              style={{ width: '250px', marginRight: '5px', marginLeft: '5px', color: 'black' }}
                              //  onChange={(e) => setDataPost({ ...dataPost, taxID: e.target.value }, isThaiNationalID(e.target.value))}></Input>
                              onChange={(e) => inputTaxId(e)}></Input>
                          </Form.Item>
                        </div>
                        {/* <div>
                          {number === 1 ?
                            <span
                              style={{ display: 'inline-block' }}
                            >
                              <Form.Item>
                                <Button type="primary" onClick={(e) => { handleIdCard(e) }}>ตรวจสอบเลขประจำตัวผู้เสียภาษี</Button>
                              </Form.Item>
                            </span> : null}
                        </div> */}
                        <div>
                          <Form.Item label='ชื่อบริษัท' name='firstname'
                            style={{ textAlign: 'left', width: '300px' }}
                            rules={[
                              {
                                required: true,
                                message: 'Please input !',
                              },]}
                          >
                            <Input type='text'
                              style={{ width: '250px', color: 'black' }}
                              onChange={(e) => setDataPost({ ...dataPost, firstname: e.target.value })}>
                            </Input>
                          </Form.Item>
                        </div>
                        <div>
                          <Form.Item label='' name='lastname'
                            style={{ textAlign: 'left', width: '300px' }}
                            rules={[
                              {
                                required: true,
                                message: 'Please input !',
                              },]}
                          >
                            <Select
                              placeholder={'เลือก'}
                              style={{ textAlign: 'left', height: '40px', width: '250px' }}
                              name='lastname'
                              initialvalues={'จำกัด'}
                              onChange={(value) => handletypeCompanyed(value)}
                              options={[
                                {
                                  label: 'มหาชน',
                                  value: 'มหาชน',
                                },
                                {
                                  label: 'จำกัด',
                                  value: 'จำกัด',
                                },
                              ]}
                            />
                          </Form.Item>
                        </div>
                        <div>
                          <Form.Item label='วันจดทะเบียน' name='birthdate'
                            style={{ textAlign: 'left', width: '300px' }}
                            rules={[
                              {
                                required: true,
                                message: 'Please input !',
                              },]}>
                            <DatePicker format={'YYYY-MM-DD'}
                              style={{ height: '40px', width: '250px', color: 'black' }}
                              onChange={(e) => {
                                if (e) {
                                  setDataPost({ ...dataPost, birthdate: e.format('YYYY-MM-DD') })
                                }
                              }} />
                          </Form.Item>
                        </div>
                        <div>
                          <Form.Item label='เบอร์โทรติดต่อ' name='telp'
                            rules={[
                              {
                                required: true,
                                message: 'Please input !',
                              },]}>
                            <Input type='text'
                              style={{ width: '250px', color: 'black' }}
                              onChange={(e) => { handlePhone(e) }}></Input>
                          </Form.Item>
                        </div>
                      </aside>
                    </Row>
                    :
                    <Row justify={'center'} >
                      <aside style={{ width: '90%' }}>
                        <div>
                          <Form.Item
                            label="คำนำหน้าชื่อ"
                            name="snam"
                            rules={[
                              {
                                required: true,
                                message: 'Please input !',
                              },]}>
                            <Radio.Group onChange={(e) => setDataPost({ ...dataPost, snam: e.target.value })} style={{ width: '250px' }}>
                              <Radio value="นาย" > นาย </Radio>
                              <Radio value="นาง"> นาง </Radio>
                              <Radio value="นางสาว"> นางสาว </Radio>
                            </Radio.Group>
                          </Form.Item>
                        </div>
                        <div>
                          <Form.Item
                            label='เลขประจำตัวประชาชน'
                            name='identificationId'
                            rules={[
                              {
                                required: true,
                                message: 'Please input !',
                              },]}>

                            <Input type='text' maxLength={13}
                              name='identificationId'
                              disabled
                              style={{ width: '250px', color: 'black' }}
                              onChange={(e) => inputIdCard(e)}></Input>
                          </Form.Item>
                          {/* {number === 1 ?
                            <span
                              style={{ display: 'inline-block' }}
                            >
                              <Form.Item
                                label='' name=''
                                style={{ textAlign: 'left', width: '300px' }}
                              >
                                <Button type="primary" onClick={(e) => { handleIdCard(e) }}>ตรวจสอบเลขประจำตัวประชาชน</Button>
                              </Form.Item>
                            </span>
                            : null} */}
                        </div>
                        <div>
                          <Form.Item label='ชื่อ' name='firstname'
                            rules={[
                              {
                                required: true,
                                message: 'Please input !',
                              },]}
                          >
                            <Input type='text'
                              style={{ width: '250px', color: 'black' }}
                              onChange={(e) => setDataPost({ ...dataPost, firstname: e.target.value })}></Input>
                          </Form.Item>
                        </div>
                        <div>
                          <Form.Item label='นามสกุล' name='lastname'

                            rules={[
                              {
                                required: true,
                                message: 'Please input !',
                              },]}>
                            <Input type='text'
                              style={{ width: '250px', color: 'black' }}
                              onChange={(e) => setDataPost({ ...dataPost, lastname: e.target.value })}></Input>
                          </Form.Item>
                        </div>
                        <div>
                          <Form.Item label='ชื่อเล่น' name='nickname'

                            rules={[
                              {
                                required: true,
                                message: 'Please input !',
                              },]}
                          >
                            <Input type='text'
                              style={{ width: '250px', color: 'black' }}
                              onChange={(e) => setDataPost({ ...dataPost, nickname: e.target.value })}></Input>
                          </Form.Item>
                        </div>
                        <div>
                          <Form.Item label='เพศ' name='gender'
                            rules={[
                              {
                                required: true,
                                message: 'Please input !',
                              },]}>
                            <Select
                              placeholder={'เลือก'}
                              style={{ width: '250px', height: "40px", color: 'black' }}
                              onChange={(value) => setDataPost({ ...dataPost, gender: value })}
                              options={[
                                {
                                  label: 'ชาย',
                                  value: 'M',
                                },
                                {
                                  label: 'หญิง',
                                  value: 'F',
                                },
                              ]}
                            />
                          </Form.Item>
                        </div>
                        <div>
                          <Form.Item label='เกิดวันที่' name='birthdate'
                            rules={[
                              {
                                required: true,
                                message: 'Please input !',
                              },]}>

                            <DatePicker format={'YYYY-MM-DD'}
                              style={{ height: '40px', width: '250px', color: 'black' }}
                              onChange={(e) => {
                                if (e) {
                                  setDataPost({ ...dataPost, birthdate: e.format('YYYY-MM-DD') })
                                }
                              }} />

                          </Form.Item>
                        </div>
                        <div>
                          <Form.Item label='เบอร์โทรติดต่อ' name='telp'
                            rules={[
                              {
                                required: true,
                                message: 'Please input !',
                              },]}>
                            <Input type='text'
                              style={{ width: '250px', color: 'black' }}
                              //onChange={(e) => setDataPhone({ ...dataPhone, telp: e.target.value })}></Input>
                              onChange={(e) => { handlePhone(e) }}></Input>
                          </Form.Item>
                        </div>
                        <div>
                          <Form.Item label='เจ้าพนักงานออกบัตร' name='issuby'>
                            <Input type='text'
                              style={{ width: '250px', color: 'black' }}
                              onChange={(e) => setDataPost({ ...dataPost, issuby: e.target.value })}></Input>
                          </Form.Item>
                        </div>
                        <div>
                          <Form.Item label='วันออกบัตร' name='issudt'
                            rules={[
                              {
                                required: true,
                                message: 'Please input !',
                              },]}>
                            <DatePicker format={'YYYY-MM-DD'}
                              style={{ height: '40px', width: '250px', color: 'black' }}
                              onChange={(e) => {
                                if (e) {
                                  setDataPost({ ...dataPost, issudt: e.format('YYYY-MM-DD') })
                                }
                              }} />
                          </Form.Item>
                        </div>
                        <div>
                          <Form.Item label='วันบัตรหมดอายุ' name='expdt'

                            rules={[
                              {
                                required: true,
                                message: 'Please input !',
                              },]}>
                            <DatePicker format={'YYYY-MM-DD'}
                              style={{ height: '40px', width: '250px', color: 'black' }}
                              onChange={(e) => {
                                if (e) {
                                  setDataPost({ ...dataPost, expdt: e.format('YYYY-MM-DD') })
                                }
                              }} />
                          </Form.Item>
                        </div>
                      </aside>
                    </Row>
                  }
                </Col>
              </Row>
              <Divider style={{ margin: 5 }} />
              <Row justify={'center'} >
                <Space>
                  <Button type='primary' onClick={handleBack} >ย้อนกลับ</Button>
                  <Button type='primary' danger onClick={handleClose} >ยกเลิก</Button>
                  <Button type='primary' htmlType='submit' style={{ backgroundColor: "green" }} >ต่อไป</Button>
                </Space>
              </Row>
            </Form>
          </Row>
        </Spin>
      </Card>
    </Row>
  )
}

export default CustomerLand