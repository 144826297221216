import React, { useState, useEffect } from "react";
import { Button, Col, Row, Table, Divider, Space, Card, Radio, Tag, notification, Select, DatePicker, Statistic, Typography, Spin, Pagination } from 'antd';
import dayjs from "dayjs";
import axios from "axios";

import ModalEditCarRate from "./modals/ModalEditCarRate";
import { pgmaindataksm, pgmaindataratezero } from "../file_mid/all_api";

const { Text } = Typography;
const { Countdown } = Statistic;

export default function MainApRateCar() {
    const currentDateTime = dayjs()
    const currentDateDay2 = dayjs()
    const showDay1 = dayjs("2023-01-01")
    const [dataPost, setDataPost] = useState({ sec: 0, days1: dayjs("2023-01-01"), days2: currentDateDay2, approvalStatus: '17' })
    const [editData, setEditData] = useState();
    const user = localStorage.getItem('username');
    const branch = localStorage.getItem('branch');
    const [modalCarRate, setModalCarRate] = useState(false); // Edit Car Rate
    const [cData, setCData] = useState([]);
    const [axiosData, setAxiosData] = useState([]); // Table
    const [arrayTable, setArrayTable] = useState(); // Array Table
    const [keyWord, setKeyWord] = useState("รออนุมัติราคารถ");
    const [api, contextHolder] = notification.useNotification();
    const [statusNoti, setStatusNoti] = useState("0");
    const token = localStorage.getItem('token')
    const [loading, setLoading] = useState(false)
    const [testPage, setTestPage] = useState();
    const [ssPage, setSsPage] = useState({ numberPage: 1, pageSize: 10 });
    const [currentPage, setCurrentPage] = useState(1)
    const [query, setQuery] = useState("");

    useEffect(() => {
        loadData();
        openNotification('top')
    }, [keyWord, modalCarRate, dataPost.approvalStatus, dataPost.days1, dataPost.days2, ssPage])

    const onChangeKeyWord = (value) => {
        console.log("value", value)
        // setKeyWord(value)
        setQuery("")
        setDataPost({ ...dataPost, approvalStatus: value })
        setCurrentPage(1)
        setSsPage({ numberPage: 1, pageSize: 10 })
    }
    const onChangePagination = (e, pageSize) => {
        // console.log(e, pageSize)
        setCurrentPage(e)
        setSsPage({ numberPage: e, pageSize: pageSize })
    }

    const loadCountData = async (data) => {
        const ez = data.filter(
            (item) =>
                item.approvalStatusId === dataPost?.approvalStatus
        );
        console.log("ez", ez)
        console.log("data", data)
        setTestPage(...ez)
        setCData(ez)
    }

    const loadData = async (data) => {
        setLoading(true)
        let mainData
        const tk = JSON.parse(token)
        const headers = {
            "Authorization": `Bearer ${tk}`,
            "Menu": JSON.stringify("5")
        }
        if (query !== "" && data !== "stop") {
            mainData = { reviewedBy: user, approvalStatus: dataPost.approvalStatus, numberPage: ssPage?.numberPage, pageSize: ssPage?.pageSize, keyword: query, day1: dataPost.days1, day2: dataPost.days2 }
        } else {
            if (data === "stop") {
                mainData = { reviewedBy: user, approvalStatus: 17, numberPage: ssPage?.numberPage, pageSize: ssPage?.pageSize, day1: dataPost.days1, day2: dataPost.days2 }
            } else {
                mainData = { reviewedBy: user, approvalStatus: dataPost.approvalStatus, numberPage: ssPage?.numberPage, pageSize: ssPage?.pageSize, day1: dataPost.days1, day2: dataPost.days2 }
            }
        }
        if (branch === "MIT" || branch === "UD" || branch === "LEX" || branch === "S4") {
            // await axios.post(`http://localhost:8080/mid/pgmaindataratezero`, mainData, { headers: headers })
            await axios.post(pgmaindataratezero, mainData, { headers: headers })
                // await axios.post(pgmaindata, mainData, { headers: headers })
                .then(async (res) => {
                    console.log("res.data", res.data)
                    if (res.data.ShowTableRateCarPG) {
                        setArrayTable(res.data.ShowTableRateCarPG)
                        setAxiosData(res.data.ShowTableRateCarPG)
                    } else {
                        setArrayTable([])
                        setAxiosData([])
                        setTestPage()
                    }
                    if (res.data.Countstatus) {
                        loadCountData(res.data.Countstatus)
                    } else {
                        setTestPage()
                        setCData([])
                    }
                    setLoading(false)
                }).catch((err) => console.log(err))
            setLoading(false)
        } else {
            await axios.post(pgmaindataksm, mainData, { headers: headers })
                .then(async (res) => {
                    console.log("res.data ksm", res.data)
                    if (res.data !== null) {
                        setArrayTable(res.data)
                        setAxiosData(res.data)
                        await loadCountData();
                    } else {
                        setArrayTable([])
                        setAxiosData([])
                    }
                    setLoading(false)
                }).catch((err) => console.log(err))
            setLoading(false)
        }
    }

    // const search = (data) => {
    //     // อนาคต ควรหาใน database โดยตรง
    //     const greaterThanTen = axiosData.filter(
    //         (item) =>
    //             item.firstName.toLowerCase().includes(data.target.value) ||
    //             item.lastName.toLowerCase().includes(data.target.value) ||
    //             item.carPlateNumber.toLowerCase().includes(data.target.value)
    //     );
    //     setArrayTable(greaterThanTen)
    // };

    const backc = {
        "ทั้งหมด": '#e6f4ff',
        "รอธุรการรับ": '#fffbe6',
        "ลูกค้าคอนเฟิร์ม": '#fffbe6',
        "รออนุมัติราคารถ": '#fff7e6',
        "รอวิเคราะห์": '#e6fffb',
        "ผ่านการวิเคราะห์": '#f0f5ff',
        "รออนุมัติ": '#fcffe6',
        "รอตรวจสอบเอกสาร": '#fcffe6',
        "อนุมัติ": '#f6ffed',
        "โทรติด": '#fff0f6',
        "โทรไม่ติด(ติดต่อไม่ได้)": '#fff2e8',
        "ไม่อนุมัติ": '#fff1f0',
        "ไม่ผ่านการวิเคราะห์": '#fff1f0',
        "รออนุมัติราคาที่ดิน": '#fff1f0',
        "รอทำสัญญา": '#f6ffed',
        "ลูกค้าปฏิเสธ": '#fff1f0',
        "Reject": '#fff1f0',
    }
    const textc = {
        "ทั้งหมด": '#1677ff',
        "รอธุรการรับ": '#e39f23',
        "ลูกค้าคอนเฟิร์ม": '#e39f23',
        "รออนุมัติราคารถ": '#d86d38',
        "รอวิเคราะห์": '#3fbccf',
        "ผ่านการวิเคราะห์": '#384ecb',
        "รออนุมัติ": '#afc222',
        "รอตรวจสอบเอกสาร": '#afc222',
        "อนุมัติ": '#47ad55',
        "โทรติด": '#c73799',
        "โทรไม่ติด(ติดต่อไม่ได้)": '#e36729',
        "ไม่อนุมัติ": '#ff4d4f',
        "ไม่ผ่านการวิเคราะห์": '#ff4d4f',
        "รออนุมัติราคาที่ดิน": '#ff4d4f',
        "รอทำสัญญา": '#47ad55',
        "ลูกค้าปฏิเสธ": '#ff4d4f',
        "Reject": '#ff4d4f',
    }

    const columns = [
        {
            fixed: true,
            title: "ลำดับ",
            dataIndex: "index",
            key: 'index',
            width: "5%",
            align: 'center',
            render: (text, object, index) => index + 1
        },
        {
            key: 'carInfo',
            title: "รายละเอียด",
            dataIndex: "carPlateNumber",
            width: "25%",
            align: 'center',
            render: (text, record) => (
                <>
                    <div>{record.snam}{record.firstName} {record.lastName}</div>
                    <div>
                        {
                            record.car === "car" ?
                                <div>
                                    <div>{record.carBrand} {record.carModel} ปีรถ {parseInt(record.carYear) + 543} เลขทะเบียน {record.carPlateNumber}</div>
                                    {
                                        record.proposalBy !== null ?
                                            <div>เสนอโดย <Space style={{ color: "red" }}><b>{record.proposalBy}</b></Space></div>
                                            :
                                            <div>เสนอโดย <Space style={{ color: "red" }}><b>{record.proposalBy2}</b></Space></div>
                                    }
                                    {
                                        record.reviewedBy !== null ?
                                            <>
                                                <div>ธุรการที่ได้เคส <Space style={{ color: "red" }}><b>{record.reviewedBy}</b></Space></div>
                                                {/* <div>เวลาผ่านการวิเคราะห์ <Space style={{ color: "red" }}><b>{dayjs(record.acceptPassDate).format("DD-MM-YYYY HH:mm:ss")}</b></Space></div> */}
                                            </>
                                            :
                                            <div>ธุรการที่ได้เคส <Space style={{ color: "red" }}><b>{record.reviewedBy2}</b></Space></div>
                                    }
                                </div>
                                :
                                <div>
                                    <div>{record.carBrand} {record.carModel} ตารางวา {record.carYear} เลขโฉนด {record.carPlateNumber}</div>
                                    <div>เสนอโดย <Space style={{ color: "red" }}><b>{record.proposalBy}</b></Space></div>
                                    {
                                        record.reviewedBy !== null ?
                                            <>
                                                <div>ผ่านการวิเคราะห์โดย <Space style={{ color: "red" }}><b>{record.reviewedBy}</b></Space></div>
                                                <div>เวลาผ่านการวิเคราะห์ <Space style={{ color: "red" }}><b>{dayjs(record.acceptPassDate).format("DD-MM-YYYY HH:mm:ss")}</b></Space></div>
                                            </>
                                            : null
                                    }
                                </div>
                        }
                    </div >
                </>
            )
        },
        {
            key: 'productLoanType',
            title: "ประเภทขอกู้",
            dataIndex: "productLoanType",
            width: "20%",
            align: 'center',
            render: (text, record) => (
                <>
                    <div>
                        {
                            record.car === "car" ?
                                <Tag color="purple">รถ</Tag>
                                :
                                <Tag color="green">ที่ดิน</Tag>
                        }
                        {
                            record.productLoanType ?
                                <Tag color="geekblue">{record.productLoanType}</Tag>
                                :
                                <Tag color="geekblue">{record.productLoanType2}</Tag>
                        }
                    </div>
                </>
            ),
        },
        // {
        //     key: 'loanAmount',
        //     title: "ยอดขอกู้",
        //     dataIndex: "loanAmount",
        //     width: "10%",
        //     align: 'center',
        //     sorter: {
        //         compare: (a, b) => a.loanAmount - b.loanAmount,
        //         multiple: 2,
        //     },
        //     render: (text, record) => (
        //         <Space size="middle">
        //             {
        //                 <Space>
        //                     <>{currencyFormatOne(record.loanAmount)}</>
        //                 </Space>
        //             }
        //         </Space>
        //     ),
        // },
        {
            title: "เรท",
            dataIndex: "carPrice",
            align: 'center',
            width: "10%",
            sorter: {
                compare: (a, b) => a.carPrice - b.carPrice,
                multiple: 2,
            },
            render: (text, record) => (
                <Space size="middle">
                    {
                        <Space>
                            <>{currencyFormatOne(record.carPrice)}</>
                        </Space>
                    }
                </Space>
            ),
        },
        {
            title: "วันที่เสนอ",
            dataIndex: "carInput",
            align: 'center',
            width: "10%",
            // defaultSortOrder: 'descend',
            sorter: {
                compare: (a, b) => dayjs(a.carInput) - dayjs(b.carInput),
                multiple: 1,
            },
            render: (text, record) => (
                <>{dayjs(record.carInput).format("DD-MM-YYYY HH:mm")}</>
            ),

        },
        {
            title: "วันที่รับ",
            dataIndex: "acceptDatePg",
            width: "10%",
            align: 'center',
            sorter: {
                compare: (a, b) => dayjs(a.acceptDatePg) - dayjs(b.acceptDatePg),
                multiple: 2,
            },
            render: (text, record) => (
                <Space size="middle">
                    {
                        record.acceptDatePg !== "0001-01-01T00:00:00Z" ?
                            <Space>
                                <>{dayjs(record.acceptDatePg).format("DD-MM-YYYY HH:mm")}</>
                            </Space>
                            : <Text type="secondary">ยังไม่รับงาน</Text>
                    }
                </Space>
            ),
        },
        {
            title: "ระยะเวลาที่คาดว่าจะเสร็จ",
            dataIndex: "",
            width: "10%",
            align: 'center',
            render: (text, record) => (
                <Space size="middle">
                    {
                        record.acceptDate !== "0001-01-01T00:00:00Z" ?
                            <Space>
                                <Countdown value={dayjs(record.acceptDate).add(3, 'hour')} />
                            </Space>
                            : <Text type="secondary">ยังไม่รับงาน</Text>
                    }
                </Space>
            ),
        },
        {
            key: 'approvalStatus',
            title: "สถานะ",
            dataIndex: "approvalStatus",
            width: "10%",
            align: 'center',
            render: (text, record) => {
                let color
                if (record.approvalStatus === 17) {
                    color = "orange"
                } else {
                    color = "orange"
                }
                return (
                    <>
                        {
                            record.approvalStatusName ?
                                <Tag color={color}>
                                    {record.approvalStatusName}
                                </Tag>
                                :
                                <Tag color={color}>
                                    {record.approvalStatusName2}
                                </Tag>
                        }
                    </>
                );
            },
        },
        {
            title: "Action",
            align: 'center',
            width: "12.5%",
            render: (record) => (
                <Space size="middle">
                    <Button style={{ backgroundColor: "blue", color: 'white' }}
                        onClick={() => {
                            setModalCarRate(true)
                            var datarate = { customerId: record.customerId, carId: record.carId, reLoanId: record.reLoanId }
                            setEditData(datarate)
                        }}>เพิ่มเรทรถ</Button>

                </Space>
            ),
        },
    ];

    const openNotification = (placement) => {
        if (statusNoti === "1") {
            api.success({
                message: <b>รับงานแล้ว</b>,
                description:
                    'สถานะถูกเปลี่ยนจาก ผ่านการวิเคราะห์ เป็น รออนุมัติ',
                placement,
            });
            setStatusNoti("0")
        }
    };

    const currencyFormatOne = (amount) => {
        return Number(amount)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, '$&,')
    }

    return (
        <>
            <Card
                bordered={false}
                style={{
                    width: 'auto',
                }}
            >
                <Row style={{ textAlign: 'center' }}>
                    <Col span={24}>
                        <Spin spinning={loading} size='large' tip=" Loading... ">
                            <div className='text-center'>
                                <h2>อนุมัติเรทรถ</h2>
                            </div>
                            <Row gutter={32} justify={'center'}>
                                <Row gutter={24} justify={'center'} style={{ margin: 10 }}>
                                    <Col className='gutter-row' span={24} style={{ textAlign: 'center' }}>
                                        <Space>
                                            <>{"เลือกวันที่ต้องการแสดงเคส :"}</>
                                            <DatePicker format={'YYYY-MM-DD'}
                                                defaultValue={showDay1}
                                                style={{ height: '40px' }}
                                                onChange={(e) => {
                                                    if (e) {
                                                        setDataPost({ ...dataPost, days1: e.format('YYYY-MM-DD') })
                                                    }
                                                }} />
                                            <>{"ถึง"}</>
                                            <DatePicker format={'YYYY-MM-DD'}
                                                defaultValue={currentDateTime}
                                                style={{ height: '40px' }}
                                                onChange={(e) => {
                                                    if (e) {
                                                        setDataPost({ ...dataPost, days2: e.format('YYYY-MM-DD') })
                                                    }
                                                }} />
                                            {/* <span style={{ display: 'inline-block' }}>
                          <Button type="primary" onClick={(e) => { handleYG(e) }}>ตรวจสอบ</Button>
                        </span> */}
                                        </Space>
                                    </Col>
                                </Row>

                                <Row gutter={24} justify={'center'} style={{ margin: 10 }}>
                                    <Col className='gutter-row' span={24} style={{ textAlign: 'center' }}>
                                        <Space>
                                            <>{"เลือกสถานะ :"}</>
                                            <Select
                                                disabled
                                                defaultValue={dataPost.approvalStatus}
                                                style={{ width: 200 }}
                                                // onChange={(value) => setDataPost({ ...dataPost, approvalStatus: value })}
                                                onChange={(value) => onChangeKeyWord(value)}
                                                options={[
                                                    {
                                                        label: 'ทั้งหมด', value: 'ทั้งหมด',
                                                    },
                                                    {
                                                        label: 'รออนุมัติราคารถ', value: '17',
                                                    },
                                                    {
                                                        label: 'รอธุรการรับ', value: '16',
                                                    },
                                                    {
                                                        label: 'รอวิเคราะห์', value: '18',
                                                    },
                                                    {
                                                        label: 'ผ่านการวิเคราะห์', value: '19',
                                                    },
                                                    {
                                                        label: 'ไม่ผ่านการวิเคราะห์', value: '20',
                                                    },
                                                    {
                                                        label: 'รออนุมัติ', value: '2',
                                                    },
                                                    {
                                                        label: 'อนุมัติ', value: '3',
                                                    },
                                                    {
                                                        label: 'ไม่อนุมัติ', value: '22',
                                                    },
                                                    {
                                                        label: 'ลูกค้าคอนเฟิร์ม', value: '24',
                                                    },
                                                    {
                                                        label: 'รอทำสัญญา', value: '27',
                                                    },
                                                    {
                                                        label: 'ลูกค้าปฏิเสธ', value: '25',
                                                    },
                                                    {
                                                        label: 'รอตรวจสอบเอกสาร', value: '26',
                                                    },
                                                    {
                                                        label: 'Reject', value: '23',
                                                    },
                                                ]}
                                            />
                                        </Space>
                                    </Col>
                                </Row>
                                <Divider style={{ margin: 5 }} />

                                <Radio.Group disabled value={keyWord} onChange={(e) => setKeyWord(e.target.value)} >
                                    {
                                        cData
                                            .sort((a, b) => {
                                                const order = [
                                                    "รอธุรการรับ",
                                                    "รออนุมัติราคารถ",
                                                    "รอวิเคราะห์",
                                                    "ผ่านการวิเคราะห์",
                                                    "ไม่ผ่านการวิเคราะห์",
                                                    "รออนุมัติ",
                                                    "อนุมัติ",
                                                    "ไม่อนุมัติ",
                                                    "รอตรวจสอบเอกสาร",
                                                    "รอทำสัญญา",
                                                    "ลูกค้าปฏิเสธ",
                                                    "Reject",
                                                    "ทั้งหมด",
                                                ];

                                                return order.indexOf(a.approvalStatus) - order.indexOf(b.approvalStatus);
                                            })
                                            .map((item) => (
                                                <Radio.Button
                                                    style={{
                                                        backgroundColor: backc[item.approvalStatus],
                                                        color: textc[item.approvalStatus]
                                                    }}
                                                    value={item.approvalStatus}
                                                >
                                                    {item.approvalStatus} <b>{item.totalCount}</b>
                                                </Radio.Button>
                                            ))}
                                </Radio.Group>
                            </Row>
                            <Row gutter={32} style={{ margin: 10 }}>
                                {/* <Col className='gutter-row' span={24} style={{ textAlign: 'right' }}>
                                <Input.Search
                                    style={{ width: '250px' }}
                                    placeholder="ค้นหา...."
                                    onChange={search}
                                    name="search"
                                    id="search"
                                    value={query}
                                />
                            </Col> */}
                            </Row>
                            <Row gutter={32}>
                                <Col span={24}>
                                    <Table
                                        pagination={false}
                                        rowKey={(record) => record.uid}
                                        dataSource={arrayTable}
                                        columns={columns}
                                        scroll={{
                                            x: 1500,
                                            y: 400,
                                        }}
                                        style={{ padding: 20 }}
                                    >
                                    </Table>
                                    <Divider />
                                    <Pagination
                                        current={currentPage}
                                        onChange={onChangePagination}
                                        onShowSizeChange={onChangePagination}
                                        defaultPageSize={10}
                                        defaultCurrent={1}
                                        total={testPage?.totalCount}
                                    />
                                    <Divider />
                                </Col>
                            </Row>
                        </Spin>
                    </Col>
                </Row>
            </Card>
            {
                modalCarRate ?
                    <ModalEditCarRate
                        open={modalCarRate}
                        close={setModalCarRate}
                        dataFromTable={editData}
                    />
                    : null
            }
            {contextHolder}
        </>
    )
}
