import React, { useState, useEffect } from 'react'
import { Form, Modal, Row, Col, Image, Space, Card, Button, Tag, Divider, notification, message, Upload, Spin } from 'antd'
import { SendOutlined, PlusOutlined } from '@ant-design/icons'
import dayjs from 'dayjs';
import axios from "axios";
import '../../css/Media.css'
import { useSelector } from 'react-redux';
import TextArea from 'antd/es/input/TextArea';
import Compressor from 'compressorjs';
import Currency from 'currency.js';
import { FIFTEENPERCENT, TWENTYFOURPERCENT } from './../../offer_case/ploan/InstallmentTable'
import { irr } from 'node-irr'
import { HirePurchase } from '../../offer_case/hire_purchase/HirePurchase';
import { Installment } from '../../offer_case/ploan/Installment';
import {
    checkguarantors, customerconfirmcar, loadOJS, makenoteresend, mutips2, resendcarmk,
    getImagess, getImagessPre, getAllFolderGuaImg, findDataBroker, getImagessGua
} from '../../file_mid/all_api';
import { ShowDataBrokers } from '../../file_mid/show_data_broker';
import { ApprovalStatusColor } from '../../file_mid/status_color';

function DescriptionAll({ getData, close, saveResendNote }) {

    const [loading, setLoading] = useState(false);
    const { confirm } = Modal;
    const guarantor = useSelector(state => state.guarantor)
    const token = localStorage.getItem('token');
    const branch = localStorage.getItem('branch');
    const [dataBroker, setDataBroker] = useState();
    const [dataModalCars, setDataModalCars] = useState(getData);
    // const [dataCareer, setDataCareer] = useState([]);
    const [career, setCareer] = useState([]) // เก็บข้อมูลอาชีพ
    const [form] = Form.useForm()

    const [guarantor1, setGuarantor1] = useState([])
    const [address, setAddresss] = useState(getData?.address)
    const [dataPhoneCus, setDataPhoneCus] = useState(getData?.phones);
    const [dataEmail, setDataEmail] = useState(getData?.emails);
    const [dataSocials, setDataSocials] = useState(getData?.socials);
    const [notes, setNotes] = useState(getData?.cars?.carLoanDetails?.notes)

    const [resendNote, setResendNotes] = useState(getData?.cars?.carLoanDetails?.notPass?.note)

    const [fileListCustomer, setFileListCustomer] = useState([]);
    const [fileListCustomerImageCareer, setFileListCustomerImageCareer] = useState([]);
    const [fileListCustomerImageCar, setFileListCustomerImageCar] = useState([]);
    const [fileListCustomerDocument, setFileListCustomerDocument] = useState([]);
    const [fileListStatement, setFileListStateMent] = useState([]);
    const [fileListStatementPDF, setFileListStateMentPDF] = useState([]);
    const [fileListOtherMent, setFileListOtherMent] = useState([]);
    const [fileListGuarantor, setFileListGuarantor] = useState([]);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [allImg, setAllImg] = useState([]);
    const [checkImage, setCheckImage] = useState();
    const newFormData = new FormData();

    const [api, contextHolder] = notification.useNotification();

    //รูปภาพ
    const [imageBlobzz, setImageBlobzz] = useState(getData?.cars?.img);
    const [getImages, setGetImages] = useState([]);

    const [arr, setArr] = useState()
    const [interestt, setInterestt] = useState(0.0)
    const [installmentWithInterestt, setInstallmentWithInterestt] = useState(0.0)
    const [resultIrr, setResultIrr] = useState(0.0)

    const [checkOpen, setCheckOpen] = useState(false)
    const [checkOpenHIRE, setCheckOpenHIRE] = useState(false)

    const [loanAmountt, setLoanAmountt] = useState();
    const [installmentCount, setInstallmentCount] = useState()

    const [checkSendNEW, setCheckSendNEW] = useState(false);

    var note = notes?.length;
    var lastIndex = note - 1;

    useEffect(() => {
        // loadData().then(async () => {
        loadGuarantors()
        loadCareer()
        loadBroker()
        form.setFieldsValue({
            note: getData?.cars?.carLoanDetails?.notPass?.note,
            offercaseNote: getData?.cars?.carLoanDetails?.noteResend?.offercaseNote,
        })
        // })
        handleCheckForCal(getData?.cars)
        if (getData?.cars?.apCarLoanDetails.loanId !== 0) {
            setLoanAmountt(parseInt(getData?.cars?.apCarLoanDetails.apLoanAmount))
            setInstallmentCount(parseInt(getData?.cars?.apCarLoanDetails.apLoanTerm))
        } else {
            setLoanAmountt(parseInt(getData?.cars?.carLoanDetails.approvedLoanAmount))
            setInstallmentCount(parseInt(getData?.cars?.carLoanDetails.approvedLoanTerm))
        }
    }, [])

    useEffect(() => {
        imageBlobzz?.map((e, index) => {
            var PreAaproveSplit = []
            PreAaproveSplit = e.pathImage.split("/")
            if (PreAaproveSplit[3] === "13") {
                setCheckSendNEW(true)
            }
        })
    }, [imageBlobzz])

    const handleCheckForCal = async (value) => {
        // console.log("handleCheckForCal", value)
        if (value.carLoanDetails?.productType === "p-loan") {
            // console.log("1 = p-loan")
            await handleCalculate(value.carLoanDetails)
        } else if (value.carLoanDetails?.productType === "เช่าซื้อ") {
            if (getData?.cars?.productTypeCar === 1) {
                // console.log("2 = รถเล็ก เช่าซื้อ")
                await handleCalculateHIRE(value.carLoanDetails)
            } else {
                // console.log("3 = รถใหญ่ เช่าซื้อ")
                await car_Calculate(value.carLoanDetails)
            }
        } else if (value.carLoanDetails?.productType === "เช่าซื้อ1") {
            // console.log("4 = รถเล็ก เช่าซื้อ1")
            await car_Calculate()
        }
    }

    const loadBroker = async () => {
        const id = { loanId: dataModalCars?.cars?.carLoanDetails?.loanId }
        await axios.post(findDataBroker, id)
            .then((res) => {
                setDataBroker(res.data)
            })
            .catch((err) => console.log(err))
    }

    const openTable = () => {
        setCheckOpen(true)
    };
    const openTable2 = () => {
        setCheckOpenHIRE(true)
    };

    // คำนวน
    const car_Calculate = async (value) => {

        var LA = value?.approvedLoanAmount
        var LT = value?.approvedLoanTerm
        var IR = value?.interestRate

        var interestRateCar = parseFloat(IR) / 100 // อัตราดอก / 100
        var rateCar = ((parseFloat(LA) * Currency(interestRateCar, { precision: 5 }).value) * LT) // (ยอดจัด * อัตราดอกที่หาร100แล้ว ,precision: 3 คือทศนิยม 3 )*งวด
        var loanTermCar = (rateCar + parseFloat(LA)) / parseInt(LT) // (ดอกเบี้ยทั้งหมด + ยอดจัด)/ งวด
        var newLoanTermCar = Math.ceil(Currency((loanTermCar * 0.07) + loanTermCar), { precision: 5 })// งวดละใหม่ = ( งวดละ * 0.07 ) + งวดละ
        var resultRateCar = Math.ceil(Currency(newLoanTermCar, { precision: 5 }) * parseInt(LT)) - parseFloat(LA) // งวดละใหม่ * งวด - ยอดจัด
        var newResultPriceCar = Math.ceil(Currency(newLoanTermCar) * parseInt(LT), { precision: 5 }) // งวดละใหม่ * งวด

        setArr(newLoanTermCar)
        setInterestt(resultRateCar)
        setInstallmentWithInterestt(newResultPriceCar)
    }

    const handleCalculate = async (value) => {
        var LA = parseInt(value?.approvedLoanAmount)
        var LT = parseInt(value?.approvedLoanTerm)
        // setDataSource([])

        if (LA && LT) {

            let loanAmount = 0.0
            let interest = 0.0

            TWENTYFOURPERCENT.map((value) => {
                if (value?.installment === ~~LT) {
                    loanAmount = value?.interest * 0.7
                }
            })

            FIFTEENPERCENT.map((value) => {
                if (value?.installment === ~~LT) {
                    interest = value?.interest * 0.3
                }
            })

            let installmentAmount = Math.round((interest + loanAmount) * LA)

            // IRR Calculate
            var array = []
            array.push(-LA)
            for (var i = 0; i < LT; i++) {
                array.push(installmentAmount)
            }

            let IRR = irr(array) * 100

            if (IRR) {
                var TotalPrincipleBalance = LA //ยอดทั้งหมด
                var totalInterest = 0.0 // ดอกเบี้ย
                var result = LA
                for (var j = 1; j <= LT; j++) {     // installmentCount จำนวนงวด
                    let principle = TotalPrincipleBalance     // เงินต้น = ยอดทั้งหมด
                    let no = j   // งวดที่ 
                    let interestAmount = Math.round((TotalPrincipleBalance * IRR) / 100) // จำนวนดอกเบี้ย = 
                    let principleAmount = installmentAmount - interestAmount    //  จำนวนงวด - จำนวนดอกเบี้ย
                    let principleBalance = principle - principleAmount          //  ดอกเบี้ยคงเหลือ = 

                    totalInterest += interestAmount // totalInterest = totalInterest + interestAmount

                    let interestBalance = totalInterest // ดอกเบี้ยคงเหลือ = ยอดรวมดอกเบี้ย

                    // setDataSource((pre) => [
                    //     ...pre,
                    //     {
                    //         installmentNo: no,                          // งวดที่ 
                    //         installmentAmount: installmentAmount,       // จำนวนงวด
                    //         interestAmount: interestAmount,             // จำนวนดอกเบี้ย
                    //         principleAmount: principleAmount,           // เงินต้น
                    //         principleBalance: principleBalance,         // เงินต้นคงเหลือ
                    //         interestBalance: interestBalance,           // ดอกเบี้ยคงเหลือ
                    //     },
                    // ])
                    // TotalPrincipleBalance = TotalPrincipleBalance - principleAmount, ยอดทั้งหมด
                    TotalPrincipleBalance -= principleAmount
                }
                result = installmentAmount * LT
                var interestNEW = result - LA
                setResultIrr(Currency(IRR, { precision: 5 }).value)
                setArr(installmentAmount)
                setInterestt(interestNEW)
                // setInterestt(totalInterest)
                setInstallmentWithInterestt(result)
            }
        }
    }

    const handleCalculateHIRE = async (value) => {

        var LA = value?.approvedLoanAmount
        var LT = value?.approvedLoanTerm

        if (LA && LT) {
            const amountWithVat = LA * 1.07
            let installment = 0.0

            FIFTEENPERCENT.map((value) => {
                if (value?.installment === ~~LT) {
                    installment = value?.interest * amountWithVat
                }
            })

            let installmentAmount = Math.round(installment)

            // IRR Calculate
            var array = []
            array.push(-amountWithVat)
            for (var i = 0; i < LT; i++) {
                array.push(installmentAmount)
            }

            let IRR = irr(array) * 100

            if (IRR) {
                var TotalPrincipleBalance = amountWithVat //ยอดทั้งหมด
                var totalInterest = 0.0 // ดอกเบี้ย
                //var result = ~~LA
                var result = amountWithVat
                for (var j = 1; j <= LT; j++) {     // installmentCount จำนวนงวด
                    let principle = TotalPrincipleBalance     // เงินต้น = ยอดทั้งหมด
                    let no = j   // งวดที่ 
                    let interestAmount = Math.round((TotalPrincipleBalance * IRR) / 100) // จำนวนดอกเบี้ย = 
                    let principleAmount = installmentAmount - interestAmount    //  จำนวนงวด - จำนวนดอกเบี้ย
                    let principleBalance = principle - principleAmount          //  ดอกเบี้ยคงเหลือ = 

                    totalInterest += interestAmount // totalInterest = totalInterest + interestAmount

                    let interestBalance = totalInterest // ดอกเบี้ยคงเหลือ = ยอดรวมดอกเบี้ย

                    // setDataSource((pre) => [
                    //     ...pre,
                    //     {
                    //         installmentNo: no,                          // งวดที่ 
                    //         installmentAmount: installmentAmount,       // จำนวนงวด
                    //         interestAmount: interestAmount,             // จำนวนดอกเบี้ย
                    //         principleAmount: principleAmount,           // เงินต้น
                    //         principleBalance: principleBalance,         // เงินต้นคงเหลือ
                    //         interestBalance: interestBalance,           // ดอกเบี้ยคงเหลือ
                    //     },
                    // ])
                    // TotalPrincipleBalance = TotalPrincipleBalance - principleAmount, ยอดทั้งหมด
                    TotalPrincipleBalance -= principleAmount
                }
                //result += totalInterest
                result = installmentAmount * LT
                var interestNEW = result - LA
                setResultIrr(Currency(IRR, { precision: 5 }).value)
                setArr(installmentAmount)
                setInterestt(interestNEW)
                setInstallmentWithInterestt(result)
                console.log("result", result)
            }
        }
    }
    // คำนวน

    const loadCareer = async () => {
        const id = { customerId: getData?.customerId }
        await axios.post(loadOJS, id)
            .then((res) => {
                setCareer(res.data)
            })
            .catch((err) => console.log(err))
    }

    const loadGuarantors = async (value) => { //คนค้ำ
        setLoading(true)
        await axios.post(checkguarantors, { typeLoan: 'car', loanId: getData?.cars?.loanId })
            .then(res => {
                if (res.status === 200) {
                    // console.log("loadGuarantors", res.data)
                    setGuarantor1(res.data)
                    // getImg()
                } else if (res.status === 204) {
                    // console.log("ไม่พบข้อมูล")
                }
            }).catch((err) => {
                console.log("err", err)
            })
        setLoading(false)
    }

    // const getImg = async () => {
    //     // setLoading(true)
    //     const path = `one_images/car/${getData?.cars?.carId}/5`
    //     await axios.get(`${getAllFolderGuaImg}/${path}?token=${token}`)
    //         .then(res => {
    //             if (res.data) {
    //                 setGetImages(res.data)
    //                 setLoading(false)
    //             } else {
    //                 setGetImages([])
    //             }
    //         })
    //         .catch((err) => {
    //             console.log("err", err)
    //             setLoading(false)
    //         })
    //     setLoading(false)
    // }

    const handleCancel = () => {
        close()
    }

    const SuccSend = (placement) => {
        api.success({
            message: <b>ส่งเคสสำเร็จ</b>,
            // description: `กรุณาเพิ่มที่อยู่ผู้ค้ำ ลำดับที่ ${index + 1}`,
            placement,
        });
    }

    const showConfirm = async () => {
        var mix = { ...getData.cars.carLoanDetails, guarantors: guarantor?.data, cars: { ...getData.cars, carInput: dayjs().format("YYYY-MM-DDTHH:mm:ssZ") }, approvalStatus: 16 }
        // console.log("mix", mix)
        confirm({
            title: 'คุณต้องการที่จะส่งเคสนี้อีกครั้ง...?',
            onOk() {
                setLoading(true)
                axios.put(resendcarmk, mix)
                    .then(res => {
                        if (res.status === 200) {
                            SuccSend("top")
                            handleSendNote()
                        }
                    }).catch((err) => console.log(err))
            },
            onCancel() {
                // dispatch(addImage())
            },
        });
    };

    const currencyFormatOne = (amount) => {
        return Number(amount)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, '$&,')
    }

    const handleSendNote = async () => {
        axios.post(makenoteresend, saveResendNote)
            .then(res => {
                if (res.status === 200) {
                    setLoading(false)
                    setTimeout(() => {
                        close(false)
                    }, 2000);
                }
            }).catch((err) => console.log(err))
    };

    const showConfirmOK = () => {
        confirm({
            title: 'ผู้กู้ยอมรับยอดที่อนุมัติ...?',
            onOk() {
                var checkOK = true
                customerConfirm(checkOK)
                // setTimeout(() => {

                // }, 1500)
            },
            onCancel() {
            },
        });
    };

    const showConfirmCancel = () => {
        confirm({
            title: 'ผู้กู้ปฏิเสธยอดที่อนุมัติ...?',
            onOk() {
                var checkOK = false
                customerConfirm(checkOK)

            },
            onCancel() {
            },
        });
    };

    const customerConfirm = async (checkOK) => {
        setLoading(true)
        if (checkOK === true) {
            var data = { loanId: getData?.cars?.carLoanDetails?.loanId, approvalStatus: 24, cusIsAccepted: true, cusIsAcceptedDate: dayjs() }
        } else {
            var data = { loanId: getData?.cars?.carLoanDetails?.loanId, approvalStatus: 25, cusIsAccepted: false, cusIsAcceptedDate: dayjs() }
        }
        await axios.put(customerconfirmcar, data)
            .then(async res => {
                if (res.status === 200) {
                    setLoading(false)
                    if (data.approvalStatus === 24) {
                        message.success('ผู้กู้ยอมรับยอดที่อนุมัติ')
                    } else {
                        message.error('ผู้กู้ปฏิเสธยอดที่อนุมัติ')
                    }
                    await handleUpload()
                    setTimeout(() => {
                        handleCancel();
                    }, 2000)
                }
            }).catch((err) => {
                console.log("err", err)
            })
    };


    let color

    if (dataModalCars?.cars?.carLoanDetails?.approvalStatus === 16) {
        color = "gold"
    }
    if (dataModalCars?.cars?.carLoanDetails?.approvalStatus === 17) {
        color = "orange"
    }
    if (dataModalCars?.cars?.carLoanDetails?.approvalStatus === 18) {
        color = "cyan"
    }
    if (dataModalCars?.cars?.carLoanDetails?.approvalStatus === 19) {
        color = "geekblue"
    }
    if (dataModalCars?.cars?.carLoanDetails?.approvalStatus === 2) {
        color = "lime"
    }
    if (dataModalCars?.cars?.carLoanDetails?.approvalStatus === 26) {
        color = "lime"
    }
    if (dataModalCars?.cars?.carLoanDetails?.approvalStatus === 3) {
        color = "green"
    }
    if (dataModalCars?.cars?.carLoanDetails?.approvalStatus === 27) {
        color = "green"
    }
    if (dataModalCars?.cars?.carLoanDetails?.approvalStatus === 21) {
        color = "green"
    }
    if (dataModalCars?.cars?.carLoanDetails?.approvalStatus === 22) {
        color = "red"
    }
    if (dataModalCars?.cars?.carLoanDetails?.approvalStatus === 25) {
        color = "red"
    }
    if (dataModalCars?.cars?.carLoanDetails?.approvalStatus === 20) {
        color = "red"
    }

    const renderGuarantorNewImg = ({ item, index }) => {
        var itemSplit = []
        if (item?.pathImg) {
            itemSplit = item.pathImg.split("/")
        }
        return (
            <>
                <div><b><u>ลำดับที่ {index + 1}</u></b></div>
                <aside style={{ width: '90%' }}>
                    <div style={{ marginBottom: 0 }}>
                        <span>ชื่อ-นามสกุล : </span><b>{item?.snam + ' ' + item?.firstname + ' ' + item?.lastname}</b>
                    </div>
                    {item?.phones?.length > 0 ?
                        <>
                            <div style={{ marginBottom: 0 }}>
                                <span>เบอร์โทรติดต่อ : </span> <b>{item?.phones[0]?.telp}</b>
                            </div>
                        </>
                        : <div style={{ marginBottom: 0 }}>
                            <span>เบอร์โทรติดต่อ : </span> <b>ไม่มี</b>
                        </div>
                    }
                    {item?.address?.length > 0 ?
                        <>
                            <div style={{ marginBottom: 0 }}>
                                <span > ที่อยู่ :  </span>
                                <b>
                                    {
                                        item.address[0]?.houseNumber + ' ' +
                                        "หมู่ " + item.address[0]?.soi + ' ' +
                                        "ถ." + item.address[0]?.road + ' ' +
                                        "ต." + item.address[0]?.subdistrict + ' ' +
                                        "อ." + item.address[0]?.district + ' ' +
                                        "จ." + item.address[0]?.province + ' '
                                        + item.address[0]?.zipCode
                                    }
                                </b>
                            </div>
                        </>
                        : null
                    }
                    {itemSplit.length > 0 ? (
                        <>
                            <Row justify={"center"}>
                                {imageBlobzz?.map((items, index) => {
                                    const segments = items.pathImage.split("/")
                                    if (itemSplit[4] === segments[4] && items.type === 5) {
                                        return (
                                            // <Image width={'100px'} key={index} src={`data:${items?.Attr};base64,${items?.data?.toString('base64')}`} alt={`Image ${index + 1}`} />
                                            <Image width={"100px"} key={index} src={`${getImagessGua}/${items.pathImage}?token=${token}`} alt={`รูปภาพ ${index + 1}`} />
                                        )
                                    }
                                })}
                            </Row>
                        </>
                    ) : null
                    }

                </aside>
                <Divider style={{ margin: 5 }} />
            </>
        )
    }

    const renderGuarantor = ({ item, index }) => { //item คือตัวแปรที่เอาไว้ดึงค่าของข้อมูลออกมาแสดง โดยใช้ item.ชื่อฟิวล์
        return (
            <>
                <div><b><u>ลำดับที่ {index + 1}</u></b></div>
                <aside style={{ width: '90%' }}>
                    <div style={{ marginBottom: 0 }}>
                        <span>ชื่อ-นามสกุล : </span><b>{item?.snam + ' ' + item?.firstname + ' ' + item?.lastname}</b>
                    </div>

                    {/* <div style={{ marginBottom: 0 }}>
                        <span>ความสัมพันธ์ : </span> <b>{item?.description}</b>
                    </div> */}
                    {item?.phones?.length > 0 ?
                        <>
                            <div style={{ marginBottom: 0 }}>
                                <span>เบอร์โทรติดต่อ : </span> <b>{item?.phones[0]?.telp}</b>
                            </div>
                        </>
                        : <div style={{ marginBottom: 0 }}>
                            <span>เบอร์โทรติดต่อ : </span> <b>ไม่มี</b>
                        </div>
                    }

                    {item?.address?.length > 0 ?
                        <>
                            <div style={{ marginBottom: 0 }}>
                                <span > ที่อยู่ :  </span>
                                <b>
                                    {
                                        item.address[0]?.houseNumber + ' ' +
                                        "หมู่ " + item.address[0]?.soi + ' ' +
                                        "ถ." + item.address[0]?.road + ' ' +
                                        "ต." + item.address[0]?.subdistrict + ' ' +
                                        "อ." + item.address[0]?.district + ' ' +
                                        "จ." + item.address[0]?.province + ' '
                                        + item.address[0]?.zipCode
                                    }
                                </b>
                            </div>
                        </>
                        : null
                    }
                </aside>
                <Divider style={{ margin: 5 }} />
            </>
        )
    }
    const renderItemAddress = ({ item, index }) => { //item คือตัวแปรที่เอาไว้ดึงค่าของข้อมูลออกมาแสดง โดยใช้ item.ชื่อฟิวล์
        return (
            <>
                <div><b><u>ลำดับที่ {index + 1}</u></b></div>
                <aside style={{ width: '90%' }}>
                    <div
                        style={{ marginBottom: 0 }}
                    >
                        <span>เลขทะเบียนบ้าน : </span> <b>{item.houseRegistrationNumber}</b>
                    </div>
                    <div
                        style={{ marginBottom: 0 }}
                    >
                        <span>ถนน : </span><b>{item.road}</b>
                    </div>
                    <div
                        style={{ marginBottom: 0 }}
                    >
                        <span>ตำบล : </span> <b>{item.subdistrict}</b>
                    </div>


                    <div
                        style={{ marginBottom: 0 }}
                    >
                        <span>บ้านเลขที่ : </span> <b>{item.houseNumber}</b>
                    </div>
                    <div
                        style={{ marginBottom: 0 }}
                    >
                        <span>หมู่บ้าน : </span> <b>{item.village}</b>
                    </div>
                    <div
                        style={{ marginBottom: 0 }}
                    >
                        <span>อำเภอ : </span> <b>{item.district}</b>
                    </div>

                    <div
                        style={{ marginBottom: 0 }}
                    >
                        <span>หมู่ / ซอย : </span> <b>{item.soi}</b>
                    </div>

                    <div
                        style={{ marginBottom: 0 }}
                    >
                        <span>จังหวัด : </span> <b>{item.province}</b>
                    </div>
                    <div
                        style={{ marginBottom: 0 }}
                    >
                        <span>รหัสไปรษณีย์ : </span> <b>{item.zipCode}</b>
                    </div>

                </aside>
                {address.length >= 1 ? <Divider /> : null}
            </>
        )
    }
    const renderItemPhones = ({ item, index }) => { //item คือตัวแปรที่เอาไว้ดึงค่าของข้อมูลออกมาแสดง โดยใช้ item.ชื่อฟิวล์
        return (
            <>
                <div
                    style={{ marginBottom: 0 }}
                >
                    <span>{item.phoneType ? <>{item.phoneType} : </> : null}</span>
                    <b> {item.telp}</b>
                </div>
            </>
        )
    }
    const renderDataNote = ({ note, length1 }) => {
        return (
            <>
                <Row gutter={32} justify={'center'}>
                    <Col className='gutter-row' span={24}>
                        {guarantor?.data?.typeLoan === "car" ?
                            <>
                                <Card
                                    key={length1}
                                    style={{ width: '270px', textAlign: 'left' }}>
                                    <>
                                        <div style={{ marginBottom: 0 }}>
                                            <span>สาเหตุ : </span>
                                            <b>{note[length1].cause}</b>
                                        </div>
                                        <div style={{ marginBottom: 0 }}>
                                            <span>หมายเหตุ : </span>
                                            <b>{note[length1].note}</b>
                                        </div>
                                        <div style={{ marginBottom: 0 }}>
                                            <span>ผู้สร้างหมายเหตุ : </span>
                                            <b>{note[length1].noteBy}</b>
                                        </div>
                                        <div style={{ marginBottom: 0 }}>
                                            <span>สถานะที่ทำการหมายเหตุ : </span>
                                            <b>{note[length1].status}</b>
                                        </div>
                                    </>
                                </Card>
                            </>
                            :
                            <>
                                <Card
                                    key={length1}
                                    style={{ width: '270px', textAlign: 'left' }}>
                                    <>
                                        <div style={{ marginBottom: 0 }}>
                                            <span>สาเหตุ : </span>
                                            <b> {note[length1].cause}</b>
                                        </div>
                                        <div style={{ marginBottom: 0 }}>
                                            <span>หมายเหตุ : </span>
                                            <b> {note[length1].note}</b>
                                        </div>
                                        <div style={{ marginBottom: 0 }}>
                                            <span>ผู้สร้างหมายเหตุ : </span>
                                            <b>{note[length1].noteBy}</b>
                                        </div>
                                        <div style={{ marginBottom: 0 }}>
                                            <span>สถานะที่ทำการหมายเหตุ : </span>
                                            <b>{note[length1].status}</b>
                                        </div>
                                    </>
                                </Card>
                            </>
                        }
                    </Col>
                </Row>
            </>
        )
    }

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);
    };

    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };

    const handleCustomerImage = async ({ fileList }) => {

        for (const removedFile of fileListCustomer.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 4)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 4));
                setAllImg(updatedAllImg);
            }
        }
        setFileListCustomer(fileList);
        setCheckImage(fileList);
        //setAllImg({ ...allImg, ts4: fileList });
        setLoading(false)
    };
    const handleCustomerImageCareer = async ({ fileList }) => {

        for (const removedFile of fileListCustomerImageCareer.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 6)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 6));
                setAllImg(updatedAllImg);
            }
        }

        setFileListCustomerImageCareer(fileList);
        setCheckImage(fileList);
        //setAllImg({ ...allImg, ts6: fileList });
        setLoading(false)
    };
    const handleCustomerImageCar = async ({ fileList }) => {

        for (const removedFile of fileListCustomerImageCar.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 1)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 1));
                setAllImg(updatedAllImg);
            }
        }
        setFileListCustomerImageCar(fileList);
        setCheckImage(fileList);
        //setAllImg({ ...allImg, ts1: fileList });
        setLoading(false)
    };
    const handleCustomerImageDocument = async ({ fileList }) => {

        for (const removedFile of fileListCustomerDocument.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 2)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 2));
                setAllImg(updatedAllImg);
            }
        }
        setFileListCustomerDocument(fileList);
        setCheckImage(fileList);
        //setAllImg({ ...allImg, ts2: fileList });
        setLoading(false)
    };
    const handleGuarantor = async ({ fileList }) => {

        for (const removedFile of fileListGuarantor.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 5)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 5));
                setAllImg(updatedAllImg);
            }
        }
        setFileListGuarantor(fileList);
        setCheckImage(fileList);
        //setAllImg({ ...allImg, ts5: fileList });
        setLoading(false)
    };
    const handleStatement = async ({ fileList }) => {

        for (const removedFile of fileListStatement.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 8)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 8));
                setAllImg(updatedAllImg);
            }
        }
        setFileListStateMent(fileList);
        setCheckImage(fileList);
        //setAllImg({ ...allImg, ts8: fileList });
        setLoading(false)
    };
    const handleStatementPDF = async ({ fileList }) => {

        for (const removedFile of fileListStatementPDF.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 7)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 7));
                setAllImg(updatedAllImg);
            }
        }
        setFileListStateMentPDF(fileList);
        setCheckImage(fileList);
        //setAllImg({ ...allImg, ts7: fileList });
        setLoading(false)
    };
    const handleOtherMent = async ({ fileList }) => {

        for (const removedFile of fileListOtherMent.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 12)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 12));
                setAllImg(updatedAllImg);
            }
        }
        setFileListOtherMent(fileList);
        setCheckImage(fileList);
        //setAllImg({ ...allImg, ts12: fileList });
        setLoading(false)
    };

    const compressImage = async (ez, types) => {
        var image = {};
        return new Promise(async (resolve) => {
            if (types !== 7) {
                if (ez.size > 4000000) {
                    // console.log("4m ไม่ให้ส่ง");
                    resolve(true);  // บอกให้ระบบทราบว่ากระบวนการเสร็จสิ้น
                } else {
                    let quality;
                    if (ez.size >= 2500000 && ez.size <= 4000000) {
                        quality = 0.1;
                    } else if (ez.size >= 2000000 && ez.size <= 2499999) {
                        quality = 0.15;
                    } else if (ez.size >= 1500000 && ez.size <= 1999999) {
                        quality = 0.2;
                    } else if (ez.size >= 1000000 && ez.size <= 1499999) {
                        quality = 0.3;
                    } else {
                        quality = 0.5;
                    }

                    try {
                        // ใช้ Promise เพื่อบีบอัดรูปภาพ
                        const compressedFile = await new Promise((resolveCompress) => {
                            new Compressor(ez, {
                                quality: quality,
                                success: (result) => {
                                    resolveCompress(result);
                                },
                            });
                        });

                        const fileOptions = {
                            type: compressedFile.type,
                            lastModified: Date.now(),
                        };
                        const compressedFileAsFile = new File([compressedFile], ez.name, fileOptions);
                        image.imageCompressedFile = compressedFileAsFile;
                        image.type = types
                        allImg.push(image);
                        //setAllImg((prevAllImg) => [...prevAllImg, image]);

                        // ตรงนี้คือการ resolve ไฟล์ที่ถูกบีบอัด
                        resolve(image);
                    } catch (error) {
                        console.error('Image compression error:', error);
                        resolve(true);  // บอกให้ระบบทราบว่ากระบวนการเสร็จสิ้น
                    }
                }
            } else {
                // ... (โลจิกสำหรับ type 7)
                image.imageCompressedFile = ez;
                image.type = types
                allImg.push(image);
                //setAllImg((prevAllImg) => [...prevAllImg, image]);
                resolve(true);  // บอกให้ระบบทราบว่ากระบวนการเสร็จสิ้น
            }
        });
    };

    const beforeUpts = async (file, ty) => {
        try {
            setLoading(true)
            const compressedFile = await compressImage(file, ty);
            return Promise.resolve(compressedFile);
        } catch (error) {
            console.error('Image compression error:', error);
            message.error('Image compression failed.');
            return Promise.reject(); // เปลี่ยนให้ return Promise.reject() เมื่อไม่สามารถบีบอัดได้
        }
    };

    const handleCancelPreview = () => {
        setPreviewVisible(false);
    }

    const handleUpload = async () => {
        // console.log("allImg", allImg)
        if (allImg?.length > 0) {
            await imgSend(allImg)
        }
    };

    const imgSend = (resultImgs) => {
        setLoading(true)
        return new Promise(async (resolve, reject) => {
            try {
                var status;
                newFormData.append('idcus', getData?.cars?.carId);
                //newFormData.append('productTypeId', "1");
                newFormData.append('tm', dayjs().format("YYYY-MM-DDTHH:mm:ssZ"));

                for (const file of resultImgs) {
                    var image = {}
                    newFormData.append('file', file.imageCompressedFile);
                    newFormData.append("type", file.type);
                    image.type = file.type
                    // resultImage.push(image)
                }
                //const response = await axios.post('http://localhost:8060/Apimg/mutips-2', newFormData);
                const response = await axios.post(mutips2, newFormData);
                if (response.data) {
                    //success(randomTenDigitNumber);
                    console.log("TY", response.data);
                    //dispatch(addSocial(response.data))
                    status = response.status;
                    resolve(status);
                }
            } catch (error) {
                error(error);
                reject(error);
            } finally {
                setLoading(false);
                console.log("OK")
            }
        });
    };

    return (
        <>
            <Row justify={'center'}>
                <Spin spinning={loading} size='large' tip=" Loading... ">
                    <Row justify={'center'} style={{ margin: '5px', fontSize: '18px' }} >รายละเอียดการขอกู้</Row>
                    <Row justify={'center'}>
                        <Form
                            labelCol={{
                                span: 12,
                            }}
                            wrapperCol={{
                                span: 24,
                            }}
                            style={{
                                width: '90%',
                            }}
                            form={form}
                            autoComplete="off"
                            initialValues={{ remember: true }}
                        >
                            <div className='main'>
                                <Card style={{ width: '100%' }}>
                                    <Row justify={'center'}>
                                        <Col span={24} style={{ textAlign: 'right' }}>
                                            <div style={{ margin: 0 }}>
                                                สถานะ : <Tag color={ApprovalStatusColor(dataModalCars?.cars?.carLoanDetails?.approvalStatus)}>{dataModalCars?.cars?.carLoanDetails?.approvalStatusTable?.name}</Tag>
                                            </div>
                                            <div style={{ margin: 0 }}>
                                                วันที่ถูกเปลี่ยนสถานะ : {dayjs(dataModalCars?.cars?.carLoanDetails?.cars?.carDateStatus).format("DD-MM-YYYY")}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Divider />
                                    {dataModalCars?.nationalId === "นิติบุคคล" ?
                                        <>
                                            <Row justify={'left'}><b><u>รายละเอียดผู้ขอกู้</u></b></Row>
                                            <Row gutter={32} justify={'center'}>
                                                <aside style={{ width: '90%' }}>
                                                    <div label='ประเภท' name='identificationId' style={{ marginBottom: 3 }}>
                                                        <span>ประเภท : </span> <b>{dataModalCars?.nationalId}</b>
                                                    </div>
                                                    <div label='เลขประจำตัวผู้เสียภาษี' name='identificationId' style={{ marginBottom: 3 }}>
                                                        <span>เลขประจำตัวผู้เสียภาษี : </span> <b>{dataModalCars.identificationId}</b>
                                                    </div>
                                                    <div label='ชื่อบริษัท' name='snam' style={{ marginBottom: 3 }}>
                                                        <span>
                                                            {dataModalCars?.snam} : </span> <b>{dataModalCars?.firstname + ' ' + dataModalCars?.lastname}</b>
                                                    </div>
                                                    <div label='วันจดทะเบียน' name='nickname' style={{ marginBottom: 3 }}>
                                                        <span>วันจดทะเบียน : </span> <b> {dataModalCars?.birthdate}</b>
                                                    </div>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <b>

                                                            {
                                                                dataPhoneCus?.map((item, index) => {
                                                                    return renderItemPhones({ item, index, key: `{item.identification} - ${index}` })
                                                                })
                                                            }
                                                        </b>
                                                    </div>
                                                </aside>
                                            </Row>
                                        </>
                                        :
                                        <>
                                            <Row justify={'left'}><b><u>รายละเอียดผู้ขอกู้</u></b></Row>
                                            <Row gutter={32} justify={'center'}>
                                                <>
                                                    <aside style={{ width: '90%' }}>
                                                        <div
                                                            style={{ marginBottom: 0, }}
                                                        >
                                                            <span>ชื่อ - นามสกุล : </span>
                                                            <b>{dataModalCars?.snam} {dataModalCars?.firstname} {dataModalCars?.lastname}</b>
                                                        </div>
                                                        <div
                                                            style={{ marginBottom: 0, }}
                                                        >
                                                            <span>ชื่อเล่น : </span>
                                                            <b>{dataModalCars?.nickname}</b>
                                                        </div>

                                                        <div
                                                            style={{ marginBottom: 0, }}
                                                        >
                                                            <span>เลขบัตรประชาชน : </span>
                                                            <b>{dataModalCars?.identificationId}</b>
                                                        </div>
                                                        <div
                                                            style={{ marginBottom: 0, }}
                                                        >
                                                            <b>
                                                                {
                                                                    dataPhoneCus?.map((item, index) => {
                                                                        return renderItemPhones({ item, index, key: `{item.identification} - ${index}` })
                                                                    })
                                                                }
                                                            </b>
                                                        </div>
                                                        <div
                                                            style={{ margin: 0, }}
                                                        >
                                                            <span>เพศ : </span>
                                                            <b>{dataModalCars?.gender}</b>
                                                        </div>
                                                        <div
                                                            style={{ marginBottom: 0, }}
                                                        >
                                                            <span>{dataEmail === "" ? <> </> : <>{dataEmail?.emailData}</>}</span>
                                                            <b>{dataEmail?.emailData}</b>
                                                        </div>
                                                        <div
                                                            style={{ marginBottom: 0, }}
                                                        >
                                                            <span>{dataSocials?.socialType}  </span>
                                                            <b>{dataSocials?.socialData}</b>
                                                        </div>
                                                    </aside>
                                                </>
                                            </Row>
                                        </>
                                    }
                                    <Divider />
                                    {address?.length >= 1 ?
                                        <>
                                            <Row justify={'left'}>
                                                <b><u>รายละเอียดที่อยู่</u></b>
                                            </Row>
                                            <Row gutter={32} justify={'center'}>
                                                {
                                                    address?.map((item, index) => {
                                                        return renderItemAddress({ item, index, key: `{item.identification} - ${index}` })
                                                    })
                                                }

                                            </Row>
                                        </>
                                        : null
                                    }

                                    {dataModalCars?.cars?.carLoanDetails?.productLoanType === "ย้ายไฟแนนซ์" || dataModalCars?.cars?.carLoanDetails?.productLoanType === "Pre-Aaprove-ย้ายไฟแนนซ์" ?
                                        <>
                                            <Row justify={'left'}><b><u>รายละเอียดยอดกู้{dataModalCars?.cars?.carLoanDetails?.productLoanType}</u></b></Row>
                                            <Row gutter={32} justify={'center'}>
                                                <aside style={{ width: '90%' }}>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>ยอดกู้ : </span>
                                                        <b>{currencyFormatOne(dataModalCars?.cars?.carLoanDetails?.loanAmount)}</b>
                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                            บาท
                                                        </span>
                                                    </div>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>จำนวนงวด : </span>
                                                        <b>{dataModalCars?.cars?.carLoanDetails?.loanTerm}</b>
                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                            งวด
                                                        </span>
                                                    </div>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>ดอกเบี้ย : </span>
                                                        <b>{currencyFormatOne(dataModalCars?.cars?.carLoanDetails?.interest)}</b>
                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                            บาท
                                                        </span>
                                                    </div>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>จำนวนผ่อนต่อเดือน : </span>
                                                        <b>{currencyFormatOne(dataModalCars?.cars?.carLoanDetails?.monthlyPayment)}</b>
                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                            บาท
                                                        </span>
                                                    </div>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>ผ่อนรวมดอกเบี้ย : </span>
                                                        <b>{currencyFormatOne(~~dataModalCars?.cars?.carLoanDetails?.monthlyPayment * ~~dataModalCars?.cars?.carLoanDetails?.loanTerm)}</b>
                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                            บาท
                                                        </span>
                                                    </div>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>ผู้เสนอเคส : </span>
                                                        <b>{dataModalCars?.cars?.carLoanDetails?.proposalBy}</b>
                                                    </div>
                                                    <b> <div
                                                        style={{ margin: 0, }}
                                                    // label='เรทรถ'
                                                    >
                                                        <span>เรทรถ : </span>
                                                        <b style={{ fontSize: '20px' }}><u>{currencyFormatOne(dataModalCars?.cars?.carPrice)}</u></b>
                                                        <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                                            บาท
                                                        </span>
                                                    </div>
                                                    </b>
                                                </aside>
                                            </Row>
                                            <Divider />
                                            <Row justify={'left'}><b><u>รายละเอียดย้ายไฟแนนซ์เดิม</u></b></Row>
                                            <Row gutter={32} justify={'center'}>
                                                <aside style={{ width: '90%' }}>
                                                    <div style={{ marginBottom: 0, textAlign: 'left' }}>
                                                        <span>จากไฟแนนซ์ : </span>
                                                        <b>{dataModalCars?.cars?.carOldLoanDetails?.issuno}</b>
                                                    </div>
                                                    <div style={{ marginBottom: 0, textAlign: 'left' }}>
                                                        <span>ยอดปิดไฟแนนซ์ : </span>
                                                        <b>{currencyFormatOne(dataModalCars?.cars?.carOldLoanDetails?.oldLoanAmount)}</b>
                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                            บาท
                                                        </span>
                                                    </div>
                                                    <div style={{ marginBottom: 0, textAlign: 'left' }}>
                                                        <span>ค่างวด / เดือน : </span>
                                                        <b>{currencyFormatOne(dataModalCars?.cars?.carOldLoanDetails?.oldMonthlyPayment)}</b>
                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                            บาท
                                                        </span>
                                                    </div>

                                                    <div style={{ marginBottom: 0, textAlign: 'left' }}>
                                                        <span>จำนวนงวดที่ผ่อน : </span>
                                                        <b>{dataModalCars?.cars?.carOldLoanDetails?.oldLoanTerm}</b>
                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                            งวด
                                                        </span>
                                                    </div>
                                                </aside>
                                                <Divider />
                                            </Row>
                                        </>
                                        :
                                        <>
                                            <Row justify={'left'}><b><u>รายละเอียด({dataModalCars?.cars?.carLoanDetails?.productLoanType})</u></b></Row>
                                            {dataModalCars?.cars?.carLoanDetails?.productType === 'เช่าซื้อ' ?
                                                <>
                                                    <Row gutter={32} justify={'center'}>
                                                        <aside style={{ width: '90%' }}>
                                                            <div
                                                                style={{ marginBottom: 0, }}
                                                            // label='ยอดกู้เช่าซื้อ'
                                                            >
                                                                <span>ยอดกู้เช่าซื้อ : </span>
                                                                <b>{currencyFormatOne(dataModalCars?.cars?.carLoanDetails?.loanAmount)}</b>
                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                    บาท
                                                                </span>
                                                            </div>
                                                            <div
                                                                style={{ marginBottom: 0, }}
                                                            // label='จำนวนงวดเช่าซื้อ'
                                                            >
                                                                <span>จำนวนงวดเช่าซื้อ : </span>
                                                                <b>{dataModalCars?.cars?.carLoanDetails?.loanTerm}</b>
                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                    งวด
                                                                </span>
                                                            </div>

                                                            <div
                                                                style={{ marginBottom: 0, }}
                                                            // label='ดอกเบี้ยเช่าซื้อ'
                                                            >
                                                                <span>ดอกเบี้ยเช่าซื้อ : </span>
                                                                {/* <b>{currencyFormatOne(resultRateCarDATA)}</b> */}
                                                                <b>{currencyFormatOne(dataModalCars?.cars?.carLoanDetails?.interest)}</b>
                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                    บาท
                                                                </span>
                                                            </div>
                                                            <div
                                                                style={{ marginBottom: 0, }}
                                                            // label='จำนวนผ่อนต่อเดือนเช่าซื้อ'
                                                            >
                                                                <span>จำนวนผ่อนต่อเดือนเช่าซื้อ : </span>
                                                                {/* <b>{currencyFormatOne(newLoanTermCarDATA)}</b> */}
                                                                <b>{currencyFormatOne(dataModalCars?.cars?.carLoanDetails?.monthlyPayment)}</b>
                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                    บาท
                                                                </span>
                                                            </div>

                                                            <div
                                                                style={{ marginBottom: 0, }}
                                                            // label='ผ่อนรวมดอกเบี้ยเช่าซื้อ'
                                                            >
                                                                <span>ผ่อนรวมดอกเบี้ยเช่าซื้อ : </span>
                                                                {/* <b>{currencyFormatOne(newResultPriceCarDATA)}</b> */}
                                                                <b>{currencyFormatOne(~~dataModalCars?.cars?.carLoanDetails?.monthlyPayment * ~~dataModalCars?.cars?.carLoanDetails?.loanTerm)}</b>
                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                    บาท
                                                                </span>
                                                            </div>
                                                            <div
                                                                style={{ marginBottom: 0, }}
                                                            // label='ผู้เสนอเคสเช่าซื้อ'
                                                            >
                                                                <span>ผู้เสนอเคสเช่าซื้อ : </span>
                                                                <b>{dataModalCars?.cars?.carLoanDetails?.proposalBy}</b>
                                                            </div>
                                                            <b> <div
                                                                style={{ margin: 0, }}
                                                            // label='เรทรถ'
                                                            >
                                                                <span>เรทรถ : </span>
                                                                <b style={{ fontSize: '20px' }}><u>{currencyFormatOne(dataModalCars?.cars?.carPrice)}</u></b>
                                                                <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                                                    บาท
                                                                </span>
                                                            </div>
                                                            </b>
                                                        </aside>
                                                        <Divider />
                                                    </Row>
                                                </>
                                                : dataModalCars?.cars?.carLoanDetails?.productType === 'เช่าซื้อ1' ?
                                                    <>
                                                        <Row gutter={32} justify={'center'}>
                                                            <aside style={{ width: '90%' }}>
                                                                <div
                                                                    style={{ marginBottom: 0, }}
                                                                // label='ยอดกู้เช่าซื้อ'
                                                                >
                                                                    <span>ยอดกู้เช่าซื้อ : </span>
                                                                    <b>{currencyFormatOne(dataModalCars?.cars?.carLoanDetails?.loanAmount)}</b>
                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                        บาท
                                                                    </span>
                                                                </div>
                                                                <div
                                                                    style={{ marginBottom: 0, }}
                                                                // label='จำนวนงวดเช่าซื้อ'
                                                                >
                                                                    <span>จำนวนงวดเช่าซื้อ : </span>
                                                                    <b>{dataModalCars?.cars?.carLoanDetails?.loanTerm}</b>
                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                        งวด
                                                                    </span>
                                                                </div>

                                                                <div
                                                                    style={{ marginBottom: 0, }}
                                                                // label='ดอกเบี้ยเช่าซื้อ'
                                                                >
                                                                    <span>ดอกเบี้ยเช่าซื้อ : </span>
                                                                    <b>{currencyFormatOne(dataModalCars?.cars?.carLoanDetails?.interest)}</b>
                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                        บาท
                                                                    </span>
                                                                </div>
                                                                <div
                                                                    style={{ marginBottom: 0, }}
                                                                // label='จำนวนผ่อนต่อเดือนเช่าซื้อ'
                                                                >
                                                                    <span>จำนวนผ่อนต่อเดือนเช่าซื้อ : </span>
                                                                    <b>{currencyFormatOne(dataModalCars?.cars?.carLoanDetails?.monthlyPayment)}</b>
                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                        บาท
                                                                    </span>
                                                                </div>

                                                                <div
                                                                    style={{ marginBottom: 0, }}
                                                                // label='ผ่อนรวมดอกเบี้ยเช่าซื้อ'
                                                                >
                                                                    <span>ผ่อนรวมดอกเบี้ยเช่าซื้อ : </span>
                                                                    <b>{currencyFormatOne(~~dataModalCars?.cars?.carLoanDetails?.monthlyPayment * ~~dataModalCars?.cars?.carLoanDetails?.loanTerm)}</b>
                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                        บาท
                                                                    </span>
                                                                </div>
                                                                <div
                                                                    style={{ marginBottom: 0, }}
                                                                // label='ผู้เสนอเคสเช่าซื้อ'
                                                                >
                                                                    <span>ผู้เสนอเคสเช่าซื้อ : </span>
                                                                    <b>{dataModalCars?.cars?.carLoanDetails?.proposalBy}</b>
                                                                </div>
                                                                <b> <div
                                                                    style={{ margin: 0, }}
                                                                // label='เรทรถ'
                                                                >
                                                                    <span>เรทรถ : </span>
                                                                    <b style={{ fontSize: '20px' }}><u>{currencyFormatOne(dataModalCars?.cars?.carPrice)}</u></b>
                                                                    <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                                                        บาท
                                                                    </span>
                                                                </div>
                                                                </b>
                                                            </aside>
                                                        </Row>
                                                        <Divider />
                                                    </>
                                                    :
                                                    <>
                                                        {/* p - loan */}
                                                        <Row gutter={32} justify={'center'}>
                                                            <aside style={{ width: '90%' }}>
                                                                <div
                                                                    style={{ marginBottom: 0, }}
                                                                >
                                                                    <span>ยอดกู้ p-loan : </span>
                                                                    <b>{currencyFormatOne(dataModalCars?.cars?.carLoanDetails?.loanAmount)}</b>
                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                        บาท
                                                                    </span>
                                                                </div>
                                                                <div
                                                                    style={{ marginBottom: 0, }}
                                                                >
                                                                    <span>จำนวนงวด p-loan : </span>
                                                                    <b>{dataModalCars?.cars?.carLoanDetails?.loanTerm}</b>
                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                        งวด
                                                                    </span>
                                                                </div>

                                                                <div
                                                                    style={{ marginBottom: 0, }}
                                                                >
                                                                    <span>ดอกเบี้ย p-loan : </span>
                                                                    <b>{currencyFormatOne(dataModalCars?.cars?.carLoanDetails?.interest)}</b>
                                                                    {/* <b>{currencyFormatOne(dataModalCars?.cars?.carLoanDetails?.interest)}</b> */}
                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                        บาท
                                                                    </span>
                                                                </div>
                                                                <div
                                                                    style={{ marginBottom: 0, }}
                                                                >
                                                                    <span>จำนวนผ่อน/เดือน p-loan : </span>
                                                                    <b>{currencyFormatOne(dataModalCars?.cars?.carLoanDetails?.monthlyPayment)}</b>
                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                        บาท
                                                                    </span>
                                                                </div>

                                                                <div
                                                                    style={{ marginBottom: 0, }}
                                                                >
                                                                    <span>ผ่อนรวมดอกเบี้ย p-loan : </span>
                                                                    <b>{currencyFormatOne(~~dataModalCars?.cars?.carLoanDetails?.monthlyPayment * ~~dataModalCars?.cars?.carLoanDetails?.loanTerm)}</b>
                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                        บาท
                                                                    </span>
                                                                </div>
                                                                <div
                                                                    style={{ marginBottom: 0, }}
                                                                >
                                                                    <span>ผู้เสนอเคส p-loan : </span>
                                                                    <b>{dataModalCars?.cars?.carLoanDetails?.proposalBy}</b>
                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                        บาท
                                                                    </span>
                                                                </div>
                                                                <b> <div
                                                                    style={{ margin: 0, }}
                                                                >
                                                                    <span>เรทรถ : </span>
                                                                    <b style={{ fontSize: '20px' }}><u>{currencyFormatOne(dataModalCars?.cars?.carPrice)}</u></b>
                                                                    <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                                                        บาท
                                                                    </span>
                                                                </div>
                                                                </b>
                                                            </aside>
                                                        </Row>
                                                        <Divider />
                                                    </>
                                            }
                                        </>
                                    }
                                    {
                                        dataModalCars?.cars?.carLoanDetails?.approvalStatus === 3 ||
                                            dataModalCars?.cars?.carLoanDetails?.approvalStatus === 21 ||
                                            dataModalCars?.cars?.carLoanDetails?.approvalStatus === 27 ||
                                            dataModalCars?.cars?.carLoanDetails?.approvalStatus === 25 ||
                                            dataModalCars?.cars?.carLoanDetails?.approvalStatus === 26 ||
                                            dataModalCars?.cars?.carLoanDetails?.approvalStatus === 24 ?
                                            <>
                                                <Row justify={'left'}><b><u>อนุมัติ {dataModalCars?.cars?.apCarLoanDetails?.apProductType !== "" ? dataModalCars?.cars?.apCarLoanDetails?.apProductType : dataModalCars?.cars?.carLoanDetails?.productType} {
                                                    dataModalCars?.cars?.apCarLoanDetails?.apProductType === "เช่าซื้อ1" || dataModalCars?.cars?.carLoanDetails?.productType === "เช่าซื้อ1" ?
                                                        <>
                                                            {dataModalCars?.cars?.apCarLoanDetails?.apInterestRate !== "" ? <>({dataModalCars?.cars?.apCarLoanDetails?.apInterestRate})</> : <>({dataModalCars?.cars?.carLoanDetails?.interestRate})</>}
                                                        </>
                                                        : null
                                                }</u></b></Row>
                                                <Row gutter={32} justify={'center'} style={{ backgroundColor: 'lightgreen' }}>
                                                    <aside style={{ width: '90%' }}>
                                                        <div style={{ marginBottom: 0, }}>
                                                            <span>ยอดกู้ : </span>
                                                            {
                                                                dataModalCars?.cars?.apCarLoanDetails?.apLoanAmount !== "0" ?
                                                                    <b>{currencyFormatOne(dataModalCars?.cars?.apCarLoanDetails?.apLoanAmount)}</b>
                                                                    :
                                                                    <b>{currencyFormatOne(dataModalCars?.cars?.carLoanDetails?.approvedLoanAmount)}</b>
                                                            }
                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                บาท
                                                            </span>
                                                        </div>
                                                        <div style={{ marginBottom: 0, }}>
                                                            <span>จำนวนงวด : </span>
                                                            {
                                                                dataModalCars?.cars?.apCarLoanDetails?.apLoanTerm !== "0" ?
                                                                    <b>{currencyFormatOne(dataModalCars?.cars?.apCarLoanDetails?.apLoanTerm)}</b>
                                                                    :
                                                                    <b>{currencyFormatOne(dataModalCars?.cars?.carLoanDetails?.approvedLoanTerm)}</b>
                                                            }
                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                งวด
                                                            </span>
                                                        </div>
                                                        <div style={{ marginBottom: 0, }}>
                                                            <span>ดอกเบี้ย : </span>
                                                            {
                                                                dataModalCars?.cars?.apCarLoanDetails?.apInterest !== "0" ?
                                                                    <b>{currencyFormatOne(dataModalCars?.cars?.apCarLoanDetails?.apInterest)}</b>
                                                                    :
                                                                    <b>{interestt}</b>
                                                            }
                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                บาท
                                                            </span>
                                                        </div>
                                                        <div style={{ marginBottom: 0, }} >
                                                            <span>จำนวนผ่อนต่อเดือน : </span>
                                                            {
                                                                dataModalCars?.cars?.apCarLoanDetails?.apMonthlyPayment !== "0" ?
                                                                    <b>{currencyFormatOne(dataModalCars?.cars?.apCarLoanDetails?.apMonthlyPayment)}</b>
                                                                    :
                                                                    <b>{arr}</b>
                                                            }
                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                บาท
                                                            </span>
                                                        </div>
                                                        <div style={{ marginBottom: 0, }} >
                                                            <span>ผ่อนรวมดอกเบี้ย : </span>
                                                            {
                                                                dataModalCars?.cars?.apCarLoanDetails?.apInstallmentWithInterest !== "0" ?
                                                                    <b>{currencyFormatOne(dataModalCars?.cars?.apCarLoanDetails?.apInstallmentWithInterest)}</b>
                                                                    :
                                                                    <b>{installmentWithInterestt}</b>
                                                            }
                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                บาท
                                                            </span>
                                                        </div>
                                                        <div style={{ marginBottom: 0, }}>
                                                            <span>ผู้อนุมัติเคส : </span>
                                                            {
                                                                dataModalCars?.cars?.apCarLoanDetails?.apApprovedBy !== "" ?
                                                                    <b>{dataModalCars?.cars?.apCarLoanDetails?.apApprovedBy}</b>
                                                                    :
                                                                    <b>{dataModalCars?.cars?.carLoanDetails?.approvedBy}</b>
                                                            }

                                                        </div>
                                                        <div>
                                                            {/* ตาราง */}
                                                            {
                                                                dataModalCars?.cars?.apCarLoanDetails?.loanId !== 0 ?
                                                                    <>
                                                                        {
                                                                            dataModalCars?.cars?.apCarLoanDetails?.apProductType === "p-loan" ?
                                                                                <>
                                                                                    <Col span={24}>
                                                                                        <div style={{ textAlign: "right" }}>
                                                                                            <Button onClick={openTable}>ตารางค่างวด P-Loan</Button>
                                                                                        </div>
                                                                                    </Col>
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    {
                                                                                        dataModalCars?.cars?.apCarLoanDetails?.apInterestRate === "0.75" || dataModalCars?.cars?.apCarLoanDetails?.apInterestRate === "0.84" ?
                                                                                            null
                                                                                            :
                                                                                            <>
                                                                                                {
                                                                                                    dataModalCars?.cars?.productTypeCar === 1 ?
                                                                                                        <>
                                                                                                            <Col span={24}>
                                                                                                                <div style={{ textAlign: "right" }}>
                                                                                                                    <Button onClick={openTable2}>ตารางค่างวด เช่าซื้อ</Button>
                                                                                                                </div>
                                                                                                            </Col>
                                                                                                        </>
                                                                                                        : null
                                                                                                }

                                                                                            </>
                                                                                    }
                                                                                </>
                                                                        }
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {
                                                                            dataModalCars?.cars?.carLoanDetails?.productType === "p-loan" ?
                                                                                <>
                                                                                    <Col span={24}>
                                                                                        <div style={{ textAlign: "right" }}>
                                                                                            <Button onClick={openTable}>ตารางค่างวด P-Loan</Button>
                                                                                        </div>
                                                                                    </Col>
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    {
                                                                                        dataModalCars?.cars?.carLoanDetails?.interestRate === "0.75" || dataModalCars?.cars?.carLoanDetails?.interestRate === "0.84" ?
                                                                                            null
                                                                                            :
                                                                                            <>
                                                                                                {
                                                                                                    dataModalCars?.cars?.productTypeCar === 1 ?
                                                                                                        <>
                                                                                                            <Col span={24}>
                                                                                                                <div style={{ textAlign: "right" }}>
                                                                                                                    <Button onClick={openTable2}>ตารางค่างวด เช่าซื้อ</Button>
                                                                                                                </div>
                                                                                                            </Col>
                                                                                                        </>
                                                                                                        : null
                                                                                                }
                                                                                            </>
                                                                                    }
                                                                                </>
                                                                        }
                                                                    </>
                                                            }
                                                        </div>
                                                    </aside>
                                                </Row>
                                                <Divider />
                                            </>
                                            : null
                                    }
                                    <Row justify={'left'}><b><u>รายละเอียดรถ</u></b></Row>
                                    <Row gutter={32} justify={'center'}>
                                        <aside style={{ width: '90%' }}>
                                            <div style={{ marginBottom: 0, }}>
                                                <span>ยี่ห้อ : </span>
                                                <b>{dataModalCars?.cars?.carBrand}</b>
                                            </div>
                                            <div style={{ marginBottom: 0, }}>
                                                <span>เลขทะเบียน : </span>
                                                <b>{dataModalCars?.cars?.carPlateNumber}</b>
                                            </div>
                                            <div style={{ marginBottom: 0, }}>
                                                <span>เลขเครื่อง : </span>
                                                <b>{dataModalCars?.cars?.carEngineNumber}</b>
                                            </div>
                                            <div style={{ marginBottom: 0, }}>
                                                <span>รุ่นสินค้า : </span>
                                                <b>{dataModalCars?.cars?.carModel}</b>
                                            </div>
                                            <div style={{ marginBottom: 0, }}>
                                                <span>เลขคัสซี : </span>
                                                <b>{dataModalCars?.cars?.carChassisNumber}</b>
                                            </div>
                                            <div style={{ marginBottom: 0, }}>
                                                <span>สี : </span>
                                                <b>{dataModalCars?.cars?.carColor}</b>
                                            </div>

                                            <div style={{ marginBottom: 0, }}>
                                                <span>แบบรถ : </span>
                                                <b>{dataModalCars?.cars?.carBaab}</b>
                                            </div>
                                            <div style={{ marginBottom: 0, }}>
                                                <span>ปีรถ : </span>
                                                <b>{dataModalCars?.cars?.carYear}</b>
                                            </div>
                                        </aside>
                                    </Row>
                                    <Divider />
                                    {
                                        ShowDataBrokers(branch, dataModalCars?.cars?.carLoanDetails, dataBroker)
                                    }
                                    <Row justify={'left'}>
                                        <b><u>อาชีพ - รายได้</u></b>
                                    </Row>
                                    {career ?
                                        <>
                                            {career?.map((e, index) => {
                                                return (
                                                    <Row gutter={32} justify={'center'}>
                                                        <aside style={{ width: '90%' }}>
                                                            <div style={{ marginBottom: 0, }}>
                                                                <span>กลุ่มอาชีพ : </span>
                                                                <b>{e?.desc}</b>
                                                            </div>
                                                            <div style={{ marginBottom: 0, }}>
                                                                <span>ชื่อบริษัท(สถานที่ทำงาน) : </span>
                                                                <b>{e?.companyName}</b>
                                                            </div>
                                                            <div style={{ marginBottom: 0, }}>
                                                                <span>อาชีพ  : </span>
                                                                <b>{e?.descSub}</b>
                                                            </div>
                                                            <div style={{ marginBottom: 0, }}>
                                                                <span>รายได้ / เดือน : </span>
                                                                <b>{currencyFormatOne(e?.incomeMonth)}</b>
                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                    บาท
                                                                </span>
                                                            </div>
                                                            <div style={{ marginBottom: 0, }}>
                                                                <span>สาขาอาชีพ : </span>
                                                                <b>{e?.descSection}</b>
                                                            </div>

                                                            <div style={{ marginBottom: 0, }}>
                                                                <span>
                                                                    รายจ่าย / เดือน : </span>
                                                                <b>{currencyFormatOne(e?.expensesMonth)}</b>
                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                    บาท
                                                                </span>
                                                            </div>
                                                        </aside>
                                                    </Row>
                                                )
                                            })}
                                        </>
                                        : null
                                    }
                                    <Divider />
                                    {
                                        guarantor1?.length >= 1 ?
                                            <>
                                                {/* {getImages?.length > 0 ?
                                                    <> */}
                                                <Row justify={'left'}><b><u>รายละเอียดคนค้ำ</u></b></Row>
                                                <Row gutter={32} justify={'center'}>
                                                    {
                                                        guarantor1?.map((item, index) => {
                                                            return renderGuarantorNewImg({ item, index })
                                                        })
                                                    }
                                                </Row>
                                                {/* </>
                                                    :
                                                    <>
                                                        <Row justify={'left'}><b><u>รายละเอียดคนค้ำ</u></b></Row>
                                                        <Row gutter={32} justify={'center'}>
                                                            {
                                                                guarantor1?.map((item, index) => {
                                                                    return renderGuarantor({ item, index })
                                                                })
                                                            }
                                                        </Row>
                                                    </>
                                                } */}
                                            </>
                                            : null
                                    }
                                    <Row justify={'left'}><Col span={24}><b><u>หมายเหตุ</u></b></Col></Row>
                                    <Row gutter={32} justify={'center'}>
                                        {notes?.length >= 1 ?
                                            < Card
                                                key={lastIndex}
                                                style={{ width: '270px', textAlign: 'left' }}>
                                                <div style={{ marginBottom: 0 }}>
                                                    <span>สาเหตุ : </span>
                                                    <b>{notes[lastIndex]?.cause}</b>
                                                </div>
                                                <div style={{ marginBottom: 0 }}>
                                                    <span>หมายเหตุ : </span>
                                                    <b>{notes[lastIndex]?.note}</b>
                                                </div>
                                                {/* <div style={{ marginBottom: 0 }}>
                                                    <span>ผู้สร้างหมายเหตุ : </span>
                                                    <b>{notes[lastIndex]?.noteBy}</b>
                                                </div> */}
                                                <div style={{ marginBottom: 0 }}>
                                                    <span>สถานะที่ทำการหมายเหตุ : </span>
                                                    <b>{notes[lastIndex]?.status}</b>
                                                </div>
                                            </Card>
                                            : <>* ยังไม่เพิ่มหมายเหตุ!</>
                                        }
                                    </Row>
                                    {
                                        getData?.cars?.carLoanDetails?.noteResend?.offercaseNote !== null ?
                                            <>
                                                <Divider />
                                                <Row justify={'left'}><Col span={24}><b><u>หมายเหตุตอนเสนอเคส</u></b></Col></Row>
                                                <Row gutter={32} justify={'center'}>
                                                    <Form.Item name="offercaseNote" label="">
                                                        <TextArea
                                                            name='offercaseNote'
                                                            style={{ color: 'blue' }}
                                                            rows={8}
                                                            disabled
                                                        >
                                                        </TextArea>
                                                    </Form.Item>
                                                </Row>
                                            </>
                                            : null
                                    }
                                    <Divider />
                                    <Row justify={'left'}><b><u>รูปบัตรประชาชนผู้ขอกู้</u></b></Row>
                                    <Row gutter={32} justify={'center'}>
                                        <Space direction="vertical" >
                                            <Row gutter={32} justify={'center'}>
                                                {imageBlobzz?.map((e, index) => {
                                                    var PreAaproveSplit = []
                                                    PreAaproveSplit = e.pathImage.split("/")
                                                    if (PreAaproveSplit[3] !== "13") {
                                                        if (e.type === 4) {
                                                            return (
                                                                <>
                                                                    <Image width={"50px"} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                </>
                                                            );
                                                        }
                                                    }
                                                    return null;
                                                })}
                                            </Row>
                                            {
                                                getData?.cars?.carLoanDetails?.approvalStatus === 21 ?
                                                    <>
                                                        <div className='gutter-row' span={12}>
                                                            <b> <Form.Item label='เพิ่มรูป' name='imageIDGuCar'
                                                            >
                                                                <Upload
                                                                    name='imageIDGuCar'
                                                                    multiple={true}
                                                                    listType="picture-card"
                                                                    fileList={fileListCustomer}
                                                                    onPreview={handlePreview}
                                                                    onChange={handleCustomerImage}
                                                                    //beforeUpload={() => false}
                                                                    beforeUpload={(file) => beforeUpts(file, 4).then(() => false).catch(() => true)}
                                                                >
                                                                    {fileListCustomer?.length >= 5 ? null : (
                                                                        <div>
                                                                            <PlusOutlined />
                                                                            <div className="ant-upload-text">upload</div>
                                                                        </div>
                                                                    )}
                                                                </Upload>
                                                                <Modal open={previewVisible} onCancel={handleCancelPreview}>
                                                                    <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                                </Modal>
                                                            </Form.Item>
                                                            </b>
                                                        </div>
                                                    </>
                                                    :
                                                    null
                                            }
                                        </Space>
                                    </Row>
                                    <Divider />
                                    {
                                        checkSendNEW ?
                                            <>
                                                <Row justify={'left'}><b><u>รูปบัตรประชาชนผู้ขอกู้</u> (ใหม่)</b></Row>
                                                <Row gutter={32} justify={'center'}>
                                                    <Space direction="vertical" >
                                                        <Row gutter={32} justify={'center'}>
                                                            {imageBlobzz?.map((e, index) => {
                                                                var PreAaproveSplit = []
                                                                PreAaproveSplit = e.pathImage.split("/")
                                                                if (PreAaproveSplit[3] === "13") {
                                                                    if (e.type === 4) {
                                                                        return (
                                                                            <>
                                                                                <Image width={"50px"} key={index} src={`${getImagessPre}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                            </>
                                                                        );
                                                                    }
                                                                }
                                                                return null;
                                                            })}
                                                        </Row>
                                                    </Space>
                                                </Row>
                                                <Divider />
                                            </>
                                            : null
                                    }
                                    {/* {
                                        getImages?.length > 0 ?
                                            null
                                            :
                                            <>
                                                <Row justify={'left'}><b><u>รูปบัตรประชาชนคนค้ำประกัน</u></b></Row>
                                                <Row gutter={32} justify={'center'}>
                                                    <Space direction='vertical'>
                                                        <Row gutter={32} justify={'center'}>
                                                            {imageBlobzz?.map((e, index) => {
                                                                if (e.type === 5) {
                                                                    return (
                                                                        <>
                                                                            <Image width={"50px"} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                        </>
                                                                    );
                                                                }
                                                                return null;
                                                            })}
                                                        </Row>
                                                    </Space>
                                                </Row>
                                                <Divider />
                                            </>
                                    } */}
                                    <Row justify={'left'}><b><u>หน้าคู่มือ</u></b></Row>
                                    <Row gutter={32} justify={'center'}>
                                        <Space direction="vertical">
                                            <Row gutter={32} justify={'center'}>
                                                {imageBlobzz?.map((e, index) => {
                                                    var PreAaproveSplit = []
                                                    PreAaproveSplit = e.pathImage.split("/")
                                                    if (PreAaproveSplit[3] !== "13") {
                                                        if (e.type === 2) {
                                                            return (
                                                                <>
                                                                    <Image width={"50px"} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                </>
                                                            );
                                                        }
                                                    }
                                                    return null;
                                                })}
                                            </Row>
                                            {
                                                getData?.cars?.carLoanDetails?.approvalStatus === 21 ?
                                                    <>
                                                        <div className='gutter-row' span={12}>
                                                            <b> <Form.Item label='เพิ่มรูป' name='imageIDGuCar'
                                                            >
                                                                <Upload
                                                                    name='imageIDGuCar'
                                                                    multiple={true}
                                                                    listType="picture-card"
                                                                    fileList={fileListCustomerDocument}
                                                                    onPreview={handlePreview}
                                                                    onChange={handleCustomerImageDocument}
                                                                    //beforeUpload={() => false}
                                                                    beforeUpload={(file) => beforeUpts(file, 2).then(() => false).catch(() => true)}
                                                                >
                                                                    {fileListCustomerDocument?.length >= 3 ? null : (
                                                                        <div>
                                                                            <PlusOutlined />
                                                                            <div className="ant-upload-text">upload</div>
                                                                        </div>
                                                                    )}
                                                                </Upload>
                                                                <Modal open={previewVisible} onCancel={handleCancelPreview}>
                                                                    <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                                </Modal>
                                                            </Form.Item>
                                                            </b>
                                                        </div>
                                                    </>
                                                    : null
                                            }
                                        </Space>
                                    </Row>
                                    <Divider />
                                    {
                                        checkSendNEW ?
                                            <>
                                                <Row justify={'left'}><b><u>หน้าคู่มือ</u> (ใหม่)</b></Row>
                                                <Row gutter={32} justify={'center'}>
                                                    <Space direction="vertical">
                                                        <Row gutter={32} justify={'center'}>
                                                            {imageBlobzz?.map((e, index) => {
                                                                var PreAaproveSplit = []
                                                                PreAaproveSplit = e.pathImage.split("/")
                                                                if (PreAaproveSplit[3] === "13") {
                                                                    if (e.type === 2) {
                                                                        return (
                                                                            <>
                                                                                <Image width={"50px"} key={index} src={`${getImagessPre}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                            </>
                                                                        );
                                                                    }
                                                                }
                                                                return null;
                                                            })}
                                                        </Row>
                                                    </Space>
                                                </Row>
                                                <Divider />
                                            </>
                                            : null
                                    }
                                    {/* {
                                        getImages?.length > 0 ?
                                            null
                                            :
                                            <>
                                                <Row justify={'left'}><b><u>ประวัติคนค้ำ / ทะเบียนบ้าน / อาชีพ</u></b></Row>
                                                <Row gutter={32} justify={'center'}>
                                                    <Space direction="vertical">
                                                        <Row gutter={32} justify={'center'}>
                                                            {imageBlobzz?.map((e, index) => {
                                                                if (e.type === 9) {
                                                                    return (
                                                                        <>
                                                                            <Image width={"50px"} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                        </>
                                                                    );
                                                                }
                                                                return null;
                                                            })}
                                                        </Row>
                                                    </Space>
                                                </Row>
                                                <Divider />
                                            </>
                                    } */}
                                    <Row justify={'left'}><b><u>ใบประเมิน / รูปรถ / สัญญาไฟแนนซ์เดิม / ใบเสร็จไฟแนนซ์เดิม</u></b></Row>
                                    <Row gutter={32} justify={'center'}>
                                        <Space direction="vertical">
                                            <Row gutter={32} justify={'center'}>
                                                {imageBlobzz?.map((e, index) => {
                                                    var PreAaproveSplit = []
                                                    PreAaproveSplit = e.pathImage.split("/")
                                                    if (PreAaproveSplit[3] !== "13") {
                                                        if (e.type === 1) {
                                                            return (
                                                                <>
                                                                    <Image width={"50px"} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                </>
                                                            );
                                                        }
                                                    }
                                                    return null;
                                                })}
                                            </Row>
                                            {
                                                getData?.cars?.carLoanDetails?.approvalStatus === 21 ?
                                                    <>
                                                        <div className='gutter-row' span={12}>
                                                            <b> <Form.Item label='เพิ่มรูป' name='imageIDGuCar'>
                                                                <Upload
                                                                    name='imageIDGuCar'
                                                                    multiple={true}
                                                                    listType="picture-card"
                                                                    fileList={fileListCustomerImageCar}
                                                                    onPreview={handlePreview}
                                                                    onChange={handleCustomerImageCar}
                                                                    //beforeUpload={() => false}
                                                                    beforeUpload={(file) => beforeUpts(file, 1).then(() => false).catch(() => true)}
                                                                >
                                                                    {fileListCustomerImageCar?.length >= 20 ? null : (
                                                                        <div>
                                                                            <PlusOutlined />
                                                                            <div className="ant-upload-text">upload</div>
                                                                        </div>
                                                                    )}
                                                                </Upload>
                                                                <Modal open={previewVisible} onCancel={handleCancelPreview}>
                                                                    <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                                </Modal>
                                                            </Form.Item>
                                                            </b>
                                                        </div>
                                                    </>
                                                    : null
                                            }
                                        </Space>
                                    </Row>
                                    <Divider />
                                    {
                                        checkSendNEW ?
                                            <>
                                                <Row justify={'left'}><b><u>ใบประเมิน / รูปรถ / สัญญาไฟแนนซ์เดิม / ใบเสร็จไฟแนนซ์เดิม</u> (ใหม่)</b></Row>
                                                <Row gutter={32} justify={'center'}>
                                                    <Space direction="vertical">
                                                        <Row gutter={32} justify={'center'}>
                                                            {imageBlobzz?.map((e, index) => {
                                                                var PreAaproveSplit = []
                                                                PreAaproveSplit = e.pathImage.split("/")
                                                                if (PreAaproveSplit[3] === "13") {
                                                                    if (e.type === 1) {
                                                                        return (
                                                                            <>
                                                                                <Image width={"50px"} key={index} src={`${getImagessPre}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                            </>
                                                                        );
                                                                    }
                                                                }
                                                                return null;
                                                            })}
                                                        </Row>
                                                    </Space>
                                                </Row>
                                                <Divider />
                                            </>
                                            : null
                                    }
                                    <Row justify={'left'}><b><u>รูปสเตทเมนต์ผู้กู้</u></b></Row>
                                    <Row gutter={32} justify={'center'}>
                                        <Space direction="vertical">
                                            <Row gutter={32} justify={'center'}>
                                                {imageBlobzz?.map((e, index) => {
                                                    var PreAaproveSplit = []
                                                    PreAaproveSplit = e.pathImage.split("/")
                                                    if (PreAaproveSplit[3] !== "13") {
                                                        if (e.type === 8) {
                                                            return (
                                                                <>
                                                                    <Image width={"50px"} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                </>
                                                            );
                                                        }
                                                    }
                                                    return null;
                                                })}
                                            </Row>
                                            {
                                                getData?.cars?.carLoanDetails?.approvalStatus === 21 ?
                                                    <>
                                                        <div className='gutter-row' span={12}>
                                                            <b> <Form.Item label='เพิ่มรูป' name='imageIDGuCar'
                                                            >
                                                                <Upload
                                                                    name='imageIDGuCar'
                                                                    multiple={true}
                                                                    listType="picture-card"
                                                                    fileList={fileListStatement}
                                                                    onPreview={handlePreview}
                                                                    onChange={handleStatement}
                                                                    //beforeUpload={() => false}
                                                                    beforeUpload={(file) => beforeUpts(file, 8).then(() => false).catch(() => true)}
                                                                >
                                                                    {fileListStatement?.length >= 15 ? null : (
                                                                        <div>
                                                                            <PlusOutlined />
                                                                            <div className="ant-upload-text">upload</div>
                                                                        </div>
                                                                    )}
                                                                </Upload>
                                                                <Modal open={previewVisible} onCancel={handleCancelPreview}>
                                                                    <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                                </Modal>
                                                            </Form.Item>
                                                            </b>
                                                        </div>
                                                    </>
                                                    : null
                                            }
                                        </Space>
                                    </Row>
                                    <Divider />
                                    {
                                        checkSendNEW ?
                                            <>
                                                <Row justify={'left'}><b><u>รูปสเตทเมนต์ผู้กู้</u> (ใหม่)</b></Row>
                                                <Row gutter={32} justify={'center'}>
                                                    <Space direction="vertical">
                                                        <Row gutter={32} justify={'center'}>
                                                            {imageBlobzz?.map((e, index) => {
                                                                var PreAaproveSplit = []
                                                                PreAaproveSplit = e.pathImage.split("/")
                                                                if (PreAaproveSplit[3] === "13") {
                                                                    if (e.type === 8) {
                                                                        return (
                                                                            <>
                                                                                <Image width={"50px"} key={index} src={`${getImagessPre}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                            </>
                                                                        );
                                                                    }
                                                                }
                                                                return null;
                                                            })}
                                                        </Row>
                                                    </Space>
                                                </Row>
                                                <Divider />
                                            </>
                                            : null
                                    }
                                    <Row justify={'left'}><b><u>รูปสเตทเมนต์ผู้กู้ PDF</u></b></Row>
                                    <Row gutter={32} justify={'center'}>
                                        <Space direction="vertical">
                                            <Row gutter={32} justify={'center'}>
                                                {imageBlobzz?.map((e, index) => {
                                                    var PreAaproveSplit = []
                                                    PreAaproveSplit = e.pathImage.split("/")
                                                    if (PreAaproveSplit[3] !== "13") {
                                                        if (e.type === 7) {
                                                            return (
                                                                <>
                                                                    <object data={`${getImagess}/${e.pathImage}?token=${token}`} type="application/pdf" width="350px" height="450px">
                                                                        <p>Alternative text - include a link <a href={`${getImagess}/${e.pathImage}?token=${token}`}>to the PDF!</a></p>
                                                                    </object>
                                                                </>
                                                            )
                                                        }
                                                    }
                                                    return null;
                                                })}
                                            </Row >
                                            {
                                                getData?.cars?.carLoanDetails?.approvalStatus === 21 ?
                                                    <>
                                                        <div className='gutter-row' span={12}>
                                                            <b> <Form.Item label='เพิ่มรูป' name='imageIDGuCar'
                                                            >
                                                                <Upload
                                                                    name='imageIDGuCar'
                                                                    multiple={true}
                                                                    listType="picture-card"
                                                                    fileList={fileListStatementPDF}
                                                                    onPreview={handlePreview}
                                                                    onChange={handleStatementPDF}
                                                                    //beforeUpload={() => false}
                                                                    beforeUpload={(file) => beforeUpts(file, 7).then(() => false).catch(() => true)}
                                                                >
                                                                    {fileListStatementPDF?.length >= 10 ? null : (
                                                                        <div>
                                                                            <PlusOutlined />
                                                                            <div className="ant-upload-text">upload</div>
                                                                        </div>
                                                                    )}
                                                                </Upload>
                                                                <Modal open={previewVisible} onCancel={handleCancelPreview}>
                                                                    <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                                </Modal>
                                                            </Form.Item>
                                                            </b>
                                                        </div>
                                                    </>
                                                    : null
                                            }
                                        </Space>
                                    </Row>
                                    <Divider />
                                    {
                                        checkSendNEW ?
                                            <>
                                                <Row justify={'left'}><b><u>รูปสเตทเมนต์ผู้กู้ PDF</u> (ใหม่)</b></Row>
                                                <Row gutter={32} justify={'center'}>
                                                    <Space direction="vertical">
                                                        <Row gutter={32} justify={'center'}>
                                                            {imageBlobzz?.map((e, index) => {
                                                                var PreAaproveSplit = []
                                                                PreAaproveSplit = e.pathImage.split("/")
                                                                if (PreAaproveSplit[3] === "13") {
                                                                    if (e.type === 7) {
                                                                        return (
                                                                            <>
                                                                                <object data={`${getImagessPre}/${e.pathImage}?token=${token}`} type="application/pdf" width="350px" height="450px">
                                                                                    <p>Alternative text - include a link <a href={`${getImagessPre}/${e.pathImage}?token=${token}`}>to the PDF!</a></p>
                                                                                </object>
                                                                            </>
                                                                        )
                                                                    }
                                                                }
                                                                return null;
                                                            })}
                                                        </Row >
                                                    </Space>
                                                </Row>
                                                <Divider />
                                            </>
                                            : null
                                    }
                                    <Row justify={'left'}><b><u>ประวัติผู้กู้ / ทะเบียนบ้าน / อาชีพ</u></b></Row>
                                    <Row gutter={32} justify={'center'}>
                                        <Space direction="vertical" >
                                            <Row gutter={32} justify={'center'}>
                                                {imageBlobzz?.map((e, index) => {
                                                    var PreAaproveSplit = []
                                                    PreAaproveSplit = e.pathImage.split("/")
                                                    if (PreAaproveSplit[3] !== "13") {
                                                        if (e.type === 6) {
                                                            return (
                                                                <>
                                                                    <Image width={"50px"} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                </>
                                                            );
                                                        }
                                                    }
                                                    return null;
                                                })}
                                            </Row>
                                            {
                                                getData?.cars?.carLoanDetails?.approvalStatus === 21 ?
                                                    <>
                                                        <div className='gutter-row' span={12}>
                                                            <b> <Form.Item label='เพิ่มรูป' name='imageIDGuCar'>

                                                                <Upload
                                                                    name='imageIDGuCar'
                                                                    multiple={true}
                                                                    listType="picture-card"
                                                                    fileList={fileListCustomerImageCareer}
                                                                    onPreview={handlePreview}
                                                                    onChange={handleCustomerImageCareer}
                                                                    //beforeUpload={() => false}
                                                                    beforeUpload={(file) => beforeUpts(file, 6).then(() => false).catch(() => true)}
                                                                >
                                                                    {fileListCustomerImageCareer?.length >= 25 ? null : (
                                                                        <div>
                                                                            <PlusOutlined />
                                                                            <div className="ant-upload-text">upload</div>
                                                                        </div>
                                                                    )}
                                                                </Upload>
                                                                <Modal open={previewVisible} onCancel={handleCancelPreview}>
                                                                    <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                                </Modal>
                                                            </Form.Item>
                                                            </b>
                                                        </div>
                                                    </>
                                                    : null
                                            }
                                        </Space>
                                    </Row>
                                    <Divider />
                                    {
                                        checkSendNEW ?
                                            <>
                                                <Row justify={'left'}><b><u>ประวัติผู้กู้ / ทะเบียนบ้าน / อาชีพ</u> (ใหม่)</b></Row>
                                                <Row gutter={32} justify={'center'}>
                                                    <Space direction="vertical" >
                                                        <Row gutter={32} justify={'center'}>
                                                            {imageBlobzz?.map((e, index) => {
                                                                var PreAaproveSplit = []
                                                                PreAaproveSplit = e.pathImage.split("/")
                                                                if (PreAaproveSplit[3] === "13") {
                                                                    if (e.type === 6) {
                                                                        return (
                                                                            <>
                                                                                <Image width={"50px"} key={index} src={`${getImagessPre}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                            </>
                                                                        );
                                                                    }
                                                                }
                                                                return null;
                                                            })}
                                                        </Row>
                                                    </Space>
                                                </Row>
                                                <Divider />
                                            </>
                                            : null
                                    }
                                    <Row justify={'left'}><b><u>เอกสารเพิ่มเติม</u></b></Row>
                                    <Row gutter={32} justify={'center'}>
                                        <Space direction="vertical">
                                            <Row gutter={32} justify={'center'}>
                                                {imageBlobzz?.map((e, index) => {
                                                    var PreAaproveSplit = []
                                                    PreAaproveSplit = e.pathImage.split("/")
                                                    if (PreAaproveSplit[3] !== "13") {
                                                        if (e.type === 12) {
                                                            return (
                                                                <>
                                                                    <Image width={"50px"} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                </>
                                                            );
                                                        }
                                                    }
                                                    return null;
                                                })}
                                            </Row>
                                        </Space>
                                    </Row>
                                    <Divider />
                                    {
                                        checkSendNEW ?
                                            <>
                                                <Row justify={'left'}><b><u>เอกสารเพิ่มเติม</u> (ใหม่)</b></Row>
                                                <Row gutter={32} justify={'center'}>
                                                    <Space direction="vertical">
                                                        <Row gutter={32} justify={'center'}>
                                                            {imageBlobzz?.map((e, index) => {
                                                                var PreAaproveSplit = []
                                                                PreAaproveSplit = e.pathImage.split("/")
                                                                if (PreAaproveSplit[3] === "13") {
                                                                    if (e.type === 12) {
                                                                        return (
                                                                            <>
                                                                                <Image width={"50px"} key={index} src={`${getImagessPre}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                            </>
                                                                        );
                                                                    }
                                                                }
                                                                return null;
                                                            })}
                                                        </Row>
                                                    </Space>
                                                </Row>
                                                <Divider />
                                            </>
                                            : null
                                    }
                                    <Col className='gutter-row' span={24} style={{ textAlign: 'right' }}>
                                        <Space>
                                            <Button type='primary' danger onClick={handleCancel}>
                                                ปิด
                                            </Button>
                                            {
                                                getData?.cars?.carLoanDetails?.approvalStatus === 21 ?
                                                    <>
                                                        <Button type='primary' danger onClick={showConfirmCancel}>
                                                            ผู้กู้ไม่ยอมรับ
                                                        </Button>
                                                        {/* <Button type='primary' danger onClick={handleTestUpload}>
                                                            test
                                                        </Button> */}
                                                        <Button type='primary' style={{ backgroundColor: 'green' }} onClick={showConfirmOK}>
                                                            ผู้กู้ยอมรับ
                                                        </Button>
                                                    </>
                                                    :
                                                    <>
                                                        <Button type='primary' icon={<SendOutlined />} onClick={showConfirm}>
                                                            ส่งเคสอีกครั้ง
                                                        </Button>
                                                    </>
                                            }
                                        </Space>
                                    </Col>
                                </Card>
                            </div>
                        </Form>
                    </Row>
                </Spin>
            </Row>
            {contextHolder}
            {
                checkOpenHIRE ?
                    <HirePurchase
                        open={checkOpenHIRE}
                        close={setCheckOpenHIRE}
                        cp={loanAmountt}
                        cp2={installmentCount}
                        checkGARFE={1}
                    />
                    : null
            }
            {
                checkOpen ?
                    <Installment
                        open={checkOpen}
                        close={setCheckOpen}
                        cp={loanAmountt}
                        cp2={installmentCount}
                        checkGARFE={1}
                    />
                    : null
            }
        </>
    )
}

export default DescriptionAll