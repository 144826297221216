import React, { useState, useEffect } from 'react'
import { Form, Modal, Row, Col, Image, Space, Card, Button, Tag, Divider, Spin, Checkbox } from 'antd'
import dayjs from 'dayjs';
import axios from "axios";
import { irr } from 'node-irr'

import { addCustomer } from '../../../redux/Customer';
import { addCar } from '../../../redux/Car';
import { addAddress } from '../../../redux/Address';
import { addPhone } from '../../../redux/Phone';
import { addGuarantor } from '../../../redux/Guarantor';
import { useSelector, useDispatch } from 'react-redux';
import { FIFTEENPERCENT, TWENTYFOURPERCENT } from '../../offer_case/ploan/InstallmentTable'
// import { Installment } from '../../offer_case/ploan/Installment';
// import { HirePurchase } from '../../offer_case/hire_purchase/HirePurchase';
import Currency from 'currency.js';

import Confirm from './Confirm';
import { checkgu, findcarall, findlandall, loadOJS, getImagess } from '../../file_mid/all_api';

export default function RockingWork({ open, dataFromTable, close }) {
    //console.log("dataFromTable =", dataFromTable)
    const [loading, setLoading] = useState(false);
    const [confirms, setConfirms] = useState(false);

    const { confirm } = Modal;
    const customers = useSelector(state => state.customers)
    // const { guarantor } = useSelector((state) => ({ ...state }))
    const guarantor = useSelector(state => state.guarantor)
    const token = localStorage.getItem('token');
    const [imageBlobzz, setImageBlobzz] = useState([]);
    const [dataModalCars, setDataModalCars] = useState({});
    const [dataCareer, setDataCareer] = useState([]);
    const [dataModalLand, setDataModalLand] = useState({});
    const [form] = Form.useForm()
    const dispatch = useDispatch()

    const [guarantor1, setGuarantor1] = useState([])
    const [address, setAddresss] = useState([])
    const [dataPhoneCus, setDataPhoneCus] = useState([]);
    const [dataEmail, setDataEmail] = useState([]);
    const [dataSocials, setDataSocials] = useState([]);
    const [notes, setNotes] = useState([])

    // const [types, setTypes] = useState([])
    const [getData, setGetData] = useState();
    const [checkApprove, setCheckApprove] = useState();

    const [resetpage, setResetpage] = useState({});
    // คำนวนรถ
    const [dataSource, setDataSource] = useState([])
    const [resultIrr, setResultIrr] = useState(0.0)
    const [loanAmountt, setLoanAmountt] = useState(); //ยอดที่อนุมัติ
    const [installmentCount, setInstallmentCount] = useState() //จำนวนงวดที่อนุมัติ
    // p-loan
    const [arr, setArr] = useState() //งวดละ p-loan
    const [interestt, setInterestt] = useState(0.0)  //ดอกเบี้ย p-loan
    const [installmentWithInterestt, setInstallmentWithInterestt] = useState(0.0) //ผ่อนรวมดอกเบี้ย
    // เช่าซื้อ
    const [resultRateCarDATA, setresultRateCarDATA] = useState({}) // งวดละใหม่ * งวด - ยอดจัด (เช่าซื้อ)
    const [newLoanTermCarDATA, setnewLoanTermCarDATA] = useState({}) // งวดละใหม่ = ( งวดละ * 0.07 ) + งวดละ  (เช่าซื้อ)
    const [newResultPriceCarDATA, setnewResultPriceCarDATA] = useState({}) // งวดละใหม่ * งวด(เช่าซื้อ)

    // คำนวนที่ดิน
    const [loanTermDATA, setloanTermDATA] = useState({})
    const [resultRateDATA, setresultRateDATA] = useState({})
    const [resultPriceDATA, setresultPriceDATA] = useState({})
    const [checkOpen, setCheckOpen] = useState(false)
    const [checkOpenHIRE, setCheckOpenHIRE] = useState(false)

    var note = notes.length;
    var lastIndex = note - 1;

    const [career, setCareer] = useState([]) // เก็บข้อมูลอาชีพ

    // console.log('dataModalLand',dataModalLand)
    // console.log('dataModalLCars',dataModalCars)

    useEffect(() => {
        console.log("RockingWork")
        loadData().then(async () => {
            await loadGuarantors()
            await loadCareer()
        })
    }, [])

    // ใช้ในการคำนวน
    useEffect(() => {
        // console.log("useEffect", getData)
        if (guarantor?.data?.typeLoan === "car") {
            if (getData?.carLoanDetails?.productType === "p-loan") {
                // console.log("1 = รถเล็ก p-loan")
                handleCalculate()
            } else if (getData?.carLoanDetails?.productType === "เช่าซื้อ") {
                if (getData?.carLoanDetails?.cars?.productTypeCar === 1) {
                    // console.log("2 = รถเล็ก เช่าซื้อ")
                    handleCalculateHIRE()
                    // car_Calculate()
                } else {
                    // console.log("3 = รถใหญ่ เช่าซื้อ")
                    car_Calculate()
                }
            } else if (getData?.carLoanDetails?.productType === "เช่าซื้อ1") {
                car_Calculate()
            }
        } else if (guarantor?.data?.typeLoan === "land") {
            land_Calculate()
        }
    }, [getData])

    console.log("GGEZ")
    //console.log("dataModalCars", career)

    const loadCareer = async () => {
        const id = { customerId: customers.data }
        setLoading(true)
        await axios.post(loadOJS, id)
            .then((res) => {
                //console.log("res", res.data)
                setCareer(res.data)
                setLoading(false)
            })
            .catch((err) => console.log(err))
        setLoading(false)
    }

    const loadGuarantors = async (value) => { //คนค้ำ
        setLoading(true)
        if (guarantor.data.typeLoan === "car") {
            await axios.post(checkgu, { typeLoan: guarantor.data.typeLoan, loanId: guarantor.data.loanId })
                .then(res => {
                    if (res.status === 200) {
                        // console.log("loadGuarantors", res.data)
                        setGuarantor1(res.data)
                        setLoading(false)
                    } else if (res.status === 204) {
                        // console.log("ไม่พบข้อมูล")
                        setLoading(false)
                    }
                }).catch((err) => {
                    console.log("err", err)
                })
        } else if (guarantor.data.typeLoan === "land") {
            await axios.post(checkgu, { typeLoan: guarantor.data.typeLoan, loanId: guarantor.data.loanId })
                .then(res => {
                    if (res.status === 200) {
                        // console.log("loadGuarantors", res.data)
                        setGuarantor1(res.data)
                        setLoading(false)
                    } else if (res.status === 204) {
                        // console.log("ไม่พบข้อมูล")
                        setLoading(false)
                    }
                }).catch((err) => {
                    console.log("err", err)
                })
        }

        setLoading(false)
    }

    const loadData = async () => {
        setLoading(true)
        if (guarantor.data.typeLoan === "car") {
            await axios.post(findcarall, { carId: dataFromTable.carId, customerId: dataFromTable.customerId })
                .then(res => {
                    if (res.status === 200) {
                        // console.log("CarCar", res.data)
                        // console.log("dataCareer", dataCareer)
                        if (res.data.ojs !== undefined || res.data.ojs?.length > 0) {
                            var countIndex = 0
                            res.data.ojs.map((e, index) => {
                                if (e.mainOccupation === true) {
                                    countIndex = index
                                    setDataCareer(res.data.ojs[countIndex])
                                }
                            })
                        } else {
                            setDataCareer()
                        }
                        setLoanAmountt(parseInt(res.data.carLoanDetails.approvedLoanAmount))
                        setInstallmentCount({ installmentAmountt: parseInt(res.data.carLoanDetails.approvedLoanTerm) })
                        setNotes(res.data.carLoanDetails.notes)
                        setDataModalCars(res.data)
                        // setDataCareer(...res.data.ojs)
                        setAddresss(res.data.address)
                        setDataPhoneCus(res.data.phones)
                        setDataEmail(res.data.emails)
                        setDataSocials(res.data.socials)
                        setGetData(res.data)
                        setCheckApprove(res.data.carLoanDetails.approvalStatus)
                        setImageBlobzz(res.data.carLoanDetails.cars.img)
                        setLoading(false)
                    }
                }).catch((err) => {
                    console.log("err", err)
                })
        } else {
            await axios.post(findlandall, { carId: dataFromTable.carId, customerId: dataFromTable.customerId })
                .then(res => {
                    if (res.status === 200) {
                        // console.log("LandLand", res.data)
                        if (res.data.ojs !== undefined || res.data.ojs?.length > 0) {
                            var countIndex = 0
                            res.data.ojs.map((e, index) => {
                                if (e.mainOccupation === true) {
                                    countIndex = index
                                    setDataCareer(res.data.ojs[countIndex])
                                }
                            })
                        } else {
                            setDataCareer()
                        }
                        setNotes(res.data.landLoanDetails.notes)
                        setDataModalLand(res.data)
                        // setDataCareer(...res.data.ojs)
                        setAddresss(res.data.address)
                        setDataPhoneCus(res.data.phones)
                        setDataEmail(res.data.emails)
                        setDataSocials(res.data.socials)
                        setGetData(res.data)
                        setCheckApprove(res.data.landLoanDetails.approvalStatus)
                        setImageBlobzz(res.data.landLoanDetails.land.img)
                        setLoading(false)
                    }
                }).catch((err) => {
                    console.log("err", err)
                })
        }
        setLoading(false)
    }

    const handleWork = () => {
        setConfirms(true)
    }

    const openTable = () => {
        setCheckOpen(true)
    };
    const openTable2 = () => {
        setCheckOpenHIRE(true)
    };

    const handleCalculate = () => {
        setDataSource([])
        //console.log("Calculate")
        if (loanAmountt && installmentCount.installmentAmountt) {
            let loanAmount = 0.0
            let interest = 0.0

            TWENTYFOURPERCENT.map((value) => {
                if (value.installment === ~~installmentCount.installmentAmountt) {
                    loanAmount = value.interest * 0.7
                }
            })

            FIFTEENPERCENT.map((value) => {
                if (value.installment === ~~installmentCount.installmentAmountt) {
                    interest = value.interest * 0.3
                }
            })

            let installmentAmount = Math.round((interest + loanAmount) * loanAmountt)

            // IRR Calculate
            var array = []
            array.push(-loanAmountt)
            for (var i = 0; i < installmentCount.installmentAmountt; i++) {
                array.push(installmentAmount)
            }

            let IRR = irr(array) * 100

            //console.log("IRR",IRR)

            if (IRR) {
                var TotalPrincipleBalance = loanAmountt //ยอดทั้งหมด
                var totalInterest = 0.0 // ดอกเบี้ย
                //var result = ~~loanAmountt
                var result = loanAmountt
                for (var j = 1; j <= installmentCount.installmentAmountt; j++) {     // installmentCount จำนวนงวด
                    let principle = TotalPrincipleBalance     // เงินต้น = ยอดทั้งหมด
                    let no = j   // งวดที่ 
                    let interestAmount = Math.round((TotalPrincipleBalance * IRR) / 100) // จำนวนดอกเบี้ย = 
                    let principleAmount = installmentAmount - interestAmount    //  จำนวนงวด - จำนวนดอกเบี้ย
                    let principleBalance = principle - principleAmount          //  ดอกเบี้ยคงเหลือ = 

                    totalInterest += interestAmount // totalInterest = totalInterest + interestAmount

                    let interestBalance = totalInterest // ดอกเบี้ยคงเหลือ = ยอดรวมดอกเบี้ย

                    setDataSource((pre) => [
                        ...pre,
                        {
                            installmentNo: no,                          // งวดที่ 
                            installmentAmount: installmentAmount,       // จำนวนงวด
                            interestAmount: interestAmount,             // จำนวนดอกเบี้ย
                            principleAmount: principleAmount,           // เงินต้น
                            principleBalance: principleBalance,         // เงินต้นคงเหลือ
                            interestBalance: interestBalance,           // ดอกเบี้ยคงเหลือ
                        },

                    ])
                    // console.log("installmentAmount", installmentAmount)
                    // console.log("interestAmount", interestAmount)
                    // console.log("principleAmount", principleAmount)
                    // console.log("principleBalance", principleBalance)
                    // console.log("interestBalance", interestBalance)

                    // TotalPrincipleBalance = TotalPrincipleBalance - principleAmount, ยอดทั้งหมด
                    TotalPrincipleBalance -= principleAmount
                }
                // result += totalInterest
                result = installmentAmount * installmentCount.installmentAmountt
                var interestNEW = result - loanAmountt
                setResultIrr(Currency(IRR, { precision: 5 }).value)
                setArr(installmentAmount) //งวดละ
                setInterestt(interestNEW) //ดอกเบี้ย
                // setInterestt(totalInterest) //ดอกเบี้ย
                setInstallmentWithInterestt(result) //ผ่อนรวมดอกเบี้ย
                // const dataM = { ...installmentCount, loanAmountt, interestt, installmentWithInterestt, ...odata, monthlyPayment: arr, interestRate: resultIrr }
                // console.log("dataM", dataM)
            }
        }
    }

    //console.log("dataModalCars", dataModalCars)

    const car_Calculate = () => {

        // var interestRateCar = parseFloat(dataModalCars?.carLoanDetails?.interestRate) / 100 // อัตราดอก / 100
        // var rateCar = ((parseFloat(dataModalCars?.carLoanDetails?.approvedLoanAmount) * Currency(interestRateCar, { precision: 5 }).value) * dataModalCars?.carLoanDetails?.approvedLoanTerm) // (ยอดจัด * อัตราดอกที่หาร100แล้ว ,precision: 3 คือทศนิยม 3 )*งวด
        // var loanTermCar = Math.ceil(Currency((rateCar + parseFloat(dataModalCars?.carLoanDetails?.approvedLoanAmount)) / parseInt(dataModalCars?.carLoanDetails?.approvedLoanTerm), { precision: 2 }).value) // (ดอกเบี้ยทั้งหมด + ยอดจัด)/ งวด
        // var newLoanTermCar = Math.ceil(Currency(loanTermCar * 0.07)) + loanTermCar // งวดละใหม่ = ( งวดละ * 0.07 ) + งวดละ
        // var resultRateCar = (newLoanTermCar * parseInt(dataModalCars?.carLoanDetails?.approvedLoanTerm)) - parseFloat(dataModalCars?.carLoanDetails?.approvedLoanAmount) // งวดละใหม่ * งวด - ยอดจัด
        // var newResultPriceCar = newLoanTermCar * parseInt(dataModalCars?.carLoanDetails?.approvedLoanTerm) // งวดละใหม่ * งวด
        var interestRateCar = parseFloat(dataModalCars?.carLoanDetails?.interestRate) / 100 // อัตราดอก / 100
        var rateCar = ((parseFloat(dataModalCars?.carLoanDetails?.approvedLoanAmount) * Currency(interestRateCar, { precision: 5 }).value) * dataModalCars?.carLoanDetails?.approvedLoanTerm) // (ยอดจัด * อัตราดอกที่หาร100แล้ว ,precision: 3 คือทศนิยม 3 )*งวด
        var loanTermCar = (rateCar + parseFloat(dataModalCars?.carLoanDetails?.approvedLoanAmount)) / parseInt(dataModalCars?.carLoanDetails?.approvedLoanTerm) // (ดอกเบี้ยทั้งหมด + ยอดจัด)/ งวด
        var newLoanTermCar = Math.ceil(Currency((loanTermCar * 0.07) + loanTermCar), { precision: 5 })// งวดละใหม่ = ( งวดละ * 0.07 ) + งวดละ
        var resultRateCar = Math.ceil(Currency(newLoanTermCar, { precision: 5 }) * parseInt(dataModalCars?.carLoanDetails?.approvedLoanTerm)) - parseFloat(dataModalCars?.carLoanDetails?.approvedLoanAmount) // งวดละใหม่ * งวด - ยอดจัด
        var newResultPriceCar = Math.ceil(Currency(newLoanTermCar) * parseInt(dataModalCars?.carLoanDetails?.approvedLoanTerm), { precision: 5 }) // งวดละใหม่ * งวด



        setresultRateCarDATA(resultRateCar) // งวดละใหม่ * งวด - ยอดจัด
        setnewLoanTermCarDATA(newLoanTermCar)  // งวดละใหม่ = ( งวดละ * 0.07 ) + งวดละ
        setnewResultPriceCarDATA(newResultPriceCar)  // งวดละใหม่ * งวด
    }

    const land_Calculate = () => {
        var interestRateLand = parseFloat(dataModalLand?.landLoanDetails?.interestRateLand) / 100 // อัตราดอก / 100
        var rate = (parseFloat(dataModalLand?.landLoanDetails?.approvedLoanAmount) * Currency(interestRateLand, { precision: 3 }).value) * parseInt(dataModalLand?.landLoanDetails?.approvedLoanTerm) // (ยอดจัด * อัตราดอกที่หาร100แล้ว ,precision: 3 คือทศนิยม 3 )*งวด
        var loanTerm = Math.ceil(Currency((rate + parseFloat(dataModalLand?.landLoanDetails?.approvedLoanAmount)) / parseInt(dataModalLand?.landLoanDetails?.approvedLoanTerm), { precision: 2 }).value) // (ดอกเบี้ยทั้งหมด + ยอดจัด)/ งวด
        var resultRate = (loanTerm * parseInt(dataModalLand?.landLoanDetails?.approvedLoanTerm)) - parseInt(dataModalLand?.landLoanDetails?.approvedLoanAmount)
        var resultPrice = loanTerm * parseInt(dataModalLand?.landLoanDetails?.approvedLoanTerm)

        // console.log("land_Calculate interestRateLand", interestRateLand)
        // console.log("land_Calculate rate", rate)
        // console.log("land_Calculate loanTerm", loanTerm)
        // console.log("land_Calculate resultRate", resultRate)
        // console.log("land_Calculate resultPrice", resultPrice)

        setloanTermDATA(loanTerm)
        setresultRateDATA(resultRate)
        setresultPriceDATA(resultPrice)
    }

    const handleCalculateHIRE = () => {
        setDataSource([])
        // console.log("handleCalculateHIRE")

        if (loanAmountt && installmentCount.installmentAmountt) {
            const amountWithVat = loanAmountt * 1.07
            let installment = 0.0

            FIFTEENPERCENT.map((value) => {
                if (value.installment === ~~installmentCount.installmentAmountt) {
                    installment = value.interest * amountWithVat
                }
            })

            let installmentAmount = Math.round(installment)

            // IRR Calculate
            var array = []
            array.push(-amountWithVat)
            for (var i = 0; i < installmentCount.installmentAmountt; i++) {
                array.push(installmentAmount)
            }

            let IRR = irr(array) * 100

            if (IRR) {
                var TotalPrincipleBalance = amountWithVat //ยอดทั้งหมด
                var totalInterest = 0.0 // ดอกเบี้ย
                //var result = ~~loanAmountt
                var result = amountWithVat
                for (var j = 1; j <= installmentCount.installmentAmountt; j++) {     // installmentCount จำนวนงวด
                    let principle = TotalPrincipleBalance     // เงินต้น = ยอดทั้งหมด
                    let no = j   // งวดที่ 
                    let interestAmount = Math.round((TotalPrincipleBalance * IRR) / 100) // จำนวนดอกเบี้ย = 
                    let principleAmount = installmentAmount - interestAmount    //  จำนวนงวด - จำนวนดอกเบี้ย
                    let principleBalance = principle - principleAmount          //  ดอกเบี้ยคงเหลือ = 

                    totalInterest += interestAmount // totalInterest = totalInterest + interestAmount

                    let interestBalance = totalInterest // ดอกเบี้ยคงเหลือ = ยอดรวมดอกเบี้ย

                    setDataSource((pre) => [
                        ...pre,
                        {
                            installmentNo: no,                          // งวดที่ 
                            installmentAmount: installmentAmount,       // จำนวนงวด
                            interestAmount: interestAmount,             // จำนวนดอกเบี้ย
                            principleAmount: principleAmount,           // เงินต้น
                            principleBalance: principleBalance,         // เงินต้นคงเหลือ
                            interestBalance: interestBalance,           // ดอกเบี้ยคงเหลือ
                        },
                    ])
                    // TotalPrincipleBalance = TotalPrincipleBalance - principleAmount, ยอดทั้งหมด
                    TotalPrincipleBalance -= principleAmount
                }
                //result += totalInterest
                result = installmentAmount * installmentCount.installmentAmountt
                var interestNEW = result - loanAmountt
                setResultIrr(Currency(IRR, { precision: 5 }).value)
                setArr(installmentAmount)
                setInterestt(interestNEW)
                // setInterestt(totalInterest) //ดอกเบี้ย
                setInstallmentWithInterestt(result)
                // console.log("installmentAmount", installmentAmount)
                // console.log("totalInterest", totalInterest)
                // console.log("result", result)
            }
        }
    }

    const handleOk = async () => {
        console.log('ok', dataModalCars)
    };

    const handleCancel = () => {
        dispatch(addCustomer())
        dispatch(addCar())
        dispatch(addAddress())
        dispatch(addPhone())
        dispatch(addGuarantor())
        close(false);
    };

    const currencyFormatOne = (amount) => {
        return Number(amount)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, '$&,')
    }


    let color

    if (dataModalCars?.carLoanDetails?.approvalStatus === 16 || dataModalLand?.landLoanDetails?.approvalStatus === 16) {
        color = "gold"
    }
    if (dataModalCars?.carLoanDetails?.approvalStatus === 17 || dataModalLand?.landLoanDetails?.approvalStatus === 17) {
        color = "orange"
    }
    if (dataModalCars?.carLoanDetails?.approvalStatus === 18 || dataModalLand?.landLoanDetails?.approvalStatus === 18) {
        color = "cyan"
    }
    if (dataModalCars?.carLoanDetails?.approvalStatus === 19 || dataModalLand?.landLoanDetails?.approvalStatus === 19) {
        color = "geekblue"
    }
    if (dataModalCars?.carLoanDetails?.approvalStatus === 2 || dataModalLand?.landLoanDetails?.approvalStatus === 2) {
        color = "lime"
    }
    if (dataModalCars?.carLoanDetails?.approvalStatus === 26 || dataModalLand?.landLoanDetails?.approvalStatus === 26) {
        color = "lime"
    }
    if (dataModalCars?.carLoanDetails?.approvalStatus === 3 || dataModalLand?.landLoanDetails?.approvalStatus === 3) {
        color = "green"
    }
    if (dataModalCars?.carLoanDetails?.approvalStatus === 27 || dataModalLand?.landLoanDetails?.approvalStatus === 27) {
        color = "green"
    }
    if (dataModalCars?.carLoanDetails?.approvalStatus === 22 || dataModalLand?.landLoanDetails?.approvalStatus === 22) {
        color = "red"
    }
    if (dataModalCars?.carLoanDetails?.approvalStatus === 25 || dataModalLand?.landLoanDetails?.approvalStatus === 25) {
        color = "red"
    }
    if (dataModalCars?.carLoanDetails?.approvalStatus === 20 || dataModalLand?.landLoanDetails?.approvalStatus === 20) {
        color = "red"
    }

    const renderGuarantor = ({ item, index }) => { //item คือตัวแปรที่เอาไว้ดึงค่าของข้อมูลออกมาแสดง โดยใช้ item.ชื่อฟิวล์
        // console.log("Item", item, index)
        return (
            <>
                <div><b><u>ลำดับที่ {index + 1}</u></b></div>
                <aside style={{ width: '90%' }}>
                    <div style={{ marginBottom: 0 }}>
                        <span>ชื่อ-นามสกุล : </span><b>{item?.snam + ' ' + item?.firstname + ' ' + item?.lastname}</b>
                    </div>

                    <div style={{ marginBottom: 0 }}>
                        <span>ความสัมพันธ์ : </span> <b>{item?.description}</b>
                    </div>
                    {item?.phones?.length > 0 ?
                        <>
                            <div style={{ marginBottom: 0 }}>
                                <span>เบอร์โทรติดต่อ : </span> <b>{item?.phones[0]?.telp}</b>
                            </div>
                        </>
                        : <div style={{ marginBottom: 0 }}>
                            <span>เบอร์โทรติดต่อ : </span> <b>ไม่มี</b>
                        </div>
                    }

                    {item?.address?.length > 0 ?
                        <>
                            <div style={{ marginBottom: 0 }}>
                                <span > ที่อยู่ :  </span>
                                <b>
                                    {
                                        item.address[0]?.houseNumber + ' ' +
                                        "หมู่ " + item.address[0]?.soi + ' ' +
                                        "ถ." + item.address[0]?.road + ' ' +
                                        "ต." + item.address[0]?.subdistrict + ' ' +
                                        "อ." + item.address[0]?.district + ' ' +
                                        "จ." + item.address[0]?.province + ' '
                                        + item.address[0]?.zipCode
                                    }
                                </b>
                            </div>
                        </>
                        : null
                    }
                </aside>
                <Divider style={{ margin: 5 }} />
            </>
        )
    }
    const renderItemAddress = ({ item, index }) => { //item คือตัวแปรที่เอาไว้ดึงค่าของข้อมูลออกมาแสดง โดยใช้ item.ชื่อฟิวล์
        return (
            <>
                <div><b><u>ลำดับที่ {index + 1}</u></b></div>
                <aside style={{ width: '90%' }}>
                    <div
                        style={{ marginBottom: 0 }}
                    >
                        <span>เลขทะเบียนบ้าน : </span> <b>{item.houseRegistrationNumber}</b>
                    </div>
                    <div
                        style={{ marginBottom: 0 }}
                    >
                        <span>ถนน : </span><b>{item.road}</b>
                    </div>
                    <div
                        style={{ marginBottom: 0 }}
                    >
                        <span>ตำบล : </span> <b>{item.subdistrict}</b>
                    </div>


                    <div
                        style={{ marginBottom: 0 }}
                    >
                        <span>บ้านเลขที่ : </span> <b>{item.houseNumber}</b>
                    </div>
                    <div
                        style={{ marginBottom: 0 }}
                    >
                        <span>หมู่บ้าน : </span> <b>{item.village}</b>
                    </div>
                    <div
                        style={{ marginBottom: 0 }}
                    >
                        <span>อำเภอ : </span> <b>{item.district}</b>
                    </div>

                    <div
                        style={{ marginBottom: 0 }}
                    >
                        <span>หมู่ / ซอย : </span> <b>{item.soi}</b>
                    </div>

                    <div
                        style={{ marginBottom: 0 }}
                    >
                        <span>จังหวัด : </span> <b>{item.province}</b>
                    </div>
                    <div
                        style={{ marginBottom: 0 }}
                    >
                        <span>รหัสไปรษณีย์ : </span> <b>{item.zipCode}</b>
                    </div>

                </aside>
                {address.length >= 1 ? <Divider /> : null}
            </>
        )
    }
    const renderItemPhones = ({ item, index }) => { //item คือตัวแปรที่เอาไว้ดึงค่าของข้อมูลออกมาแสดง โดยใช้ item.ชื่อฟิวล์
        return (
            <>
                <div
                    style={{ marginBottom: 0 }}
                >
                    <span>{item.phoneType ? <>{item.phoneType} : </> : null}</span>
                    <b> {item.telp}</b>
                </div>
            </>
        )
    }
    const renderDataNote = ({ note, length1 }) => {
        return (
            <>
                <Row gutter={32} justify={'center'}>
                    <Col className='gutter-row' span={24}>
                        {guarantor?.data?.typeLoan === "car" ?
                            <>
                                <Card
                                    key={length1}
                                    style={{ width: '270px', textAlign: 'left' }}>
                                    <>
                                        <div style={{ marginBottom: 0 }}>
                                            <span>สาเหตุ : </span>
                                            <b>{note[length1].cause}</b>
                                        </div>
                                        <div style={{ marginBottom: 0 }}>
                                            <span>หมายเหตุ : </span>
                                            <b>{note[length1].note}</b>
                                        </div>
                                        <div style={{ marginBottom: 0 }}>
                                            <span>ผู้สร้างหมายเหตุ : </span>
                                            <b>{note[length1].noteBy}</b>
                                        </div>
                                        <div style={{ marginBottom: 0 }}>
                                            <span>สถานะที่ทำการหมายเหตุ : </span>
                                            <b>{note[length1].status}</b>
                                        </div>
                                    </>
                                </Card>
                            </>
                            :
                            <>
                                <Card
                                    key={length1}
                                    style={{ width: '270px', textAlign: 'left' }}>
                                    <>
                                        <div style={{ marginBottom: 0 }}>
                                            <span>สาเหตุ : </span>
                                            <b> {note[length1].cause}</b>
                                        </div>
                                        <div style={{ marginBottom: 0 }}>
                                            <span>หมายเหตุ : </span>
                                            <b> {note[length1].note}</b>
                                        </div>
                                        <div style={{ marginBottom: 0 }}>
                                            <span>ผู้สร้างหมายเหตุ : </span>
                                            <b>{note[length1].noteBy}</b>
                                        </div>
                                        <div style={{ marginBottom: 0 }}>
                                            <span>สถานะที่ทำการหมายเหตุ : </span>
                                            <b>{note[length1].status}</b>
                                        </div>
                                    </>
                                </Card>
                            </>
                        }
                    </Col>
                </Row>
            </>
        )
    }

    return (
        <div>
            <Modal title="ตรวจสอบข้อมูล" open={open} onOk={handleOk} onCancel={handleCancel} width={'1100px'}
                footer={[
                    <Row justify={'center'}>
                        <Button key="back" type='primary' danger onClick={handleCancel}>
                            ปิด
                        </Button>
                    </Row>
                ]} >
                <Spin spinning={loading} size='large' tip=" Loading... ">
                    <Row justify={'center'}>
                        <Col className='gutter-row' span={24} style={{ fontSize: '25px', textAlign: 'center' }}>
                            <b>รายละเอียด</b>
                        </Col>
                    </Row>
                    <Row justify={'center'}>
                        <Divider style={{ margin: 5 }} />
                        <Form
                            labelCol={{
                                span: 12,
                            }}
                            wrapperCol={{
                                span: 24,
                            }}
                            style={{
                                width: '90%',
                            }}
                            form={form}
                            // onFinish={handleSubmit}
                            autoComplete="off"
                            initialValues={{ remember: true }}
                        >
                            <div className='main'>
                                <Card style={{ width: '100%' }}>
                                    <Row justify={'center'}>
                                        <Col span={24} style={{ textAlign: 'right' }}>
                                            {guarantor.data.typeLoan === "car" ?
                                                <>
                                                    <div style={{ margin: 0 }}>
                                                        สถานะ : <Tag color={color}>{dataModalCars?.carLoanDetails?.approvalStatusTable?.name}</Tag>
                                                    </div>
                                                    <div style={{ margin: 0 }}>
                                                        วันที่ถูกเปลี่ยนสถานะ : {dayjs(dataModalCars?.carLoanDetails?.cars?.carDateStatus).format("DD-MM-YYYY")}
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <div style={{ margin: 0 }}>
                                                        สถานะ : <Tag color={color}>{dataModalLand?.landLoanDetails?.approvalStatusTable?.name}</Tag>
                                                    </div>
                                                    <div style={{ margin: 0 }}>
                                                        วันที่ถูกเปลี่ยนสถานะ : {dayjs(dataModalLand?.landLoanDetails?.land?.LandDateStatus).format("DD-MM-YYYY")}
                                                    </div>
                                                </>
                                            }
                                        </Col>
                                    </Row>
                                    <Divider />
                                    {dataModalCars?.nationalId === "นิติบุคคล" ?
                                        <>
                                            <Row justify={'left'}><b><u>รายละเอียดผู้ขอกู้</u></b></Row>
                                            <Row gutter={32} justify={'center'}>
                                                <aside style={{ width: '90%' }}>
                                                    <div label='ประเภท' name='identificationId' style={{ marginBottom: 3 }}>
                                                        <span>ประเภท : </span> <b>{dataModalCars?.nationalId}</b>
                                                    </div>
                                                    <div label='เลขประจำตัวผู้เสียภาษี' name='identificationId' style={{ marginBottom: 3 }}>
                                                        <span>เลขประจำตัวผู้เสียภาษี : </span> <b>{dataModalCars.identificationId}</b>
                                                    </div>
                                                    <div label='ชื่อบริษัท' name='snam' style={{ marginBottom: 3 }}>
                                                        <span>
                                                            {dataModalCars?.snam} : </span> <b>{dataModalCars?.firstname + ' ' + dataModalCars?.lastname}</b>
                                                    </div>
                                                    <div label='วันจดทะเบียน' name='nickname' style={{ marginBottom: 3 }}>
                                                        <span>วันจดทะเบียน : </span> <b> {dataModalCars?.birthdate}</b>
                                                    </div>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <b>

                                                            {
                                                                dataPhoneCus?.map((item, index) => {
                                                                    return renderItemPhones({ item, index, key: `{item.identification} - ${index}` })
                                                                })
                                                            }
                                                        </b>
                                                    </div>
                                                </aside>
                                            </Row>
                                        </>
                                        :
                                        <>
                                            <Row justify={'left'}><b><u>รายละเอียดผู้ขอกู้</u></b></Row>
                                            <Row gutter={32} justify={'center'}>
                                                {guarantor.data.typeLoan === "car" ?
                                                    <>
                                                        <aside style={{ width: '90%' }}>
                                                            <div
                                                                style={{ marginBottom: 0, }}
                                                            >
                                                                <span>ชื่อ - นามสกุล : </span>
                                                                <b>{dataModalCars?.snam + '   ' + dataModalCars?.firstname + '   ' + dataModalCars?.lastname}</b>
                                                            </div>
                                                            <div
                                                                style={{ marginBottom: 0, }}
                                                            >
                                                                <span>ชื่อเล่น : </span>
                                                                <b>{dataModalCars?.nickname}</b>
                                                            </div>

                                                            <div
                                                                style={{ marginBottom: 0, }}
                                                            >
                                                                <span>เลขบัตรประชาชน : </span>
                                                                <b>{dataModalCars?.identificationId}</b>
                                                            </div>
                                                            <div
                                                                style={{ marginBottom: 0, }}
                                                            >
                                                                <b>
                                                                    {
                                                                        dataPhoneCus?.map((item, index) => {
                                                                            return renderItemPhones({ item, index })
                                                                        })
                                                                    }
                                                                </b>
                                                            </div>
                                                            <div
                                                                style={{ margin: 0, }}
                                                            >
                                                                <span>เพศ : </span>
                                                                <b>{dataModalCars?.gender}</b>
                                                            </div>
                                                            <div
                                                                style={{ marginBottom: 0, }}
                                                            >
                                                                <span>{dataEmail === "" ? <> </> : <>{dataEmail?.emailData}</>}</span>
                                                                <b>{dataEmail?.emailData}</b>
                                                            </div>
                                                            <div
                                                                style={{ marginBottom: 0, }}
                                                            >
                                                                <span>{dataSocials?.socialType}  </span>
                                                                <b>{dataSocials?.socialData}</b>
                                                            </div>
                                                        </aside>
                                                    </>
                                                    :
                                                    <>
                                                        <aside style={{ width: '90%' }}>
                                                            <div
                                                                style={{ marginBottom: 0, }}
                                                            >
                                                                <span>ชื่อ - นามสกุล : </span>
                                                                <b>{dataModalLand?.snam + '   ' + dataModalLand?.firstname + '   ' + dataModalLand?.lastname}</b>
                                                            </div>

                                                            <div
                                                                style={{ marginBottom: 0, }}
                                                            >
                                                                <span>ชื่อเล่น : </span>
                                                                <b>{dataModalLand?.nickname}</b>
                                                            </div>

                                                            <div
                                                                style={{ marginBottom: 0, }}
                                                            >
                                                                <span>เลขบัตรประชาชน : </span>
                                                                <b>{dataModalLand?.identificationId}</b>
                                                            </div>
                                                            <div
                                                                style={{ marginBottom: 0, }}
                                                            >
                                                                <b>

                                                                    {
                                                                        dataPhoneCus?.map((item, index) => {
                                                                            return renderItemPhones({ item, index })
                                                                        })
                                                                    }
                                                                </b>
                                                            </div>
                                                            <div
                                                                style={{ margin: 0, }}
                                                            >
                                                                <span>เพศ : </span>
                                                                <b>{dataModalLand?.gender}</b>
                                                            </div>
                                                            <div
                                                                style={{ marginBottom: 0, }}
                                                            >
                                                                <span>{dataEmail === "" ? <> </> : <>{dataEmail?.emailData}</>}</span>
                                                                <b>{dataEmail?.emailData}</b>
                                                            </div>
                                                            <div
                                                                style={{ marginBottom: 0, }}
                                                            >
                                                                <span>{dataSocials?.socialType}  </span>
                                                                <b>{dataSocials?.socialData}</b>
                                                            </div>
                                                        </aside>
                                                    </>
                                                }
                                            </Row>
                                        </>
                                    }
                                    <Divider />
                                    {address?.length >= 1 ?
                                        <>
                                            <Row justify={'left'}>
                                                <b><u>รายละเอียดที่อยู่</u></b>
                                            </Row>
                                            <Row gutter={32} justify={'center'}>
                                                {
                                                    address?.map((item, index) => {
                                                        return renderItemAddress({ item, index })
                                                    })
                                                }

                                            </Row>
                                        </>
                                        : null
                                    }
                                    {guarantor.data.typeLoan === "car" ?
                                        <>
                                            {dataFromTable?.productLoanType === "ย้ายไฟแนนซ์" || dataFromTable?.productLoanType === "Pre-Aaprove-ย้ายไฟแนนซ์" ?
                                                <>
                                                    <Row justify={'left'}><b><u>รายละเอียดยอดกู้{dataFromTable?.productLoanType}</u></b></Row>
                                                    <Row gutter={32} justify={'center'}>
                                                        <aside style={{ width: '90%' }}>
                                                            <div
                                                                style={{ marginBottom: 0, }}
                                                            // label='ยอดกู้เช่าซื้อ'
                                                            >
                                                                <span>ยอดกู้ : </span>
                                                                <b>{currencyFormatOne(dataModalCars?.carLoanDetails?.loanAmount)}</b>
                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                    บาท
                                                                </span>
                                                            </div>
                                                            <div
                                                                style={{ marginBottom: 0, }}
                                                            // label='จำนวนงวด'
                                                            >
                                                                <span>จำนวนงวด : </span>
                                                                <b>{dataModalCars?.carLoanDetails?.loanTerm}</b>
                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                    งวด
                                                                </span>
                                                            </div>

                                                            <div
                                                                style={{ marginBottom: 0, }}
                                                            // label='ดอกเบี้ย'
                                                            >
                                                                <span>ดอกเบี้ย : </span>
                                                                <b>{currencyFormatOne(dataModalCars?.carLoanDetails?.interest)}</b>
                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                    บาท
                                                                </span>
                                                            </div>
                                                            <div
                                                                style={{ marginBottom: 0, }}
                                                            // label='จำนวนผ่อนต่อเดือน'
                                                            >
                                                                <span>จำนวนผ่อนต่อเดือน : </span>
                                                                <b>{currencyFormatOne(dataModalCars?.carLoanDetails?.monthlyPayment)}</b>
                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                    บาท
                                                                </span>
                                                            </div>

                                                            <div
                                                                style={{ marginBottom: 0, }}
                                                            // label='ผ่อนรวมดอกเบี้ย'
                                                            >
                                                                <span>ผ่อนรวมดอกเบี้ย : </span>
                                                                <b>{currencyFormatOne(~~dataModalCars?.carLoanDetails?.monthlyPayment * ~~dataModalCars?.carLoanDetails?.loanTerm)}</b>
                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                    บาท
                                                                </span>
                                                            </div>
                                                            <div
                                                                style={{ marginBottom: 0, }}
                                                            // label='ผู้เสนอเคส'
                                                            >
                                                                <span>ผู้เสนอเคส : </span>
                                                                <b>{dataModalCars?.carLoanDetails?.proposalBy}</b>
                                                            </div>
                                                            <b> <div
                                                                style={{ margin: 0, }}
                                                            // label='เรทรถ'
                                                            >
                                                                <span>เรทรถ : </span>
                                                                <b style={{ fontSize: '20px' }}><u>{currencyFormatOne(dataModalCars?.carLoanDetails?.cars?.carPrice)}</u></b>
                                                                <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                                                    บาท
                                                                </span>
                                                            </div>
                                                            </b>
                                                        </aside>
                                                    </Row>
                                                    <Divider />
                                                    <Row justify={'left'}><b><u>รายละเอียดย้ายไฟแนนซ์เดิม</u></b></Row>
                                                    <Row gutter={32} justify={'center'}>
                                                        <aside style={{ width: '90%' }}>
                                                            <div style={{ marginBottom: 0, textAlign: 'left' }}>
                                                                <span>จากไฟแนนซ์ : </span>
                                                                <b>{dataModalCars?.carLoanDetails?.cars?.oldLoanDetails?.issuno}</b>
                                                            </div>
                                                            <div style={{ marginBottom: 0, textAlign: 'left' }}>
                                                                <span>ยอดปิดไฟแนนซ์ : </span>
                                                                <b>{currencyFormatOne(dataModalCars?.carLoanDetails?.cars?.oldLoanDetails?.oldLoanAmount)}</b>
                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                    บาท
                                                                </span>
                                                            </div>
                                                            <div style={{ marginBottom: 0, textAlign: 'left' }}>
                                                                <span>ค่างวด / เดือน : </span>
                                                                <b>{currencyFormatOne(dataModalCars?.carLoanDetails?.cars?.oldLoanDetails?.oldMonthlyPayment)}</b>
                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                    บาท
                                                                </span>
                                                            </div>

                                                            <div style={{ marginBottom: 0, textAlign: 'left' }}>
                                                                <span>จำนวนงวดที่ผ่อน : </span>
                                                                <b>{dataModalCars?.carLoanDetails?.cars?.oldLoanDetails?.oldLoanTerm}</b>
                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                    งวด
                                                                </span>
                                                            </div>
                                                        </aside>
                                                        <Divider />
                                                    </Row>
                                                    {
                                                        dataModalCars?.carLoanDetails?.approvalStatus === 3 ||
                                                            dataModalCars?.carLoanDetails?.approvalStatus === 21 ||
                                                            dataModalCars?.carLoanDetails?.approvalStatus === 27 ||
                                                            dataModalCars?.carLoanDetails?.approvalStatus === 25 ||
                                                            dataModalCars?.carLoanDetails?.approvalStatus === 26 ||
                                                            dataModalCars?.carLoanDetails?.approvalStatus === 24 ?
                                                            <>
                                                                {dataModalCars?.carLoanDetails?.productType === 'p-loan' ?
                                                                    <>
                                                                        <Row justify={'left'}><b><u>ยอดที่อนุมัติ</u></b></Row>
                                                                        <Row gutter={32} justify={'center'} style={{ backgroundColor: "lightgreen" }}>
                                                                            <aside style={{ width: '90%' }}>
                                                                                <div style={{ marginBottom: 0, }}>
                                                                                    <span>ยอดกู้ p-loan : </span>
                                                                                    <b>{currencyFormatOne(dataModalCars?.carLoanDetails?.approvedLoanAmount)}</b>
                                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                        บาท
                                                                                    </span>
                                                                                </div>
                                                                                <div style={{ marginBottom: 0, }}>
                                                                                    <span>จำนวนงวด p-loan : </span>
                                                                                    <b>{dataModalCars?.carLoanDetails?.approvedLoanTerm}</b>
                                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                        งวด
                                                                                    </span>
                                                                                </div>

                                                                                <div style={{ marginBottom: 0, }}>
                                                                                    <span>ดอกเบี้ย p-loan : </span>
                                                                                    <b>{currencyFormatOne(interestt)}</b>
                                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                        บาท
                                                                                    </span>
                                                                                </div>
                                                                                <div style={{ marginBottom: 0, }}>
                                                                                    <span>จำนวนผ่อน/เดือน p-loan : </span>
                                                                                    <b>{currencyFormatOne(arr)}</b>
                                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                        บาท
                                                                                    </span>
                                                                                </div>

                                                                                <div style={{ marginBottom: 0, }}>
                                                                                    <span>ผ่อนรวมดอกเบี้ย p-loan : </span>
                                                                                    <b>{currencyFormatOne(installmentWithInterestt)}</b>
                                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                        บาท
                                                                                    </span>
                                                                                </div>
                                                                                <div style={{ marginBottom: 0, }}>
                                                                                    <span>ผู้อนุมัติ p-loan : </span>
                                                                                    <b>{dataModalCars?.carLoanDetails?.approvedBy}</b>

                                                                                </div>
                                                                            </aside>
                                                                            {/* ตาราง */}
                                                                            <Col span={24}>
                                                                                <div style={{ textAlign: "right" }}>
                                                                                    <Button onClick={openTable}>ตารางค่างวด P-Loan</Button>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                        <Divider />
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {/* เช่าซื้อ */}
                                                                        {dataModalCars?.carLoanDetails?.cars?.productTypeCar === 1 ?
                                                                            <>
                                                                                <Row justify={'left'}><b><u>ยอดที่อนุมัติ</u></b></Row>
                                                                                <Row gutter={32} justify={'center'} style={{ backgroundColor: 'lightgreen' }}>
                                                                                    <aside style={{ width: '90%' }}>
                                                                                        <div
                                                                                            style={{ marginBottom: 0, }}
                                                                                        >
                                                                                            <span>ยอดกู้ : </span>
                                                                                            <b>{currencyFormatOne(dataModalCars?.carLoanDetails?.approvedLoanAmount)}</b>
                                                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                                บาท
                                                                                            </span>
                                                                                        </div>
                                                                                        <div
                                                                                            style={{ marginBottom: 0, }}
                                                                                        >
                                                                                            <span>จำนวนงวด : </span>
                                                                                            <b>{dataModalCars?.carLoanDetails?.approvedLoanTerm}</b>
                                                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                                งวด
                                                                                            </span>
                                                                                        </div>

                                                                                        <div
                                                                                            style={{ marginBottom: 0, }}
                                                                                        >
                                                                                            <span>ดอกเบี้ย : </span>
                                                                                            <b>{currencyFormatOne(interestt)}</b>
                                                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                                บาท
                                                                                            </span>
                                                                                        </div>
                                                                                        <div
                                                                                            style={{ marginBottom: 0, }}
                                                                                        >
                                                                                            <span>จำนวนผ่อนต่อเดือน : </span>
                                                                                            <b>{currencyFormatOne(arr)}</b>
                                                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                                บาท
                                                                                            </span>
                                                                                        </div>

                                                                                        <div
                                                                                            style={{ marginBottom: 0, }}
                                                                                        >
                                                                                            <span>ผ่อนรวมดอกเบี้ย : </span>
                                                                                            <b>{currencyFormatOne(installmentWithInterestt)}</b>
                                                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                                บาท
                                                                                            </span>
                                                                                        </div>
                                                                                        <div
                                                                                            style={{ marginBottom: 0, }}
                                                                                        // label='ผู้เสนอเคส'
                                                                                        >
                                                                                            <span>ผู้อนุมัติเคส : </span>
                                                                                            <b>{dataModalCars?.carLoanDetails?.approvedBy}</b>
                                                                                        </div>
                                                                                        <b> <div
                                                                                            style={{ margin: 0, }}
                                                                                        // label='เรทรถ'
                                                                                        ></div></b>
                                                                                    </aside>
                                                                                    {/* ตาราง */}
                                                                                    <Col span={24}>
                                                                                        <div style={{ textAlign: "right" }}>
                                                                                            <Button onClick={openTable2}>ตารางค่างวด เช่าซื้อ</Button>
                                                                                        </div>
                                                                                    </Col>
                                                                                </Row>

                                                                            </>
                                                                            :
                                                                            <>
                                                                                <Row justify={'left'}><b><u>ยอดที่อนุมัติ</u></b></Row>
                                                                                <Row gutter={32} justify={'center'} style={{ backgroundColor: 'lightgreen' }}>
                                                                                    <aside style={{ width: '90%' }}>
                                                                                        <div
                                                                                            style={{ marginBottom: 0, }}
                                                                                        >
                                                                                            <span>ยอดกู้ : </span>
                                                                                            <b>{currencyFormatOne(dataModalCars?.carLoanDetails?.approvedLoanAmount)}</b>
                                                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                                บาท
                                                                                            </span>
                                                                                        </div>
                                                                                        <div
                                                                                            style={{ marginBottom: 0, }}
                                                                                        >
                                                                                            <span>จำนวนงวด : </span>
                                                                                            <b>{dataModalCars?.carLoanDetails?.approvedLoanTerm}</b>
                                                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                                งวด
                                                                                            </span>
                                                                                        </div>

                                                                                        <div
                                                                                            style={{ marginBottom: 0, }}
                                                                                        >
                                                                                            <span>ดอกเบี้ย : </span>
                                                                                            <b>{currencyFormatOne(resultRateCarDATA)}</b>
                                                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                                บาท
                                                                                            </span>
                                                                                        </div>
                                                                                        <div
                                                                                            style={{ marginBottom: 0, }}
                                                                                        >
                                                                                            <span>จำนวนผ่อนต่อเดือน : </span>
                                                                                            <b>{currencyFormatOne(newLoanTermCarDATA)}</b>
                                                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                                บาท
                                                                                            </span>
                                                                                        </div>

                                                                                        <div
                                                                                            style={{ marginBottom: 0, }}
                                                                                        >
                                                                                            <span>ผ่อนรวมดอกเบี้ย : </span>
                                                                                            <b>{currencyFormatOne(newResultPriceCarDATA)}</b>
                                                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                                บาท
                                                                                            </span>
                                                                                        </div>
                                                                                        <div
                                                                                            style={{ marginBottom: 0, }}
                                                                                        // label='ผู้เสนอเคส'
                                                                                        >
                                                                                            <span>ผู้อนุมัติเคส : </span>
                                                                                            <b>{dataModalCars?.carLoanDetails?.approvedBy}</b>
                                                                                        </div>
                                                                                        <b> <div
                                                                                            style={{ margin: 0, }}
                                                                                        // label='เรทรถ'
                                                                                        ></div></b>
                                                                                    </aside>
                                                                                    {/* ตาราง */}
                                                                                    <Col span={24}>
                                                                                        <div style={{ textAlign: "right" }}>
                                                                                            {/* <Button onClick={openTable}>ตารางค่างวด P-Loan</Button> */}
                                                                                        </div>
                                                                                    </Col>
                                                                                </Row>
                                                                            </>
                                                                        }
                                                                    </>

                                                                }
                                                            </>
                                                            : null
                                                    }

                                                </>
                                                :
                                                null
                                            }
                                            {dataFromTable?.productLoanType === "ถือเล่มมา" ||
                                                dataFromTable?.productLoanType === "ซื้อ-ขาย" ||
                                                dataFromTable?.productLoanType === "ปรับโครงสร้าง" ||
                                                dataFromTable?.productLoanType === 'Pre-Aaprove' ||
                                                dataFromTable?.productLoanType === 'Pre-Aaprove-ถือเล่มมา' ||
                                                dataFromTable?.productLoanType === 'Pre-Aaprove-ซื้อ-ขาย' ||
                                                dataFromTable?.productLoanType === 'Pre-Aaprove-รีโอน' ||
                                                dataFromTable?.productLoanType === 'เปลี่ยนสัญญา' ||
                                                dataFromTable?.productLoanType === 'Pre-Aaprove-เปลี่ยนสัญญา' ||
                                                dataFromTable?.productLoanType === 'รี+เปลี่ยนสัญญา' ||
                                                dataFromTable?.productLoanType === 'ปรับ+เปลี่ยนสัญญา' ||
                                                dataFromTable?.productLoanType === 'Pre-Aaprove-รี-Ploan' ||
                                                dataFromTable?.productLoanType === 'รี-3' ||
                                                dataFromTable?.productLoanType === 'Pre-Aaprove-ปรับโครงสร้าง' ||
                                                dataFromTable?.productLoanType === 'Pre-Aaprove-รี' ||
                                                dataFromTable?.productLoanType === 'Pre-Aaprove-รี' ||
                                                dataFromTable?.productLoanType === 'Pre-Aaprove-รี+เปลี่ยนสัญญา' ||
                                                dataFromTable?.productLoanType === 'Pre-Aaprove-Ploan' ||
                                                dataFromTable?.productLoanType === 'Pre-Aaprove-เปลี่ยนสัญญา'
                                                ?
                                                <>
                                                    <Row justify={'left'}><b><u>รายละเอียด({dataFromTable?.productLoanType})</u></b></Row>
                                                    {dataModalCars?.carLoanDetails?.productType === 'เช่าซื้อ' ?
                                                        <>
                                                            <Row gutter={32} justify={'center'}>
                                                                <aside style={{ width: '90%' }}>
                                                                    <div
                                                                        style={{ marginBottom: 0, }}
                                                                    // label='ยอดกู้เช่าซื้อ'
                                                                    >
                                                                        <span>ยอดกู้เช่าซื้อ : </span>
                                                                        <b>{currencyFormatOne(dataModalCars?.carLoanDetails?.loanAmount)}</b>
                                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                            บาท
                                                                        </span>
                                                                    </div>
                                                                    <div
                                                                        style={{ marginBottom: 0, }}
                                                                    // label='จำนวนงวดเช่าซื้อ'
                                                                    >
                                                                        <span>จำนวนงวดเช่าซื้อ : </span>
                                                                        <b>{dataModalCars?.carLoanDetails?.loanTerm}</b>
                                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                            งวด
                                                                        </span>
                                                                    </div>

                                                                    <div
                                                                        style={{ marginBottom: 0, }}
                                                                    // label='ดอกเบี้ยเช่าซื้อ'
                                                                    >
                                                                        <span>ดอกเบี้ยเช่าซื้อ : </span>
                                                                        <b>{currencyFormatOne(resultRateCarDATA)}</b>
                                                                        {/* <b>{currencyFormatOne(dataModalCars?.carLoanDetails?.interest)}</b> */}
                                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                            บาท
                                                                        </span>
                                                                    </div>
                                                                    <div
                                                                        style={{ marginBottom: 0, }}
                                                                    // label='จำนวนผ่อนต่อเดือนเช่าซื้อ'
                                                                    >
                                                                        <span>จำนวนผ่อนต่อเดือนเช่าซื้อ : </span>
                                                                        <b>{currencyFormatOne(newLoanTermCarDATA)}</b>
                                                                        {/* <b>{currencyFormatOne(dataModalCars?.carLoanDetails?.monthlyPayment)}</b> */}
                                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                            บาท
                                                                        </span>
                                                                    </div>

                                                                    <div
                                                                        style={{ marginBottom: 0, }}
                                                                    // label='ผ่อนรวมดอกเบี้ยเช่าซื้อ'
                                                                    >
                                                                        <span>ผ่อนรวมดอกเบี้ยเช่าซื้อ : </span>
                                                                        <b>{currencyFormatOne(newResultPriceCarDATA)}</b>
                                                                        {/* <b>{currencyFormatOne(~~dataModalCars?.carLoanDetails?.monthlyPayment * ~~dataModalCars?.carLoanDetails?.loanTerm)}</b> */}
                                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                            บาท
                                                                        </span>
                                                                    </div>
                                                                    <div
                                                                        style={{ marginBottom: 0, }}
                                                                    // label='ผู้เสนอเคสเช่าซื้อ'
                                                                    >
                                                                        <span>ผู้เสนอเคสเช่าซื้อ : </span>
                                                                        <b>{dataModalCars?.carLoanDetails?.proposalBy}</b>
                                                                    </div>
                                                                    <b> <div
                                                                        style={{ margin: 0, }}
                                                                    // label='เรทรถ'
                                                                    >
                                                                        <span>เรทรถ : </span>
                                                                        <b style={{ fontSize: '20px' }}><u>{currencyFormatOne(dataModalCars?.carLoanDetails?.cars?.carPrice)}</u></b>
                                                                        <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                                                            บาท
                                                                        </span>
                                                                    </div>
                                                                    </b>
                                                                </aside>
                                                            </Row>
                                                            <Divider />
                                                            {
                                                                dataModalCars?.carLoanDetails?.approvalStatus === 3 ||
                                                                    dataModalCars?.carLoanDetails?.approvalStatus === 27 ||
                                                                    dataModalCars?.carLoanDetails?.approvalStatus === 25 ||
                                                                    dataModalCars?.carLoanDetails?.approvalStatus === 26 ||
                                                                    dataModalCars?.carLoanDetails?.approvalStatus === 24 ?
                                                                    <>
                                                                        {dataModalCars?.carLoanDetails?.cars?.productTypeCar === 1 ?
                                                                            <>
                                                                                <Row justify={'left'}><b><u>ยอดที่อนุมัติ!!!</u></b></Row>
                                                                                <Row gutter={32} justify={'center'} style={{ backgroundColor: 'lightgreen' }}>
                                                                                    <aside style={{ width: '90%' }}>
                                                                                        <div
                                                                                            style={{ marginBottom: 0, }}
                                                                                        >
                                                                                            <span>ยอดกู้ : </span>
                                                                                            <b>{currencyFormatOne(dataModalCars?.carLoanDetails?.approvedLoanAmount)}</b>
                                                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                                บาท
                                                                                            </span>
                                                                                        </div>
                                                                                        <div
                                                                                            style={{ marginBottom: 0, }}
                                                                                        >
                                                                                            <span>จำนวนงวด : </span>
                                                                                            <b>{dataModalCars?.carLoanDetails?.approvedLoanTerm}</b>
                                                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                                งวด
                                                                                            </span>
                                                                                        </div>

                                                                                        <div
                                                                                            style={{ marginBottom: 0, }}
                                                                                        >
                                                                                            <span>ดอกเบี้ย : </span>
                                                                                            <b>{currencyFormatOne(interestt)}</b>
                                                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                                บาท
                                                                                            </span>
                                                                                        </div>
                                                                                        <div
                                                                                            style={{ marginBottom: 0, }}
                                                                                        >
                                                                                            <span>จำนวนผ่อนต่อเดือน : </span>
                                                                                            <b>{currencyFormatOne(arr)}</b>
                                                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                                บาท
                                                                                            </span>
                                                                                        </div>

                                                                                        <div
                                                                                            style={{ marginBottom: 0, }}
                                                                                        >
                                                                                            <span>ผ่อนรวมดอกเบี้ย : </span>
                                                                                            <b>{currencyFormatOne(installmentWithInterestt)}</b>
                                                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                                บาท
                                                                                            </span>
                                                                                        </div>
                                                                                        <div
                                                                                            style={{ marginBottom: 0, }}
                                                                                        // label='ผู้เสนอเคส'
                                                                                        >
                                                                                            <span>ผู้อนุมัติเคส : </span>
                                                                                            <b>{dataModalCars?.carLoanDetails?.approvedBy}</b>
                                                                                        </div>
                                                                                        <b> <div
                                                                                            style={{ margin: 0, }}
                                                                                        // label='เรทรถ'
                                                                                        ></div></b>
                                                                                    </aside>
                                                                                    {/* ตาราง */}
                                                                                    <Col span={24}>
                                                                                        <div style={{ textAlign: "right" }}>
                                                                                            <Button onClick={openTable2}>ตารางค่างวด เช่าซื้อ</Button>
                                                                                        </div>
                                                                                    </Col>
                                                                                </Row>

                                                                            </>
                                                                            :
                                                                            <>
                                                                                <Row justify={'left'}><b><u>ยอดที่อนุมัติ</u></b></Row>
                                                                                <Row gutter={32} justify={'center'} style={{ backgroundColor: 'lightgreen' }}>
                                                                                    <aside style={{ width: '90%' }}>
                                                                                        <div
                                                                                            style={{ marginBottom: 0, }}
                                                                                        >
                                                                                            <span>ยอดกู้ : </span>
                                                                                            <b>{currencyFormatOne(dataModalCars?.carLoanDetails?.approvedLoanAmount)}</b>
                                                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                                บาท
                                                                                            </span>
                                                                                        </div>
                                                                                        <div
                                                                                            style={{ marginBottom: 0, }}
                                                                                        >
                                                                                            <span>จำนวนงวด : </span>
                                                                                            <b>{dataModalCars?.carLoanDetails?.approvedLoanTerm}</b>
                                                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                                งวด
                                                                                            </span>
                                                                                        </div>

                                                                                        <div
                                                                                            style={{ marginBottom: 0, }}
                                                                                        >
                                                                                            <span>ดอกเบี้ย : </span>
                                                                                            <b>{currencyFormatOne(resultRateCarDATA)}</b>
                                                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                                บาท
                                                                                            </span>
                                                                                        </div>
                                                                                        <div
                                                                                            style={{ marginBottom: 0, }}
                                                                                        >
                                                                                            <span>จำนวนผ่อนต่อเดือน : </span>
                                                                                            <b>{currencyFormatOne(newLoanTermCarDATA)}</b>
                                                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                                บาท
                                                                                            </span>
                                                                                        </div>

                                                                                        <div
                                                                                            style={{ marginBottom: 0, }}
                                                                                        >
                                                                                            <span>ผ่อนรวมดอกเบี้ย : </span>
                                                                                            <b>{currencyFormatOne(newResultPriceCarDATA)}</b>
                                                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                                บาท
                                                                                            </span>
                                                                                        </div>
                                                                                        <div
                                                                                            style={{ marginBottom: 0, }}
                                                                                        // label='ผู้เสนอเคส'
                                                                                        >
                                                                                            <span>ผู้อนุมัติเคส : </span>
                                                                                            <b>{dataModalCars?.carLoanDetails?.approvedBy}</b>
                                                                                        </div>
                                                                                        <b> <div
                                                                                            style={{ margin: 0, }}
                                                                                        // label='เรทรถ'
                                                                                        ></div></b>
                                                                                    </aside>
                                                                                    {/* ตาราง */}
                                                                                    <Col span={24}>
                                                                                        <div style={{ textAlign: "right" }}>
                                                                                            {/* <Button onClick={openTable}>ตารางค่างวด P-Loan</Button> */}
                                                                                        </div>
                                                                                    </Col>
                                                                                </Row>
                                                                            </>
                                                                        }
                                                                    </>
                                                                    : null
                                                            }

                                                        </>
                                                        : dataModalCars?.carLoanDetails?.productType === 'เช่าซื้อ1' ?
                                                            <>
                                                                <Row gutter={32} justify={'center'}>
                                                                    <aside style={{ width: '90%' }}>
                                                                        <div
                                                                            style={{ marginBottom: 0, }}
                                                                        // label='ยอดกู้เช่าซื้อ'
                                                                        >
                                                                            <span>ยอดกู้เช่าซื้อ : </span>
                                                                            <b>{currencyFormatOne(dataModalCars?.carLoanDetails?.loanAmount)}</b>
                                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                บาท
                                                                            </span>
                                                                        </div>
                                                                        <div
                                                                            style={{ marginBottom: 0, }}
                                                                        // label='จำนวนงวดเช่าซื้อ'
                                                                        >
                                                                            <span>จำนวนงวดเช่าซื้อ : </span>
                                                                            <b>{dataModalCars?.carLoanDetails?.loanTerm}</b>
                                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                งวด
                                                                            </span>
                                                                        </div>

                                                                        <div
                                                                            style={{ marginBottom: 0, }}
                                                                        // label='ดอกเบี้ยเช่าซื้อ'
                                                                        >
                                                                            <span>ดอกเบี้ยเช่าซื้อ : </span>
                                                                            <b>{currencyFormatOne(dataModalCars?.carLoanDetails?.interest)}</b>
                                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                บาท
                                                                            </span>
                                                                        </div>
                                                                        <div
                                                                            style={{ marginBottom: 0, }}
                                                                        // label='จำนวนผ่อนต่อเดือนเช่าซื้อ'
                                                                        >
                                                                            <span>จำนวนผ่อนต่อเดือนเช่าซื้อ : </span>
                                                                            <b>{currencyFormatOne(dataModalCars?.carLoanDetails?.monthlyPayment)}</b>
                                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                บาท
                                                                            </span>
                                                                        </div>

                                                                        <div
                                                                            style={{ marginBottom: 0, }}
                                                                        // label='ผ่อนรวมดอกเบี้ยเช่าซื้อ'
                                                                        >
                                                                            <span>ผ่อนรวมดอกเบี้ยเช่าซื้อ : </span>
                                                                            <b>{currencyFormatOne(~~dataModalCars?.carLoanDetails?.monthlyPayment * ~~dataModalCars?.carLoanDetails?.loanTerm)}</b>
                                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                บาท
                                                                            </span>
                                                                        </div>
                                                                        <div
                                                                            style={{ marginBottom: 0, }}
                                                                        // label='ผู้เสนอเคสเช่าซื้อ'
                                                                        >
                                                                            <span>ผู้เสนอเคสเช่าซื้อ : </span>
                                                                            <b>{dataModalCars?.carLoanDetails?.proposalBy}</b>
                                                                        </div>
                                                                        <b> <div
                                                                            style={{ margin: 0, }}
                                                                        // label='เรทรถ'
                                                                        >
                                                                            <span>เรทรถ : </span>
                                                                            <b style={{ fontSize: '20px' }}><u>{currencyFormatOne(dataModalCars?.carLoanDetails?.cars?.carPrice)}</u></b>
                                                                            <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                                                                บาท
                                                                            </span>
                                                                        </div>
                                                                        </b>
                                                                    </aside>
                                                                </Row>
                                                                <Divider />
                                                                {
                                                                    dataModalCars?.carLoanDetails?.approvalStatus === 3 ||
                                                                        dataModalCars?.carLoanDetails?.approvalStatus === 27 ||
                                                                        dataModalCars?.carLoanDetails?.approvalStatus === 25 ||
                                                                        dataModalCars?.carLoanDetails?.approvalStatus === 26 ||
                                                                        dataModalCars?.carLoanDetails?.approvalStatus === 24 ?
                                                                        <>
                                                                            {dataModalCars?.carLoanDetails?.cars?.productTypeCar === 1 ?
                                                                                <>
                                                                                    <Row justify={'left'}><b><u>ยอดที่อนุมัติ!!!</u></b></Row>
                                                                                    <Row gutter={32} justify={'center'} style={{ backgroundColor: 'lightgreen' }}>
                                                                                        <aside style={{ width: '90%' }}>
                                                                                            <div
                                                                                                style={{ marginBottom: 0, }}
                                                                                            >
                                                                                                <span>ยอดกู้ : </span>
                                                                                                <b>{currencyFormatOne(dataModalCars?.carLoanDetails?.approvedLoanAmount)}</b>
                                                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                                    บาท
                                                                                                </span>
                                                                                            </div>
                                                                                            <div
                                                                                                style={{ marginBottom: 0, }}
                                                                                            >
                                                                                                <span>จำนวนงวด : </span>
                                                                                                <b>{dataModalCars?.carLoanDetails?.approvedLoanTerm}</b>
                                                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                                    งวด
                                                                                                </span>
                                                                                            </div>

                                                                                            <div
                                                                                                style={{ marginBottom: 0, }}
                                                                                            >
                                                                                                <span>ดอกเบี้ย : </span>
                                                                                                <b>{currencyFormatOne(resultRateCarDATA)}</b>
                                                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                                    บาท
                                                                                                </span>
                                                                                            </div>
                                                                                            <div
                                                                                                style={{ marginBottom: 0, }}
                                                                                            >
                                                                                                <span>จำนวนผ่อนต่อเดือน : </span>
                                                                                                <b>{currencyFormatOne(newLoanTermCarDATA)}</b>
                                                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                                    บาท
                                                                                                </span>
                                                                                            </div>

                                                                                            <div
                                                                                                style={{ marginBottom: 0, }}
                                                                                            >
                                                                                                <span>ผ่อนรวมดอกเบี้ย : </span>
                                                                                                <b>{currencyFormatOne(newResultPriceCarDATA)}</b>
                                                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                                    บาท
                                                                                                </span>
                                                                                            </div>
                                                                                            <div
                                                                                                style={{ marginBottom: 0, }}
                                                                                            // label='ผู้เสนอเคส'
                                                                                            >
                                                                                                <span>ผู้อนุมัติเคส : </span>
                                                                                                <b>{dataModalCars?.carLoanDetails?.approvedBy}</b>
                                                                                            </div>
                                                                                            <b> <div
                                                                                                style={{ margin: 0, }}
                                                                                            // label='เรทรถ'
                                                                                            ></div></b>
                                                                                        </aside>
                                                                                        {/* ตาราง */}
                                                                                        <Col span={24}>
                                                                                            <div style={{ textAlign: "right" }}>
                                                                                                <Button onClick={openTable2}>ตารางค่างวด เช่าซื้อ</Button>
                                                                                            </div>
                                                                                        </Col>
                                                                                    </Row>

                                                                                </>
                                                                                :
                                                                                <>
                                                                                    <Row justify={'left'}><b><u>ยอดที่อนุมัติ</u></b></Row>
                                                                                    <Row gutter={32} justify={'center'} style={{ backgroundColor: 'lightgreen' }}>
                                                                                        <aside style={{ width: '90%' }}>
                                                                                            <div
                                                                                                style={{ marginBottom: 0, }}
                                                                                            >
                                                                                                <span>ยอดกู้ : </span>
                                                                                                <b>{currencyFormatOne(dataModalCars?.carLoanDetails?.approvedLoanAmount)}</b>
                                                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                                    บาท
                                                                                                </span>
                                                                                            </div>
                                                                                            <div
                                                                                                style={{ marginBottom: 0, }}
                                                                                            >
                                                                                                <span>จำนวนงวด : </span>
                                                                                                <b>{dataModalCars?.carLoanDetails?.approvedLoanTerm}</b>
                                                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                                    งวด
                                                                                                </span>
                                                                                            </div>

                                                                                            <div
                                                                                                style={{ marginBottom: 0, }}
                                                                                            >
                                                                                                <span>ดอกเบี้ย : </span>
                                                                                                <b>{currencyFormatOne(resultRateCarDATA)}</b>
                                                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                                    บาท
                                                                                                </span>
                                                                                            </div>
                                                                                            <div
                                                                                                style={{ marginBottom: 0, }}
                                                                                            >
                                                                                                <span>จำนวนผ่อนต่อเดือน : </span>
                                                                                                <b>{currencyFormatOne(newLoanTermCarDATA)}</b>
                                                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                                    บาท
                                                                                                </span>
                                                                                            </div>

                                                                                            <div
                                                                                                style={{ marginBottom: 0, }}
                                                                                            >
                                                                                                <span>ผ่อนรวมดอกเบี้ย : </span>
                                                                                                <b>{currencyFormatOne(newResultPriceCarDATA)}</b>
                                                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                                    บาท
                                                                                                </span>
                                                                                            </div>
                                                                                            <div
                                                                                                style={{ marginBottom: 0, }}
                                                                                            // label='ผู้เสนอเคส'
                                                                                            >
                                                                                                <span>ผู้อนุมัติเคส : </span>
                                                                                                <b>{dataModalCars?.carLoanDetails?.approvedBy}</b>
                                                                                            </div>
                                                                                            <b> <div
                                                                                                style={{ margin: 0, }}
                                                                                            // label='เรทรถ'
                                                                                            ></div></b>
                                                                                        </aside>
                                                                                        {/* ตาราง */}
                                                                                        <Col span={24}>
                                                                                            <div style={{ textAlign: "right" }}>
                                                                                                {/* <Button onClick={openTable}>ตารางค่างวด P-Loan</Button> */}
                                                                                            </div>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </>
                                                                            }
                                                                        </>
                                                                        : null
                                                                }

                                                            </>
                                                            :
                                                            <>
                                                                {/* p - loan */}
                                                                <Row gutter={32} justify={'center'}>
                                                                    <aside style={{ width: '90%' }}>
                                                                        <div
                                                                            style={{ marginBottom: 0, }}
                                                                        >
                                                                            <span>ยอดกู้ p-loan : </span>
                                                                            <b>{currencyFormatOne(dataModalCars?.carLoanDetails?.loanAmount)}</b>
                                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                บาท
                                                                            </span>
                                                                        </div>
                                                                        <div
                                                                            style={{ marginBottom: 0, }}
                                                                        >
                                                                            <span>จำนวนงวด p-loan : </span>
                                                                            <b>{dataModalCars?.carLoanDetails?.loanTerm}</b>
                                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                งวด
                                                                            </span>
                                                                        </div>

                                                                        <div
                                                                            style={{ marginBottom: 0, }}
                                                                        >
                                                                            <span>ดอกเบี้ย p-loan : </span>
                                                                            <b>{currencyFormatOne(dataModalCars?.carLoanDetails?.interest)}</b>
                                                                            {/* <b>{currencyFormatOne(dataModalCars?.carLoanDetails?.interest)}</b> */}
                                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                บาท
                                                                            </span>
                                                                        </div>
                                                                        <div
                                                                            style={{ marginBottom: 0, }}
                                                                        >
                                                                            <span>จำนวนผ่อน/เดือน p-loan : </span>
                                                                            <b>{currencyFormatOne(dataModalCars?.carLoanDetails?.monthlyPayment)}</b>
                                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                บาท
                                                                            </span>
                                                                        </div>

                                                                        <div
                                                                            style={{ marginBottom: 0, }}
                                                                        >
                                                                            <span>ผ่อนรวมดอกเบี้ย p-loan : </span>
                                                                            <b>{currencyFormatOne(~~dataModalCars?.carLoanDetails?.monthlyPayment * ~~dataModalCars?.carLoanDetails?.loanTerm)}</b>
                                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                บาท
                                                                            </span>
                                                                        </div>
                                                                        <div
                                                                            style={{ marginBottom: 0, }}
                                                                        >
                                                                            <span>ผู้เสนอเคส p-loan : </span>
                                                                            <b>{dataModalCars?.carLoanDetails?.proposalBy}</b>
                                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                บาท
                                                                            </span>
                                                                        </div>
                                                                        <b> <div
                                                                            style={{ margin: 0, }}
                                                                        >
                                                                            <span>เรทรถ : </span>
                                                                            <b style={{ fontSize: '20px' }}><u>{currencyFormatOne(dataModalCars?.carLoanDetails?.cars?.carPrice)}</u></b>
                                                                            <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                                                                บาท
                                                                            </span>
                                                                        </div>
                                                                        </b>
                                                                    </aside>
                                                                </Row>
                                                                <Divider />
                                                                {
                                                                    dataModalCars?.carLoanDetails?.approvalStatus === 3 ||
                                                                        dataModalCars?.carLoanDetails?.approvalStatus === 27 ||
                                                                        dataModalCars?.carLoanDetails?.approvalStatus === 25 ||
                                                                        dataModalCars?.carLoanDetails?.approvalStatus === 26 ||
                                                                        dataModalCars?.carLoanDetails?.approvalStatus === 24 ?
                                                                        <>
                                                                            <Row justify={'left'}><b><u>ยอดที่อนุมัติ</u></b></Row>
                                                                            <Row gutter={32} justify={'center'} style={{ backgroundColor: "lightgreen" }}>
                                                                                <aside style={{ width: '90%' }}>
                                                                                    <div
                                                                                        style={{ marginBottom: 0, }}
                                                                                    >
                                                                                        <span>ยอดกู้ p-loan : </span>
                                                                                        <b>{currencyFormatOne(dataModalCars?.carLoanDetails?.approvedLoanAmount)}</b>
                                                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                            บาท
                                                                                        </span>
                                                                                    </div>
                                                                                    <div
                                                                                        style={{ marginBottom: 0, }}
                                                                                    >
                                                                                        <span>จำนวนงวด p-loan 555 : </span>
                                                                                        <b>{dataModalCars?.carLoanDetails?.approvedLoanTerm}</b>
                                                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                            งวด
                                                                                        </span>
                                                                                    </div>

                                                                                    <div
                                                                                        style={{ marginBottom: 0, }}
                                                                                    >
                                                                                        <span>ดอกเบี้ย p-loan : </span>
                                                                                        <b>{currencyFormatOne(interestt)}</b>
                                                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                            บาท
                                                                                        </span>
                                                                                    </div>
                                                                                    <div
                                                                                        style={{ marginBottom: 0, }}
                                                                                    >
                                                                                        <span>จำนวนผ่อน/เดือน p-loan : </span>
                                                                                        <b>{currencyFormatOne(arr)}</b>
                                                                                        {/* <b>{currencyFormatOne(arr)}</b> */}
                                                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                            บาท
                                                                                        </span>
                                                                                    </div>

                                                                                    <div
                                                                                        style={{ marginBottom: 0, }}
                                                                                    >
                                                                                        <span>ผ่อนรวมดอกเบี้ย p-loan : </span>
                                                                                        <b>{currencyFormatOne(installmentWithInterestt)}</b>
                                                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                            บาท
                                                                                        </span>
                                                                                    </div>
                                                                                    <div
                                                                                        style={{ marginBottom: 0, }}
                                                                                    >
                                                                                        <span>ผู้อนุมัติ p-loan : </span>
                                                                                        <b>{dataModalCars?.carLoanDetails?.approvedBy}</b>
                                                                                    </div>
                                                                                </aside>
                                                                                {/* ตาราง */}
                                                                                <Col span={24}>
                                                                                    <div style={{ textAlign: "right" }}>
                                                                                        <Button onClick={openTable}>ตารางค่างวด P-Loan</Button>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </>
                                                                        : null
                                                                }
                                                            </>
                                                    }
                                                </>
                                                : null
                                            }
                                        </>
                                        :
                                        <>
                                            {/* ที่ดิน */}
                                            {dataFromTable?.productLoanType === "ย้ายไฟแนนซ์" || dataFromTable?.productLoanType === "ย้ายไฟแนนซ์" || dataFromTable?.productLoanType === "Pre-Aaprove-ย้ายไฟแนนซ์" ?
                                                <>
                                                    <Row justify={'left'}><b><u>รายละเอียดขอ{dataModalLand?.landLoanDetails?.productLoanLandType}</u></b></Row>
                                                    <Row gutter={32} justify={'center'}>
                                                        <aside style={{ width: '90%' }}>
                                                            <div
                                                                style={{ marginBottom: 0, }}
                                                            >
                                                                <span>ประเภทขอกู้ : </span>
                                                                <b>{dataModalLand?.landLoanDetails?.productType}</b>
                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>

                                                                </span>
                                                            </div>
                                                            <div
                                                                style={{ marginBottom: 0, }}
                                                            >
                                                                <span>ยอดจัด : </span>
                                                                <b>{currencyFormatOne(dataModalLand?.landLoanDetails?.land?.landPrice)}</b>
                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                    บาท
                                                                </span>
                                                            </div>

                                                            <div
                                                                style={{ marginBottom: 0, }}
                                                            >
                                                                <span>อัตราดอกเบี้ย : </span>
                                                                <b>{currencyFormatOne(dataModalLand?.landLoanDetails?.interestRateLand)}</b>
                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                    <b>% +VAT</b>
                                                                </span>
                                                            </div>
                                                            <div
                                                                style={{ marginBottom: 0, }}
                                                            >
                                                                <span>จำนวนงวด : </span>
                                                                <b>{dataModalLand?.landLoanDetails?.loanLandTerm}</b>
                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                    งวด
                                                                </span>
                                                            </div>

                                                            <div
                                                                style={{ marginBottom: 0, }}
                                                            >
                                                                <span>ดอกเบี้ย : </span>
                                                                <b>{currencyFormatOne(~~dataModalLand?.landLoanDetails?.interestLand)}</b>
                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                    บาท
                                                                </span>
                                                            </div>
                                                            <div
                                                                style={{ marginBottom: 0, }}
                                                            >
                                                                <span>งวดละ : </span>
                                                                <b>{currencyFormatOne(dataModalLand?.landLoanDetails?.monthlyPaymentLand)}</b>
                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                    บาท
                                                                </span>
                                                            </div>
                                                            <b> <div
                                                                style={{ margin: 0, }}
                                                            >
                                                                <span>ราคารวม : </span>
                                                                <b style={{ fontSize: '20px' }}><u>{currencyFormatOne(dataModalLand?.landLoanDetails?.loanAmountLand)}</u></b>
                                                                <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                                                    บาท
                                                                </span>
                                                            </div>
                                                            </b>
                                                        </aside>
                                                    </Row>
                                                    <Divider />
                                                    <Row justify={'left'}><b><u>รายละเอียดย้ายไฟแนนซ์เดิม</u></b></Row>
                                                    <Row gutter={32} justify={'center'}>
                                                        <aside style={{ width: '90%' }}>
                                                            <div
                                                                style={{ marginBottom: 0 }}
                                                            >
                                                                <span>จากไฟแนนซ์ : </span>
                                                                <b>{dataModalLand?.landLoanDetails?.land?.oldLandLoanDetails?.issuno}</b>

                                                            </div>
                                                            <div
                                                                style={{ marginBottom: 0 }}
                                                            >
                                                                <span>ยอดปิดไฟแนนซ์ : </span>
                                                                <b>{currencyFormatOne(dataModalLand?.landLoanDetails?.land?.oldLandLoanDetails?.oldLandLoanAmount)}</b>
                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                    บาท
                                                                </span>
                                                            </div>
                                                            <div
                                                                style={{ marginBottom: 0 }}
                                                            >
                                                                <span>ค่างวด / เดือน : </span>
                                                                <b>{currencyFormatOne(dataModalLand?.landLoanDetails?.land?.oldLandLoanDetails?.oldLandMonthlyPayment)}</b>
                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                    บาท
                                                                </span>
                                                            </div>

                                                            <div
                                                                style={{ marginBottom: 0 }}
                                                            >
                                                                <span>จำนวนงวดที่ผ่อน : </span>
                                                                <b>{dataModalLand?.landLoanDetails?.land?.oldLandLoanDetails?.oldLandLoanTerm}</b>
                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                    งวด
                                                                </span>
                                                            </div>
                                                        </aside>
                                                        <Divider />
                                                    </Row>
                                                    {
                                                        dataModalLand?.landLoanDetails?.approvalStatus === 3 ||
                                                            dataModalLand?.landLoanDetails?.approvalStatus === 21 ||
                                                            dataModalLand?.landLoanDetails?.approvalStatus === 27 ||
                                                            dataModalLand?.landLoanDetails?.approvalStatus === 25 ||
                                                            dataModalLand?.landLoanDetails?.approvalStatus === 26 ||
                                                            dataModalLand?.landLoanDetails?.approvalStatus === 24 ?
                                                            <>
                                                                <Row justify={'left'}><b><u>ยอดที่อนุมัติ</u></b></Row>
                                                                <Row gutter={32} justify={'center'} style={{ backgroundColor: 'lightgreen' }}>
                                                                    <aside style={{ width: '90%' }}>
                                                                        <div
                                                                            style={{ marginBottom: 0, }}
                                                                        >
                                                                            <span>ยอดจัด : </span>
                                                                            <b>{currencyFormatOne(dataModalLand?.landLoanDetails?.approvedLoanAmount)}</b>
                                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                บาท
                                                                            </span>
                                                                        </div>
                                                                        <div
                                                                            style={{ marginBottom: 0, }}
                                                                        >
                                                                            <span>จำนวนงวด : </span>
                                                                            <b>{dataModalLand?.landLoanDetails?.approvedLoanTerm}</b>
                                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                งวด
                                                                            </span>
                                                                        </div>

                                                                        <div
                                                                            style={{ marginBottom: 0, }}
                                                                        >
                                                                            <span>งวดละ : </span>
                                                                            <b>{currencyFormatOne(loanTermDATA)}</b>
                                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                บาท
                                                                            </span>
                                                                        </div>
                                                                        <div
                                                                            style={{ marginBottom: 0, }}
                                                                        >
                                                                            <span>ดอกเบี้ย : </span>
                                                                            <b>{currencyFormatOne(resultRateDATA)}</b>
                                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                บาท
                                                                            </span>
                                                                        </div>

                                                                        <div
                                                                            style={{ marginBottom: 0, }}
                                                                        >
                                                                            <span>ราคารวม : </span>
                                                                            <b>{currencyFormatOne(resultPriceDATA)}</b>
                                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                บาท
                                                                            </span>
                                                                        </div>
                                                                        <div
                                                                            style={{ marginBottom: 0, }}
                                                                        >
                                                                            <span>ผู้อนุมัติเคส : </span>
                                                                            <b>{dataModalLand?.landLoanDetails?.approvedBy}</b>
                                                                        </div>
                                                                    </aside>
                                                                </Row>
                                                                <Divider />
                                                            </>
                                                            : null
                                                    }

                                                </>
                                                : null
                                            }
                                            {dataFromTable?.productLoanType === "ถือโฉนด" ||
                                                dataFromTable?.productLoanType === "ถือโฉนด(ที่ดิน)" ||
                                                dataFromTable?.productLoanType === "ซื้อ-ขาย" ||
                                                dataFromTable?.productLoanType === "ปรับโครงสร้าง" ||
                                                dataFromTable?.productLoanType === "ที่ดิน+บ้าน" ||
                                                dataFromTable?.productLoanType === "ฝากโฉนด" ||
                                                dataFromTable?.productLoanType === "Pre-Aaprove" ||
                                                dataFromTable?.productLoanType === 'Pre-Aaprove-ถือเล่มมา' ||
                                                dataFromTable?.productLoanType === 'Pre-Aaprove-ซื้อ-ขาย' ||
                                                dataFromTable?.productLoanType === "Pre-Aaprove-ฝากโฉนด"
                                                ?
                                                <>
                                                    <Row justify={'left'}><b><u>รายละเอียด{dataModalLand?.landLoanDetails?.productLoanLandType}</u></b></Row>
                                                    <Row gutter={32} justify={'center'}>
                                                        <aside style={{ width: '90%' }}>
                                                            <div
                                                                style={{ marginBottom: 0, }}
                                                            >
                                                                <span>ยอดจัด : </span>
                                                                <b>{currencyFormatOne(dataModalLand?.landLoanDetails?.loanAmountLand)}</b>
                                                                {/* <b>{currencyFormatOne(dataModalLand?.landLoanDetails?.loanAmounttLand)}</b> */}
                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                    บาท
                                                                </span>
                                                            </div>
                                                            <div
                                                                style={{ marginBottom: 0, }}
                                                            >
                                                                <span>จำนวนงวด : </span>
                                                                <b>{dataModalLand?.landLoanDetails?.loanLandTerm}</b>
                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                    งวด
                                                                </span>
                                                            </div>

                                                            <div
                                                                style={{ marginBottom: 0, }}
                                                            >
                                                                <span>งวดละ : </span>
                                                                <b>{currencyFormatOne(dataModalLand?.landLoanDetails?.monthlyPaymentLand)}</b>
                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                    บาท
                                                                </span>
                                                            </div>
                                                            <div
                                                                style={{ marginBottom: 0, }}
                                                            >
                                                                <span>ดอกเบี้ย : </span>
                                                                <b>{currencyFormatOne(dataModalLand?.landLoanDetails?.interestLand)}</b>
                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                    บาท
                                                                </span>
                                                            </div>

                                                            <div
                                                                style={{ marginBottom: 0, }}
                                                            >
                                                                <span>ราคารวม : </span>
                                                                <b>{currencyFormatOne(~~dataModalLand?.landLoanDetails?.loanAmountLand + ~~dataModalLand?.landLoanDetails?.interestLand)}</b>
                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                    บาท
                                                                </span>
                                                            </div>
                                                            <div
                                                                style={{ marginBottom: 0, }}
                                                            >
                                                                <span>ผู้เสนอเคสเช่าซื้อ : </span>
                                                                <b>{dataModalLand?.landLoanDetails?.proposalBy}</b>
                                                            </div>
                                                        </aside>
                                                    </Row>
                                                    <Divider />
                                                    {
                                                        dataModalLand?.landLoanDetails?.approvalStatus === 3 ||
                                                            dataModalLand?.landLoanDetails?.approvalStatus === 27 ||
                                                            dataModalLand?.landLoanDetails?.approvalStatus === 25 ||
                                                            dataModalLand?.landLoanDetails?.approvalStatus === 26 ||
                                                            dataModalLand?.landLoanDetails?.approvalStatus === 24 ?
                                                            <>
                                                                <Row justify={'left'}><b><u>ยอดที่อนุมัติ</u></b></Row>
                                                                <Row gutter={32} justify={'center'} style={{ backgroundColor: 'lightgreen' }}>
                                                                    <aside style={{ width: '90%' }}>
                                                                        <div
                                                                            style={{ marginBottom: 0, }}
                                                                        >
                                                                            <span>ยอดจัด : </span>
                                                                            <b>{currencyFormatOne(dataModalLand?.landLoanDetails?.approvedLoanAmount)}</b>
                                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                บาท
                                                                            </span>
                                                                        </div>
                                                                        <div
                                                                            style={{ marginBottom: 0, }}
                                                                        >
                                                                            <span>จำนวนงวด : </span>
                                                                            <b>{dataModalLand?.landLoanDetails?.approvedLoanTerm}</b>
                                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                งวด
                                                                            </span>
                                                                        </div>

                                                                        <div
                                                                            style={{ marginBottom: 0, }}
                                                                        >
                                                                            <span>งวดละ : </span>
                                                                            <b>{currencyFormatOne(loanTermDATA)}</b>
                                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                บาท
                                                                            </span>
                                                                        </div>
                                                                        <div
                                                                            style={{ marginBottom: 0, }}
                                                                        >
                                                                            <span>ดอกเบี้ย : </span>
                                                                            <b>{currencyFormatOne(resultRateDATA)}</b>
                                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                บาท
                                                                            </span>
                                                                        </div>

                                                                        <div
                                                                            style={{ marginBottom: 0, }}
                                                                        >
                                                                            <span>ราคารวม : </span>
                                                                            <b>{currencyFormatOne(resultPriceDATA)}</b>
                                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                บาท
                                                                            </span>
                                                                        </div>
                                                                        <div
                                                                            style={{ marginBottom: 0, }}
                                                                        >
                                                                            <span>ผู้อนุมัติเคส : </span>
                                                                            <b>{dataModalLand?.landLoanDetails?.approvedBy}</b>
                                                                        </div>
                                                                    </aside>
                                                                </Row>
                                                                <Divider />
                                                            </>
                                                            : null
                                                    }

                                                </>
                                                : null
                                            }
                                        </>
                                    }

                                    {dataModalCars?.carLoanDetails?.cars?.productTypeCar === 1 || dataModalCars?.carLoanDetails?.cars?.productTypeCar === 2 || dataModalCars?.carLoanDetails?.cars?.productTypeCar === 3 ?

                                        <>
                                            <Row justify={'left'}><b><u>รายละเอียดรถ</u></b></Row>
                                            <Row gutter={32} justify={'center'}>
                                                <Col className='gutter-row' span={8}>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>ยี่ห้อ : </span>
                                                        <b>{dataModalCars?.carLoanDetails?.cars?.carBrand}</b>
                                                    </div>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>เลขทะเบียน : </span>
                                                        <b>{dataModalCars?.carLoanDetails?.cars?.carPlateNumber}</b>
                                                    </div>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>เลขเครื่อง : </span>
                                                        <b>{dataModalCars?.carLoanDetails?.cars?.carEngineNumber}</b>
                                                    </div>
                                                </Col>
                                                <Col className='gutter-row' span={8}>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>รุ่นสินค้า : </span>
                                                        <b>{dataModalCars?.carLoanDetails?.cars?.carModel}</b>
                                                    </div>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>เลขคัสซี : </span>
                                                        <b>{dataModalCars?.carLoanDetails?.cars?.carChassisNumber}</b>
                                                    </div>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>สี : </span>
                                                        <b>{dataModalCars?.carLoanDetails?.cars?.carColor}</b>
                                                    </div>
                                                </Col>
                                                <Col className='gutter-row' span={8}>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>แบบรถ : </span>
                                                        <b>{dataModalCars?.carLoanDetails?.cars?.carBaab}</b>
                                                    </div>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>ปีรถ : </span>
                                                        <b>{dataModalCars?.carLoanDetails?.cars?.carYear}</b>
                                                    </div>

                                                </Col>
                                            </Row>
                                        </>
                                        :
                                        <>
                                            <Row justify={'left'}><b><u>รายละเอียดที่ดิน</u></b></Row>
                                            <Row gutter={32} justify={'center'}>
                                                <aside style={{ width: '90%' }}>
                                                    <div
                                                        style={{ marginBottom: 0 }}
                                                    >
                                                        <span>กลุ่มสินค้า : </span>{<b>{dataModalLand?.landLoanDetails?.productType}</b>}
                                                    </div>
                                                    <div
                                                        style={{ marginBottom: 0 }}
                                                    >
                                                        <span>ประเภทเอกสาร : </span>{<b>{dataModalLand?.landLoanDetails?.productLoanLandType}</b>}
                                                    </div>
                                                    <div
                                                        style={{ marginBottom: 0 }}
                                                    >
                                                        <span>เลขโฉนดที่ดิน : </span>{<b>{dataModalLand?.landLoanDetails?.land?.numberLand}</b>}
                                                    </div>
                                                    <div
                                                        style={{ marginBottom: 0 }}
                                                    >
                                                        <span>เลขที่ดินหรือระวาง : </span>{<b>{dataModalLand?.landLoanDetails?.land?.numberLandLawang}</b>}
                                                    </div>
                                                    <div
                                                        style={{ marginBottom: 0 }}
                                                    >
                                                        <span>จังหวัด : </span>{<b>{dataModalLand?.landLoanDetails?.land?.province}</b>}
                                                    </div>
                                                    <div
                                                        style={{ marginBottom: 0 }}
                                                    >
                                                        <span>อำเภอ : </span>{<b>{dataModalLand?.landLoanDetails?.land?.district}</b>}
                                                    </div>
                                                    <div
                                                        style={{ marginBottom: 0 }}
                                                    >
                                                        <span>พื้นที่ไร่ : </span>{<b>{dataModalLand?.landLoanDetails?.land?.rai}</b>}
                                                        <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                                            ไร่
                                                        </span>
                                                    </div>
                                                    <div
                                                        style={{ marginBottom: 0 }}
                                                    >
                                                        <span>พื้นที่งาน : </span>{<b>{dataModalLand?.landLoanDetails?.land?.workArea}</b>}
                                                        <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                                            งาน
                                                        </span>
                                                    </div>
                                                    <div
                                                        style={{ marginBottom: 0 }}
                                                    >
                                                        <span>พื้นที่ตารางวา : </span>{<b>{currencyFormatOne(dataModalLand?.landLoanDetails?.land?.squareWaArea)}</b>}
                                                        <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                                            ตารางวา
                                                        </span>
                                                    </div>
                                                    <div
                                                        style={{ marginBottom: 0 }}
                                                    >
                                                        <span>ราคาประเมินที่ดิน : </span> {<b>{currencyFormatOne(dataModalLand?.landLoanDetails?.land?.landPrice)}</b>}
                                                        <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                                            บาท / ตารางวา
                                                        </span>
                                                    </div>
                                                    <div
                                                        style={{ marginBottom: 0 }}
                                                    >
                                                        <span>ราคารวม : </span> {<b>{currencyFormatOne(dataModalLand?.landLoanDetails?.land?.resultLandPrice)}</b>}
                                                        <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                                            บาท
                                                        </span>
                                                    </div>
                                                </aside>
                                            </Row>
                                            <Divider />
                                            <Row justify={'left'}><b><u>ราคาประเมินที่ดิน</u></b></Row>
                                            <Row gutter={32} justify={'center'}>
                                                <aside style={{ width: '90%' }}>
                                                    <div style={{ marginBottom: 0 }}>
                                                        <span style={{ fontSize: '17px' }}>ราคาต่ำสุด : </span>{<b>{currencyFormatOne(dataModalLand?.landLoanDetails?.land?.approval?.minPrice)}</b>}
                                                        <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                                            บาท
                                                        </span>
                                                    </div>
                                                    <div style={{ marginBottom: 0 }}>
                                                        <span style={{ fontSize: '17px' }}>ราคาสูงสุด : </span>{<b>{currencyFormatOne(dataModalLand?.landLoanDetails?.land?.approval?.maxPrice)}</b>}
                                                        <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                                            บาท
                                                        </span>
                                                    </div>
                                                    <div style={{ marginBottom: 0 }}>
                                                        <span style={{ fontSize: '17px' }}>หมายเหตุ : </span>{<b>{dataModalLand?.landLoanDetails?.land?.approval?.note}</b>}
                                                    </div>
                                                </aside>

                                            </Row>

                                        </>
                                    }
                                    <Divider />
                                    <Row justify={'left'}>
                                        <b><u>อาชีพ - รายได้</u></b>
                                    </Row>
                                    {career ?
                                        <>
                                            {
                                                guarantor?.data?.typeLoan === "car" ?
                                                    <>
                                                        {career?.map((e, index) => {
                                                            return (
                                                                <Row gutter={32} justify={'center'}>
                                                                    <aside style={{ width: '90%' }}>
                                                                        <div style={{ marginBottom: 0, }}>
                                                                            <span>กลุ่มอาชีพ : </span>
                                                                            <b>{e?.desc}</b>
                                                                        </div>
                                                                        <div style={{ marginBottom: 0, }}>
                                                                            <span>ชื่อบริษัท(สถานที่ทำงาน) : </span>
                                                                            <b>{e?.companyName}</b>
                                                                        </div>
                                                                        <div style={{ marginBottom: 0, }}>
                                                                            <span>อาชีพ  : </span>
                                                                            <b>{e?.descSub}</b>
                                                                        </div>
                                                                        <div style={{ marginBottom: 0, }}>
                                                                            <span>รายได้ / เดือน : </span>
                                                                            <b>{currencyFormatOne(e?.incomeMonth)}</b>
                                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                บาท
                                                                            </span>
                                                                        </div>
                                                                        <div style={{ marginBottom: 0, }}>
                                                                            <span>สาขาอาชีพ : </span>
                                                                            <b>{e?.descSection}</b>
                                                                        </div>

                                                                        <div style={{ marginBottom: 0, }}>
                                                                            <span>
                                                                                รายจ่าย / เดือน : </span>
                                                                            <b>{currencyFormatOne(e?.expensesMonth)}</b>
                                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                บาท
                                                                            </span>
                                                                        </div>
                                                                    </aside>
                                                                </Row>
                                                            )
                                                        })}
                                                    </>
                                                    :
                                                    <>
                                                        {career?.map((e, index) => {
                                                            return (
                                                                <Row gutter={32} justify={'center'}>
                                                                    <aside style={{ width: '90%' }}>
                                                                        <div style={{ marginBottom: 0, }}>
                                                                            <span>กลุ่มอาชีพ : </span>
                                                                            <b>{e?.desc}</b>
                                                                        </div>
                                                                        <div style={{ marginBottom: 0, }}>
                                                                            <span>ชื่อบริษัท(สถานที่ทำงาน) : </span>
                                                                            <b>{e?.companyName}</b>
                                                                        </div>
                                                                        <div style={{ marginBottom: 0, }}>
                                                                            <span>อาชีพ  : </span>
                                                                            <b>{e?.descSub}</b>
                                                                        </div>
                                                                        <div style={{ marginBottom: 0, }}>
                                                                            <span>รายได้ / เดือน : </span>
                                                                            <b>{currencyFormatOne(e?.incomeMonth)}</b>
                                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                บาท
                                                                            </span>
                                                                        </div>
                                                                        <div style={{ marginBottom: 0, }}>
                                                                            <span>สาขาอาชีพ : </span>
                                                                            <b>{e?.descSection}</b>
                                                                        </div>

                                                                        <div style={{ marginBottom: 0, }}>
                                                                            <span>
                                                                                รายจ่าย / เดือน : </span>
                                                                            <b>{currencyFormatOne(e?.expensesMonth)}</b>
                                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                บาท
                                                                            </span>
                                                                        </div>
                                                                    </aside>
                                                                </Row>
                                                            )
                                                        })}
                                                    </>
                                            }
                                            <Divider />
                                        </>
                                        : null
                                    }
                                    {guarantor1?.length >= 1 ?
                                        <>

                                            <Row justify={'left'}>
                                                <b><u>รายละเอียดคนค้ำ</u></b>
                                            </Row>
                                            {
                                                guarantor1?.map((item, index) => {
                                                    return renderGuarantor({ item, index })
                                                })
                                            }
                                        </>
                                        : null
                                    }
                                    <Row justify={'left'}><Col span={24}><b><u>หมายเหตุ</u></b></Col></Row>
                                    <Row gutter={32} justify={'center'}>
                                        {notes?.length >= 1 ?
                                            <Card
                                                key={lastIndex}
                                                style={{ width: '270px', textAlign: 'left' }}>
                                                <div style={{ marginBottom: 0 }}>
                                                    <span>สาเหตุ : </span>
                                                    <b>{notes[lastIndex].cause}</b>
                                                </div>
                                                <div style={{ marginBottom: 0 }}>
                                                    <span>หมายเหตุ : </span>
                                                    <b>{notes[lastIndex].note}</b>
                                                </div>
                                                <div style={{ marginBottom: 0 }}>
                                                    <span>ผู้สร้างหมายเหตุ : </span>
                                                    <b>{notes[lastIndex].noteBy}</b>
                                                </div>
                                                <div style={{ marginBottom: 0 }}>
                                                    <span>สถานะที่ทำการหมายเหตุ : </span>
                                                    <b>{notes[lastIndex].status}</b>
                                                </div>
                                            </Card>
                                            : <>* ยังไม่เพิ่มหมายเหตุ!</>
                                        }
                                    </Row>
                                    <Divider />
                                    {/* <Row justify={'left'}><b><u>รูปบัตรประชาชนผู้ขอกู้</u></b></Row>
                                    <Row gutter={32} justify={'center'}>
                                        <Space direction="vertical" >
                                            <Row gutter={32} justify={'center'}>
                                                {imageBlobzz?.map((e, index) => {
                                                    if (e.type === 4) {
                                                        return (
                                                            <>
                                                                <Image width={"200px"} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                            </>
                                                        );
                                                    }
                                                    return null;
                                                })}
                                            </Row>
                                        </Space>
                                    </Row>
                                    <Divider />
                                    <Row justify={'left'}><b><u>ประวัติผู้กู้ / ทะเบียนบ้าน / อาชีพ</u></b></Row>
                                    <Row gutter={32} justify={'center'}>
                                        <Space direction="vertical" >
                                            <Row gutter={32} justify={'center'}>
                                                {imageBlobzz?.map((e, index) => {
                                                    if (e.type === 6) {
                                                        return (
                                                            <>
                                                                <Image width={"200px"} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                            </>
                                                        );
                                                    }
                                                    return null;
                                                })}
                                            </Row>
                                        </Space>
                                    </Row>
                                    <Divider />
                                    {dataFromTable.car === "car" ?
                                        <>
                                            <Row justify={'left'}><b><u>ใบประเมิน / รูปรถ / สัญญาไฟแนนซ์เดิม / ใบเสร็จไฟแนนซ์เดิม</u></b></Row>
                                            <Row gutter={32} justify={'center'}>
                                                <Space direction="vertical">
                                                    <Row gutter={32} justify={'center'}>
                                                        {imageBlobzz?.map((e, index) => {
                                                            if (e.type === 1) {
                                                                return (
                                                                    <>
                                                                        <Image width={"200px"} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                    </>
                                                                );
                                                            }
                                                            return null;
                                                        })}
                                                    </Row>
                                                </Space>
                                            </Row>
                                            <Divider />
                                            <Row justify={'left'}><b><u>หน้าคู่มือ</u></b></Row>
                                            <Row gutter={32} justify={'center'}>
                                                <Space direction="vertical">
                                                    <Row gutter={32} justify={'center'}>
                                                        {imageBlobzz?.map((e, index) => {
                                                            if (e.type === 2) {
                                                                return (
                                                                    <>
                                                                        <Image width={"200px"} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                    </>
                                                                );
                                                            }
                                                            return null;
                                                        })}
                                                    </Row>
                                                </Space>
                                            </Row>
                                        </>
                                        :
                                        <>
                                            <Row justify={'left'}><b><u>รูปโฉนด/หน้า-หลัง</u></b></Row>
                                            <Row gutter={32} justify={'center'}>
                                                <Space direction='vertical'>
                                                    <Row gutter={32} justify={'center'}>
                                                        {imageBlobzz?.map((e, index) => {
                                                            if (e.type === 3) {
                                                                return (
                                                                    <>
                                                                        <Image width={"200px"} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                    </>
                                                                );
                                                            }
                                                            return null;
                                                        })}
                                                    </Row >
                                                </Space>
                                            </Row>
                                            <Divider />
                                            <Row justify={'left'}><b><u>ใบแบ่งใบต่อ</u></b></Row>
                                            <Row gutter={32} justify={'center'}>
                                                <Space direction='vertical'>
                                                    <Row gutter={32} justify={'center'}>
                                                        {imageBlobzz?.map((e, index) => {
                                                            if (e.type === 10) {
                                                                return (
                                                                    <>
                                                                        <Image width={"200px"} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                    </>
                                                                );
                                                            }
                                                            return null;
                                                        })}
                                                    </Row >
                                                </Space>
                                            </Row>
                                            <Divider />
                                            <Row justify={'left'}><b><u>ระวาง</u></b></Row>
                                            <Row gutter={32} justify={'center'}>
                                                <Space direction='vertical'>
                                                    <Row gutter={32} justify={'center'}>
                                                        {imageBlobzz?.map((e, index) => {
                                                            if (e.type === 11) {
                                                                return (
                                                                    <>
                                                                        <Image width={"200px"} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                    </>
                                                                );
                                                            }
                                                            return null;
                                                        })}
                                                    </Row >
                                                </Space>
                                            </Row>
                                            <Divider />
                                            <Row justify={'left'}><b><u>รูปที่ดิน/สิ่งปลูกสร้าง-ที่ดินปัจจุบัน</u></b></Row>
                                            <Row gutter={32} justify={'center'}>
                                                <Space direction='vertical'>
                                                    <Row gutter={32} justify={'center'}>
                                                        {imageBlobzz?.map((e, index) => {
                                                            if (e.type === 1) {
                                                                return (
                                                                    <>
                                                                        <Image width={"200px"} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                    </>
                                                                );
                                                            }
                                                            return null;
                                                        })}
                                                    </Row >
                                                </Space>
                                            </Row>
                                            <Divider />
                                        </>
                                    }
                                    <Divider /> */}
                                    {/* <Row justify={'left'}><b><u>รูปสเตทเมนต์ผู้กู้</u></b></Row>
                                    <Row gutter={32} justify={'center'}>
                                        <Space direction="vertical">
                                            <Row gutter={32} justify={'center'}>
                                                {imageBlobzz?.map((e, index) => {
                                                    if (e.type === 8) {
                                                        return (
                                                            <>
                                                                <Image width={"200px"} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                            </>
                                                        );
                                                    }
                                                    return null;
                                                })}
                                            </Row>
                                        </Space>
                                    </Row>
                                    <Divider />
                                    <Row justify={'left'}><b><u>รูปสเตทเมนต์ผู้กู้ PDF</u></b></Row>
                                    <Row gutter={32} justify={'center'}>
                                        <Space direction="vertical">
                                            <Row gutter={32} justify={'center'}>
                                                {imageBlobzz?.map((e, index) => {
                                                    if (e.type === 7) {
                                                        return (
                                                            <>
                                                                <object data={`${getImagess}/${e.pathImage}?token=${token}`} type="application/pdf" width="350px" height="450px">
                                                                    <p>Alternative text - include a link <a href={`${getImagess}/${e.pathImage}?token=${token}`}>to the PDF!</a></p>
                                                                </object>
                                                            </>
                                                        )
                                                    }
                                                    return null;
                                                })}
                                            </Row >
                                        </Space>
                                    </Row>
                                    <Divider />
                                    <Row justify={'left'}><b><u>ประวัติคนค้ำ / ทะเบียนบ้าน / อาชีพ</u></b></Row>
                                    <Row gutter={32} justify={'center'}>
                                        <Space direction="vertical">
                                            <Row gutter={32} justify={'center'}>
                                                {imageBlobzz?.map((e, index) => {
                                                    if (e.type === 9) {
                                                        return (
                                                            <>
                                                                <Image width={"200px"} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                            </>
                                                        );
                                                    }
                                                    return null;
                                                })}
                                            </Row>
                                        </Space>
                                    </Row>
                                    <Divider />
                                    <Row justify={'left'}><b><u>เอกสารเพิ่มเติม</u></b></Row>
                                    <Row gutter={32} justify={'center'}>
                                        <Space direction="vertical">
                                            <Row gutter={32} justify={'center'}>
                                                {imageBlobzz?.map((e, index) => {
                                                    if (e.type === 12) {
                                                        return (
                                                            <>
                                                                <Image width={"200px"} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                            </>
                                                        );
                                                    }
                                                    return null;
                                                })}
                                            </Row>
                                        </Space>
                                    </Row> */}
                                    {/* <Divider /> */}
                                    <Col className='gutter-row' span={24} style={{ textAlign: 'right' }}>
                                        <Button
                                            style={{ margin: '5px', background: '#597ef7', color: '#ffffff' }}
                                            onClick={handleWork}
                                        >
                                            โยกงาน-ฝากงาน
                                        </Button>
                                    </Col>
                                </Card>
                            </div>
                        </Form>
                    </Row>
                </Spin>
            </Modal>
            {
                confirms ?
                    <Confirm
                        open={confirms}
                        close={setConfirms}
                        typeData={dataFromTable}
                        shootdata={getData}
                        closeAll={close}
                    />
                    : null
            }
        </div >
    )
}
