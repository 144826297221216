import React, { useState, useEffect } from 'react'
import { Modal, Row, Spin, Space } from 'antd'
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { addCar } from '../../../redux/Car';
import { addImage } from '../../../redux/Image';
import { alldataland1, getcontnoJojonoi } from '../../file_mid/all_api';
import Tel from './Tel';
import SendData from './SendData';

export default function Contno({ open, close, data, dataType }) {
    const { confirm } = Modal
    const dispatch = useDispatch()
    const [count, setCount] = useState('1')
    const [loading, setLoading] = useState(false)
    const [allData, setAllData] = useState()
    const [numberRe, setNumberRe] = useState()
    const [dataLand, setDataLand] = useState()
    const [beeypupLand, setBeeypupLand] = useState()

    useEffect(() => {
        if (data.PRODUCTS === "car") {
            loadDataContno()
        } else {
            loadDataContnoLand()
        }
        if (dataType === "โทร") {
            setCount("0")
        }
        if (dataType === "send") {
            setCount("1")
        }

    }, [])
    console.log("data", data)
    console.log("dataType", dataType)

    const loadDataContno = async () => {
        setLoading(true)
        await axios.get(`${getcontnoJojonoi}/${data?.CONTNO}`)
            .then(res => {
                if (res.data) {
                    // // console.log("Contno42", res.data)
                    // if (parseFloat(res.data?.re1) < parseFloat(res.data?.re2)) {
                    //     // console.log("re1 น้อยกว่า re2");
                    //     setNumberRe(res.data?.re1)
                    // } else if (parseFloat(res.data?.re2) < parseFloat(res.data?.re1)) {
                    //     // console.log("re2 น้อยกว่า re1");
                    //     setNumberRe(res.data?.re2)
                    // } else {
                    //     // console.log("re1 เท่ากับ re2");
                    //     setNumberRe(res.data?.re1)
                    // }
                    const result = Number(Math.round(Math.min(res.data?.re1, res.data?.re2)))
                    setNumberRe(String(result))
                    setAllData(res.data)
                    setLoading(false)
                }
            }).catch((err) => {
                console.log("err", err)
            })
        setLoading(false)
    }

    const loadDataContnoLand = async () => {
        setLoading(true)
        await axios.post(alldataland1, { CONTNO: data.CONTNO })
            .then(res => {
                if (res.data) {
                    // console.log("loadDataContnoLand", res.data)
                    setDataLand(res.data?.Data2)
                    setNumberRe(res.data?.Data2?.re1)
                    setAllData(res.data?.Data1)
                    setBeeypupLand(res.data?.beeypup)
                    setLoading(false)
                }
            }).catch((err) => {
                console.log("err", err)
            })
        setLoading(false)
    }

    const showConfirmCancel = () => {
        confirm({
            title: 'คุณต้องการยกเลิก...?',
            onOk() {
                localStorage.removeItem('addCar');
                localStorage.removeItem('addLand');
                localStorage.removeItem('addOccupation');
                localStorage.removeItem('addCareerIncome');
                dispatch(addCar());
                dispatch(addImage());
                close(false);
            },
            onCancel() {
            },
        });
    };

    const Onclose = () => {
        localStorage.removeItem('addCar');
        localStorage.removeItem('addLand');
        localStorage.removeItem('addOccupation');
        localStorage.removeItem('addCareerIncome');
        dispatch(addCar());
        dispatch(addImage());
        close(false);

    };

    return (
        <div>
            <Modal open={open} width={800} footer={null} onCancel={showConfirmCancel}>
                <Spin tip="Loading..." spinning={loading} >
                    <Space
                        direction="vertical"
                        size="middle"
                        style={{
                            display: 'flex',
                        }}>
                        <Row justify={'center'}>
                            {count === '0' && (<Tel allData={allData} data={data} onCancel={Onclose} changepage={setCount} dataType={dataType} />)}
                            {count === '1' && (<SendData allData={allData} data={data} onCancel={Onclose} changepage={setCount} dataType={dataType} />)}
                        </Row>
                    </Space>
                </Spin>
            </Modal>
        </div >
    )
}
