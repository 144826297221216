import React, { useState, useEffect } from 'react'
import { Button, Form, Select, Col, Row, Input, Modal, Card, Spin, Space, Divider, notification } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import Currency from 'currency.js';
import '../../css/Media.css'
import TextArea from 'antd/es/input/TextArea';
import { AllData, GetAmphur1, GetProvince, PostProvince1 } from '../../file_mid/all_api';

function ModalAddData({ close, setDataSendBack, dataSendBack, funcAdd, dataEdit, dataEditIndex, checkEdit, funcEdit, newDataLand, dataForCheckDup }) {

    const [api, contextHolder] = notification.useNotification();
    const currentTime = dayjs();
    const formattedTime = currentTime.format('YYYY-MM-DDTHH:mm:ssZ');
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const { Option } = Select;

    //////////////////// ที่ดิน /////////////////////////////
    const [dataPostLand, setDataPostLand] = useState()
    const [pvcodeS, setPvcodeS] = useState({});
    const [amcodeS, setAmcodeS] = useState({});
    const [provinces, setProvinces] = useState([]);
    const [district, setDistricts] = useState([]);
    /////////////////////////////////////////////////////
    const [checkButtonLand, setCheckButtonLand] = useState(0)

    useEffect(() => {
        GetAmphur(dataForCheckDup?.district)
        PostProvince(dataForCheckDup?.province)
        if (checkEdit) {
            form.setFieldsValue({
                ...dataEdit,
                provinces: dataForCheckDup.province,
                district: dataForCheckDup.district,
            })
            setDataPostLand({
                ...dataEdit,
                landInput: formattedTime,
                landDateStatus: formattedTime,
                provinces: dataForCheckDup.province,
                district: dataForCheckDup.district,
            })
        } else {
            setDataPostLand({
                customerId: 1746,
                landInput: formattedTime,
                productTypeLand: 2,
                gcode: null,
                // numberLand: null,
                numberLandlawang: null,
                provinces: dataForCheckDup.province,
                district: dataForCheckDup.district,
                subdistrict: null,
                rai: 0,
                workArea: 0,
                squareWaArea: 0,
                landPrice: 0,
                resultLandPrice: 0,
                landInputContno: null,
                landDateStatus: formattedTime,
                landContno: null,
                landStatus: null,
                landPriceStatus: false,
                landMemo: null,
                parcellat: null,
                parcellon: null,
                storyLand: null,
            })
            form.setFieldsValue({
                productTypeLand: 2,
                provinces: dataForCheckDup.province,
                district: dataForCheckDup.district,

            })
        }
    }, [])


    const handleSubmit = async () => {
        setLoading(true)
        if (checkButtonLand === 1 && dataPostLand?.productTypeLand === 2) {
            alert("กรุณากดตรวจสอบ")
        } else {
            var check = 0
            newDataLand.map((e, index) => {
                if (e.numberLand === dataPostLand.numberLand) {
                    check = 1
                }
            })
            if (check === 0) {
                if (checkEdit) {
                    funcEdit(dataPostLand, dataEditIndex)
                    close()
                } else {
                    // funcAdd(dataPostLand)
                    setDataSendBack({ ...dataSendBack, ...dataPostLand })
                    funcAdd({ ...dataSendBack, ...dataPostLand })
                    close()
                }

            } else {
                errorDup("top")
            }

        }
        setLoading(false)
    }

    const errorDup = (placement) => {
        api.warning({
            message: "ข้อมูลซ้ำกับทำมีอยู่แล้ว",
            description:
                'กรุณาเลือกที่ดินอื่น',
            placement,
        });
    };

    /////////////// ที่อยู่ ของ ที่ดิน //////////////////
    const getProvinces = async () => {
        setLoading(true)
        await axios.get(GetProvince)
            .then((res) => {
                setProvinces(res.data)
                setLoading(false)
            }).catch((err) => {
                console.error(err);
            })
        setLoading(false)
    }

    const PostProvince = async (value) => {
        const pr = { pvcode: value }
        setLoading(true)
        await axios.post(PostProvince1, pr)
            .then((res) => {
                setPvcodeS(res.data[0].pvcode)
                setProvinces(res.data)
                setLoading(false)
            }).catch((err) => {
                console.error(err);
            })
        setLoading(false)
    }

    const GetAmphur = async (value) => {
        const am = { pvcode: value }
        setLoading(true)
        await axios.post(GetAmphur1, am)
            .then((res) => {
                setDistricts(res.data)
                setAmcodeS(res.data[0].amcode)
                setLoading(false)
            }).catch((err) => {
                console.error(err);
            })
        setLoading(false)
    }

    const handleProvinceChange = async (value, key) => {
        setCheckButtonLand(1)
        setPvcodeS(key["key"])
        setDataPostLand({ ...dataPostLand, provinces: value });
        form.setFieldsValue(
            {
                numberLand: "",
                numberLandlawang: "",
                rai: "",
                workArea: "",
                squareWaArea: "",
                landPrice: "",
                resultLandPrice: "",
                district: "",
            }
        )
        setLoading(true)
        const test = { pvcode: key["key"] }
        await axios.post(GetAmphur1, test)
            .then((res) => {
                setDistricts(res.data)
                setLoading(false)
            }).catch((err) => {
                console.error(err);
            })
        setLoading(false)
    };

    const handleDistrictChange = (value, key) => {
        setCheckButtonLand(1)
        setAmcodeS(key["key"])
        setDataPostLand({ ...dataPostLand, district: value });
        form.setFieldsValue(
            {
                numberLand: "",
                numberLandlawang: "",
                rai: "",
                workArea: "",
                squareWaArea: "",
                landPrice: "",
                resultLandPrice: "",
            }
        )

    };
    const handleNumberLand = (e) => {
        setCheckButtonLand(1)
        setDataPostLand({ ...dataPostLand, numberLand: e.target.value })
    };

    ///////////////// กรณี นส.3 ///////////////////////////
    const handleProvinceChange1 = async (value, key) => {
        setPvcodeS(key["key"])
        setDataPostLand({ ...dataPostLand, provinces: value });
        form.setFieldsValue(
            {
                numberLand: "",
                numberLandlawang: "",
                rai: "",
                workArea: "",
                squareWaArea: "",
                district: "",
            }
        )
        setLoading(true)
        const test = { pvcode: key["key"] }
        await axios.post(GetAmphur1, test)
            .then((res) => {
                setDistricts(res.data)
                setLoading(false)
            }).catch((err) => {
                console.error(err);
            })
        setLoading(false)
    };

    const handleDistrictChange2 = (value, key) => {
        setAmcodeS(key["key"])
        setDataPostLand({ ...dataPostLand, district: value });
        form.setFieldsValue(
            {
                numberLand: "",
                numberLandlawang: "",
                rai: "",
                workArea: "",
                squareWaArea: "",
            }
        )

    };

    const currencyFormatOne = (amount) => {
        if (amount) {
            return Number(amount)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, '$&,')
        }
    }
    /////////////////// เลือก 1 = นส.3 กับ 2 = นส.4 //////////////////////
    const handleProductLand = (value) => {
        if (value === 1) {
            setDataPostLand({
                ...dataPostLand,
                productTypeLand: value,
                numberLand: "-",
                numberLandlawang: "-",
                rai: 0,
                workArea: 0,
                squareWaArea: 0,
                landPrice: 0,
                resultLandPrice: 0,
                landPriceStatus: false,
                subdistrict: null,
            })
            form.setFieldsValue(
                {
                    numberLand: "",
                    numberLandlawang: "",
                    rai: "",
                    workArea: "",
                    squareWaArea: "",
                    landPrice: "",
                    resultLandPrice: "",
                    // provinces: "",
                    // district: "",
                }
            )
        } else {
            setDataPostLand({
                ...dataPostLand,
                productTypeLand: value,
                numberLand: "-",
                numberLandlawang: "-",
                rai: 0,
                workArea: 0,
                squareWaArea: 0,
                landPrice: 0,
                resultLandPrice: 0,
                landPriceStatus: false,
            })
            form.setFieldsValue(
                {
                    numberLand: "",
                    numberLandlawang: "",
                    rai: "",
                    workArea: "",
                    squareWaArea: "",
                    landPrice: "",
                    resultLandPrice: "",
                    // provinces: "",
                    // district: "",
                }
            )
        }
    }

    const errorLand = () => {
        setDataPostLand({
            ...dataPostLand,
            numberLandlawang: null,
            rai: null,
            workArea: null,
            squareWaArea: null,
            landPrice: 0,
            resultLandPrice: 0,
            landPriceStatus: false
        });
        form.setFieldsValue(
            {
                numberLandlawang: "",
                rai: "",
                workArea: "",
                squareWaArea: "",
                landPrice: 0,
                resultLandPrice: 0,
            }
        )
        Modal.error({
            title: 'ไม่พบข้อมูล',
            content: 'กรุณาตรวจสอบข้อมูลที่กรอกอีกครั้ง',
        })
    }

    const handleCheckLand = async () => {
        var provids = pvcodeS
        var amphurs = amcodeS
        var landNos = dataPostLand.numberLand
        var result = { pvcode: provids, amcode: amphurs, landNo: landNos }
        const headers = {
            "Content-Type": "application/json",
        }
        setLoading(true)
        await axios.post(AllData, result, { headers: headers })
            .then((response) => {
                if (response.data) {
                    const squareWaArea = parseFloat(response.data.result[0].wa + "." + response.data.result[0].subwa)
                    const landPricePerWa = parseInt(response.data.result[0].landprice.replace(/,/g, ""))
                    const totalWa = (
                        ((parseInt(response.data?.result[0].rai) * 400)) +
                        ((parseInt(response.data?.result[0].ngan) * 100)) +
                        parseFloat(response.data?.result[0].wa + "." + response.data?.result[0].subwa)
                    )
                    const totalPrice = Currency(totalWa * landPricePerWa).value
                    // console.log("รวมตารางวาทั้งหมด =", totalPrice.toFixed(1));
                    if (landPricePerWa > 0) {
                        setDataPostLand({
                            ...dataPostLand,
                            subdistrict: response.data?.result[0].tumbolname,
                            parcellat: response.data?.result[0].parcellat,
                            parcellon: response.data?.result[0].parcellon,
                            numberLandlawang: response.data?.result[0].utm,
                            rai: parseInt(response.data?.result[0].rai),
                            workArea: parseInt(response.data?.result[0].ngan),
                            squareWaArea: parseFloat(squareWaArea),
                            landPrice: parseInt(response.data.result[0].landprice.replace(/,/g, "")) || 0,
                            resultLandPrice: totalPrice || 0,
                            landPriceStatus: true
                        });
                        form.setFieldsValue(
                            {
                                numberLandlawang: response.data?.result[0].utm,
                                rai: parseInt(response.data?.result[0].rai),
                                workArea: parseInt(response.data?.result[0].ngan),
                                squareWaArea: squareWaArea,
                                landPrice: currencyFormatOne(parseInt(response.data.result[0].landprice.replace(/,/g, ""))) || 0,
                                resultLandPrice: currencyFormatOne(totalPrice) || 0,
                            }
                        )
                    } else {
                        setDataPostLand({
                            ...dataPostLand,
                            subdistrict: response.data?.result[0].tumbolname,
                            parcellat: response.data?.result[0].parcellat,
                            parcellon: response.data?.result[0].parcellon,
                            numberLandlawang: response.data?.result[0].utm,
                            rai: parseInt(response.data?.result[0].rai),
                            workArea: parseInt(response.data?.result[0].ngan),
                            squareWaArea: parseFloat(squareWaArea),
                            landPrice: 0,
                            resultLandPrice: 0,
                            landPriceStatus: false
                        });
                        form.setFieldsValue(
                            {
                                numberLandlawang: response.data?.result[0].utm,
                                rai: parseInt(response.data?.result[0].rai),
                                workArea: parseInt(response.data?.result[0].ngan),
                                squareWaArea: squareWaArea,
                                landPrice: 0,
                                resultLandPrice: 0,
                            }
                        )
                    }
                    setLoading(false)
                    setCheckButtonLand(0)
                } else {
                    console.log("DATA =", response.data)
                }
            })
            .catch((err) => {
                setDataPostLand({
                    ...dataPostLand,
                    parcellat: "-",
                    parcellon: "-",
                    numberLandlawang: "-",
                    rai: 0,
                    workArea: 0,
                    squareWaArea: 0,
                    landPrice: 0,
                    resultLandPrice: 0,
                    landPriceStatus: false
                });
                errorLand()
            })
            .finally(() => {
                setLoading(false);
            })
        setLoading(false)
    }

    // const handleBackPage = () => {
    //     setPage(page - 1)
    // }
    const handleClose = () => {
        close()
    }

    return (

        <Row justify={'center'}>
            <Card>
                <Form
                    name="basic"
                    labelCol={{
                        span: 12,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    onFinish={handleSubmit}
                    autoComplete="off"
                    form={form}
                >
                    <Spin spinning={loading} size='large' tip=" Loading... ">
                        <Divider orientation='center'><b>เพิ่มข้อมูลที่ดิน</b></Divider>

                        <Form.Item label='ประเภทเอกสาร' name='productTypeLand'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input !'
                                },]}>
                            <Select placeholder={'เลือก'} onChange={(value) => handleProductLand(value)}>
                                <Option value={1}>นส.3 ก.</Option>
                                <Option value={2}>นส.4 จ.</Option>
                            </Select>
                        </Form.Item>
                        {
                            dataPostLand?.productTypeLand === 1 ?
                                <>
                                    <Form.Item name='provinces' label='จังหวัด'
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input !'
                                            },]}>
                                        <Select
                                            showSearch
                                            disabled
                                            loading={loading}
                                            name='provinces'
                                            placeholder="จังหวัด"
                                            onChange={handleProvinceChange1}
                                        >
                                            {provinces?.result?.map((pro, index) => (
                                                <Option key={pro.pvcode} value={pro.pvnamethai}>
                                                    {pro.pvnamethai}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item name='district' label='อำเภอ'
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input !'
                                            },]}>
                                        <Select
                                            showSearch
                                            disabled
                                            loading={loading}
                                            name='district'
                                            placeholder="อำเภอ"
                                            onChange={handleDistrictChange2}
                                        >
                                            {district?.map((dis, index) => (
                                                <Option key={dis.amcode} value={dis.amnamethai}>
                                                    {dis.amnamethai}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item label='เลขที่โฉนด/เลขที่' name='numberLand'
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input !'
                                            },]}>
                                        <Input type='number'
                                            onChange={(e) => setDataPostLand({ ...dataPostLand, numberLand: e.target.value })}></Input>
                                    </Form.Item>
                                    <Form.Item label='พื้นที่ไร่' name='rai'
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input !'
                                            },]}>
                                        <Input type='number' suffix="ไร่"
                                            onChange={(e) => setDataPostLand({ ...dataPostLand, rai: parseInt(e.target.value) })}></Input>
                                    </Form.Item>
                                    <Form.Item label='พื้นที่งาน' name='workArea'
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input !'
                                            },]}>
                                        <Input type='number' suffix="งาน"
                                            onChange={(e) => setDataPostLand({ ...dataPostLand, workArea: parseInt(e.target.value) })}></Input>
                                    </Form.Item>
                                    <Form.Item label='พื้นที่ตารางวา' name='squareWaArea'
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input !'
                                            },]}>
                                        <Input type='number' suffix="ตารางวา"
                                            onChange={(e) => setDataPostLand({ ...dataPostLand, squareWaArea: parseFloat(e.target.value) })}></Input>
                                    </Form.Item>
                                </>
                                :
                                <>
                                    <Form.Item name='provinces' label='จังหวัด'
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input !'
                                            },]}>
                                        <Select
                                            showSearch
                                            disabled
                                            loading={loading}
                                            name='provinces'
                                            placeholder="จังหวัด"
                                            onChange={handleProvinceChange}
                                        >
                                            {provinces?.result?.map((pro, index) => (
                                                <Option key={pro.pvcode} value={pro.pvnamethai}>
                                                    {pro.pvnamethai}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item name='district' label='อำเภอ'
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input !'
                                            },]}>
                                        <Select
                                            showSearch
                                            disabled
                                            loading={loading}
                                            name='district'
                                            placeholder="อำเภอ"
                                            onChange={handleDistrictChange}
                                        >
                                            {district?.map((dis, index) => (
                                                <Option key={dis.amcode} value={dis.amnamethai}>
                                                    {dis.amnamethai}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item label='เลขโฉนดที่ดิน' name='numberLand'
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input !'
                                            },]}>
                                        <Input type='text'
                                            onChange={(e) => handleNumberLand(e)}></Input>
                                    </Form.Item>
                                    <Form.Item label='ตรวจสอบ'>
                                        <Button type="primary" onClick={handleCheckLand}>ตรวจสอบ</Button>
                                    </Form.Item>
                                    <Form.Item label='ระวาง' name='numberLandlawang'>
                                        <Input type='text' disabled style={{ color: 'black' }}
                                            onChange={(e) => setDataPostLand({ ...dataPostLand, numberLandlawang: e.target.value })}></Input>
                                    </Form.Item>
                                    <Form.Item label='พื้นที่ไร่' name='rai'>
                                        <Input type='text' suffix="ไร่" disabled style={{ color: 'black' }}
                                            onChange={(e) => setDataPostLand({ ...dataPostLand, rai: parseInt(e.target.value) })}></Input>
                                    </Form.Item>
                                    <Form.Item label='พื้นที่งาน' name='workArea'>
                                        <Input type='text' suffix="งาน" disabled style={{ color: 'black' }}
                                            onChange={(e) => setDataPostLand({ ...dataPostLand, workArea: parseInt(e.target.value) })}></Input>
                                    </Form.Item>
                                    <Form.Item label='พื้นที่ตารางวา' name='squareWaArea'>
                                        <Input type='text' suffix="ตารางวา" disabled style={{ color: 'black' }}
                                            onChange={(e) => setDataPostLand({ ...dataPostLand, squareWaArea: parseFloat(e.target.value) })}></Input>
                                    </Form.Item>
                                    <Form.Item label='ราคาประเมินที่ดิน' name='landPrice'>
                                        <Input type='text' suffix="บาทต่อตารางวา" disabled style={{ color: 'black' }}
                                            onChange={(e) => setDataPostLand({ ...dataPostLand, landPrice: parseInt(e.target.value) })}></Input>
                                    </Form.Item>
                                    <Form.Item label='ราคารวม' name='resultLandPrice'>
                                        <Input type='text' suffix="บาท" disabled style={{ color: 'black' }}
                                            onChange={(e) => setDataPostLand({ ...dataPostLand, resultLandPrice: parseInt(e.target.value) })}></Input>
                                    </Form.Item>
                                </>
                        }
                        <Divider orientation='left'><b>สตอรี่รายละเอียดที่ดิน</b></Divider>
                        <Form.Item name="storyLand" label=""
                            rules={[
                                {
                                    required: true,
                                    message: 'กรุณากรอกสตอรี่รายละเอียดที่ดิน ไม่มีให้ใส่ - '
                                },]}>
                            <TextArea
                                name='storyLand'
                                style={{ color: 'blue' }}
                                rows={8}
                                onChange={(e) => setDataPostLand({ ...dataPostLand, storyLand: e.target.value.trim() })}
                            >
                            </TextArea>
                        </Form.Item>
                    </Spin>
                    <Divider />
                    <Col span={24} style={{ textAlign: "right" }}>
                        <Space>
                            {/* <Button type='primary' style={{ backgroundColor: "gray" }} onClick={handleBackPage}>ย้อนกลับ</Button> */}
                            <Button type='primary' danger onClick={handleClose}>ยกเลิก</Button>
                            <Button type='primary' htmlType='submit' style={{ backgroundColor: "green" }}>ยืนยัน</Button>
                        </Space>
                    </Col>
                </Form >
            </Card>
            {contextHolder}
        </Row >

    )
}

export default ModalAddData