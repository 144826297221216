import React, { useState, useEffect } from "react";
// import { Routes, Route, HashRouter } from "react-router-dom";
// import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import Permis from "./pages/Permis";
import Main from "./components/layout/Main";
// import "antd/dist/antd.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import { useSelector } from "react-redux";
import { requestForToken, onMessageListener } from "./firebase";

export default function App() {
  //  const { users } = useSelector((state) => ({ ...state }))
  const users = useSelector((state) => state.users);
  const token = localStorage.getItem("token");
  // const currentToken = localStorage.getItem("currentToken");
  useEffect(() => {
    // if (!currentToken) {
    //   console.log("register firebase");
    //   if ('serviceWorker' in navigator) {
    //     navigator.serviceWorker.register('/firebase-messaging-sw.js')
    //       .then((registration) => {
    //         console.log("Service Worker registered with scope:", registration.scope);
    //       })
    //       .catch((error) => {
    //         console.error("Service Worker registration failed:", error);
    //       });
    //   } else {
    //     console.log("Service Worker is not supported");
    //   }
    //   if (Notification.permission !== 'granted') {
    //     Notification.requestPermission();
    //   }
    //   requestForToken();
    // }
    // onMessageListener();
  }, []);

  // if (!users.userData) {
  if (!token) {
    return (
      <>
        <SignIn />
      </>
    );
  }
  return (
    <>
      <Main />
    </>
  );
}
