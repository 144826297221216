import React, { useEffect, useState } from 'react'
import { Table, Card, Button, Col, Row, Checkbox, Modal, Input, Space, Form } from 'antd';
import axios from 'axios';
import { addRegister } from "../redux/User";
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { permission } from './file_mid/all_api';

export default function Permis() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { users } = useSelector((state) => ({ ...state }))
    //console.log("easy",users.registerData.USER.id)
    const token = localStorage.getItem('token')
    const menu = localStorage.getItem('menu')
    const tk = JSON.parse(token)
    const [dataR, setDataR] = useState()

    const success = () => {
        Modal.success({
            title: 'Success',
            content: 'บันทึกสำเร็จ',
        })
    }
    const error = () => {
        Modal.error({
            title: 'Error',
            content: "บันทึกไม่สำเร็จ",
        })
    }

    const handleSubmit = async () => {
        const headers = {
            "Authorization": `Bearer ${tk}`,
            "Menu": JSON.stringify("15")
        }
        //const roleName = "PG"
        const permis = Object.assign({}, dataSource.filter(item => item.menuId))
        const newData = {};
        for (const key in permis) {
            const item = permis[key];
            const menuName = item.key;
            const permissions = {
                read: item.read,
                write: item.write,
                delete: item.delete,
            };
            newData[menuName] = permissions;
        }
        const selectedData1 = { ...dataR, permission: newData }
        //console.log("สิทธิ์", selectedData1)

        await axios.post(permission, selectedData1, { headers: headers })
            .then(res => {
                if (res.status === 200) {
                    success()
                    dispatch(addRegister())
                    navigate("/")
                }
                else {
                    error()
                }
            })
            .catch((err) => error())
    }

    const data = [
        {
            key: 1,
            menu: 'home',
            menuId: true,
            read: true,
            //read: false,
            write: false,
            delete: false,
        },
        {
            key: 2,
            menu: 'เสนอเคส',
            read: false,
            write: false,
            delete: false,
        },
        {
            key: 3,
            menu: 'ตรวจสอบข้อมูล',
            read: false,
            write: false,
            delete: false,
        },
        {
            key: 4,
            menu: 'ตรวจสอบข้อมูลธุรการ',
            read: false,
            write: false,
            delete: false,
        },
        {
            key: 5,
            menu: 'ตรวจสอบข้อมูลพื่กั้ง',
            read: false,
            write: false,
            delete: false,
        },
        {
            key: 6,
            menu: 'ตรวจสอบข้อมูลพื่หนุ่ม',
            read: false,
            write: false,
            delete: false,
        },
        {
            key: 7,
            menu: 'ตรวจสอบข้อมูลผู้จัดการ',
            read: false,
            write: false,
            delete: false,
        },
        {
            key: 8,
            menu: 'ตรวจสอบข้อมูลบอส',
            read: false,
            write: false,
            delete: false,
        },
        {
            key: 9,
            menu: 'บันทึกประวัติลูกค้า',
            read: false,
            write: false,
            delete: false,
        },
        {
            key: 10,
            menu: 'บันทึกทรัพย์สิน',
            read: false,
            write: false,
            delete: false,
        },
        {
            key: 11,
            menu: 'บันทึกสัญญาเงินกู้',
            read: false,
            write: false,
            delete: false,
        },
        {
            key: 12,
            menu: 'สอบถามข้อมูล Blacklist',
            read: false,
            write: false,
            delete: false,
        },
        {
            key: 13,
            menu: 'รับเงินเข้างวด ออโต้ test',
            read: false,
            write: false,
            delete: false,
        },
        {
            key: 14,
            menu: 'รับเงิน test',
            read: false,
            write: false,
            delete: false,
        },
        {
            key: 15,
            menu: 'รับเงิน (ยกเลิก)',
            read: false,
            write: false,
            delete: false,
        },
        {
            key: 16,
            menu: 'พิมพ์ใบเสร็จ test',
            read: false,
            write: false,
            delete: false,
        },
        {
            key: 17,
            menu: 'สมัครสมาชิก',
            read: false,
            write: false,
            delete: false,
        },
        {
            key: 18,
            menu: 'กำหนดสิทธิ์',
            read: false,
            write: false,
            delete: false,
        },
        {
            key: 19,
            menu: 'แก้ไขสิทธิ์',
            read: false,
            write: false,
            delete: false,
        },
        {
            key: 20,
            menu: 'memo',
            read: false,
            write: false,
            delete: false,
        },
        {
            key: 21,
            menu: 'ตรวจสอบเอกสาร',
            read: false,
            write: false,
            delete: false,
        },
        {
            key: 22,
            menu: 'อนุมัติเรทรถ',
            read: false,
            write: false,
            delete: false,
        },
    ];

    const [dataSource, setDataSource] = useState(data);
    const handleCheckboxChange = (e, key, type) => {
        //console.log("e", e.target.checked, "key", key, "type", type)
        const updatedDataSource = dataSource.map((item) => {
            if (item.key === key) {
                if (type === "menuId" && !e.target.checked) {
                    return {
                        ...item,
                        menuId: e.target.checked,
                        //menuId: key,
                        read: false,
                        write: false,
                        delete: false,
                    };
                }
                return {
                    ...item,
                    menuId: key,
                    [type]: e.target.checked,
                };
            }
            return item;
        })
        //console.log("update", updatedDataSource)
        setDataSource(updatedDataSource)
    }

    const columns = [
        {
            title: "ลำดับ",
            dataIndex: "index",
            key: 'index',
            width: 80,
            align: 'center',
            render: (text, object, index) => index + 1
        },
        {
            title: 'เลือกเมนู',
            dataIndex: 'menuId',
            key: 'menuId',
            width: 80,
            align: 'center',
            render: (text, record) => (
                <Checkbox
                    checked={record.menuId}
                    onChange={(e) => handleCheckboxChange(e, record.key, 'menuId')}
                />
            ),
        },
        {
            title: 'Menu',
            dataIndex: 'menu',
            key: 'menu',
        },
        {
            title: 'read',
            dataIndex: 'read',
            key: 'read',
            render: (text, record) => (
                <Checkbox
                    disabled={record.menuId ? false : true}
                    checked={record.read}
                    onChange={(e) => handleCheckboxChange(e, record.key, 'read')}
                />
            ),
        },
        {
            title: 'write',
            dataIndex: 'write',
            key: 'write',
            render: (text, record) => (
                <Checkbox
                    disabled={record.menuId ? false : true}
                    checked={record.write}
                    onChange={(e) => handleCheckboxChange(e, record.key, 'write')}
                />
            ),
        },
        {
            title: 'delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <Checkbox
                    disabled={record.menuId ? false : true}
                    checked={record.delete}
                    onChange={(e) => handleCheckboxChange(e, record.key, 'delete')}
                />
            ),
        },
    ];
    return (
        <Card>
            <Row style={{ textAlign: 'center' }} >
                <Col span={24}>
                    <b style={{ textAlign: 'center', color: '#002766', fontSize: '30px' }}>
                        <u>กำหนดสิทธิ์</u>
                    </b>
                </Col>
            </Row>
            <Row justify={'center'}>
                <Form.Item style={{ width: 500 }}>
                    <b style={{ display: 'flex', textAlign: 'center', color: '#002766', fontSize: '18px' }}>
                        <span style={{ width: '100px', lineHeight: '38px' }}>
                            ชื่อสิทธิ์ :
                        </span>
                        <Input type='text' onChange={(e) => setDataR({ ...dataR, roleName: e.target.value })}></Input>
                        {/* <span style={{ width: '100px', lineHeight: '38px' }}>
                            ชื่อสิทธิ์ :
                        </span> */}
                    </b>
                </Form.Item>
            </Row>
            <Row>
                <Col span={24} >
                    <Table dataSource={dataSource} columns={columns} style={{ fontSize: '30px', }} />

                </Col>
            </Row >
            <Row justify={'center'}>
                <Button type="primary" onClick={handleSubmit} style={{ background: 'green' }}>บันทึก</Button>
            </Row>
        </Card >
    )
}

